export class ItemApontamento {
  Id: number;
  ProdutoId: number;
  ApontamentoId: number;
  QtdRetirada: number = 0;
  QtdRetorno: number = 0;
  QtdAplicada: number = 0;
  PrecoUnitario: number = 0;
  IdERP: number = 0;
  StatusERP: EnumStatusERP;
  ValorTotal: number = 0;
  UN: String = "";
  isServico: boolean;
  ItemOsId: number;

  FlagExcluido: boolean;

  //Aux
  QtdPrevista: number = 0;
  QtdJaAplicada: number = 0;
  LocalEstoqueId: number;
  //auxiliar
  Quantidade:string = "";
}

export enum EnumStatusERP
{
    Pendente = 1,
    Baixado = 2
}
