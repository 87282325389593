import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Area } from '../classes/Area';
import { AreaImport } from '../classes/AreaImport';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor(private http: HttpClient) { }

  saveKmlFileExtractSchemas(file: File, fazendaId: number): Observable<any>{
    const formData = new FormData();
    
    formData.append("file", file, file.name);
 
    return this.http.post(`${environment.apiURL}/areas/SaveKmlFileExtractSchemas/${fazendaId}`, formData)
  }

  importAreaFromKmlFile(id: string, AreaTotalName: string, DescricaoName:string, FazendaId: number, areas :AreaImport[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/areas/ImportAreaFromKmlFile/${id}/${AreaTotalName}/${DescricaoName}/${FazendaId}`, areas)
  }

  getAreas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/areas`)
  }

  getArea(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/areas/${id}`)
  }

  postArea(area: Area): Observable<any>{
    return this.http.post(`${environment.apiURL}/areas`, area);
  }

  putArea(id: number, area: Area): Observable<any>{
    return this.http.put(`${environment.apiURL}/areas/${id}`, area);
  }

  deleteArea(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/areas/${id}`);
  }

  getAreasPorFazenda(idFazenda: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/areas/fazenda/${idFazenda}`)
  }

  getAreasPorFazendas(fazendas: number[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/areas/fazendas`,fazendas)
  }

  getAreasPorFazendaPorAno(idFazenda: number, idAnoSafra: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/areas/fazenda/${idFazenda}/${idAnoSafra}`)
  }

  getAreasPorSafras(safras: number[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/areas/ListarPorSafra`, safras)
  }

  getAreasPorSafra(id: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/areas/ListarPorSafraId/${id}`)
  }
}
