import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FiltroVwAplicacoesLavoura } from 'src/app/classes/FiltroVwAplicacoesLavoura';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { Safra } from 'src/app/classes/Safra';
import { VwAplicacoesLavoura } from 'src/app/classes/VwAplicacoesLavoura';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { VwAplicacoesLavouraService } from 'src/app/services/VwAplicacoesLavoura.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { SafraService } from 'src/app/services/safra.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-OSPlanejadaRealizada',
  templateUrl: './OSPlanejadaRealizada.component.html',
  styleUrls: ['./OSPlanejadaRealizada.component.scss']
})
export class OSPlanejadaRealizadaComponent implements OnInit {
  @ViewChild('ModalOSPlanejadaRealizada', { static: false })
  ModalOSPlanejadaRealizada: UiModalComponent;

  vwAplicacoesLavoura: VwAplicacoesLavoura[] = [];
  filtro: FiltroVwAplicacoesLavoura = new FiltroVwAplicacoesLavoura();
  safras: Safra[] = [];
  gruposProduto: GrupoProduto[] = [];
  carregando: boolean = false;

  constructor(
    private vwAplicacoesLavouraService: VwAplicacoesLavouraService,
    private grupoprodutoService: GrupoProdutoService,
    private safrasService: SafraService,
    private utils: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService) {

  }

  ngOnInit() {
    this.carregando = true;
    this.filtro = new FiltroVwAplicacoesLavoura();
    this.route.queryParams.subscribe(params => {
      if (params['id'] != undefined) {
        this.filtro.SafraId = params['id'];
        this.atualizaGrid();
      }
    });
    this.safrasService.getSafras().subscribe(res => {
      this.safras = res;
    }, error => {
      this.utils.getErro(error);
    });
    this.grupoprodutoService.getGruposProduto().subscribe(res => {
      this.gruposProduto = res;
    }, error => {
      this.utils.getErro(error);
    });
    this.carregando = false;
  }

  atualizaGrid() {
    this.vwAplicacoesLavouraService.get(this.filtro).subscribe(res => {
      this.vwAplicacoesLavoura = res;

    }, error => {
      this.utils.getErro(error);
    });
  }

  exportar(event) {
    this.orgarnizaFiltro();
    let base64 = '';
    this.vwAplicacoesLavouraService.exportar(this.filtro).subscribe(res => {
      base64 = res.retorno;
      if (base64 != null && base64 != undefined && base64 != '') {
        const blob = new Blob([base64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      }
    }, error => {
      this.utils.getErro(error);
    });
  }

  fecharModelFiltro() {
    this.ModalOSPlanejadaRealizada.hide();
  }
  orgarnizaFiltro() {
    if (typeof this.filtro.SafraId == 'string') {
      this.filtro.SafraId = parseInt(this.filtro.SafraId);
    }
    if (typeof this.filtro.GrupoProdutoId == 'string') {
      this.filtro.GrupoProdutoId = parseInt(this.filtro.GrupoProdutoId);
    }
    if (typeof this.filtro.ProdutoId == 'string') {
      this.filtro.ProdutoId = parseInt(this.filtro.ProdutoId);
    }
    if (typeof this.filtro.StatusOS == 'string') {
      this.filtro.StatusOS = parseInt(this.filtro.StatusOS);
    }
    if (typeof this.filtro.TipoOS == 'string') {
      this.filtro.TipoOS = parseInt(this.filtro.TipoOS);
    }
  }
  filtrar() {
    this.ModalOSPlanejadaRealizada.hide();
    this.orgarnizaFiltro();
    this.atualizaGrid();
  }

  exibeFiltro(event) {
    this.ModalOSPlanejadaRealizada.show();
  }
  changeProduto(event) {
    this.filtro.ProdutoId = event.Id;
  }
}
