import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Safra } from '../classes/Safra';

@Injectable({
  providedIn: 'root'
})
export class SafraService {

  public safraSelecionada: Safra;

  constructor(private http: HttpClient) { }

  getSafras(): Observable<any>{
    return this.http.get(`${environment.apiURL}/safra`)
  }

  getSafrasPorAnoFazenda(anoSafraId: number, fazendaId: number, status: number = 0, isCadastroSafra: boolean = false): Observable<any>{
    return this.http.get(`${environment.apiURL}/safra/listarPorAno/${anoSafraId}/${fazendaId}/${status}/${isCadastroSafra}`)
  }

  getSafrasPorCultura(culturaId: number, fazendaId: number, status: number = 0, isCadastroSafra: boolean = false): Observable<any>{
    return this.http.get(`${environment.apiURL}/safra/listarPorCultura/${culturaId}/${fazendaId}/${status}/${isCadastroSafra}`)
  }

  getSafrasPorAnoECultura(anoSafraId: number, culturaId: number, fazendaId: number, status: number = 0, isCadastroSafra: boolean = false): Observable<any>{
    return this.http.get(`${environment.apiURL}/safra/listarPorAnoECultura/${anoSafraId}/${culturaId}/${fazendaId}/${status}/${isCadastroSafra}`)
  }

  getSafra(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/safra/${id}`)
  }

  getPorProjeto(projetoId:string): Observable<any>{
    return this.http.get(`${environment.apiURL}/safra/projeto/${projetoId}`)
  }

  postSafra(safra: Safra): Observable<any>{
    return this.http.post(`${environment.apiURL}/safra`, safra);
  }

  putSafra(id: number, safra: Safra): Observable<any>{
    return this.http.put(`${environment.apiURL}/safra/${id}`, safra);
  }

  deleteSafra(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/safra/${id}`);
  }

  getSafrasPorAnoFazendas(anoSafraId: number, fazendas: number[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/safra/listarPorAnoPorFazendas/${anoSafraId}`, fazendas);
  }

  getSafrasPorFazendas(fazendas: number[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/safra/listarPorFazenda/`,fazendas);
  }
  getPdfFimSafra(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/safra/R_HistoricoAplicacao/${id}`)
  }
  getSafrasNaoIniciado(): Observable<any>{
    return this.http.get(`${environment.apiURL}/safra/NaoIniciado`)
  }
}
