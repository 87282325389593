import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AnexoCotacao } from 'src/app/classes/cotacao/AnexoCotacao';
import { Cotacao } from 'src/app/classes/cotacao/cotacao';
import { cotacaoService } from 'src/app/services/cotacao/cotacaoService';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-anexo-cotacao',
  templateUrl: './anexo-cotacao.component.html',
  styleUrls: ['./anexo-cotacao.component.scss']
})
export class AnexoCotacaoComponent implements OnInit, OnChanges  {

  @Input() cotacao: Cotacao;
  @Output() close = new EventEmitter<any>();

  uploadedFiles: Array<File> = [];
  anexos: AnexoCotacao[] = [];

  constructor(private cotacaoService: cotacaoService,
    private utils: UtilsService) { }

  ngOnInit() {
  }

  salvar() {
    this.cotacao.Anexos = this.anexos;
    
    this.cotacaoService.put(this.cotacao.Id, this.cotacao).subscribe(
      res => {
        this.uploadedFiles = [];
        this.anexos = [];
        this.cotacao = res;
      },
      err => {
        this.utils.getErro(err);
    });
  }

  download(arquivo: string) {
    this.cotacaoService.getAnexo("cotacao/DownloadAnexo", arquivo);
  }

  excluir(anexo: AnexoCotacao) {
    Swal.fire({
      title: 'Deletar '+ anexo.Arquivo + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.cotacaoService.deleteAnexo(anexo.Id).subscribe(res =>{
          const indice = this.cotacao.Anexos.findIndex(item => item.Id === anexo.Id);
          if (indice !== -1) {
            this.cotacao.Anexos.splice(indice, 1);
          }
        },
        err => {
          this.utils.getErro(err);
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cotacao) {
      this.cotacaoService.getPorIdComAnexos(this.cotacao.Id).subscribe(res =>{
        this.cotacao = res;
      },
      err => {
        this.utils.getErro(err);
      });

      this.uploadedFiles = [];
      this.anexos = [];
    }
  }

  getBase64() {
    let that = this;

    let a = this.anexos.map(e => e.Arquivo);
    for (let uf of this.uploadedFiles) {
      if(a.includes(uf.name)) {
        continue;
      }
      let reader = new FileReader();
      reader.readAsDataURL(uf);
      reader.onload = function () {
        let anexo = new AnexoCotacao();
        anexo.Arquivo = uf.name;
        anexo.UploadArquivo = reader.result.toString();
        that.anexos.push(anexo);
      };
        reader.onerror = function (error) {
        console.error('Error: ', error);
      };
    }
  }

}
