export class Cultivar {
    Id: number;
    Descricao:string = "";
    EmpresaId: number;
    CulturaId: number;
    CultivarBaseId: number;
    Unidade:string = "";
    Obtentor:string = "";
    Ciclo: number = 0;
    PesoMilSemente: number = 0;
    Germinacao: number = 0;
    Vigor: number = 0;
    IdERP:string = "";
}
