import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AgendaAtividade } from '../classes/AgendaAtividade';

@Injectable({
  providedIn: 'root'
})
export class AgendaAtividadeService {  

  constructor(private http: HttpClient) { }

  getAgendasAtividade(): Observable<any>{
    return this.http.get(`${environment.apiURL}/agendaAtividade`)
  }

  getAgendaAtividade(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/agendaAtividade/${id}`)
  }

  postAgendaAtividade(agendaAtividade: AgendaAtividade): Observable<any>{
    return this.http.post(`${environment.apiURL}/agendaAtividade`, agendaAtividade);
  }

  putAgendaAtividade(id: number, agendaAtividade: AgendaAtividade): Observable<any>{
    return this.http.put(`${environment.apiURL}/agendaAtividade/${id}`, agendaAtividade);
  }

  deleteAgendaAtividade(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/agendaAtividade/${id}`);
  }

  selecionaPorOs(oremServicoId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/agendaAtividade/selecionaPorOs/${oremServicoId}`)
  }
}
