export class ParametrosCompras {
  Id: number = 0;
  //Solicitação

  //Permite Utilizar Safra para Cotações?
  PermiteUtilizarSafraEmCotacoes: boolean = false;

  //Permite incluir produto na solicitação vinda de safra?
  PermiteIncluirProdutoVindoSafra: boolean = false;

  //Permite excluir produto na solicitação vinda de safra?
  PermiteExcluirProdutoVindoSafra: boolean = false;

  //Permite editar produto na solicitação vinda de safra?
  PermiteEditarProdutoVindoSafra: boolean = false;

  //Cotações

  //Usa Cotação?
  UsaCotacao: boolean = false;

  //Utiliza Alçada de Aprovação?
  UtilizaAlcadaAprovacao: boolean = false;

  //Número Minimo de Fornecedores na Cotação?
  NumeroMininoFornecedorCotacao: number = 0

  //Número Máximo de Fornecedores na Cotação?
  NumeroMaximoFornecedorCotacao: number = 0

  //Aprovação Por Tipo de Compra? (Lista de Tipos de compras)
  AprovacaoPorTipoCompras: boolean = false;

  //Utiliza data limite para retorno de Cotação?
  UtilizaDataLimiteRetornoCotacao: boolean = false;

  //Permite inserir valores manualmente na cotação?
  PermiteInserirValoresManualmenteCotacao: boolean = false;

  //Envia E-mail automaticamente da cotação aos fornecedores participantes?
  EnviaEmailsAutomaticoFornecedoresCotacao: boolean = false;

  //Notifica o usuário solicitante ao abrir uma cotação?
  NotificaUsuarioSolicitanteAoAbrirCotacao: boolean = false;

  //Avalia Cotação pela Melhor Oferta
  AvaliaCotacaoPelaMelhorOferta: boolean = false;

  //Transação Destino Padrão
  TransacaoDestinoPadrao: number

  //Permite Inserir itens durante a cotação
  PermiteInserirItensDuranteCotacao: boolean = false;

  //Permite agrupar Solicitações na Cotação
  PermiteAgruparSolicitacoesCotacao: boolean = false;

  //Permite Recebimento Parcial para Ordem de Compra
  PermiteRecebimentoParcialOrdemCompra: boolean = false;

  //Permite Inserir fornecedor para itens não cotados após recebimento
  PermiteInserirFornecedorItensNaoCotados: boolean = false;

  //Habilita campo Observação na Cotação
  HabilitaObservacaoCotacao: boolean = false;

  //Permite anexo na cotação?
  PermiteAnexoCotacao: boolean = false;

  //Tamanho máximo do anexo na cotação
  TamanhoMaximoAnexoCotacao: number = 0

  //Diretório para salvar arquivos anexos
  DiretorioAnexoCotacao: string = "";

  //Não agrupar itens iguais na cotação
  NaoAgruparItensIguaisCotacao: boolean = false;

  //Usar desconto global na cotação
  UsarDescontoGlobalCotacao: boolean = false;

  //Mensagem padrão para envio do e-mail ao fornecedor (texto + tags)
  MsgPadraoEmailFornecedor: string = "";

  //Flag para habilitar grupos de compra para aprovação
  UtilizaGrupoComprasAprovacao: boolean = false;
}
