import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { LancamentoFinanceiro, ListaEnumTipoPagamento } from 'src/app/classes/LancamentoFinanceiro';
import { Movimento } from 'src/app/classes/Movimento';
import { Natureza } from 'src/app/classes/Natureza';
import { Projeto } from 'src/app/classes/Projeto';
import { Rateio } from 'src/app/classes/Rateio';
import { Transacao } from 'src/app/classes/Transacao';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { SelectArvoreComponent } from 'src/app/components/shared-farm/select-arvore/select-arvore.component';
import { LancamentoFinanceiroService } from 'src/app/services/lancamento-financeiro.service';
import { RateioService } from 'src/app/services/rateio.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-movimento-confirmacao-forms',
  templateUrl: './movimento-confirmacao-forms.component.html',
  styleUrls: ['./movimento-confirmacao-forms.component.scss']
})
export class MovimentoConfirmacaoFormsComponent implements OnInit {

  constructor(private rateioService: RateioService, private lancamentoFinanceiroService: LancamentoFinanceiroService, private utils: UtilsService) { }
  @Input() centrosResultados: CentroDeResultados[] = [];
  @Input() projetos: Projeto[] = [];
  @Input() naturezas: Natureza[] = [];
  @Input() movimento: Movimento;
  @ViewChild('rateiosGrid', { static: false }) rateiosGrid;

  @ViewChild("SelectProjetos", {static: false})
  SelectProjetos: SelectArvoreComponent;
  @ViewChild("SelectCentroResultados", {static: false})
  SelectCentroResultados: SelectArvoreComponent;
  @ViewChild("SelectNatureza", {static: false})
  SelectNatureza: SelectArvoreComponent;

  isSubmit: boolean;
  isSubmitFinanceiro: boolean;
  carregando = false;
  transacao = new Transacao();
  rateio = new Rateio();
  colDefs: ColDef[] = [];
  colDefsFinanceiro: ColDef[] = [];
  showForm = false;
  rateioSelecionado: Rateio;
  rateiosSelecionados: Rateio[] = [];
  showFormFinanceiro = false;
  lancamentoFinanceiro = new LancamentoFinanceiro();
  lancamentoFinanceiroSelecionado: LancamentoFinanceiro;
  lancamentosFinanceiroSelecionados: LancamentoFinanceiro[] = [];
  listaEnumTipoPagamento = ListaEnumTipoPagamento;

  // saldoRatear: number = 0;

  ngOnInit() {
    this.movimento.Rateios = [];
    this.colDefs = [
      {headerName: 'Natureza', field: 'GetNarueza', flex: 1},
      {headerName: 'Centro Resultados', field: 'GetCentroResultado', flex: 1},
      {headerName: 'Projeto', field: 'GetProjeto', flex: 1},
      {field: 'Percentual', flex: 1, valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.Percentual)}%`},
      {field: 'Valor', cellStyle: {'text-align': 'right'}, flex: 1, valueFormatter: (params) => `R$ ${this.utils.formatarDecimais(params.data.Valor)}`},
    ];
    this.colDefsFinanceiro = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'Parcela', flex: 1},
      {headerName: 'Vencimento', field: 'DataVencimento', flex: 1, valueFormatter: (params) => `${this.utils.formatarData(params.data.DataVencimento)}`},
      {headerName: 'Valor', field: 'ValorOriginal', cellStyle: {'text-align': 'right'}, flex: 1, valueFormatter: (params) => `R$ ${this.utils.formatarDecimais(params.data.ValorOriginal)}`},
      {headerName: 'Tipo Pagamento', field: 'TipoPagamento', flex: 1, valueFormatter: (params) => `Boleto`},
      {field: 'Adiantamento', flex: 1, valueFormatter: (params) => params.data.Adiantamento ? 'Sim' : 'Não'},

    ]
  }
  naturezaDesc(id: number): string {
    if (id != null || id != undefined) {
      let natureza = this.naturezas.find(p => p.Id == id)
      return natureza != undefined ? natureza.Descricao : id.toString();
    }
    return id + '';
  }
  projetoDesc(id: number): string {
    if (id != null || id != undefined) {
      let projeto = this.projetos.find(p => p.Id == id)
      return projeto != undefined ? projeto.Descricao : id.toString();
    }
    return id + '';
  }
  centroResultadoDesc(id: number): string {
    if (id != null || id != undefined) {
      let centrosResultado = this.centrosResultados.find(p => p.Id == id)
      return centrosResultado != undefined ? centrosResultado.Descricao : id.toString();
    }
    return id + '';
  }
  atualizarTransacao(transacao: Transacao){
    this.transacao = transacao;
  }
  atualizarIsSubmit(isSubmit: boolean){
    this.isSubmit = isSubmit;
  }
  novo(){
    this.rateio = new Rateio();
    this.showForm = true;

    this.SelectProjetos.descricao = null;
    this.SelectCentroResultados.descricao = null;
    this.SelectNatureza.descricao = null;
  }
  changeType(event){
    this.showForm = !this.showForm;
  }
  salvar(){
    this.carregando = true;
    this.rateio.OrigemId = this.movimento.Id;
    if(this.rateio.Id > 0)
      this.rateioService.put(this.rateio.Id, this.rateio)
      .subscribe(res => {
        this.utils.handleSuccess('Rateio salvo!');
        const index = this.movimento.Rateios.findIndex(p => p.Id == this.rateio.Id);
        this.movimento.Rateios = this.movimento.Rateios.filter(r => r.Id != this.rateio.Id)
        if (index != -1) {
          this.movimento.Rateios.splice(index, 1, res);
          // this.rateiosGrid.setRowData(this.movimento.Rateios);
        }
        this.showForm = false;
      }, err => this.utils.getErro(err));
    else
      this.rateioService.post(this.rateio)
      .subscribe(res => {
        this.utils.handleSuccess('Rateio cadastrado!');
        this.movimento.Rateios.push(res)
        this.showForm = false;
      }, err => this.utils.getErro(err));

    this.attTotal();
    this.carregando = false;
  }
  selectionChanged(event) {
    this.rateiosSelecionados = event;
  }
  cellDoubleClicked(event) {
    this.rateio = this.rateioSelecionado;
    this.showForm = true;
  }
  cellClicked({ data }) {
    this.rateioSelecionado = data;
  }

  selecionarNatureza(id: number) {
    this.rateio.NaturezaId = id;
  }

  selecionarCentroResultados(id: number) {
    this.rateio.CentroResultadoId = id;
  }

  selecionarProjeto(id: number) {
    this.rateio.ProjetoId = id;
  }

  attTotal(){
    this.movimento.ValorTotalRateio = 'R$ '+ this.utils.formatarDecimais(this.movimento.Rateios.reduce((acc, curr) => acc + curr.Valor, 0));
    const totalRateado = this.movimento.Rateios.reduce((acc, curr) => acc + curr.Percentual, 0);
    this.movimento.PercentualTotalRateio = this.utils.formatarDecimais(totalRateado) + '%';
    this.movimento.PercentualNaoRateado =  this.utils.formatarDecimais(100 - totalRateado) + `%`;
  }


}


