import { AgrupapmentoProdutos } from './../../../classes/cotacao/AgrupapmentoProdutos';
import { fazendaCotacaoService } from './../../../services/cotacao/fazendaCotacao.Service';
import { FazendaService } from './../../../services/fazenda.service';
import { culturaCotacaoService } from './../../../services/cotacao/culturaCotacao.Service';
import { FornecedorCotacao } from './../../../classes/cotacao/FornecedorCotacao';
import { FornecedorCotacaoService } from './../../../services/cotacao/FornecedorCotacao.service';
import { itensCotacaoService } from './../../../services/cotacao/itensCotacao.Service';
import { itensCotacao } from './../../../classes/cotacao/itensCotacao';
import { ProdutoService } from './../../../services/produto.service';
import { Produto } from './../../../classes/Produto';
import { safraCotacaoService } from './../../../services/cotacao/safraCotacao.Service';
import { CulturaService } from './../../../services/cultura.service';
import { SafraService } from './../../../services/safra.service';
import { UsuarioService } from './../../../services/usuario.service';
import { Usuario } from './../../../classes/Usuario';
import { CotacaoInclude } from './../../../classes/cotacao/CotacaoInclude';
import { Cotacao } from './../../../classes/cotacao/cotacao';
import { FornecedoresService } from './../../../services/cotacao/Fornecedores.service';
import { Fornecedores } from './../../../classes/cotacao/Fornecedores';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { Safra } from 'src/app/classes/Safra';
import { Cultura } from 'src/app/classes/Cultura';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Fazenda } from 'src/app/classes/Fazenda';
import { cotacaoService } from "src/app/services/cotacao/cotacaoService";
import { EnumModulo } from 'src/app/classes/GrupoTransacao';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { EnumStatusAprovacao, ListaStatusMovimento, Movimento } from 'src/app/classes/Movimento';
import { UtilsService } from 'src/app/utils/utils.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { Transacao } from 'src/app/classes/Transacao';
import { DadosTabela } from 'src/app/classes/cotacao/DadosTabela';
import { PessoaFormComponent } from '../../cadastros/pessoa/pessoa-form/pessoa-form.component';
import { EnumEventoAprovacao } from 'src/app/classes/RegistrosAprovacoes';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-cotacao',
  templateUrl: './cotacao.component.html',
  styleUrls: ['./cotacao.component.scss']
})
export class CotacaoComponent implements OnInit {
  @Output() inserindoEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('Modal', { static: false })
  ModalSafra: UiModalComponent;
  @ViewChild('Modal2', { static: false })
  ModalProduto: UiModalComponent;
  @ViewChild('Modal3', { static: false })
  ModalFornecedor: UiModalComponent;
  @ViewChild('ModalFornecedorCadastro', { static: false })
  ModalFornecedorCadastro: UiModalComponent;
  @ViewChild('ModalSafraCadastro', { static: false })
  ModalSafraCadastro: UiModalComponent;
  @ViewChild('AnexosCotacaoModal', { static: false })
  AnexosCotacaoModal: UiModalComponent;
  @ViewChild(PessoaFormComponent, { static: false }) pessoaFormComponent: PessoaFormComponent;

  transacoes: Transacao;
  cotacaoSelecionada!: Cotacao;
  cotacoesSelecionadas: Cotacao[] = [];
  modulo = EnumModulo.Compras;
  filtro = new FiltroTela();
  listaStatus = ListaStatusMovimento;
  minHeight = 0;
  showGrid = true;
  acoes = ['Editar',
    'Donload Mapa Cotação'
  ];
  colDefs: ColDef[] = [];

  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;
  id: number = 0;

  Safras: Safra[] = [];
  Culturas: Cultura[] = [];
  Fazendas: Fazenda[] = [];
  Produtos: Produto[] = [];
  Fornecedores: Fornecedores[] = [];
  AuxFornecedores: Fornecedores[] = [];
  itensCotacao: itensCotacao;
  itensCotacaoPaginado: itensCotacao[];
  agrupamento: AgrupapmentoProdutos[] = [];
  agrupamento2: AgrupapmentoProdutos[] = [];
  agrupamento3: AgrupapmentoProdutos[] = [];
  agrupamento4: AgrupapmentoProdutos[] = [];
  cotacao: CotacaoInclude;
  cotacoes: Cotacao[] = [];
  auxCotacoes: Cotacao[] = [];
  listaResponsavel: Usuario[] = [];
  FornecedorCotacao: FornecedorCotacao[] = [];
  FornecedorCotacaoCadastro: FornecedorCotacao;
  //filtros
  safrasFiltro: number[] = [];
  fazendasFiltro: number[] = [];
  culturasFiltro: number[] = [];
  fornecedoresFiltro: number[] = [];
  filtroFornecedor: boolean = false;
  localBusca: string = "";
  //paginação produtos
  pagina: number = 1;
  totalItens: number = 0;
  tamanhoPagina: number = 5;
  //Expansão
  Detalhe: number = 0;
  mostrarDetalhe: boolean = false;
  tabela: DadosTabela = new DadosTabela();
  carregadoResultado = false;
  similares: boolean[] = [];
  //Aprovação
  isSubmitAprovacao = false;
  obsModalAprovacao: string;
  idAprovacao: number = 0;

  // tradução
  ERRO: string = 'Erro!';
  FEITO: string = 'Feito!';
  COTACAO_ALTERADA: string = 'Cotação Alterada!';
  COTACAO_CADASTRADA: string = 'Cotação cadastrada!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  COTACAO_DELETADA: string = 'Cotação deletada!';
  DELETAR_ESSE_ITEM: string = 'Deletar esse item?';
  ITEM_DELETADO: string = 'Item Deletado!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_TAMBEM_SERAO_DELETADOS_TODAS_AS_SAFRAS_PERTENCENTES_A_ESSA_FAZENDA: string = 'Se deletar, esta ação não poderá ser desfeita! também serão deletados todas as safras pertencentes a essa fazenda';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_O_ITEM_SERA_EXCLUIDO_DA_COTACAO_TODAS_SAFRAS_AO_QUAL_ELA_PERTENCE: string = 'Se deletar, esta ação não poderá ser desfeita! O item será excluído da cotação todas safras ao qual ela pertence';
  PRODUTO_ALTERADO: string = 'Produto Alterado!';
  PRODUTO_CADASTRADO: string = 'Produto cadastrado!';
  COTACAO_INICIADA: string = 'Cotação Iniciada';
  AVANCAR_DE_ETAPA: string = 'Avançar de Etapa?';
  SE_AVANCAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR: string = 'Se avançar agora, não poderá voltar a etapa anterior!';
  COTACAO_AVANCOU_DE_ETAPA: string = 'Cotação avançou de etapa';
  FINALIZAR_COTACAO: string = 'Finalizar Cotação?';
  SE_FINALIZAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR: string = 'Se finalizar agora, não poderá voltar a etapa anterior!';
  SE_CANCELAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR: string = 'Se cancelar agora, não poderá voltar a etapa anterior!';
  COTACAO_CANCELADA: string = 'Cotação cancelada';
  DELETAR_ESSA_FAZENDA: string = 'Deletar essa fazenda?';
  CANCELAR_COTACAO: string = 'Cancelar Cotação?';
  EXCLUIR_FORNECEDOR: string = 'Excluir Fornecedor';
  FORNECEDORES_CADASTRADOS: string = 'Fornecedores Cadastrados';
  FORNECEDORES: string = 'Fornecedores';


  constructor(private FornecedoresService: FornecedoresService,
    private transacaoService: TransacaoService,
    private UsuarioService: UsuarioService,
    private CotacaoService: cotacaoService,
    private SafraService: SafraService,
    private CulturaService: CulturaService,
    private safraCotacaoService: safraCotacaoService,
    private ProdutoService: ProdutoService,
    private itensCotacaoService: itensCotacaoService,
    private FornecedorCotacaoService: FornecedorCotacaoService,
    private fazendaCotacaoService: fazendaCotacaoService,
    private FazendaService: FazendaService,
    private culturaCotacaoService: culturaCotacaoService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private utils: UtilsService,
    private toasty: CustomToastyService,
    private translate: TranslateService) { }

  ngOnInit() {
    let usuario = this.storageService.getUsuario();
    if(usuario.PermissaoUsuario.AcessoCotacao == false || usuario.PermissaoUsuario == null)
    {
      Swal.fire('Erro!', 'Usuário não tem acesso a cotações', 'error');
      this.router.navigate(["/"]);
    }
    this.carregando = true;
    this.route.params.subscribe(res => {
      this.id = res.idCotacao;
      if (this.id > 0) {
        this.selecionar();
        this.obterArrays();
        return;
      }
    });
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.cotacao = new CotacaoInclude();
    this.itensCotacao = new itensCotacao();
    if (this.id > 0) {
      this.router.navigate(["/cotacao/cadastro/" + this.id]);
      this.selecionar();
      this.obterArrays();
      return;
    }

    if (this.router.url.search('cadastro') > 0) {
      this.obterArrays();
      this.novo();
      return;
    }

    else {
      console.log("listar");
      this.inserindo = false;
      this.listar();
    }
    this.carregando = false;
  }

  obterArrays() {
    // this.SafraService.getSafras().subscribe(
    //   res => {
    //     this.Safras = res;
    //     console.log(res);
    //   });
    this.UsuarioService.getCompradores().subscribe(
      res => {
        this.listaResponsavel = res;

        console.log(res);
      });
    // this.ProdutoService.getProdutos().subscribe(
    //   res => {
    //     this.Produtos = res;

    //     console.log(res);
    //   });
    // this.FazendaService.getFazendas().subscribe(
    //   res => {
    //     this.Fazendas = res;

    //     console.log(res);
    //   });
    this.FornecedoresService.get().subscribe(
      res => {
        this.Fornecedores = res;
        this.AuxFornecedores = res;

        console.log(res);
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    // this.CulturaService.getCulturas().subscribe(ret => {
    //   this.Culturas = ret;

    //   console.log(ret);
    // });
    this.transacaoService.getTransacoes().subscribe(ret => {
      this.transacoes = ret;

      console.log(ret);
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
    this.carregando = false;
  }

  listarProdutos(idGrupo: number, tipo: number) {
    this.carregando = true;
    this.CotacaoService.getResultadoComTabela(this.id).subscribe(res => {
      this.tabela = res.DadosTabela;
      this.carregadoResultado = true;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });

    this.itensCotacaoService.getPaginado(this.id, this.pagina, this.tamanhoPagina, tipo, idGrupo, this.textoBusca).subscribe(
      res => {
        this.itensCotacaoPaginado = res.Lista;
        this.totalItens = res.TotalItens;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }
  loadPage(page: number) {
    this.pagina = page;
    this.listarProdutos(this.Detalhe, 1);
  }
  loadPageTotal(page: number) {
    this.pagina = page;
    this.listarProdutos(this.Detalhe, 0);
  }
  loadPageSafra(page: number) {
    this.pagina = page;
    this.listarProdutos(this.Detalhe, 2);
  }
  loadPageFazenda(page: number) {
    this.pagina = page;
    this.listarProdutos(this.Detalhe, 3);
  }
  loadPageCultura(page: number) {
    this.pagina = page;
    this.listarProdutos(this.Detalhe, 4);
  }
  listar() {
    let status;
    this.route.queryParamMap.subscribe(
      (params) => (status = params.get('status'))
    );

    if (status != null) {
      this.carregando = true;
      console.log("getPorStatus");
      this.CotacaoService.getPorStatus(status).subscribe(
        res => {
          this.cotacoes = res;
          this.auxCotacoes = res;
          this.carregando = false;
          this.configurar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else {
      console.log("getTodos");
      this.carregando = true;
      this.CotacaoService.get().subscribe(
        res => {
          this.cotacoes = res;
          this.auxCotacoes = res;
          this.carregando = false;
          this.configurar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }


  }

  filtrar(filtro: FiltroTela) {
    console.log(filtro);
  }

  TrocaSimilar(i: number) {
    if (this.similares[i])
      this.similares[i] = false;
    else
      this.similares[i] = true;

    this.carregando = true;
    this.CotacaoService.getResultadoComTabelaSimilares(this.similares, this.id).subscribe(ret => {
      this.cotacao = ret;
      this.carregando = false;
    },
      err => {
        Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  novo() {
    this.cotacao = new CotacaoInclude();
    this.cotacao.Status = 0;
    this.cotacao.getStatus = "Cadastrado";
    this.id = null;
    this.inserindo = true;
    this.inserindoEvent.emit(true);
  }

  editar(cotacao: Cotacao) {
    this.id = cotacao.Id;
    this.router.navigate(["/cotacao/cadastro/" + this.id]);
    this.CotacaoService.getPorIdInclude(this.id).subscribe(
      res => {
        this.cotacao = res;

        if (this.cotacao.Status == 2) {
          this.router.navigate(["/cotacao/resumo/" + this.cotacao.Id]);
        }
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    this.FornecedorCotacaoService.getArray(this.id).subscribe(ret => {
      this.fornecedoresFiltro = ret;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
    this.FornecedorCotacaoService.getPorCotacao(this.id).subscribe(
      res => {
        this.FornecedorCotacao = res;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    this.itensCotacaoService.getGrupos(this.id, 1).subscribe(
      res => {
        this.agrupamento = res;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    this.itensCotacaoService.getGrupos(this.id, 2).subscribe(
      res => {
        this.agrupamento2 = res;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    this.itensCotacaoService.getGrupos(this.id, 3).subscribe(
      res => {
        this.agrupamento3 = res;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    this.itensCotacaoService.getGrupos(this.id, 4).subscribe(
      res => {
        this.agrupamento4 = res;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    this.culturaCotacaoService.getArray(this.id).subscribe(ret => {
      this.culturasFiltro = ret;
    }),
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    };
    this.fazendaCotacaoService.getArray(this.id).subscribe(ret => {
      this.fazendasFiltro = ret;
      this.SafraService.getSafrasPorFazendas(this.fazendasFiltro).subscribe(ret => {
        this.Safras = ret;
        this.safraCotacaoService.getArray(this.id).subscribe(ret => {
          this.safrasFiltro = ret;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      });
    });
    this.exibeItensTotais();
    this.inserindo = false;
    this.inserindoEvent.emit(true);
  }

  selecionar() {
    this.carregando = true;
    this.cotacao = new CotacaoInclude();
    this.CotacaoService.getResultadoComTabela(this.id).subscribe(res => {
      this.cotacao = res;
      this.FornecedorCotacaoService.getArray(this.id).subscribe(ret => {

        this.fornecedoresFiltro = ret;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
      this.FornecedorCotacaoService.getPorCotacao(this.id).subscribe(
        res => {
          this.FornecedorCotacao = res;
          console.log('====================> ' + this.FornecedorCotacao.values())
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      this.culturaCotacaoService.getArray(this.id).subscribe(ret => {
        this.culturasFiltro = ret;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
      this.fazendaCotacaoService.getArray(this.id).subscribe(ret => {
        this.fazendasFiltro = ret;
        this.SafraService.getSafrasPorFazendas(this.fazendasFiltro).subscribe(ret => {
          this.Safras = ret;
          this.safraCotacaoService.getArray(this.id).subscribe(ret => {
            this.safrasFiltro = ret;
            this.exibeItensTotais();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      });
      this.carregando = false;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }

  save(form: any) {
    this.carregando = true;
    this.configurarTraducao();
    var cotacaoTemp: Cotacao = new Cotacao();
    cotacaoTemp.Id = this.cotacao.Id;
    cotacaoTemp.CompradorResponsavelId = this.cotacao.CompradorResponsavelId;
    cotacaoTemp.Titulo = this.cotacao.Titulo;
    cotacaoTemp.TipoCotacao = this.cotacao.TipoCotacao;
    cotacaoTemp.DataCriacao = this.cotacao.DataCriacao;
    cotacaoTemp.DataLimite = this.cotacao.DataLimite;
    cotacaoTemp.CompradorResponsavelId = this.cotacao.CompradorResponsavelId;
    cotacaoTemp.Status = this.cotacao.Status;
    cotacaoTemp.UsarSimilar = this.cotacao.UsarSimilar;
    cotacaoTemp.PercentualDescontoDesejado = this.cotacao.PercentualDescontoDesejado;
    cotacaoTemp.PercentualEliminacao = this.cotacao.PercentualEliminacao;
    cotacaoTemp.QuantidadeItens = this.cotacao.QuantidadeItens;
    cotacaoTemp.EmpresaId = this.cotacao.EmpresaId;
    cotacaoTemp.DataCadastro = this.cotacao.DataCadastro;
    cotacaoTemp.DataAlteracao = this.cotacao.DataAlteracao;
    cotacaoTemp.UsuarioCadastroId = this.cotacao.UsuarioCadastroId;
    cotacaoTemp.UsuarioAlteracaoId = this.cotacao.UsuarioAlteracaoId;
    cotacaoTemp.FlagExcluido = this.cotacao.FlagExcluido;
    cotacaoTemp.TransacaoDestinoId = this.cotacao.TransacaoDestinoId;
    cotacaoTemp.MotivmentoOrigemId = this.cotacao.MotivmentoOrigemId;
    if (this.cotacao.Id > 0)
      this.CotacaoService.put(this.cotacao.Id, cotacaoTemp).subscribe(
        res => {
          let idTemp = res.Id;
          if (form != null) {
            this.toasty.show(this.FEITO, this.COTACAO_ALTERADA, 'success');
            this.carregando = false;
            this.listar();
            this.cancelar();
          }
          else
            this.id = idTemp;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.CotacaoService.post(cotacaoTemp).subscribe(
        res => {
          let idTemp = res.Id;
          if (form != null) {
            this.toasty.show(this.FEITO, this.COTACAO_CADASTRADA, 'success');
            this.carregando = false;
            this.listar();
            this.cancelar();
          }
          else
            this.id = idTemp;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  excluir() {
    if (this.cotacaoSelecionada.Status != 8) {
      Swal.fire({
        title: 'Deletar ' + this.cotacaoSelecionada.Titulo + '?',
        text: 'Se deletar, esta ação não poderá ser desfeita!',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim',
      }).then((willDelete) => {
        if (willDelete.dismiss) { }
        else {
          this.carregando = true;
          this.CotacaoService.delete(this.cotacaoSelecionada.Id).subscribe(
            res => {
              this.toasty.show('Feito!', 'Cotação deletada!', 'success');
              this.carregando = false;
              this.listar();
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
    }
    if (this.cotacaoSelecionada.Status == 8) {
      this.router.navigate(["/cotacao/OrdemCompra/" + this.cotacaoSelecionada.Id]);
    }
  }

  excluirSafra(id: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR_ESSE_ITEM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.safraCotacaoService.delete(id).subscribe(
          res => {
            this.safraCotacaoService.getArray(this.id).subscribe(ret => {
              this.safrasFiltro = ret;
            });
            this.toasty.show(this.FEITO, this.ITEM_DELETADO, 'success');
            this.carregando = false;
            this.selecionar();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  excluirFazenda(id: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR_ESSE_ITEM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_TAMBEM_SERAO_DELETADOS_TODAS_AS_SAFRAS_PERTENCENTES_A_ESSA_FAZENDA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.fazendaCotacaoService.delete(id).subscribe(
          res => {
            this.fazendaCotacaoService.getArray(this.id).subscribe(ret => {
              this.fazendasFiltro = ret;
            });
            this.toasty.show(this.FEITO, this.ITEM_DELETADO, 'success');
            this.carregando = false;
            this.selecionar();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  excluirCultura(id: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR_ESSE_ITEM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.culturaCotacaoService.delete(id).subscribe(
          res => {
            this.culturaCotacaoService.getArray(this.id).subscribe(ret => {
              this.culturasFiltro = ret;
            });
            this.toasty.show(this.FEITO, this.ITEM_DELETADO, 'success');
            this.carregando = false;
            this.selecionar();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  excluirItemCotacao(id: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR_ESSE_ITEM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_O_ITEM_SERA_EXCLUIDO_DA_COTACAO_TODAS_SAFRAS_AO_QUAL_ELA_PERTENCE,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.itensCotacaoService.delete(id).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.ITEM_DELETADO, 'success');
            this.carregando = false;
            this.selecionar();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  fecharModalSafra() {
    this.ModalSafra.hide();
  }
  incluirSafra() {
    this.save(null);
    this.ModalSafra.show();
  }
  incluirProduto() {
    this.itensCotacao = new itensCotacao();
    this.ModalProduto.show();
  }
  fecharModalProdutos() {
    this.ModalProduto.hide();
  }
  incluirFornecedor() {
    this.ModalFornecedor.show();
  }
  fecharModalFornecedores() {
    this.ModalFornecedor.hide();
  }

  excluirFornecedor(id: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR_ESSE_ITEM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.FornecedorCotacaoService.delete(id).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.ITEM_DELETADO, 'success');
            this.carregando = false;
            this.selecionar();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  selectionarSafra() {
    this.carregando = true;
    this.safraCotacaoService.sincronizar(this.safrasFiltro, this.id).subscribe(ret => {
      this.safrasFiltro = ret;
      this.selecionar();
    });
    this.carregando = false;
  }

  selectionarFazenda() {
    this.carregando = true;
    this.configurarTraducao();
    this.fazendaCotacaoService.getArray(this.id).subscribe(ret => {
      let temp = ret;
      let obj = this.fazendasFiltro;
      if (temp.length > this.fazendasFiltro.length) {
        Swal.fire({
          title: this.DELETAR_ESSA_FAZENDA,
          text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_TAMBEM_SERAO_DELETADOS_TODAS_AS_SAFRAS_PERTENCENTES_A_ESSA_FAZENDA,
          type: 'warning',
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: this.CANCELAR,
          confirmButtonText: this.SIM,
        }).then((willDelete) => {
          if (willDelete.dismiss) {
            this.fazendasFiltro = temp;
          } else {
            this.fazendaCotacaoService.sincronizar(obj, this.id).subscribe(ret => {
              this.fazendasFiltro = ret;
              this.selecionar();
            },
              err => {
                this.utils.getErro(err);
              });
          }
        });
      }
      else {
        this.fazendaCotacaoService.sincronizar(obj, this.id).subscribe(ret => {
          this.fazendasFiltro = ret;
          this.selecionar();
        },
          err => {
            this.utils.getErro(err);
          });
      }
    });
    this.carregando = false;
  }

  selectionarCultura() {
    this.carregando = true;
    this.culturaCotacaoService.sincronizar(this.culturasFiltro, this.id).subscribe(ret => {
      this.culturasFiltro = ret;
      this.selecionar();
    });
    this.carregando = false;
  }
  selectionarFornecedor() {
    this.carregando = true;
    this.FornecedorCotacaoService.sincronizar(this.fornecedoresFiltro, this.id).subscribe(ret => {
      this.fornecedoresFiltro = ret;
      this.selecionar();
    });
    this.carregando = false;
  }

  selecionarFornecedor(id: number) {
    this.FornecedorCotacaoService.getPorFornecedor(this.id, id).subscribe(ret => {
      let temp = ret;
      if (ret.length > 0) {
        this.FornecedorCotacaoService.deletePorFornecedor(id, this.id).subscribe(ret => {
          temp = ret;
        });
      }
      else {
        this.FornecedorCotacaoCadastro = new FornecedorCotacao();
        this.FornecedorCotacaoCadastro.CotacaoId = this.id;
        this.FornecedorCotacaoCadastro.FornecedorId = id;
        this.FornecedorCotacaoService.post(this.FornecedorCotacaoCadastro).subscribe(ret => {
          temp = ret;
        });
      }
      this.selecionar();
    });
  }

  validarCheckBox(idFor: number): boolean {
    let retorno: boolean = false;
    var result = this.fornecedoresFiltro.filter(function (el) {
      return el == idFor;
    });
    if (result.length > 0) {
      retorno = true;
    }
    return retorno;
  }

  filtrarFornecedor() {
    this.Fornecedores = this.AuxFornecedores.filter(x => (!this.localBusca || (x.RazaoSocial.toLowerCase().indexOf(this.localBusca.toLowerCase()) > -1) ||
      (x.NomeFantasia.toLowerCase().indexOf(this.localBusca.toLowerCase()) > -1) ||
      (x.CpfCnpj.toLowerCase().indexOf(this.localBusca.toLowerCase()) > -1)));
  }

  editarProduto(id: number) {
    var result = this.cotacao.ItensCotacao.filter(function (el) {
      return el.Id == id;
    });
    for (var elemento of result) {
      var index = this.cotacao.ItensCotacao.indexOf(elemento);
      this.itensCotacao = this.cotacao.ItensCotacao[index];
    }

    this.ModalProduto.show();
  }

  salvarItemCotacao(form: any) {
    this.carregando = true;
    this.configurarTraducao();

    if (this.itensCotacao.Id > 0)
      this.itensCotacaoService.put(this.itensCotacao.Id, this.itensCotacao).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.PRODUTO_ALTERADO, 'success');
          this.carregando = false;
          this.selecionar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.itensCotacaoService.post(this.itensCotacao).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.PRODUTO_CADASTRADO, 'success');
          this.carregando = false;
          this.selecionar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    this.ModalProduto.hide();
  }

  iniciarCotacao() {
    this.configurarTraducao();
    if (this.cotacao.Id > 0) {
      this.carregando = true;
      this.CotacaoService.iniciarCotacao(this.id).subscribe(
        res => {
          Swal.fire(this.FEITO, this.COTACAO_INICIADA, 'success');
          this.listar();
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
  }

  mudarEtapa() {
    this.configurarTraducao();
    Swal.fire({
      title: this.AVANCAR_DE_ETAPA,
      text: this.SE_AVANCAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        if (this.cotacao.Id > 0) {
          this.carregando = true;
          this.CotacaoService.mudarEtapaCotacao(this.id).subscribe(
            res => {
              Swal.fire(this.FEITO, this.COTACAO_AVANCOU_DE_ETAPA, 'success');
              this.listar();
              this.carregando = false;
              this.cancelar();
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      }
    });
  }

  analiseCotacao() {
    this.configurarTraducao();
    Swal.fire({
      title: this.FINALIZAR_COTACAO,
      text: this.SE_FINALIZAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        if (this.cotacao.Id > 0) {
          this.carregando = true;
          this.CotacaoService.finalizarCotacao(this.id).subscribe(
            res => {
              Swal.fire(this.FEITO, this.COTACAO_AVANCOU_DE_ETAPA, 'success');
              this.listar();
              this.carregando = false;
              this.cancelar();
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      }
    });
  }
  cancelarCotacao() {
    this.configurarTraducao();
    Swal.fire({
      title: this.CANCELAR_COTACAO,
      text: this.SE_CANCELAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        if (this.cotacao.Id > 0) {
          this.carregando = true;
          this.CotacaoService.Cancelar(this.id).subscribe(
            res => {
              Swal.fire(this.FEITO, this.COTACAO_CANCELADA, 'success');
              this.listar();
              this.carregando = false;
              this.cancelar();
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      }
    });
  }
  addFornecedorCadastro() {
    this.ModalFornecedor.hide();
    this.ModalFornecedorCadastro.show();
  }

  onFornecedorCriadoEvento(){
    this.pessoaFormComponent.id = 0;
    this.pessoaFormComponent.selecionarPessoa();
    this.fecharFornecedorCadastro();
  }

  fecharFornecedorCadastro() {
    this.carregando = true;
    this.FornecedoresService.get().subscribe(
      res => {
        this.Fornecedores = res;
        this.AuxFornecedores = res;
        this.carregando = false;
      });
    this.ModalFornecedorCadastro.hide();
    this.incluirFornecedor();
  }
  addSafraCadastro() {
    this.ModalSafra.hide();
    this.ModalSafraCadastro.show();
  }
  fecharSafraCadastro() {
    this.carregando = true;
    this.fazendaCotacaoService.getArray(this.id).subscribe(ret => {
      this.fazendasFiltro = ret;
      this.SafraService.getSafrasPorFazendas(this.fazendasFiltro).subscribe(ret => {
        this.Safras = ret;
        this.safraCotacaoService.getArray(this.id).subscribe(ret => {
          this.safrasFiltro = ret;
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
    this.ModalSafraCadastro.hide();
    this.incluirSafra();
  }
  exibeItensGrupoProdutos(id: number) {
    this.Detalhe = id;
    if (id == 0)
      this.itensCotacaoPaginado = [];
    else {
      this.listarProdutos(id, 1);
    }
  }
  exibeItensGrupoSafra(id: number) {
    this.Detalhe = id;
    if (id == 0)
      this.itensCotacaoPaginado = [];
    else {
      this.listarProdutos(id, 2);
    }
  }
  exibeItensGrupoFazenda(id: number) {
    this.Detalhe = id;
    if (id == 0)
      this.itensCotacaoPaginado = [];
    else {
      this.listarProdutos(id, 3);
    }
  }
  exibeItensGrupoCultura(id: number) {
    this.Detalhe = id;
    if (id == 0)
      this.itensCotacaoPaginado = [];
    else {
      this.listarProdutos(id, 4);
    }
  }
  exibeItensTotais() {
    this.Detalhe = 0;
    this.listarProdutos(0, 0);
  }
  valores() {
    this.router.navigate(["/cotacao/resumo/" + this.cotacao.Id]);
  }
  fetchNews(evt: any) {
    this.exibeItensTotais();
  }

  //
  toggleGrid() {
    this.showGrid = !this.showGrid;
  }

  atualiazr() { }

  cancelar() {
    this.listar();
    this.inserindo = false;
    this.id = null;
    this.router.navigate(["/cotacao"]);
    this.inserindoEvent.emit(false);
  }

  clonar() { }

  imprimir() { }

  buscar(textoBusca: string) {
    this.cotacoes = this.auxCotacoes.filter(x =>
      x.Titulo.toUpperCase().includes(textoBusca.toUpperCase())
    );
  }

  acao(acao: string) {
    switch (acao) {
      case "Editar":
        this.editar(this.cotacaoSelecionada);
        break;
      case 'Donload Mapa Cotação':
        this.downloadMapaCotacao();
        break;
      default:
        break
    }
  }

  downloadMapaCotacao() {
    if (this.cotacoesSelecionadas.length <= 0) {
      this.utils.handleWarning("Selecione uma Cotação para prosseguir!");
      return;
    }
    if (this.cotacoesSelecionadas.length > 1) {
      this.utils.handleWarning("Selecione apenas uma Cotação para prosseguir!");
      return;
    }
    this.CotacaoService.getMapaCotacaoDownload(this.cotacoesSelecionadas[0].Id);
  }

  files() {
    if (!this.cotacaoSelecionada) {
      this.utils.handleWarning("Selecione uma Cotação para prosseguir!");
      return;
    }

    this.AnexosCotacaoModal.show();
  }

  onCellClicked(row) {
    this.cotacaoSelecionada = row.data;
  }

  cellDoubleClicked(event) {
    this.editar(this.cotacaoSelecionada);
  }

  selectionChanged(event) {
    this.cotacoesSelecionadas = event;
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Título', field: 'Titulo', flex: 1 },
      { headerName: 'Comprador Responsável', field: 'CompradorResponsavel.Nome', flex: 1 },
      { headerName: 'Valor Atual da Cotação', field: 'ValorTotalCotacaoString', flex: 1 },
      { headerName: 'Data de Abertura', field: 'DataCriacao', flex: 1, valueFormatter: (params) => this.utils.formatarData(params.data.DataCadastro) },
      { headerName: 'Data Limite', field: 'DataLimite', flex: 1, valueFormatter: (params) => this.utils.formatarData(params.data.DataLimite) },
      { headerName: 'Status', field: 'getStatus', flex: 1, cellRenderer: (params) => this.statusBadge(params.data.getStatus) },
    ]
  }

  statusBadge(status: string) {
    switch (status) {
      case "Pendente":
        return '<span class="text-warning text-uppercase">Pendente</span>'
      case "Em Cotação":
        return '<span class="text-warning text-uppercase">Em Cotacão</span>'
      case "Em Análise":
        return '<span class="text-primary text-uppercase">Em Análise</span>'
      case "Finalizado":
        return '<span class="text-success text-uppercase">Finalizado</span>'
      case "Rodada1":
        return '<span class="text-dark text-uppercase">Rodada 1</span>'
      case "Rodada2":
        return '<span class="text-dark text-uppercase">Rodada 2</span>'
      case "Rodada3":
        return '<span class="text-dark text-uppercase">Rodada 3</span>'
      case "Cancelado":
        return '<span class="text-danger text-uppercase">Cancelado</span>'
      case "Ordem Compra Gerado":
        return '<span class="text-success text-uppercase">Ordem de Compra Gerada</span>'
      case "Em Aprovação":
        return '<span class="text-primary text-uppercase">Em Aprovação</span>'
      case "Reprovado em Analise":
        return '<span class="text-danger text-uppercase">Reprovado em Analise</span>'
      case "Revisar":
        return '<span class="text-primary text-uppercase">Warning</span>'
      default:
        break
    }
  }

  statusAprovacaoBadge(status: EnumEventoAprovacao) {
    // switch (status) {
    //   case EnumEventoAprovacao.Aprovado:
    //     return '<span class="text-success text-uppercase">Aprovado</span>'
    //   case EnumEventoAprovacao.Reprovado:
    //     return '<span class="text-danger text-uppercase">Reprovado</span>'
    //   case EnumEventoAprovacao.Cadastrado:
    //     return '<span class="text-secondary text-uppercase">Cadastrado</span>'
    //   case EnumEventoAprovacao.EmAprovacao:
    //     return '<span class="text-primary text-uppercase">Em aprovação</span>'
    //   case EnumEventoAprovacao.Revisar:
    //     return '<span class="text-primary text-uppercase">Revisão</span>'
    //   default:
    //     break
    // }
  }

  carregarSlider(item: DadosTabela) {
    this.Detalhe = item.Id;
    this.tabela = item;
    this.carregando = true;
    this.mostrarDetalhe = true;
    this.carregando = false;
  }
  descarregarSlider() {
    this.mostrarDetalhe = false;
    this.Detalhe = 0;
  }
  onKeyDown(event) {
    if (event.keyCode === 27) {
      this.descarregarSlider();
    }
  }

  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.FEITO = this.translate.instant('FEITO');
    this.COTACAO_ALTERADA = this.translate.instant('COTACAO_ALTERADA');
    this.COTACAO_CADASTRADA = this.translate.instant('COTACAO_CADASTRADA');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.COTACAO_DELETADA = this.translate.instant('COTACAO_DELETADA');
    this.DELETAR_ESSE_ITEM = this.translate.instant('DELETAR_ESSE_ITEM');
    this.ITEM_DELETADO = this.translate.instant('ITEM_DELETADO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_TAMBEM_SERAO_DELETADOS_TODAS_AS_SAFRAS_PERTENCENTES_A_ESSA_FAZENDA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_TAMBEM_SERAO_DELETADOS_TODAS_AS_SAFRAS_PERTENCENTES_A_ESSA_FAZENDA');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_O_ITEM_SERA_EXCLUIDO_DA_COTACAO_TODAS_SAFRAS_AO_QUAL_ELA_PERTENCE = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_O_ITEM_SERA_EXCLUIDO_DA_COTACAO_TODAS_SAFRAS_AO_QUAL_ELA_PERTENCE');
    this.PRODUTO_ALTERADO = this.translate.instant('PRODUTO_ALTERADO');
    this.PRODUTO_CADASTRADO = this.translate.instant('PRODUTO_CADASTRADO');
    this.COTACAO_INICIADA = this.translate.instant('COTACAO_INICIADA');
    this.AVANCAR_DE_ETAPA = this.translate.instant('AVANCAR_DE_ETAPA');
    this.SE_AVANCAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR = this.translate.instant('SE_AVANCAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR');
    this.COTACAO_AVANCOU_DE_ETAPA = this.translate.instant('COTACAO_AVANCOU_DE_ETAPA');
    this.FINALIZAR_COTACAO = this.translate.instant('FINALIZAR_COTACAO');
    this.SE_FINALIZAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR = this.translate.instant('SE_FINALIZAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR');
    this.SE_CANCELAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR = this.translate.instant('SE_CANCELAR_AGORA_NAO_PODERA_VOLTAR_A_ETAPA_ANTERIOR');
    this.COTACAO_CANCELADA = this.translate.instant('COTACAO_CANCELADA');
    this.CANCELAR_COTACAO = this.translate.instant('CANCELAR_COTACAO');
    this.DELETAR_ESSA_FAZENDA = this.translate.instant('DELETAR_ESSA_FAZENDA');
  }
}
