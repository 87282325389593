import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Sistema } from 'src/app/classes/Sistema';
import { SistemaService } from 'src/app/services/sistema.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-sistema-forms',
  templateUrl: './sistema-forms.component.html',
  styleUrls: ['./sistema-forms.component.scss']
})
export class SistemaFormsComponent implements OnInit {

  @Output() fecharFormsEvent = new EventEmitter<any>();
  @Input() id:number;
  @Input() inserindo: boolean;
  sistema: Sistema;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;

  constructor(
    private sistemaService: SistemaService,
    private translate: TranslateService,
    private utils : UtilsService) {}

  ngOnInit() {
    this.sistema = new Sistema();
    if(this.inserindo)
      this.sistema = new Sistema()
    else
      this.selecionarSistema();
  }

  selecionarSistema() {
    this.carregando = true;
    this.sistemaService.ObterPorId(this.id).subscribe(res =>{
      this.sistema = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.sistema.Id > 0){
      this.sistemaService.Atualizar(this.sistema.Id, this.sistema).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('SISTEMA_ALTERADO'));
          this.carregando = false;
          this.fecharFormsEvent.emit();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
    else{
      this.sistemaService.Inserir(this.sistema).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('SISTEMA_CADASTRADO'));
          this.carregando = false;
          this.fecharFormsEvent.emit();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
  }

  cancelar(){
    this.fecharFormsEvent.emit();
  }

  excluirItem(i: number) {
    if(this.sistema.Itens[i].Id) {
      this.sistemaService.ExcluirItem(this.sistema.Itens[i].Id).subscribe(res => {
        this.selecionarSistema();
      })
    }
    else
    {
      this.sistema.Itens.splice(i, 1);
    }
  }

}