import { FornecedoresService } from './../../../services/cotacao/Fornecedores.service';
import { FornecedorSenha } from './../../../classes/cotacao/FornecedorSenha';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-alterar-senha-fornecedor',
  templateUrl: './alterar-senha-fornecedor.component.html',
  styleUrls: ['./alterar-senha-fornecedor.component.scss']
})
export class AlterarSenhaFornecedorComponent implements OnInit {

  FornecedorSenha:FornecedorSenha = new FornecedorSenha();
  erros: string;
  carregando: boolean = false;
  constructor(private FornecedoresService: FornecedoresService, 
    private storageService: StorageService, 
    private router: Router) { }


  ngOnInit() {
  }
  login() {
    this.carregando = true;
    this.FornecedoresService.alterarSenha(this.FornecedorSenha).subscribe(
      data => {
        this.carregando = false;
        this.router.navigate(["/loginfornecedor"]);
      },
      ops => {
        this.carregando = false;
        this.erros = ops.error;
        if (ops.Message)
          this.erros = ops.Message;
      }
    );
  }

  onKeyDown(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
}
