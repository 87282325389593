import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DiagnosesService } from '../../../services/diagnoses/diagnoses.service';
import { Empresa } from 'src/app/classes/Empresa';
import { UtilsService } from 'src/app/utils/utils.service';
import { Fazenda } from 'src/app/classes/Fazenda';
import { Safra } from 'src/app/classes/Safra';
import { BuscarAreas } from 'src/app/classes/tipos-diagnose/BuscarAreas';

@Component({
  selector: 'app-menu-filtro',
  templateUrl: './menu-filtro.component.html',
  styleUrls: ['./menu-filtro.component.scss']
})
export class MenuFiltroComponent implements OnInit {

  @Output() opcaoSelecionada = new EventEmitter<string>();
  @Output() opcaoSelecionadaInvalida = new EventEmitter<string>();
  @Output() buscarAreas = new EventEmitter<BuscarAreas>();

  listaEmpresas: Empresa[] = [];
  empresaSelecionada: Empresa = null;
  listaFazendas: Fazenda[] = [];
  fazendaSelecionada: Fazenda = null;
  listaSafra: Safra[] = [];
  safraSelecionada: Safra = null;

  constructor(
    private diagnosesService: DiagnosesService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.CarregarEmpresas();
  }

  selecionarEmpresa(event) {
    if (event === null) {
      this.fazendaSelecionada = null;
      this.safraSelecionada = null;
      this.listaFazendas = [];
      this.listaSafra = [];
      return;
    }
    this.empresaSelecionada = event;
    this.fazendaSelecionada = null;
    this.CarregarFazendas();
    this.opcaoSelecionada.emit('Empresa');
  }

  selecionarFazenda(event) {
    if (event === null) {
      this.fazendaSelecionada = null;
      this.safraSelecionada = null;
      this.listaSafra = [];
      return;
    }
    this.fazendaSelecionada = event;
    this.safraSelecionada = null;
    this.CarregarSafras();
    this.opcaoSelecionada.emit('Fazenda');
  }

  selecionarSafra(event) {
    if (event === null) {
      this.safraSelecionada = null;
      return;
    }
    this.safraSelecionada = event;
    this.opcaoSelecionada.emit('Safra');
    this.buscarAreas.emit({
      EmpresaId: this.empresaSelecionada.Id,
      FazendaId: this.fazendaSelecionada.Id,
      SafraId: this.safraSelecionada.Id
    })
  }

  private CarregarEmpresas() {
    this.diagnosesService.ObterEmpresas().subscribe(
      res => this.listaEmpresas = res,
      error => this.utilsService.getErro(error)
    );
  }

  private CarregarFazendas() {
    this.diagnosesService.ObterFazendasPorEmpresaId(this.empresaSelecionada.Id).subscribe(
      res => this.listaFazendas = res,
      error => {
        this.opcaoSelecionadaInvalida.emit('Empresa');
        this.listaFazendas = [];
      }
    );
  }

  private CarregarSafras() {
    this.diagnosesService
      .ObterSafrasPorEmpresaIdEFazendaId(this.empresaSelecionada.Id, this.fazendaSelecionada.Id)
      .subscribe(
        res => {
          this.listaSafra = res;
          if (res.length == 0) {
            this.opcaoSelecionadaInvalida.emit('Fazenda');
          }
        },
        error => this.utilsService.handleError(error)
      );
  }
}
