import { Component, OnInit, ViewChild } from '@angular/core';
import { Safra } from 'src/app/classes/Safra';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { AnoSafraService } from 'src/app/services/ano-safra.service';
import { Planejamento } from 'src/app/classes/Planejamento';
import { Area } from 'src/app/classes/Area';
import { Fazenda } from 'src/app/classes/Fazenda';
import { FazendaService } from 'src/app/services/fazenda.service';
import { Cultivar } from 'src/app/classes/Cultivar';
import { CultivarService } from 'src/app/services/cultivar.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { OrdemServico, EnumStatusOS } from 'src/app/classes/OrdemServico';
import { SafraService } from 'src/app/services/safra.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { Funcionario } from 'src/app/classes/Funcionario';
import { Atividade } from 'src/app/classes/Atividade';
import { TipoAplicacao } from 'src/app/classes/TipoAplicacao';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { AtividadeService } from 'src/app/services/atividade.service';
import { TipoAplicacaoService } from 'src/app/services/tipo-aplicacao.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-ordem-servico',
  templateUrl: './ordem-servico.component.html',
  styleUrls: ['./ordem-servico.component.scss']
})
export class OrdemServicoComponent implements OnInit {
  carregando: boolean = false;
  anosSafras: AnoSafra[];
  fazendas: Fazenda[];

  planejamentoSelecionado: Planejamento;
  ordensServicos: OrdemServico[];
  auxOrdensServico: OrdemServico[];

  fazenda: Fazenda;

  cultivares: Cultivar[];
  funcionarios: Funcionario[];
  atividades: Atividade[];
  tiposAplicacao: TipoAplicacao[];

  filtroStatus: number = 0;
  filtroArea: number = 0;
  filtroOrigem: number = 0;

  filtroStatusAtivo: string = "Por status";
  getFiltroStatusAtivo(): string {
    return this.translate.instant('POR_STATUS');
  }
  getFiltroAreaAtivo(): string {
    return this.translate.instant('POR_AREA');
  }
  getFiltroOrigemAtivo(): string {

    return this.translate.instant('POR_ORIGEM');
  }
  filtroAreaAtivo: string = "Por área";
  filtroOrigemAtivo: string = "Por origem";

  public isSubmit: boolean;

  idOSAbrir: number = 0;

  // tradução
  POR_STATUS: string = 'Por status';
  POR_AREA: string = 'Por área';
  POR_ORIGEM: string = 'Por origem';
  NAO_CONCLUIDAS: string = 'Não Concluídas';
  ABERTA: string = 'Aberta';
  AGUARDANDO_LIBERACAO: string = 'Aguardando Liberação';
  PENDENTE: string = 'Pendente';
  PARCIALMENTE_CONCLUIDA: string = 'Parcialmente Concluída';
  CONCLUIDA: string = 'Concluída';
  CANCELADA: string = 'Cancelada';
  PLANEJAMENTO: string = 'Planejamento';
  ORDEM_DE_SERVICO: string = 'Ordem de serviço';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  NAO_ASSOCIADO: string = 'Não associado';

  configurarTraducao() {
    this.POR_STATUS = this.translate.instant('POR_STATUS');
    this.POR_AREA = this.translate.instant('POR_AREA');
    this.POR_ORIGEM = this.translate.instant('POR_ORIGEM');
    this.NAO_CONCLUIDAS = this.translate.instant('NAO_CONCLUIDAS');
    this.ABERTA = this.translate.instant('ABERTA');
    this.AGUARDANDO_LIBERACAO = this.translate.instant('AGUARDANDO_LIBERACAO');
    this.PENDENTE = this.translate.instant('PENDENTE');
    this.PARCIALMENTE_CONCLUIDA = this.translate.instant('PARCIALMENTE_CONCLUIDA');
    this.CONCLUIDA = this.translate.instant('CONCLUIDA');
    this.CANCELADA = this.translate.instant('CANCELADA');
    this.PLANEJAMENTO = this.translate.instant('PLANEJAMENTO');
    this.ORDEM_DE_SERVICO = this.translate.instant('ORDEM_DE_SERVICO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.NAO_ASSOCIADO = this.translate.instant('NAO_ASSOCIADO');
  }

  constructor(private anoSafraService: AnoSafraService,
              private fazendaService: FazendaService,
              public ordemServicoService: OrdemServicoService,
              private funcionarioService: FuncionarioService,
              private atividadeService: AtividadeService,
              private http: HttpClient,
              private tipoAplicacaoService: TipoAplicacaoService,
              private cultivarService: CultivarService,
              private empresaService: EmpresaService,
              private router: Router,
              private utils: UtilsService,
              private translate: TranslateService, 
              private storageService: StorageService) {
  }

  ngOnInit() {

    this.anoSafraService.getAnosSafras().subscribe(ret =>{
      this.anosSafras = ret;
    });

    this.fazendaService.getFazendas().subscribe(ret =>{
      this.fazendas = ret;
    });

    this.cultivarService.getCultivares().subscribe(ret =>{
      this.cultivares = ret;
    });

    this.funcionarioService.getFuncionarios().subscribe(ret =>{
      this.funcionarios = ret;
    });

    this.atividadeService.getAtividades().subscribe(ret =>{
      this.atividades = ret;
    });

    this.tipoAplicacaoService.getTiposAplicacao().subscribe(ret =>{
      this.tiposAplicacao = ret;
    });
  }

  selecionarPlanejamento(planejamento: Planejamento){
    this.planejamentoSelecionado = planejamento;
    this.listarOrdensServico();
  }

  selecionarFazenda(fazenda: Fazenda){
    this.fazenda = fazenda;
  }

  listarOrdensServico(){
    if (this.planejamentoSelecionado == null || !this.planejamentoSelecionado.Id)
      return;

    this.carregando = true;
    this.ordemServicoService.getOrdensServicoPorPlanejamento(this.planejamentoSelecionado.Id).subscribe(
      res => {
        this.ordensServicos = res;
        this.auxOrdensServico = this.ordensServicos;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  filtrar(){
    this.configurarTraducao();
    this.filtroStatusAtivo = this.POR_STATUS;

    switch (this.filtroStatus) {
      case -1: this.filtroStatusAtivo = this.NAO_CONCLUIDAS; break;
      case 1: this.filtroStatusAtivo = this.ABERTA; break;
      case 2: this.filtroStatusAtivo = this.AGUARDANDO_LIBERACAO; break;
      case 3: this.filtroStatusAtivo = this.PENDENTE; break;
      case 4: this.filtroStatusAtivo = this.PARCIALMENTE_CONCLUIDA; break;
      case 5: this.filtroStatusAtivo = this.CONCLUIDA; break;
      case 6: this.filtroStatusAtivo = this.CANCELADA; break;
    }

    this.filtroAreaAtivo = this.POR_AREA;

    /*if (this.filtroArea > 0)
      this.filtroAreaAtivo = this.getArea(this.filtroArea).Descricao;*/

    this.filtroOrigemAtivo = this.POR_ORIGEM;

    if (this.filtroOrigem == 2)
      this.filtroOrigemAtivo = this.PLANEJAMENTO;
    else if (this.filtroOrigem == 1)
      this.filtroOrigemAtivo = this.ORDEM_DE_SERVICO;

    this.ordensServicos = this.auxOrdensServico.filter(x =>
      (this.filtroStatus ==  x.Status || this.filtroStatus == 0 ||
        (this.filtroStatus == -1 && x.Status != 5 && x.Status != 6)) &&
      //(x.AreaId == this.filtroArea || this.filtroArea == 0) &&
      (this.filtroOrigem == 0 || this.filtroOrigem == x.Tipo)
    );
  }

  filtrarStatus(status){
    this.filtroStatus = status;
    this.filtrar();
  }

  filtrarArea(areaId){
    this.filtroArea = areaId;
    this.filtrar();
  }

  filtrarOrigem(origem){
    this.filtroOrigem = origem;
    this.filtrar();
  }

  limparFiltros(){
    this.filtroStatus = 0;
    this.filtroArea = 0;
    this.filtroOrigem = 0;
    this.filtrar();
  }

  /*getArea(id: number) : Area {
    if (!this.areas)
      return new Area();

    let area = this.areas.filter(x =>
      x.Id==id
    );

    return area.length > 0 ? area[0] : new Area();
  }

  getAreaPorFazenda() : Area[] {
    if (!this.areas || !this.fazenda)
      return [];

    let area = this.areas.filter(x =>
      x.FazendaId==this.fazenda.Id
    );

    return area;
  }*/

  deletar(id: number, index: number, nome: string){
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR + ' ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.ordemServicoService.deleteOrdemServico(id).subscribe(
          res => {
            this.ordensServicos.splice(index, 1);
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
        }
      });
  }

  cancelar(id: number, index: number, nome: string){
    this.configurarTraducao();
    Swal.fire({
      title: this.CANCELAR + ' ' + nome + '?',
      text: '',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          let ordemServico = this.ordensServicos.filter(o => o.Id == id)[0];
          let statusAtual = ordemServico.Status;
          ordemServico.Status = EnumStatusOS.Cancelada;
          this.ordemServicoService.putOrdemServico(id, ordemServico).subscribe(
          res => {

          },
          err => {
            this.utils.getErro(err);
            ordemServico.Status = statusAtual;
            this.carregando = false;
          });
        }
      });
  }

  getFuncionario(id: number) : Funcionario {
    this.configurarTraducao();
    if (!this.funcionarios)
      return new Funcionario();

      if (!id){
        let novo = new Funcionario();
        novo.Nome = this.NAO_ASSOCIADO;
        return novo;
      }

    let funcionarios = this.funcionarios.filter(x =>
      x.Id==id
    );

    return funcionarios.length > 0 ? funcionarios[0] : new Funcionario();
  }

  getAtividade(id: number) : Atividade {
    if (!this.atividades)
      return new Atividade();

    let atividades = this.atividades.filter(x =>
      x.Id==id
    );

    return atividades.length > 0 ? atividades[0] : new Atividade();
  }

  getTipoAplicacao(id: number) : TipoAplicacao {
    this.configurarTraducao();
    if (!this.tiposAplicacao)
      return new TipoAplicacao();

    if (!id){
      let novo = new TipoAplicacao();
      novo.Descricao = this.NAO_ASSOCIADO;
      return novo;
    }

    let tiposAplicacao = this.tiposAplicacao.filter(x =>
      x.Id==id
    );

    return tiposAplicacao.length > 0 ? tiposAplicacao[0] : new TipoAplicacao();
  }

  imprimir(id: number){
    this.carregando = true;
    this.http.get(`${environment.apiURL}/ordemServico/gerarPDF/${this.empresaService.getEmpresaSelecionada().Id}/${id}/${this.storageService.getUsuario().Id}`,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    }); 
  }

  abrirOS(event){
    if (!event || event.keyCode == 13) {
      this.ordemServicoService.getOrdemServico(this.idOSAbrir).subscribe(res =>{
        this.router.navigate(['/ordem-servico/ordem-servico/'+this.idOSAbrir]);
      },
      ex =>{
        this.utils.getErro(ex);
      })
    }
  }

}
