import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoTransacao } from 'src/app/classes/GrupoTransacao';
import { MovimentoSerie } from 'src/app/classes/MovimentoSerie';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
import { ListaBaseNumeracao } from 'src/app/classes/enum/EnumBaseNumeracao';
import { EnumEfeitoFinanceiro, ListaEfeitoFinanceiro } from 'src/app/classes/enum/EnumEfeitoFinanceiro';
import { ListaEfeitoSobreEstoque } from 'src/app/classes/enum/EnumEfeitoSobreEstoque';
import { ListaLocalEstoque } from 'src/app/classes/enum/EnumLocalEstoque';
import { ListaTipoEmissao } from 'src/app/classes/enum/EnumTipoEmissao';
import { ListaTipoFinanceiro } from 'src/app/classes/enum/EnumTipoFinanceiro';
import { ListaTipoItem } from 'src/app/classes/enum/EnumTipoItem';
import { ListaTipoNumeracao } from 'src/app/classes/enum/EnumTipoNumeracao';
import { DestinoTransacaoService } from 'src/app/services/destino-transacao.service';
import { GrupoTransacaoService } from 'src/app/services/grupo-transacao.service';
import { MovimentoSerieService } from 'src/app/services/movimento-serie.service';
import { StorageService } from 'src/app/services/storage.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transacao-forms',
  templateUrl: './transacao-forms.component.html',
  styleUrls: ['./transacao-forms.component.scss']
})
export class TransacaoFormsComponent implements OnInit {

  constructor(private grupoTransacaoService: GrupoTransacaoService,
    private usuarioService: UsuarioService,
    private transacaoService: TransacaoService,
    private route: ActivatedRoute,
    private destinoService: DestinoTransacaoService,
    private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private movimentoSerieService: MovimentoSerieService,
    private utils: UtilsService) {
    this.transacao;
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  @ViewChild('ModalDestino', { static: false })
  ModalDestino: UiModalComponent;

  destinos: Transacao[] = new Array<Transacao>();
  idsDestino: number[] = new Array<number>();
  series: MovimentoSerie[] = [];
  transacoesDestinos: Transacao[] = new Array<Transacao>();


  id: number = 0;
  numeros: number[];
  transacao: Transacao;
  transacoes: Transacao[];
  auxTransacoes: Transacao[];
  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;
  isNota: boolean = false;
  isNumeroValido: boolean = true;
  isFormValid: boolean = false;
  numeroTransacao: number = 0;

  tipoMovimento: number = 0;

  listaTipoItem = ListaTipoItem;
  listaBaseNumeracao = ListaBaseNumeracao;
  listaTipoNumeracao = ListaTipoNumeracao;
  listaLocalEstoque = ListaLocalEstoque;
  listaEfeitoFinanceiro = ListaEfeitoFinanceiro;
  listaTipoFinanceiro = ListaTipoFinanceiro;
  listaTipoEmissao = ListaTipoEmissao;
  listaEfeitoSobreEstoque = ListaEfeitoSobreEstoque;
  listaUsuario: Usuario[];
  listaGrupoTransacao: GrupoTransacao[];
  showCampoSerie = false;

  ngOnInit() {
    this.obterNumeros();
    this.listarUsuarios();
    this.listarGrupoTransacoes();
    this.listarSeries();
  }

  selecionarTransacao() {
    this.carregando = true;
    this.transacaoService.getTransacao(this.id).subscribe(res => {
      this.transacao = res;
      this.numeroTransacao = this.transacao.Numero;
      this.formIsValid();
      this.listarDestinos(this.id);
      this.showCampoSerie = this.transacao.NumeracaoAuto;
      if (!this.transacao.Numero || this.transacao.Numero <= 0) {
        this.toasty.show('Erro', 'Número deve ser preenchido', 'error');
      }
      this.carregando = false;
    },
      ex => {
        this.utils.getErro(ex);
        this.carregando = false;
      })
  }

  obterNumeros() {
    this.carregando = true;
    this.transacaoService.getNova().subscribe(res => {
      this.numeros = res;
      if (this.router.url.search("inserir") > 0)
        this.novaTransacao();
      else
        this.selecionarTransacao();
      this.carregando = false;
    },
      ex => {
        this.utils.getErro(ex);
        this.carregando = false;
      })
  }

  novaTransacao() {
    this.transacao = new Transacao();
    this.transacao.Numero = this.numeros[0] + 1;
  }

  validarNumero() {
    const n = parseInt(this.transacao.Numero.toString());
    if (!this.numeros.includes(n))
      this.isNumeroValido = true;
    else if (this.numeros.includes(n) && this.numeroTransacao === n)
      this.isNumeroValido = true;
    else
      this.isNumeroValido = false;
  }

  listarSeries() {
    this.movimentoSerieService.getList()
      .subscribe(res => {
        this.series = res;
      }, err => this.utils.getErro(err));
  }
  save() {
    if (!this.formIsValid()) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    if (!this.transacao.Numero || this.transacao.Numero <= 0) {
      this.toasty.show('Erro', 'Número deve ser preenchido', 'warning');
      return;
    }
    if (this.transacao.Id > 0) {
      this.idsDestino = new Array<number>();
      this.destinos.forEach(destino => this.idsDestino.push(destino.Id));
      this.transacao.DestinosIds = this.idsDestino;

      this.transacaoService.putTransacao(this.transacao.Id, this.transacao).subscribe(
        res => {
          this.toasty.show('Feito!', 'Transação alterada!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/transacao']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else {
      this.idsDestino = new Array<number>();
      this.destinos.forEach(destino => this.idsDestino.push(destino.Id));
      this.transacao.DestinosIds = this.idsDestino;

      this.transacaoService.postTransacao(this.transacao).subscribe(
        res => {
          this.toasty.show('Feito!', 'Transação cadastrada!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/transacao']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
  }
  onNumeracaoAutoChange(event) {
    this.transacao.TipoNumeracao = null;
    this.showCampoSerie = event;
    if (!event) {
      this.transacao.MovimentoSerieId = null;
    }

  }
  cancelar() {
    this.router.navigate(['/cadastros/transacao']);
  }

  listarUsuarios() {
    this.usuarioService.getUsuarios().subscribe({
      next: (res) => {
        this.listaUsuario = res;
      },
      error: (err) => {
        this.utils.getErro(err);
      },
    });
  }

  listarGrupoTransacoes() {
    this.grupoTransacaoService.getList().subscribe({
      next: (res) => {
        this.listaGrupoTransacao = res
      },
      error: (err) => {
        this.utils.getErro(err);
      },
    });
  }

  selecionarGrupoTransacao(grupo: GrupoTransacao) {


    if (grupo.TipoMovimento === 5 || grupo.TipoMovimento === 6) {
      this.transacao.CompradorPadraoId = null;
      this.transacao.AprovadorPadraoId = null;
      this.transacao.ExigeCotacao = false;
      this.transacao.SugereFornecedoresHistoricoCompras = false;
      this.transacao.ExigePrazoEntrega = false;
      this.transacao.RequerAprovacao = false;
      this.transacao.BuscaProdutosSafra = false;
      this.transacao.QuantidadeFornecedoresSugerir = false;
      this.transacao.PrazoPadraoAtendimento = false;
      this.transacao.UsaAlcadaAprovacao = false;
      this.isNota = true;
    }
    else if (grupo.TipoMovimento === 11)
    {
      this.tipoMovimento = 11;
      this.transacao.NumeracaoAuto = true;
      this.transacao.TipoNumeracao = 2;
    }
    else
    {
      this.isNota = false;
    }
  }

  formIsValid() {
    var erros = new Array<string>();
    // Cabeçalho
    if (!this.transacao.GrupoTransacaoId) {
      erros.push('Grupo Transação')
    }
    if (!this.transacao.Descricao) {
      erros.push('Descrição')
    }
    if (this.transacao.Numero === null) {
      erros.push('Número')
    }

    // Configurações Gerais
    if (!this.transacao.TipoItem) {
      erros.push('TipoItem')
    }
    if (!this.transacao.BaseNumeracao) {
      erros.push('Base Numeração')
    }
    if (this.transacao.NumeracaoAuto && !this.transacao.TipoNumeracao) {
      erros.push('Tipo Numeração')
    }
    if (this.transacao.PrestacaoServico && !this.pedidoDeComprasOuNotaDeEntrada()) {
      erros.push('PrestacaoServico')
    }

    // Compras
    if (!this.isNota) {
      if (this.transacao.RequerAprovacao) {
        if (!this.transacao.CompradorPadraoId) {
          erros.push('Comprador Padrão')
        }
        if (!this.transacao.AprovadorPadraoId) {
          erros.push('Aprovador Padrão')
        }
      }
    }

    // Estoque
    if (!this.transacao.LocalEstoque) {
      erros.push('Local Estoque')
    }
    if (!this.transacao.EfeitoSobreEstoque && this.transacao.EfeitoSobreEstoque !== 0) {
      erros.push('Efeito sobre estoque')
    }

    // Financeiro
    if (!(this.transacao.EfeitoFinanceiro === 0)) {
      if (!this.transacao.TipoFinanceiro) {
        erros.push('Tipo Financeiro')
      }
      if (!this.transacao.HistoricoPadraoFinanceiro) {
        erros.push('Histórico Padrão Financeiro')
      }
      // if (this.transacao.GerarFinanceiroAtravesDoItemDeMovimento && !(this.transacao.EfeitoFinanceiro === EnumEfeitoFinanceiro.Provisao && this.transacao.PrestacaoServico)) {
      //   erros.push('GerarFinanceiroAtravesDoItemDeMovimento')
      // }
    }

    // Contabilidade
    if (this.transacao.ExigeComplementoContabilidade) {
      if (!this.transacao.ComplementoContabilidade) {
        erros.push('Complemento Contabilidade')
      }
    }

    // NFe
    if (this.transacao.EnviaNfe) {
      if (!this.transacao.ModeloDocumento) {
        erros.push('Modelo Documento')
      }
      if (!this.transacao.TransacaoDenegadaId) {
        erros.push('Transação Denegada')
      }
      if (!this.transacao.TransacaoPendenteRetornoId) {
        erros.push('Transação Pendente Retorno')
      }
      if (!this.transacao.TipoEmissao) {
        erros.push('Tipo Emissão')
      }
      if (!this.transacao.CodigoModeloImpressaoDANFE) {
        erros.push('Código Modelo Impressão DANFE')
      }
    }

    if (erros.length > 0) {
      this.isFormValid = false;
      //Swal.fire('Erro!', 'Os campos abaixo devem ser preenchidos corretamente: ' + erros.join("; "), 'error');
      return false;
    }
    this.isFormValid = true;
    return true;
  }


  onChangeEfeitoFinanceiro() {
    if (!this.transacao.EfeitoFinanceiro) {
      this.transacao.TipoFinanceiro = null;
      this.transacao.ExigeAutorizacaoPagamento = false;
      this.transacao.ExigeAutorizaBaixa = false;
      this.transacao.UsaDataPrevisaoBaixa = false;
      this.transacao.ExigeHistoricoFinanceiro = false;
      this.transacao.HistoricoPadraoFinanceiro = null;
    }

    if(this.transacao.EfeitoFinanceiro != 1)
      this.transacao.GerarFinanceiroAtravesDoItemDeMovimento = false;
  }

  onChangeEnviaNfe() {
    if (!this.transacao.TipoFinanceiro) {
      this.transacao.ModeloDocumento = null;
      this.transacao.TransacaoDenegadaId = null;
      this.transacao.TransacaoPendenteRetornoId = null;
      this.transacao.TipoEmissao = null;
      this.transacao.CodigoModeloImpressaoDANFE = null;
    }
  }

  //Destinos
  abrirModalDestinos() {
    this.ModalDestino.show();
  }

  fecharModalDestinos() {
    this.ModalDestino.hide();
  }

  selecionarDestino(destino: Transacao) {
    let index = this.transacoesDestinos.indexOf(destino);
    this.transacoesDestinos.splice(index, 1);
    this.destinos.push(destino);
    this.formIsValid();
  }

  removerDestino(destino: Transacao) {

    Swal.fire({
      title: 'Deletar ' + destino.Descricao + '?',
      text: 'Ao confirmar está ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {

        let indexDestino = this.destinos.indexOf(destino);
        this.destinos.splice(indexDestino, 1);
        this.transacoesDestinos.push(destino);
        if (this.id > 0) {
          this.carregando = true;
          this.idsDestino = new Array<number>();
          this.destinos.forEach(destino => this.idsDestino.push(destino.Id));
          this.transacao.DestinosIds = this.idsDestino;

          this.transacaoService.putTransacao(this.transacao.Id, this.transacao).subscribe(
            res => {
              this.toasty.show('Feito!', 'Transação destino excluido!', 'success');
              this.carregando = false;
              this.router.navigate(['/cadastros/transacao/' + this.id]);
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      }
    });

  }

  listarDestinos(id: number) {
    this.carregando = true;
    this.destinoService.getDestinosPorIdOrigem(id).subscribe(
      res => {
        this.destinos = res;
        this.transacaoService.getTransacoes().subscribe(
          res => {
            console.debug(res)
            this.transacoesDestinos = res;
            this.filtrarTransacoesDestino();
          });
      },
      err => this.destinos = new Array<Transacao>());
  }

  filtrarTransacoesDestino() {
    this.transacoesDestinos.forEach((item, index) => {
      if (item.Id == this.id) this.transacoesDestinos.splice(index, 1);
    });

    let ids = this.destinos.map(p => p.Id);
    this.transacoesDestinos = this.transacoesDestinos.filter(p => !ids.includes(p.Id));
  }

  pedidoDeComprasOuNotaDeEntrada(): boolean {
    const grupoTransacao = this.listaGrupoTransacao.filter(c => c.Id === this.transacao.GrupoTransacaoId)[0].Descricao;
    return ["Pedido de Compras", "Nota de Entrada"].includes(grupoTransacao);
  }

}
