import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { StorageService } from './storage.service';
import { Fazenda } from '../classes/Fazenda';
import { ManutencaoFrota } from '../classes/gestao-lite/ManutencaoFrota';
import { ChecklistManutencaoFrota } from '../classes/gestao-lite/ChecklistManutencaoFrota';

@Injectable({
  providedIn: 'root'
})
export class FrotaService {

  constructor(private http: HttpClient) { }

  getManutencao(): Observable<any>{
    return this.http.get(`${environment.apiURL}/manutencaoFrota`)
  }

  getManutencaoId(id: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/manutencaoFrota/${id}`)
  }
  
  postManutencao(manutencao: ManutencaoFrota): Observable<any>{
    return this.http.post(`${environment.apiURL}/manutencaoFrota`, manutencao);
  }

  putManutencao(id: number, manutencao: ManutencaoFrota): Observable<any>{
    return this.http.put(`${environment.apiURL}/manutencaoFrota/${id}`, manutencao);
  }

  deleteManutencao(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/manutencaoFrota/${id}`);
  }

  getStatusManutencaoComData(status:number, tipo:number, origem:number, tipoIntervalo:number, equipamento:number, dataInicial:string, dataFinal:string): Observable<any>{
    return this.http.get(`${environment.apiURL}/ManutencaoFrota/BuscaPendencia/${status}/${tipo}/${origem}/${tipoIntervalo}/${equipamento}/${dataInicial}/${dataFinal}`);
  }

  getStatusManutencao(status:number, tipo:number, origem:number, tipoIntervalo:number, equipamento:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ManutencaoFrota/BuscaPendencia/${status}/${tipo}/${origem}/${tipoIntervalo}/${equipamento}`);
  }

  //Safra
  postSafra(safra: any): Observable<any>{
    return this.http.post(`${environment.apiURL}/safraManutencaoFrota`, safra);
  }

  getManutencaoEquipamento(equipamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ManutencaoFrota/listar/${equipamentoId}`)
  }

  indicarChecklist(manutencaoFrotaId: number, checklist:ChecklistManutencaoFrota): Observable<any>{
    return this.http.post(`${environment.apiURL}/indicarChecklist/${manutencaoFrotaId}`, checklist);
  }
}
