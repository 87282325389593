import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MovimentoSafra } from '../movimento-item-safra/movimento-item-safra.component';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { Planejamento, PlanejamentoDetalhe } from 'src/app/classes/Planejamento';
import { UtilsService } from 'src/app/utils/utils.service';
import { AtividadePlanejamento, enumProcessoPlanejamento } from 'src/app/classes/AtividadePlanejamento';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { AtividadePlanejamentoService } from 'src/app/services/atividade-planejamento.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { Etapa } from 'src/app/classes/Etapa';
import { prependOnceListener } from 'process';


@Component({
  selector: 'app-modal-planejamento',
  templateUrl: './modal-planejamento.component.html',
  styleUrls: ['./modal-planejamento.component.scss']
})
export class ModalPlanejamentoComponent implements OnInit {

  @ViewChild('modal', { static: true }) modal
  @Input() safraMovimento: MovimentoSafra;

  planejamentos: Planejamento[] = [];
  processosPlanejamento = enumProcessoPlanejamento;
  carregando = false;
  atividadesPlanejamento: AtividadePlanejamento[] = [];
  etapas: Etapa[] = [];
  planejamentoDetalhes: PlanejamentoDetalhe[] = []
  constructor(
     private planejamentoService: PlanejamentoService,
     private atividadePlanejamentoService: AtividadePlanejamentoService,
     private etapaService: EtapaService,
     private atividadeService: EtapaService,
     private utils: UtilsService
  ) { }

  ngOnInit() {
    this.safraMovimento = new MovimentoSafra();
  }
  listarPlanejamentos(){
    this.carregando = true;
    this.planejamentoService.getListarPlanosAgronomico(this.safraMovimento.SafraId)
    .subscribe(res => {
      this.planejamentos = res;
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }
  toggleCollapse(item: Planejamento){
    this.planejamentoDetalhes = [];
    item.Collapse = !item.Collapse;
    if(item.Collapse){
      this.planejamentos.forEach(p => {
        if(p.Id == item.Id)
          return;

        p.Collapse = false;
      })
      this.planejamentoService.getPlanejamentosDetalhe(item.Id)
      .subscribe(res => {
        this.planejamentoDetalhes = res;
      })
    }
  }
    listarProcessos(){
    return this.planejamentoDetalhes.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.ProcessoId === obj.ProcessoId);
      if (!existingObj) {
        acc.push(obj);
      }
      return acc;
    }, []);
  }
  listarEtapas(processoId){
    return this.planejamentoDetalhes.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.EtapaId === obj.EtapaId);
      if (!existingObj) {
        acc.push(obj);
      }
      return acc;
    }, []).filter(p => p.ProcessoId == processoId);
  }
  listarAtividades(etapaId){
    return this.planejamentoDetalhes.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.AtividadeId === obj.AtividadeId);
      if (!existingObj) {
        acc.push(obj);
      }
      return acc;
    }, []).filter(p => p.EtapaId == etapaId);
  }
  listarProdutos(atividadeId){
    const lista = this.planejamentoDetalhes.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.ProdutoId === obj.ProdutoId);
      if (!existingObj) {
        acc.push(obj);
      }
      return acc;
    }, []);
    const retorno = lista.filter(p => p.AtividadeId == atividadeId);
    return retorno;
  }
  distinctAtividadesPlanejamento(){
    return this.planejamentoDetalhes.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.AtividadeId === obj.AtividadeId);
      if (!existingObj) {
        acc.push(obj);
      }
      return acc;
    }, []);
  }

  formatarDecimal(value: number){
    return this.utils.formatarDecimais(value);
  }
  open(){
    this.modal.show();
  }

  close(){
    this.modal.hide();
  }

}
