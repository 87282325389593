import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { Regional } from 'src/app/classes/regionais/Regional';
import { RegionaisService } from 'src/app/services/regionais/regionais.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-regionais',
  templateUrl: './regionais.component.html',
  styleUrls: ['./regionais.component.scss']
})
export class RegionaisComponent implements OnInit {

  @ViewChild('Gird', { static: false }) grid;

  colDefs: ColDef[] = [];
  listaRegionais: Regional[] = [];
  objSelecionado: Regional = null;
  objSelecionadoId: string = null;
  objetosSelecionados: Regional[] = []

  constructor(private router: Router,
    private regionaisService: RegionaisService,
    private utilsService: UtilsService,
    private toasty: CustomToastyService,
  ) { }

  ngOnInit() {
    this.configuraGrid();
    this.pegarRegionais();
  }

  configuraGrid() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true
      },
      {
        field: 'Nome', headerName: 'Nome', flex: 1
      },
      {
        field: 'Contato', headerName: 'Contato', flex: 1
      },
      {
        field: 'Telefone', headerName: 'Telefone', flex: 1
      },
      {
        field: 'Cidade', headerName: 'Cidade', flex: 1
      },
      {
        field: 'UF', headerName: 'Estado', flex: 1
      },
      {
        field: 'AreaTotal', headerName: 'Área Total', flex: 1
      },
      {
        field: 'CriadoEm', headerName: 'Data de Criação', flex: 1, cellRenderer: (dado) => this.utilsService.formatarData(dado.value)
      },
    ]
  }

  pegarRegionais() {
    this.regionaisService.Listar().subscribe(
      res => this.listaRegionais = res,
      error => this.utilsService.getErro(error)
    );
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/regionais/' + event.data.Id]);
  }

  novo() {
    this.router.navigate(['/cadastros/regionais/inserir'])
  }

  excluir() {
    if (this.objetosSelecionados.length <= 0) {
      this.utilsService.handleWarning("Selecione uma regional para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utilsService.handleWarning("Selecione apenas uma regional para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar ' + this.objetosSelecionados[0].Nome + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.regionaisService.Excluir(this.objSelecionadoId).subscribe(
          res => {
            this.toasty.show('Feito!', 'Regional excluída!', 'success');
            this.pegarRegionais();
          },
          err => {
            this.utilsService.getErro(err);
          });
      }
    });
  }
}
