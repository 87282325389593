import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ItensAtividade } from '../classes/ItensAtividade';

@Injectable({
  providedIn: 'root'
})
export class ItensAtividadeService {

  constructor(private http: HttpClient) { }

  getItensPorAtividade(atividadePlanejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itensAtividade/listar/${atividadePlanejamentoId}`)
  }

  getItensPorPlanejamento(planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itensAtividade/listarPorPlanejamento/${planejamentoId}`)
  }

  getItensAtividade(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itensAtividade/${id}`)
  }

  postItensAtividade(itensAtividade: ItensAtividade): Observable<any>{
    return this.http.post(`${environment.apiURL}/itensAtividade`, itensAtividade);
  }

  putItensAtividade(id: number, itensAtividade: ItensAtividade): Observable<any>{
    return this.http.put(`${environment.apiURL}/itensAtividade/${id}`, itensAtividade);
  }

  deleteItensAtividade(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/itensAtividade/${id}`);
  }
}
