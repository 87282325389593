export class ItemMovimentoEstoque {
    Id: number;
    MovimentoEstoqueId: number;
    ProdutoId: number;
    Quantidade: number = 0;
    PrecoCusto: number = 0;
    IsEntrada: boolean;
    LocalEstoqueId: number;
    //auxiliares
    CustoTotal: number = 0;
}
