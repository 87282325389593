import { AtividadeSafra } from './AtividadeSafra';
import { ItemEnum } from './ItemEnum';
import { Planejamento } from './Planejamento';

export class Safra {
    Id: number;
    Descricao:string = "";
    DataInicio: Date = new Date();
    DataFim: Date = new Date();
    Inativa: boolean;
    CulturaId: number;
    AnoSafraId: number;

    //auxiliar no planejamento da safra
    Planejamentos: Planejamento[];
    FazendaId: number;
    CustoPorHA: number = 0;
    ProdutividadeSimulada: number = 0;
    PrecoVendaSimulado: number = 0;
    LucroSimulado: number = 0;
    AreaTotal: number = 0;
    LucroTotalSimulado: number = 0;
    DescricaoFazenda:string = "";
    PlanoMestreProducaoId: number;
    AtividadesSafra: AtividadeSafra[];

    //integração ERP
    CentroCustoERP:string = "";
    CodProjetoSankhya:string = "";
    IdERP:string = "";

    //filtro
    Status: EnumStatusSafra
}

export enum EnumStatusSafra
{
    NaoIniciada = 1,
    EmAndamento,
    Finalizada
}

export const StatusSafra: ItemEnum[] = [
    {name: 'Não Iniciada', value: EnumStatusSafra.NaoIniciada},
    {name: 'Em Andamento', value: EnumStatusSafra.EmAndamento},
    {name: 'Finalizada', value: EnumStatusSafra.Finalizada}
  ];
