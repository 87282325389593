import { AtividadeService } from './../../../services/atividade.service';
import { Atividade } from './../../../classes/Atividade';
import { Component, OnInit } from '@angular/core';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { GrupoOperacaoService } from 'src/app/services/grupo-operacao.service';
import Swal from 'sweetalert2';
import { Empresa } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-grupo-operacao',
  templateUrl: './grupo-operacao.component.html',
  styleUrls: ['./grupo-operacao.component.scss']
})
export class GrupoOperacaoComponent implements OnInit {

  gruposOperacao: GrupoOperacao[];
  auxGruposOperacao: GrupoOperacao[];
  grupoOperacao: GrupoOperacao;
  id: number = 0;
  idExpandir: number = 0;
  inserindo: boolean = false;
  inserindoAtividade: boolean = false;
  carregando: boolean = false;

  atividades: Atividade[];

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  processosPlanejamento: ProcessoPlanejamento[];

  // tradução
  ERRO: string = 'Erro!';
  FEITO: string = 'Feito!';
  GRUPO_DE_OPERACAO_ALTERADO: string = 'Grupo de Operação alterado!';
  GRUPO_DE_OPERACAO_CADASTRADO: string = 'Grupo de Operação cadastrado!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  GRUPO_DE_OPERACAO_DELETADO: string = 'Grupo de Operação deletado!';
  ATIVIDADE_DELETADA: string = 'Atividade deletada!';

  constructor(private gruposOperacaoService: GrupoOperacaoService, private atividadeService: AtividadeService,
    private route: ActivatedRoute, private router: Router, private storageService: StorageService,
    private toasty: CustomToastyService, private processoPlanejamentoService: ProcessoPlanejamentoService,
    private translate: TranslateService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
      this.idExpandir = res.idExpandir;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.grupoOperacao = new GrupoOperacao();

    this.processoPlanejamentoService.getProcessosPlanejamento().subscribe(res => {
      this.processosPlanejamento = res;
    });

    if (this.id > 0)
      this.selecionarGrupoOperacao();
    else {
      this.listarAtividades();
      this.listargruposOperacao();
    }
  }

  selecionarGrupoOperacao() {
    this.carregando = true;
    this.configurarTraducao();
    this.gruposOperacaoService.getGrupoOperacao(this.id).subscribe(res => {
      this.grupoOperacao = new GrupoOperacao();
      this.grupoOperacao = res;
      this.carregando = false;
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listargruposOperacao() {
    this.carregando = true;
    this.configurarTraducao();
    this.gruposOperacaoService.getGruposOperacao().subscribe(
      res => {
        this.gruposOperacao = res;
        this.auxGruposOperacao = res;
        this.carregando = false;

        this.gruposOperacao.filter(g => !this.idExpandir || g.Id != this.idExpandir).forEach(g => g.Collapsed = true);

        if (this.textoBusca != '')
          this.filtrarGruposOperacao();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.configurarTraducao();
    this.carregando = true;

    if (this.grupoOperacao.Id > 0)
      this.gruposOperacaoService.putGrupoOperacao(this.grupoOperacao.Id, this.grupoOperacao).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.GRUPO_DE_OPERACAO_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.gruposOperacaoService.postGrupoOperacao(this.grupoOperacao).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.GRUPO_DE_OPERACAO_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(grupoOperacaoId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.gruposOperacaoService.deleteGrupoOperacao(grupoOperacaoId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.GRUPO_DE_OPERACAO_DELETADO, 'success');
            this.listargruposOperacao();
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }

  filtrarGruposOperacao() {
    this.gruposOperacao = this.auxGruposOperacao.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
    );
  }

  cancelar() {
    this.router.navigate(['/cadastros/grupo-operacao']);
  }

  listarAtividades() {
    this.configurarTraducao();
    this.carregando = true;
    this.atividadeService.getAtividades().subscribe(
      res => {
        this.atividades = res;
        this.carregando = false;
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  excluirAtividade(atividadeId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.atividadeService.deleteAtividade(atividadeId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.ATIVIDADE_DELETADA, 'success');
            this.listarAtividades();
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }
  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.FEITO = this.translate.instant('FEITO');
    this.GRUPO_DE_OPERACAO_ALTERADO = this.translate.instant('GRUPO_DE_OPERACAO_ALTERADO');
    this.GRUPO_DE_OPERACAO_CADASTRADO = this.translate.instant('GRUPO_DE_OPERACAO_CADASTRADO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.GRUPO_DE_OPERACAO_DELETADO = this.translate.instant('GRUPO_DE_OPERACAO_DELETADO');
    this.ATIVIDADE_DELETADA = this.translate.instant('ATIVIDADE_DELETADA');
  }
}
