import { GrupoProdutoCulturaCentroCusto } from "./GrupoProdutoCulturaCentroCusto";

export class GrupoProduto {
    Id: number;
    Descricao:string = "";
    EmpresaId: number;
    IdERP:string = "";

    //Auxiliar para custo
    CustoPrevisto: number = 0;
    CustoRealizado: number = 0;
    CustoPrevistoHa: number = 0;
    CustoRealizadoHa: number = 0;
    Servico:boolean = false;
    //Integração ERP
    CentroCustoERP:string = "";
    listaGrupoProdutoCulturaCentroCusto: GrupoProdutoCulturaCentroCusto[];

}
