import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ContaEnergia } from '../classes/ContaEnergia';

@Injectable({
  providedIn: 'root'
})
export class ContaEnergiaService {  

  constructor(private http: HttpClient) { }   

  getContasEnergia(): Observable<any>{
    return this.http.get(`${environment.apiURL}/contasenergia`)
  }

  getContaEnergia(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/contasenergia/${id}`)
  }

  postContaEnergia(contaEnergia: ContaEnergia): Observable<any>{
    return this.http.post(`${environment.apiURL}/contasenergia`, contaEnergia);
  }

  putContaEnergia(id: number, contaEnergia: ContaEnergia): Observable<any>{
    return this.http.put(`${environment.apiURL}/contasenergia/${id}`, contaEnergia);
  }

  deleteContaEnergia(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/contasenergia/${id}`);
  }

  getContasEnergiaPorFazenda(idFazenda: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/contasenergia/fazenda/${idFazenda}`)
  }
  
  getContasEnergiaPorFazendaUnidadeConsumidora(idFazenda: number, idUnidadeConsumidora): Observable<any>{
    return this.http.get(`${environment.apiURL}/contasenergia/${idFazenda}/${idUnidadeConsumidora}`)
  }

}
