import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cotacao } from 'src/app/classes/cotacao/cotacao';
import { ProdutosFornecedores } from 'src/app/classes/cotacao/ProdutosFornecedores';
import { MovimentoCotacao } from 'src/app/classes/auxiliar/MovimentoCotacao';
import { UtilsService } from 'src/app/utils/utils.service';


@Injectable({
  providedIn: 'root'
})
export class cotacaoService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  get(): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao`);
  }

  getPorId(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/${id}`);
  }

  getPorIdComAnexos(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/${id}/Anexo`);
  }

  post(cotacao: Cotacao): Observable<any> {
    return this.http.post(`${environment.apiURL}/Cotacao`, cotacao);
  }

  put(id: number, cotacao: Cotacao): Observable<any> {
    return this.http.put(`${environment.apiURL}/Cotacao/${id}`, cotacao);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/Cotacao/${id}`);
  }

  selecionaPorPeriodo(dataInicial: Date, dataFinal: Date): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/Periodo/${dataInicial}/${dataFinal}`);
  }
  selecionaPorPeriodoInclude(dataInicial: Date, dataFinal: Date): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/PeriodoComInclude/${dataInicial}/${dataFinal}`);
  }
  getInclude(): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/Include`);
  }
  getPorIdInclude(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/Include/${id}`);
  }
  getPorIdIncludeFornecedor(id: number, idFornecedor:number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/Include/${id}/${idFornecedor}`);
  }
  getPorFornecedor(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/fornecedor/${id}`);
  }
  getProdutos(safra: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/listaProdutos/${safra}`);
  }
  iniciarCotacao(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/iniciar/${id}`);
  }
  mudarEtapaCotacao(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/MudarEtapa/${id}`);
  }
  finalizarCotacao(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/Finalizar/${id}`);
  }
  getResultado(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/Resultados/${id}`);
  }
  getResultadoComTabela(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/ResultadosTabela/${id}`);
  }
  Cancelar(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/Cancelar/${id}`);
  }
  getResultadoComTabelaSimilares(similares: boolean[], id: number): Observable<any>{
    return this.http.post(`${environment.apiURL}/Cotacao/ResultadosTabela/Sincronizar/${id}`, similares);
  }
  gerarMovimento(produtosFornecedores: ProdutosFornecedores[], cotacaoId: number, movimentoDestinoid:number): Observable<any>{
    return this.http.post(`${environment.apiURL}/Cotacao/GerarMovimento/${cotacaoId}/${movimentoDestinoid}`, produtosFornecedores);
  }
  gerarCotacaoMovimento(movimentoCotacao:MovimentoCotacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/Cotacao/GerarCotacaoMovimento`, movimentoCotacao);
  }
  getPorStatus(status: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Cotacao/ListarPorStatus/${status}`);
  }

  podeAprovar(id: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Cotacao/PodeAprovar/${id}`);
  }

  aprovar(id: number, tipo: number, texto: string): Observable<any>{
    return this.http.post(`${environment.apiURL}/Cotacao/Aprovar/${id}/${tipo}`, {Texto: texto});
  }
  
  deleteAnexo(anexoId: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/Cotacao/ExcluirAnexo/${anexoId}`);
  }

  getAnexo(url: string, arquivo) {
    return this.http.get(`${environment.apiURL}/${url}/` + arquivo, { responseType: 'blob' })
    .subscribe(data => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = arquivo;
      document.body.appendChild(a);
      a.click();
    }, err => {
      this.utils.getErro(err)
    });
  }

  getMapaCotacaoNovaAba(cotacaoId: number) {
    this.http.get(`${environment.apiURL}/Cotacao/RelatorioMapaCotacao/` + cotacaoId,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    }, err => {
      this.utils.getErro(err)
    }); 
  }

  getMapaCotacaoDownload(cotacaoId: number) {
    this.http.get(`${environment.apiURL}/Cotacao/RelatorioMapaCotacao/` + cotacaoId,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'RMC' + ("00000" + cotacaoId).slice(-5) + '.pdf';
      document.body.appendChild(a);
      a.click();
    }, err => {
      this.utils.getErro(err)
    }); 
  }
}
