import { Equipamento } from './../../../classes/Equipamento';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-select-equipamento',
  templateUrl: './select-equipamento.component.html',
  styleUrls: ['./select-equipamento.component.scss']
})
export class SelectEquipamentoComponent implements OnInit {
  @Input() isSubmit: boolean;
  @Input() equipamentoId: number = 0;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() auxClass: string;
  @Input() ocultarMsgObrigatorio: boolean = false;
  @Output() equipamentoChange: EventEmitter<number> = new EventEmitter();
  @Input() equipamentos: Equipamento[];
  @Input() flagImplemento: boolean = false;
  auxEquipamentos: Equipamento[];

  constructor(
    private equipamentoService: EquipamentoService, 
    private utils: UtilsService) {

  }

  ngOnInit() {
    if(!this.equipamentos)
      this.equipamentoService.getEquipamentos().subscribe((ret: Equipamento[])=> {
        if(this.flagImplemento)
          this.equipamentos = ret.filter(x => x.FlagImplemento);
        else
          this.equipamentos = ret.filter(x => !x.FlagImplemento);
      }, err => this.utils.getErro(err));
    else {
      this.auxEquipamentos = this.equipamentos;
      this.filtrarEquipamentos();
    }
    console.debug('equip',this.equipamentos)
  }

  filtrarEquipamentos(){
    if(this.flagImplemento){
      this.equipamentos = this.auxEquipamentos.filter(equipamento => equipamento.FlagImplemento);
    }
    else{
      this.equipamentos = this.auxEquipamentos.filter(equipamento => !equipamento.FlagImplemento);
    }
  }

  filtraChangeEquipamentos(term: string, item: Equipamento) {
    term = term.toLowerCase();
    return item.Descricao.toLowerCase().indexOf(term) > -1;
  }

  onModeloChange(valor){
    if (!valor)
    this.equipamentoChange.emit(null);
    else {
      if (this.equipamentos.find(p => p.Id == valor)) {
        this.equipamentoChange.emit(valor);
      } else
        this.equipamentoChange.emit(null);
    }
    this.equipamentoChange.emit(this.equipamentoId);
  }

}
