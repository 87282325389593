import { Location } from '@angular/common';
import { RastreioMovimentoService } from './../../../services/rastreio-movimento.service';
import { RastreioMovimento } from './../../../classes/rastreio-movimento.model';
import { StorageService } from './../../../services/storage.service';
import { FornecedoresService } from './../../../services/cotacao/Fornecedores.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { EnumStatusAprovacao, EnumStatusMovimento, ListaStatusMovimento, Movimento } from 'src/app/classes/Movimento';
import { UtilsService } from 'src/app/utils/utils.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { EnumModulo, EnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { Transacao } from 'src/app/classes/Transacao';
import { ItemMovimento } from 'src/app/classes/ItemMovimento';
import { ProdutoService } from 'src/app/services/produto.service';
import { Produto } from 'src/app/classes/Produto';
import { MovimentoService } from 'src/app/services/movimento.service';
import { cotacaoService } from 'src/app/services/cotacao/cotacaoService';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalizacaoGridService } from 'src/app/services/PersonalizacaoGrid.Service';
import { PersonalizacaoGridModel } from 'src/app/classes/PersonalizacaoGridModel';
import { EnumGrid } from 'src/app/classes/enum/EnumGrid';
import * as moment from 'moment';
import { Safra } from 'src/app/classes/Safra';
import { SafraService } from 'src/app/services/safra.service';
import { MovimentoCotacao } from 'src/app/classes/auxiliar/MovimentoCotacao';
import { Usuario } from 'src/app/classes/Usuario';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Pessoa } from 'src/app/classes/Pessoa';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { CentroDeResultadosService } from 'src/app/services/centro-de-resultados.service';
import { ProjetoService } from 'src/app/services/projeto.service';
import { NaturezaService } from 'src/app/services/natureza.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Natureza } from 'src/app/classes/Natureza';
import { Projeto } from 'src/app/classes/Projeto';
import { MovimentoSharedService } from './movimento-shared.service';
import { CriarNotaEntradaRequest } from 'src/app/classes/auxiliar/CriarNotaEntradaRequest';
import { ConfiguracaoDestinoService } from 'src/app/services/configuracao-destino.service';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-movimento',
  templateUrl: './movimento.component.html',
  styleUrls: ['./movimento.component.scss']
})
export class MovimentoComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private transacaoService: TransacaoService,
    private produtoService: ProdutoService,
    private movimentoService: MovimentoService,
    private safraService: SafraService,
    private sharedService: MovimentoSharedService,
    private router: Router,
    private route: ActivatedRoute,
    private PersonalizacaoGridService: PersonalizacaoGridService,
    private cotacaoService: cotacaoService,
    private UsuarioService: UsuarioService,
    private FornecedoresService: FornecedoresService,
    private grupoProdutoService: GrupoProdutoService,
    private centroDeResultadoService: CentroDeResultadosService,
    private projetoService: ProjetoService,
    private naturezaService: NaturezaService,
    private localEstoqueService: LocalEstoqueService,
    private rastreioMovimentoService: RastreioMovimentoService,
    private storageService: StorageService,
    private configuracaoDestinoService: ConfiguracaoDestinoService,
    private location: Location) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }
  @ViewChild('componenteItem', { static: false }) componenteItem
  @ViewChild('movimentosGrid', { static: false }) movimentosGrid
  @ViewChild('fornecedoresCotacaoGrid', { static: false }) fornecedoresCotacaoGrid
  @ViewChild('ModalTransacaoDestino', { static: false }) modal
  @ViewChild('ModalCotacao', { static: true }) modalCotacao
  @ViewChild('ModalRastreio', { static: true }) modalRastreio

  id: number = 0;
  movimentoCotacao: MovimentoCotacao = new MovimentoCotacao();
  centrosResultados: CentroDeResultados[] = [];
  locaisEstoque: LocalEstoque[] = [];
  naturezas: Natureza[] = [];
  projetos: Projeto[] = [];
  listaFornecedores: Pessoa[] = [];
  movimentos: Movimento[];
  listaResponsavel: Usuario[] = [];
  auxMovimentos: Movimento[] = [];
  carregandoMovimento: boolean = false;
  ultimaPagina: boolean = false;
  termoBusca: String = '';
  itensPorPagina = 50;
  pagina = 2;
  movimentoSelecionadoId: number = null;
  movimentoSelecionado: Movimento = new Movimento();
  movimentosSelecionados: Movimento[] = [];
  items: ItemMovimento[] = [];
  auxItems: ItemMovimento[] = [];
  modulo = EnumModulo.Compras;
  filtro = new FiltroTela();
  listaStatus = ListaStatusMovimento;
  transacoes: Transacao[] = [];

  produtos: Produto[] = [];
  gruposProduto: GrupoProduto[] = [];
  PersonalizacaoGrid: PersonalizacaoGridModel = new PersonalizacaoGridModel();
  qtdItems = 340;
  acoes = [
    'Editar',
  ]
  acoesItem = [
    'Editar',
    'Novo item Opcional'
  ]
  colDefs: ColDef[] = [];
  colDefsItems: ColDef[] = [];
  showGrid = true;
  showGridItems = true;
  minHeight = 0;
  inserindo: boolean;
  transacaoDestino: number = 0;
  isSubmitTransacao = false;
  isSubmitAprovacao = false;
  obsModalAprovacao: string;
  safras: Safra[] = [];
  solicitacoesColDefs: ColDef[] = [];
  fornecedoresColDefs: ColDef[] = [];
  solicitacoes: Movimento[] = [];
  solicitacoesSelecionadas: Movimento[] = [];
  fornecedores: Pessoa[] = [];
  fornecedoresSelecionados: Pessoa[] = [];
  fornecedorSelect: number = 0;
  isSubmit = false;
  loading: boolean = false;
  usuario: Usuario;
  showRastreio: boolean = false;
  rastreios: RastreioMovimento[] = [];
  showFilter = true;
  tipoMovimento: EnumTipoMovimento;
  transacoesDestino: Transacao[] = [];
  comparadorDeData = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      //using moment js
      var dateAsString = moment(cellValue).format('DD/MM/YYYY');
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
        return 0
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    }
  }

  cifraCorrente: string = '';


  ngOnInit() {
    const currentUrl = this.route.snapshot.url.join('/');

    if (currentUrl.includes('pedido-vendas')) {
      this.sharedService.tipoMovimento = EnumTipoMovimento.PedidoVendas;
    } else if (currentUrl.includes('solicitacao-compra')) {
      this.sharedService.tipoMovimento = EnumTipoMovimento.SolicitacaoCompras;
    } else if (currentUrl.includes('pedido-compras')) {
      this.sharedService.tipoMovimento = EnumTipoMovimento.PedidoCompras;
    }

    this.usuario = this.storageService.getUsuario();
    //console.debug('usuario', this.usuario)
    if (this.usuario.PermissaoUsuario == null || this.usuario.PermissaoUsuario.AcessoSolicitacaoCompras == false) {
      Swal.fire('Ops!', 'Usuário não tem acesso solicitações de compras', 'warning');
      this.router.navigate(["/"]);
    }

    this.tipoMovimento = this.sharedService.tipoMovimento;

    this.listarTransacoes();
    this.listarProdutos()
    this.listarMovimento();
    this.listarCentroResultados();
    this.listarProjetos();
    this.listarNaturezas();
    this.listarLocalEstoque();


    this.inserindo = this.router.url.search("inserir") > 0;
    if (this.id > 0)
      this.inserindo = true

    this.cifraCorrente = this.utils.getCifraMoeda();
  }

  configurar() {
    this.solicitacoesColDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Empresa', field: 'GetEmpresaInterna', flex: 1 },
      { field: 'Solicitante', flex: 1 },
      { field: 'DataEmissao', headerName: 'Data Emissão', flex: 1, valueFormatter: (params) => this.utils.formatarData(params.data.DataEmissao) },
      { field: '', headerName: 'Itens', flex: 1, valueFormatter: (params) => params.data.Itens.length },
    ];

    this.fornecedoresColDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'RazaoSocial', headerName: 'Razão Social', flex: 1 },
      { field: 'NomeResponsavel', headerName: 'Vendedor', flex: 1 },
      { field: 'Telefone', flex: 1 },
      { field: 'Email', headerName: 'E-mail', flex: 1 },
    ];
    let colDefsAux: ColDef[] = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Transação', field: 'GetTransacao' },
      { headerName: 'Empresa', field: 'GetEmpresaInterna' },
      { headerName: 'Data Criação', field: 'DataCadastro', filter: 'agDateColumnFilter', filterParams: this.comparadorDeData, valueFormatter: (params) => this.utils.formatarData(params.data.DataCadastro) },
      { headerName: 'Data Emissão', field: 'DataEmissao', filter: 'agDateColumnFilter', filterParams: this.comparadorDeData, valueFormatter: (params) => this.utils.formatarData(params.data.DataEmissao) },
      { field: 'Solicitante' },
      { headerName: 'Status', field: 'StatusMovimento', cellRenderer: (params) => this.statusBadge(params.data.StatusMovimento) },
      { field: 'Serie' },
      { field: 'NumeroDocumento' },
      { field: 'NumeroContrato' },
      { headerName: 'Comprador', field: 'GetUsuarioComprador' },
      { headerName: 'Parceiro', field: 'GetPessoa' },
      { headerName: 'Natureza', field: 'GetNatureza' },
      { headerName: 'Centro Resultado', field: 'GetCentroDeResultados' },
      { headerName: 'Projeto', field: 'GetProjeto' },
      { headerName: 'Data Entrada Saída', field: 'DataEntradaSaida', filter: 'agDateColumnFilter', filterParams: this.comparadorDeData, valueFormatter: (params) => this.utils.formatarData(params.data.DataEntradaSaida) },
      { headerName: 'Data Previsão Entrega', field: 'DataPrevisaoEntrega', filter: 'agDateColumnFilter', filterParams: this.comparadorDeData, valueFormatter: (params) => this.utils.formatarData(params.data.DataPrevisaoEntrega) },
      { headerName: 'Data Previsão Compras', field: 'DataPrevisaoCompras', filter: 'agDateColumnFilter', filterParams: this.comparadorDeData, valueFormatter: (params) => this.utils.formatarData(params.data.DataPrevisaoCompras) },
      { field: 'ValorBruto', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => `${this.cifraCorrente} ` + this.formatarDecimais(params.data.ValorBruto) },
      { field: 'Funrural' },
      { field: 'Juros', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => `${this.cifraCorrente} ` + this.formatarDecimais(params.data.Juros) },
      { field: 'PercentualDesconto', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => `${this.formatarDecimais(params.data.PercentualDesconto)}%` },
      { field: 'Descontos', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => `${this.cifraCorrente} ` + this.formatarDecimais(params.data.Descontos) },
      { headerName: 'Valor Líquido', cellStyle: { 'text-align': 'right' }, field: 'ValorLiquido', valueFormatter: (params) => `${this.cifraCorrente} ` + this.formatarDecimais(params.data.ValorLiquido) },
      { headerName: 'Observação', field: 'ObservacaoComprador' },
      { headerName: 'Histórico Financeiro', field: 'HistoricoParaFinanceiro' },
      { headerName: 'Status Aprovação', field: 'StatusAprovacao', cellRenderer: (params) => this.statusAprovacaoBadge(params.data.StatusAprovacao) },
      { field: 'Rateado' },
      { headerName: 'Cotação', field: 'GetCotacao' },
      { field: 'TemFrete' },
      { field: 'TipoFrete' },
      { field: 'ValorFrete', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => `${this.cifraCorrente} ` + this.formatarDecimais(params.data.ValorBruto) },
      { field: 'PesoBruto', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => this.formatarDecimais(params.data.ValorBruto) },
      { headerName: 'Peso Líquido', cellStyle: { 'text-align': 'right' }, field: 'PesoLiquido', valueFormatter: (params) => this.formatarDecimais(params.data.ValorBruto) },
      { field: 'ChaveNfe' },
      { headerName: 'Local Estoque', field: 'GetLocalEstoque' },
      { headerName: 'Fazenda', field: 'GetFazenda' },
    ];
    this.colDefs = colDefsAux;
    //aplica personalizaçãodo grid
    this.PersonalizacaoGridService.GetPorUserTela(EnumGrid.Movimento).subscribe(res => {
      if (res == null) {
        this.PersonalizacaoGridService.postPersonalizacao(EnumGrid.Movimento, 'vazio', false, colDefsAux).subscribe(res => {
          this.colDefs = colDefsAux;
        });
      }
      else {
        colDefsAux.forEach(element => {
          let col = res.filter(x => x.field == element.field)[0];
          if (col.field == element.field) {
            //element.hide = col.hide;
          }
        });
        this.colDefs = colDefsAux;
      }
    });
    //Fim aplica personalizaçãodo grid
    let colDefsItemsAux: ColDef[] = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Produto Similar', field: 'GetItemPaiDescricao', flex: 1 },
      { headerName: 'Grupo Produto', field: '', flex: 1, valueFormatter: (params) => this.grupoProdutoDesc(params.data.ProdutoId) },
      { headerName: 'Produto', field: 'ProdutoId', flex: 1, valueFormatter: (params) => this.produtoDesc(params.data.ProdutoId) },
      { headerName: 'Qtd Pedida', cellStyle: { 'text-align': 'right' }, field: 'QuantidadePedida', flex: 1, valueFormatter: (params) => `${this.formatarDecimais(params.data.QuantidadePedida)}` },
      { headerName: 'Qtd Recebido', cellStyle: { 'text-align': 'right' }, field: 'QuantidadeRecebida', flex: 1, valueFormatter: (params) => `${this.formatarDecimais(params.data.QuantidadeRecebida)}` },
      { headerName: 'Qtd Pendente', cellStyle: { 'text-align': 'right' }, field: 'QuantidadePendente', flex: 1, valueFormatter: (params) => `${this.formatarDecimais(params.data.QuantidadePendente)}` },
      { headerName: 'Unidade Medida', field: 'Un', flex: 1 },
      { headerName: 'Status', flex: 1, cellRenderer: (params) => this.statusBadge(params.data.Status) },
      { headerName: 'Origem', field: 'GetSafra', flex: 1 },
    ];
    //aplica personalizaçãodo grid
    this.PersonalizacaoGridService.GetPorUserTela(EnumGrid.ItemMovimento).subscribe(res => {
      if (res == null) {
        this.PersonalizacaoGridService.postPersonalizacao(EnumGrid.ItemMovimento, 'vazio', false, colDefsItemsAux).subscribe(res => {
          this.colDefsItems = colDefsItemsAux;
        });
      }
      else {
        colDefsItemsAux.forEach(element => {
          let col = res.filter(x => x.field == element.field)[0];
          if (col.field == element.field) {
            //element.hide = col.hide;
          }
        });
        this.colDefsItems = colDefsItemsAux;
      }
    });
    this.colDefsItems = colDefsItemsAux;
    //Fim aplica personalizaçãodo grid
    const elFiltro = document.getElementById('corpo');
    if (elFiltro) {
      this.minHeight = elFiltro.offsetHeight - 60;
      this.redimensionarGrids();
    }
  }
  oncolumnVisible(event) {
    this.PersonalizacaoGridService.postPersonalizacao(EnumGrid.Movimento, event.column.colId, !event.column.visible, this.colDefs).subscribe(res => {
      this.colDefs.forEach(element => {
        let col = res.filter(x => x.field == element.field)[0];
        if (col.field == element.field) {
          //element.hide = col.hide;
        }
      });
    });
  }

  oncolumnVisibleItens(event) {
    this.PersonalizacaoGridService.postPersonalizacao(EnumGrid.ItemMovimento, event.column.colId, !event.column.visible, this.colDefsItems).subscribe(res => {
      this.colDefsItems.forEach(element => {
        let col = res.filter(x => x.field == element.field)[0];
        if (col.field == element.field) {
          //element.hide = col.hide;
        }
      });
    });
  }

  redimensionarGrids() {
    var corpo = document.getElementById("corpo");
    var gridMovimentos = document.getElementById("grid-movimentos");
    var gridItems = document.getElementById("grid-items");
    var redimensionador = document.getElementById("redimensionador");
    var arrastando = false;

    function iniciarArrasto(e) {
      arrastando = true;
      e.preventDefault();
      window.addEventListener("mousemove", arrastar);
      window.addEventListener("mouseup", pararArrasto);
    }
    function getMousePos(canvas, evt) {
      var rect = canvas.getBoundingClientRect();
      return {
        x: evt.clientX - rect.left,
        y: evt.clientY - rect.top
      };
    }
    function arrastar(e) {
      if (arrastando) {
        var pos = getMousePos(corpo, e);
        var mouseY = pos.y;
        var alturaMovimentos = mouseY - redimensionador.offsetHeight / 2;
        var alturaItems =
          corpo.offsetHeight - alturaMovimentos - redimensionador.offsetHeight;
        gridMovimentos.style.height = alturaMovimentos + "px";
        gridItems.style.height = alturaItems + "px";
        redimensionador.style.top = alturaMovimentos + "px";
        // redimensionador.style.marginTop = "-0.7px";
        redimensionador.style.setProperty("--before-top", alturaMovimentos - 10 + "px");
        redimensionador.style.setProperty("--after-bottom", alturaItems - 10 + "px");
      }
    }

    function pararArrasto(e) {
      window.removeEventListener("mousemove", arrastar);
      window.removeEventListener("mouseup", pararArrasto);
      arrastando = false;
    }

    redimensionador.addEventListener("mousedown", iniciarArrasto);
  }
  toogleGridItem(event) {

  }

  listarCentroResultados() {
    this.centroDeResultadoService.getCentrosDeResultados()
      .subscribe(res => {
        this.centrosResultados = res;
      }, err => this.utils.getErro(err));
  }
  listarProjetos() {
    this.projetoService.getList()
      .subscribe(res => {
        this.projetos = res;
      }, err => this.utils.getErro(err));
  }
  listarNaturezas() {
    this.naturezaService.getList()
      .subscribe(res => {
        this.naturezas = res;
      }, err => this.utils.getErro(err));
  }
  listarLocalEstoque() {
    this.localEstoqueService.getLocaisEstoque()
      .subscribe(res => {
        this.locaisEstoque = res;
      }, err => this.utils.getErro(err));
  }
  listarSafras() {
    this.safraService.getSafras()
      .subscribe(res => {
        this.safras = res;
      }, err => this.utils.getErro(err));
  }
  listarTransacoes() {
    if(this.tipoMovimento)
        this.transacaoService.getTransacoesPermitidasPorTipo(this.tipoMovimento)
          .subscribe((res: Transacao[]) => {
            this.transacoes = res;
            this.configurar();
          }, err => {
            this.utils.getErro(err)
          }
        );
    else
      this.transacaoService.getTransacoes()
        .subscribe((res: Transacao[]) => {
          this.transacoes = res;
          this.configurar();
        }, err => {
          this.utils.getErro(err)
        }
      );
  }
  listarProdutos() {
    this.produtoService.getProdutos()
      .subscribe(res => {
        this.produtos = res;
      }, err => {
        this.utils.getErro(err)
      });
    this.grupoProdutoService.getGruposProduto()
      .subscribe(res => {
        this.gruposProduto = res;
      }, err => {
        this.utils.getErro(err)
      });
  }
  listarMovimento() {
    this.route.queryParamMap.subscribe(
      (res2) => {
        const tipo = res2.get('tipo');
        const status = res2.get('status');
        if (status || tipo) {
          this.movimentoService.getPaginandoTipado(1, this.itensPorPagina, parseInt(tipo))
          .subscribe(res => {
            this.movimentos = res.Lista;
            this.auxMovimentos = res.Lista;
            this.movimentosGrid.setRowData(this.movimentos)
            this.usuario.permissaoUsuarioTransacaos.forEach(p => {
              if (!p.PermiteVisualiza) {
                this.movimentos = this.movimentos.filter(c => c.TransacaoId != p.TransacaoId);
                this.auxMovimentos = this.auxMovimentos.filter(c => c.TransacaoId != p.TransacaoId);
              }
            })

          }, err => this.utils.getErro(err));


          // this.movimentos = this.auxMovimentos.filter(p =>
          //   (!status || p.StatusMovimento == parseInt(status)) &&
          //   (!tipo || p.GetTipoMovimentoId == parseInt(tipo)))
        }
        else
        {
          if(this.tipoMovimento)
          {

            this.movimentoService.getPaginando(1, this.itensPorPagina, this.tipoMovimento)
            .subscribe(res => {
              this.movimentos = res.Lista;
              this.auxMovimentos = res.Lista;
              this.movimentosGrid.setRowData(this.movimentos)
              this.usuario.permissaoUsuarioTransacaos.forEach(p => {
                if (!p.PermiteVisualiza) {
                  this.movimentos = this.movimentos.filter(c => c.TransacaoId != p.TransacaoId);
                  this.auxMovimentos = this.auxMovimentos.filter(c => c.TransacaoId != p.TransacaoId);
                }
              })
            }, err => this.utils.getErro(err));
          }
          else{
            this.movimentoService.getPaginando(1, this.itensPorPagina)
              .subscribe(res => {
                this.movimentos = res.Lista;
                this.auxMovimentos = res.Lista;
                this.movimentosGrid.setRowData(this.movimentos)
                this.usuario.permissaoUsuarioTransacaos.forEach(p => {
                  if (!p.PermiteVisualiza) {
                    this.movimentos = this.movimentos.filter(c => c.TransacaoId != p.TransacaoId);
                    this.auxMovimentos = this.auxMovimentos.filter(c => c.TransacaoId != p.TransacaoId);
                  }
                })
              }, err => this.utils.getErro(err)
            );
          }
        }
      }
    );
  }
  onScrollToEndMovimento() {
    if (this.carregandoMovimento || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }
  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

      this.timoutFectch = setTimeout(() => {
        this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
        this.carregandoMovimento = true;
        this.movimentoService.getPaginando(this.pagina, this.itensPorPagina)
          .subscribe(ret => {
            this.carregandoMovimento = false;

            if (!this.ultimaPagina || termoBusca != null) {

              this.movimentos = this.movimentos.concat(ret.Lista);
              this.auxMovimentos = this.auxMovimentos.concat(ret.Lista);
              this.movimentosGrid.setRowData(this.movimentos)
            }

            this.ultimaPagina = ret.TotalPaginas <= this.pagina;

            if (!this.ultimaPagina)
              this.pagina = this.pagina + 1;
          });
      }, 0);
  }
  itemDesc(id: number): string {
    if (id != null || id != undefined) {
      let item;
      if (this.id > 0)
        item = this.movimentos.find(p => p.Id == this.id).Itens.find(p => p.Id == id);
      else
        item = this.movimentoSelecionado.Itens.find(p => p.Id == id);
      return item != undefined ? item.Produto.Descricao : id.toString();
    }
    if (id)
      return id + '';

    return 'Não possui';
  }
  produtoDesc(id: number): string {
    if (id != null || id != undefined) {
      let produto = this.produtos.find(p => p.Id == id);
      return produto != undefined ? produto.Descricao : id.toString();
    }
    return id + '';
  }
  grupoProdutoDesc(id: number): string {
    if (id != null || id != undefined) {
      let produto = this.produtos.find(p => p.Id == id);
      let grupoProduto = this.gruposProduto.find(p => p.Id == produto.GrupoProdutoId);
      return grupoProduto.Descricao;
    }
    return id + '';
  }

  formatarDecimais(value) {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  statusBadge(status: EnumStatusMovimento) {
    switch (status) {
      case EnumStatusMovimento.Cadastrado:
        return '<span class="text-secondary text-uppercase">Em Elaboração</span>'
      case EnumStatusMovimento.Pendente:
        return '<span class="text-secondary text-uppercase">Pendente</span>'
      case EnumStatusMovimento.Cancelado:
        return '<span class="text-danger text-uppercase">Cancelado</span>'
      case EnumStatusMovimento.ParcialmenteRecebido:
        return '<span class="text-primary text-uppercase">Parcialmente Recebido</span>'
      case EnumStatusMovimento.Recebido:
        return '<span class="text-success text-uppercase">Recebido</span>'
      case EnumStatusMovimento.ValidacaoPorComprador:
        return '<span class="text-primary text-uppercase">Em Validação Por Comprador</span>'
      case EnumStatusMovimento.ValidacaoRecebimento:
        return '<span class="text-primary text-uppercase">Em Validação do Recebimento</span>'
      case EnumStatusMovimento.LiberacaoFinanceiro:
        return '<span class="text-primary text-uppercase">Aguardando Liberação Financeira</span>'
      case EnumStatusMovimento.FinanceiroLiberado:
        return '<span class="text-primary text-uppercase">Liberado no Financeiro</span>'
      case EnumStatusMovimento.Reprovado:
        return '<span class="text-danger text-uppercase">Reprovado</span>'
      case EnumStatusMovimento.EmRevisao:
        return '<span class="text-secondary text-uppercase">Em Revisão</span>'
      case EnumStatusMovimento.EmAprovacao:
        return '<span class="text-primary text-uppercase">Em Aprovação</span>'
      case EnumStatusMovimento.Aprovado:
        return '<span class="text-primary text-uppercase">Aprovado</span>'
      default:
        break
    }
  }
  statusAprovacaoBadge(status: EnumStatusAprovacao) {
    switch (status) {
      case EnumStatusAprovacao.Aprovado:
        return '<span class="text-success text-uppercase">Aprovado</span>'
      case EnumStatusAprovacao.Reprovado:
        return '<span class="text-danger text-uppercase">Reprovado</span>'
      case EnumStatusAprovacao.Cadastrado:
        return '<span class="text-secondary text-uppercase">Cadastrado Recebido</span>'
      case EnumStatusAprovacao.Revisao:
        return '<span class="text-primary text-uppercase">Revisão</span>'
      default:
        break
    }
  }
  cellDoubleClicked(event) {
    var t = this.usuario.permissaoUsuarioTransacaos.find(p => p.TransacaoId == event.data.TransacaoId);
    if (t && !t.PermiteEditar) {
      this.semPermissao();
      return;
    }

    this.router.navigate([this.sharedService.getRoute() + '/' + event.data.Id]);
  }
  // cellDoubleClickedItem(event){
  //   console.debug('double')
  //   event.data;
  // }
  onCellClicked({ data }) {
    this.movimentoSelecionadoId = data.Id;
    this.movimentoSelecionado = data;
    this.auxItems = data.Itens;
  }
  selectionChanged(event) {
    this.movimentosSelecionados = event;
  }
  filtrar(filtro: FiltroTela) {
    this.movimentos = this.auxMovimentos.filter(movimento =>
      (!filtro.TransacaoId || movimento.TransacaoId === filtro.TransacaoId) &&
      (!filtro.EmpresaInternaId || movimento.EmpresaInternaId === filtro.EmpresaInternaId) &&
      (!filtro.DataCriacaoInicial || moment(movimento.DataCadastro).toDate() >= moment(filtro.DataCriacaoInicial).set({ hours: 0, minutes: 0, seconds: 0 }).toDate()) &&
      (!filtro.DataCriacaoFinal || moment(movimento.DataCadastro).toDate() <= moment(filtro.DataCriacaoFinal).set({ hours: 23, minutes: 59, seconds: 59 }).toDate()) &&
      (!filtro.DataEmissaoInicial || moment(movimento.DataEmissao).toDate() >= moment(filtro.DataEmissaoInicial).set({ hours: 0, minutes: 0, seconds: 0 }).toDate()) &&
      (!filtro.DataEmissaoFinal || moment(movimento.DataEmissao).toDate() <= moment(filtro.DataEmissaoFinal).set({ hours: 23, minutes: 59, seconds: 59 }).toDate()) &&
      (!filtro.NumeroDocumento || movimento.NumeroDocumento === filtro.NumeroDocumento) &&
      (!filtro.Identificador || movimento.Id === filtro.Identificador) &&
      (filtro.Status == null || movimento.StatusMovimento === filtro.Status)
      //
    );
    this.items = this.auxItems.filter(item => (!filtro.ProdutoId || item.ProdutoId === filtro.ProdutoId))
  }
  atualizar() {
  }

  novo() {
    this.router.navigate([this.sharedService.getRoute() + '/inserir']);
  }

  excluir() {
    if (this.movimentosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione um Movimento para prosseguir!");
      return;
    }
    let prosseguir: boolean = true;
    this.movimentosSelecionados.forEach(m => {
      var t = this.usuario.permissaoUsuarioTransacaos.find(p => p.TransacaoId == m.TransacaoId);
      if (t && !t.PermiteExcluir) {
        prosseguir = false;
        return;
      }
    })
    if (!prosseguir) {
      this.semPermissao();
      return;
    }
    Swal.fire({
      title: 'Deletar Movimentos selecionados?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.movimentosSelecionados.forEach(m => {
          if (m.StatusMovimento == EnumStatusMovimento.Cadastrado)
            this.movimentoService.delete(m.Id)
              .subscribe(res => {
                this.utils.handleSuccess("Movimento excluido!")
                this.listarMovimento();
              }, err => this.utils.getErro(err));
          else
            this.utils.handleWarning(`Movimento #${m.Serie.toString().padStart(5, '0')} não excluído pois o status é diferente de Cadastrado`)
        })
      }
    });

  }

  buscar(textoBusca: string) {
    this.movimentos = this.auxMovimentos.filter(x =>
      x.Solicitante && x.Solicitante.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetCentroDeResultados && x.GetCentroDeResultados.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetCotacao && x.GetCotacao.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetFazenda && x.GetFazenda.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetLocalEstoque && x.GetLocalEstoque.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetNatureza && x.GetNatureza.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetPessoa && x.GetPessoa.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetProjeto && x.GetProjeto.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetTipoMovimento && x.GetTipoMovimento.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetTransacao && x.GetTransacao.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.GetUsuarioComprador && x.GetUsuarioComprador.toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.DataCadastro && this.utils.formatarData(x.DataCadastro.toString()).toUpperCase().includes(textoBusca.toUpperCase()) ||
      x.DataEmissao && this.utils.formatarData(x.DataEmissao.toString()).toUpperCase().includes(textoBusca.toUpperCase())
    );
  }

  acao(acao: string) {
    switch (acao) {
      case 'Editar':
        this.editar();
        break;
      default:
        break;
    }
  }
  acaoItem(acao: string) {
    switch (acao) {
      case 'Editar':
        this.componenteItem.editar();
        break;
      case 'Novo item Opcional':
        this.componenteItem.novoItemOpcional();
        break;
    }
  }
  editar() {
    if (this.movimentosSelecionados.length <= 0 || this.movimentosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um Movimento para prosseguir!");
      return;
    }
    var t = this.usuario.permissaoUsuarioTransacaos.find(p => p.TransacaoId == this.movimentosSelecionados[0].TransacaoId);
    if (t && !t.PermiteEditar) {
      this.semPermissao();
      return;
    }
    this.router.navigate([this.sharedService.getRoute() + this.movimentosSelecionados[0].Id]);
  }

  modalTransacaoDestino() {
    if (this.movimentosSelecionados.length <= 0 || this.movimentosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um Movimento para prosseguir!");
      return;
    }
    var t = this.usuario.permissaoUsuarioTransacaos.find(p => p.TransacaoId == this.movimentosSelecionados[0].TransacaoId);
    if (t && !t.PermiteReceber) {
      this.semPermissao();
      return;
    }
    const transacao = this.transacoes.find(p => p.Id == this.movimentosSelecionados[0].TransacaoId);
    if (transacao.ExigeCotacao) {
      this.utils.handleWarning("Não permitido Recebimento para esse Movimento");
      return;
    }
    // transacoesDestino listar as transacoes de destino do primeiro movimento
    this.configuracaoDestinoService.listarPorIdOrigem(this.movimentosSelecionados[0].TransacaoId)
    .subscribe(res => {
      if(res.length == 0){
        this.utils.handleWarning("Não encontrado destinos para essa Transação");
        return
      }
      this.transacoesDestino = res
      this.modal.show();
    })
  }
  rastreio() {
    if (this.movimentosSelecionados.length <= 0 || this.movimentosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um Movimento para prosseguir!");
      return;
    }
    this.rastreioMovimentoService.Get(this.movimentosSelecionados[0].Id)
      .subscribe(res => {
        this.rastreios = res;
        // console.debug("rastreio", this.rastreios);
        this.showRastreio = true;
        this.modalRastreio.show();
      });
  }
  fecharRastreio() {
    this.modalRastreio.hide();
    this.showRastreio = false;
  }
  receber(form: any) {
    if (!form.valid) {
      this.isSubmitTransacao = true;
      return;
    }
    this.movimentosSelecionados.forEach((m: Movimento) => {
      if (m.StatusMovimento != EnumStatusMovimento.Recebido){
        if(m.GetTipoMovimento == 'Pedido de Compras'){
          console.debug(this.movimentosSelecionados.map(p => p.Id))
          var payload = {
            MovimentoIds: this.movimentosSelecionados.map(p => p.Id),
            TransacaoDestinoId: this.transacaoDestino,
          } as CriarNotaEntradaRequest;
          this.movimentoService.criarNotaEntrada(payload)
          .subscribe(res => {
            this.utils.handleSuccess("Movimento recebido!");
            this.listarMovimento();
            this.modal.hide();
          }, err => this.utils.getErro(err));
        }
        else{
          this.movimentoService.receber(this.transacaoDestino, m)
          .subscribe(res => {
            this.utils.handleSuccess("Movimento recebido!");
            this.listarMovimento();
            this.modal.hide();
          }, err => this.utils.getErro(err));
        }
        }
      else
        this.utils.handleWarning(`Movimento #${m.Serie.toString().padStart(5, '0')} ja Recebido!`)

    })
  }
  imprimir() {
    if (this.movimentosSelecionados.length <= 0 || this.movimentosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um Movimento para prosseguir!");
      return;
    }
    var t = this.usuario.permissaoUsuarioTransacaos.find(p => p.TransacaoId == this.movimentosSelecionados[0].TransacaoId);
    if (t && !t.PermiteImprimir) {
      this.semPermissao();
      return;
    }

    // implementar
    this.utils.handleWarning("Funcao nao implementada!");
  }
  clonar() {
    if (this.movimentosSelecionados.length <= 0 || this.movimentosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um Movimento para prosseguir!");
      return;
    }
    var t = this.usuario.permissaoUsuarioTransacaos.find(p => p.TransacaoId == this.movimentosSelecionados[0].TransacaoId);
    if (t && !t.PermiteClonar) {
      this.semPermissao();
      return;
    }
    Swal.fire({
      title: `Duplicar o movimento ${this.movimentosSelecionados[0].Id}?`,
      text: 'Realmente deseja duplicar o movimento?',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willClone) => {
      if (willClone.dismiss) {

      } else {
        this.movimentoService.clonar(this.movimentosSelecionados[0].Id).subscribe(res => {
          this.router.navigate([`${this.sharedService.getRoute()}/${res}`]);
        },
          err => {
            this.utils.getErro(err);
          });
      }
    });
  }

  cancelar() {
    if (this.movimentosSelecionados.length <= 0 || this.movimentosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um Movimento para prosseguir!");
      return;
    }

    var t = this.usuario.permissaoUsuarioTransacaos.find(p => p.TransacaoId == this.movimentosSelecionados[0].TransacaoId);
    if (t && !t.PermiteCancelar) {
      this.semPermissao();
      return;
    }

    if (this.movimentosSelecionados[0].StatusMovimento == EnumStatusMovimento.Cadastrado
      || this.movimentosSelecionados[0].StatusMovimento == EnumStatusMovimento.EmRevisao
      || this.movimentosSelecionados[0].StatusMovimento == EnumStatusMovimento.Aprovado)
      Swal.fire({
        text: 'Qual o motivo do cancelamento?',
        input: 'textarea',
        inputPlaceholder: 'Digite aqui...',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
      }).then(res => {
        this.movimentosSelecionados[0].StatusMovimento = EnumStatusMovimento.Cancelado;
        this.movimentoService.put(this.movimentosSelecionados[0].Id, false, this.movimentosSelecionados[0])
          .subscribe(res2 => {
            this.utils.handleSuccess("Movimento cancelado!")
            this.listarMovimento();
          }, err => this.utils.getErro(err));
      })
    else
      this.utils.handleWarning(`Não foi possível cancelar Movimento #${this.movimentosSelecionados[0].Serie.toString().padStart(5, '0')}`)
  }

  cotacao() {
    if (this.movimentosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione um ou mais Movimentos para prosseguir!");
      return;
    }
    this.solicitacoes = this.movimentosSelecionados;
    this.movimentoCotacao = new MovimentoCotacao();
    this.FornecedoresService.getParaCotacao(this.movimentosSelecionados[0].Id).subscribe(res => {
      this.listaFornecedores = res;
      //console.log(this.listaFornecedores)
      this.UsuarioService.getCompradores().subscribe(res => {
        this.listaResponsavel = res;
        this.modalCotacao.show();
      });
    });
  }

  gerarCotacao() {
    this.loading = true;
    this.cotacaoService.gerarCotacaoMovimento(this.movimentoCotacao)
      .subscribe(res => {
        this.loading = false;
        Swal.fire({
          title: 'Cotação gerada',
          text: 'deseja ir para essa cotação?',
          type: 'success',
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
        }).then((ret) => {
          if (!ret.dismiss)
            this.router.navigate([`${this.sharedService.getRoute()}/${res}`]).then(nav => {
              window.location.reload();
            });
          else
            this.listarMovimento();
        });

      }, (err) => {
        this.loading = false;
        this.utils.getErro(err)
      });
  }
  getMovimentoSelectionato() {
    return this.auxMovimentos.find(p => p.Id == this.movimentoSelecionadoId);
  }

  EnviarCotacao(form: any) {
    if (this.solicitacoes.length == 0) {
      this.utils.handleWarning('Deve haver pelo menos uma Solicitação!');
      return;
    }

    if (this.fornecedores.length == 0) {
      this.utils.handleWarning('Deve haver pelo menos um Fornecedor!');
      return;
    }

    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    this.movimentoCotacao.MovimentosId = this.solicitacoes.map(p => p.Id);
    this.movimentoCotacao.FornecedoresId = this.fornecedores.map(p => p.Id);
    Swal.fire({
      title: 'Deseja enviar para cotação?',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then((ret) => {
      if (!ret.dismiss)
        this.gerarCotacao();
    });
  }

  removerSolicitacao() {
    this.solicitacoesSelecionadas.forEach(element => {
      this.solicitacoes = this.solicitacoes.filter(p => p.Id != element.Id)
    });
  }
  adicionarFornecedor() {
    var fornecedor = this.listaFornecedores.find(f => f.Id == this.fornecedorSelect);
    if (fornecedor) {
      this.fornecedores.push(fornecedor)
      this.fornecedoresCotacaoGrid.setRowData(this.fornecedores)
      this.fornecedorSelect = null;
    }
  }
  removerFornecedores() {
    this.fornecedoresSelecionados.forEach(element => {
      this.fornecedores = this.fornecedores.filter(p => p.Id != element.Id)
    });
  }
  selectionChangedFornecedor(event) {
    this.fornecedoresSelecionados = event;
  }
  selectionChangedSolicitacoes(event) {
    this.solicitacoesSelecionadas = event;
  }
  semPermissao() {
    this.utils.handleWarning("Você não tem permissão para executar essa ação!")
  }
  toggleFilter(event: boolean){
    this.showFilter = event;
  }
}
