import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ItemDescontoProducao } from 'src/app/classes/gestao-lite/ItemDescontoProducao';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { PlanejamentoSafraService } from 'src/app/services/planejamento-safra/planejamento-safra.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exibir-anexos',
  templateUrl: './exibir-anexos.component.html',
  styleUrls: ['./exibir-anexos.component.scss']
})
export class ExibirAnexosComponent implements OnInit {

  @ViewChild('ModalAnexo', { static: false })
  ModalAnexo: UiModalComponent;
  @ViewChild('Grid', { static: false })
  grid: any;

  @Output() fechou = new EventEmitter<any>();
  @Output() itemRemovido = new EventEmitter<any>();

  @Input() entradaProducaoId: number = null;

  itensDescontoProducao: ItemDescontoProducao[] = [];

  colDefs: ColDef[] = [];

  objSelecionado: ItemDescontoProducao = null;

  constructor(
    private planejamentoSafraService: PlanejamentoSafraService,
    private translate: TranslateService,
    private utils: UtilsService,
    private toasty: CustomToastyService,
  ) { }

  ngOnInit() {
    this.configurar();
    this.listarItensDescontoProducao();
  }

  ngAfterViewInit() {
    this.ModalAnexo.show();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'TipoDescontoNome', headerName: 'Tipo Desconto', flex: 1 },
      { field: 'Percentual', headerName: 'Percentual', flex: 1 },
      {
        field: 'Quantidade', headerName: 'Quantidade', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.Quantidade)}`
      },
      { field: 'Observacao', headerName: 'Observação', flex: 1 },
      {
        field: 'DataCadastro', headerName: 'Data Cadastro', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarData(params.data.DataCadastro)}`
      },
    ];
  }

  selectionChanged(event) {
    this.objSelecionado = event;
  }

  excluir() {
    if (this.objSelecionado == null) {
      this.utils.handleInfo("Selecione um Item Desconto");
      return;
    }
    let ITEM_DESCONTO = this.translate.instant("ITEM_DESCONTO");
    let CONFIRMAR_DELETE = this.translate.instant("CONFIRMAR_DELETE");
    let CANCELAR = this.translate.instant("CANCELAR");
    let SIM = this.translate.instant("SIM");
    let ITEM_DESCONTO_DELETADO = this.translate.instant("ITEM_DESCONTO_DELETADO");
    let FEITO = this.translate.instant("FEITO");
    let DELETAR_PARAM = this.translate.instant("DELETAR_PARAM", { value: `${ITEM_DESCONTO} Id: ${this.objSelecionado.Id}` });

    Swal.fire({
      title: DELETAR_PARAM,
      text: CONFIRMAR_DELETE,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: CANCELAR,
      confirmButtonText: SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.planejamentoSafraService.DeleteItemDescontoProducao(this.objSelecionado.Id)
          .subscribe(
            res => {
              this.toasty.show(FEITO, ITEM_DESCONTO_DELETADO, 'success');
              this.listarItensDescontoProducao();
              this.itemRemovido.emit();
              this.objSelecionado = null;
            },
            err => this.utils.getErro(err)
          )
      }
    });
  }

  fechar() {
    this.itensDescontoProducao = [];
    this.ModalAnexo.hide();
    this.fechou.emit();
  }

  private listarItensDescontoProducao() {
    this.planejamentoSafraService
      .GetItensDescontoProducao(this.entradaProducaoId)
      .subscribe(
        res => this.itensDescontoProducao = res,
        err => this.utils.getErro(err)
      );
  }
}
