export class ContaEnergia {
    Id: number;
    EmpresaId: number;
    FazendaId: number;
    UnidadeConsumidoraId: number;
    Referencia: Date = new Date();
    PeriodoMedicaoIni: Date = new Date();
    PeriodoMedicaoFin: Date = new Date();
    Observacao:string = "";
    ValorTotal: number = 0;
}
