import { EmpresaService } from './../../../services/empresa.service';
import { CultivarService } from './../../../services/cultivar.service';
import { CulturaProduto } from './../../../classes/CulturaProduto';
import { CulturaService } from 'src/app/services/cultura.service';
import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { Produto, enumTipo } from 'src/app/classes/Produto';
import { ProdutoService } from 'src/app/services/produto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { ClasseProdutoService } from 'src/app/services/classe-produto.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Cultura } from 'src/app/classes/Cultura';
import { CulturaProdutoService } from 'src/app/services/cultura-produto.service';
import { Cultivar } from 'src/app/classes/Cultivar';
import { Empresa, enumErpIntegrado } from 'src/app/classes/Empresa';
import { NaturezaService } from 'src/app/services/natureza.service';
import { Natureza } from 'src/app/classes/Natureza';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { auxPossuiIntegracao } from 'src/app/classes/auxiliar/auxPossuiIntegracao';
import { auxIntegracaoService } from 'src/app/services/auxIntegracaoService';
import { set } from 'lodash';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.scss']
})
export class ProdutoComponent implements OnInit {
  @ViewChild('Modal', { static: false })  Modal: UiModalComponent;

  @Input() IsNovo: boolean = false;
  @Input() IsServico: boolean = false;

  produtos: Produto[];
  auxProdutos: Produto[];
  produto: Produto;
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  servicos: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";

  perfilLogado: number = 0;

  listaNatureza: Natureza[] = [];
  gruposProduto: GrupoProduto[];
  culturas: Cultura[];
  culturaProduto: CulturaProduto = new CulturaProduto();
  culturasProduto: CulturaProduto[];
  auxCultivares: Cultivar[];
  culturaId: number = 0;

  possuiIntegracao: boolean;
  integracaoRM: boolean;
  auxPossuiIntegracao: auxPossuiIntegracao = new auxPossuiIntegracao();

  pagina: number = 1;
  totalItens: number = 0;
  tamanhoPagina: number = 20;
  grupoProdutoFiltro: number = 0;
  grupoDescricaoFiltro: string = "Todos";
  ultimaSincronizacao: Date;
  permissaoSincronizaEstoqueERP: boolean;

  // tradução
  ERRO: string = 'Erro!';
  TODOS: string = 'Todos';
  FEITO: string = 'Feito!';
  SERVICO_ALTERADO: string = 'Serviço alterado!';
  PRODUTO_ALTERADO: string = 'Produto alterado!';
  SERVICO_CADASTRADO: string = 'Serviço cadastrado!';
  PRODUTO_CADASTRADO: string = 'Produto cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  SERVICO_DELETADO: string = 'Serviço deletado!';
  PRODUTO_DELETADO: string = 'Produto deletado!';
  SEM_PERMISSAO: string = 'Sem Permissão!';
  VOCE_NAO_TEM_PERMISSAO_PARA_SINCRONIZAR_O_CADASTRO_DE_PRODUTOS: string = 'Você não tem permissão para sincronizar o cadastro de produtos.';
  TUDO_CERTO: string = 'Tudo certo!';
  FORAM_SINCRONIZADOS: string = 'Foram sincronizados';
  PRODUTOS: string = 'produtos';
  NENHUM_PRODUTO_IMPORTADO: string = 'Nenhum produto importado';
  CULTURA_DEVE_SER_INFORMADA: string = 'Cultura deve ser informada';
  CONFIGURACAO_DE_CULTURA_ADICIONADA: string = 'Configuração de Cultura adicionada!';
  DELETAR_CULTURA: string = 'Deletar Cultura';
  CONFIGURACAO_DE_CULTURA_DELETADA: string = 'Configuração de Cultura deletada!';
  PRODUTO_ATUALIZADO_COM_SUCESSO: string = 'Produto atualizado com sucesso!';

  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.TODOS = this.translate.instant('TODOS');
    this.FEITO = this.translate.instant('FEITO');
    this.SERVICO_ALTERADO = this.translate.instant('SERVICO_ALTERADO');
    this.PRODUTO_ALTERADO = this.translate.instant('PRODUTO_ALTERADO');
    this.SERVICO_CADASTRADO = this.translate.instant('SERVICO_CADASTRADO');
    this.PRODUTO_CADASTRADO = this.translate.instant('PRODUTO_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.SERVICO_DELETADO = this.translate.instant('SERVICO_DELETADO');
    this.PRODUTO_DELETADO = this.translate.instant('PRODUTO_DELETADO');
    this.SEM_PERMISSAO = this.translate.instant('SEM_PERMISSAO');
    this.VOCE_NAO_TEM_PERMISSAO_PARA_SINCRONIZAR_O_CADASTRO_DE_PRODUTOS = this.translate.instant('VOCE_NAO_TEM_PERMISSAO_PARA_SINCRONIZAR_O_CADASTRO_DE_PRODUTOS');
    this.TUDO_CERTO = this.translate.instant('TUDO_CERTO');
    this.FORAM_SINCRONIZADOS = this.translate.instant('FORAM_SINCRONIZADOS');
    this.PRODUTOS = this.translate.instant('PRODUTOS');
    this.NENHUM_PRODUTO_IMPORTADO = this.translate.instant('NENHUM_PRODUTO_IMPORTADO');
    this.CULTURA_DEVE_SER_INFORMADA = this.translate.instant('CULTURA_DEVE_SER_INFORMADA');
    this.CONFIGURACAO_DE_CULTURA_ADICIONADA = this.translate.instant('CONFIGURACAO_DE_CULTURA_ADICIONADA');
    this.DELETAR_CULTURA = this.translate.instant('DELETAR_CULTURA');
    this.CONFIGURACAO_DE_CULTURA_DELETADA = this.translate.instant('CONFIGURACAO_DE_CULTURA_DELETADA');
    this.PRODUTO_ATUALIZADO_COM_SUCESSO = this.translate.instant('PRODUTO_ATUALIZADO_COM_SUCESSO');
  }


  constructor(private produtosService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private classeProdutoService: ClasseProdutoService,
    private naturezaService: NaturezaService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private culturaService: CulturaService,
    private culturaProdutoService: CulturaProdutoService,
    private cultivarService: CultivarService,
    private empresaService: EmpresaService,
    private integracaoService: auxIntegracaoService,
    private utils: UtilsService) {
    this.route.params.subscribe(res => {
      this.id = res.id;

    });
    this.servicos = this.router.url.includes('servicos');
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.integracaoService.getIntegracao().then(res => {
      this.auxPossuiIntegracao = res;
      this.possuiIntegracao = this.auxPossuiIntegracao.possuiIntegracao;
      this.integracaoRM = this.auxPossuiIntegracao.integracaoRM == enumErpIntegrado.Totvs_RM;
      this.ultimaSincronizacao = this.auxPossuiIntegracao.ultimaSincronizacao;
      this.permissaoSincronizaEstoqueERP = this.auxPossuiIntegracao.permissaoSincronizaEstoqueERP;
    });
    this.inserindo = this.router.url.search("inserir") > 0;
    this.produto = new Produto();
    if(this.IsNovo)
    {
      this.inserindo = true;
      if(this.IsServico == true)
      {
        this.servicos = true;
      }
    }

    if (this.servicos) {
      this.grupoProdutoService.getServicos().subscribe(ret => {
        this.gruposProduto = ret;
      });
    }
    else {
      this.grupoProdutoService.getProdutos().subscribe(ret => {
        this.gruposProduto = ret;
      });
    }

    this.culturaService.getCulturas().subscribe(ret => {
      this.culturas = ret;
    })

    this.cultivarService.getCultivares().subscribe(ret => {
      this.auxCultivares = ret;
    })

    this.naturezaService.getList().subscribe(
      res => {
        this.listaNatureza = res;
      },
      err =>
        this.utils.getErro(err)
    );

      this.listarProdutos();
  }

  abrir(id: number) {
    if (this.servicos)
      this.router.navigate(['/cadastros/servicos/' + id]);
    else
      this.router.navigate(['/estoques/produto/' + id]);
  }

  selecionarGrupoFiltro() {
    if (this.grupoProdutoFiltro > 0)
      this.grupoDescricaoFiltro = this.gruposProduto.find(x => x.Id == this.grupoProdutoFiltro).Descricao;
    else
      this.grupoDescricaoFiltro = this.translate.instant('TODOS');
    this.listarProdutos();
  }

  listarProdutos() {
    this.carregando = true;
    if(!this.grupoProdutoFiltro) this.grupoProdutoFiltro = 0;
    if (!this.servicos) {
      this.produtosService.getSoTodosProdutosPaginando(this.pagina, this.tamanhoPagina, this.grupoProdutoFiltro, this.textoBusca).subscribe(
        res => {
          this.produtos = res.Lista;
          this.auxProdutos = this.produtos;
          this.totalItens = res.TotalItens;
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else {
      this.produtosService.getSoTodosServicosPaginando(this.pagina, this.tamanhoPagina, this.grupoProdutoFiltro, this.textoBusca).subscribe(
        res => {
          this.produtos = res.Lista;
          this.auxProdutos = this.produtos;
          this.totalItens = res.TotalItens;
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
  }

  loadPage(page: number) {
    this.pagina = page;
    this.listarProdutos();
  }

  cancelar(isServico: boolean) {
    this.id = 0;
    if (isServico)
      this.router.navigate(['/cadastros/servicos/']);
    else
      this.router.navigate(['/estoques/produto']);
  }

  excluir(grupooperacaoId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR + ' ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.produtosService.deleteProduto(grupooperacaoId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.servicos ? this.SERVICO_DELETADO : this.PRODUTO_DELETADO, 'success');
            this.listarProdutos();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  filtrarProdutos() {
    if (!this.textoBusca || this.textoBusca.length >= 3)
      this.listarProdutos();
  }

  getGrupoProduto(id: number): GrupoProduto {
    let grupos = this.gruposProduto.filter(x =>
      x.Id == id
    );

    return grupos.length > 0 ? grupos[0] : new GrupoProduto();
  }

  sincronizarERP() {
    this.configurarTraducao();
    if (!this.permissaoSincronizaEstoqueERP) {
      Swal.fire(this.SEM_PERMISSAO, this.VOCE_NAO_TEM_PERMISSAO_PARA_SINCRONIZAR_O_CADASTRO_DE_PRODUTOS, 'warning');
    }
    this.carregando = true;
    this.produtosService.sincronizarErp(this.storageService.getThreadImportId()).subscribe(
      res => {
        let produtosImportados: Produto[] = res;
        if (produtosImportados)
          Swal.fire(this.TUDO_CERTO, this.FORAM_SINCRONIZADOS + ' ' + produtosImportados.length + ' ' + this.PRODUTOS, 'success');
        else
          Swal.fire(this.TUDO_CERTO, this.NENHUM_PRODUTO_IMPORTADO, 'success');
        /*let retorno:number = res;
        this.storageService.setThreadImportId(retorno);
        if(retorno > 0)
          Swal.fire('Iniciado!', 'Sincronização de Produtos foi iniciada e será executada em segundo plano. O sistema pode ser usado enquanto isso.', 'success');
        else
          Swal.fire('Sincronização já iniciada!', 'Uma sincronização já está em progresso. Tente novamente em 5 minutos.', 'warning');*/


        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  getDescricaoCultura(culturaId: number): string {
    return this.culturas.find(x => x.Id == culturaId).Descricao;
  }

  atualizarProdutos()
  {
    this.carregando = true;
    this.produtosService.atualizarProdutos().subscribe(
      res => {
        this.carregando = false;
      },err=>{
        Swal.fire('Erro!', err.message, 'error');
              this.carregando = false;
      });
  }

  atualizarPrecos()
  {
    this.carregando = true;
    this.produtosService.atualizarPrecos().subscribe(
      res => {
        this.carregando = false;
      },err=>{
        Swal.fire('Erro!', err.message, 'error');
              this.carregando = false;
      });
  }
}
