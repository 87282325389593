import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { ItemMovimentoEstoque } from 'src/app/classes/ItemMovimentoEstoque';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { MovimentoEstoque, tiposMovimentoEstoque, gruposMovimentoEstoque } from 'src/app/classes/MovimentoEstoque';
import { Produto } from 'src/app/classes/Produto';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { MovimentoEstoqueService } from 'src/app/services/movimento-estoque.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';
import { ItemMovimentoEstoqueService } from '../../../services/item-movimento-estoque.service';

@Component({
  selector: 'app-edita-movimento-estoque',
  templateUrl: './edita-movimento-estoque.component.html',
  styleUrls: ['./edita-movimento-estoque.component.scss']
})
export class EditaMovimentoEstoqueComponent implements OnInit {
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  @Output() fechou: EventEmitter<any> = new EventEmitter();
  @Output() movimentoSalvo: EventEmitter<any> = new EventEmitter();
  @Input() locaisEstoque: LocalEstoque[];
  @Input() movimentoEstoqueId: number;

  movimentoEstoque: MovimentoEstoque;
  itensMovimentoEstoque: ItemMovimentoEstoque[];
  itensMovimentoEstoqueRemovidos: ItemMovimentoEstoque[] = [];

  tiposMovimentoEstoqueList = tiposMovimentoEstoque;
  gruposMovimentoEstoqueList = gruposMovimentoEstoque;
  //ng-select produto
  pagina: number = 2;
  termoBusca: String = '';
  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  public inputProduto$ = new Subject<string | null>();
  produtos: Produto[] = [];
  gruposProdutos: GrupoProduto[] = [];
  valorTotal: number = 0;
  salvando: boolean = false;

  // tradução
  TIPO_DE_MOVIMENTO_DEVE_SER_SELECIONADO: string = 'Tipo de Movimento deve ser selecionado';
  MOTIVO_DA_MOVIMENTACAO_DEVE_SER_SELECIONADO: string = 'Motivo da Movimentação deve ser selecionado';
  LOCAL_DE_ESTOQUE_DEVE_SER_SELECIONADO: string = 'Local de estoque deve ser selecionado';
  LOCAL_DE_ESTOQUE_DE_ORIGEM_DEVE_SER_SELECIONADO: string = 'Local de estoque de origem deve ser selecionado';
  LOCAL_DE_ESTOQUE_DE_DESTINO_SER_SELECIONADO: string = 'Local de estoque de destino ser selecionado';
  DATA_DO_MOVIMENTO_DEVE_SER_INSERIDO: string = 'Data do movimento deve ser inserido';
  DEVE_SER_INSERIDO_AO_MENOS_UM_ITEM_PARA_O_MOVIMENTO: string = 'Deve ser inserido ao menos um item para o movimento';
  LOCAL_DE_ESTOQUE_DE_ORIGEM_E_DESTINO_NAO_PODEM_SER_IGUAIS: string = 'Local de estoque de origem e destino não podem ser iguais';
  MOVIMENTO_EXECUTADO_COM_SUCESSO: string = 'Movimento Executado com sucesso';

  configurarTraducao() {
    this.TIPO_DE_MOVIMENTO_DEVE_SER_SELECIONADO = this.translate.instant('TIPO_DE_MOVIMENTO_DEVE_SER_SELECIONADO');
    this.MOTIVO_DA_MOVIMENTACAO_DEVE_SER_SELECIONADO = this.translate.instant('MOTIVO_DA_MOVIMENTACAO_DEVE_SER_SELECIONADO');
    this.LOCAL_DE_ESTOQUE_DEVE_SER_SELECIONADO = this.translate.instant('LOCAL_DE_ESTOQUE_DEVE_SER_SELECIONADO');
    this.LOCAL_DE_ESTOQUE_DE_ORIGEM_DEVE_SER_SELECIONADO = this.translate.instant('LOCAL_DE_ESTOQUE_DE_ORIGEM_DEVE_SER_SELECIONADO');
    this.LOCAL_DE_ESTOQUE_DE_DESTINO_SER_SELECIONADO = this.translate.instant('LOCAL_DE_ESTOQUE_DE_DESTINO_SER_SELECIONADO');
    this.DATA_DO_MOVIMENTO_DEVE_SER_INSERIDO = this.translate.instant('DATA_DO_MOVIMENTO_DEVE_SER_INSERIDO');
    this.DEVE_SER_INSERIDO_AO_MENOS_UM_ITEM_PARA_O_MOVIMENTO = this.translate.instant('DEVE_SER_INSERIDO_AO_MENOS_UM_ITEM_PARA_O_MOVIMENTO');
    this.LOCAL_DE_ESTOQUE_DE_ORIGEM_E_DESTINO_NAO_PODEM_SER_IGUAIS = this.translate.instant('LOCAL_DE_ESTOQUE_DE_ORIGEM_E_DESTINO_NAO_PODEM_SER_IGUAIS');
    this.MOVIMENTO_EXECUTADO_COM_SUCESSO = this.translate.instant('MOVIMENTO_EXECUTADO_COM_SUCESSO');
  }

  constructor(private movimentoEstoqueService: MovimentoEstoqueService,
    private produtoService: ProdutoService,
    private translate: TranslateService,
    private utils: UtilsService,
    private grupoProdutoService: GrupoProdutoService,
  ) {
    this.locaisEstoque = [];
    this.movimentoEstoque = new MovimentoEstoque();
    this.itensMovimentoEstoque = [];
    this.inputProduto$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMore(newTerm);
    });
  }

  ngOnInit() {
    this.carregarMovimento();
  }

  listarProdutos() {
    this.produtoService.getTodosProdutosPaginando(1, 25, 0, '').subscribe(ret => {
      this.produtos = ret.Lista;
      this.grupoProdutoService.getGruposProduto().subscribe(ret => {
        this.gruposProdutos = ret;
        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    });
  }

  carregarMovimento() {
    if (this.movimentoEstoqueId && this.movimentoEstoqueId > 0) {
      this.movimentoEstoqueService.getMovimentoEstoqueCompleto(this.movimentoEstoqueId)
        .subscribe(res => {
          this.movimentoEstoque = res;
          this.itensMovimentoEstoque = res.ItensMovimentoEstoque;
          this.itensMovimentoEstoque.forEach(item => {
            this.carregarProduto(item.ProdutoId);
            this.changeProduto(item, item.ProdutoId);
          });
        });
    }
  }

  carregarProduto(id: number) {
    this.produtoService.getProduto(id).subscribe(res => {
      this.pagina = 1;
      this.fetchMore((res as Produto).Descricao.substring(0, 4));
    })
  }

  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProdutos.filter(x =>
      x.Id == id
    );

    let novo = new GrupoProduto();

    return grupos.length > 0 ? grupos[0] : novo;
  }

  changeProduto(item: ItemMovimentoEstoque, idProduto: number) {
    if (item.ProdutoId != idProduto) {
      item.ProdutoId = idProduto;
      item.PrecoCusto = this.getProduto(item.ProdutoId).PrecoUnitario;
    }

    item.CustoTotal = item.PrecoCusto * item.Quantidade;
    this.valorTotal += item.CustoTotal;
  }

  getProduto(id: number): Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';

    if (!this.produtos)
      return novo;

    let produto = this.produtos.filter(x =>
      x.Id == id
    );

    return produto.length > 0 ? produto[0] : novo;
  }

  ngAfterViewInit() {
    this.Modal.show();
  }

  salvarMovimento() {
    this.configurarTraducao();
    let ok: boolean = true;
    this.salvando = true;
    if (!this.movimentoEstoque.TipoMovimento) {
      ok = false;
      this.utils.handleErro(this.TIPO_DE_MOVIMENTO_DEVE_SER_SELECIONADO);
    }
    if (!this.movimentoEstoque.GrupoMovimento) {
      ok = false;
      this.utils.handleErro(this.MOTIVO_DA_MOVIMENTACAO_DEVE_SER_SELECIONADO);
    }
    if ((this.movimentoEstoque.LocalEstoqueId <= 0 || this.movimentoEstoque.LocalEstoqueId == null) && this.movimentoEstoque.GrupoMovimento != 5) {
      ok = false;
      this.utils.handleErro(this.LOCAL_DE_ESTOQUE_DEVE_SER_SELECIONADO);

    }
    if ((this.movimentoEstoque.LocalEstoqueId <= 0 || this.movimentoEstoque.LocalEstoqueId == null) && this.movimentoEstoque.GrupoMovimento == 5) {
      ok = false;
      this.utils.handleErro(this.LOCAL_DE_ESTOQUE_DE_ORIGEM_DEVE_SER_SELECIONADO);
    }
    if ((this.movimentoEstoque.LocalEstoqueDestinoId <= 0 || this.movimentoEstoque.LocalEstoqueDestinoId == null) && this.movimentoEstoque.GrupoMovimento == 5) {
      ok = false;
      this.utils.handleErro(this.LOCAL_DE_ESTOQUE_DE_DESTINO_SER_SELECIONADO);
    }
    if (this.movimentoEstoque.DataMovimento == null) {
      ok = false;
      this.utils.handleErro(this.DATA_DO_MOVIMENTO_DEVE_SER_INSERIDO);
    }
    if (this.itensMovimentoEstoque == null || this.itensMovimentoEstoque.length == 0) {
      ok = false;
      this.utils.handleErro(this.DEVE_SER_INSERIDO_AO_MENOS_UM_ITEM_PARA_O_MOVIMENTO);
    }
    if (this.movimentoEstoque.GrupoMovimento == 5 && this.movimentoEstoque.LocalEstoqueId == this.movimentoEstoque.LocalEstoqueDestinoId) {
      ok = false;
      this.utils.handleErro(this.LOCAL_DE_ESTOQUE_DE_ORIGEM_E_DESTINO_NAO_PODEM_SER_IGUAIS);
    }
    if (!ok) {
      this.salvando = false;
    }
    if (ok) {

      this.movimentoEstoque.ItensMovimentoEstoque = this.itensMovimentoEstoque;
      this.movimentoEstoque.ItensMovimentoEstoqueRemovidos = this.itensMovimentoEstoqueRemovidos;

      let result: Observable<any>;

      if (this.movimentoEstoque.Id > 0) {
        result = this.movimentoEstoqueService.atualizaMovimentoCompleto(this.movimentoEstoque);
      } else {
        result = this.movimentoEstoqueService.inserirMovimentoCompleto(this.movimentoEstoque);
      }

      result.subscribe(ret => {
        this.movimentoSalvo.emit()
        this.utils.handleSuccess(this.MOVIMENTO_EXECUTADO_COM_SUCESSO);
        this.fechar();
      },
        err => {
          this.utils.getErro(err);
        });
    }

  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

  novoItem() {
    if (!this.movimentoEstoque)
      return;

    if (!this.itensMovimentoEstoque)
      this.itensMovimentoEstoque = [];

    if (this.itensMovimentoEstoque.length == 0 || this.itensMovimentoEstoque[this.itensMovimentoEstoque.length - 1].ProdutoId > 0) {
      let novo = new ItemMovimentoEstoque();
      novo.MovimentoEstoqueId = this.movimentoEstoque.Id;
      this.itensMovimentoEstoque.push(novo);
    }
  }
  excluirItem(item: ItemMovimentoEstoque) {
    if (item.Id != null) {
      this.itensMovimentoEstoqueRemovidos.push(item);
      this.itensMovimentoEstoque = this.itensMovimentoEstoque.filter(x => x.Id != item.Id);
    }
    this.itensMovimentoEstoque = this.itensMovimentoEstoque.filter(x => x != item);
  }
  //ng-select functions
  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }

  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    // if (this.timoutFectch)
    // clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;
      this.produtoService.getTodosProdutosPaginando(this.pagina, 25, 0, this.termoBusca).subscribe(ret => {
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.produtos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    }, 300);
  }

  timoutTotal: any;
  changeTotal(item: ItemMovimentoEstoque) {
    if (this.timoutTotal)
      clearTimeout(this.timoutTotal);

    this.timoutTotal = setTimeout(() => {
      item.CustoTotal = item.PrecoCusto * item.Quantidade;
    }, 500);
  }
}
