import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EscalaTrabalho } from '../classes/EscalaTrabalho';

@Injectable({
  providedIn: 'root'
})
export class EscalaTrabalhoService {

  constructor(private http: HttpClient) { }

  getEscalaTrabalhos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/escalaTrabalho`)
  }

  getEscalaTrabalho(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/escalaTrabalho/${id}`)
  }

  postEscalaTrabalho(escalaTrabalho: EscalaTrabalho): Observable<any>{
    return this.http.post(`${environment.apiURL}/escalaTrabalho`, escalaTrabalho);
  }

  putEscalaTrabalho(id: number, escalaTrabalho: EscalaTrabalho): Observable<any>{
    return this.http.put(`${environment.apiURL}/escalaTrabalho/${id}`, escalaTrabalho);
  }

  deleteEscalaTrabalho(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/escalaTrabalho/${id}`);
  }
}
