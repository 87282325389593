import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ItemManutencaoFrota } from '../classes/gestao-lite/ItemManutencaoFrota';
import { ChecklistManutencaoFrota } from '../classes/gestao-lite/ChecklistManutencaoFrota';


@Injectable({
  providedIn: 'root'
})
export class ItemManutencaoFrotaService {

  constructor(private http: HttpClient) { }

  getItensManutencaoFrota(manutencaoFrotaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itemManutencaoFrota/ListarPorFrota/${manutencaoFrotaId}`)
  }

  getItemManutencaoFrota(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itemManutencaoFrota/${id}`)
  }

  postItemManutencaoFrota(itemManutencaoFrota: ItemManutencaoFrota): Observable<any>{
    return this.http.post(`${environment.apiURL}/itemManutencaoFrota`, itemManutencaoFrota);
  }

  putItemManutencaoFrota(id: number, itemManutencaoFrota: ItemManutencaoFrota): Observable<any>{
    return this.http.put(`${environment.apiURL}/itemManutencaoFrota/${id}`, itemManutencaoFrota);
  }

  deleteItemManutencaoFrotae(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/itemManutencaoFrota/${id}`);
  }

  indicarProduto(checklistId:number, manutencaoFrotaId:number, itemManutencaoFrotaId:number): Observable<any>{
    return this.http.post(`${environment.apiURL}/itemManutencaoFrota/IndicarProduto/${checklistId}/${manutencaoFrotaId}/${itemManutencaoFrotaId}`, null);
  }

  exluirProduto(checklistId:number, manutencaoFrotaId:number, itemManutencaoFrotaId:number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/itemManutencaoFrota/IndicarProduto/${checklistId}/${manutencaoFrotaId}/${itemManutencaoFrotaId}`);
  }
}
