import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Empresa } from 'src/app/classes/Empresa';
import { Usuario } from 'src/app/classes/Usuario';
import { EmpresaService } from 'src/app/services/empresa.service';
import { FazendaService } from 'src/app/services/fazenda.service';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-login-fornecedor',
  templateUrl: './login-fornecedor.component.html',
  styleUrls: ['./login-fornecedor.component.scss']
})
export class LoginFornecedorComponent implements OnInit {
  usuario: Usuario = new Usuario();
  erros: string;
  carregando: boolean = false;
  constructor(private loginService: LoginService,
    private storageService: StorageService,
    private empresaService: EmpresaService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilsService) { }

  ngOnInit() {
    this.route.params.subscribe(res => {
      let token = res.token;
      if (token) {
        this.loginService.logarFornecedorAutomatico(token).subscribe(data => {
          this.storageService.setToken(data.access_token);
          this.loginService.getUsuarioLogado().subscribe(
            res => {
              this.usuario = res;
              this.usuario.IsFornecedor = true;
              this.storageService.setUsuario(this.usuario);
              if (this.usuario.EmpresaId) {
                this.empresaService.getEmpresa(this.usuario.EmpresaId).subscribe(
                  ret => {
                    console.log("logado");
                    this.router.navigate(["/painelfornecedor"]);
                  },
                  errEmp => {
                    this.carregando = false;
                    this.erros = errEmp.error;
                    console.log(errEmp);

                    if (errEmp.Message)
                      this.erros = errEmp.Message;
                  }
                );
              } else {
                this.carregando = false;
                this.storageService.setEmpresa({
                  Id: 0,
                  RazaoSocial: "Super",
                  NomeFantasia: "Super",
                  Ativa: true,
                  UsarSimilar: false,
                  AtividadePadraoId: 0,
                  UsaTarefas: false,
                  UsaGestaoLite: false,
                  PermiteVariasAtividadesPorEtapa: false,
                  Foto: "",
                  AgrupaPlanoComMesmoIntervalo: false,
                  UsarIntervaloFixoPlanoManutencao: false,
                  Moeda: "real"
                });
                this.router.navigate(["/"]);
              }
            },
            ops => {
              this.carregando = false;
              this.erros = ops.error;
              if (ops.Message)
                this.erros = ops.Message;
            }
          );
        },
        erro => {
          this.carregando = false;
          this.erros = erro.error;
          if (erro.Message)
            this.erros = erro.Message;
          if (erro.error.error_description)
            this.erros = erro.error.error_description;
        });
        return;
      }
    });



  }

  login() {
    this.carregando = true;
    this.loginService.logarFornecedor(this.usuario.Login, this.usuario.Senha).subscribe(
      data => {
        this.storageService.setToken(data.access_token);
        this.loginService.getUsuarioLogado().subscribe(
          res => {
            this.usuario = res;
            this.usuario.IsFornecedor = true;
            this.storageService.setUsuario(this.usuario);
            if (this.usuario.EmpresaId) {
              this.empresaService.getEmpresa(this.usuario.EmpresaId).subscribe(
                ret => {
                  console.log("logado");
                  this.router.navigate(["/painelfornecedor"]);
                },
                errEmp => {
                  this.carregando = false;
                  this.erros = errEmp.error;

                  if (errEmp.Message)
                    this.erros = errEmp.Message;
                }
              );
            } else {
              this.carregando = false;
              this.storageService.setEmpresa({
                Id: 0,
                RazaoSocial: "Super",
                NomeFantasia: "Super",
                Ativa: true,
                UsarSimilar: false,
                AtividadePadraoId: 0,
                UsaGestaoLite: false,
                UsaTarefas: false,
                PermiteVariasAtividadesPorEtapa: false,
                Foto: "",
                AgrupaPlanoComMesmoIntervalo: false,
                UsarIntervaloFixoPlanoManutencao: false,
                Moeda: "real"
              });
              this.router.navigate(["/"]);
            }
          },
          ops => {
            this.carregando = false;
            this.erros = ops.error;
            if (ops.Message)
              this.erros = ops.Message;
          }
        );

      },
      erro => {
        console.log(erro);
        this.carregando = false;
        this.erros = erro.error;
        this.utils.getErro(this.erros);
        if (erro.Message)
          this.erros = erro.Message;
        if (erro.error.error_description)
          this.erros = erro.error.error_description;
      });
  }

  onKeyDown(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
}
