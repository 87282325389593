import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RedefinirSenha, Usuario } from 'src/app/classes/Usuario';
import { StorageService } from 'src/app/services/storage.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-altera-senha',
  templateUrl: './altera-senha.component.html',
  styleUrls: ['./altera-senha.component.scss']
})
export class AlteraSenhaComponent implements OnInit {

  @Output() fechou: EventEmitter<any> = new EventEmitter();
  
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;
  
  public carregarSenha: boolean = false;
  public novaSenha: string = "";
  public confirmNovaSenha: string = "";
  public redefinirSenha: RedefinirSenha = new RedefinirSenha();
  public usuario: Usuario = new Usuario();

   // tradução
  FEITO: string = 'Feito!';
  SENHAS_DIFERENTES: string = 'Senhas diferentes';
  ATENCAO: string = 'Atenção!';
  SENHA_ATUALIZADA: string = 'Senha atualizada';
  SENHA_ATUALIZADA_VOCE_SERA_DIRECIONADO_PARA_PAGINA_DE_LOGIN: string = 'Senha atualizada, você será direcionado para página de login.';

  configurarTraducao() {
    this.FEITO = this.translate.instant('FEITO');
    this.SENHAS_DIFERENTES = this.translate.instant('SENHAS_DIFERENTES');
    this.ATENCAO = this.translate.instant('ATENCAO');
    this.SENHA_ATUALIZADA = this.translate.instant('SENHA_ATUALIZADA');
    this.SENHA_ATUALIZADA_VOCE_SERA_DIRECIONADO_PARA_PAGINA_DE_LOGIN = this.translate.instant('SENHA_ATUALIZADA_VOCE_SERA_DIRECIONADO_PARA_PAGINA_DE_LOGIN');
  }
  
  constructor(
    private usuarioService: UsuarioService,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private translate: TranslateService,
    private router: Router,
    private utils: UtilsService) { }

  ngOnInit() {    
    this.usuario = this.storageService.getUsuario();
    this.usuarioService.getUsuario(this.usuario.Id).subscribe(
      res => {
        this.usuario = res;
        this.Modal.show();
      },
      err => {
        console.error(err)
      }
    ); 
  }

  alterarSenha(){
    this.configurarTraducao();
    if (this.redefinirSenha.NovaSenha != this.redefinirSenha.ConfirmNovaSenha){
      this.utils.handleWarning(this.SENHAS_DIFERENTES);
      return;
    }
    this.carregarSenha = true;
    this.redefinirSenha.UsuarioId = this.usuario.Id;
    this.usuarioService.redefinirSenha(this.redefinirSenha)
    .subscribe(
      res => {
        this.utils.handleSuccess(this.SENHA_ATUALIZADA);
        Swal.fire(this.FEITO, this.SENHA_ATUALIZADA_VOCE_SERA_DIRECIONADO_PARA_PAGINA_DE_LOGIN, 'success');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2000);  
      },
      err => {
        this.utils.getErro(err);        
        this.carregarSenha = false;
      }
    )
  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

}
