import { ItemEnum } from "../ItemEnum";
import { MBaseEmpresa } from "../MBaseEmpresa";
import { PendenciaFrota } from "../PendenciaFrota";
import { ChecklistManutencaoFrota } from "./ChecklistManutencaoFrota";
import { ItemChecklistManutencaoFrota } from "./ItemChecklistManutencaoFrota";
import { ItemManutencaoFrota } from "./ItemManutencaoFrota";

export class ManutencaoFrota extends MBaseEmpresa{
    Id: number;
    SafraId: number;
    DataManutencao: Date = new Date();
    DataPrevista: Date = new Date();
    DataConclusao: Date = new Date();
    DataEnvioExterno: Date = new Date();
    TipoManutencao: EnumTipoManutencao;
    OrigemManutencao: EnumOrigemManutencao;
    Status:EnumStatusManutencaoFrota;
    ResponsavelId: number;
    EquipamentoId: number;
    UltimoMedidor: number = 0;
    MedidorPrevisto: number = 0;
    MedidorAtual: number = 0;
    CustoTotalProdutos: number = 0;
    CustoTotalServicos: number = 0;
    CustoTotal: number = 0;
    FornecedorId: number;
    Observacao:string = "";
    ItensManutencaoFrota: ItemManutencaoFrota[];
    ItensSafra: any [];
    LocalEstoqueId: number;



    //auxilixar
    Pendencias: PendenciaFrota[];
    ItensChecklist: ItemChecklistManutencaoFrota[];
    ChecklistManutencaoFrota:ChecklistManutencaoFrota[];
    StatusString:string = "";
    PrioridadeString:string = "";
    VariacaoMedidor:string = "";
    Tempo: number = 0;
    DescricaoGrupoEquipamento:string = "";
    DescricaoEquipamento:string = "";
    ResponsavelString:string = "";
    OficinaString:string = "";
    DescricaoFazenda:string = "";
}

export enum EnumTipoManutencao{
    Preventiva = 1,
    Corretiva = 2,
    Preditiva = 3,
    Externo = 4
}

export enum EnumStatusManutencaoFrota{
    Pendente = 1,
    Execucao = 2,
    Pausada = 3,
    Cancelada = 4,
    ParcialmenteConcluida = 5,
    Finalizada = 6
}

export enum EnumOrigemManutencao{
    Interna = 1,
    Externa = 2
}

export const ListaTipoManutencao: ItemEnum[] = [
    {name: 'Corretiva', value: EnumTipoManutencao.Corretiva},
    {name: 'Preventiva', value: EnumTipoManutencao.Preventiva},
    {name: 'Preditiva', value: EnumTipoManutencao.Preditiva},
    {name: 'Externo', value: EnumTipoManutencao.Externo}
];

export const ListaOrigemManutencao: ItemEnum[] = [
    {name: 'Interna', value: EnumOrigemManutencao.Interna},
    {name: 'Externa', value: EnumOrigemManutencao.Externa}
];

