import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Etapa } from '../classes/Etapa';

@Injectable({
  providedIn: 'root'
})
export class EtapaService {

  constructor(private http: HttpClient) { }

  getEtapas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/etapa`)
  }

  getEtapa(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/etapa/${id}`)
  }

  postEtapa(etapa: Etapa): Observable<any>{
    return this.http.post(`${environment.apiURL}/etapa`, etapa);
  }

  putEtapa(id: number, etapa: Etapa): Observable<any>{
    return this.http.put(`${environment.apiURL}/etapa/${id}`, etapa);
  }

  deleteEtapa(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/etapa/${id}`);
  }

  deleteCompleto(etapaId: number, planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/etapa/deleteCompleto/${planejamentoId}/${etapaId}`);
  }
}
