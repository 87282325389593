import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { StorageService } from '../services/storage.service';
import { enumPerfil } from '../classes/Usuario';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: "root"
  })
export class AdminGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private storageService: StorageService,
    private translate: TranslateService) {
    }

    VOCE_NAO_TEM_PERMISSAO_PARA_ACESSAR_ESTE_RECURSO: string = 'Você não tem permissão para acessar este recurso!';
    ACESSO_NEGADO: string = 'Acesso negado!';

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.loginService.usuarioEstaAutenticado() &&
      this.storageService.getUsuario() &&
      (this.storageService.getUsuario().PerfilInt == enumPerfil.Super ||
      this.storageService.getUsuario().PerfilInt == enumPerfil.Admin ||
      this.storageService.getUsuario().PerfilInt == enumPerfil.Master)) {
      return true;
    }
    this.VOCE_NAO_TEM_PERMISSAO_PARA_ACESSAR_ESTE_RECURSO = this.translate.instant('VOCE_NAO_TEM_PERMISSAO_PARA_ACESSAR_ESTE_RECURSO');
    this.ACESSO_NEGADO = this.translate.instant('ACESSO_NEGADO');

    Swal.fire(this.ACESSO_NEGADO, this.VOCE_NAO_TEM_PERMISSAO_PARA_ACESSAR_ESTE_RECURSO, 'warning');
    this.router.navigate(['/painel']);
    return false;
  }
}

