export class ContaBancaria {
    Id: number;
    Tipo: number = 0;
    BancoId: number;
    AgenciaId: number;
    DataRefencia: Date = new Date();
    SaldoDataRefencia: number = 0;
    CodigoEmpresaExclusiva: number = 0;
    BancoDescricao:string = "";
    AgenciaDescricao:string = "";

    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
}

