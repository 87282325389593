import { Component, OnInit } from '@angular/core';
import { TipoImprodutividade } from 'src/app/classes/TipoImprodutividade';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { TipoImprodutividadeService } from 'src/app/services/tipo-improdutividade.service';
import Swal from 'sweetalert2';
import { Empresa } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { TipoImprodutividadeTranslate } from './tipo-improdutividade.translate';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tipo-improdutividade',
  templateUrl: './tipo-improdutividade.component.html',
  styleUrls: ['./tipo-improdutividade.component.scss']
})
export class TipoImprodutividadeComponent extends TipoImprodutividadeTranslate implements OnInit {

  tiposImprodutividade: TipoImprodutividade[];
  auxTiposImprodutividade: TipoImprodutividade[];
  tipoImprodutividade: TipoImprodutividade;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;


  constructor(private tiposImprodutividadeService: TipoImprodutividadeService, private empresaService: EmpresaService,
              private route: ActivatedRoute, private router: Router, private storageService: StorageService,
              private toasty: CustomToastyService,
              private translate: TranslateService) {
    super(translate);
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.tipoImprodutividade = new TipoImprodutividade();

    if(this.id > 0)
      this.selecionarTipoImprodutividade();
    else
      this.listartiposImprodutividade();
  }

  selecionarTipoImprodutividade() {
    this.carregando = true;
    this.tiposImprodutividadeService.getTipoImprodutividade(this.id).subscribe(res =>{
      this.tipoImprodutividade = new TipoImprodutividade();
      this.tipoImprodutividade = res;
      this.carregando = false;
    },
    ex =>{
      Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
      this.carregando = false;
    })
  }

  listartiposImprodutividade(){
    this.carregando = true;
    this.tiposImprodutividadeService.getTiposImprodutividade().subscribe(
      res => {
        this.tiposImprodutividade = res;
        this.auxTiposImprodutividade = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarTiposImprodutividade();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.tipoImprodutividade.Id > 0)
      this.tiposImprodutividadeService.putTipoImprodutividade(this.tipoImprodutividade.Id, this.tipoImprodutividade).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.TIPO_DE_IMPRODUTIVIDADE_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.tiposImprodutividadeService.postTipoImprodutividade(this.tipoImprodutividade).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.TIPO_DE_IMPRODUTIVIDADE_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(tipoImprodutividadeId: number, nome: string) {
    this.DELETAR_PARAM = this.translate.instant('DELETAR_PARAM', {value: nome})
    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.tiposImprodutividadeService.deleteTipoImprodutividade(tipoImprodutividadeId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.TIPO_DE_IMPRODUTIVIDADE_DELETADO, 'success');
              this.listartiposImprodutividade();
              this.carregando = false;
            },
            err => {
              Swal.fire(this.ERRO, err.message, 'error');
              this.carregando = false;
            });
        }
      });
  }

  filtrarTiposImprodutividade(){
    this.tiposImprodutividade = this.auxTiposImprodutividade.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/tipo-improdutividade']);
  }
}
