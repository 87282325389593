export enum EnumEfeitoFinanceiro
{
    Nenhum = 0,
    Provisao,
    Real
}

export const ListaEfeitoFinanceiro = [
  { descricao: "Nenhum", valor: EnumEfeitoFinanceiro.Nenhum },
  { descricao: "Provisão", valor: EnumEfeitoFinanceiro.Provisao },
  { descricao: "Real", valor: EnumEfeitoFinanceiro.Real }
];