import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { culturaCotacao } from 'src/app/classes/cotacao/culturaCotacao';

@Injectable({
  providedIn: 'root'
})
export class culturaCotacaoService {  

  constructor(private http: HttpClient) { }

  get(): Observable<any>{
    return this.http.get(`${environment.apiURL}/culturaCotacao`)
  }

  getPorId(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/culturaCotacao/${id}`)
  }

  getPorCotacao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/culturaCotacao/Cotacao/${id}`)
  }

  post(culturaCotacao: culturaCotacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/culturaCotacao`, culturaCotacao);
  }

  put(id: number, culturaCotacao: culturaCotacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/culturaCotacao/${id}`, culturaCotacao);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/culturaCotacao/${id}`);
  }

  sincronizar(culturas: number[], id: number): Observable<any>{
    return this.http.post(`${environment.apiURL}/culturaCotacao/Sincronizar/${id}`, culturas);
  }

  getArray(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/culturaCotacao/array/${id}`)
  }
}
