import { Fornecedores } from './../../classes/cotacao/Fornecedores';
import { FornecedorSenha } from './../../classes/cotacao/FornecedorSenha';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FornecedoresService {  

  constructor(private http: HttpClient) { }

  get(): Observable<any>{
    return this.http.get(`${environment.apiURL}/Fornecedores`)
  }

  getPorId(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Fornecedores/${id}`)
  }

  post(Fornecedores: Fornecedores): Observable<any>{
    return this.http.post(`${environment.apiURL}/Fornecedores`, Fornecedores);
  }

  put(id: number, Fornecedores: Fornecedores): Observable<any>{
    return this.http.put(`${environment.apiURL}/Fornecedores/${id}`, Fornecedores);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/Fornecedores/${id}`);
  }

  recuperarSenha(chave:string): Observable<any>{
    return this.http.post(`${environment.apiURL}/Fornecedores/RecuperarSenhaFornecedor/${chave}`, chave);
  }
  alterarSenha(FornecedorSenha: FornecedorSenha): Observable<any>{
    return this.http.post(`${environment.apiURL}/Fornecedores/AlterarSenha/${FornecedorSenha.chaveTemp}/${FornecedorSenha.chave}/${FornecedorSenha.senha}`, FornecedorSenha);
  }
  getPorChave(chave:string): Observable<any>{
    return this.http.get(`${environment.apiURL}/Fornecedores/chave/${chave}`)
  }

  getParaCotacao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Fornecedores/ParaCotacao/${id}`)
  }
}
