import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TelemetriaService {

  constructor(private http: HttpClient) { }

  getTelemetria(): Observable<any>{
    return this.http.get(`${environment.apiURL}/telemetria`)
  }  

  getHistoricoTelemetria(data: string): Observable<any>{
    return this.http.get(`${environment.apiURL}/telemetria/historico/${data}`)
  }
  
  getHistoricoTelemetriaEquipamento(data: string, IdIrrigaPrime:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/telemetria/historicoEquipamento/${data}/${IdIrrigaPrime}`)
  }
}
