import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RastreioMovimento } from '../classes/rastreio-movimento.model';

@Injectable({
  providedIn: 'root'
})
export class RastreioMovimentoService {

  constructor(private http: HttpClient) { }

  route = 'RastreioMovimentos'

  Get(id: number): Observable<any> {
    return this.http.post(`${environment.apiURL}/${this.route}/Rastrear/${id}`, id)
  }

}
