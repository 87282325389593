import cep from 'cep-promise';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs'
import { Parceiro } from 'src/app/classes/parceiro/Parceiro';
import { ParceirosService } from 'src/app/services/parceiros/parceiros.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-parceiro-form',
  templateUrl: './parceiro-form.component.html',
  styleUrls: ['./parceiro-form.component.scss']
})
export class ParceiroFormComponent implements OnInit {

  form: FormGroup
  mascaraDocumento = '000.000.000-00';
  mascaraCEP = '00000-000';
  tipos = ['CPF', 'CNPJ'];
  tipo = 'CPF';
  inserindo = true;
  submetido = false;
  id: string = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private parceirosService: ParceirosService,
    private utils: UtilsService,
  ) {
    this.route.params.subscribe(res => this.id = res.id);
  }

  ngOnInit() {
    this.criarForm(new Parceiro());
    this.setarParceiro();

  }

  get formControl() { return this.form.controls; }

  setarParceiro() {
    if (this.router.url.search('inserir') == -1) {
      this.inserindo = false;
      this.parceirosService.ObterPorId(this.id).subscribe(
        res => this.atualizarForm(res),
        error => this.utils.getErro(error)
      )
    }
  }

  criarForm(parceiro: Parceiro) {
    this.form = this.formBuilder.group({
      Id: [parceiro.Id],
      Documento: [parceiro.Documento, [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
      RazaoSocial: [parceiro.RazaoSocial, [Validators.required]],
      CEP: [parceiro.CEP, [Validators.required, Validators.pattern("\\d{8}")]],
      UF: [parceiro.UF, [Validators.required, Validators.minLength(2)]],
      Cidade: [parceiro.Cidade, [Validators.required, Validators.minLength(4)]],
      Bairro: [parceiro.Bairro, [Validators.required]],
      Endereco: [parceiro.Endereco, [Validators.required]],
      Numero: [parceiro.Numero],
      Referencia: [parceiro.Referencia],
      Contato: [parceiro.Contato, [Validators.required]],
      Telefone: [parceiro.Telefone, [Validators.required]],
      Situacao: [parceiro.Situacao],
      AreaTotal: [parceiro.AreaTotal, [Validators.required, Validators.min(1)]],
    });
  }

  atualizarForm(parceiro: Parceiro) {
    this.tipo = parceiro.Documento.length === 11 ? 'CPF' : 'CNPJ';
    this.form.patchValue({
      Id: parceiro.Id,
      Documento: parceiro.Documento,
      RazaoSocial: parceiro.RazaoSocial,
      CEP: parceiro.CEP,
      UF: parceiro.UF,
      Cidade: parceiro.Cidade,
      Bairro: parceiro.Bairro,
      Endereco: parceiro.Endereco,
      Numero: parceiro.Numero,
      Referencia: parceiro.Referencia,
      Contato: parceiro.Contato,
      Telefone: parceiro.Telefone,
      Situacao: parceiro.Situacao,
      AreaTotal: parceiro.AreaTotal,
    } as Parceiro)
  }

  alterarTipo() {
    this.mascaraDocumento = this.tipo === 'CPF' ? '000.000.000-00' : '00.000.000/0000-00';
  }

  cancelar() {
    this.router.navigate(['/cadastros/parceiros']);
  }

  buscarPorCEP() {
    if (this.form.value.CEP.length != 8) return;
    cep(this.form.value.CEP)
      .then(res => {
        this.form.patchValue({
          UF: res.state,
          Cidade: res.city,
          Endereco: res.street,
          Bairro: res.neighborhood
        })
      })
      .catch(e => console.error("erro", e))
  }

  onSubmit() {
    this.submetido = true;
    if (!this.form.valid) return;

    let result: Observable<Parceiro | null> = null;
    if (this.inserindo) {
      result = this.parceirosService.Inserir(this.form.value);
    } else {
      console.log(this.form.value);
      result = this.parceirosService.Atualizar(this.form.value, this.form.value.Id);
    }

    result.subscribe(
      res => { this.router.navigate(['/cadastros/parceiros']); console.log(res) },
      error => console.error(error)
    )
  }
}
