import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './documentacao.component.html',
  styleUrls: ['./documentacao.component.scss']
})
export class DocumentacaoComponent implements OnInit {

  filtro: string = "";
  baseLink: string = "https://doc.farmway.com.br/";
  link: string = "";
  public getScreenWidth: any;
  public getScreenHeight: any;  

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(res => {
      this.filtro = res.filtro;      
    });
   }

  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    this.link = this.baseLink;
    if(this.filtro){
      this.link = this.link + "?s=" + this.filtro;
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

}
