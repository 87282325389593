import {Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Area } from "src/app/classes/Area";
import { Atividade } from "src/app/classes/Atividade";
import { Equipamento } from "src/app/classes/Equipamento";
import { Funcionario } from "src/app/classes/Funcionario";
import { FuncionarioTarefa } from "src/app/classes/FuncionarioTarefa";
import { ItemEnum } from "src/app/classes/ItemEnum";
import { Tarefa, ListaTipoTarefa } from "src/app/classes/Tarefa";
import { AreaService } from "src/app/services/area.service";
import { AtividadeService } from "src/app/services/atividade.service";
import { EquipamentoService } from "src/app/services/equipamento.service";
import { FuncionarioService } from "src/app/services/funcionario.service";
import { StorageService } from "src/app/services/storage.service";
import { TarefaService } from "src/app/services/tarefa.service";
import { CustomToastyService } from "src/app/theme/shared/components/toasty/custom-toasty.service";
import { UtilsService } from "src/app/utils/utils.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-form-tarefas",
  templateUrl: "./form-tarefas.component.html",
  styleUrls: ["./form-tarefas.component.scss"],
})
export class FormTarefasComponent implements OnInit {
  tarefa: Tarefa;
  tarefas: Tarefa[] = [];
  @Input() equipamentos: Equipamento[];
  @Input() implementos: Equipamento[];
  @Input() funcionarios: Funcionario[];
  @Input() atividades: Atividade[];
  @Input() areas: Area[];
  @Input() fazendaId: number = 0;
  @Input() id: number = 0;
  @Input() inserindo: boolean = false;
  @Output() voltarTarefa: EventEmitter<any> = new EventEmitter();

  auxAreas: Area[] = [];

  horaInicio: string;
  horaFim: string;
  carregando: boolean = false;
  isSubmit: boolean = false;
  funcionariosTarefa: FuncionarioTarefa[] = [];
  pesquisarFuncionario: boolean = false;
  tiposTarefa: ItemEnum[] = ListaTipoTarefa;
  projetosCadastrados: ItemEnum[] = [];
  projetoSelecionado: any;

  constructor(
    private tarefaService: TarefaService,
    private storageService: StorageService,
    private utils: UtilsService,
    private toasty: CustomToastyService,
    private funcionarioService: FuncionarioService
  ) {
    this.tarefa = new Tarefa();
    this.tarefa.Id = 0;
    this.tarefa.DataInicio = new Date();
    this.tarefa.DataFim = new Date();
    this.tarefa.LocalTexto = "";
    this.tarefa.FlagArea = true;
    this.tarefa.FlagConcluido = false;
  }

  ngOnInit() {
    if (this.fazendaId == 0)
      this.fazendaId = this.storageService.getFazenda().Id;

    this.funcionarioService.getFuncionarios().subscribe((ret) => {
      this.funcionarios = ret;
    });

    this.tarefaService.listarProjeto().subscribe((ret) => {
      let projetos = ret;
      projetos.forEach((p) => {
        if (p) this.projetosCadastrados.push({ value: p, name: p });
      });
    });

    if (this.id > 0) this.selecionarTarefa();
  }

  selecionarTarefa() {
    this.tarefaService.getTarefa(this.id).subscribe((res) => {
      this.tarefa = res;
      this.projetoSelecionado = this.tarefa.Projeto;
      this.funcionariosTarefa = this.tarefa.FuncionariosTarefa;
      this.horaInicio =
        new Date(this.tarefa.DataInicio.toString())
          .getHours()
          .toString()
          .padStart(2, "0") +
        ":" +
        new Date(this.tarefa.DataInicio.toString())
          .getMinutes()
          .toString()
          .padStart(2, "0");
      this.horaFim =
        new Date(this.tarefa.DataFim.toString())
          .getHours()
          .toString()
          .padStart(2, "0") +
        ":" +
        new Date(this.tarefa.DataFim.toString())
          .getMinutes()
          .toString()
          .padStart(2, "0");
    });
  }

  salvar(form: any, limparForm: boolean) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.tarefa.TipoTarefa = Number.parseInt(this.tarefa.TipoTarefa.toString())

    let tipoTarefa = this.tarefa.TipoTarefa;
    if(tipoTarefa !== undefined)
      this.tarefa.TipoTarefa = Number.parseInt(tipoTarefa.toString())

    if (!this.tarefa.FlagDiaInteiro) {
      this.tarefa.DataInicio = new Date(this.tarefa.DataInicio.toString());
      this.tarefa.DataFim = new Date(this.tarefa.DataFim.toString());
      if(this.horaInicio.length == 0 || this.horaFim.length == 0){
        this.tarefa.HoraOriginal = '';
      }else{
        this.tarefa.HoraOriginal = this.horaInicio + "-" + this.horaFim;
      }
      /*this.tarefa.DataInicio  = new Date(this.tarefa.DataInicio.getFullYear(), this.tarefa.DataInicio.getMonth(), this.tarefa.DataInicio.getDate(), auxHoraInicio.getHours(), auxHoraInicio.getMinutes());
      this.tarefa.DataFim = new Date(this.tarefa.DataFim.getFullYear(), this.tarefa.DataFim.getMonth(), this.tarefa.DataFim.getDate(), auxHoraFim.getHours(), auxHoraFim.getMinutes());       */
    }

    this.tarefa.FuncionariosTarefa = this.funcionariosTarefa;
    this.tarefa.Projeto = this.projetoSelecionado ? this.projetoSelecionado.name : "";

    if (this.id > 0)
      this.tarefaService.putTarefa(this.id, this.tarefa).subscribe(
        (res) => {
          this.toasty.show("Feito!", "Tarefa alterada!", "success");
          this.carregando = false;
        },
        (err) => {
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
    else
      this.tarefaService.postTarefa(this.tarefa).subscribe(
        (res) => {
          this.tarefa = res;
          this.id = this.tarefa.Id;
          this.tarefas.push(this.tarefa);

          this.toasty.show("Feito!", "Tarefa incluída!", "success");
          this.carregando = false;

          if (limparForm) {
            this.isSubmit = false;
            this.tarefa = new Tarefa();
          }
        },
        (err) => {
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
  }

  concluir() {
    this.tarefa.FlagConcluido = !this.tarefa.FlagConcluido;
    this.tarefaService.putTarefa(this.id, this.tarefa).subscribe(
      (res) => {
        if (this.tarefa.FlagConcluido)
          this.toasty.show("Feito!", "Tarefa concluída!", "success");
      },
      (err) => {
        this.utils.getErro(err);
      }
    );
  }

  cancelar() {
    this.voltarTarefa.emit("");
  }

  excluir() {
    Swal.fire({
      title: "Deletar tarefa?",
      text: "Se deletar, esta ação não poderá ser desfeita!",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.carregando = true;
        this.tarefaService.deleteTarefa(this.tarefa.Id).subscribe(
          (res) => {
            this.toasty.show("Feito!", "Tarefa excluída!", "success");
            this.carregando = false;
            this.voltarTarefa.emit("");
          },
          (err) => {
            this.utils.getErro(err);
            this.carregando = false;
          }
        );
      }
    });
  }

  adicionarFuncionario(idFuncionario: number) {
    let funcionario: FuncionarioTarefa = new FuncionarioTarefa();
    funcionario.FuncionarioId = idFuncionario;
    funcionario.TarefaId = this.tarefa.Id;
    funcionario.Funcao = this.funcionarios.find(
      (f) => f.Id == idFuncionario
    ).Funcao;
    this.funcionariosTarefa.push(funcionario);
    this.pesquisarFuncionario = false;
  }

  adicionarVariosFuncionarios(ids: any) {
    console.log("ids ", ids);

    for (let i = 0; i < ids.length; i++) {
      let funcionario: FuncionarioTarefa = new FuncionarioTarefa();
      funcionario.FuncionarioId = ids[i];
      funcionario.TarefaId = this.tarefa.Id;
      funcionario.Funcao = this.funcionarios.find((f) => f.Id == ids[i]).Funcao;
      this.funcionariosTarefa.push(funcionario);
    }
    this.pesquisarFuncionario = false;
  }

  getNomeFuncionario(id: number) {
    let funcionario = this.funcionarios.find((f) => f.Id == id);
    return funcionario ? funcionario.Nome : "";
  }

  getSiglaFuncionario(id: number) {
    let funcionario = this.funcionarios.find((f) => f.Id == id);
    let sigla: string = "";
    if (funcionario) {
      let nome: string = this.funcionarios.find((f) => f.Id == id).Nome;
      let nomes = nome.split(" ");
      if (nomes.length > 1)
        sigla =
          nomes[0].substring(0, 1) + nomes[nomes.length - 1].substring(0, 1);
      else sigla = nome.substring(0, 2);
    }
    return sigla;
  }

  removerFuncionario(id: number) {
    Swal.fire({
      title: "Remover Funcionário?",
      text: "Se remover, esta ação não poderá ser desfeita!",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.carregando = true;
        let funcTarefa = this.funcionariosTarefa.find(
          (f) => f.FuncionarioId == id
        );
        if (funcTarefa.Id > 0) {
          this.tarefaService.deleteFuncionarioTarefa(funcTarefa.Id).subscribe(
            (res) => {
              let index = this.funcionariosTarefa.findIndex(
                (f) => f.FuncionarioId == id
              );
              this.funcionariosTarefa.splice(index, 1);
              this.toasty.show("Feito!", "Funcionário removido!", "success");
              this.carregando = false;
            },
            (err) => {
              this.utils.getErro(err);
              this.carregando = false;
            }
          );
        } else {
          let index = this.funcionariosTarefa.findIndex(
            (f) => f.FuncionarioId == id
          );
          this.funcionariosTarefa.splice(index, 1);
          this.carregando = false;
        }
      }
    });
  }
}
