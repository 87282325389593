import { Tarefa } from './../classes/Tarefa';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TarefaService {

  constructor(private http: HttpClient) { }

  getTarefas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/tarefa`)
  }

  getTarefa(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/tarefa/${id}`)
  }

  postTarefa(Tarefa: Tarefa): Observable<any>{
    return this.http.post(`${environment.apiURL}/tarefa`, Tarefa);
  }

  putTarefa(id: number, Tarefa: Tarefa): Observable<any>{
    return this.http.put(`${environment.apiURL}/tarefa/${id}`, Tarefa);
  }

  deleteTarefa(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/tarefa/${id}`);
  }

  deleteFuncionarioTarefa(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/funcionarioTarefa/${id}`);
  }

  listarProjeto(): Observable<any>{
    return this.http.get(`${environment.apiURL}/tarefa/listarprojeto`)
  }

  listarPorSafra(safraId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/tarefa/listarporsafra/${safraId}`)
  }
}
