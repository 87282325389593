export enum EnumTipoContaBancaria
{
    Corrente = 1,
    Aplicacao = 2,
    Caixa = 3,
    CartaoDeCredito = 4
}

export const ListaTipoContaBancaria = [
    { descricao: "Corrente", valor: EnumTipoContaBancaria.Corrente },
    { descricao: "Aplicação", valor: EnumTipoContaBancaria.Aplicacao },
    { descricao: "Caixa", valor: EnumTipoContaBancaria.Caixa },
    { descricao: "Cartão de Credito", valor: EnumTipoContaBancaria.CartaoDeCredito }
];

export const DicionariTipoContaBancaria = {
    1: 'Corrente',
    2: 'Aplicação',
    3: 'Caixa',
    4: 'Cartão de Credito',
};