import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { CentroDeResultados } from '../classes/CentroDeResultados';

@Injectable({
  providedIn: 'root'
})
export class CentroDeResultadosService {

  public centroDeResultadosSelecionada: CentroDeResultados;

  constructor(private http: HttpClient) { }

  getCentrosDeResultados(): Observable<any>{
    return this.http.get(`${environment.apiURL}/CentrosDeResultados`)
  }

  getCentroDeResultados(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/CentrosDeResultados/${id}`)
  }

  postCentroDeResultados(centroDeResultados: CentroDeResultados): Observable<any>{
    return this.http.post(`${environment.apiURL}/CentrosDeResultados`, centroDeResultados);
  }

  putCentroDeResultados(id: number, centroDeResultados: CentroDeResultados): Observable<any>{
    return this.http.put(`${environment.apiURL}/CentrosDeResultados/${id}`, centroDeResultados);
  }

  deleteCentroDeResultados(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/CentrosDeResultados/${id}`);
  }

}
