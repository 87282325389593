import { Component, OnInit } from '@angular/core';
import { ClasseProduto } from 'src/app/classes/ClasseProduto';
import { ClasseProdutoService } from 'src/app/services/classe-produto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { ClasseProdutoTranslate } from './classe-produto.translate';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-classe-produto',
  templateUrl: './classe-produto.component.html',
  styleUrls: ['./classe-produto.component.scss']
})
export class ClasseProdutoComponent extends ClasseProdutoTranslate implements OnInit {

  classesProduto: ClasseProduto[];
  auxClassesProduto: ClasseProduto[];
  classeProduto: ClasseProduto;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;


  constructor(private classesProdutoService: ClasseProdutoService,
              private route: ActivatedRoute, private router: Router,
              private storageService: StorageService,
              private toasty: CustomToastyService,
              private translate: TranslateService) {
            super(translate)
            this.route.params.subscribe(res => {
              this.id = res.id;
            });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.classeProduto = new ClasseProduto();

    if(this.id > 0)
      this.selecionarClasseProduto();
    else
      this.listarClassesProduto();
  }

  selecionarClasseProduto() {
    this.carregando = true;
    this.classesProdutoService.getClasseProduto(this.id).subscribe(res =>{
      this.classeProduto = new ClasseProduto();
      this.classeProduto = res;
      this.carregando = false;
    },
    ex =>{
      Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
      this.carregando = false;
    })
  }

  listarClassesProduto(){
    this.carregando = true;
    this.classesProdutoService.getClassesProduto().subscribe(
      res => {
        this.classesProduto = res;
        this.auxClassesProduto = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarClassesProduto();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.classeProduto.Id > 0)
      this.classesProdutoService.putClasseProduto(this.classeProduto.Id, this.classeProduto).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.CLASSE_DE_PRODUTO_ALTERADA, 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.classesProdutoService.postClasseProduto(this.classeProduto).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.CLASSE_DE_PRODUTO_CADASTRADA, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(classeProdutoId: number, nome: string) {
    this.DELETAR_PARAM = this.translate.instant('DELETAR_PARAM', {value: nome});
    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.classesProdutoService.deleteClasseProduto(classeProdutoId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.CLASSE_DE_PRODUTO_DELETADA, 'success');
              this.listarClassesProduto();
              this.carregando = false;
            },
            err => {
              Swal.fire(this.ERRO, err.message, 'error');
              this.carregando = false;
            });
        }
      });
  }

  filtrarClassesProduto(){
    this.classesProduto = this.auxClassesProduto.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/estoques/classe-produto']);
  }

}
