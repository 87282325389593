import { TarefaService } from 'src/app/services/tarefa.service';
import { AtividadeService } from './../../../services/atividade.service';
import { AreaService } from './../../../services/area.service';
import { FuncionarioService } from './../../../services/funcionario.service';
import { EquipamentoService } from './../../../services/equipamento.service';
import { Component, Input, OnInit } from '@angular/core';
import { Area } from 'src/app/classes/Area';
import { Atividade } from 'src/app/classes/Atividade';
import { Equipamento } from 'src/app/classes/Equipamento';
import { Funcionario } from 'src/app/classes/Funcionario';
import { Tarefa } from 'src/app/classes/Tarefa';
import { UtilsService } from 'src/app/utils/utils.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { TranslateService } from '@ngx-translate/core';
import { FazendaService } from 'src/app/services/fazenda.service';
import { Fazenda } from 'src/app/classes/Fazenda';
import { SafraService } from 'src/app/services/safra.service';
import { Safra } from 'src/app/classes/Safra';

@Component({
  selector: 'app-lista-tarefas',
  templateUrl: './lista-tarefas.component.html',
  styleUrls: ['./lista-tarefas.component.scss']
})
export class ListaTarefasComponent implements OnInit {

  @Input() equipamentos: Equipamento[];
  @Input() implementos: Equipamento[];
  @Input() funcionarios: Funcionario[];
  @Input() atividades: Atividade[];
  @Input() areas: Area[];
  @Input() id: number = 0;
  tarefas: Tarefa[] = [];
  fazendas: Fazenda[] = [];
  safras: Safra[] = [];
  safrasFilter: Safra[] = [];
  areasFilter: Area[];
  tarefasAux: Tarefa[] = [];
  carregando: boolean = false;

  operadorDescricao: string = "Todos";
  fazendaDescricao: string = "Todos";
  safraDescricao: string = "Todos";
  equipamentoDescricao: string = "Todos";
  implementoDescricao: string = "Todos";
  statusDescricao: string = "Todos";
  periodoDescricao: string = "Todos";
  textoBusca: string = "";
  itemDetalhe: number[] = [];
  atividadeId: number;
  filtrarAtividade: boolean = false;
  disabled: false;
  localBusca: string = "";
  operadorId: number = 0;
  statusId: number = 0;
  periodoId: number = 0;
  dataInicio: Date;
  dataFinal: Date;
  ordemServicoIdFilter: string = "";
  areaIdFilter: number = 0;
  areaFilterDescricao: string = "Todos";
  safraIdFilter: number = 0;

  constructor(private tarefaService: TarefaService,
    private utils: UtilsService,
    private translate: TranslateService,
    private equipamentoService: EquipamentoService,
    private funcionarioService: FuncionarioService,
    private areaService: AreaService,
    private atividadeService: AtividadeService,
    private toasty: CustomToastyService,
    private fazendaService: FazendaService,
    private safraService: SafraService,
    private TarefaService: TarefaService,
  ) { }

  ngOnInit() {
    this.carregando = true;
    if (this.equipamentos == null || this.equipamentos.length == 0)
      this.equipamentoService.getEquipamentos().subscribe(res => {
        this.equipamentos = res;
      });

    if (this.funcionarios == null || this.funcionarios.length == 0)
      this.funcionarioService.getFuncionarios().subscribe(res => {
        this.funcionarios = res;
      });

    if (this.atividades == null || this.atividades.length == 0)
      this.atividadeService.getAtividades().subscribe(res => {
        this.atividades = res;
      });

    this.areasFilter = this.areas;

    if (this.areas == null || this.areas.length == 0)
      this.areaService.getAreas().subscribe(res => {
        this.areas = res;
        this.areasFilter = res;
      });

    this.fazendaService.getFazendas().subscribe(res => {
      this.fazendas = res;
    });

    this.safraService.getSafras().subscribe(res => {
      this.safras = res;
      this.safrasFilter = res;
    });

    setTimeout(() => {
      this.listarTarefas();
      this.carregando = false;
    }, 1000);

  }

  listarTarefas() {
    this.carregando = true;
    this.tarefaService.getTarefas().subscribe(res => {
      this.tarefas = res;
      this.tarefasAux = res;
      this.carregando = false;
    },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  selecionaFazenda(id: number) {
    if (id > 0) {
      this.fazendaDescricao = this.getFazenda(id).Nome;
    }
    else {
      this.fazendaDescricao = this.translate.instant('TODOS');
    }
    this.filtrarSafras(id);
    this.selecionaSafra(0);

    this.filtrarAreasPorFazenda(id);
    this.selecionaArea(0);
  }

  filtrarSafras(fazendaId: number) {
    if (fazendaId == 0)
      this.safrasFilter = this.safras;
    else
      this.safraService.getSafrasPorFazendas([fazendaId]).subscribe(res => {
        this.safrasFilter = res;
      });
  }

  selecionaSafra(id: number) {
    if (id > 0) {
      this.safraDescricao = this.getSafra(id).Descricao;
    }
    else {
      this.safraDescricao = this.translate.instant('TODOS');
    }
    this.safraIdFilter = id;
    this.filtrarAreasPorSafra(id);
    this.selecionaArea(0);
  }

  filtrarAreasPorSafra(safraId: number) {
    if (safraId == 0 && this.fazendaDescricao != this.translate.instant('TODOS')) {
      this.areaService.getAreasPorFazenda(this.fazendas.find(x => x.Nome == this.fazendaDescricao).Id).subscribe(res => {
        this.areasFilter = res;
      });
    }
    else if (safraId == 0)
      this.areasFilter = this.areas;
    else {
      this.areaService.getAreasPorSafras([safraId]).subscribe(res => {
        this.areasFilter = res;
      });
    }
  }

  filtrarAreasPorFazenda(fazendaId: number) {
    if (fazendaId == 0)
      this.areasFilter = this.areas;
    else {
      this.areaService.getAreasPorFazenda(fazendaId).subscribe(res => {
        this.areasFilter = res;
      });
    }
  }

  selecionaOperador(id: number) {
    if (id > 0) {
      this.operadorDescricao = this.getOperador(id).Nome;
    }
    else {
      this.operadorDescricao = this.translate.instant('TODOS');
    }
    this.operadorId = id;
    this.filtrarTarefas();
  }

  selecionaArea(id: number) {
    if (id > 0) {
      this.areaFilterDescricao = this.getArea(id).Descricao;
    }
    else {
      this.areaFilterDescricao = this.translate.instant('TODOS');
    }
    this.areaIdFilter = id;
    this.filtrarTarefas();
  }

  selecionaPeriodo(id: number) {
    switch (id) {
      case 0:
        this.periodoDescricao = this.translate.instant('TODOS');
        break;
      case 1:
        this.periodoDescricao = this.translate.instant('HOJE');
        this.dataInicio = new Date();
        this.dataFinal = new Date();
        break;
      case 2:
        this.periodoDescricao = this.translate.instant('ONTEM');
        this.dataInicio = new Date();
        this.dataFinal = new Date();
        this.dataFinal.setDate(this.dataFinal.getDate() - 1);
        this.dataInicio.setDate(this.dataInicio.getDate() - 1);
        break;
      case 3:
        this.periodoDescricao = this.translate.instant('AMANHA');
        this.dataInicio = new Date();
        this.dataFinal = new Date();
        this.dataInicio.setDate(this.dataInicio.getDate() + 1);
        this.dataFinal.setDate(this.dataFinal.getDate() + 1);
        break;
      case 4:
        this.periodoDescricao = this.translate.instant('ESTA_SEMANA');
        this.dataInicio = new Date();
        this.dataFinal = new Date();
        this.dataInicio.setDate(this.dataInicio.getDate() - this.dataInicio.getDay());
        this.dataFinal.setDate(this.dataFinal.getDate() - this.dataFinal.getDay() + 6);
        break;
      case 5:
        this.periodoDescricao = this.translate.instant('PROXIMA_SEMANA');
        this.dataInicio = new Date();
        this.dataFinal = new Date();
        this.dataInicio.setDate(this.dataInicio.getDate() - this.dataInicio.getDay() + 7);
        this.dataFinal.setDate(this.dataFinal.getDate() - this.dataFinal.getDay() + 13);
        break;
      case 6:
        this.periodoDescricao = this.translate.instant('ESTE_MES');
        this.dataInicio = new Date();
        this.dataFinal = new Date();
        this.dataInicio.setDate(1);
        this.dataFinal.setMonth(this.dataFinal.getMonth() + 1);
        this.dataFinal.setDate(0);

        break;
      case 7:
        this.periodoDescricao = this.translate.instant('PROXIMO_MES');
        this.dataInicio = new Date();
        this.dataFinal = new Date();
        this.dataInicio.setMonth(this.dataInicio.getMonth() + 1);
        this.dataInicio.setDate(1);
        this.dataFinal.setMonth(this.dataFinal.getMonth() + 2);
        this.dataFinal.setDate(0);
        break;
      case 8:
        this.periodoDescricao = this.translate.instant('ESTE_ANO');
        this.dataInicio = new Date();
        this.dataFinal = new Date();
        this.dataInicio.setMonth(0);
        this.dataFinal.setMonth(11);
        break;
    }

    this.dataInicio.setHours(0, 0, 0, 0);
    this.dataFinal.setHours(23, 59, 59, 0);
    this.periodoId = id;
    this.filtrarTarefas();
  }

  selecionaEquipamento(id: number) {

  }

  selecionaStatus(id: number) {
    if (id > 0) {
      if (id == 1)
        this.statusDescricao = this.translate.instant('PENDENTES');
      else
        this.statusDescricao = this.translate.instant('CONCLUIDAS');
    }
    else {
      this.statusDescricao = this.translate.instant('TODOS');
    }
    this.statusId = id;
    this.filtrarTarefas();
  }

  filtroGeral(tarefasFilter: Tarefa[]) {
    setTimeout(() => {
      this.carregando = true;
      let tarefaFiltrada = tarefasFilter.filter(x => (!this.atividadeId || x.AtividadeId == this.atividadeId) &&
        //(!this.localBusca || (x.LocalTexto.toLowerCase().indexOf(this.localBusca.toLowerCase()) > -1) ||
        //(x.AreaId > 0 && this.getArea(x.AreaId)?.Descricao.toLowerCase().indexOf(this.localBusca.toLowerCase()) > -1) &&
        (!this.operadorId || (x.FuncionarioId == this.operadorId || x.FuncionariosTarefa.find(y => y.FuncionarioId == this.operadorId))) &&
        (!this.statusId || x.FlagConcluido == (this.statusId == 2)) &&
        (!this.periodoId || (new Date(x.DataInicio) >= this.dataInicio && new Date(x.DataInicio) <= this.dataFinal)) &&
        (!this.areaIdFilter || x.AreaId == this.areaIdFilter) &&
        (!this.ordemServicoIdFilter || (
          x.OrdemServicoId != null
          && x.OrdemServicoId.toString().indexOf(this.ordemServicoIdFilter) > -1)
        )
      );
      this.tarefas = [];
      tarefaFiltrada.forEach(tarefa => {
        this.areasFilter.forEach(area => {
          if (tarefa.AreaId == area.Id)
            this.tarefas.push(tarefa);
        });
      });
      this.carregando = false;
    }, 1000); // Delay de 1 segundo
  }

  filtrarTarefas() {
    if (this.safraIdFilter > 0) {
      this.tarefaService.listarPorSafra(this.safraIdFilter).subscribe(res => {
        let tarefaBck = res;
        this.filtroGeral(tarefaBck);
      });
    }
    else
      this.filtroGeral(this.tarefasAux);
  }

  getOperador(id: number) {
    return this.funcionarios.find(x => x.Id == id);
  }
  getFazenda(id: number): Fazenda {
    return this.fazendas.find(x => x.Id == id);
  }
  getSafra(id: number): Safra {
    return this.safras.find(x => x.Id == id);
  }
  getEquipamento(id: number) {
    if (this.equipamentos == undefined) return;
    return this.equipamentos.find(x => x.Id == id);
  }

  getImplemento(id: number) {
    if (this.equipamentos == undefined) return;
    return this.equipamentos.find(x => x.Id == id);
  }

  getAtividade(id: number) {
    return this.atividades.find(x => x.Id == id);
  }

  getArea(id: number) {
    return this.areas.find(x => x.Id == id);
  }

  exibirDetalhe(id: number) {
    if (this.itemDetalhe.indexOf(id) > -1) {
      this.itemDetalhe = this.itemDetalhe.filter(x => x != id);
    }
    else {
      this.itemDetalhe.push(id);
    }
  }

  mesmoDia(dataInicio: Date, dataFim: Date): boolean {
    dataInicio = new Date(dataInicio);
    dataFim = new Date(dataFim);
    return dataInicio.getDate() == dataFim.getDate() && dataInicio.getMonth() == dataFim.getMonth() && dataInicio.getFullYear() == dataFim.getFullYear();
  }

  concluir(id: number) {
    let tarefa = this.tarefas.find(x => x.Id == id);
    tarefa.FlagConcluido = true;
    this.tarefaService.putTarefa(id, tarefa).subscribe(res => {
      this.utils.handleSuccess(this.translate.instant('TAREFA_CONCLUIDA'));
      this.listarTarefas();
    },
      err => {
        this.utils.getErro(err);
      });
  }

  onChangeAtividade() {
    this.filtrarTarefas()
  }


}
