export class TiposDiagnoseAudios
{
    Bytes:string = "";
    TipoDiagnoseId:string = "";
    EmpresaId: number;
    UsuarioNome:string = "";
    SafraId: number;
    AreaId: number;
    Latitude: number | null;
    Longitude: number | null;
    Tipo: string | null;
    DataCadastro: Date = new Date();
    Url: string | null;
}

