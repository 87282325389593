import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { GrupoTransacao, ListaEnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { EnumModulo } from 'src/app/classes/GrupoTransacao';
import { ItemEnum } from 'src/app/classes/ItemEnum';
import { Produto } from 'src/app/classes/Produto';
import { Transacao } from 'src/app/classes/Transacao';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { GrupoTransacaoService } from 'src/app/services/grupo-transacao.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({ selector: 'app-filtro-lateral', templateUrl: './filtro-lateral.component.html', styleUrls: ['./filtro-lateral.component.scss'] })
export class FiltroLateralComponent implements OnInit {

  constructor(
    private empresaInternaService: EmpresaInternaService,
    private grupoTransacaoService: GrupoTransacaoService,
    private transacaoService: TransacaoService,
    private produtoService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private utils: UtilsService)
    {
      this.inputProduto$.subscribe((newTerm) => {
        this.pagina = 1;
        this.fetchMore(newTerm);
      });
  }

  @Input() listaStatus: ItemEnum[];
  @Input() modulo: EnumModulo;
  @Input() filtro: FiltroTela;
  @Input() border: boolean = false;
  @Input() minHeight: number = 0;
  @Input() mostrarTipoMovimento: boolean = false;
  @Input() mostrarItem: boolean = true;
  @Output() filtrar: EventEmitter<any> = new EventEmitter();
  @Output() atualizar: EventEmitter<any> = new EventEmitter();

  empresasIntenas: EmpresaInterna[] = [];
  transacoes: Transacao[] = [];
  recolherFiltro: boolean = false;
  transacaoId: number = 0;
  empresaInternaId: number = 0;
  produtoId: number = 0;

  listaTipoMovimento = ListaEnumTipoMovimento;

  // produtos
  produtos: Produto[] = [];
  gruposProduto: GrupoProduto[] = [];
  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  termoBusca: String = '';
  pagina: number = 2;
  public inputProduto$ = new Subject<string | null>();


  ngOnInit() {
    this.filtro.DataCriacaoInicial = null;
    this.filtro.DataCriacaoFinal = null;
    this.filtro.DataEmissaoInicial = null;
    this.filtro.DataEmissaoFinal = null;

    this.listarProdutos();
    this.listarEmpreasasInternas();
    this.listarTransacoes();
  }

  onTransacaoChange(valor: number){
    console.debug(valor);
    if(!valor)
      this.filtro.TransacaoId = null;
    else{
      if(this.transacoes.find(p => p.Id == valor)){
        this.filtro.TransacaoId = valor;
      }else
      this.filtro.TransacaoId = null;
    }
  }
  onEmpresaChange(valor: number){
    console.debug(valor);
    if(!valor)
      this.filtro.EmpresaInternaId = null;
    else{
      if(this.empresasIntenas.find(p => p.Id == valor)){
        this.filtro.EmpresaInternaId = valor;
      }else
      this.filtro.EmpresaInternaId = null;
    }
  }
  onItemChange(valor: number){
    console.debug(valor);
    if(!valor)
      this.filtro.ProdutoId = null;
    else{
      if(this.produtos.find(p => p.Id == valor)){
        this.filtro.ProdutoId = valor;
      }else
        this.filtro.ProdutoId = null;
    }
  }
  toogleFiltroClick() {
    this.recolherFiltro = !this.recolherFiltro;
  }
  filtrarClick(){
    this.filtrar.emit(this.filtro)
  }

  atualizarClick(){
    this.atualizar.emit();
  }
  listarTransacoes(){
    this.grupoTransacaoService.getList()
    .subscribe((res: GrupoTransacao[]) => {
      let grupos = res.filter(g => g.Modulo == this.modulo);
      this.transacaoService.getTransacoes()
      .subscribe((res2: Transacao[]) => {
        grupos.forEach(g => {
          let transacoes = res2.filter(t => t.GrupoTransacaoId == g.Id);
          this.transacoes = this.transacoes.concat(transacoes);
        })
      }, err => {
          this.utils.getErro(err);
      })
    }, err => {
        this.utils.getErro(err)
    });
  }
  listarEmpreasasInternas(){
    this.empresaInternaService.getEmpresasInternas().subscribe(res => {
      this.empresasIntenas = res;
    }, err => {
      this.utils.getErro(err)
    });
  }
  listarProdutos(){
    this.produtoService.getTodosProdutosPaginando(1,10,0, '').subscribe(ret =>{
      this.produtos = ret.Lista;
      this.grupoProdutoService.getGruposProduto().subscribe(ret =>{
        this.gruposProduto = ret;
        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    });
  }
  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProduto.filter(x =>
      x.Id==id
    );
    let novo = new GrupoProduto();
    return grupos.length > 0 ? grupos[0] : novo;
  }
  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }
  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;
      this.produtoService.getProdutosPaginandoOS(0,this.pagina,this.termoBusca, false).subscribe(ret =>{
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.produtos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    }, 300);
  }

  public limparFiltros() {
    this.filtro = {
      TransacaoId: null,
      EmpresaInternaId: null,
      DataCriacaoInicial: null,
      DataCriacaoFinal: null,
      DataEmissaoInicial: null,
      DataEmissaoFinal: null,
      NumeroDocumento: null,
      Identificador: null,
      Status: null,
      ProdutoId: null,
      Tipo: null
    };

    this.filtrar.emit(this.filtro);
  }
}