import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ManutencaoFrota } from '../classes/gestao-lite/ManutencaoFrota';

@Injectable({
  providedIn: 'root'
})
export class ManutencaoFrotaService {

  constructor(private http: HttpClient) { }

  getList(): Observable<any>{
    return this.http.get(`${environment.apiURL}/ManutencaoFrota`)
  }
  gerarCadastro(): Observable<any>{
    return this.http.post(`${environment.apiURL}/ManutencaoFrota/GerarCadastro`, null)
  }
  post(obj: ManutencaoFrota): Observable<any>{
    return this.http.post(`${environment.apiURL}/ManutencaoFrota`, obj)
  }
  put(id: number, obj: ManutencaoFrota): Observable<any>{
    return this.http.put(`${environment.apiURL}/ManutencaoFrota/${id}`, obj)
  }
  excluir(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/ManutencaoFrota/${id}`)
  }
  exibirPlanoManutencaoFrota(ItemChecklistManutencaoFrotaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ManutencaoFrota/ExibirPlanoManutencao/${ItemChecklistManutencaoFrotaId}`)
  }
}
