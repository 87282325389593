import cep from 'cep-promise';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { Parceiro } from 'src/app/classes/parceiro/Parceiro';
import { ParceirosService } from 'src/app/services/parceiros/parceiros.service';
import { RegionaisService } from 'src/app/services/regionais/regionais.service';
import { Regional } from 'src/app/classes/regionais/Regional';
import { UtilsService } from 'src/app/utils/utils.service';
import { Empresa } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-regionais-form',
  templateUrl: './regionais-form.component.html',
  styleUrls: ['./regionais-form.component.scss']
})
export class RegionaisFormComponent implements OnInit {

  @ViewChild('empresaDiponiveis', { static: false }) empresaDiponiveis;
  @ViewChild('empresaAdicionadas', { static: false }) empresaAdicionadas;

  form: FormGroup;
  mascaraCEP = '00000-000';
  inserindo = true;
  submetido = false;
  listaParceiros: Parceiro[] = [];
  listaEmpresaDisponiveis: Empresa[] = [];
  listaEmpresaAdicionadas: Empresa[] = [];
  listaEmpresaRelacionadas: Empresa[] = [];
  colDefs: ColDef[] = [];
  // gridApiDisponiveis: GridApi;
  // gridApiAdicionados: GridApi;
  id: string = null;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private regionaisService: RegionaisService,
    private parceirosService: ParceirosService,
    private empresaService: EmpresaService,
    private utils: UtilsService
  ) {
    this.route.params.subscribe(res => this.id = res.id)
  }

  ngOnInit() {
    this.configurar();
    this.criarForm(new Regional());
    this.pegarListaParceiros();
    this.pegarListaEmpresas(this.id);
    this.setarRegional();
  }

  get formControl() { return this.form.controls; }

  setarRegional() {
    if (this.router.url.search('inserir') == -1) {
      this.inserindo = false;
      this.regionaisService.ObterPorId(this.id).subscribe(
        res => this.atualizarForm(res),
        error => this.utils.getErro(error)
      )
    }
  }

  criarForm(regional: Regional) {
    this.form = this.formBuilder.group({
      Id: [regional.Id],
      Nome: [regional.Nome, [Validators.required]],
      ParceiroId: [regional.ParceiroId, [Validators.required]],
      CEP: [regional.CEP, [Validators.required, Validators.pattern("\\d{8}")]],
      UF: [regional.UF, [Validators.required, Validators.minLength(2)]],
      Cidade: [regional.Cidade, [Validators.required, Validators.minLength(4)]],
      Bairro: [regional.Bairro, [Validators.required]],
      Endereco: [regional.Endereco, [Validators.required]],
      Numero: [regional.Numero, [Validators.required]],
      Referencia: [regional.Referencia],
      Contato: [regional.Contato],
      Telefone: [regional.Telefone],
      Situacao: [regional.Situacao],
      AreaTotal: [regional.AreaTotal, [Validators.required, Validators.min(1)]],
    })
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'NomeFantasia', headerName: 'Nome da Empresa', flex: 1 },
      { field: 'RazaoSocial', headerName: 'Razão Social', flex: 1 }
    ]
  }

  atualizarForm(regional: Regional) {
    this.form.patchValue({
      Id: regional.Id,
      Nome: regional.Nome,
      ParceiroId: regional.ParceiroId,
      CEP: regional.CEP,
      UF: regional.UF,
      Cidade: regional.Cidade,
      Bairro: regional.Bairro,
      Endereco: regional.Endereco,
      Numero: regional.Numero,
      Referencia: regional.Referencia,
      Contato: regional.Contato,
      Telefone: regional.Telefone,
      Situacao: regional.Situacao,
      AreaTotal: regional.AreaTotal,
    } as Regional);
  }

  pegarListaParceiros() {
    this.parceirosService.Listar().subscribe(
      res => { this.listaParceiros = res; },
      error => { this.utils.getErro(error); }
    )
  }

  onListaEmpresaAdicionadasEmitted(event) {
    this.listaEmpresaAdicionadas = event;
  }
  onListaEmpresaRelacionadasEmitted(event) {
    this.listaEmpresaRelacionadas = event;
  }

  buscarPorCEP() {
    if (this.form.value.CEP.length != 8) return;
    cep(this.form.value.CEP)
      .then(res => {
        this.form.patchValue({
          UF: res.state,
          Cidade: res.city,
          Endereco: res.street,
          Bairro: res.neighborhood
        })
      })
      .catch(e => console.error("erro", e))
  }

  cancelar() {
    this.router.navigate(['/cadastros/regionais'])
  }

  onSubmit() {
    this.submetido = true;
    if (!this.form.valid) return;

    let result: Observable<Regional | null> = null;

    if (this.inserindo) {
      result = this.regionaisService.Inserir(this.form.value, this.listaEmpresaAdicionadas);
    } else {
      result = this.regionaisService.Atualizar(
        this.form.value, this.id,
        this.listaEmpresaAdicionadas, this.listaEmpresaRelacionadas
      );
    }

    result.subscribe(
      res => {
        this.router.navigate(['/cadastros/regionais']);
        this.utils.handleSuccess('Regional cadastrado com sucesso!');
      },
      error => console.error(error)
    )
  }

  private pegarListaEmpresas(id: string) {
    this.empresaService.getEmpresas().subscribe(
      res => {
        this.listaEmpresaDisponiveis = res;
        if (id != null) {
          this.pegarListaEmpresasRelacionadas(id);
        }
      },
      error => { this.utils.getErro(error); }
    );
  }

  private pegarListaEmpresasRelacionadas(id: string) {
    this.regionaisService.ObterEmpresasRelacionadas(id).subscribe(
      res => {
        this.listaEmpresaRelacionadas = res.Empresa;
      },
      error => this.utils.getErro(error)
    )
  }

}
