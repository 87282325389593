import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Setor } from '../classes/Setor';

@Injectable({
  providedIn: 'root'
})
export class SetorService {  

  constructor(private http: HttpClient) { }   

  getSetores(): Observable<any>{
    return this.http.get(`${environment.apiURL}/setor`)
  }

  getSetor(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/setor/${id}`)
  }

  postSetor(setor: Setor): Observable<any>{
    return this.http.post(`${environment.apiURL}/setor`, setor);
  }

  putSetor(id: number, setor: Setor): Observable<any>{
    return this.http.put(`${environment.apiURL}/setor/${id}`, setor);
  }

  deleteSetor(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/setor/${id}`);
  }

  getSetorPorArea(areaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/setor/area/${areaId}`)
  }

  getSetoresPorFazenda(fazendaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/setor/fazenda/${fazendaId}`)
  }
}
