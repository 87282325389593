import { Component, Input, OnInit } from '@angular/core';
import { TiposDiagnosePragas } from 'src/app/classes/tipos-diagnose/TiposDiagnosePragas';

@Component({
  selector: 'app-diagnose-pragas',
  templateUrl: './diagnose-pragas.component.html',
  styleUrls: ['./diagnose-pragas.component.scss']
})
export class DiagnosePragasComponent implements OnInit {

  @Input() diagnosePraga: TiposDiagnosePragas;

  constructor() { }

  ngOnInit() {
  }

}
