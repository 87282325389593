import { Component, OnInit } from '@angular/core';
import { TipoLancamento } from 'src/app/classes/TipoLancamento';
import { TipoLancamentoService } from 'src/app/services/tipoLancamento.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tipo-lancamento',
  templateUrl: './tipo-lancamento.component.html',
  styleUrls: ['./tipo-lancamento.component.scss']
})
export class TipoLancamentoComponent implements OnInit {

  tiposLancamento: TipoLancamento[];
  auxTiposLancamento: TipoLancamento[];
  tipoLancamento: TipoLancamento;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";

  // tradução
  TIPO_DE_LANCAMENTO_ALTERADO: string = 'Tipo de Lançamento alterado!';
  TIPO_DE_LANCAMENTO_CADASTRADO: string = 'Tipo de Lançamento cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  TIPO_DE_LANCAMENTO_DELETADO: string = 'Tipo de Lançamento deletado!';

  configurarTraducao() {
    this.TIPO_DE_LANCAMENTO_ALTERADO = this.translate.instant('TIPO_DE_LANCAMENTO_ALTERADO');
    this.TIPO_DE_LANCAMENTO_CADASTRADO = this.translate.instant('TIPO_DE_LANCAMENTO_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.TIPO_DE_LANCAMENTO_DELETADO = this.translate.instant('TIPO_DE_LANCAMENTO_DELETADO');
  }

  constructor(private tipoLancamentoService: TipoLancamentoService, 
              private route: ActivatedRoute, 
              private router: Router,
              private utils: UtilsService,
              private translate: TranslateService,
              private toasty: CustomToastyService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    this.tipoLancamento = new TipoLancamento();

    if(this.id > 0)
      this.selecionartipoLancamento();
    else
      this.listartipoLancamentos();
  }

  selecionartipoLancamento() {
    this.carregando = true;
    this.tipoLancamentoService.getTipoLancamento(this.id).subscribe(res =>{
      this.tipoLancamento = new TipoLancamento();
      this.tipoLancamento = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  listartipoLancamentos(){
    this.carregando = true;
    this.tipoLancamentoService.getTiposLancamento().subscribe(
      res => {
        this.tiposLancamento = res;
        this.auxTiposLancamento = res;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.tipoLancamento.Id > 0)
      this.tipoLancamentoService.putTipoLancamento(this.tipoLancamento.Id, this.tipoLancamento).subscribe(
        res => {
          this.utils.handleSuccess(this.TIPO_DE_LANCAMENTO_ALTERADO);
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.tipoLancamentoService.postTipoLancamento(this.tipoLancamento).subscribe(
        res => {
          this.tipoLancamento = res;
          this.utils.handleSuccess(this.TIPO_DE_LANCAMENTO_CADASTRADO);
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  excluir(tipoLancamentoId: number, descricao: string) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR + ' '+ descricao + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.tipoLancamentoService.deleteTipoLancamento(tipoLancamentoId).subscribe(
            res => {
              this.utils.handleSuccess(this.TIPO_DE_LANCAMENTO_DELETADO);
              this.listartipoLancamentos();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrartipoLancamentos(){
    this.tiposLancamento = this.auxTiposLancamento.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/gerenciador/tipo-lancamento']);
  }

}
