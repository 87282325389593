import { LocalUsoIndicador } from "./LocalUsoIndicador";
import { TipoIndicador } from "./TipoIndicador";


export class Indicador {
  Id:string = "";
  Descricao:string = "";
  Tipo: TipoIndicador;
  LocalUso: LocalUsoIndicador;
  OpcoesLista: string | null;
  TipoDiagnoseId: string | null;
}
