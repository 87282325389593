import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { StorageService } from './storage.service';
import { Usuario } from '../classes/Usuario';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  perfil: number = 0;
  constructor(private router: Router, private storageService: StorageService, private http: HttpClient) { }

  usuarioEstaAutenticado() {
    if (this.storageService.getUsuario() &&
      this.storageService.getToken()) {
      return true;
    }

    return false;
  }

  logout() {
    this.perfil = this.storageService.getUsuario().PerfilInt;
    this.storageService.removerUsuario();
    this.storageService.removerToken();
    this.storageService.removerEmpresa();
    this.storageService.removerFazenda();
    if (this.perfil == 4) {
      this.router.navigate(['/loginfornecedor']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  logar(username, password): Observable<any> {
    const body = new HttpParams()
      .set('UserName', username)
      .set('Password', password)
      .set('grant_type', 'password');

    return this.http.post(`${environment.apiURL}/token`,
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  logarFornecedor(username, password): Observable<any> {
    const body = new HttpParams()
      .set('UserName', username)
      .set('Password', password)
      .set('grant_type', 'password');

    return this.http.post(`${environment.apiURL}/Token/fornecedor`,
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }

  logarFornecedorAutomatico(token: string): Observable<any> {
    return this.http.post(`${environment.apiURL}/Token/fornecedor/token/${token}`, null);
  }

  getUsuarioLogado(): Observable<any> {
    return this.http.get(`${environment.apiURL}/usuarios/usuariologado`)
  }
}
