import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Usuario } from 'src/app/classes/Usuario';
import { MasksService } from 'src/app/utils/masks.service';
import { UsuarioHorarioAcessoValidacaoSharedService } from './usuario-horario-acesso-validacao-shared.service';

@Component({
  selector: 'app-usuario-horario-acesso',
  templateUrl: './usuario-horario-acesso.component.html',
  styleUrls: ['./usuario-horario-acesso.component.scss']
})
export class UsuarioHorarioAcessoComponent implements OnInit, OnChanges {

  @Input() usuario: Usuario;

  horarioInicio = '00:00:00'
  horarioFim =  '00:00:00'

  constructor(public masks: MasksService, public validacaoService: UsuarioHorarioAcessoValidacaoSharedService) { }

  ngOnInit() {
    if(this.usuario.Id !== undefined)
      this.validate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.usuario) {
      this.ngOnInit();
    }
  }

  toDate(timeString) {
    if (timeString == undefined) return;
    const timeParts = timeString.split(":");

    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);
    const second = parseInt(timeParts[1], 10);

    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(second);

    return date;
  }

  onInicioChange(horario) {
    this.horarioInicio = horario;
    this.usuario.DiaHorarioAcessoUsuario.Domingo.Inicio =  horario;
    this.usuario.DiaHorarioAcessoUsuario.Segunda.Inicio = horario;
    this.usuario.DiaHorarioAcessoUsuario.Terca.Inicio = horario;
    this.usuario.DiaHorarioAcessoUsuario.Quarta.Inicio = horario;
    this.usuario.DiaHorarioAcessoUsuario.Quinta.Inicio = horario;
    this.usuario.DiaHorarioAcessoUsuario.Sexta.Inicio = horario;
    this.usuario.DiaHorarioAcessoUsuario.Sabado.Inicio = horario;
    this.validate();
  }

  onFimChange(horario) {
    this.horarioFim = horario;
    this.usuario.DiaHorarioAcessoUsuario.Domingo.Fim = horario;
    this.usuario.DiaHorarioAcessoUsuario.Segunda.Fim = horario;
    this.usuario.DiaHorarioAcessoUsuario.Terca.Fim = horario;
    this.usuario.DiaHorarioAcessoUsuario.Quarta.Fim = horario;
    this.usuario.DiaHorarioAcessoUsuario.Quinta.Fim = horario;
    this.usuario.DiaHorarioAcessoUsuario.Sexta.Fim = horario;
    this.usuario.DiaHorarioAcessoUsuario.Sabado.Fim = horario;
    this.validate();
  }

  onDomingoFimChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Domingo.Fim = horario;
    this.validate();
  }
  onSegundaFimChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Segunda.Fim = horario;
    this.validate();
  }
  onTercaFimChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Terca.Fim = horario;
    this.validate();
  }
  onQuartaFimChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Quarta.Fim = horario;
    this.validate();
  }
  onQuintaFimChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Quinta.Fim = horario;
    this.validate();
  }
  onSextaFimChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Sexta.Fim = horario;
    this.validate();
  }
  onSabadoFimChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Sabado.Fim = horario;
    this.validate();
  }

  onDomingoInicioChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Domingo.Inicio = horario;
    this. validate();
  }
  onSegundaInicioChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Segunda.Inicio = horario;
    this.validate();
  }
  onTercaInicioChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Terca.Inicio = horario;
    this.validate();
  }
  onQuartaInicioChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Quarta.Inicio = horario;
    this.validate();
  }
  onQuintaInicioChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Quinta.Inicio = horario;
    this.validate();
  }
  onSextaInicioChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Sexta.Inicio = horario;
    this.validate();
  }
  onSabadoInicioChange(horario) {
    this.usuario.DiaHorarioAcessoUsuario.Sabado.Inicio = horario;
    this.validate();
  }

  validate() {
    this.validacaoService.validarHorarios(this.usuario.DiaHorarioAcessoUsuario);
  }
}
