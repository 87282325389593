import { ContatoFornecedor } from "./ContatoFornecedor";

export class Fornecedores{
    Id: number;
    RazaoSocial:string = "";
    NomeFantasia:string = "";
    CpfCnpj:string = "";
    Contatos:ContatoFornecedor[];
    EmpresaId: number;
    CodVerificacao:string = "";
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId:number;
    UsuarioAlteracaoId:number;
    FlagExcluido:boolean = false;
    FornecedorParticipante: boolean;
}
