import { Usuario } from 'src/app/classes/Usuario';
import { GrupoProdutoService } from './../../../../services/grupo-produto.service';
import { Produto } from './../../../../classes/Produto';
import { OrdemServicoService } from './../../../../services/ordem-servico.service';
import { EmpresaService } from './../../../../services/empresa.service';
import { StorageService } from './../../../../services/storage.service';
import { Empresa } from './../../../../classes/Empresa';
import { Observable, Subject } from 'rxjs';
import { Atividade } from './../../../../classes/Atividade';
import { GrupoOperacao } from './../../../../classes/GrupoOperacao';
import { GrupoOperacaoService } from './../../../../services/grupo-operacao.service';
import { EnumStatusOS } from './../../../../classes/OrdemServico';
import { CulturaService } from './../../../../services/cultura.service';
import { Cultura } from './../../../../classes/Cultura';
import { CultivarService } from './../../../../services/cultivar.service';
import { Cultivar } from './../../../../classes/Cultivar';
import { PlanejamentoService } from './../../../../services/planejamento.service';
import { AreaService } from './../../../../services/area.service';
import { SafraService } from './../../../../services/safra.service';
import { FazendaService } from './../../../../services/fazenda.service';
import { AnoSafraService } from './../../../../services/ano-safra.service';
import { Area } from './../../../../classes/Area';
import { Safra } from './../../../../classes/Safra';
import { Fazenda } from './../../../../classes/Fazenda';
import { AnoSafra } from './../../../../classes/AnoSafra';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Planejamento } from 'src/app/classes/Planejamento';
import Swal from 'sweetalert2';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Router } from '@angular/router';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { SubstituirProduto } from 'src/app/classes/SubstituirProduto';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { TranslateService } from '@ngx-translate/core';
import { auxIntegracaoService } from 'src/app/services/auxIntegracaoService';
import { auxPossuiIntegracao } from 'src/app/classes/auxiliar/auxPossuiIntegracao';

@Component({
  selector: 'app-detalhamento-tab-lateral',
  templateUrl: './detalhamento-tab-lateral.component.html',
  styleUrls: ['./detalhamento-tab-lateral.component.scss']
})
export class DetalhamentoTabLateralComponent implements OnInit, OnChanges, AfterViewInit {

  anosSafras: AnoSafra[];
  fazendas: Fazenda[];
  safras: Safra[];
  areas: Area[];
  planejamentos: Planejamento[];
  cultivares: Cultivar[];
  culturas: Cultura[];

  @Input() status: EnumStatusOS[];
  @Input() periodo: number = 0;
  @Input() carregando: boolean;
  @Input() atividades: Atividade[];
  @Input() gruposOperacao: GrupoOperacao[];
  @Input() semAgrupamento: boolean;
  @Input() semStatus: boolean;
  @Input() semPeriodo: boolean;
  @Input() semVisualizacao: boolean;
  @Input() dataInicio: Date;
  @Input() dataFim: Date;

  @Output() filtrar: EventEmitter<any> = new EventEmitter();
  @Output() toogleFiltros: EventEmitter<any> = new EventEmitter();
  @Output() exibeAcessoRapido: EventEmitter<any> = new EventEmitter();

  usuario: Usuario;
  atividadesPorGrupo: Atividade[] = [];
  anoSafra: AnoSafra = new AnoSafra();
  fazendasFiltro: number[] = [];
  safrasFiltro: number[] = [];
  planejamentosFiltro: number[] = [];
  atividadesFiltro: number[] = [];
  gruposOperacaoFiltro: number[] = [];
  processoPlanejamentoFiltro: number[] = [];
  processosPlanejamento: ProcessoPlanejamento[] = [];

  statusAberta: boolean = false;
  statusAguardandoLiberacao: boolean = false;
  statusPendente: boolean = false;
  statusParcialmenteConcluida: boolean = false;
  statusConcluida: boolean = false;
  statusCancelada: boolean = false;

  agruparPorFazenda: boolean = false;
  agruparPorSafra: boolean = false;
  agruparPorArea: boolean = false;
  agruparPorEtapa: boolean = true;

  tipoVisualizacao: number = 1;

  recolherFiltro: boolean = false;
  @Input() acessoRapido: boolean = false;

  opcaoAtalho: string;
  tituloAtalho: string;
  empresa: Empresa = new Empresa();
  osImpressa: boolean = false;
  permissaoFazendas: boolean = true;

  idOSAbrir: number = 0;

  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  pagina: number = 2;
  termoBusca: String = '';
  inserindo: boolean = false;
  produtos: Produto[];
  gruposProdutos: GrupoProduto[];
  public inputProduto$ = new Subject<string | null>();

  substituirProduto: SubstituirProduto = new SubstituirProduto();

  auxPossuiIntegracao: auxPossuiIntegracao = new auxPossuiIntegracao();
  // form
  form: any;
  isSubmit: boolean = false;

  // tradução
  ATENCAO: string = 'Atenção!';
  SELECIONE_PELO_MENOS_UMA_FAZENDA_PRA_TERMINAR: string = 'Selecione pelo menos uma Fazenda pra terminar!';
  LIBERACAO_DE_ORDEM_DE_SERVICO: string = 'Liberação de Ordem de Serviço';
  APONTAMENTO_DE_ORDEM_DE_SERVICO: string = 'Apontamento de Ordem de Serviço';
  ANTECIPACAO_DE_ORDEM_DE_SERVICO: string = 'Antecipação de Ordem de Serviço';
  INCLUIR_ORDEM_DE_SERVICO: string = 'Incluir Ordem de Serviço';
  ERRO: string = 'Erro!';
  GERAR_OS: string = 'Gerar OS?';
  GERA_TODAS_AS_ORDENS_DE_SERVICO_DE_ACORDO_COM_OS_PLANEJAMENTOS_DA_EMPRESA: string = 'Gera todas as ordens de serviço de acordo com os planejamentos da empresa!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  FEITO: string = 'Feito!';
  ORDENS_DE_SERVICOS_GERADAS: string = 'Ordens de serviços geradas!';
  NAO_ENCERRADAS: string = 'Não Encerradas!';
  ALGUMAS_ORDENS_DE_SERVICOS_NAO_FORAM_GERADAS_POIS_NAO_FOI_ENCERRADO_O_PLANTIO_DA_SAFRA: string = 'Algumas Ordens de Serviços não foram geradas pois não foi encerrado o plantio da Safra!';
  ORDEM_DE_SERVICO_NAO_ENCONTRADA: string = 'Ordem de serviço não encontrada!';
  O_PRODUTO_NOVO_NAO_PODE_SER_O_MESMO_DO_PRODUTO_A_SER_SUBSTITUIDO: string = 'O Produto novo não pode ser o mesmo do Produto a ser substituído!';
  PRODUTOS_SUBSTITUIDOS_BR_ORDENS_DE_SERVICO_AFETADAS_: string = 'Produtos substituídos! <br> Ordens de Serviço afetadas: ';
  NENHUMA_ORDEM_DE_SERVICO_AFETADA: string = 'Nenhuma Ordem de Serviço afetada';

  constructor(
    private anoSafraService: AnoSafraService,
    private fazendaService: FazendaService,
    private safraService: SafraService,
    private areaService: AreaService,
    private planejamentoService: PlanejamentoService,
    private cultivarService: CultivarService,
    private culturaService: CulturaService,
    private router: Router,
    private storageService: StorageService,
    private empresaService: EmpresaService,
    private ordemServicoService: OrdemServicoService,
    private toasty: CustomToastyService,
    private produtoService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private processoPlanejamentoService: ProcessoPlanejamentoService,
    private integracaoService: auxIntegracaoService,
    private translate: TranslateService
  ) {
    this.inputProduto$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMore(newTerm);
    });
  }

  ngAfterViewInit(): void {
    this.toogleAcessoRapido();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.carregaStatus();

    this.atividadesPorGrupo = this.atividades;
    if (this.atividades) {
      this.atividadesPorGrupo.forEach(a => {
        if (this.gruposOperacao.filter(g => g.Id == a.GrupoOperacaoId).length > 0)
          a.GrupoOperacaoDescricao = this.gruposOperacao.filter(g => g.Id == a.GrupoOperacaoId)[0].Descricao;
      });
    }
  }

  ngOnInit() {
    this.integracaoService.getIntegracao().then(res => {
      this.auxPossuiIntegracao = res;
    });

    this.periodo = 0;
    this.dataInicio = new Date();
    this.dataFim = new Date();
    this.dataFim.setDate(this.dataInicio.getDate() + 30);

    //this.filtrarClick();

    this.culturaService.getCulturas().subscribe(ret => {
      this.culturas = ret;
    });


    this.anoSafraService.getAnosSafras().subscribe(ret => {
      this.anosSafras = ret;

      if (this.anosSafras.length == 1)
        this.anoSafra = this.anosSafras[0];
    });

    this.fazendaService.getFazendas().subscribe(ret => {
      this.fazendas = ret;
    });

    this.cultivarService.getCultivares().subscribe(ret => {
      this.cultivares = ret;
    });

    this.processoPlanejamentoService.getProcessosPlanejamento().subscribe(ret => {
      this.processosPlanejamento = ret;
    });

    this.anoSafra.Id = 0;
    this.fazendasFiltro = [];
    this.permissaoFazendas = this.storageService.getUsuario().PermissaoUsuario.AcessoTodasFazendas;
    if(this.storageService.getFazenda()){
      this.fazendasFiltro.push(this.storageService.getFazenda().Id);
      this.selecionarFazenda();
    }

    this.usuario = this.storageService.getUsuario();
  }

  selecionarFazenda() {
    this.safrasFiltro = [];
    this.planejamentosFiltro = [];

    if (this.fazendasFiltro.length == 0) {
      this.safras = [];
      this.areas = [];
      return;
    }

    this.carregando = true;
    this.areaService.getAreasPorFazendas(this.fazendasFiltro).subscribe(ret => {
      this.areas = ret
      this.carregando = false;
    });

    this.carregando = true;
    this.safraService.getSafrasPorAnoFazendas(0, this.fazendasFiltro).subscribe(ret =>{
      this.safras = ret;
      this.carregando = false;
    });
  }

  selectionarSafra() {
    if (this.safrasFiltro.length == 0) {
      this.planejamentos = [];
      this.planejamentosFiltro = [];
      return;
    }
    this.carregando = true;
    this.planejamentoService.getPlanejamentosPorSafras(this.safrasFiltro).subscribe(ret => {
      this.planejamentos = ret;

      this.planejamentos.forEach(p => {
        p.DescricaoArea = this.getArea(p.AreaId).Descricao + ' (' + this.getCultivar(p.CultivarId).Descricao + ') ' + p.AreaPlantada + ' Ha';
      });

      this.planejamentosFiltro = [];
      this.carregando = false;
    });
  }

  selecionarGrupoOperacao() {
    if (this.gruposOperacaoFiltro.length == 0) {
      this.atividadesPorGrupo = this.atividades;
    } else {
      let atividades = [];
      this.gruposOperacaoFiltro.forEach(g => {
        this.atividades.filter(a => a.GrupoOperacaoId == g).forEach(a => atividades.push(a));
      });

      this.atividadesPorGrupo = atividades;
    }
  }
  adicionarERP()
  {
    if(this.auxPossuiIntegracao.possuiIntegracao)
      this.produtos.forEach(prod =>{
        if (!prod.Descricao.includes('IDERP:')) {
          prod.Descricao = prod.Descricao + ' - IDERP: ' + prod.IdERP;
        }
    });
  }
  selecionaStatus() {
    this.status = [];

    if (this.statusAberta)
      this.status.push(EnumStatusOS.Aberta);

    if (this.statusAguardandoLiberacao) {
      this.status.push(EnumStatusOS.Aberta);
      this.status.push(EnumStatusOS.AguardandoLiberacao);
    }

    if (this.statusPendente) {
      this.status.push(EnumStatusOS.Pendente);
      this.status.push(EnumStatusOS.ParcialmenteConcluida);
    }

    if (this.statusParcialmenteConcluida)
      this.status.push(EnumStatusOS.ParcialmenteConcluida);

    if (this.statusConcluida)
      this.status.push(EnumStatusOS.Concluida);

    if (this.statusCancelada)
      this.status.push(EnumStatusOS.Cancelada);
  }

  carregaStatus() {
    if (this.status)
      this.status.forEach(s => {
        if (s == EnumStatusOS.AguardandoLiberacao)
          this.statusAguardandoLiberacao = true;

        if (s == EnumStatusOS.Pendente)
          this.statusPendente = true;

        if (s == EnumStatusOS.ParcialmenteConcluida)
          this.statusParcialmenteConcluida = true;

        if (s == EnumStatusOS.Concluida)
          this.statusConcluida = true;

        if (s == EnumStatusOS.Cancelada)
          this.statusCancelada = true;
      });
  }

  getCultura(id: number): Cultura {
    if (!this.culturas)
      return new Cultura();

    let cultura = this.culturas.filter(x =>
      x.Id == id
    );

    return cultura.length > 0 ? cultura[0] : new Cultura();
  }

  getCultivar(id: number): Cultivar {
    if (!this.cultivares)
      return new Cultivar();

    let cultivar = this.cultivares.filter(x =>
      x.Id == id
    );

    return cultivar.length > 0 ? cultivar[0] : new Cultivar();
  }

  getArea(id: number): Area {
    if (!this.areas)
      return new Area();

    let area = this.areas.filter(x =>
      x.Id == id
    );

    return area.length > 0 ? area[0] : new Area();
  }

  getSafra(id: number): Safra {
    if (!this.safras)
      return new Safra();

    let safra = this.safras.filter(x =>
      x.Id == id
    );

    return safra.length > 0 ? safra[0] : new Safra();
  }

  getFazenda(id: number): Fazenda {
    if (!this.fazendas)
      return new Fazenda();

    let fazendas = this.fazendas.filter(x =>
      x.Id == id
    );

    return fazendas.length > 0 ? fazendas[0] : new Fazenda();
  }

  filtrarClick(toogle?: boolean) {
    this.configurarTraducao();
    if (!this.fazendasFiltro || this.fazendasFiltro.length == 0) {
      Swal.fire(this.ATENCAO, this.SELECIONE_PELO_MENOS_UMA_FAZENDA_PRA_TERMINAR, 'warning');
      return;
    }

    this.filtrar.emit({
      Status: this.status ? this.status : [],
      Periodo: this.periodo ? this.periodo : 0,
      AnoSafraId: this.anoSafra && this.anoSafra.Id ? this.anoSafra.Id : 0,
      Fazenda: this.fazendasFiltro,
      Safra: this.safrasFiltro,
      Planejamento: this.planejamentosFiltro,
      Operacao: this.gruposOperacaoFiltro,
      Atividade: this.atividadesFiltro,
      AgruparPorFazenda: this.agruparPorFazenda,
      AgruparPorSafra: this.agruparPorSafra,
      AgruparPorArea: this.agruparPorArea,
      AgruparPorEtapa: this.agruparPorEtapa,
      tipoVisualizacao: this.tipoVisualizacao,
      dataInicio: this.dataInicio,
      dataFim: this.dataFim,
      OsImpressa: this.osImpressa,
      ProcessosPlanejamento: this.processoPlanejamentoFiltro
    });
    if (toogle)
      this.toogleFiltroClick();
  }

  toogleFiltroClick() {
    this.recolherFiltro = !this.recolherFiltro;
    this.toogleFiltros.emit(this.recolherFiltro);
  }

  toogleAcessoRapido(fechar?: boolean) {
    this.opcaoAtalho = "";
    if (fechar)
      this.acessoRapido = false;
    else
      this.acessoRapido = !this.acessoRapido;
    this.exibeAcessoRapido.emit(this.acessoRapido);
  }

  selecionaAtalho(opcao: string) {
    this.configurarTraducao();
    this.opcaoAtalho = opcao;
    switch (opcao) {
      case "liberar":
        this.tituloAtalho = this.LIBERACAO_DE_ORDEM_DE_SERVICO;
        break;
      case "apontar":
        this.tituloAtalho = this.APONTAMENTO_DE_ORDEM_DE_SERVICO;
        break;
      case "antecipar":
        this.tituloAtalho = this.ANTECIPACAO_DE_ORDEM_DE_SERVICO;
        this.selecionarEmpresa();
        break;
      case "incluir":
        this.tituloAtalho = this.INCLUIR_ORDEM_DE_SERVICO;
        this.opcaoAtalho = null;
        //this.router.navigate(['/ordem-servico/ordem-servico/inserir']);
        window.location.href = "/#/ordem-servico/ordem-servico/inserir";
        break;
      default:
        break;
    }

  }

  continuarAtalho() {
    this.statusPendente = false;
    this.statusAguardandoLiberacao = false;
    this.dataInicio = new Date();
    this.configurarTraducao();
    if (this.fazendasFiltro.length > 0) {
      switch (this.opcaoAtalho) {
        case "liberar":
          this.tituloAtalho = this.LIBERACAO_DE_ORDEM_DE_SERVICO;
          this.statusAguardandoLiberacao = true;
          this.dataInicio.setDate(this.dataInicio.getDate() - (365 * 2));
          this.dataFim.setDate(this.dataInicio.getDate() + (365 * 2));
          break;
        case "apontar":
          this.tituloAtalho = this.APONTAMENTO_DE_ORDEM_DE_SERVICO;
          this.statusPendente = true;
          this.statusAguardandoLiberacao = false;
          this.dataInicio.setDate(this.dataInicio.getDate() - (365 * 2));
          this.dataFim.setDate(this.dataInicio.getDate() + (365 * 2));
          break;
        case "antecipar":
          this.tituloAtalho = this.ANTECIPACAO_DE_ORDEM_DE_SERVICO;
          break;
        default:
          break;
      }
      this.selecionaStatus();
      this.filtrarClick(false);
      this.toogleAcessoRapido(true);
      this.recolherFiltro = true;
      this.toogleFiltros.emit(this.recolherFiltro);
    }
  }

  pularAtalho() {
    this.opcaoAtalho = "pular";
    this.toogleAcessoRapido(true);
  }

  selecionarEmpresa() {
    this.configurarTraducao();
    this.carregando = true;
    let id = this.storageService.getEmpresa().Id;
    this.empresaService.getEmpresa(id).subscribe(res =>{
      this.empresa = new Empresa();
      this.empresa = res;
      this.carregando = false;
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  gerarOS() {
    this.configurarTraducao();
    Swal.fire({
      title: this.GERAR_OS,
      text: this.GERA_TODAS_AS_ORDENS_DE_SERVICO_DE_ACORDO_COM_OS_PLANEJAMENTOS_DA_EMPRESA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.carregando = true;

        this.empresaService.putEmpresa(this.empresa.Id, this.empresa).subscribe(
          res => {
            this.ordemServicoService.gerarOrdemServico(this.empresa.Id).subscribe(
              res => {
                this.selecionarEmpresa();
                this.toasty.show(this.FEITO, this.ORDENS_DE_SERVICOS_GERADAS, 'success');

                let retorno = res.split(';');
                if (retorno && retorno.length > 0 && retorno[0] != "") {
                  this.toasty.show(this.NAO_ENCERRADAS, this.ALGUMAS_ORDENS_DE_SERVICOS_NAO_FORAM_GERADAS_POIS_NAO_FOI_ENCERRADO_O_PLANTIO_DA_SAFRA, 'warning');
                }

                this.carregando = false;
              },
              err => {
                Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
                this.carregando = false;
              });
          },
          err => {
            Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
            this.carregando = false;
          });
      }
    });
  }

  abrirOS(event, click: boolean) {
    this.configurarTraducao();
    if (!event || event.keyCode == 13 || click) {
      this.ordemServicoService.getOrdemServico(this.idOSAbrir).subscribe(res => {
        this.router.navigate(['/ordem-servico/ordem-servico/' + this.idOSAbrir]);
      },
        ex => {
          Swal.fire(this.ERRO, this.ORDEM_DE_SERVICO_NAO_ENCONTRADA, 'error');
        })
    }
  }

  listarProdutos() {
    this.produtoService.getTodosProdutosPaginando(1, 10, 0, '').subscribe(ret => {
      this.produtos = ret.Lista;
      this.grupoProdutoService.getGruposProduto().subscribe(ret =>{
        this.gruposProdutos = ret;
        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
        this.adicionarERP();
      });
    });
  }
  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }

  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;
      this.produtoService.getProdutosPaginandoOS(0, this.pagina, this.termoBusca, false).subscribe(ret => {
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.produtos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
        this.adicionarERP();
      });
    }, 300);
  }

  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProdutos.filter(x =>
      x.Id == id
    );
    let novo = new GrupoProduto();
    return grupos.length > 0 ? grupos[0] : novo;
  }

  getProduto(id: number): Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';

    if (!this.produtos)
      return novo;

    let produto = this.produtos.filter(x =>
      x.Id == id
    );
    return produto.length > 0 ? produto[0] : novo;
  }

  salvarSubstituirProduto(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.configurarTraducao();

    if (this.substituirProduto.ProdutoAntigoId == this.substituirProduto.ProdutoNovoId) {
      Swal.fire(this.ATENCAO, this.O_PRODUTO_NOVO_NAO_PODE_SER_O_MESMO_DO_PRODUTO_A_SER_SUBSTITUIDO, 'warning');
      return;
    }

    this.produtoService.substituirProduto(this.substituirProduto)
      .subscribe(res => {
        var osAlteraras: string = '';
        if (res.length > 0) {
          res.forEach(p => osAlteraras += p + ",  ")
          osAlteraras = osAlteraras.slice(0, osAlteraras.length - 3)
        }
        this.substituirProduto = new SubstituirProduto();
        this.isSubmit = false;
        form.resetForm();
        document.getElementById('closeModalSubstituirProduto').click();
        Swal.fire(this.FEITO, (res.length > 0 ? this.PRODUTOS_SUBSTITUIDOS_BR_ORDENS_DE_SERVICO_AFETADAS_ + osAlteraras : this.NENHUMA_ORDEM_DE_SERVICO_AFETADA), 'success');
      },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        })
  }

  configurarTraducao() {
    this.ATENCAO = this.translate.instant('ATENCAO');
    this.SELECIONE_PELO_MENOS_UMA_FAZENDA_PRA_TERMINAR = this.translate.instant('SELECIONE_PELO_MENOS_UMA_FAZENDA_PRA_TERMINAR');
    this.LIBERACAO_DE_ORDEM_DE_SERVICO = this.translate.instant('LIBERACAO_DE_ORDEM_DE_SERVICO');
    this.APONTAMENTO_DE_ORDEM_DE_SERVICO = this.translate.instant('APONTAMENTO_DE_ORDEM_DE_SERVICO');
    this.ANTECIPACAO_DE_ORDEM_DE_SERVICO = this.translate.instant('ANTECIPACAO_DE_ORDEM_DE_SERVICO');
    this.INCLUIR_ORDEM_DE_SERVICO = this.translate.instant('INCLUIR_ORDEM_DE_SERVICO');
    this.ERRO = this.translate.instant('ERRO');
    this.GERAR_OS = this.translate.instant('GERAR_OS');
    this.GERA_TODAS_AS_ORDENS_DE_SERVICO_DE_ACORDO_COM_OS_PLANEJAMENTOS_DA_EMPRESA = this.translate.instant('GERA_TODAS_AS_ORDENS_DE_SERVICO_DE_ACORDO_COM_OS_PLANEJAMENTOS_DA_EMPRESA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.FEITO = this.translate.instant('FEITO');
    this.ORDENS_DE_SERVICOS_GERADAS = this.translate.instant('ORDENS_DE_SERVICOS_GERADAS');
    this.NAO_ENCERRADAS = this.translate.instant('NAO_ENCERRADAS');
    this.ALGUMAS_ORDENS_DE_SERVICOS_NAO_FORAM_GERADAS_POIS_NAO_FOI_ENCERRADO_O_PLANTIO_DA_SAFRA = this.translate.instant('ALGUMAS_ORDENS_DE_SERVICOS_NAO_FORAM_GERADAS_POIS_NAO_FOI_ENCERRADO_O_PLANTIO_DA_SAFRA');
    this.ORDEM_DE_SERVICO_NAO_ENCONTRADA = this.translate.instant('ORDEM_DE_SERVICO_NAO_ENCONTRADA');
    this.O_PRODUTO_NOVO_NAO_PODE_SER_O_MESMO_DO_PRODUTO_A_SER_SUBSTITUIDO = this.translate.instant('O_PRODUTO_NOVO_NAO_PODE_SER_O_MESMO_DO_PRODUTO_A_SER_SUBSTITUIDO');
    this.PRODUTOS_SUBSTITUIDOS_BR_ORDENS_DE_SERVICO_AFETADAS_ = this.translate.instant('PRODUTOS_SUBSTITUIDOS_BR_ORDENS_DE_SERVICO_AFETADAS_');
    this.NENHUMA_ORDEM_DE_SERVICO_AFETADA = this.translate.instant('NENHUMA_ORDEM_DE_SERVICO_AFETADA');
  }
}
