import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NovoTiposDiagnose } from 'src/app/classes/tipos-diagnose/NovoTiposDiagnose';
import { TiposDiagnose } from 'src/app/classes/tipos-diagnose/TiposDiagnose';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TiposDiagnoseService {

  readonly route: string = 'TiposDiagnose';

  constructor(private http: HttpClient) { }

  Listar(): Observable<TiposDiagnose[] | null>{
    return this.http.get<TiposDiagnose[]>(`${environment.apiURL}/${this.route}`);
  }

  ObterPorId(id: string): Observable<TiposDiagnose | null>{
    return this.http.get<TiposDiagnose>(`${environment.apiURL}/${this.route}/${id}`);
  }

  Inserir(tiposDiagnose: NovoTiposDiagnose): Observable<TiposDiagnose | null>{
    return this.http.post<TiposDiagnose>(`${environment.apiURL}/${this.route}`, tiposDiagnose);
  }

  Atualizar(tiposDiagnose: TiposDiagnose, id: string): Observable<TiposDiagnose | null> {
    return this.http.put<TiposDiagnose>(`${environment.apiURL}/${this.route}/${id}`, tiposDiagnose);
  }

  Excluir(id: string): Observable<any> {
    return this.http.delete(`${environment.apiURL}/${this.route}/${id}`);
  }
}
