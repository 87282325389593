import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalEstoque } from '../classes/LocalEstoque';

@Injectable({
  providedIn: 'root'
})
export class LocalEstoqueService {

  constructor(private http: HttpClient) { }

  getLocaisEstoque(): Observable<any>{
    return this.http.get(`${environment.apiURL}/localEstoque`)
  }

  getLocalEstoque(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/localEstoque/${id}`)
  }

  postLocalEstoque(localEstoque: LocalEstoque): Observable<any>{
    return this.http.post(`${environment.apiURL}/localEstoque`, localEstoque);
  }

  putLocalEstoque(id: number, localEstoque: LocalEstoque): Observable<any>{
    return this.http.put(`${environment.apiURL}/localEstoque/${id}`, localEstoque);
  }

  deleteLocalEstoque(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/localEstoque/${id}`);
  }

  listarPorFazenda(fazendaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/localEstoque/fazenda/${fazendaId}`)
  }
}
