import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { TranslateService } from '@ngx-translate/core';
import { Anexo } from 'src/app/classes/chamado/Anexo';
import { Chamado, ListaCriticidadeChamado, ListaModuloChamado, ListaTipoChamado } from 'src/app/classes/chamado/Chamado';
import { ChamadoService } from 'src/app/services/chamados.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-novo-chamado',
  templateUrl: './novo-chamado.component.html',
  styleUrls: ['./novo-chamado.component.scss']
})
export class NovoChamadoComponent implements OnInit {
  constructor(
    private chamadoService: ChamadoService,
    private toasty: CustomToastyService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }
  @ViewChild('Modal', { static: true }) modal;
  @ViewChild('validationForm', { static: true }) validationForm;
  @Output() concluir = new EventEmitter();
  @Input() isEdit?: boolean;
  @Input() chamado?: Chamado;

  public uploadedFiles: Array<File> = [];
  public carregando: boolean = false;
  public listaModulos = ListaModuloChamado;
  public listaCriticidades = ListaCriticidadeChamado;
  public listaTipos = ListaTipoChamado;
  public novoChamado: Chamado = new Chamado();
  public anexos: Anexo[] = [];

  // form
  form: any;
  isSubmit: boolean = false;

  ngOnInit() {
  }

  public clear(): void {
    this.uploadedFiles = [];
  }
  openModal() {
    this.clear()
    if (this.isEdit) {
      this.novoChamado = this.chamado;
    } else {
      this.isSubmit = false;
      this.novoChamado = new Chamado();
      this.novoChamado.Anexos = [];

    }
    this.modal.show();
  }

  closeModal() {
    this.modal.hide();
    this.isSubmit = false;
    this.validationForm.resetForm();

  }
  async save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.novoChamado.Anexos = this.anexos.filter(p => this.uploadedFiles.map(m => m.name).includes(p.Imagem));
    if (!this.isEdit) {
      this.chamadoService.post(this.novoChamado)
        .subscribe(
          res => {
            this.concluir.emit();
            this.carregando = false;
            this.closeModal();
          },
          err => {
            this.utils.getErro(err)
            this.carregando = false;
          }
        );
    } else {
      this.chamadoService.put(this.novoChamado.Id, this.novoChamado)
        .subscribe(
          res => {
            this.utils.handleSuccess(this.translate.instant('SALVO_COM_SUCESSO'))
            this.carregando = false;
            this.concluir.emit();
            this.closeModal();
          },
          err => {
            this.utils.getErro(err)
            this.carregando = false;
          }
        );
    }

  }

  getBase64() {
    let that = this;
    for (let file of this.uploadedFiles) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let anexo = new Anexo();
        anexo.Imagem = file.name;
        anexo.ImagemBase64 = reader.result.toString().split(',')[1];
        that.anexos.push(anexo);
      };
      reader.onerror = function (error) {
        console.error('Error: ', error);
      };
    }
    
  }
}
