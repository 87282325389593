export class Oficina {
    Id: number;
    Descricao:string = "";
    IsTerceiro: boolean;
    FornecedorId?: number;
    Ativo: boolean = true;

    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
}
