import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProdutoConsolidado } from 'src/app/classes/ProdutoConsolidado';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { ProdutoConsolidadoService } from 'src/app/services/produtoConsolidado.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-grid-produtos-consolidados',
  templateUrl: './grid-produtos-consolidados.component.html',
  styleUrls: ['./grid-produtos-consolidados.component.scss']
})
export class GridProdutosConsolidadosComponent implements OnInit {
  @Input() produtosConsolidados: ProdutoConsolidado[];

  constructor(private router: Router,
    private translate: TranslateService,
    private utils: UtilsService,
    private produtoConsolidadoService: ProdutoConsolidadoService
  ) { }
  @ViewChild('Grid', { static: false }) grid: any;
  colDefs: ColDef[] = [
    {
      width: 45,
      lockPosition: true,
      lockVisible: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {field: 'Descricao', headerName: 'Descrição', flex: 1},
    {field: 'QuantidadeProdutos', headerName: 'Quantidade Produtos', flex: 1},
    {field: 'DataCadastro', headerName: 'Data Criação', flex: 1 }
  ];
  objSelecionado: ProdutoConsolidado = new ProdutoConsolidado();
  objSelecionadoId: number = 0;
  objetosSelecionados: ProdutoConsolidado[] = [];
  ngOnInit() {
    this.carregar();
  }

  carregar()
  {
    this.produtoConsolidadoService.getAll().subscribe((res) => {
      this.produtosConsolidados = res;
    }, (error) => {
      this.utils.getErro(error);
    });
  }

  novo() {
    this.router.navigate(['/estoques/produto-consolidado/inserir']);
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/estoques/produto-consolidado/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {
    Swal.fire({
      title: this.translate.instant('DELETAR') + ' ' + this.objetosSelecionados[0].Descricao + '?',
      text: this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA'),
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('CANCELAR'),
      confirmButtonText: this.translate.instant('SIM'),
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.produtoConsolidadoService.delete(this.objetosSelecionados[0].Id).subscribe(
            res => {
              this.utils.handleSuccess(this.translate.instant('TIPO_DESCONTO_PRODUCAO_EXCLUIDO'));
              this.carregar();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }
}
