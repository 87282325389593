import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Etapa } from 'src/app/classes/Etapa';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { EtapaService } from 'src/app/services/etapa.service';

@Component({
  selector: 'app-etapa',
  templateUrl: './etapa.component.html',
  styleUrls: ['./etapa.component.scss']
})
export class EtapaComponent implements OnInit {

  etapas: Etapa[];
  auxEtapas: Etapa[];
  etapa: Etapa;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  ERRO: string = 'Erro!';
  FEITO: string = 'Feito!';
  ALVO_ALTERADO: string = 'Alvo alterado!';
  ALVO_CADASTRADO: string = 'Alvo cadastrado!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  ALVO_DELETADO: string = 'Alvo deletado!';
  DELETAR_PARAM: string;
  constructor(private etapaService: EtapaService,
              private route: ActivatedRoute, private router: Router, private storageService: StorageService,
              private toasty: CustomToastyService,
              private translate: TranslateService
    ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.configurarTraducao();
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.etapa = new Etapa();

    if(this.id > 0)
      this.selecionarEtapa();
    else
      this.listarEtapas();
  }
  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.FEITO = this.translate.instant('FEITO');
    this.ALVO_ALTERADO = this.translate.instant('ALVO_ALTERADO');
    this.ALVO_CADASTRADO = this.translate.instant('ALVO_CADASTRADO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.ALVO_DELETADO = this.translate.instant('ALVO_DELETADO');
}
  selecionarEtapa() {
    this.carregando = true;
    this.etapaService.getEtapa(this.id).subscribe(res =>{
      this.etapa = new Etapa();
      this.etapa = res;
      this.carregando = false;
    },
    ex =>{
      Swal.fire('Erro!', ex.error.ExceptionMessage, 'error');
      this.carregando = false;
    })
  }

  listarEtapas(){
    this.carregando = true;
    this.etapaService.getEtapas().subscribe(
      res => {
        this.etapas = res;
        this.auxEtapas = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarEtapas();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.etapa.Id > 0)
      this.etapaService.putEtapa(this.etapa.Id, this.etapa).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ALVO_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.FEITO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.etapaService.postEtapa(this.etapa).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ALVO_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          Swal.fire(this.FEITO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(etapaId: number, nome: string) {
    this.DELETAR_PARAM = this.translate.instant("DELETAR_PARAM", { value: nome });
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.etapaService.deleteEtapa(etapaId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.ALVO_DELETADO, 'success');
              this.listarEtapas();
              this.carregando = false;
            },
            err => {
              Swal.fire(this.ERRO, err.message, 'error');
              this.carregando = false;
            });
        }
      });
  }

  filtrarEtapas(){
    this.etapas = this.auxEtapas.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/etapa']);
  }
}