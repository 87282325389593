import { UtilsService } from './../../../utils/utils.service'
import { NaturezaService } from './../../../services/natureza.service'
import { Component, OnInit  } from '@angular/core'
import { Natureza } from 'src/app/classes/Natureza'
import { ActivatedRoute, Router } from '@angular/router'
import { TreeItem } from 'src/app/classes/TreeItem'
import { StorageService } from 'src/app/services/storage.service'
import { EmpresaService } from 'src/app/services/empresa.service'

@Component({
  selector: 'app-natureza',
  templateUrl: './natureza.component.html',
  styleUrls: ['./natureza.component.scss'],
})
export class NaturezaComponent implements OnInit {
  constructor(
    private naturezaService: NaturezaService,
    private utils: UtilsService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private empresaService: EmpresaService,
    private router: Router,
  ) {
    this.route.params.subscribe((res) => {
      this.id = res.id
      if (this.id > 0) {
        this.selecionar()
        this.exibirForm = true
      }
    })
  }
  naturezas: Natureza[]
  arvore: any[] = []
  items: TreeItem[] = []
  htmlTree: string
  carregando: boolean = false
  exibirForm: boolean = false
  id: number
  natureza: Natureza = new Natureza()
  mostrarArvore: boolean = false
  mask: string = "00000000";

  ngOnInit() {
    this.empresaService.getEmpresa(this.storageService.getEmpresa().Id)
    .subscribe(res => {
      const mask = res.MascaraNaturezaReceitaDespesa;
      this.mask = mask != null ? mask : this.mask;

    })
    if (this.router.url.search('inserir') > 0) {
      this.natureza = new Natureza()
      this.exibirForm = true
    }
    this.atualizarArvore()
  }
  atualizarArvore() {
    this.items = [];
    this.mostrarArvore = false
    this.naturezaService.getList().subscribe(
      (res) => {
        this.mostrarArvore = false
        this.carregando = true
        this.naturezas = res
        this.naturezas.forEach((n) => {
          let as: TreeItem = {
            Id: n.Id,
            Codigo: Number(n.Codigo),
            CodigoPai: n.CodigoPai,
            Descricao: n.Descricao,
            IsAnalitico: n.IsAnalitico,
          }
          this.items.push(as)
        })
        this.mostrarArvore = true
        this.carregando = false
      },
      (err) => {
        this.utils.getErro(err)
        this.carregando = false
      },
    )
  }
  selecionar() {
    this.naturezaService.get(this.id).subscribe(
      (res) => {
        this.natureza = res
      },
      (err) => {
        this.utils.getErro(err)
      },
    )
  }
}
