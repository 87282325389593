export class Atividade {
    Id: number;
    Descricao:string = "";
    EmpresaId: number;
    GrupoOperacaoId: number;
    GrupoOperacaoDescricao: String = "";
    UtilizaInsumo: boolean;
    DuracaoPadrao: string = "00:00:00";
    InformarTipoAplicacao: boolean;
    Plantio: boolean;
    Padrao: boolean;
    JuntaInsumosServicos: boolean;
    UsaServico: boolean;
}
