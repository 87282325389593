import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { Endereco, EnumTipoEndereco } from 'src/app/classes/Endereco';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { EnderecoFormInternoComponent } from '../../endereco/endereco-form-interno/endereco-form-interno.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumTipoPessoa } from 'src/app/classes/Pessoa';
import { EnderecoService } from 'src/app/services/endereco.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { EnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { Transacao } from 'src/app/classes/Transacao';

@Component({
  selector: 'app-empresa-interna-form',
  templateUrl: './empresa-interna-form.component.html',
  styleUrls: ['./empresa-interna-form.component.scss']
})
export class EmpresaInternaFormComponent implements OnInit, AfterViewInit {

  @ViewChild('enderecoFormInternoComponentPrincipal', { static: false }) enderecoFormInternoComponentPrincipal: EnderecoFormInternoComponent;

  private enderecoFormInternoComponentCorrespondencia: EnderecoFormInternoComponent;
  @ViewChild('enderecoFormInternoComponentCorrespondencia', { static: false }) set content1(content1: EnderecoFormInternoComponent) {
    if (content1) {
      this.enderecoFormInternoComponentCorrespondencia = content1;
      if (!this.empresaInterna.EnderecoCorrespondenciaIgualPrincipal)
        this.enderecoFormInternoComponentCorrespondencia.meuForm.patchValue(this.enderecoCorrespondencia);
    }
  }

  private enderecoFormInternoComponentCobranca: EnderecoFormInternoComponent;
  @ViewChild('enderecoFormInternoComponentCobranca', { static: false }) set content(content: EnderecoFormInternoComponent) {
    if (content) {
      this.enderecoFormInternoComponentCobranca = content;
      if (!this.empresaInterna.EnderecoCobrancaIgualPrincipal)
        this.enderecoFormInternoComponentCobranca.meuForm.patchValue(this.enderecoCobranca);
    }
  }

  @ViewChild('EnderecoModal', { static: false })
  EnderecoModal: UiModalComponent;
  endereco: Endereco;

  enderecoPrincipal = new Endereco();
  enderecoCorrespondencia: Endereco = new Endereco();
  enderecoCobranca: Endereco = new Endereco();

  public isCollapsed: boolean;

  empresaInterna: EmpresaInterna;

  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;

  isSubmit: boolean;
  touched = false;

  mascaraCpfCnpj = '';
  listaMatrizes: EmpresaInterna[];


  tipos = [
    { valor: 1, descricao: 'Pessoa Física' },
    { valor: 2, descricao: 'Pessoa Jurídica' }
  ];

  transacoesEntradaProducao = [];
  transacoesSaidasDePecas = [];

  constructor(
    private enderecoService: EnderecoService,
    private route: ActivatedRoute,
    private utils: UtilsService,
    private empresaInternaService: EmpresaInternaService,
    private router: Router,
    private toasty: CustomToastyService,
    private transacaoService: TransacaoService
  ) {
    this.route.params.subscribe(res => {
      if (res.id == 'inserir')
        this.id = 0;
      else
        this.id = res.id;
    });
    this.enderecoPrincipal = new Endereco();
    this.enderecoCorrespondencia = new Endereco();
    this.enderecoCobranca = new Endereco();
    this.isCollapsed = true;
  }

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    this.empresaInterna = new EmpresaInterna();
    this.listarMatrizes();

    this.transacaoService.getTransacoesPermitidasPorTipo(EnumTipoMovimento.MovimentacaoEstoque)
          .subscribe((res: Transacao[]) => {
            this.transacoesEntradaProducao = res;
          }, err => {
            this.utils.getErro(err)
          }
        );
    this.transacaoService.getTransacoesPermitidasPorTipo(EnumTipoMovimento.MovimentacaoEstoque)
          .subscribe((res: Transacao[]) => {
            this.transacoesSaidasDePecas = res;
          }, err => {
            this.utils.getErro(err)
          }
        );
  }

  ngAfterViewInit() {
    if (this.id > 0)
      this.selecionarEmpresaInterna();
  }

  changeTipo(tipo: any) {
    switch (tipo) {
      case EnumTipoPessoa.Fisica:
        this.mascaraCpfCnpj = "000.000.000-00"
        break;
      case EnumTipoPessoa.Juridica:
        this.mascaraCpfCnpj = "00.000.000/0000-00"
        break;
    }
  }

  selecionarEmpresaInterna() {
    this.carregando = true;
    this.empresaInternaService.getEmpresaInterna(this.id).subscribe(res => {
      this.empresaInterna = res
      try {
        this.enderecoPrincipal = res.Enderecos.filter(c => c.Tipo == EnumTipoEndereco.Principal)[0];
        this.enderecoFormInternoComponentPrincipal.meuForm.patchValue(this.enderecoPrincipal);

        if (!this.empresaInterna.EnderecoCobrancaIgualPrincipal) {
          this.enderecoCobranca = res.Enderecos.filter(c => c.Tipo == EnumTipoEndereco.Entrega)[0];
        }
        if (!this.empresaInterna.EnderecoCorrespondenciaIgualPrincipal) {
          this.enderecoCorrespondencia = res.Enderecos.filter(c => c.Tipo == EnumTipoEndereco.Correspondencia)[0];
        }
      }
      catch { }
      this.carregando = false;
    },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      })
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    if(!this.empresaInterna.MatrizId)
      this.empresaInterna.MatrizId = 0;
    this.carregando = true;
    if (this.enderecoFormInternoComponentPrincipal.meuForm.invalid) {
      this.enderecoFormInternoComponentPrincipal.isSubmit = true;
      Swal.fire('Ops!', 'O Endereço Principal é obrigatório!', 'warning');
      this.carregando = false;
      return;
    }
    this.empresaInterna.Enderecos = [];
    this.enderecoPrincipal = this.enderecoFormInternoComponentPrincipal.meuForm.value;
    this.enderecoPrincipal.Tipo = EnumTipoEndereco.Principal;
    this.empresaInterna.Enderecos.push(this.enderecoPrincipal);

    if (!this.empresaInterna.EnderecoCobrancaIgualPrincipal) {
      if (this.enderecoFormInternoComponentCobranca.meuForm.invalid) {
        this.enderecoFormInternoComponentCobranca.isSubmit = true;
        Swal.fire('Ops!', 'Preencha o Endereço de Cobrança corretamente!', 'warning');
        this.carregando = false;
        return;
      }
      this.enderecoCobranca = this.enderecoFormInternoComponentCobranca.meuForm.value;
      this.enderecoCobranca.Tipo = EnumTipoEndereco.Entrega;
      this.empresaInterna.Enderecos.push(this.enderecoCobranca);
    }

    if (!this.empresaInterna.EnderecoCorrespondenciaIgualPrincipal) {
      if (this.enderecoFormInternoComponentCorrespondencia.meuForm.invalid) {
        this.enderecoFormInternoComponentCorrespondencia.isSubmit = true;
        Swal.fire('Ops!', 'Preencha o Endereço de Correspondencia corretamente!', 'warning');
        this.carregando = false;
        return;
      }
      this.enderecoCorrespondencia = this.enderecoFormInternoComponentCorrespondencia.meuForm.value;
      this.enderecoCorrespondencia.Tipo = EnumTipoEndereco.Correspondencia;
      this.empresaInterna.Enderecos.push(this.enderecoCorrespondencia);
    }

    if (this.empresaInterna.Id > 0) {
      this.empresaInternaService.putEmpresaInterna(this.empresaInterna.Id, this.empresaInterna).subscribe(
        res => {
          this.toasty.show('Feito!', 'Empresa Interna  alterada!', 'success');
          this.carregando = false;
          this.empresaInterna = res;
          this.router.navigate([`/cadastros/empresa-interna/${res.Id}`]);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else {
      this.empresaInternaService.postEmpresaInterna(this.empresaInterna).subscribe(
        res => {
          this.toasty.show('Feito!', 'Empresa Interna cadastrada!', 'success');
          this.carregando = false;
          this.empresaInterna = res;
          //this.router.navigate([`/cadastros/empresa-interna/${res.Id}`]);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
  }

  cancelar() {
    this.router.navigate(['/cadastros/empresa-interna']);
  }

  listarMatrizes() {
    this.empresaInternaService.getEmpresasInternas().subscribe(
      res => {
        this.listaMatrizes = res;
        console.log(this.empresaInterna)
      },
      err => {
        this.utils.getErro(err);
      });
  }

  getBase64(event) {
    let that = this;
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      that.empresaInterna.LogoBase64 = reader.result.toString();
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
}
