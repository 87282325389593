export class EstoqueProduto {
    Id: number;
    ProdutoId: number;
    ProdutoDescricao:string = "";
    LocalEstoqueId: number;
    LocalEstoqueDescricao:string = "";
    UnidadeMedida:string = "";
    Saldo: number = 0;
    Reserva: number = 0;
    Disponivel: number = 0;
    Entradas: number = 0;
    Saidas: number = 0;
    DataUltimaEntrada: Date = new Date();
    DataUltimaSaida: Date = new Date();
    PrecoMedio: number = 0;
    ValorEstoque: number = 0;

    constructor() {
        this.ProdutoId = 0;
        this.ProdutoDescricao = '';
        this.LocalEstoqueId = 0;
        this.LocalEstoqueDescricao = '';
        this.UnidadeMedida = '';
        this.Saldo = 0;
        this.Reserva = 0;
        this.Disponivel = 0;
        this.Entradas = 0;
        this.Saidas = 0;
        this.DataUltimaEntrada = new Date();
        this.DataUltimaSaida = new Date();
        this.PrecoMedio = 0;
        this.ValorEstoque = 0;
    }

}
