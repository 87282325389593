import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { Cultura } from 'src/app/classes/Cultura';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { Safra } from 'src/app/classes/Safra';
import { Setor } from 'src/app/classes/Setor';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit {

  @Input() anosSafra: AnoSafra[];
  @Input() safras: Safra[];
  @Input() culturas: Cultura[];
  @Input() gruposOperacao: GrupoOperacao[];
  @Input() setores: Setor[];

  @Input() filtros: boolean;

  @Input() anoSafraId: number = 0;
  @Input() safraId: number = 0;
  @Input() culturaSelecionadaId: number = 0;
  @Input() grupoOperacaoId: number = 0;
  @Input() setorId: number = 0;
  @Input() organizaPorSetor: boolean;


  @Output() anoSafraEvento = new EventEmitter<number>();
  @Output() safrasEvento = new EventEmitter<number>();
  @Output() culturasEvento = new EventEmitter<number>();
  @Output() gruposOperacaoEvento = new EventEmitter<number>();
  @Output() setoresEvento = new EventEmitter<number>();

  dispararAnoSafraEvento() {
    this.anoSafraEvento.emit(this.anoSafraId);
  }

  dispararSafrasEvento() {
    this.safrasEvento.emit(this.safraId);
  }

  dispararCulturasEvento() {
    this.culturasEvento.emit(this.culturaSelecionadaId);
  }

  dispararGruposOperacaoEvento() {
    this.gruposOperacaoEvento.emit(this.grupoOperacaoId);
  }

  dispararSetoresEvento() {
    this.setoresEvento.emit(this.setorId);
  }



  constructor() { }

  ngOnInit() {
  }

}
