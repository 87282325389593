import { Component, OnInit } from "@angular/core";
import { CustoPorGrupoEquipamento } from "src/app/classes/gestao-lite/CustoPorGrupoEquipamento";
import { GrupoEquipamentoService } from "src/app/services/grupo-equipamento.service";

@Component({
  selector: "app-grafico-barras-custos",
  templateUrl: "./grafico-barras-custos.component.html",
  styleUrls: ["./grafico-barras-custos.component.scss"],
})
export class GraficoBarrasCustosComponent implements OnInit {
  custos: CustoPorGrupoEquipamento[];
  categories: string[] = new Array<string>();
  data: number[] = new Array<number>();
  showGrafico = false;

  public chartOptions: any;
  

  constructor(private grupoEquipamentoService: GrupoEquipamentoService) {}

  ngOnInit() {
    this.grupoEquipamentoService.getCustoGrupoEquipamento().subscribe((data) => {
      this.custos = data;
      this.dadosGrafico();
    });

    this.chartOptions = {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: ["#4680ff"],
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      series: [
        {
          data: this.data,
          name: "Valor Total"
        },
      ],
      xaxis: {
        categories: this.categories,
      },
    };
  }

  dadosGrafico() {
    if(this.custos.length >= 5) {
      for (let i = 0; i < 5; i++) {
        this.categories.push(this.custos[i].Descricao);
        this.data.push(this.custos[i].Total);
      }
    }
    
    else {
      for (let i = 0; i < this.custos.length; i++) {
        this.categories.push(this.custos[i].Descricao);
        this.data.push(this.custos[i].Total);
      }
    }

    this.showGrafico = true; 
  }
}
