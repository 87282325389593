import { Component, OnInit, ViewChild } from '@angular/core';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-lista-movimento-estoque',
  templateUrl: './lista-movimento-estoque.component.html',
  styleUrls: ['./lista-movimento-estoque.component.scss']
})
export class ListaMovimentoEstoqueComponent implements OnInit {
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;
  
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.Modal.show();
  }

  fechar(){
    this.Modal.hide();
  }

}
