import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ManutencaoFrota } from '../classes/gestao-lite/ManutencaoFrota';
import { ItemChecklistManutencaoFrota } from '../classes/gestao-lite/ItemChecklistManutencaoFrota';

@Injectable({
  providedIn: 'root'
})
export class ItemChecklistManutencaoFrotaService {

  constructor(private http: HttpClient) { }


   listarPorManutencao(manutencaoFrotaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ItemChecklistManutencaoFrota/ListarPorManutencao/${manutencaoFrotaId}`)
  }
  finalizar(obj: any): Observable<any>{
    return this.http.post(`${environment.apiURL}/ItemChecklistManutencaoFrota/Finalizar`, obj);
  }
  excluir(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/ItemChecklistManutencaoFrota/${id}`,);
  }
}
