import { AtividadePlanejamento } from './../../../../classes/AtividadePlanejamento';
import { TipoAplicacao } from './../../../../classes/TipoAplicacao';
import { Atividade } from './../../../../classes/Atividade';
import { Produto } from './../../../../classes/Produto';
import { ItensAtividade } from './../../../../classes/ItensAtividade';
import { EtapaService } from './../../../../services/etapa.service';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Cultura } from 'src/app/classes/Cultura';
import { Etapa } from 'src/app/classes/Etapa';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AtividadePlanejamentoService } from 'src/app/services/atividade-planejamento.service';
import Swal from 'sweetalert2';
import { EstadioFenologico } from 'src/app/classes/EstadioFenologico';
import { CulturaService } from 'src/app/services/cultura.service';
import { Planejamento } from 'src/app/classes/Planejamento';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { Safra } from 'src/app/classes/Safra';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { Router } from '@angular/router';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-tab-lateral',
  templateUrl: './tab-lateral.component.html',
  styleUrls: ['./tab-lateral.component.scss']
})
export class TabLateralComponent implements OnInit, OnChanges {
  @ViewChild('ModalEtapa', { static: false })
  ModalEtapa: UiModalComponent;

  @Input() isDetalhe: Boolean = false;
  @Input() carregando: boolean = false;
  @Input() culturas: Cultura[];
  @Input() planejamento: Planejamento;
  @Input() custoTotalPlanejamento: number = 0;
  @Output() changeCultura: EventEmitter<Cultura> = new EventEmitter();
  @Output() custoTotalPlanejamentoAgrupado: EventEmitter<number> = new EventEmitter();

  @Input() etapas: Etapa[];
  @Input() estadiosFenologicos: EstadioFenologico[];
  @Output() changeEtapa: EventEmitter<Etapa> = new EventEmitter();
  @Output() insereEtapa: EventEmitter<Etapa> = new EventEmitter();
  @Output() excluiEtapa: EventEmitter<Etapa> = new EventEmitter();
  @Output() attListEtapas: EventEmitter<any> = new EventEmitter();
  @Output() changeAtividadeSalva: EventEmitter<AtividadePlanejamento> = new EventEmitter();

  @Input() safraSelecionada: Safra;

  @Input() visualizacaoAgrupada: boolean = false;
  @Input() atividades: Atividade[];
  @Input() gruposOperacao: GrupoOperacao[];
  @Input() itensAtividades: ItensAtividade[];
  @Input() tiposAplicacoes: TipoAplicacao[];
  @Input() atividadesPlanejamento: AtividadePlanejamento[];
  @Input() produtos: Produto[];
  @Input() sementes: Produto[];
  @Input() servicos: Produto[];
  @Input() apenasSimulacao: boolean = false;
  @Input() processosPlanejamento: ProcessoPlanejamento[];

  culturaSelecionada: Cultura;
  etapaSelecionada: Etapa;
  etapaEditando: Etapa;
  etapasAdicionadas: Etapa[] = [];
  etapasCadastradas: Etapa[] = [];

  filtroCultura: string = '';
  @Input() auxCulturas: Cultura[];
  etapaEditada: number = 0;
  etapaModal: any = "";
  dapModal: number = 0;
  EstadioFenologicoModalId: number = 0;
  processoPlanejamentoId: number = 0;
  ReferenciaDAPSelect: number = 0;
  custoEtapa = new Map<number, number>();
  custoProcesso = new Map<number, number>();
  processosExistentes: number[] = [];
  processoPlanejamentoSelecionado: ProcessoPlanejamento;
  salvando: boolean = false;

  public simulando: boolean;

  public isSubmit: boolean;

  constructor(private atividadePlanejamentoService: AtividadePlanejamentoService,
    private culturaService: CulturaService,
    private planejamentoService: PlanejamentoService,
    private router: Router,
    private etapaService: EtapaService,
    private toasty: CustomToastyService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.etapasAdicionadas = [];
    if (!this.isDetalhe) {
      this.culturaSelecionada = this.culturaService.culturaSelecionada == null ? this.culturas != null && this.culturas.length > 0 ? this.culturas[0] : null : this.culturaService.culturaSelecionada;
      this.selecionarCultura(this.culturaSelecionada);
    }

    if (this.isDetalhe) {
      this.etapaService.getEtapas().subscribe(
        ret => {
          this.etapasCadastradas = ret;
        });
    }
  }

  filtrarCulturas() {
    this.culturas = this.auxCulturas.filter(x => x.Descricao.toUpperCase().includes(this.filtroCultura.toUpperCase()))
  }

  ngOnChanges() {
    this.etapasAdicionadas = [];
    if (!this.isDetalhe) {
      this.culturaSelecionada = this.culturaService.culturaSelecionada == null ? this.culturas != null && this.culturas.length > 0 ? this.culturas[0] : null : this.culturaService.culturaSelecionada;
      this.selecionarCultura(this.culturaSelecionada);
    } else if (this.etapas != null && this.etapas.length > 0) {
      this.etapas.forEach(etapa => {
        if (etapa.DiasAposPlantio != null) {
          if (this.etapaSelecionada == null)
            this.etapaSelecionada = etapa;

          this.addEtapa(etapa);
        }
      });

      if (this.etapasAdicionadas.length == 1) {//inserindo primeiro produto
        this.etapaSelecionada = this.etapasAdicionadas[0];
      }
      //verificar quais etapas contém itens
      if (this.etapasAdicionadas != null && this.etapasAdicionadas != undefined && this.atividadesPlanejamento != null && this.atividadesPlanejamento != undefined) {
        this.etapasAdicionadas = this.etapasAdicionadas.filter(etapa =>
          this.atividadesPlanejamento.some(atividade => atividade.EtapaId === etapa.Id)
        );
      }
      this.selecionarEtapa(this.etapasAdicionadas.indexOf(this.etapaSelecionada));
      this.calculaLucro();
    }
  }

  selecionarCultura(cultura: Cultura) {
    this.culturaSelecionada = cultura;
    this.culturaService.culturaSelecionada = cultura;
    this.changeCultura.emit(cultura);
  }

  selecionarEtapa(index: number) {
    this.etapaSelecionada = this.etapasAdicionadas[index];
    this.changeEtapa.emit(this.etapaSelecionada);
  }

  inserirEtapa() {
    this.isSubmit = false;
    this.etapaEditada = -1;
    this.etapaModal = null;
    this.dapModal = 0;
    this.EstadioFenologicoModalId = -1;
    this.processoPlanejamentoId = -1;
    this.ModalEtapa.show();
  }

  editarEtapa(item: Etapa, etapa: string, dap: number, estadioId: number, index: number, processo: number, referenciaDap: number) {
    console.log("Editando")
    this.isSubmit = false;
    this.etapaModal = etapa;
    this.etapaEditada = index;
    this.EstadioFenologicoModalId = estadioId == null ? -1 : estadioId;
    this.dapModal = dap;
    this.processoPlanejamentoId = processo == null ? -1 : processo;
    this.ReferenciaDAPSelect = referenciaDap;
    this.etapaEditando = item;
    this.ModalEtapa.show();
  }

  addEtapa(nova: Etapa): Etapa {
    if (!this.etapasAdicionadas)
      this.etapasAdicionadas = [];

    this.etapasAdicionadas.push(nova);
    this.etapasAdicionadas = this.etapasAdicionadas.sort((a, b) => (a.DiasAposPlantio < b.DiasAposPlantio ? -1 : 1));

    for (let i = 0; i < this.etapasAdicionadas.length; i++)
      this.etapasAdicionadas[i].Index = i;

    if (this.processosExistentes.indexOf(nova.ProcessoPlanejamentoId) == -1)
      this.processosExistentes.push(nova.ProcessoPlanejamentoId);

    return nova;
  }

  adicionarEtapa(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    if (!this.dapModal)
      this.dapModal = 0;

    if (this.processoPlanejamentoId == -1) {
      Swal.fire('Erro!', 'Um Processo deve ser selecionado!', 'error');
      return;
    }

    /*if(this.dapModal == 0)
    {
      Swal.fire('Erro!', 'O Dias Referência deve ser maior que zero!', 'error');
      return;
    }*/
    this.salvando = true;

    let etapaSel: Etapa;

    let novaEtapa = new Etapa();

    if (this.etapaModal.Descricao)
      novaEtapa.Descricao = this.etapaModal.Descricao;
    else
      novaEtapa.Descricao = this.etapaModal;
    novaEtapa.DiasAposPlantio = this.dapModal != null && this.dapModal != undefined ? parseInt(this.dapModal.toString()) : 0;
    novaEtapa.EstadioFenologicoId = (this.EstadioFenologicoModalId > 0 ? this.EstadioFenologicoModalId : null);
    novaEtapa.PlanejamentoId = this.planejamento.Id;
    novaEtapa.ReferenciaDAP = this.ReferenciaDAPSelect;
    novaEtapa.ProcessoPlanejamentoId = this.processoPlanejamentoId;

    if (this.etapaEditando && this.etapaEditando.Id > 0) {
      novaEtapa.Id = this.etapaEditando.Id;
    }
    this.etapaService.postEtapa(novaEtapa).subscribe(
      res => {
        etapaSel = res;

        if (this.etapaEditada >= 0) {
          let etapa = this.etapasAdicionadas[this.etapaEditada];

          this.carregando = true;

          this.atividadePlanejamentoService.alterarDAPPorEtapa(this.planejamento.Id, etapa.Id, etapaSel.Id, etapa.DiasAposPlantio, this.dapModal ? parseInt(this.dapModal.toString()) : 0, this.EstadioFenologicoModalId, this.processoPlanejamentoId, this.ReferenciaDAPSelect).subscribe(
            res => {
              this.etapasAdicionadas.splice(this.etapaEditada, 1);

              let nova = this.createEtapa(etapaSel);
              this.addEtapa(nova)
              this.selecionarEtapa(this.etapasAdicionadas.indexOf(nova));
              this.insereEtapa.emit(nova);
              this.ModalEtapa.hide();
              this.carregando = false;
              this.salvando = false;
            },
            err => {
              Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
              this.carregando = false;
              this.salvando = false;
            });
        } else {

          //Inserir Atividade Padrão Para exibir a etapa
          let auxAtividadePlanejamento: AtividadePlanejamento = new AtividadePlanejamento();
          let atividadePadrao: Atividade = this.atividades.find(x => x.Padrao);
          auxAtividadePlanejamento.AtividadeId = atividadePadrao.Id;
          auxAtividadePlanejamento.PlanejamentoId = this.planejamento.Id;
          auxAtividadePlanejamento.EtapaId = etapaSel.Id;
          auxAtividadePlanejamento.DiasAposPlantio = this.dapModal != null && this.dapModal != undefined ? parseInt(this.dapModal.toString()) : 0;
          auxAtividadePlanejamento.EstadioFenologicoId = etapaSel.EstadioFenologicoId;
          auxAtividadePlanejamento.DataPrevistaFinal = null;
          auxAtividadePlanejamento.ReferenciaDAP = this.ReferenciaDAPSelect;
          auxAtividadePlanejamento.TipoAplicacaoId = null;
          auxAtividadePlanejamento.ProcessoPlanejamentoId = this.processoPlanejamentoId;

          this.atividadePlanejamentoService.postAtividadePlanejamento(auxAtividadePlanejamento).subscribe(
            res => {
              auxAtividadePlanejamento = res;
              if (!auxAtividadePlanejamento.TipoAplicacaoId)
                auxAtividadePlanejamento.TipoAplicacaoId = -1;
              if (!this.atividadesPlanejamento)
                this.atividadesPlanejamento = [];
              this.atividadesPlanejamento.push(auxAtividadePlanejamento);
              this.changeAtividadeSalva.emit(auxAtividadePlanejamento);
              let nova = this.createEtapa(etapaSel);
              this.addEtapa(nova)
              this.selecionarEtapa(this.etapasAdicionadas.indexOf(nova));
              this.insereEtapa.emit(nova);
              this.ModalEtapa.hide();
              this.carregando = false;
              this.salvando = false;
            },
            err => {
              Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
              this.carregando = false;
              this.salvando = false;
            });
        }
      },
      err => {
        Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  createEtapa(etapaSel: Etapa): Etapa {
    let nova: Etapa = new Etapa();
    nova.Id = etapaSel.Id;
    nova.Descricao = etapaSel.Descricao;
    nova.DiasAposPlantio = this.dapModal;

    if (this.EstadioFenologicoModalId == -1) {
      nova.EstadioFenologico = new EstadioFenologico();
      nova.EstadioFenologico.Descricao = "-";
      nova.EstadioFenologico.Id = this.EstadioFenologicoModalId;
    } else
      nova.EstadioFenologico = this.estadiosFenologicos.filter(x => x.Id == this.EstadioFenologicoModalId)[0];

    etapaSel.DiasAposPlantio = this.dapModal;
    etapaSel.ReferenciaDAP = this.ReferenciaDAPSelect;
    etapaSel.EstadioFenologico = nova.EstadioFenologico;
    etapaSel.ProcessoPlanejamentoId = this.processoPlanejamentoId;

    return nova;
  }

  timoutLucro: any;
  changeSimulacao() {
    if (this.timoutLucro)
      clearTimeout(this.timoutLucro);

    this.timoutLucro = setTimeout(() => {
      this.calculaLucro();

      this.carregando = true;
      this.planejamentoService.putPlanejamento(this.planejamento.Id, this.planejamento).subscribe(
        res => {
          this.carregando = false;
        },
        err => {
          Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    }, 1000);
  }

  calculaLucro() {
    this.planejamento.LucroSimulado = (this.planejamento.ProdutividadeSimulada * this.planejamento.PrecoVendaSimulado) - this.custoTotalPlanejamento;
  }

  toogleEtapa(etapa: Etapa) {
    etapa.Collapsed = !etapa.Collapsed;
  }

  changeCusto(custo: number) {
    if (custo) {
      this.custoTotalPlanejamento = custo;
      this.custoTotalPlanejamentoAgrupado.emit(custo);
    }
  }

  changeCustoProcessoPlanejamento(idProcesso: number, custo: number) {
    this.custoProcesso.set(idProcesso, custo);
  }

  changeCustoEtapa(idEtapa: number, custo: number) {
    this.custoEtapa.set(idEtapa, custo);
  }

  concluir() {
    var concluir: boolean = true
    const title: string = this.translateService.instant("ATENCAO");
    const msg: string = this.translateService.instant("NAO_CONCLUIDO_VALORES_SAO_ZERO");
    this.itensAtividades.forEach(p => {
      if (concluir)
        if (p.PrecoUnitario <= 0) {
          this.toasty.show(title, msg, 'warning');
          concluir = false;
          return;
        }
    })
    if (concluir)
      this.router.navigate((this.planejamento != null && this.planejamento.Padrao) ? ['/planejamento/plano-mestre-producao'] : ['/planejamento/safra']);
  }

  selecionaProcessoPlanejamento() {
    this.processoPlanejamentoSelecionado = this.processosPlanejamento.find(x => x.Id == this.processoPlanejamentoId);
    if (this.processoPlanejamentoSelecionado) {
      this.ReferenciaDAPSelect = this.processoPlanejamentoSelecionado.ReferenciaDAP;
    }
  }

  excluirEtapa(etapa: Etapa) {
    this.excluiEtapa.emit(etapa);
  }
}
