import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnumEventoAprovacao, RegistrosAprovacoes } from '../classes/RegistrosAprovacoes';

@Injectable({
  providedIn: 'root'
})
export class RegistroAprovacoesService {

  constructor(private http: HttpClient) { }

  get(): Observable<any>{
    return this.http.get(`${environment.apiURL}/RegistrosAprovacoes`);
  }
  alterarStatus( resgistro: RegistrosAprovacoes, evento: EnumEventoAprovacao): Observable<any>{
    resgistro.UsuarioAlteracao = null;
    resgistro.UsuarioCadastro = null;
    resgistro.Aprovador = null;
    return this.http.post(`${environment.apiURL}/RegistrosAprovacoes/AlterarStatus/${evento}`, resgistro);
  }
}
