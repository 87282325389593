import { Component, OnInit } from '@angular/core';
import { GrupoEquipamento } from 'src/app/classes/GrupoEquipamento';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { GrupoEquipamentoService } from 'src/app/services/grupo-equipamento.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-grupo-equipamento',
  templateUrl: './grupo-equipamento.component.html',
  styleUrls: ['./grupo-equipamento.component.scss']
})
export class GrupoEquipamentoComponent implements OnInit {

  gruposEquipamento: GrupoEquipamento[];
  auxGruposEquipamento: GrupoEquipamento[];
  grupoEquipamento: GrupoEquipamento;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;


  constructor(private gruposEquipamentoService: GrupoEquipamentoService, 
              private route: ActivatedRoute, 
              private router: Router, 
              private storageService: StorageService,
              private utils: UtilsService,
              private translate: TranslateService,
              private toasty: CustomToastyService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  // tradução
  FEITO: string  = 'Feito!';
  GRUPO_DE_EQUIPAMENTO_ALTERADO: string = 'Grupo de Equipamento alterado!';
  GRUPO_DE_EQUIPAMENTO_CADASTRADO: string = 'Grupo de Equipamento cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  GRUPO_DE_EQUIPAMENTO_DELETADO: string = 'Grupo de Equipamento deletado!';

  configurarTraducao() {
    this.FEITO = this.translate.instant('FEITO');
    this.GRUPO_DE_EQUIPAMENTO_ALTERADO = this.translate.instant('GRUPO_DE_EQUIPAMENTO_ALTERADO');
    this.GRUPO_DE_EQUIPAMENTO_CADASTRADO = this.translate.instant('GRUPO_DE_EQUIPAMENTO_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.GRUPO_DE_EQUIPAMENTO_DELETADO = this.translate.instant('GRUPO_DE_EQUIPAMENTO_DELETADO');
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.grupoEquipamento = new GrupoEquipamento();

    if(this.id > 0)
      this.selecionarGrupoEquipamento();
    else
      this.listargruposEquipamento();
  }

  selecionarGrupoEquipamento() {
    this.carregando = true;
    this.gruposEquipamentoService.getGrupoEquipamento(this.id).subscribe(res =>{
      this.grupoEquipamento = new GrupoEquipamento();
      this.grupoEquipamento = res;
      this.carregando = false;
    },
    err =>{
      this.utils.getErro(err);
      this.carregando = false;
    })
  }

  listargruposEquipamento(){
    this.carregando = true;
    this.gruposEquipamentoService.getGrupoEquipamentos().subscribe(
      res => {
        this.gruposEquipamento = res;
        this.auxGruposEquipamento = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarGruposEquipamento();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.grupoEquipamento.Id > 0)
      this.gruposEquipamentoService.putGrupoEquipamento(this.grupoEquipamento.Id, this.grupoEquipamento).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.GRUPO_DE_EQUIPAMENTO_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.gruposEquipamentoService.postGrupoEquipamento(this.grupoEquipamento).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.GRUPO_DE_EQUIPAMENTO_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(grupoEquipamentoId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title:  this.DELETAR + ' '+ nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText:  this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.gruposEquipamentoService.deleteGrupoEquipamento(grupoEquipamentoId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.GRUPO_DE_EQUIPAMENTO_DELETADO, 'success');
              this.listargruposEquipamento();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarGruposEquipamento(){
    this.gruposEquipamento = this.auxGruposEquipamento.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/grupo-equipamento']);
  }
}
