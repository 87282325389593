import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UnidadeConsumidora } from '../classes/UnidadeConsumidora';

@Injectable({
  providedIn: 'root'
})
export class UnidadeConsumidoraService {

  constructor(private http: HttpClient) { }

  getUnidadesConsumidoras(): Observable<any> {
    return this.http.get(`${environment.apiURL}/unidadesconsumidoras`)
  }

  getUnidadeConsumidora(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/unidadesConsumidoras/${id}`)
  }

  postUnidadeConsumidora(unidadeConsumidora: UnidadeConsumidora): Observable<any> {
    return this.http.post(`${environment.apiURL}/unidadesconsumidoras`, unidadeConsumidora);
  }

  putUnidadeConsumidora(id: number, unidadeConsumidora: UnidadeConsumidora): Observable<any> {
    return this.http.put(`${environment.apiURL}/unidadesconsumidoras/${id}`, unidadeConsumidora);
  }

  deleteUnidadeConsumidora(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/unidadesconsumidoras/${id}`);
  }

  getUnidadesConsumidorasPorFazenda(idFazenda: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/unidadesconsumidoras/fazenda/${idFazenda}`)
  }


}
