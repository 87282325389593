import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Empresa } from 'src/app/classes/Empresa';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-escolha-empresa-grid',
  templateUrl: './escolha-empresa-grid.component.html',
  styleUrls: ['./escolha-empresa-grid.component.scss']
})
export class EscolhaEmpresaGridComponent implements OnInit, OnChanges {

  @ViewChild('gridEmpresaDisponiveis', { static: false }) gridEmpresasDiponiveis;
  @ViewChild('gridEmpresasAdicionadas', { static: false }) gridEmpresasAdicionadas;

  @Input() listaEmpresaDisponiveis: Empresa[] = [];
  @Input() listaEmpresaRelacionadas: Empresa[] = [];

  @Output() listaEmpresaAdicionadasEmitted = new EventEmitter<Empresa[]>();

  listaEmpresaAdicionadas: Empresa[] = [];
  listaEmpresaSelecionadas: Empresa[] = [];
  colDefs: ColDef[] = [];
  // gridApiDisponiveis: GridApi;
  // gridApiAdicionados: GridApi;

  constructor() { }

  ngOnInit() {
    this.configurar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.AtualizarListas();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'NomeFantasia', headerName: 'Nome da Empresa', flex: 1 },
      { field: 'RazaoSocial', headerName: 'Razão Social', flex: 1 }
    ];
  }

  adicionarEmpresas() {
    this.listaEmpresaSelecionadas.forEach(item => {
      this.tiraItemDeLista(this.listaEmpresaDisponiveis, item);
      this.adicionarItemALista(this.listaEmpresaAdicionadas, item);
    });
    this.atualizarGrids();
  }

  removerEmpresas() {
    this.listaEmpresaSelecionadas.forEach(item => {
      this.tiraItemDeLista(this.listaEmpresaAdicionadas, item);
      this.adicionarItemALista(this.listaEmpresaDisponiveis, item);
    });
    this.atualizarGrids();
  }

  tiraItemDeLista(lista: Empresa[], item: Empresa) {
    const index = lista.findIndex(i => i.Id == item.Id)
    if (index == -1) return;
    lista.splice(index, 1);
  }

  adicionarItemALista(lista: Empresa[], item: Empresa) {
    const adicionado = lista.find(i => i.Id == item.Id)
    if (adicionado != undefined) return;
    lista.push(item)
  }

  atualizarGrids() {
    // this.gridApiAdicionados.setRowData(this.listaEmpresaAdicionadas);
    // this.gridApiDisponiveis.setRowData(this.listaEmpresaDisponiveis);
    this.listaEmpresaAdicionadasEmitted.emit(this.listaEmpresaAdicionadas);
    this.listaEmpresaSelecionadas = [];
  }

  onGridReadyDiponiveis(event) {
    // this.gridApiDisponiveis = event.api;
  }

  onGridReadyAdicionados(event) {
    // this.gridApiAdicionados = event.api;
  }

  selectionChanged(event) {
    this.listaEmpresaSelecionadas = event;
  }

  private AtualizarListas() {
    this.listaEmpresaAdicionadas = [...this.listaEmpresaRelacionadas];
    this.listaEmpresaRelacionadas.forEach(item => {
      this.tiraItemDeLista(this.listaEmpresaDisponiveis, item);
    });
    this.atualizarGrids();
  }
}
