import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Cultura } from 'src/app/classes/Cultura';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { GrupoProdutoCulturaCentroCusto } from 'src/app/classes/GrupoProdutoCulturaCentroCusto';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-grupo-produto-forms',
  templateUrl: './grupo-produto-forms.component.html',
  styleUrls: ['./grupo-produto-forms.component.scss']
})
export class GrupoProdutoFormsComponent implements OnInit {

  @Input() IsNovo: boolean = false;
  @Input() IsServico: boolean = false;
  @Output() cancelarEvent = new EventEmitter<any>();

  grupoProduto: GrupoProduto;
  id: number = 0;
  carregando: boolean = false;
  servicos: boolean = false;

  form: any;
  public isSubmit: boolean;

  listaGrupoProdutoCulturaCentroCusto: GrupoProdutoCulturaCentroCusto[] = [];
  culturas: Cultura[] = [];
  integraTotvs: boolean = false;

  // tradução
  GRUPO_DE_SERVICO_ALTERADO: string = 'Grupo de Serviço alterado!';
  GRUPO_DE_PRODUTO_ALTERADO: string = 'Grupo de Produto alterado!';
  FEITO: string = 'Feito!';
  GRUPO_DE_SERVICO_CADASTRO: string = 'Grupo de Serviço cadastro!';
  GRUPO_DE_PRODUTO_CADASTRO: string = 'Grupo de Produto cadastro!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  GRUPO_DE_SERVICO_DELETADO: string = 'Grupo de Serviço deletado!';
  GRUPO_DE_PRODUTO_DELETADO: string = 'Grupo de Produto deletado!';


  configurarTraducao() {
    this.GRUPO_DE_SERVICO_ALTERADO = this.translate.instant('GRUPO_DE_SERVICO_ALTERADO');
    this.GRUPO_DE_PRODUTO_ALTERADO = this.translate.instant('GRUPO_DE_PRODUTO_ALTERADO');
    this.FEITO = this.translate.instant('FEITO');
    this.GRUPO_DE_SERVICO_CADASTRO = this.translate.instant('GRUPO_DE_SERVICO_CADASTRO');
    this.GRUPO_DE_PRODUTO_CADASTRO = this.translate.instant('GRUPO_DE_PRODUTO_CADASTRO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.GRUPO_DE_SERVICO_DELETADO = this.translate.instant('GRUPO_DE_SERVICO_DELETADO');
    this.GRUPO_DE_PRODUTO_DELETADO = this.translate.instant('GRUPO_DE_PRODUTO_DELETADO');
  }

  constructor(
    private gruposProdutoService: GrupoProdutoService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private translate: TranslateService,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private planejamentoService: PlanejamentoService
    ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
    this.servicos = this.router.url.includes('grupo-servico');
  }

  ngOnInit() {
    this.IsNovo = this.router.url.search("inserir") > 0;
    if(this.IsNovo)
    {
      if(this.IsServico)
      {
        this.servicos = true;
      }
    }
    this.grupoProduto = new GrupoProduto();
    this.integraTotvs = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Totvs_RM;
   
    this.planejamentoService.getCulturas().subscribe(ret => {
      this.culturas = ret;
      this.carregando = false;
    });

    if (this.id > 0)
      this.selecionarGrupoProduto();

    this.configurarTraducao();
  }

  selecionarGrupoProduto() {
    this.carregando = true;
    this.gruposProdutoService.getGrupoProduto(this.id).subscribe(res => {
      this.grupoProduto = new GrupoProduto();
      this.grupoProduto = res.grupoProduto;
      this.listaGrupoProdutoCulturaCentroCusto = res.listaGruposCusto;
      this.carregando = false;
    },
      ex => {
        this.utils.getErro(ex);
        this.carregando = false;
      })
  }

  save(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if (this.listaGrupoProdutoCulturaCentroCusto.length > 0) {
      this.grupoProduto.listaGrupoProdutoCulturaCentroCusto = this.listaGrupoProdutoCulturaCentroCusto;
    }
    if (this.servicos) {
      this.grupoProduto.Servico = true;
    }
    else {
      this.grupoProduto.Servico = false;
    }
    if (this.grupoProduto.Id > 0)
      this.gruposProdutoService.putGrupoProduto(this.grupoProduto.Id, this.grupoProduto).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.servicos ? this.GRUPO_DE_SERVICO_ALTERADO : this.GRUPO_DE_PRODUTO_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.gruposProdutoService.postGrupoProduto(this.grupoProduto).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.servicos ? this.GRUPO_DE_SERVICO_CADASTRO : this.GRUPO_DE_PRODUTO_CADASTRO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  cancelar() {
    this.cancelarEvent.emit(this.servicos);
  }

  novoItemCCusto() {
    let itemCCusto: GrupoProdutoCulturaCentroCusto = new GrupoProdutoCulturaCentroCusto;
    this.listaGrupoProdutoCulturaCentroCusto.push(itemCCusto);
  }

}
