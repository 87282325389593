import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EnumTipoPagamento, LancamentoFinanceiro, ListaEnumTipoPagamento } from 'src/app/classes/LancamentoFinanceiro';
import { Movimento } from 'src/app/classes/Movimento';
import { Transacao } from 'src/app/classes/Transacao';
import { EnumOrigemLancamentoFinanceiro } from 'src/app/classes/enum/EnumOrigemLancamentoFinanceiro';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { LancamentoFinanceiroService } from 'src/app/services/lancamento-financeiro.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-movimento-financeiro-forms',
  templateUrl: './movimento-financeiro-forms.component.html',
  styleUrls: ['./movimento-financeiro-forms.component.scss']
})
export class MovimentoFinanceiroFormsComponent implements OnInit {
  @Input() movimento: Movimento;
  @Input() transacao: Transacao;
  showFormFinanceiro = false;
  lancamentoFinanceiro = new LancamentoFinanceiro();
  lancamentoFinanceiroSelecionado: LancamentoFinanceiro;
  lancamentosFinanceiroSelecionados: LancamentoFinanceiro[] = [];
  listaEnumTipoPagamento = ListaEnumTipoPagamento;
  colDefsFinanceiro: ColDef[] = [];
  showForm = false;
  carregando: boolean = false;
  isSubmitFinanceiro: boolean;
  constructor(private lancamentoFinanceiroService: LancamentoFinanceiroService,
    private utils: UtilsService) { }

  ngOnInit() {
    this.colDefsFinanceiro = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'Parcela', flex: 1 },
      { headerName: 'Vencimento', field: 'DataVencimento', flex: 1, valueFormatter: (params) => `${this.utils.formatarData(params.data.DataVencimento)}` },
      { headerName: 'Valor', field: 'ValorOriginal', cellStyle: { 'text-align': 'right' }, flex: 1, valueFormatter: (params) => `R$ ${this.utils.formatarDecimais(params.data.ValorOriginal)}` },
      { headerName: 'Tipo Pagamento', field: 'GetTipoPagamento', flex: 1 },
      { field: 'Adiantamento', flex: 1, cellRenderer: (params) => params.data.Adiantamento
        ? '<span class="badge badge-light-success custom-badge-grid">Sim</span>'
        : '<span class="badge badge-light-danger custom-badge-grid">Não</span>'},

    ]
  }
  formaPagamento(tipo: EnumTipoPagamento){
    return ListaEnumTipoPagamento.find(p => p.value == tipo).name;
  }
  novo() {
    this.showForm = true;
    this.lancamentoFinanceiroSelecionado = new LancamentoFinanceiro();
  }

  novoFinanceiro() {
    this.lancamentoFinanceiro = new LancamentoFinanceiro();
    this.lancamentoFinanceiro.Parcela = this.movimento.LancamentosFinanceiros.length + 1;
    this.showFormFinanceiro = true;
  }

  changeTypeFinanceiro(event) {
    this.showFormFinanceiro = !this.showFormFinanceiro;
  }

  salvarFinanceiro() {
    this.carregando = true;
    const form = new FormGroup({
      Parcela: new FormControl(this.lancamentoFinanceiro.Parcela, [Validators.required]),
      ValorOriginal: new FormControl(this.lancamentoFinanceiro.ValorOriginal, [Validators.required]),
      DataVencimento: new FormControl(this.lancamentoFinanceiro.DataVencimento, [Validators.required]),
      TipoPagamento: new FormControl(this.lancamentoFinanceiro.TipoPagamento, [Validators.required]),
    })
    if (!form.valid) {
      this.isSubmitFinanceiro = true;
      this.carregando = false;
      return;
    }
    this.lancamentoFinanceiro.OrigemId = this.movimento.Id;
    this.lancamentoFinanceiro.Origem = EnumOrigemLancamentoFinanceiro.Movimento;
    if (this.lancamentoFinanceiro.Id > 0)
      this.lancamentoFinanceiroService.put(this.lancamentoFinanceiro.Id, this.lancamentoFinanceiro)
        .subscribe(res => {
          this.utils.handleSuccess('Lançamento salvo!');
          const index = this.movimento.LancamentosFinanceiros.findIndex(p => p.Id == this.lancamentoFinanceiro.Id);
          this.movimento.LancamentosFinanceiros = this.movimento.LancamentosFinanceiros.filter(r => r.Id != this.lancamentoFinanceiro.Id)
          if (index != -1) {
            this.movimento.LancamentosFinanceiros.splice(index, 1, res);
            // this.rateiosGrid.setRowData(this.movimento.Rateios);
          }
          this.showFormFinanceiro = false;
          this.attTotalFinanceiro();
          this.carregando = false;
        }, err => this.utils.getErro(err));
    else {
      if ((this.movimento.LancamentosFinanceiros.reduce((acc, curr) => acc + curr.ValorOriginal, 0) + this.lancamentoFinanceiro.ValorOriginal) > this.movimento.ValorLiquido) {
        this.utils.handleWarning('O valor total das parcelas ultrapassa o valor total do Movimento!')
        this.carregando = false;
        return;
      }
      this.lancamentoFinanceiroService.post(this.lancamentoFinanceiro)
        .subscribe(res => {
          this.utils.handleSuccess('Lançamento cadastrado!');
          this.movimento.LancamentosFinanceiros.push(res)
          this.showFormFinanceiro = false;
          this.attTotalFinanceiro();
          this.carregando = false;
        }, err => this.utils.getErro(err));

    }

  }

  selectionChangedFinanceiro(event) {
    this.lancamentosFinanceiroSelecionados = event;
  }

  cellDoubleClickedFinanceiro(event) {
    this.lancamentoFinanceiro = this.lancamentoFinanceiroSelecionado;
    this.showFormFinanceiro = true;
  }

  cellClickedFinanceiro({ data }) {
    this.lancamentoFinanceiroSelecionado = data;
  }

  attTotalFinanceiro() {
    this.movimento.TotalParcelas = `${this.movimento.LancamentosFinanceiros.length}`;
    this.movimento.TotalMovimento = 'R$ ' + this.utils.formatarDecimais(this.movimento.ValorLiquido);
    this.movimento.SaldoParcelar = 'R$ ' + this.utils.formatarDecimais(this.movimento.ValorLiquido - (this.movimento.LancamentosFinanceiros.reduce((acc, curr) => acc + curr.ValorOriginal, 0)))
  }

  removeFinanceiro() {
    if (this.lancamentosFinanceiroSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione um ou mais Lançamentos para prosseguir!");
      return;
    }
    this.lancamentosFinanceiroSelecionados.forEach(item => {
      this.lancamentoFinanceiroService.delete(item.Id)
        .subscribe(res => {
          this.movimento.LancamentosFinanceiros = this.movimento.LancamentosFinanceiros.filter(p => p.Id != item.Id);
          this.attTotalFinanceiro();
          this.utils.handleSuccess('Lançamento removido!');
          this.carregando = false;
        }, err => this.utils.getErro(err));

    })
  }
}
