import { EnumStatusAprovacao } from "../Movimento";
import { Usuario } from "../Usuario";
import { AnexoCotacao } from "./AnexoCotacao";

export class Cotacao{
    Id: number = 0;
    Titulo:string = "";
    TipoCotacao: number = 0;
    DataCriacao: Date = new Date();
    DataLimite: Date = new Date();
    CompradorResponsavelId: number;
    CompradorResponsavel:Usuario;
    Status: number = 0;
    UsarSimilar:boolean = false;
    PercentualDescontoDesejado:number = 0;
    PercentualEliminacao:number = 0;
    ValorTotalCotacao:number = 0;
    ValorTotalCotacaoString:string = "";
    getStatus:string = "";
    QuantidadeItens:number = 0;
    EmpresaId: number;
    TempoRestante: number = 0;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId:number;
    UsuarioAlteracaoId:number;
    FlagExcluido:boolean = false;
    TransacaoDestinoId: number;
    AprovadoString:string = "";
    Aprovado: boolean;
    MotivmentoOrigemId: number;
    StatusAprovacao: EnumStatusAprovacao;
    MotivoAprovacao:string = "";
    Anexos: AnexoCotacao[];
    //auxiliar
    statusOc: number = 0;
    FornecedorParticipante: boolean;
}
