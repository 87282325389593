import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Agencia } from 'src/app/classes/Agencia';
import { Bancos } from 'src/app/classes/Bancos';
import { AgenciaService } from 'src/app/services/agencia.service';
import { BancosService } from 'src/app/services/banco.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agencia-forms',
  templateUrl: './agencia-forms.component.html',
  styleUrls: ['./agencia-forms.component.scss']
})
export class AgenciaFormsComponent implements OnInit {

  id:number;
  agencia: Agencia;
  agencias: Agencia[];
  auxAgencias: Agencia[];
  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  listaBancos: Bancos[] = [];

  constructor(
    private bancosService: BancosService,
    private agenciaService: AgenciaService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private utils : UtilsService) {
      this.route.params.subscribe(res => {
        this.id = res.id;
      });
    }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.agencia = new Agencia();
    if(this.router.url.search("inserir") > 0)
      this.agencia = new Agencia()
    else
      this.selecionarAgencia();

    this.bancosService.Listar().subscribe(res => {
      this.listaBancos = res;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });

  }

  selecionarAgencia() {
    this.carregando = true;
    this.agenciaService.ObterPorId(this.id).subscribe(res =>{
      this.agencia = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.agencia.Id > 0){
                  
      this.agenciaService.Atualizar(this.agencia.Id, this.agencia).subscribe(
        res => {
          this.toasty.show('Feito!', 'Agência alterada!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/agencia']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
    else{

      this.agenciaService.Inserir(this.agencia).subscribe(
        res => {
          this.toasty.show('Feito!', 'Agência cadastrada!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/agencia']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
  }

  

  cancelar(){
    this.router.navigate(['/cadastros/agencia']);
  }

}