import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-barra-tarefas',
  templateUrl: './barra-tarefas.component.html',
  styleUrls: ['./barra-tarefas.component.scss']
})
export class BarraTarefasComponent implements OnInit {

  constructor() { }

  @Output() toggleFilter = new EventEmitter<any>();
  @Output() toggleGrid = new EventEmitter<any>();
  @Output() novo = new EventEmitter<any>();
  @Output() salvar: EventEmitter<any> = new EventEmitter();
  @Output() clonar = new EventEmitter<any>();
  @Output() cancelar = new EventEmitter<any>();
  @Output() excluir = new EventEmitter<any>();
  @Output() imprimir = new EventEmitter<any>();
  @Output() buscar = new EventEmitter<any>();
  @Output() acao = new EventEmitter<any>();
  @Output() rastreamento = new EventEmitter<any>();
  @Output() cotacao = new EventEmitter<any>();
  @Output() receber = new EventEmitter<any>();
  @Output() files: EventEmitter<any> = new EventEmitter();
  @Input() acoes: string[];
  @Input() customClass: string;
  @Input() icons: string[] = [];
  @Input() disables: string[] = [];
  @Input() qtd: number = 0;
  @Input() placementTooltip: string = 'bottom';
  @Input() titulo: string;
  @Input() filtroOn: boolean = false;
  hideTitulo: boolean = false;
  textoBusca: string = '';
  iconFilter = 'filter_alt_off';

  alterIconFilter(){
    this.iconFilter = this.iconFilter == 'filter_alt' ? 'filter_alt_off' : 'filter_alt';
    this.iconFilter = this.filtroOn ? 'filter_alt' : this.iconFilter;
    console.debug(this.toggleFilter)
    if(this.iconFilter == 'filter_alt')
      this.toggleFilter.emit(false);
    else
      this.toggleFilter.emit(true);
  }

  ngOnInit() {
    this.hideTitulo = !this.titulo || this.titulo.trim() === '';
  }

}
