export class UnidadeConsumidora {
    Id: number;
    IdInstalacao:string = "";
    EmpresaId: number;
    FazendaId: number;
    ModeloMedidor:string = "";
    DemandaContratada: number = 0;
    DescricaoInstalacao:string = "";
    HorarioPontaInicial: Date = new Date();
    HorarioPontaFinal: Date = new Date();
    MetaGeralNoturno: number = 0;
    AniversarioConta: Date = new Date();
    ModalidadeTarifaria: number = 0;
    Classe: number = 0;
}


export enum enumModalidadeTarifaria
{
    Convencional = 1,
    THSVerde = 2,
    THSAzul = 3
}

export enum enumClasse
{
    Industrial = 1,
    Rural = 2,
    RuralMonofasico = 3,
    RuralTrifasico = 4
}
