import { Usuario } from 'src/app/classes/Usuario';
import { ChamadoService } from 'src/app/services/chamados.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chamado, EnumCriticidadeChamado, EnumModuloChamado, EnumStatusChamado, EnumTipoChamado, ListaCriticidadeChamado, ListaModuloChamado, ListaStatusChamado, ListaTipoChamado } from 'src/app/classes/chamado/Chamado';
import { UtilsService } from 'src/app/utils/utils.service'
import { Anexo } from 'src/app/classes/chamado/Anexo';
import * as moment from 'moment'
import { Comentario } from 'src/app/classes/chamado/Comentario';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-detalhe-chamado',
  templateUrl: './detalhe-chamado.component.html',
  styleUrls: ['./detalhe-chamado.component.scss']
})
export class DetalheChamadoComponent implements OnInit {

  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    private chamadoService: ChamadoService,
    private usuarioService: UsuarioService,
    private utils: UtilsService,
    private toasty: CustomToastyService,
    private translateService: TranslateService
  )
  {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }
  public id: number = 0;
  public chamado: Chamado = new Chamado();
  public chamadoEdit: Chamado = new Chamado();
  public usuario: Usuario = new Usuario();
  public albums = [];
  public listaStatus = ListaStatusChamado;
  public listaModulos = ListaModuloChamado;
  public listaCriticidades = ListaCriticidadeChamado;
  public listaTipos = ListaTipoChamado;
  public novoComentario: Comentario;
  public isSubmitNew = false;
  public carregando = false;

  ngOnInit() {
    this.usuario = this.storageService.getUsuario();
    this.getDetalhes(this.id);
    this.novoComentario = new Comentario();
  }

  getDetalhes(id: number){
    this.chamadoService.find(id).subscribe(
      res => {
        this.chamado = res;
        this.chamadoEdit = JSON.parse(JSON.stringify(res));
        this.popularAlbum(this.chamado.Anexos)
        // this.chamadoService.getAnexos(res.Id)
        // .subscribe(
        //   res2 => {
        //     this.chamado.Anexos = res2;
        //     this.popularAlbum(this.chamado.Anexos)
        //   },
        //   err => {
        //     this.utils.getErro(err);
        //   }
        // );
        this.getComentarios();
      },
      err => {
        this.utils.getErro(err);
      }
    )
  }

  concluirCriacaoChamado(){
    this.getDetalhes(this.id);
  }

  getComentarios(){
    this.carregando = true;
    this.chamadoService.getComentarios(this.id)
    .subscribe(
      res => {
        this.chamado.Comentarios = res;
        this.chamado.Comentarios.sort((a, b) => Date.parse(b.DataCadastro) - Date.parse(a.DataCadastro));
        if(this.chamado.Comentarios.length == 0)
          this.carregando = false;

        this.chamado.Comentarios.forEach(p => {
          this.usuarioService.findUsuario(p.UsuarioCadastroId)
          .subscribe(
            res => {
              p.UsuarioCadastro = res;
              this.carregando = false;
            },
            err => {
              this.utils.getErro(res);
              this.carregando = false;
            }
          )
        })
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    )
  }

  salvarComentario(form: any, isEdit: boolean = false, comentario: Comentario = null) {
    const msg_sucesso = this.translateService.instant("COMENTARIO_SALVO_SUCESSO");
    const msg_erro = this.translateService.instant("COMENTARIO_ERRO_SALVAR");
    if (!form.valid) {
      this.isSubmitNew = true;
      this.toasty.show('Ops!', msg_erro, 'warning')
      return;
    }
    const self = this;
    function concluir(){
      self.utils.handleSuccess(msg_sucesso);
      self.getComentarios();
    }

    console.debug('comentario', comentario)
    if(!isEdit){
      this.novoComentario.CarregandoSave = true;
      this.novoComentario.Autor = this.usuario.Nome;
      this.novoComentario.ChamadoId = this.id;
      console.debug('comentario', this.novoComentario)
      this.chamadoService.postComentario(this.id, this.novoComentario)
      .subscribe(
        res => {
          concluir();
          this.novoComentario.CarregandoSave = false;
          this.novoComentario = new Comentario();
        },
        err => {
          this.utils.getErro(err);
          this.novoComentario.CarregandoSave = false;
        }
      );
    }else{
      this.chamadoService.putComentario(this.id, comentario.Id, comentario)
      .subscribe(
        res => {
          concluir();
          comentario.CarregandoSave = false;
        },
        err => {
          this.utils.getErro(err);
          comentario.CarregandoSave = false;
        }
      )
    }
  }

  toggleEditComentario(comentario: Comentario){
    comentario.IsEditar = !comentario.IsEditar;
  }

  excluirComentario(comentario: Comentario){
    comentario.Excluindo = true;
    const mensagem =  this.translateService.instant("COMENTARIO_EXCLUIDO_SUCESSO");
    this.chamadoService.deleteComentario(this.id, comentario.Id)
    .subscribe(
      res => {
        this.utils.handleSuccess(mensagem);
        comentario.Excluindo = false;
        this.getComentarios();
      },
      err => {
        this.utils.getErro(err);
        comentario.Excluindo = false
      }
    )
  }

  excluirImagem(event: any) {
    const mensagem = this.translateService.instant("IMAGEM_EXCLUIDA_SUCESSO")
    this.chamadoService.deleteAnexo(this.id, event.id)
    .subscribe(
      res => {
        this.utils.handleSuccess(mensagem);
        this.getDetalhes(this.id);
      },
      err => {
        this.utils.getErro(err);
      }
    )
  }


  formatarData(data: string){
    return moment(data).format('DD-MM-YYYY');
  }

  calcularTempo(data: Date){
    return this.utils.calcularTempoDecorrido(data);
  }

  tipoChamado(tipo: EnumTipoChamado): string[]{
    // cor, icone, tooltip

    let duvida: string = this.translateService.instant("DUVIDA");
    let sugestao: string = this.translateService.instant("SUGESTAO");
    let erro: string = this.translateService.instant("ERRO");

    switch (tipo) {
      case EnumTipoChamado.Duvida:
        return ['text-primary', 'help', duvida]
        break;
      case EnumTipoChamado.Sugestao:
        return ['text-info', 'info', sugestao]
        break;
      case EnumTipoChamado.Erro:
        return ['text-danger', 'bug_report', erro]
        break;
    }
  }

  moduloChamado(modulo: EnumModuloChamado): string[]{
    // icone, tooltip

    let compras: string = this.translateService.instant("COMPRAS");
    let manutecao: string = this.translateService.instant("MANUTECAO");
    let operacao: string = this.translateService.instant("OPERACAO");

    switch (modulo) {
      case EnumModuloChamado.Compras:
        return ['fa fa-shopping-basket', compras]
        break;
      case EnumModuloChamado.Manutencoes:
        return ['fa fa-tools', manutecao]
        break;
      case EnumModuloChamado.Operacoes:
        return ['fa fa-tractor', operacao]
        break;
    }
  }

  criticidadeBadge(obj: EnumCriticidadeChamado) {
    switch (obj) {
      case EnumCriticidadeChamado.Alto:
        return '<span class="badge m-r-5 badge-danger ">Crítico</span>'
        break
      case EnumCriticidadeChamado.Medio:
        return '<span class="badge m-r-5 badge-warning">Importante</span>'
        break
      case EnumCriticidadeChamado.Baixo:
        return '<span class="badge m-r-5 badge-success">Normal</span>'
        break
      default:
        break
    }
  }

  private popularAlbum(anexos: Anexo[]) {
    this.albums = [];
    anexos.forEach((p) => {
      const album = {
        id: p.Id,
        src: p.Imagem,
        caption: p.Descricao,
        thumb: p.Imagem,
      }
      this.albums.push(album)
    });
  }

  updateStatusChamado(){

    if(this.chamado.Status == EnumStatusChamado.Aberto){
      this.chamado.Status = EnumStatusChamado.EmAndamento;
    }else if(this.chamado.Status == EnumStatusChamado.EmAndamento){
      this.chamado.Status = EnumStatusChamado.Concluido;
    } else if(this.chamado.Status == EnumStatusChamado.Concluido){
      this.chamado.Status = EnumStatusChamado.EmAndamento;
    }

    this.chamadoService.put(this.id, this.chamado)
    .subscribe(
      res => {
        this.utils.handleSuccess(this.translateService.instant('STATUS_ATUALIZADO_COM_SUCESSO'));
      }
    )
  }

}
