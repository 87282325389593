import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { Checklist } from 'src/app/classes/Checklist';
import { ChecklistService } from 'src/app/services/checklist.service';

@Component({
  selector: 'app-select-checklist',
  templateUrl: './select-checklist.component.html',
  styleUrls: ['./select-checklist.component.scss']
})
export class SelectChecklistComponent implements OnInit {
  @Output() checklistChange: EventEmitter<any> = new EventEmitter();  
  @Input() checklistId: number = 0;
  @Input() isSubmit: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() auxClass: string;
  @Input() checklists: Checklist[];
  @Input() puxarAPI: Boolean = true;
  @Input() multiple: boolean = false;
  
  @Input() checklistsFiltro: number[] = [];

  //ng-select 
  pagina: number = 2;
  termoBusca: String = '';
  carregandoChecklist: boolean = false;
  ultimaPagina: boolean = false;
  public inputChecklist$ = new Subject<string | null>();

  constructor(private checklistService: ChecklistService) {
    this.inputChecklist$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMore(newTerm);
    });
  }

  ngOnInit() {
    this.checklistService.ObterPorId(this.checklistId).subscribe(check => {
      this.checklistService.getPaginando(1, check.Descricao).subscribe(ret => {
        this.checklists = ret.Lista;
        this.fetchMore(check.Descricao);
      });
    });
  }

  listarChecklists() {
    if (this.puxarAPI)
      this.checklistService.getPaginando(1, '').subscribe(ret => {
        this.checklists = ret.Lista;
      });
  }

  changeChecklist(checklists: any) {
    this.checklistChange.emit(checklists);
    
  }

  //ng-select functions
  onScrollToEndChecklist() {
    if (this.carregandoChecklist || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }

  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoChecklist = true;
      this.checklistService.getPaginando(this.pagina, this.termoBusca).subscribe(ret => {
        this.carregandoChecklist = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.checklists.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.checklists = termoBusca != null ? ret.Lista : this.checklists.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

      });
    }, 300);
  }

  getChecklist(id: number): Checklist {
    let novo = new Checklist();

    if (!this.checklists)
      return novo;

    let checklist = this.checklists.filter(x =>
      x.Id == id
    );
    return checklist.length > 0 ? checklist[0] : novo;
  }

}
