import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { Area } from 'src/app/classes/Area';
import { Cultivar } from 'src/app/classes/Cultivar';
import { Fazenda } from 'src/app/classes/Fazenda';
import { Planejamento } from 'src/app/classes/Planejamento';
import { Safra } from 'src/app/classes/Safra';
import { AnoSafraService } from 'src/app/services/ano-safra.service';
import { AreaService } from 'src/app/services/area.service';
import { FazendaService } from 'src/app/services/fazenda.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { SafraService } from 'src/app/services/safra.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-tab-lateral',
  templateUrl: './tab-lateral.component.html',
  styleUrls: ['./tab-lateral.component.scss']
})
export class TabLateralComponent implements OnInit {
  @Input() carregando: boolean = false;
  @Input() anosSafras: AnoSafra[];
  @Input() fazendas: Fazenda[];
  @Input() cultivares: Cultivar[];

  @Output() changePlanejamento: EventEmitter<Planejamento> = new EventEmitter();
  @Output() changeFazenda: EventEmitter<Fazenda> = new EventEmitter();

  fazendaSelecionada : Fazenda;
  anoSafraSelecionado : AnoSafra;
  safraSelecionada: Safra;
  planejamentoSelecionado: Planejamento;
  planejamentos: Planejamento[];
  safras: Safra[];
  areas: Area[];

  public isSubmit: boolean;

  constructor(private safraService: SafraService,
    private fazendaService: FazendaService,
    private anoSafraService: AnoSafraService,
    private utils: UtilsService,
    private areaService: AreaService,
    private planejamentoService: PlanejamentoService) { }

  ngOnInit() {
    this.onLoad();
  }

  ngOnChanges() {
    this.onLoad();
  }

  onLoad() {
    if (this.anoSafraService.anoSafraSelecionado == null) {
      if (this.anoSafraSelecionado == null && this.anosSafras != null && this.anosSafras.length > 0)
        this.selecionarAnoSafra(this.anosSafras[this.anosSafras.length-1], true);
    } else
      this.selecionarAnoSafra(this.anoSafraService.anoSafraSelecionado, true);

    if (this.fazendaService.fazendaSelecionada == null) {
      if (this.fazendaSelecionada == null && this.fazendas != null && this.fazendas.length > 0)
        this.selecionarFazenda(this.fazendas[this.fazendas.length-1], true);
    } else
      this.selecionarFazenda(this.fazendaService.fazendaSelecionada, true);
  }

  selecionarPlanejamento(planejamento: Planejamento) {
    if (this.planejamentoSelecionado == null || planejamento == null || this.planejamentoSelecionado.Id != planejamento.Id) {
      this.planejamentoSelecionado = planejamento;
      this.changePlanejamento.emit(this.planejamentoSelecionado);
      this.planejamentoService.planejamentoSelecionado = this.planejamentoSelecionado;
    }
  }

  selecionarFazenda(fazenda: Fazenda, onload?: boolean) {
    if (this.fazendaSelecionada == null || this.fazendaSelecionada.Id != fazenda.Id) {
      if (!onload){
        this.safraSelecionada = null;
        this.safraService.safraSelecionada = null;
        this.safras = [];
        this.areas = [];
        this.planejamentos = [];

        this.planejamentoService.planejamentoSelecionado = null;
        this.selecionarPlanejamento(null);
      }
      this.fazendaSelecionada = fazenda;
      this.changeFazenda.emit(this.fazendaSelecionada);
      this.fazendaService.fazendaSelecionada = fazenda;

      this.listarSafras();
    }
  }

  selecionarAnoSafra(anoSafra: AnoSafra, onload?: boolean) {
    if (this.anoSafraSelecionado == null || this.anoSafraSelecionado.Id != anoSafra.Id) {
      if (!onload){
        this.safraSelecionada = null ;
        this.safraService.safraSelecionada = null;
        this.safras = [];
        this.areas = [];
        this.planejamentos = [];

        this.planejamentoService.planejamentoSelecionado = null;
        this.selecionarPlanejamento(null);
      }
      this.anoSafraSelecionado = anoSafra;
      this.anoSafraService.anoSafraSelecionado = anoSafra;

      this.listarSafras();
    }
  }

  selecionarSafra(safra: Safra, onload?: boolean) {
    if (this.safraSelecionada == null || this.safraSelecionada.Id != safra.Id) {
      if (!onload){
        this.planejamentoService.planejamentoSelecionado = null;
        this.selecionarPlanejamento(null);
      }
      this.safraSelecionada = safra;
      this.safraService.safraSelecionada = safra;

      this.listarPlanejamentos();
    }
  }

  listarPlanejamentos(){
    if (this.safraSelecionada == null)
      return;

    this.carregando = true;
    this.planejamentoService.getPlanejamentosPorSafra(this.safraSelecionada.Id).subscribe(
      res => {
        this.planejamentos = res;

        let planejamento = this.planejamentoService.planejamentoSelecionado == null ? this.planejamentos != null && this.planejamentos.length > 0 ? this.planejamentos[0] : null : this.planejamentoService.planejamentoSelecionado;
        this.selecionarPlanejamento(planejamento);

        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  listarSafras(){
    if (this.anoSafraSelecionado == null)
      return;

    if (this.fazendaSelecionada == null)
      return;

    this.carregando = true;
    this.safraService.getSafrasPorAnoFazenda(this.anoSafraSelecionado.Id, this.fazendaSelecionada.Id).subscribe(
      res => {
        this.safras = res;

        let safra = this.safraService.safraSelecionada == null ? this.safras != null && this.safras.length > 0 ? this.safras[0] : null : this.safraService.safraSelecionada;
        this.selecionarSafra(safra);

        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });

      this.carregando = true;
      this.areaService.getAreasPorFazendaPorAno(this.fazendaSelecionada.Id, this.anoSafraSelecionado.Id).subscribe(
        res => {
          this.areas = res;
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  getArea(id: number) : Area {
    if (!this.areas)
      return new Area();

    let area = this.areas.filter(x =>
      x.Id==id
    );

    return area.length > 0 ? area[0] : new Area();
  }

  getCultivar(id: number) : Cultivar {
    if (!this.cultivares)
      return new Cultivar();

    let cultivar = this.cultivares.filter(x =>
      x.Id==id
    );

    return cultivar.length > 0 ? cultivar[0] : new Cultivar();
  }

}
