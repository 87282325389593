import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { TreeItem } from 'src/app/classes/TreeItem';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit {

  constructor() { }

  @Input() items: TreeItem[];
  @Input() rotaBase: string;
  arvore: any[] = [];

  ngOnInit() {
    this.arvore = this.transformDataToTree(this.items);
  }

  transformDataToTree(items: TreeItem[]): any[] {
    const roots: any[] = [];
    const map: { [key: number]: any } = {};
    let node: any;

    for (let i = 0; i < items.length; i++) {
      map[items[i].Codigo] = { ...items[i], children: [] };
    }
    for (let i = 0; i < items.length; i++) {
      node = map[items[i].Codigo];
      if (items[i].CodigoPai !== undefined && items[i].CodigoPai !== null && items[i].CodigoPai !== 0) {
        map[items[i].CodigoPai].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }
  refresh(){
    this.arvore = this.transformDataToTree(this.items);
  }
  onNodeClick(event){
    // console.debug(event)
  }

}
