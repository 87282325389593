import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Classe } from 'src/app/classes/Classe';
import { Pessoa } from 'src/app/classes/Pessoa';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { ClasseService } from 'src/app/services/classe.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-classe',
  templateUrl: './classe.component.html',
  styleUrls: ['./classe.component.scss']
})
export class ClasseComponent implements OnInit {

  DESCRICAO: string = 'Descrição';
  STATUS: string = 'Status';
  SIM: string = 'Sim';
  NAO: string = 'Não';
  ATIVO: string = 'Ativo';
  INATIVO: string = 'Inativo';
  SELECIONE_UMA_CLASSE_PARA_PROSSEGUIR: string = 'Selecione uma classe para prosseguir!';
  SELECIONE_APENAS_UMA_CLASSE_PARA_PROSSEGUIR: string = 'Selecione apenas uma classe para prosseguir!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  CLASSE_EXCLUIDA: string = 'Classe excluída!';

  configurarTraducao() {
    this.DESCRICAO = this.translate.instant('DESCRICAO');
    this.STATUS = this.translate.instant('STATUS');
    this.SIM = this.translate.instant('SIM');
    this.NAO = this.translate.instant('NAO');
    this.ATIVO = this.translate.instant('ATIVO');
    this.INATIVO = this.translate.instant('INATIVO');
    this.SELECIONE_UMA_CLASSE_PARA_PROSSEGUIR = this.translate.instant('SELECIONE_UMA_CLASSE_PARA_PROSSEGUIR');
    this.SELECIONE_APENAS_UMA_CLASSE_PARA_PROSSEGUIR = this.translate.instant('SELECIONE_APENAS_UMA_CLASSE_PARA_PROSSEGUIR');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.CLASSE_EXCLUIDA = this.translate.instant('CLASSE_EXCLUIDA');
  }

  constructor(
    private translate: TranslateService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private classeService: ClasseService
    ) { 
      this.route.params.subscribe(res => {
        this.id = res.id;
      });
    }

  @ViewChild('Grid', { static: false }) grid: any;

  colDefs: ColDef[] = [];
  listaClasse: Classe[] = [];
  objSelecionado: Classe = new Classe();
  objSelecionadoId: number = null;
  objetosSelecionados: Classe[] = [];
  fornecedores: Pessoa[];
  id: number = 0;
  inserindo: boolean;
  carregando: boolean = true;

  ngOnInit() {
    this.configurar();
    this.listarClasse();
  }

  configurar() {
    this.configurarTraducao();
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'Descricao', headerName: 'Descrição', flex: 1},
      {field: 'Ativo', headerName: 'Status', flex: 1, cellRenderer: (params) => this.statusBadge(params.data.Ativo)},
    ];

  }

  statusBadge(status: boolean) {
    if (status)
      return '<span class="text-success text-uppercase">'+ this.translate.instant('ATIVO') +'</span>'
    else
      return '<span class="text-danger text-uppercase">'+ this.translate.instant('INATIVO') +'</span>'
  }

  listarClasse() {
    this.inserindo = false;
    this.id = 0;
    this.classeService.Listar()
      .subscribe(res => {
        this.listaClasse = res;
        this.grid.setRowData(this.listaClasse);
      }, err => this.utils.getErro(err));
  }

  novo() {
    this.inserindo = true;
  }

  cellDoubleClicked(event) {
    this.id = event.data.Id;
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {
    this.configurarTraducao();
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning(this.SELECIONE_UMA_CLASSE_PARA_PROSSEGUIR);
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning(this.SELECIONE_APENAS_UMA_CLASSE_PARA_PROSSEGUIR);
      return;
    }

    Swal.fire({
      title: this.DELETAR + ' '+ this.objetosSelecionados[0].Descricao + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText:  this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.classeService.Excluir(this.objetosSelecionados[0].Id).subscribe(
            res => {
              this.utils.handleSuccess(this.CLASSE_EXCLUIDA);
              this.listarClasse();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

}
