import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoImprodutividade } from '../classes/TipoImprodutividade';

@Injectable({
  providedIn: 'root'
})
export class TipoImprodutividadeService {

  constructor(private http: HttpClient) { }

  getTiposImprodutividade(): Observable<any>{
    return this.http.get(`${environment.apiURL}/tipoImprodutividade`)
  }

  getTipoImprodutividade(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/tipoImprodutividade/${id}`)
  }

  postTipoImprodutividade(tipoImprodutividade: TipoImprodutividade): Observable<any>{
    return this.http.post(`${environment.apiURL}/tipoImprodutividade`, tipoImprodutividade);
  }

  putTipoImprodutividade(id: number, tipoImprodutividade: TipoImprodutividade): Observable<any>{
    return this.http.put(`${environment.apiURL}/tipoImprodutividade/${id}`, tipoImprodutividade);
  }

  deleteTipoImprodutividade(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/tipoImprodutividade/${id}`);
  }
}
