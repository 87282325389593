import { ProcessoPlanejamentoService } from './../../../services/processo-planejamento.service';
import { Component, OnInit } from '@angular/core';
import { Equipamento, TiposMedidorEquipamento } from 'src/app/classes/Equipamento';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { GrupoEquipamento } from 'src/app/classes/GrupoEquipamento';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { GrupoEquipamentoService } from 'src/app/services/grupo-equipamento.service';
import { environment } from 'src/environments/environment';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-equipamento',
  templateUrl: './equipamento.component.html',
  styleUrls: ['./equipamento.component.scss']
})
export class EquipamentoComponent implements OnInit {

  equipamentos: Equipamento[];
  auxEquipamentos: Equipamento[];
  equipamento: Equipamento;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;
  processosPlanejamento: ProcessoPlanejamento[];
  processosPlanejamentoSelecionados: number[] = [];
  showAbastecimento: boolean = false;
  listaTipoMedidor = TiposMedidorEquipamento;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";

  grupoEquipamentos: GrupoEquipamento[];

  baseIcone: string = environment.baseApiURL + '/Assets/Icones/';

  constructor(private equipamentosService: EquipamentoService,
              private grupoEquipamentoService: GrupoEquipamentoService,
              private route: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private toasty: CustomToastyService,
              private processoPlanejamentoService: ProcessoPlanejamentoService,
              private utils: UtilsService
            ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    this.equipamento = new Equipamento();

    this.grupoEquipamentoService.getGrupoEquipamentos().subscribe(ret =>{
      this.grupoEquipamentos = ret,
      err => {
        this.utils.getErro(err);
      };
    });

    this.processoPlanejamentoService.getProcessosPlanejamento().subscribe(res =>{
      this.processosPlanejamento = res,
      err => {
        this.utils.getErro(err);
      };
    });

    if(this.id > 0)
      this.selecionarEquipamento();
    else
      this.listarEquipamentos();
  }

  selecionarEquipamento() {
    this.carregando = true;
    this.equipamentosService.getEquipamento(this.id).subscribe(res =>{
      this.equipamento = new Equipamento();
      this.equipamento = res;
      this.carregando = false;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    })
  }

  listarEquipamentos(){
    this.carregando = true;
    this.equipamentosService.getEquipamentos().subscribe(
      res => {
        this.equipamentos = res;
        this.auxEquipamentos = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarEquipamentos();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid || this.equipamento.VidaUtilHoras <= 0) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.equipamento.TipoMedidor = Number.parseInt(this.equipamento.TipoMedidor.toString());

    if(this.equipamento.Id > 0)
      this.equipamentosService.putEquipamento(this.equipamento.Id, this.equipamento).subscribe(
        res => {
          this.toasty.show('Feito!', 'Equipamento alterado!', 'success');
          this.selecionarEquipamento();
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.equipamentosService.postEquipamento(this.equipamento).subscribe(
        res => {
          this.toasty.show('Feito!', 'Equipamento cadastrado!', 'success');
          this.carregando = false;
          this.listarEquipamentos();
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(grupooperacaoId: number, nome: string) {
    Swal.fire({
      title: 'Deletar '+ nome + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.equipamentosService.deleteEquipamento(grupooperacaoId).subscribe(
            res => {
              this.toasty.show('Feito!', 'Equipamento deletado!', 'success');
              this.listarEquipamentos();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarEquipamentos(){
    this.equipamentos = this.auxEquipamentos.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/equipamento']);
  }

  getGrupoEquipamento(id: number) : GrupoEquipamento {
    if (!this.grupoEquipamentos)
      return null;

    let grupoEquipamento = this.grupoEquipamentos.filter(x =>
      x.Id==id
    );

    return grupoEquipamento.length > 0 ? grupoEquipamento[0] : new GrupoEquipamento();
  }

  getBase64(event) {
    let that = this;
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      that.equipamento.IconeIrrigaPrimeBase64 = reader.result.toString();
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  prepararListaProcesso(selecionados: any){
    this.equipamento._processoPlanejamento = selecionados.map(x => x.value.Id);
    this.equipamento.ProcessoPlanejamento = selecionados.map(x => x.value.Id).join(",");
  }

}
