export class Checklist {
  Id: number;
  Descricao:string = "";
  TipoChecklist: number = 0;
  Intervalo: number = 0;
  TipoIntervalo: number = 0;
  Observacao:string = "";
}

export enum EnumTipoIntervalo {
  Horimetro = 1,
  Hodometro = 2,
  Dias = 3
}

export enum EnumTipoChecklist {
  Preventivo = 1,
  Preditivo = 2,
  PreUso = 3,
  Auditoria = 4,
  Inspecao = 5
}


export const TipoIntervalo = [
  {value: EnumTipoIntervalo.Horimetro, name: "Horímetro"},
  {value: EnumTipoIntervalo.Hodometro, name: "Hodômetro"},
  {value: EnumTipoIntervalo.Dias, name: "Dias"}
]

export const TipoChecklist = [
  {value: EnumTipoChecklist.Preventivo, name: "Preventivo"},
  {value: EnumTipoChecklist.Preditivo, name: "Preditivo"},
  {value: EnumTipoChecklist.PreUso, name: "Pré-Uso"},
  {value: EnumTipoChecklist.Auditoria, name: "Auditoria"},
  {value: EnumTipoChecklist.Inspecao, name: "Inspeção"},
]
