import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { auxResumo } from 'src/app/classes/cotacao/auxResumo';
import { CotacaoInclude } from 'src/app/classes/cotacao/CotacaoInclude';
import { cotacaoService } from 'src/app/services/cotacao/cotacaoService';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import Swal from 'sweetalert2';
import { DadosTabela } from 'src/app/classes/cotacao/DadosTabela';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Fornecedores } from 'src/app/classes/cotacao/Fornecedores';
import { ProdutosFornecedores } from 'src/app/classes/cotacao/ProdutosFornecedores';
import { UtilsService } from 'src/app/utils/utils.service';
import { Transacao } from 'src/app/classes/Transacao';
import { DestinoTransacaoService } from 'src/app/services/destino-transacao.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resumo-cotacao',
  templateUrl: './resumo-cotacao.component.html',
  styleUrls: ['./resumo-cotacao.component.scss']
})
export class ResumoCotacaoComponent implements OnInit {
  @ViewChild('ModalTrocaFornecedor', { static: false })
  ModalTrocaFornecedor: UiModalComponent;
  @ViewChild('ModalTransacaoDestino', { static: true }) modal

  novoFornecedorId: number = 0;
  fornecedorGanhadorId: number = 0;
  fornecedores: Fornecedores[] = [];
  transacoes: Transacao[] = [];
  transacaoDestino: number = 0;
  isSubmitTransacao = false;
  motivoTroca: string = "";
  produtosFornecedores: ProdutosFornecedores[] = [];
  idItem: number = 0;

  carregando: boolean = false;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;
  id: number = 0;
  indexs: number = 0;
  similares: boolean[] = [];
  cotacao: CotacaoInclude = new CotacaoInclude();
  tabela: DadosTabela = new DadosTabela();
  //Expansão
  Detalhe: number = 0;
  mostrarDetalhe: boolean = false;

  // tradução
  ERRO: string = 'Erro!';

  constructor(private CotacaoService: cotacaoService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private utils: UtilsService,
    private destinoTransacaoService: DestinoTransacaoService,
    private translate: TranslateService) {
        this.cotacao.auxResumo = new auxResumo();
        this.route.params.subscribe(res => {
          this.id = res.id;
        });
  }
  incremento() {
    this.indexs++;
  }
  ngOnInit() {
    this.carregar();
  }
  carregar() {
    this.carregando = true;
    this.configurarTraducao();
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.CotacaoService.getResultadoComTabela(this.id).subscribe(ret => {
      this.cotacao = ret;
      this.destinoTransacaoService.getDestinosPorMovimentoId(this.cotacao.MotivmentoOrigemId).subscribe(ret => {
        this.transacoes = ret;
      },
        err => {
          Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
      this.tabela = this.cotacao.DadosTabela[0];
      this.cotacao.ItensCotacao.forEach(item => {
        this.similares.push(true);
      });
      this.cotacao.Auxiliar.forEach(aux => {
        aux.MelhorCompra.forEach(cmp => {

          let prod: ProdutosFornecedores = {
            PrecoCotacaoItensId: cmp.PrecoCotacaoItensId,
            FornecedorId: aux.IdVencedor,
            CotacaoId: this.cotacao.Id,
          };

          this.produtosFornecedores.push(prod);
        });
      });
      this.carregando = false;
    },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
    this.carregando = false;
  }

  exibeItens(id: number) {
    this.Detalhe = id;
  }
  TrocaSimilar(i: number) {
    this.configurarTraducao();
    if (this.similares[i])
      this.similares[i] = false;
    else
      this.similares[i] = true;

    this.carregando = true;
    this.CotacaoService.getResultadoComTabelaSimilares(this.similares, this.id).subscribe(ret => {
      this.cotacao = ret;
      this.carregando = false;
    },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  carregarSlider(item: DadosTabela) {
    this.Detalhe = item.Id;
    this.tabela = item;
    this.carregando = true;
    this.mostrarDetalhe = true;
    this.carregando = false;
  }
  descarregarSlider() {
    this.mostrarDetalhe = false;
    this.Detalhe = 0;
  }
  onKeyDown(event) {
    if (event.keyCode === 27) {
      this.descarregarSlider();
    }
  }

  modalTransacaoDestino() {
    console.log("aqui");
    if (this.cotacao.Status != 2) {
      this.utils.handleWarning("Cotação não está em Análise");
      return;
    }
    if (!this.cotacao.Aprovado) {
      this.utils.handleWarning("Cotação não está aprovada");
      return;
    }

    this.modal.show();
  }

  enviar() {
    this.cotacao.TransacaoDestinoId = this.transacaoDestino;
    const transacao = this.transacoes.find(p => p.Id == this.cotacao.TransacaoDestinoId);
    if (this.transacaoDestino < 1) {
      this.utils.handleWarning("Selecione uma transação de destino");
      return;
    }
    this.CotacaoService.gerarMovimento(this.produtosFornecedores, this.cotacao.Id, this.transacaoDestino).subscribe(ret => {
      this.toasty.show('Feito!', 'Cotação Finalizada!', 'success'); //Alterar mensagem
      this.router.navigate(['/cotacao']);
    },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  incluirProduto(id: number, idFornecedor: number) {
    if (this.produtosFornecedores.filter(p => p.PrecoCotacaoItensId == id).length != 1) {
      let prod: ProdutosFornecedores = {
        PrecoCotacaoItensId: id,
        FornecedorId: idFornecedor,
        CotacaoId: this.cotacao.Id
      };

      this.produtosFornecedores.push(prod);
    }

    else this.produtosFornecedores = this.produtosFornecedores.filter(p => p.PrecoCotacaoItensId != id);
  }

  verificarInclusao(idItem: number): boolean {
    let retorno: boolean = false;
    this.produtosFornecedores.forEach(prod => {
      if (prod.PrecoCotacaoItensId == idItem) {
        retorno = true;
      }
    });
    return retorno;
  }

  abrirModalTrocaFornecedor(idFornecedor: number, idItem: number) {
    this.idItem = idItem;
    let i = 0;
    this.novoFornecedorId = null;
    this.motivoTroca = "";
    this.fornecedorGanhadorId = idFornecedor;
    this.cotacao.Auxiliar.forEach(item => {
      if (item.FornecedorGanhador.Id != idFornecedor)
        this.fornecedores.push(item.FornecedorGanhador);
    })
    this.produtosFornecedores.forEach(prod => {
      if (prod.PrecoCotacaoItensId == idItem) {
        this.novoFornecedorId = this.produtosFornecedores[i].FornecedorId;
        this.motivoTroca = this.produtosFornecedores[i].MotivoTrocaFornecedor;
      }
      i++;
    });
    this.ModalTrocaFornecedor.show();
  }

  fecharModalTrocaFornecedor() {
    this.ModalTrocaFornecedor.hide();
    this.fornecedores = new Array<Fornecedores>();
  }

  incluirMotivo() {
    this.produtosFornecedores.forEach(prod => {
      if (prod.PrecoCotacaoItensId == this.idItem) {
        prod.FornecedorId = this.novoFornecedorId;
        prod.MotivoTrocaFornecedor = this.motivoTroca;
        prod.FornecedorOriginalId = this.fornecedorGanhadorId;
      }
    });
    this.novoFornecedorId = null;
    this.motivoTroca = "";
    this.fecharModalTrocaFornecedor();
  }

  voltar() {
    this.router.navigate(['/cotacao']);
  }

  mapaCotacao() {
    this.CotacaoService.getMapaCotacaoNovaAba(this.id);
  }

  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
  }
}
