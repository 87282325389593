import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApontamentoIndicadores } from 'src/app/classes/ApontamentoIndicadores';
import { ApontamentoIndicadoresService } from 'src/app/services/ApontamentoIndicadores.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { FiltroRelatorio } from '../relatorios-componente/filtro-relatorios/filtro-relatorios.component';

@Component({
  selector: 'app-relatorio-apontamento-indicadores',
  templateUrl: './relatorio-apontamento-indicadores.component.html',
  styleUrls: ['./relatorio-apontamento-indicadores.component.scss']
})
export class RelatorioApontamentoIndicadoresComponent implements OnInit, OnChanges {
  @Input() filtro: FiltroRelatorio;
  apontamentoIndicadores: ApontamentoIndicadores[] = [];
  mediaPesoReal: number = 0;
  mediaApontada: number = 0;
  mediaCoeficiente: number = 0;
  mediaVariacao: number = 0;
  isSubmit: boolean = false;
  carregando: boolean;
  constructor(private apontamentoIndicadoresService: ApontamentoIndicadoresService,
    private utils: UtilsService) { }

  ngOnInit() {
    this.listar();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtro) {

      this.listar();

    }
  }
  listar() {
    this.carregando = true;
    if (!this.filtro.safraId)
      this.filtro.safraId = 0;
    if (!this.filtro.operadorId)
      this.filtro.operadorId = 0;
    if (!this.filtro.culturaId)
      this.filtro.culturaId = 0;
    if (!this.filtro.equipamentoId)
      this.filtro.equipamentoId = 0;
    if (!this.filtro.talhaoId)
      this.filtro.talhaoId = 0;
    this.apontamentoIndicadoresService.listarParaRelatorio(this.filtro)
      .subscribe(res => {
        this.apontamentoIndicadores = res;
        let somaReal = 0;
        let somaApontado = 0;
        let somaVariacao = 0;
        let somaCoeficiente = 0;
        let i = 0;
        this.apontamentoIndicadores.forEach(m => {
          somaReal += m.PesoReal;
          somaApontado += m.PesoApontado;
          somaVariacao += m.VariacaoPeso;
          somaCoeficiente += m.CoeficienteVariacaoDispersao;
          i++;
        })
        if (i > 0) {
          this.mediaPesoReal = somaReal / i;
          this.mediaApontada = somaApontado / i;
          this.mediaVariacao = somaVariacao / i;
          this.mediaCoeficiente = somaCoeficiente / i;
        }

        this.carregando = false;
      },
        err => {
          this.carregando = false;
          this.utils.getErro(err);
        });
  }
}
