import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Empresa } from 'src/app/classes/Empresa';
import { Usuario, enumPerfil } from 'src/app/classes/Usuario';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  perfis?: number[];
  docLink?: string;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

@Injectable()
export class NavigationItem {
  constructor(private storageService: StorageService) {
  }
  public getProvider() {
    var NavigationItems = [
        {
          id: 'other',
          title: this.storageService.getEmpresa() ? this.storageService.getEmpresa().NomeFantasia.toUpperCase() : 'Painel Fornecedor',
          type: 'group',
          icon: 'feather icon-align-left',
          children: [
            {
              id: 'mapa',
              title: 'MAPA',
              type: 'item',
              url: '/mapa',
              classes: 'nav-item',
              icon: 'feather icon-map',
              breadcrumbs: false,
            },
            {
              id: 'painel',
              title: 'PAINEL_GERENCIAL',
              type: 'item',
              url: '/painel',
              classes: 'nav-item',
              icon: 'feather icon-sidebar',
              breadcrumbs: false,
            },
            {
              id: 'chamado',
              title: 'CHAMADOS',
              type: 'item',
              url: '/chamado',
              classes: 'nav-item',
              icon: 'feather icon-list',
              breadcrumbs: false,
              perfis: [enumPerfil.Super, enumPerfil.Master]
            },

            {
              id: 'gerenciador',
              title: 'GERENCIADOR',
              type: 'collapse',
              icon: 'feather icon-sliders',
              perfis: [enumPerfil.Master],
              children: [
                {
                  id: 'empresa',
                  title: 'EMPRESAS',
                  type: 'item',
                  url: '/gerenciador/empresa'
                },
                {
                  id: 'cultura',
                  title: 'CULTURAS',
                  type: 'item',
                  url: '/gerenciador/cultura'
                },

                {
                  id: 'variedade',
                  title: 'VARIEDADES',
                  type: 'item',
                  url: '/cadastros/variedade',
                },
                {
                  id: 'tipo-bico',
                  title: 'TIPOS_DE_BICOS',
                  type: 'item',
                  url: '/gerenciador/tipo-bico'
                },
                {
                  id: 'ano-agricola',
                  title: 'ANOS_AGRICOLAS',
                  type: 'item',
                  url: '/gerenciador/ano-agricola'
                },
                {
                  id: 'parceiros',
                  title: 'Parceiros',
                  type: 'item',
                  url: '/cadastros/parceiros'
                },
                {
                  id: 'regionais',
                  title: 'Regionais',
                  type: 'item',
                  url: '/cadastros/regionais'
                },
                {
                  id: 'empresa-interna',
                  title: 'Empresas',
                  type: 'item',
                  url: '/cadastros/empresa-interna',
                  perfis: [enumPerfil.Super, enumPerfil.Admin],
                },
                {
                  id: 'pessoa',
                  title: 'Pessoas',
                  type: 'item',
                  url: '/cadastros/pessoa',
                },
                {
                  id: 'natureza',
                  title: 'Naturezas',
                  type: 'item',
                  url: '/cadastros/natureza',
                },
                {
                  id: 'centro-de-resultados',
                  title: 'Centro de Resultados',
                  type: 'item',
                  url: '/cadastros/centro-de-resultados',
                },
                {
                  id: 'projeto',
                  title: 'Projetos',
                  type: 'item',
                  url: '/cadastros/projeto',
                }
              ]
            },
            {
              id: 'cadastros',
              title: 'CADASTROS',
              type: 'collapse',
              icon: 'feather icon-edit',
              perfis: [enumPerfil.Super, enumPerfil.Admin, enumPerfil.Usuario, enumPerfil.Master],
              children: [
                {
                  id: 'agricolas',
                  title: 'Agrícolas',
                  type: 'collapse',
                  children: [
                    {
                      id: 'fazenda',
                      title: 'FAZENDAS_E_GLEBAS',
                      type: 'item',
                      url: '/cadastros/fazenda',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'variedades',
                      title: 'VARIEDADES',
                      type: 'item',
                      url: '/cadastros/variedade',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'processo-planejamento',
                      title: 'PROCESSOS_DE_PLANEJAMENTO',
                      type: 'item',
                      url: '/cadastros/processo-planejamento',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'grupo-operacao',
                      title: 'OPERACOES_AGRICOLAS',
                      type: 'item',
                      url: '/cadastros/grupo-operacao',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'tipo-aplicacao',
                      title: 'TIPOS_DE_APLICACOES',
                      type: 'item',
                      url: '/cadastros/tipo-aplicacao',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'grupo-equipamento',
                      title: 'GRUPOS_DE_EQUIPAMENTO',
                      type: 'item',
                      url: '/cadastros/grupo-equipamento',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'equipamento',
                      title: 'Equipamentos',
                      type: 'item',
                      url: '/cadastros/equipamento',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'tipo-improdutividade',
                      title: 'TIPOS_DE_PARADA',
                      type: 'item',
                      url: '/cadastros/tipo-improdutividade',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'escala-trabalho',
                      title: 'Escalas de Trabalho',
                      type: 'item',
                      url: '/cadastros/escala-trabalho',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'funcionario',
                      title: 'Funcionários',
                      type: 'item',
                      url: '/cadastros/funcionario',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                  ]
                },
                {
                  id: 'cadastro-estoque',
                  title: 'Suprimentos',
                  type: 'collapse',
                  children: [
                    {
                      id: 'local-estoque',
                      title: 'Locais de Estoques',
                      type: 'item',
                      url: '/estoques/local-estoque',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                    {
                      id: 'grupo-produto',
                      title: 'GRUPOS_DE_PRODUTOS',
                      type: 'item',
                      url: '/estoques/grupo-produto',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },

                    {
                      id: 'produto',
                      title: 'PRODUTOS',
                      type: 'item',
                      url: '/estoques/produto',
                      perfis: [enumPerfil.Super, enumPerfil.Admin],
                    },
                  ]
                },
              ]
            },
            {
              id: 'manutencao',
              title: 'NAV_MANUTENCAO',
              type: 'None',
              icon: 'feather icon-check-square',
              children: [
                {
                  id: 'ordem-servico',
                  title: 'ORDENS_DE_SERVICO',
                  type: 'item',
                  url: '/manutencao/ordem-servico',
                  perfis: [enumPerfil.Super, enumPerfil.Admin],
                }]
            },
            {
              id: 'planejamento',
              title: 'PLANEJAMENTOS',
              type: 'collapse',
              icon: 'feather icon-sliders',
              perfis: [enumPerfil.Super, enumPerfil.Admin, enumPerfil.Usuario, enumPerfil.Master],
              children: [
                {
                  id: 'plano-mestre-producao',
                  title: 'PLANO_AGRONOMICO',
                  type: 'item',
                  url: '/planejamento/plano-mestre-producao',
                  perfis: [enumPerfil.Super, enumPerfil.Admin],
                },
                {
                  id: 'safra',
                  title: 'SAFRAS',
                  type: 'item',
                  url: '/planejamento/safra',
                  perfis: [enumPerfil.Super, enumPerfil.Admin],
                },
                {
                  id: 'lista-tarefas',
                  title: 'TAREFAS',
                  type: 'item',
                  url: '/planejamento/tarefas',
                  perfis: [enumPerfil.Super, enumPerfil.Admin]
                },
              ]
            },
            {
              id: 'execucao',
              title: 'EXECUCAO',
              type: 'collapse',
              icon: 'feather icon-clipboard',
              perfis: [enumPerfil.Super, enumPerfil.Admin, enumPerfil.Usuario, enumPerfil.Master],
              children: [
                {
                  id: 'ordem-servico',
                  title: 'ORDENS_DE_SERVICO',
                  type: 'item',
                  url: '/ordem-servico/ordem-servico',
                  perfis: [enumPerfil.Super, enumPerfil.Admin, enumPerfil.Usuario],
                },
                {
                  id: 'apontamento-ordem-servico',
                  title: 'ATIVIDADES_MAQUINA',
                  type: 'item',
                  url: '/ordem-servico/agenda-atividade',
                  perfis: [enumPerfil.Super, enumPerfil.Admin, enumPerfil.Usuario],
                }
              ]
            },
            {
              id: 'movimento-estoque',
              title: 'Movimento de Estoque',
              type: 'item',
              icon: 'feather icon-package',
              url: '/movimento-estoque/sync',
              perfis: [enumPerfil.Super, enumPerfil.Admin, enumPerfil.Usuario],
            },
            {
              id: 'relatorios',
              title: 'Relatorios',
              type: 'item',
              url: '/relatorios',
              classes: 'nav-item',
              icon: 'feather icon-bar-chart',
              breadcrumbs: false
            },
            {
              id: 'configuracoes',
              title: 'CONFIGURACOES',
              type: 'collapse',
              icon: 'feather icon-settings',
              perfis: [enumPerfil.Super, enumPerfil.Admin],
              children: [
                {
                  id: 'parametrosEmpresa',
                  title: 'Empresa',
                  type: 'item',
                  url: '/cadastros/parametros/empresa'
                },
                {
                  id: 'usuario',
                  title: 'Usuários',
                  type: 'item',
                  url: '/cadastros/usuario',
                },
              ]
            },
          ]
        }
      ];

    return NavigationItems;
  }
  public getProviderFornecedor() {
    var NavigationItems = [
      {
        id: 'other',
        title: this.storageService.getEmpresa() ? this.storageService.getEmpresa().NomeFantasia.toUpperCase() : '',
        type: 'group',
        icon: 'feather icon-align-left',
        children: [
          {
            id: 'painel',
            title: 'Painel',
            type: 'item',
            url: '/painelfornecedor',
            classes: 'nav-item',
            icon: 'feather icon-sidebar',
            breadcrumbs: false
          },
        ]
      }
    ];

    return NavigationItems;
  }
}
