import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import cep from 'cep-promise';
import { Endereco, TipoEndereco } from 'src/app/classes/Endereco';
import { EnderecoService } from 'src/app/services/endereco.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inserir-endereco',
  templateUrl: './inserir-endereco.component.html',
  styleUrls: ['./inserir-endereco.component.scss']
})
export class InserirEnderecoComponent implements OnInit{

  @Input() endereco: Endereco;

  @Input() id: number = 0;
  @Input() empresaInternaId: number = 0;
  @Input() pessoaId: number = 0;
  @Input() principal: boolean = false;
  
  carregando: boolean = false;
  isSubmit: boolean;
  touched = false;

  tipoEndereco = TipoEndereco;

  @Output() enderecoCriadoEvento = new EventEmitter<any>();
  @Output() enderecoAtualizadoEvento = new EventEmitter<any>();
  @Output() enderecoCancelarEvento = new EventEmitter<any>();

  constructor(
    private enderecoService: EnderecoService,
    private toasty: CustomToastyService,
    ) {}

  ngOnInit() { 
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    this.endereco.EmpresaInternaId = this.empresaInternaId;
    this.endereco.PessoaId = this.pessoaId;

    if (this.endereco.Id > 0)
      this.enderecoService.Atualizar(this.endereco.Id, this.endereco).subscribe(
        res => {
          this.toasty.show('Feito!', 'Endereço  alterado!', 'success');
          this.carregando = false;
          this.enderecoAtualizadoEvento.emit();
        },
        err => {
          Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else{
      this.carregando = false;
      this.enderecoCriadoEvento.emit(this.endereco);
    }
  }

  cancelar() {
    this.enderecoCancelarEvento.emit();
  }

  buscaCep() {
    if(this.endereco.CEP.replace('-', '').length == 8)
    cep(this.endereco.CEP)
    .then(res => {
        this.carregando = true;
        this.endereco.UF = res.state;
        this.endereco.Cidade = res.city;
        this.endereco.Logradouro = res.street;
        this.endereco.Bairro = res.neighborhood;
        this.carregando = false;
      }
    ).catch();
  }

}
