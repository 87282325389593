import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Checklist } from 'src/app/classes/Checklist';
import { ChecklistEquipamento } from 'src/app/classes/ChecklistEquipamento';
import { ChecklistEquipamentoService } from 'src/app/services/checklist-equipamento.service';
import { ChecklistService } from 'src/app/services/checklist.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-checklist-equipamento',
  templateUrl: './checklist-equipamento.component.html',
  styleUrls: ['./checklist-equipamento.component.scss']
})
export class ChecklistEquipamentoComponent implements OnInit {

  @Input() equipamentoId: number = 0;

  checklistEquipamento: ChecklistEquipamento;
  listaChecklistEquipamento: ChecklistEquipamento[] = [];
  auxChecklistEquipamentos: ChecklistEquipamento[] = [];

  listaChecklist: Checklist[] = [];

  id: number = 0;

  inserindo: boolean = false;
  editando: boolean = false;
  textoBusca: string = '';
  
  carregando: boolean = false;
  isSubmit: boolean;

  // tradução
  ERRO: string = 'Erro';
  A_DATA_DA_ULTIA_EXECUCAO_OU_O_MEDIDOR_DEVE_SER_PREENCHIDO: string = 'A Data da últia execução ou o Medidor deve ser preenchido';
  CHECKLIST__ALTERADO: string = 'Checklist  alterado!';
  CHECKLIST_CADASTRADO: string = 'Checklist cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  CHECKLIST_EXCLUIDO: string = 'Checklist excluído!';

  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.A_DATA_DA_ULTIA_EXECUCAO_OU_O_MEDIDOR_DEVE_SER_PREENCHIDO = this.translate.instant('A_DATA_DA_ULTIA_EXECUCAO_OU_O_MEDIDOR_DEVE_SER_PREENCHIDO');
    this.CHECKLIST__ALTERADO = this.translate.instant('CHECKLIST__ALTERADO');
    this.CHECKLIST_CADASTRADO = this.translate.instant('CHECKLIST_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.CHECKLIST_EXCLUIDO = this.translate.instant('CHECKLIST_EXCLUIDO');
  }

  constructor(
    private checklistService: ChecklistService,
    private checklistEquipamentoService: ChecklistEquipamentoService,
    private utils: UtilsService,
    private translate: TranslateService,
    private router: Router,
    private toasty: CustomToastyService) {
    }

  ngOnInit() { 
    
    this.checklistEquipamento = new ChecklistEquipamento();

    this.listarChecklists();

      if(this.equipamentoId > 0)
        this.listar();
  }

  save(form: any) {
    debugger;
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    if(!this.checklistEquipamento.DataUltimaExecucao && !this.checklistEquipamento.MedidorUltimaExecucao) {
      Swal.fire(this.ERRO, this.A_DATA_DA_ULTIA_EXECUCAO_OU_O_MEDIDOR_DEVE_SER_PREENCHIDO, 'error');
      return;
    }
    this.checklistEquipamento.EquipamentoId = this.equipamentoId;
    this.carregando = true;

    if (this.checklistEquipamento.Id > 0) {
      this.checklistEquipamentoService.Atualizar(this.checklistEquipamento.Id, this.checklistEquipamento).subscribe(
        res => {
          this.utils.handleSuccess(this.CHECKLIST__ALTERADO);
          this.checklistEquipamento = new ChecklistEquipamento();
          this.listar();
          this.carregando = false;
          this.isSubmit = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else
      this.checklistEquipamentoService.Inserir(this.checklistEquipamento).subscribe(
        res => {
          this.utils.handleSuccess(this.CHECKLIST_CADASTRADO);
          this.listaChecklistEquipamento.push(res);
          this.checklistEquipamento = new ChecklistEquipamento();
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  selecionar() {
    this.carregando = true;
    this.checklistEquipamentoService.ObterPorId(this.id).subscribe(res =>{
      this.checklistEquipamento = res;
      this.carregando = false;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });

  }

  listar(){
    this.carregando = true;
    this.checklistEquipamentoService.Listar(this.equipamentoId).subscribe(
      res => {
        console.log(res);
        this.listaChecklistEquipamento = res;
        this.auxChecklistEquipamentos = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrar();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }



  excluir(id: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.checklistEquipamentoService.Excluir(id).subscribe(
            res => {
              this.utils.handleSuccess(this.CHECKLIST_EXCLUIDO);
              this.listar();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  editar(id: number) {
    this.id = id;
    this.selecionar();
  }

  filtrar(){
    this.listaChecklistEquipamento = this.auxChecklistEquipamentos.filter(x =>
      x.Id.toString().includes(this.textoBusca)
    );
  }

  cancelar(){
    this.router.navigate([`/ordem-servico/equipamento/${this.equipamentoId}`]);
  }

  listarChecklists(){
    this.checklistService.Listar().subscribe({
      next: (res) => {
          this.listaChecklist = res;
      },
      error: (err) => {
        this.utils.getErro(err);
      }
    });
  }

}
