import { Component, OnInit, ViewChild } from '@angular/core';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { Area } from 'src/app/classes/Area';
import { Cultura } from 'src/app/classes/Cultura';
import { Fazenda } from 'src/app/classes/Fazenda';
import { Safra } from 'src/app/classes/Safra';
import { SafraService } from 'src/app/services/safra.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustoRealizadoAreaComponent } from '../custo/custo-realizado-area/custo-realizado-area.component';
import { FiltroRelatorio } from './filtro-relatorios/filtro-relatorios.component';
import { RelatorioService } from 'src/app/services/relatorio.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/utils/utils.service';


@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss']
})
export class RelatoriosComponent implements OnInit {

  @ViewChild('custoRef', { static: false }) custoRealizadoAreaComponent: CustoRealizadoAreaComponent;

  area: Area;
  anoSafra: AnoSafra;
  safra: Safra;
  cultura: Cultura;
  fazenda: Fazenda;
  filtrarPeriodo = true;

  empresaId: number = 0;

  filtro: FiltroRelatorio = {
    fazendaId: null,
    areaId: null,
    anoSafraId: null,
    safraId: null,
    culturaId: null,
    dataInicio: null,
    dataFim: null,
    setorId: null,
    equipamentoId:null,
    talhaoId:null,
    operadorId:null,
    filtrarPeriodo: this.filtrarPeriodo
  };

  dataInicio: Date;
  dataFinal: Date;

  filtros = true;
  tipo: number = 1;

  anosSafra: AnoSafra[];
  areas: Area[];
  safras: Safra[];
  culturas: Cultura[];

  carregando: boolean = false;

  constructor(private safraService: SafraService,
              private storageService: StorageService,
              private relatorioService: RelatorioService,
              private translateService: TranslateService,
              private utils: UtilsService) { }

  ngOnInit() {
    this.fazenda = this.storageService.getFazenda();
    this.empresaId = this.storageService.getEmpresa().Id;
  }
  onTipoChange()
  {
    if(this.tipo == 6)
      this.filtrarPeriodo=true;
  }
  onFiltrarEvento(filtro: FiltroRelatorio) {
    this.filtro = filtro;

    if(filtro.safraId > 0){
      this.selecionarSafra(filtro.safraId);
    }
  }

  selecionarSafra(safraId: number) {
    this.safraService.getSafra(safraId).subscribe(ret => {
      this.safra = ret;
    });
  }

  GerarRelatorio() {
    this.carregando = true;
    let result: Observable<any>;

    if (this.tipo == 2) {
      result = this.relatorioService.getR_HistoricoAplicacao(this.filtro);
    }
    else if (this.tipo == 3) {
      result = this.relatorioService.getR_FimSafra(this.filtro);
    }
    else if (this.tipo == 4) {
      result = this.relatorioService.getR_InsumosSafraArea(this.filtro);
    }
    else if (this.tipo == 5) {
      result = this.relatorioService.getCustoRealizadoInsumos(this.filtro);
    }
    else if (this.tipo == 6) {
      result = this.relatorioService.getApontamentoIndicadores(this.filtro);
    }

    result.subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      this.carregando = false;
    }, err => {
      this.utils.getErro(err)
      this.carregando = false;
    });
  }

  relatoriosDescricao(id: number) {
    const descricaoMap = {
      "custo": "CUSTO",
      "Histórico de Aplicações da Safra": "HISTORICO_DE_APLICACOES_DA_SAFRA",
      "Resumo de Safra": "RESUMO_DE_SAFRA",
      "Aplicação de Insumos por Safra e Area": "APLICACAO_DE_INSUMOS_POR_SAFRA_E_AREA",
      "Custo Realizado de Insumos": "CUSTO_REALIZADO_DE_INSUMOS",
      "Apontamentos de Indicadores": "APONTAMENTOS_DE_INDICADORES",
      "Os Planejadas x Realizadas": "OS_PLANEJADAS_X_REALIZADAS"
    };

    const descricao = this.relatorios.find(r => r.Id == id).Descricao;

    return this.translateService.instant(descricaoMap[descricao]);
  }

  relatorios = [
    {
      'Id': 1,
      'Descricao': 'Custo',
      'Filtros': { 'AnoSafra': true, 'Safra': true, 'Cultura': false, 'Setor': true, 'Area': true, 'DataInicio': true, 'DataFim': true, 'Equipamento':false, 'Talhao':false, 'Operador':false }
    },
    {
      'Id': 2,
      'Descricao': 'Histórico de Aplicações da Safra',
      'Filtros': { 'AnoSafra': false, 'Safra': true, 'Cultura': false, 'Setor': false, 'Area': false, 'DataInicio': true, 'DataFim': true, 'Equipamento':false, 'Talhao':false, 'Operador':false }
    },
    {
      'Id': 3,
      'Descricao': 'Resumo de Safra',
      'Filtros': { 'AnoSafra': false, 'Safra': true, 'Cultura': false, 'Setor': false, 'Area': false, 'DataInicio': true, 'DataFim': true, 'Equipamento':false, 'Talhao':false, 'Operador':false }
    },
    {
      'Id': 4,
      'Descricao': 'Aplicação de Insumos por Safra e Area',
      'Filtros': { 'AnoSafra': false, 'Safra': true, 'Cultura': false, 'Setor': true, 'Area': true, 'DataInicio': true, 'DataFim': true, 'Equipamento':false, 'Talhao':false, 'Operador':false}
    },
    {
      'Id': 5,
      'Descricao': 'Custo Realizado de Insumos',
      'Filtros': { 'AnoSafra': true, 'Safra': true, 'Cultura': true, 'Setor': true, 'Area': true, 'DataInicio': true, 'DataFim': true, 'Equipamento':false, 'Talhao':false, 'Operador':false }
    },
    {
      'Id': 6,
      'Descricao': 'Apontamentos de Indicadores',
      'Filtros': { 'AnoSafra': false, 'Safra': true, 'Cultura': true, 'Setor': false, 'Area': false, 'DataInicio': true, 'DataFim': true, 'Equipamento':true, 'Talhao':true, 'Operador':true }
    },
    {
      'Id': 7,
      'Descricao': 'Os Planejadas x Realizadas',
      'Filtros': { 'AnoSafra': false, 'Safra': false, 'Cultura': false, 'Setor': false, 'Area': false, 'DataInicio': false, 'DataFim': false, 'Equipamento':false, 'Talhao':false, 'Operador':false }
    },
  ];

}
