import { RelatorioAutomaticoListaUsuarios } from "./RelatorioAutomaticoListaUsuarios";

export class RelatorioAutomatico{
    Id: number;
    EmpresaId: number;
    Periodo: number = 0;
    DiaEnvioMensal: number = 0;
    UltimoEnvio: Date = new Date();
    ListaUsuarios: RelatorioAutomaticoListaUsuarios[]=[];
    getPeriodo:string = "";
    GetModelo:string = "";
    Modelo:number = 0;
}
