import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LocalEstoque, enumTipoEstoque } from 'src/app/classes/LocalEstoque';
import { EnumStatusSafra, Safra } from 'src/app/classes/Safra';
import { Usuario } from 'src/app/classes/Usuario';
import { Abastecimento } from 'src/app/classes/gestao-lite/Abastecimento';
import { AbastecimentoService } from 'src/app/services/abastecimento.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { SafraService } from 'src/app/services/safra.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { Subject } from 'rxjs';
import { Equipamento } from 'src/app/classes/Equipamento';
import { ProdutoService } from 'src/app/services/produto.service';
import { ApontamentoFrotaService } from 'src/app/services/apontamento-frota.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-abastecimento',
  templateUrl: './abastecimento.component.html',
  styleUrls: ['./abastecimento.component.scss']
})
export class AbastecimentoComponent implements OnInit {
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  @Output() fechou: EventEmitter<any> = new EventEmitter();

  @Input() id: number = 0;
  @Input() equipamentoId: number = 0;

  abastecimento: Abastecimento = new Abastecimento();
  safras: Safra[] = [];
  locaisEstoque: LocalEstoque[] = [];
  usuarios: Usuario[] = [];
  carregando: boolean = false;
  isSubmit: boolean = false;
  ultimoMedidor: number = 0;
  variacaoMedidor: number = 0;
  equipamento: Equipamento;
  
  horaAbastecimento: Date;  

  //Select
  public inputResponsavel$ = new Subject<string | null>();

  // tradução
ABASTECIMENTO_ALTERADO_COM_SUCESSO: string = 'Abastecimento alterado com sucesso!';
ABASTECIMENTO_INSERIDO_COM_SUCESSO: string = 'Abastecimento inserido com sucesso!';
LIMITE_DIARIO_NAO_PODE_SER_SUPERIOR_A_: string = 'Limite diário não pode ser superior a ';
LIMITE_DIARIO_NAO_PODE_SER_SUPERIOR_A_H: string = 'Limite diário não pode ser superior a 24h';

  constructor(private abastecimentoService: AbastecimentoService,
    private safraService: SafraService,
    private localEstoqueService: LocalEstoqueService,
    private usuarioService: UsuarioService,
    private utils: UtilsService,
    private equipamentoService: EquipamentoService,
    private produtoService: ProdutoService,
    private apontamentoFrotaService: ApontamentoFrotaService,
    private translate: TranslateService) { }

  ngOnInit() {

    

    if (!this.id){
      this.abastecimento.DataAbastecimento = new Date();
      if(this.equipamentoId){
        this.abastecimento.EquipamentoId = this.equipamentoId;
        this.selecionarEquipamento(this.equipamentoId);
      }
      
      this.horaAbastecimento = this.abastecimento.DataAbastecimento.toString().substring(11, 19) as any;
    }

    this.safraService.getSafras().subscribe((data) => {
      this.safras = data;
      this.safras = this.safras.filter(x => x.Status == EnumStatusSafra.EmAndamento);
    },
      (error) => {
        this.carregando = false;
        this.utils.getErro(error);
      });

    this.localEstoqueService.getLocaisEstoque().subscribe((data) => {
      this.locaisEstoque = data;
      this.locaisEstoque = this.locaisEstoque.filter(x => !x.TipoEstoque || x.TipoEstoque == enumTipoEstoque.Almoxarifado);
    });

    this.usuarioService.getUsuarios().subscribe((data) => {
      this.usuarios = data;
    });

    if (this.id) {
      this.abastecimentoService.getAbastecimento(this.id).subscribe((data) => {
        this.abastecimento = data;
        this.horaAbastecimento =  this.abastecimento.DataAbastecimento.toString().substring(11, 19) as any;
        console.log("ABASTECIMENTO +> ", this.abastecimento.DataAbastecimento.toString().substring(11, 19));
        this.selecionarEquipamento(this.abastecimento.EquipamentoId);
      });
    }

  }

  ngAfterViewInit() {
    this.Modal.show();    
  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

  selecionarEquipamento(equipamentoId: number) {   
    this.abastecimento.EquipamentoId = equipamentoId;
    this.equipamentoService.getEquipamento(equipamentoId).subscribe(ret => {
      this.equipamento = ret;
      this.apontamentoFrotaService.GetUltimoMedidor(equipamentoId).subscribe(ret => {
        this.ultimoMedidor = ret;
        /*this.abastecimentoService.getUltimoAberto(equipamentoId).subscribe(aberto => {
          if (aberto) {
            this.abastecimento = aberto;
          }
        });*/
      });
    },
      (error) => {
        this.carregando = false;
        this.utils.getErro(error);        
      });
  }

  selecionarProduto(produtoId: number) {
    if(!produtoId)
      return;
    this.abastecimento.ProdutoId = produtoId;
    this.abastecimento.Quantidade = 1;
    this.produtoService.getProduto(produtoId).subscribe(ret => {
      this.abastecimento.ValorUnt = ret.PrecoUnitario;
      this.abastecimento.Total = ret.PrecoUnitario;
    });
  }

  calcularVariacaoMedidor() {
    this.variacaoMedidor = this.abastecimento.Medidor - this.ultimoMedidor;
  }

  calcularValorTotal() {
    if (this.abastecimento.Quantidade == 0)
      this.abastecimento.Quantidade = 1;

    this.abastecimento.Total = this.abastecimento.ValorUnt * this.abastecimento.Quantidade;
  }

  salvarAbastecimento(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
      this.configurarTraducao();
    //juntar data e hora
    let data = new Date(this.abastecimento.DataAbastecimento);
    let hora = new Date("1900-04-01T" + this.horaAbastecimento.toString());
    
    data.setUTCHours(hora.getHours());
    data.setUTCMinutes(hora.getMinutes());
    data.setUTCSeconds(hora.getSeconds());
    this.abastecimento.DataAbastecimento = data;
    
    this.carregando = true;
    if(!this.abastecimento.Id)
      this.abastecimentoService.realizarAbastecimento(this.abastecimento).subscribe((data) => {
        this.carregando = false;
        this.utils.handleSuccess(this.ABASTECIMENTO_INSERIDO_COM_SUCESSO);
        this.selecionarEquipamento(this.abastecimento.EquipamentoId);
        this.fechar();
      },
      (error) => {
        this.carregando = false;
        this.utils.getErro(error);
      });
    else
      this.abastecimentoService.putAbastecimento(this.abastecimento.Id, this.abastecimento).subscribe((data) => {
        this.carregando = false;
        this.utils.handleSuccess(this.ABASTECIMENTO_ALTERADO_COM_SUCESSO);
        this.selecionarEquipamento(this.abastecimento.EquipamentoId);
        this.fechar();
      },
        (error) => {
          this.carregando = false;
          this.utils.getErro(error);
        });
  }

  changeResponsavel(id: number) {
  }
  configurarTraducao() {
    this.ABASTECIMENTO_ALTERADO_COM_SUCESSO = this.translate.instant('ABASTECIMENTO_ALTERADO_COM_SUCESSO');
    this.ABASTECIMENTO_INSERIDO_COM_SUCESSO = this.translate.instant('ABASTECIMENTO_INSERIDO_COM_SUCESSO');
    this.LIMITE_DIARIO_NAO_PODE_SER_SUPERIOR_A_ = this.translate.instant('LIMITE_DIARIO_NAO_PODE_SER_SUPERIOR_A_');
    this.LIMITE_DIARIO_NAO_PODE_SER_SUPERIOR_A_H = this.translate.instant('LIMITE_DIARIO_NAO_PODE_SER_SUPERIOR_A_H');
}

}
