import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cultura } from '../classes/Cultura';

@Injectable({
  providedIn: 'root'
})
export class CulturaService {

  public culturaSelecionada: Cultura;

  constructor(private http: HttpClient) { }

  getCulturas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/cultura`)
  }

  getCultura(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/cultura/${id}`)
  }

  postCultura(cultura: Cultura): Observable<any>{
    return this.http.post(`${environment.apiURL}/cultura`, cultura);
  }

  putCultura(id: number, cultura: Cultura): Observable<any>{
    return this.http.put(`${environment.apiURL}/cultura/${id}`, cultura);
  }

  deleteCultura(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/cultura/${id}`);
  }

  getCulturasPorSafras(safras: number[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/cultura/ListarPorSafra`, safras)
  }

  getCulturasPlanejadas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/cultura/ListarPlanejada`)
  }
}
