import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { PendenciaFrota } from '../classes/PendenciaFrota';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PendenciaFrotaService {

  constructor(
    private http: HttpClient,
    private utils: UtilsService
    ) { }

  route = "PendenciaFrota";

  Listar(): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}`)
  }
  GetPorEquipamento(equipamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}/Equipamento/${equipamentoId}`)
  }

  ObterPorId(id:string): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}/${id}`)
  }

  Inserir(obj: PendenciaFrota): Observable<any>{
    return this.http.post(`${environment.apiURL}/${this.route}`, obj);
  }

  finalizar(obj: any): Observable<any>{
    return this.http.post(`${environment.apiURL}/${this.route}/Finalizar`, obj);
  }

  Atualizar(id: string, obj: PendenciaFrota): Observable<any>{
    return this.http.put(`${environment.apiURL}/${this.route}/${id}`, obj);
  }

  Excluir(id: string): Observable<any>{
    return this.http.delete(`${environment.apiURL}/${this.route}/${id}`);
  }

  getAnexo(arquivo: string) {
    return this.http.get(`${environment.apiURL}/${this.route}/DownloadAnexo/` + arquivo, { responseType: 'blob' })
    .subscribe(data => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = arquivo;
      document.body.appendChild(a);
      a.click();
    }, err => {
      this.utils.getErro(err)
    });
  }

  deleteAnexo(anexoId: string): Observable<any> {
    return this.http.delete(`${environment.apiURL}/${this.route}/ExcluirAnexo/${anexoId}`);
  }

}
