import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApontamentoIndicadores } from 'src/app/classes/ApontamentoIndicadores';
import { OrdemServico } from 'src/app/classes/OrdemServico';
import { ApontamentoIndicadoresService } from 'src/app/services/ApontamentoIndicadores.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-apontamento-indicadores',
  templateUrl: './apontamento-indicadores.component.html',
  styleUrls: ['./apontamento-indicadores.component.scss']
})
export class ApontamentoIndicadoresComponent implements OnInit, OnChanges {
  @Input() ordemServico: OrdemServico;
  @Input() apontamentoIndicadores: ApontamentoIndicadores = new ApontamentoIndicadores();
  @Input() inserindo: boolean;
  @Output() fechou: EventEmitter<any> = new EventEmitter();
  apontamentosIndicadores: ApontamentoIndicadores[] = [];

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  isSubmit: boolean = false;
  carregando: boolean;
  constructor(private apontamentoIndicadoresService: ApontamentoIndicadoresService,
    private translate: TranslateService,
    private utils: UtilsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.ordemServico){
      if(this.ordemServico.Id)
        this.atualizarApontamentos();
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.atualizarApontamentos();
    }, 2000);
  }


  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

  changePeso() {
    if (this.apontamentoIndicadores.PesoApontado > this.apontamentoIndicadores.PesoReal)
      this.apontamentoIndicadores.VariacaoPeso = this.apontamentoIndicadores.PesoApontado - this.apontamentoIndicadores.PesoReal;
    else
      this.apontamentoIndicadores.VariacaoPeso = this.apontamentoIndicadores.PesoReal - this.apontamentoIndicadores.PesoApontado;
  }

  salvar(form: any, concluir?: boolean) {
    if (!form.valid ||
      !this.apontamentoIndicadores.Data) {
      this.isSubmit = true;
      return;
    }

    this.carregando = true;
    if (this.apontamentoIndicadores.Id > 0)
      this.apontamentoIndicadoresService.put(this.apontamentoIndicadores.Id, this.apontamentoIndicadores).subscribe(
        res => {
          this.carregando = false;
          this.isSubmit = false;
          this.atualizarApontamentos();
          this.fechar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.apontamentoIndicadoresService.post(this.apontamentoIndicadores).subscribe(
        res => {
          this.carregando = false;
          this.isSubmit = false;
          this.atualizarApontamentos();
          this.fechar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
  }

  public novoApontamentoIndicadores() {
    this.apontamentoIndicadores = new ApontamentoIndicadores();
    this.apontamentoIndicadores.PhCalda = this.ordemServico.PhCalda;
    this.apontamentoIndicadores.Temperatura = this.ordemServico.Temperatura;
    this.apontamentoIndicadores.Umidade = this.ordemServico.Umidade;
    this.apontamentoIndicadores.VelocidadeVento = this.ordemServico.VelocidadeVento;
    this.apontamentoIndicadores.OrdemServicoId = this.ordemServico.Id;
    this.Modal.show();
  }

  abrirApontamentoIndicador(apontamento) {
    this.apontamentoIndicadores = apontamento;
    this.Modal.show();
  }

  excluirApontamentoIndicadores(apontamento: ApontamentoIndicadores) {
    Swal.fire({
      title: this.translate.instant('DELETAR_O_APONTAMENTO') + ' ' + apontamento.Id + '?',
      text: this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA'),
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('CANCELAR'),
      confirmButtonText: this.translate.instant('SIM'),
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.carregando = true;
        this.apontamentoIndicadoresService.delete(apontamento.Id).subscribe(
          res => {
            this.apontamentosIndicadores.splice(this.apontamentosIndicadores.indexOf(apontamento), 1);
            this.utils.handleSuccess(this.translate.instant('APONTAMENTO_DELETADO'));
            this.carregando = false;
            this.atualizarApontamentos();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  atualizarApontamentos() {
    this.apontamentoIndicadoresService.listarPorOS(this.ordemServico.Id).subscribe(res => {
      this.apontamentosIndicadores = res;
      this.carregando = false;
    });
  }

}
