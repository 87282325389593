import { ItemEnum } from "./ItemEnum"

export class GrupoTransacao {
  Id: number;
  Descricao: string = "";
  Modulo: EnumModulo
  TipoMovimento: EnumTipoMovimento
  GetTipoMovimento: string = "";
}
export enum EnumModulo {
  Compras = 1,
  Vendas = 2,
  Financeiro = 3,
  Estoque = 4
}
export const ListaEnumModulo: ItemEnum[] = [
  { name: 'Compras', value: EnumModulo.Compras },
  { name: 'Vendas', value: EnumModulo.Vendas },
  { name: 'Financeiro', value: EnumModulo.Financeiro },
  { name: 'Estoque', value: EnumModulo.Estoque },
]

export enum EnumTipoMovimento {
  SolicitacaoCompras = 1,
  Cotacao = 2,
  PedidoCompras = 3,
  Pagamento = 4,
  NotaEntrada = 5,
  NotaSaida = 6,
  Devolucao = 7,
  TransferenciaEstoque = 8,
  BaixaEstoque = 9,
  Producao = 10,
  PedidoVendas = 11,
  Financeiro = 12,
  DevolucaoVendas = 13,
  MovimentacaoEstoque = 14,
  DevolucaoMovimentacaoEstoque = 15,
  Inventario = 16,
  MovimentoBancario = 17,
  Manutencao = 18,
  Abastecimento = 19
}
export const ListaEnumTipoMovimento: ItemEnum[] = [
  { name: 'Solicitação de Compras', value: EnumTipoMovimento.SolicitacaoCompras },
  { name: 'Cotação', value: EnumTipoMovimento.Cotacao },
  { name: 'Pedido de Compras', value: EnumTipoMovimento.PedidoCompras },
  { name: 'Pagamento', value: EnumTipoMovimento.Pagamento },
  { name: 'Nota de Entrada', value: EnumTipoMovimento.NotaEntrada },
  { name: 'Nota de Saída', value: EnumTipoMovimento.NotaSaida },
  { name: 'Devolução', value: EnumTipoMovimento.Devolucao },
  { name: 'Transferência de Estoque', value: EnumTipoMovimento.TransferenciaEstoque },
  { name: 'Baixa de Estoque', value: EnumTipoMovimento.BaixaEstoque },
  { name: 'Produção', value: EnumTipoMovimento.Producao },
  { name: 'Pedido de Vendas', value: EnumTipoMovimento.PedidoVendas },
  { name: 'Financeiro', value: EnumTipoMovimento.Financeiro },
  { name: 'Devolução de Vendas', value: EnumTipoMovimento.DevolucaoVendas },
  { name: 'Movimentação de Estoque', value: EnumTipoMovimento.MovimentacaoEstoque },
  { name: 'Devolução de Movimentação de Estoque', value: EnumTipoMovimento.DevolucaoMovimentacaoEstoque },
  { name: 'Inventario', value: EnumTipoMovimento.Inventario },
  { name: 'Movimento Bancário', value: EnumTipoMovimento.MovimentoBancario },
  { name: 'Manutenção', value: EnumTipoMovimento.Manutencao },
  { name: 'Abastecimento', value: EnumTipoMovimento.Abastecimento },
]
