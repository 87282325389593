import { ImprodutividadeEquipamento } from './ImprodutividadeEquipamento';
import { ItemApontamento } from './ItemApontamento';
import { EquipamentoApontamento } from './EquipamentoApontamento';
export class Apontamento {
    Id: number;
    OrdemServicoId: number;
    Data: Date = new Date();
    FuncionarioId: number;
    Formulario: String = "";
    Observacao: String = "";
    RealizarSincroniaERP:boolean = true;

    //auxiliares do salvar
    EquipamentoApontamento: EquipamentoApontamento;
    ItensApontamento: ItemApontamento[];
    ImprodutividadesEquipamento: ImprodutividadeEquipamento[];
    LocalEstoqueId:number;
    IdMovimentoErp:string = "";

    sincronizando: boolean = false;
}
