import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Indicador } from 'src/app/classes/indicadores/Indicador';
import { LocalUsoIndicador } from 'src/app/classes/indicadores/LocalUsoIndicador';
import { NovoIndicador } from 'src/app/classes/indicadores/NovoIndicador';
import { TipoIndicador } from 'src/app/classes/indicadores/TipoIndicador';
import { TiposDiagnose } from 'src/app/classes/tipos-diagnose/TiposDiagnose';
import { IndicadoresService } from 'src/app/services/Indicadores/indicadores.service';
import { StorageService } from 'src/app/services/storage.service';
import { TiposDiagnoseService } from 'src/app/services/tipos-diagnose/tipos-diagnose.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-indicadores-form',
  templateUrl: './indicadores-form.component.html',
  styleUrls: ['./indicadores-form.component.scss']
})
export class IndicadoresFormComponent implements OnInit {

  form: FormGroup;
  id: string = null;
  inserindo: boolean = true;
  submetido: boolean = false;
  mostrarOpcoesLista: boolean = false;
  mostrarTiposDiagnose: boolean = false;

  listaTiposDiagnose: TiposDiagnose[] = [];
  tipos = this.enumParaObject(TipoIndicador);
  localUso = this.enumParaObject(LocalUsoIndicador);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private indicadoresService: IndicadoresService,
    private tiposDiagnoseService: TiposDiagnoseService,
    private utils: UtilsService,
    private storageService: StorageService
  ) {
    this.route.params.subscribe(res => this.id = res.id);
  }

  ngOnInit() {
    this.criarForm(new NovoIndicador());
    this.setarIndicadores();
  }

  get formControl() { return this.form.controls }

  alterarTipo() {
    this.mostrarOpcoesLista = this.form.value.Tipo == TipoIndicador.Lista;
    if (!this.mostrarOpcoesLista) {
      this.form.patchValue({ OpcoesLista: null });
    }
  }

  alterarLocalUso() {
    this.mostrarTiposDiagnose = this.form.value.LocalUso == LocalUsoIndicador.Diagnose;
    if (this.mostrarTiposDiagnose) {
      this.obterListaTiposDiagnose();
    } else {
      this.form.patchValue({ TipoDiagnoseId: null });
    }
  }

  onSubmit() {
    this.submetido = true;
    if (!this.form.valid) return;

    let result: Observable<Indicador | null> = null;

    if (this.form.value.LocalUso == LocalUsoIndicador.Diagnose && this.form.value.TipoDiagnoseId == null) {
      this.utils.handleErro('Selecione um Tipos Diagnose');
      return;
    }

    if (this.form.value.Tipo == TipoIndicador.Lista && this.form.value.OpcoesLista == null) {
      this.utils.handleError('Adicione opções separadas por ; no Opções Lista');
      return;
    }

    this.form.value.EmpresaId = this.storageService.getUsuario().EmpresaId;

    if (this.inserindo) {
      result = this.indicadoresService.Inserir(this.form.value);
    } else {
      this.form.value.Id = this.id;
      result = this.indicadoresService.Atualizar(this.form.value, this.id);
    }

    result.subscribe(
      res => {
        this.utils.handleSuccess('Indicador salvo com sucesso!');
        this.router.navigate(['/cadastros/indicadores'])
      },
      error => this.utils.getErro(error)
    )
  }

  cancelar() {
    this.router.navigate(['/cadastros/indicadores']);
  }

  private obterListaTiposDiagnose() {
    this.tiposDiagnoseService.Listar().subscribe(
      res => this.listaTiposDiagnose = res,
      error => this.utils.getErro(error)
    );
  }

  private setarIndicadores() {
    if (this.router.url.search('inserir') == -1) {
      this.inserindo = false;
      this.indicadoresService.ObterPorId(this.id).subscribe(
        res => {
          this.atualizarForm(res);
          this.alterarTipo();
          this.alterarLocalUso();
        },
        error => this.utils.getErro(error)
      )
    }
  }

  private criarForm(indicador: NovoIndicador) {
    this.form = this.formBuilder.group({
      Descricao: [indicador.Descricao, [Validators.required]],
      Tipo: [indicador.Tipo, [Validators.required]],
      LocalUso: [indicador.LocalUso, [Validators.required]],
      OpcoesLista: [indicador.OpcoesLista],
      TipoDiagnoseId: [indicador.TipoDiagnoseId]
    });
  }

  private atualizarForm(indicador: Indicador) {
    this.form.patchValue({
      Id: indicador.Id,
      Descricao: indicador.Descricao,
      Tipo: indicador.Tipo,
      LocalUso: indicador.LocalUso,
      OpcoesLista: indicador.OpcoesLista,
      TipoDiagnoseId: indicador.TipoDiagnoseId
    } as Indicador)
  }

  private enumParaObject(input: any): { Id: string, Value: string }[] {
    return Object
      .keys(input)
      .map(key => ({ Id: key, Value: input[key] }))
  }
}
