import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { EnumModulo } from 'src/app/classes/GrupoTransacao';
import { ItemMovimento } from 'src/app/classes/ItemMovimento';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { EnumStatusAprovacao, EnumStatusMovimento, ListaStatusMovimento, Movimento } from 'src/app/classes/Movimento';
import { Natureza } from 'src/app/classes/Natureza';
import { Produto } from 'src/app/classes/Produto';
import { Projeto } from 'src/app/classes/Projeto';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { CentroDeResultadosService } from 'src/app/services/centro-de-resultados.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { MovimentoService } from 'src/app/services/movimento.service';
import { NaturezaService } from 'src/app/services/natureza.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { ProjetoService } from 'src/app/services/projeto.service';
import { StorageService } from 'src/app/services/storage.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-movimentacao-produto',
  templateUrl: './movimentacao-produto.component.html',
  styleUrls: ['./movimentacao-produto.component.scss']
})
export class MovimentacaoProdutoComponent implements OnInit {

  modulo = EnumModulo.Compras;
  filtro = new FiltroTela();
  listaStatus = ListaStatusMovimento;
  produtos: Produto[] = [];
  gruposProduto: GrupoProduto[] = [];
  @Input() produtoId: number = 0;
  acoes = [
    'Editar',
  ]
  acoesItem = [
    'Editar',
    'Novo item Opcional'
  ]
  movimentos: Movimento[];
  colDefs: ColDef[] = [];
  colDefsItems: ColDef[] = [];
  minHeight = 0;
  auxItems: ItemMovimento[] = [];


  movimentoSelecionadoId: number = null;
  movimentoSelecionado: Movimento = new Movimento();
  movimentosSelecionados: Movimento[] = [];

  centrosResultados: CentroDeResultados[] = [];
  locaisEstoque: LocalEstoque[] = [];
  naturezas: Natureza[] = [];
  projetos: Projeto[] = [];
  transacoes: Transacao[] = [];
  usuario: Usuario;

  comparadorDeData = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      //using moment js
      var dateAsString = moment(cellValue).format('DD/MM/YYYY');
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

      if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
        return 0
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    }
  }

  constructor(private centroDeResultadoService: CentroDeResultadosService,
    private projetoService: ProjetoService,
    private naturezaService: NaturezaService,
    private produtoService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private localEstoqueService: LocalEstoqueService,
    private utils: UtilsService,
    private router: Router,
    private storageService: StorageService,
    private transacaoService: TransacaoService,
    private movimentoService: MovimentoService,) { }

  ngOnInit() {

    this.filtro.ProdutoId = this.produtoId;

    this.configurar();
    this.listarTransacoes();
    this.listarMovimentos();
    this.listarCentroResultados();
    this.listarProdutos();
    this.listarProjetos();
    this.listarNaturezas();
    this.listarLocalEstoque();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Cod Transação', field: 'TransacaoId', width: 90 },
      { headerName: 'Transação', field: 'GetTransacao' },
      { headerName: 'Cod Empresa', field: 'EmpresaInternaId', width: 90 },
      { headerName: 'Empresa', field: 'GetEmpresaInterna' },
      { headerName: 'Usuário Cadastro', field: 'GetUsuarioCadastro' },
      { headerName: 'Data Criação', field: 'DataCadastro', width: 120 , filter: 'agDateColumnFilter', filterParams: this.comparadorDeData, valueFormatter: (params) => this.utils.formatarData(params.data.DataCadastro) },
      { headerName: 'Data Emissão', field: 'DataEmissao', width: 120 , filter: 'agDateColumnFilter', filterParams: this.comparadorDeData, valueFormatter: (params) => this.utils.formatarData(params.data.DataEmissao) },
      { field: 'ValorBruto', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => 'R$ ' + this.formatarDecimais(params.data.ValorBruto) },
      { headerName: 'Valor Líquido', cellStyle: { 'text-align': 'right' }, field: 'ValorLiquido', valueFormatter: (params) => 'R$ ' + this.formatarDecimais(params.data.ValorLiquido) },
    ];
    this.colDefsItems = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Produto Similar', field: 'GetItemPaiDescricao', flex: 1 },
      { headerName: 'Produto', field: 'ProdutoId', flex: 1, valueFormatter: (params) => this.produtoDesc(params.data.ProdutoId) },
      { headerName: 'Qtd Pedida', cellStyle: { 'text-align': 'right' }, field: 'QuantidadePedida', flex: 1, valueFormatter: (params) => `${this.formatarDecimais(params.data.QuantidadePedida)}` },
      { headerName: 'Qtd Recebido', cellStyle: { 'text-align': 'right' }, field: 'QuantidadeRecebida', flex: 1, valueFormatter: (params) => `${this.formatarDecimais(params.data.QuantidadeRecebida)}` },
      { headerName: 'Qtd Pendente', cellStyle: { 'text-align': 'right' }, field: 'QuantidadePendente', flex: 1, valueFormatter: (params) => `${this.formatarDecimais(params.data.QuantidadePendente)}` },
      { headerName: 'Unidade Medida', field: 'Un', flex: 1 },
      { headerName: 'Status', flex: 1, cellRenderer: (params) => this.statusBadge(params.data.Status) },
      { headerName: 'Origem', field: 'GetSafra', flex: 1 },
    ];
  }

  filtrar(filtro: FiltroTela){
    let parametros: any = { ...filtro };
    parametros.tamanhoPagina = 100000;

    this.movimentoService.listarPaginado(parametros).subscribe(res => {
      this.movimentos = res.Lista;
    }, err => this.utils.getErro(err))
  }

  listarMovimentos(){
    let parametros: any = { ...this.filtro };
    parametros.tamanhoPagina = 100000;

    this.movimentoService.listarPaginado(parametros).subscribe(res => {
      this.movimentos = res.Lista;
    }, err => this.utils.getErro(err))
  }
  listarCentroResultados() {
    this.centroDeResultadoService.getCentrosDeResultados()
      .subscribe(res => {
        this.centrosResultados = res;
      }, err => this.utils.getErro(err));
  }
  listarProjetos() {
    this.projetoService.getList()
      .subscribe(res => {
        this.projetos = res;
      }, err => this.utils.getErro(err));
  }
  listarNaturezas() {
    this.naturezaService.getList()
      .subscribe(res => {
        this.naturezas = res;
      }, err => this.utils.getErro(err));
  }
  listarLocalEstoque() {
    this.localEstoqueService.getLocaisEstoque()
      .subscribe(res => {
        this.locaisEstoque = res;
      }, err => this.utils.getErro(err));
  }
  listarProdutos() {
    this.produtoService.getProdutos()
      .subscribe(res => {
        this.produtos = res;
      }, err => {
        this.utils.getErro(err)
      });
    this.grupoProdutoService.getGruposProduto()
      .subscribe(res => {
        this.gruposProduto = res;
      }, err => {
        this.utils.getErro(err)
      });
  }
  listarTransacoes() {
    this.transacaoService.getTransacoes()
      .subscribe((res: Transacao[]) => {
        this.transacoes = res;
      }, err => {
        this.utils.getErro(err)
      });
  }
  statusBadge(status: EnumStatusMovimento) {
    switch (status) {
      case EnumStatusMovimento.Cadastrado:
        return '<span class="text-secondary text-uppercase">Em Elaboração</span>'
      case EnumStatusMovimento.Pendente:
        return '<span class="text-secondary text-uppercase">Pendente</span>'
      case EnumStatusMovimento.Cancelado:
        return '<span class="text-danger text-uppercase">Cancelado</span>'
      case EnumStatusMovimento.ParcialmenteRecebido:
        return '<span class="text-primary text-uppercase">Parcialmente Recebido</span>'
      case EnumStatusMovimento.Recebido:
        return '<span class="text-success text-uppercase">Recebido</span>'
      case EnumStatusMovimento.ValidacaoPorComprador:
        return '<span class="text-primary text-uppercase">Em Validação Por Comprador</span>'
      case EnumStatusMovimento.ValidacaoRecebimento:
        return '<span class="text-primary text-uppercase">Em Validação do Recebimento</span>'
      case EnumStatusMovimento.LiberacaoFinanceiro:
        return '<span class="text-primary text-uppercase">Aguardando Liberação Financeira</span>'
      case EnumStatusMovimento.FinanceiroLiberado:
        return '<span class="text-primary text-uppercase">Liberado no Financeiro</span>'
      case EnumStatusMovimento.Reprovado:
        return '<span class="text-danger text-uppercase">Reprovado</span>'
      case EnumStatusMovimento.EmRevisao:
        return '<span class="text-secondary text-uppercase">Em Revisão</span>'
      case EnumStatusMovimento.EmAprovacao:
        return '<span class="text-primary text-uppercase">Em Aprovação</span>'
      case EnumStatusMovimento.Aprovado:
        return '<span class="text-primary text-uppercase">Aprovado</span>'
      default:
        break
    }
  }
  statusAprovacaoBadge(status: EnumStatusAprovacao) {
    switch (status) {
      case EnumStatusAprovacao.Aprovado:
        return '<span class="text-success text-uppercase">Aprovado</span>'
      case EnumStatusAprovacao.Reprovado:
        return '<span class="text-danger text-uppercase">Reprovado</span>'
      case EnumStatusAprovacao.Cadastrado:
        return '<span class="text-secondary text-uppercase">Cadastrado Recebido</span>'
      case EnumStatusAprovacao.Revisao:
        return '<span class="text-primary text-uppercase">Revisão</span>'
      default:
        break
    }
  }

  produtoDesc(id: number): string {
    if (id != null || id != undefined) {
      let produto = this.produtos.find(p => p.Id == id);
      return produto != undefined ? produto.Descricao : id.toString();
    }
    return id + '';
  }
  formatarDecimais(value) {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  cellDoubleClicked(event) {
    var t = this.usuario.permissaoUsuarioTransacaos.find(p => p.TransacaoId == event.data.TransacaoId);
    if (t && !t.PermiteEditar) {
      this.semPermissao();
      return;
    }
    const movimento: Movimento = event.data;
    let route: string;
    switch (movimento.GetTipoMovimentoId){
      case 1:
        route = 'solicitacao-compra/';
        break;
      case 3:
        route = 'pedido-compras/';
        break;
      case 11:
        route = 'pedido-vendas/';
        break;
      default:
        route = 'cadastros/movimento/';
        break;
    }

    this.router.navigate([route + event.data.Id]);
  }
  onCellClicked(row) {
    this.movimentoSelecionadoId = row.data.Id;
    this.movimentoSelecionado = row.data;
    this.auxItems = this.movimentoSelecionado.Itens;
  }
  selectionChanged(event) {
    this.movimentosSelecionados = event;
  }
  semPermissao() {
    this.utils.handleWarning("Você não tem permissão para executar essa ação!")
  }

}
