export class RetornoCusto {
    OrdemServicoId: number;
    FazendaId: number;
    SafraId: number;
    AreaId: number;
    AreaDescricao:string = "";
    AreaTotal: number = 0;
    AreaPlantada: number = 0;
    ProdutoId: number;
    ProdutoDescricao:string = "";
    GrupoProdutoId: number;
    GrupoProdutoDescricao:string = "";
    PrecoUnitario: number = 0;
    QuantidadePrevista: number = 0;
    QuantidadeAplicada: number = 0;
    PrecoTotal: number = 0;
    PrecoTotalPlanejado: number = 0;
    PrecoPorHa: number = 0;
    PrecoPorHaPlanejado: number = 0;
    Status: number = 0;
}

export class RetornoCustoGrupo {
    RetornoCusto: RetornoCusto[];
    GrupoId: number;
}
