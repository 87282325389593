import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Empresa } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-frota',
  templateUrl: './frota.component.html',
  styleUrls: ['./frota.component.scss']
})
export class FrotaComponent implements OnInit {
  @Output() fechou: EventEmitter<any> = new EventEmitter();
  
  showAbastecimento: boolean = false;
  showManutencao: boolean = false;
  showCustoFrota: boolean = false;
  showApontamentoFrota: boolean = false;
  empresa: Empresa;

  constructor(private empresaService: EmpresaService,
              private storageService: StorageService,
              private utils: UtilsService,
              private router: Router) { }

  ngOnInit() {
    this.empresaService.getEmpresa(this.storageService.getEmpresa().Id).subscribe(
      res => {
        this.empresa = res;
      },
      err => {
        this.utils.getErro(err);
      }
    )
  }

  inserirManutencao(){
    this.showManutencao = true;
  }
  
  inserirAbastecimento(){
    this.showAbastecimento = true;
  }

  inserirCustoFrota(){
    this.showCustoFrota = true;
  }

  inserirApontamentoFrota(){
    this.showApontamentoFrota = true;
  }

  fecharCustoFrota(){
    this.showCustoFrota = false;
    this.fechou.emit();
    this.router.navigate(['/gestao-lite/frota']);
  }

  fecharApontamentoFrota(){
    this.showApontamentoFrota = false;
    this.fechou.emit();
    this.router.navigate(['/gestao-lite/frota']);
  }

  fecharAbastecimento(){
    this.showAbastecimento = false;
    this.fechou.emit();
    this.router.navigate(['/gestao-lite/frota']);
  }

  fecharManutencao(){
    this.showManutencao = false;
    this.fechou.emit();
    this.router.navigate(['/gestao-lite/frota']);
  }

}
