export class Abastecimento{
    Id: number;
    ProdutoId: number;
    LocalEstoqueId: number;
    Quantidade: number = 0;
    ValorUnt: number = 0;
    DataAbastecimento: Date = new Date();
    UsuarioResponsavelId: number;
    UsuarioOperadorId: number;
    EquipamentoId: number;
    SafraId: number;
    Medidor: number = 0;
    Total: number = 0;
    Observacao:string = "";
}
