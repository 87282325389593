import { SharedFarmModule } from './../shared-farm/shared-farm.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CustomFormsModule } from 'ng2-validation';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { NgbDatepickerModule, NgbCollapseModule, NgbDropdownModule, NgbTooltipModule, NgbTabsetModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GrupoEquipamentoComponent } from './grupo-equipamento/grupo-equipamento.component';
import { EquipamentoComponent } from './equipamento/equipamento.component';
import { EscalaTrabalhoComponent } from './escala-trabalho/escala-trabalho.component';
import { FuncionarioComponent } from './funcionario/funcionario.component';
import { OrdemServicoComponent } from './ordem-servico/ordem-servico.component';
import { TabLateralComponent } from './ordem-servico/tab-lateral/tab-lateral.component';
import { DetalhamentoComponent } from './detalhamento/detalhamento.component';
import { DetalhamentoTabLateralComponent } from './detalhamento/detalhamento-tab-lateral/detalhamento-tab-lateral.component';
import { DetalhamentoTabelaComponent } from './detalhamento/detalhamento-tabela/detalhamento-tabela.component';
import { ApontamentoComponent } from './apontamento/apontamento.component';
import { LiberacaoComponent } from './liberacao/liberacao.component';
import { AgendaAtividadeComponent } from './agenda-atividade/agenda-atividade.component';
import { BuscaOrdemServicoComponent } from './busca-ordem-servico/busca-ordem-servico.component';
import { ApontamentoMaquinaComponent } from './apontamento-maquina/apontamento-maquina.component';
import { LiberacaoIsumosComponent } from './liberacao/liberacao-isumos/liberacao-isumos.component';
import { ChecklistEquipamentoComponent } from './equipamento/checklist-equipamento/checklist-equipamento.component';
import { CustoEquipamentoComponent } from './equipamento/custo-equipamento/custo-equipamento.component';
import { ApontamentoFrotaComponent } from './equipamento/apontamento-frota/apontamento-frota.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AbastecimentosComponent } from './equipamento/abastecimentos/abastecimentos.component';
import { AbastecimentoComponent } from './equipamento/abastecimento/abastecimento.component';
import { ApontamentoIndicadoresComponent } from './apontamento-indicadores/apontamento-indicadores.component';
import { ManutencaoComponent } from './equipamento/manutencao/manutencao.component';
import { ManutencoesComponent } from './equipamento/manutencoes/manutencoes.component';
import { ArchwizardModule } from 'ng2-archwizard/dist';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { OrdemServicoAvulsaModalComponent } from './ordem-servico/componentes/ordem-servico-avulsa-modal/ordem-servico-avulsa-modal.component';
import { ItemsOrdemServicoComponent } from './ordem-servico/componentes/items-ordem-servico/items-ordem-servico.component';
import { DetalhesOrdemServicoComponent } from './ordem-servico/componentes/detalhes-ordem-servico/detalhes-ordem-servico.component';
import { OrdemServicoFormsComponent } from './ordem-servico/componentes/ordem-servico-forms/ordem-servico-forms.component';
import { ObservacaoOrdemServicoComponent } from './ordem-servico/componentes/observacao-ordem-servico/observacao-ordem-servico.component';

@NgModule({
  declarations: [
    GrupoEquipamentoComponent,
    EquipamentoComponent,
    EscalaTrabalhoComponent,
    FuncionarioComponent,
    OrdemServicoComponent,
    TabLateralComponent,
    DetalhamentoComponent,
    DetalhamentoTabLateralComponent,
    DetalhamentoTabelaComponent,
    ApontamentoComponent,
    LiberacaoComponent,
    AgendaAtividadeComponent,
    BuscaOrdemServicoComponent,
    ApontamentoMaquinaComponent,
    LiberacaoIsumosComponent,
    ChecklistEquipamentoComponent,
    CustoEquipamentoComponent,
    ApontamentoFrotaComponent,
    AbastecimentosComponent,
    AbastecimentoComponent,
    ApontamentoIndicadoresComponent,
    ManutencaoComponent,
    ManutencoesComponent,
    OrdemServicoAvulsaModalComponent,
    ItemsOrdemServicoComponent,
    DetalhesOrdemServicoComponent,
    OrdemServicoFormsComponent,
    ObservacaoOrdemServicoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    SharedFarmModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbTabsetModule,
    NgSelectModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
  })
  ],
  exports: [
    OrdemServicoAvulsaModalComponent,
    DetalhamentoTabLateralComponent,
    ApontamentoComponent,
    LiberacaoComponent,
    BuscaOrdemServicoComponent,
    CustoEquipamentoComponent,
    ApontamentoFrotaComponent,
    AbastecimentoComponent,
    ApontamentoIndicadoresComponent,
    ManutencaoComponent,
    ManutencoesComponent
  ]
})
export class OrdemServicoModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
