import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AnexoPendenciaFrota, PendenciaFrota } from 'src/app/classes/PendenciaFrota';
import { PendenciaFrotaService } from 'src/app/services/pendencia-frota.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-anexo-pendencia-frota',
  templateUrl: './anexo-pendencia-frota.component.html',
  styleUrls: ['./anexo-pendencia-frota.component.scss']
})
export class AnexoPendenciaFrotaComponent implements OnInit  {

  @Input() pendenciaFrota: PendenciaFrota;
  @Output() changeAnexos = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  uploadedFiles: Array<File> = [];
  anexos: AnexoPendenciaFrota[] = [];

  constructor(private pendenciaFrotaService: PendenciaFrotaService,
    private utils: UtilsService) { }

  ngOnInit() {
  }

  download(arquivo: string) {
    this.pendenciaFrotaService.getAnexo(arquivo);
  }

  excluir(anexo: AnexoPendenciaFrota) {

    if(!anexo.Id){
      const indice = this.pendenciaFrota.Anexos.findIndex(item => item.Id === anexo.Id);
      if (indice !== -1) {
        this.pendenciaFrota.Anexos.splice(indice, 1);
      }
      return;
    }

    Swal.fire({
      title: 'Deletar '+ anexo.Arquivo + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.pendenciaFrotaService.deleteAnexo(anexo.Id).subscribe(res =>{
          const indice = this.pendenciaFrota.Anexos.findIndex(item => item.Id === anexo.Id);
          if (indice !== -1) {
            this.pendenciaFrota.Anexos.splice(indice, 1);
          }
        },
        err => {
          this.utils.getErro(err);
        });
      }
    });
  }

  getBase64() {
    let that = this;

    let a = this.anexos.map(e => e.Arquivo);
    for (let uf of this.uploadedFiles) {
      if(a.includes(uf.name)) {
        continue;
      }
      let reader = new FileReader();
      reader.readAsDataURL(uf);
      reader.onload = function () {
        let anexo = new AnexoPendenciaFrota();
        anexo.Arquivo = uf.name;
        anexo.UploadArquivo = reader.result.toString().replace(/^data:[A-z]*\/?[A-z]*;base64,/, '');
        that.changeAnexos.emit(anexo);
      };
        reader.onerror = function (error) {
        console.error('Error: ', error);
      };
    }
    this.uploadedFiles = [];
  }

}
