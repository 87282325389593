import { Component, Input, OnInit } from '@angular/core';
import { TiposDiagnoseAudios } from 'src/app/classes/tipos-diagnose/TiposDiagnoseAudios';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-diagnose-audio',
  templateUrl: './diagnose-audio.component.html',
  styleUrls: ['./diagnose-audio.component.scss']
})
export class DiagnoseAudioComponent implements OnInit {

  @Input() diagnoseAudio: TiposDiagnoseAudios

  serverUrl: string;

  constructor() {
    this.serverUrl = environment.apiURL.replace('api/v1', '');
  }

  ngOnInit() { }

}
