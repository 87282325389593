import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Empresa } from 'src/app/classes/Empresa';
import { Fazenda } from 'src/app/classes/Fazenda';
import { Safra } from 'src/app/classes/Safra';
import { AreaUltimaModificacao } from 'src/app/classes/tipos-diagnose/AreaUltimaModificacao';
import { BuscarAreas } from 'src/app/classes/tipos-diagnose/BuscarAreas';
import { Diagnoses } from 'src/app/classes/tipos-diagnose/Diagnoses';
import { environment } from 'src/environments/environment';
import { DiagnosesPorArea } from '../../classes/tipos-diagnose/DiagnosesPorArea';

@Injectable({
  providedIn: 'root'
})
export class DiagnosesService {

  constructor(private http: HttpClient) { }

  ObterEmpresas(): Observable<Empresa[] | null> {
    return this.http.get<Empresa[]>(`${environment.apiURL}/Usuarios/Consultor/Empresas`);
  }

  ObterFazendasPorEmpresaId(id: number): Observable<Fazenda[] | null> {
    return this.http.get<Fazenda[]>(`${environment.apiURL}/Fazendas/empresaId/${id}`);
  }

  ObterSafrasPorEmpresaIdEFazendaId(empresaId: number, fazendaId: number): Observable<Safra[] | null> {
    return this.http.get<Safra[]>(`${environment.apiURL}/Safra/empresaId/${empresaId}/fazendaId/${fazendaId}`);
  }

  ObterAreaUltimaModificacao(buscarAreas: BuscarAreas): Observable<AreaUltimaModificacao[] | null> {
    return this.http.get<AreaUltimaModificacao[]>(`${environment.apiURL}/Diagnoses?EmpresaId=${buscarAreas.EmpresaId}&FazendaId=${buscarAreas.FazendaId}&SafraId=${buscarAreas.SafraId}`);
  }

  ObterDiagnosesPorArea(diagnoses: DiagnosesPorArea): Observable<Diagnoses | null> {
    return this.http.get<Diagnoses>(`${environment.apiURL}/Diagnoses/porAreas?EmpresaId=${diagnoses.EmpresaId}&SafraId=${diagnoses.SafraId}&AreaId=${diagnoses.AreaId}`);
  }
}
