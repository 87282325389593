import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContaBancaria } from 'src/app/classes/ContaBancaria';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { PersonalizacaoGridModel } from 'src/app/classes/PersonalizacaoGridModel';
import { DicionariTipoContaBancaria } from 'src/app/classes/enum/EnumTipoContaBancaria';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { ContasBancariasService } from 'src/app/services/contas-bancarias.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contas-bancarias',
  templateUrl: './contas-bancarias.component.html',
  styleUrls: ['./contas-bancarias.component.scss']
})
export class ContasBancariasComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private toasty: CustomToastyService,
    private contaBancariaService : ContasBancariasService,
    ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  id: number = 0;

  @ViewChild('Grid', { static: false }) grid

  PersonalizacaoGrid: PersonalizacaoGridModel = new PersonalizacaoGridModel();
  acoesItem = [
    'Editar'
  ]
  colDefs: ColDef[] = [];
  showGrid = true;
  showGridItems = true;
  minHeight = 0;
  inserindo: boolean;
  listaContasBancarias: ContaBancaria[] = [];
  objSelecionado: ContaBancaria = new ContaBancaria();
  objSelecionadoId: number = null;
  objetosSelecionados: ContaBancaria[] = [];
  filtro = new FiltroTela();
  isSubmit = false;
  loading: boolean = false;

  dicTipo = DicionariTipoContaBancaria;

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    if (this.id > 0)
      this.inserindo = true

    this.configurar();

    this.listarContasBancarias();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'Id', headerName: 'Id', width: 60},
      {field: 'Tipo', headerName: 'Tipo', flex: 1, valueFormatter: (params) => this.dicTipo[params.data.Tipo] },
      {field: 'BancoDescricao', headerName: 'Banco', flex: 1},
      {field: 'AgenciaDescricao', headerName: 'Agência', flex: 1},
      {field: 'SaldoDataRefencia', headerName: 'Saldo Data Referência', flex: 1, valueFormatter: (params) => `${this.formatarDecimais(params.data.SaldoDataRefencia)}`},
      {field: 'CodigoEmpresaExclusiva', headerName: 'Cód. Empresa Exclusiva', flex: 1}
    ];

  }
  formatarDecimais(value) {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  listarContasBancarias() {
    this.contaBancariaService.Listar()
      .subscribe(res => {
        this.listaContasBancarias = res;
        this.grid.setRowData(this.listaContasBancarias);
      }, err => this.utils.getErro(err));
  }

  novo() {
    this.router.navigate(['/cadastros/contas-bancarias/inserir']);
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/contas-bancarias/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione uma conta bancária para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas uma conta bancária para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.contaBancariaService.Excluir(this.objSelecionadoId).subscribe(
            res => {
              this.toasty.show('Feito!', 'Conta Bancária excluída!', 'success');
              this.listarContasBancarias();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

  editar() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione uma conta bancária para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas uma conta bancária para prosseguir!");
      return;
    }
    this.router.navigate([`/cadastros/contas-bancarias/${this.objSelecionadoId}`]);
  }

}
