import { Injectable } from '@angular/core';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { EnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Movimento } from 'src/app/classes/Movimento';
import { Natureza } from 'src/app/classes/Natureza';
import { Pessoa } from 'src/app/classes/Pessoa';
import { Produto } from 'src/app/classes/Produto';
import { Projeto } from 'src/app/classes/Projeto';
import { Safra } from 'src/app/classes/Safra';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
import { CentroDeResultadosService } from 'src/app/services/centro-de-resultados.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { NaturezaService } from 'src/app/services/natureza.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { ProjetoService } from 'src/app/services/projeto.service';
import { SafraService } from 'src/app/services/safra.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MovimentoSharedService {

  naturezas: Natureza[] = [];
  locaisEstoque: LocalEstoque[] = [];
  centrosResultados: CentroDeResultados[] = [];
  projetos: Projeto[] = [];
  listaFornecedores: Pessoa[] = [];
  movimentos: Movimento[];
  listaResponsavel: Usuario[] = [];
  auxMovimentos: Movimento[] = [];
  produtos: Produto[] = [];
  gruposProduto: GrupoProduto[] = [];
  transacoes: Transacao[] = [];
  safras: Safra[] = [];
  tipoMovimento: EnumTipoMovimento;

  constructor(
    private utils: UtilsService,
    private naturezaService: NaturezaService,
    private localEstoqueService: LocalEstoqueService,
    private centroDeResultadoService: CentroDeResultadosService,
    private projetoService: ProjetoService,
    private produtoService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private transacaoService: TransacaoService,
    private safraService: SafraService,) { 
      //this.loadAll();
    }

    listarCentroResultados(){
      this.centroDeResultadoService.getCentrosDeResultados()
      .subscribe(res => {
        this.centrosResultados = res;
      }, err => this.utils.getErro(err));
    }
    listarProjetos(){
      this.projetoService.getList()
      .subscribe(res => {
        this.projetos = res;
      }, err => this.utils.getErro(err));
    }
    listarNaturezas(){
      this.naturezaService.getList()
      .subscribe(res => {
        this.naturezas = res;
      }, err => this.utils.getErro(err));
    }
    listarLocalEstoque() {
      this.localEstoqueService.getLocaisEstoque()
        .subscribe(res => {
          this.locaisEstoque = res;
        }, err => this.utils.getErro(err));
    }
    listarSafras() {
      this.safraService.getSafras()
        .subscribe(res => {
          this.safras = res;
        }, err => this.utils.getErro(err));
    }
    listarTransacoes() {
      if(this.tipoMovimento)
        this.transacaoService.getTransacoesPermitidasPorTipo(this.tipoMovimento)
          .subscribe((res: Transacao[]) => {
            this.transacoes = res;
          }, err => {
            this.utils.getErro(err)
          }
        );
      else 
        this.transacaoService.getTransacoes()
          .subscribe((res: Transacao[]) => {
            this.transacoes = res;
          }, err => {
            this.utils.getErro(err)
          }
        );
    }
    listarProdutos() {
      this.produtoService.getProdutos()
        .subscribe(res => {
          this.produtos = res;
        }, err => {
          this.utils.getErro(err)
        });
      this.grupoProdutoService.getGruposProduto()
        .subscribe(res => {
          this.gruposProduto = res;
        }, err => {
          this.utils.getErro(err)
        });
    }

    loadAll() {
      const produtoReq = this.produtoService.getProdutos();
      const transacaoReq = this.tipoMovimento ? this.transacaoService.getTransacoesPermitidasPorTipo(this.tipoMovimento) : this.transacaoService.getTransacoes();
      const localEstoqueReq = this.localEstoqueService.getLocaisEstoque();
      const naturezaReq = this.naturezaService.getList();
      const projetoReq = this.projetoService.getList();
      const centroResultadoReq = this.centroDeResultadoService.getCentrosDeResultados();
      //const safraReq = this.safraService.getSafras();

      forkJoin([produtoReq, transacaoReq, localEstoqueReq, naturezaReq, projetoReq, centroResultadoReq]).subscribe(
        (results) => {
          this.produtos = results[0];
          this.transacoes = results[1];
          this.locaisEstoque = results[2];
          this.naturezas = results[3];
          this.projetos = results[4];
          this.centrosResultados = results[5];
          //this.safras = results[2];
        },
        (error) => {
          this.utils.getErro(error);
        }
      );
    }

    getRoute(): string {
      switch (this.tipoMovimento) {
        case 1:
          return '/solicitacao-compra';
        case 3:
          return '/pedido-compras';
        case 11:
          return '/pedido-vendas';
        default: 
          return '/cadastros/movimento';
      }
    }
}

