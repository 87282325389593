import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-apontamento-frota-lite',
  templateUrl: './apontamento-frota.component.html',
  styleUrls: ['./apontamento-frota.component.scss']
})
export class ApontamentoFrotaComponent implements OnInit {
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  @Output() fechou: EventEmitter<any> = new EventEmitter();
  id: number = 0;
  isSubmit: boolean = false;  

  constructor() {}

  ngOnInit() {    
  }

  ngAfterViewInit() {
    this.Modal.show();
  }

  fechar(){
    this.Modal.hide();
    this.fechou.emit();
  }

  selecionarEquipamento(equipamentoId: number){    
    this.id = 0;    
    setTimeout(() => {
      if(equipamentoId)
        this.id = equipamentoId;
    }, 100);    
  }

  salvar(){    
  }
}
