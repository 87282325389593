// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiURL: "https://webapp-farmway-prod-brazilsouth.azurewebsites.net/api/v1",
  baseApiURL:"https://webapp-farmway-prod-brazilsouth.azurewebsites.net",
  production: false
};
//"http://localhost:51050/api",
//https://api.linkfarm.com.br
//https://apihmg.linkfarm.com.br
//http://localhost:8080
//https://webapp-farmway-prod-brazilsouth.azurewebsites.net
//https://webapp-farmway-dev-brazilsouth.azurewebsites.net
//https://apidev.farmway.com.br

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
