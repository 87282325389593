import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EstoqueComponent } from "./estoque/estoque.component";
import { SharedModule } from "src/app/theme/shared/shared.module";
import { FormsModule } from "@angular/forms";
import { ChartModule } from "angular2-chartjs";
import { CardModule } from "../../theme/shared/components/card/card.module";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ModalModule } from "../../theme/shared/components/modal/modal.module";
import { EstoqueModule } from "../estoque/estoque.module";
import { ProducaoComponent } from "./producao/producao.component";
import { SharedFarmModule } from "../shared-farm/shared-farm.module";
import { FrotaComponent } from "./frota/frota.component";
import { GraficoBarrasCustosComponent } from "./grafico-barras-custos/grafico-barras-custos.component";
import { TabelaRelatorioComponent } from "./tabela-relatorio/tabela-relatorio.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { CustoFrotaComponent } from './frota/custo-frota/custo-frota.component';
import { ApontamentoFrotaComponent } from './frota/apontamento-frota/apontamento-frota.component';
import { OrdemServicoModule } from '../ordem-servico/ordem-servico.module';
import { ListaMovimentoEstoqueComponent } from "./estoque/lista-movimento-estoque/lista-movimento-estoque.component";
import { StatusManutencoesComponent } from './frota/status-manutencoes/status-manutencoes.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient} from '@angular/common/http';
import { DashboardManutencaoComponent } from './dashboard-manutencao/dashboard-manutencao.component';

@NgModule({
    declarations: [
        GraficoBarrasCustosComponent,
        TabelaRelatorioComponent,
        ApontamentoFrotaComponent,
        CustoFrotaComponent,
        EstoqueComponent,
        ProducaoComponent,
        FrotaComponent,
        ListaMovimentoEstoqueComponent,
        StatusManutencoesComponent,
        DashboardManutencaoComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgSelectModule,
        FormsModule,
        ChartModule,
        CardModule,
        NgbTooltipModule,
        NgbDropdownModule,
        CurrencyMaskModule,
        ModalModule,
        EstoqueModule,
        SharedFarmModule,
        OrdemServicoModule,
        TranslateModule.forChild({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            },
            defaultLanguage: 'pt'
        })
    ],
    exports: [

      ]
})
export class GestaoLiteModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }
