import { EquipamentoService } from './../../../../services/equipamento.service';
import { FuncionarioService } from './../../../../services/funcionario.service';
import { Equipamento } from './../../../../classes/Equipamento';
import { Funcionario } from './../../../../classes/Funcionario';
import { EquipamentoApontamentoService } from 'src/app/services/equipamento-apontamento.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EquipamentoApontamento } from 'src/app/classes/EquipamentoApontamento';

@Component({
  selector: 'app-horas-produtivas-paradas',
  templateUrl: './horas-produtivas-paradas.component.html',
  styleUrls: ['./horas-produtivas-paradas.component.scss']
})
export class HorasProdutivasParadasComponent implements OnInit {
  equipamentosApontamento: EquipamentoApontamento[];
  funcionarios: Funcionario[];
  equipamentos: Equipamento[];
  @Input() funcionarioId:number;
  @Input() equipamentoId:number;
  @Input() implementoId:number;
  totalTrabalhadas: number = 0;
  totalParadas:number;

  carregando: boolean;

  public pieChartData: any;
  @ViewChild('doughnutChart', {static: false}) pieChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;
  public options: any = {
    legend: { position: 'bottom' }
  }

  constructor(private equipamentoApontamentoService: EquipamentoApontamentoService,
              private funcionarioService: FuncionarioService,
              private equipamentoService: EquipamentoService) { }

  ngOnInit() { 
    if(!this.funcionarioId)
      this.funcionarioId = 0;
    if(!this.equipamentoId)
      this.equipamentoId = 0;
    if(!this.implementoId)
      this.implementoId = 0;  
    /*this.funcionarioService.getFuncionarios().subscribe(ret=> {
      this.funcionarios = ret;
    })

    this.equipamentoService.getEquipamentos().subscribe(ret => {
      this.equipamentos = ret;
    })    */

    this.calcular();
  }

  calcular(){
    this.carregando = true;
    this.totalParadas = 0;
    this.totalTrabalhadas = 0;
    this.equipamentoApontamentoService.getEquipamentosApontamentoFiltro(this.funcionarioId, this.equipamentoId, this.implementoId).subscribe(ret=>{
      this.equipamentosApontamento = ret;
      this.equipamentosApontamento.forEach(x =>{
        this.totalParadas += (x.ImprodutividadeTotal);
        this.totalTrabalhadas += x.HorasTrabalhadas;
      })

      /*doughnut_chart */
      setTimeout(()=>{   
        const pieTag = (((this.pieChart.nativeElement as HTMLCanvasElement).children));
        this.pieChartTag = ((pieTag['doughnut_chart']).lastChild).getContext('2d'); // doughnut_chart
        this.pieChartData = {
          labels: ['Horas Paradas', 'Horas Trabalhadas'],
          datasets: [{          
            data: [this.totalParadas.toFixed(2), this.totalTrabalhadas.toFixed(2)],
            backgroundColor: ['#F44336', '#4680FF'],
            hoverBackgroundColor: ['#F44336', '#4680FF']
          }],
          legends:{
            position: 'bottom'
          }
        };
      }, 500)

      this.carregando = false;

    })
  }

}
