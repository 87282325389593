import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Empresa, enumErpIntegrado } from 'src/app/classes/Empresa';
import { PermissaoUsuario } from 'src/app/classes/PermissaoUsuario';
import { PermissaoUsuarioTransacao } from 'src/app/classes/PermissaoUsuarioTransacao';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
import { StorageService } from 'src/app/services/storage.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { MasksService } from 'src/app/utils/masks.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { UsuarioHorarioAcessoValidacaoSharedService } from '../usuario-horario-acesso/usuario-horario-acesso-validacao-shared.service';
import { RegionaisService } from 'src/app/services/regionais/regionais.service';
import { Regional } from 'src/app/classes/regionais/Regional';
import { Parceiro } from 'src/app/classes/parceiro/Parceiro';
import { ParceirosService } from 'src/app/services/parceiros/parceiros.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.scss']
})
export class UsuarioFormComponent implements OnInit, OnChanges {

  classBtnMarcarTodos: string = 'btn btn-primary  btn-sm';
  classBtnDesmarcarTodos: string = 'btn btn-secondary  btn-sm';

  usuario: Usuario;
  @Input() id: number = 0;
  carregando: boolean = false;
  confirmaSenha: string;
  permissaoUsuarioTransacao: PermissaoUsuarioTransacao = new PermissaoUsuarioTransacao();
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  empresaSelecionada: number = 0;
  integraTotvs: boolean = false;
  integraSankhya: boolean = false;

  collapseGerais = false;

  todasMarcadas = false;

  transacoes: Transacao[] = [];

  usuarioLogado: Usuario;
  listaParceiros: Parceiro[];
  mostrarParceiros: boolean = false;
  listaRegionais: Regional[];
  mostrarRegionais: boolean = false;
  listaEmpresaAdicionadas: Empresa[] = [];
  listaEmpresaRelacionadas: Empresa[] = [];
  listaEmpresasUsuarios: EmpresasUsuarios[] = [];
  listaEmpresaDisponivel: Empresa[] = [];
  mostrarEscolhaEmpresa: boolean = false;

  constructor(private usuarioService: UsuarioService,
    private utils: UtilsService,
    public masks: MasksService,
    private toasty: CustomToastyService,
    private storageService: StorageService,
    private router: Router,
    private transacaoService: TransacaoService,
    public validacaoHorarioService: UsuarioHorarioAcessoValidacaoSharedService,
    private parceirosService: ParceirosService,
    private regionaisService: RegionaisService,
    private empresaService: EmpresaService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    let empresa = this.storageService.getEmpresa();
    if(empresa)
      this.empresaSelecionada = empresa.Id;
    this.usuario = new Usuario();
    this.usuarioLogado = this.storageService.getUsuario();
    this.integraTotvs = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Totvs_RM;
    this.integraSankhya = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Sankhya;
    this.pegarListaDeEmpresas();
  }

  selecionarUsuario() {
    this.carregando = true;
    let idstr = '';
    if (this.id)
      idstr = this.id.toString();
    if (!this.id || idstr == 'inserir') {
      this.usuario = new Usuario();
      this.transacaoService.getTransacoesTotais().subscribe(
        res => {
          this.transacoes = res;
          let aux: Transacao[] = res;
          this.transacoes.forEach(transacao => {
            if(this.usuario.permissaoUsuarioTransacaos == undefined) return;
            this.usuario.permissaoUsuarioTransacaos.forEach((item, index) => {
              if (item.TransacaoId == transacao.Id)
                aux.splice(index, 1);
            });
          });
          this.transacoes = aux;
        },
        err => {
          this.utils.getErro(err);
        }
      );

      this.verificarMarcadas();
      this.carregando = false;
      return;
    }

    this.usuarioService.getUsuario(this.id).subscribe(res => {
      this.usuario = res;
      this.confirmaSenha = this.usuario.Senha;

      if (this.usuario.PermissaoUsuario == null)
        this.usuario.PermissaoUsuario = new PermissaoUsuario();

      this.transacaoService.getTransacoesTotais().subscribe(
        res => {
          this.transacoes = res;
          let aux: Transacao[] = res;
          this.transacoes.forEach(transacao => {
            this.usuario.permissaoUsuarioTransacaos.forEach((item, index) => {
              if (item.TransacaoId == transacao.Id)
                aux.splice(index, 1);
            });
          });
          this.transacoes = aux;
        },
        err => {
          this.utils.getErro(err);
        }
      );

      this.verificarMarcadas();
      this.selecionarPerfil();
      this.carregando = false;
    },
      ex => {
        this.utils.getErro(ex);
        this.carregando = false;
      }
    );
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    if (this.usuario.Id > 0 && !this.validacaoHorarioService.validarHorarios(this.usuario.DiaHorarioAcessoUsuario)) {
      this.utils.handleWarning('Horario de acesso Inconssistente!');
      return;
    }
    this.carregando = true;
    this.usuario.Perfil = Number(this.usuario.PerfilInt);
    if (this.usuario.Id > 0) {
      this.usuarioService.putUsuario(this.usuario.Id, this.usuario).subscribe(
        res => {
          this.usuario = res;
          this.confirmaSenha = this.usuario.Senha;
          this.usuarioService.relacionarAEmpresas(this.usuario.Id, this.listaEmpresaAdicionadas, this.listaEmpresaRelacionadas);
          this.toasty.show('Feito!', 'Usuário alterada!', 'success');
          if(this.usuarioLogado.Id == this.usuario.Id)
            this.loginService.logout();

          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
    } else {
      this.usuarioService.postUsuario(this.usuario).subscribe(
        res => {
          this.toasty.show('Feito!', 'Usuário cadastrada!', 'success');
          this.usuario = res;
          this.confirmaSenha = this.usuario.Senha;
          this.usuarioService.relacionarAEmpresas(this.usuario.Id,this.listaEmpresaAdicionadas, this.listaEmpresaRelacionadas);
          this.carregando = false;
          this.router.navigate([`/cadastros/usuario/${res.Id}`]);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
    }
  }

  cancelar() {
    this.router.navigate([`/cadastros/usuario`]);
  }

  marcarTodasComoTrue() {
    for (const prop in this.usuario.PermissaoUsuario) {
      if (this.usuario.PermissaoUsuario.hasOwnProperty(prop) && typeof this.usuario.PermissaoUsuario[prop] === 'boolean') {
        this.usuario.PermissaoUsuario[prop] = true;
      }
    }
    this.todasMarcadas = true;
  }

  marcarTodasComoFalse() {
    for (const prop in this.usuario.PermissaoUsuario) {
      if (this.usuario.PermissaoUsuario.hasOwnProperty(prop) && typeof this.usuario.PermissaoUsuario[prop] === 'boolean') {
        this.usuario.PermissaoUsuario[prop] = false;
      }
    }
    this.todasMarcadas = false;
  }
  verificarMarcadas() {
    let allTrue = true;
    let anyTrue = false;

    for (const prop in this.usuario.PermissaoUsuario) {
      if (this.usuario.PermissaoUsuario.hasOwnProperty(prop) && typeof this.usuario.PermissaoUsuario[prop] === 'boolean') {
        if (this.usuario.PermissaoUsuario[prop]) {
          anyTrue = true;
        } else {
          allTrue = false;
        }
      }
    }

    if (allTrue) {
      this.todasMarcadas = true;
    } else if (!anyTrue) {
      this.todasMarcadas = false;
    } else {
      this.todasMarcadas = false;
    }
  }

  setAll(props: string[], ativo: boolean) {
    props.forEach((prop) => {
      if (this.usuario.PermissaoUsuario.hasOwnProperty(prop)) {
        this.usuario.PermissaoUsuario[prop] = ativo;
      }
    });
  }

  setPropriedadesPermissaoUsuario(sessao: string, ativo: boolean) {
    let props: string[] = [];
    switch (sessao) {
      case 'os':
        props = ['IncluirOS', 'DuplicarOS', 'EditarOS', 'ExcluirOS', 'LiberarOS', 'ConcluirOS', 'ReabrirOS', 'ApontarOS', 'ExcluirApontamentoOS', 'SubstituirProdutosOSPendentes'];
        this.setAll(props, ativo);
        break;
      case 'cadastro funcionarios':
        props = ['IncluirFuncionario', 'EditarFuncionario', 'ExcluirFuncionario'];
        this.setAll(props, ativo);
        break;
      case 'grupo de equipamento':
        props = ['IncluirGrupoEquipamento', 'EditarGrupoEquipamento', 'ExcluirGrupoEquipamento'];
        this.setAll(props, ativo);
        break;
      case 'equipamentos':
        props = ['IncluirEquipamento', 'EditarEquipamento', 'ExcluirEquipamento'];
        this.setAll(props, ativo);
        break;
      case 'operacoes agricolas':
        props = ['IncluirOperacoes', 'ExcluirOperacoes', 'IncluirMotivoParadas', 'EditarMotivoParadas', 'ExcluirMotivoParadas'];
        this.setAll(props, ativo);
        break;
      case 'fazenda e areas':
        props = ['IncluirFazenda', 'EditarFazenda', 'ExcluirFazenda', 'IncluirArea', 'EditarArea', 'ExcluirArea', 'ImportarMapaArea', 'DesenharMapaArea'];
        this.setAll(props, ativo);
        break;
      case 'variedades':
        props = ['IncluirVariedade', 'ExcluirVariedade'];
        this.setAll(props, ativo);
        break;
      case 'grupo de produtos e produtos':
        props = ['IncluirGrupoProduto', 'EditarGrupoProduto', 'ExcluirGrupoProduto', 'IncluirProduto', 'EditarProduto', 'ExcluirProduto'];
        this.setAll(props, ativo);
        break;
      case 'tipos de aplicacao':
        props = ['incluirTipoAplicacao', 'EditarTipoAplicacao', 'ExcluirTipoAplicacao'];
        this.setAll(props, ativo);
        break;
      case 'plano agronomico':
        props = ['VisualizarPlanoAgronomico', 'ConfigurarPlanoAgronomico', 'DuplicarPlanoAgronomico', 'ExcluirPlanoAgronomico', 'SubstituirProdutoPlano'];
        this.setAll(props, ativo);
        break;
      case 'safras':
        props = ['IncluirAnoSafra', 'EditarAnoSafra', 'ExcluirAnoSafra', 'IncluirSafra', 'EditarSafra', 'ExcluirSafra', 'ExcluirSafraInteira', 'AlterarStatusSafra', 'FinalizarSafra', 'AlterarSafraCriadaOutroUsuario'];
        this.setAll(props, ativo);
        break;
      case 'compras':
        props = ['UsarGrupoCompra', 'Comprador', 'AcessoSolicitacaoCompras', 'AcessoCotacao', 'AcessoPedidoCompras', 'AcessoTransacoes', 'PermiteEnviarCotacao', 'PermiteFinalizarCotacao', 'AcessoPedidoVendas'];
        this.setAll(props, ativo);
        break;
    }
  }

  filtrarPermissaoTransacao() {
    this.usuario.permissaoUsuarioTransacaos.forEach((item, index) => {
      if (item.NomeTransacao == this.textoBusca) this.transacoes.splice(index, 1);
    });

    let ids = this.usuario.permissaoUsuarioTransacaos.map(p => p.TransacaoId);
    this.transacoes = this.transacoes.filter(p => !ids.includes(p.Id));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id) {
      this.selecionarUsuario();
    }
  }

  get perfil() { return this.usuarioLogado.Perfil; }

  get mostrarOpcaoAdmin() {
    return this.empresaSelecionada != 1 && this.perfil != 5 && this.perfil != 6
  }

  pegarListaParceiros() {
    this.parceirosService.Listar().subscribe(
      res => this.listaParceiros = res,
      error => this.utils.getErro(error)
    )
  }

  pegarListaDeRegionais(parceiroId: string) {
    this.regionaisService.ListarPorParceiroId(parceiroId).subscribe(
      res => { this.listaRegionais = res },
      error => this.utils.getErro(error)
    )
  }

  pegarListaDeEmpresas() {
    if(this.usuarioLogado.RegionalId == undefined) return;
    this.regionaisService.ObterEmpresasRelacionadas(this.usuarioLogado.RegionalId).subscribe(
      res => {
        this.listaEmpresaDisponivel = res.Empresa;
        if (this.id != undefined) {
          this.empresaService.getEmpresasPorUsuarioId(this.id).subscribe(
            res => {
              this.listaEmpresasUsuarios = res;
              this.pegarListaEmpresasRelacionadas(res);
            },
            error => console.error(error)
          )
        }
      },
      error => console.error(error)
    )
  }

  selecionarPerfil() {
    const perfil = this.usuario.PerfilInt;
    if (perfil == 5) {
      this.pegarListaParceiros();
      this.mostrarParceiros = true;
    } else if (perfil == 6) {
      this.pegarListaDeRegionais(this.usuarioLogado.ParceiroId);
      this.usuario.ParceiroId = this.usuarioLogado.ParceiroId;
      this.mostrarRegionais = true;
    } else if (perfil == 7) {
      this.usuario.ParceiroId = this.usuarioLogado.ParceiroId;
      this.usuario.RegionalId = this.usuarioLogado.RegionalId;
      this.mostrarEscolhaEmpresa = true;
    } else {
      this.mostrarParceiros = false;
      this.mostrarRegionais = false;
      this.usuario.ParceiroId = null;
      this.usuario.RegionalId = null;
      this.mostrarEscolhaEmpresa = false;
    }
  }

  onListaEmpresaAdicionadasEmitted(event) {
    this.listaEmpresaAdicionadas = event;
  }

  private pegarListaEmpresasRelacionadas(empresasUsuarios: EmpresasUsuarios[]) {
    this.listaEmpresaRelacionadas = [...this.listaEmpresaDisponivel.filter(emp => {
      return empresasUsuarios.some(item => item.EmpresaId === emp.Id);
    })]
  }
}
