import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { FiltroMovimentoEstoqueAvulso } from 'src/app/classes/movimento-estoque/FiltroMovimentoEstoqueAvulso';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-movimento-estoque-avulso-filtro',
  templateUrl: './movimento-estoque-avulso-filtro.component.html',
  styleUrls: ['./movimento-estoque-avulso-filtro.component.scss']
})
export class MovimentoEstoqueAvulsoFiltroComponent implements OnInit {

  @Input() recolherFiltro: boolean = false;
  @Input() minHeight: number = 0;
  @Output() atualizarLista: EventEmitter<FiltroMovimentoEstoqueAvulso> = new EventEmitter();

  filtro: FiltroMovimentoEstoqueAvulso = new FiltroMovimentoEstoqueAvulso();
  locaisEstoque: LocalEstoque[] = [];

  constructor(
    private localEstoqueService: LocalEstoqueService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.localEstoqueService.getLocaisEstoque().subscribe(
      res => this.locaisEstoque = res,
      err => this.utilsService.getErro(err)
    );
    this.atualizarLista.emit(this.filtro);
  }

  limparFiltros() {
    this.filtro = new FiltroMovimentoEstoqueAvulso();
  }

  filtrarClick() {
    this.atualizarLista.emit(this.filtro);
  }
}
