export class Produto {
    Id: number;
    Descricao:string = "";
    EmpresaId: number;
    GrupoProdutoId: number;
    GrupoProduto:string;
    Tipo: number = 0;
    Formulacao:string = "";
    Fabricante:string = "";
    UnidadeMedida:string = "";
    PrecoUnitario: number = 0;
    Observacao:string = "";
    IdERP:string = "";
    Cultivar: boolean;
    PrincipioAtivo:string = "";
    DesconsiderarEmRelEspecifico: boolean;
    CultivarId: number;
    NaoControlaEstoque: boolean;
    FlagInativo: boolean;
    IsTerceiro: boolean;
    Classificacao: enumClassificacaoProduto;
    NaturezaId: number;
    CodDescricao: string;
}

export enum enumTipo
{
    Produto = 1,
    Servico = 2
}

export enum enumClassificacaoProduto
{
    Insumos = 1,
    Adubos = 2,
    Combustivel = 3,
    Pecas = 4,
    Patrimonio = 5
}
