import { OrdemCompraService } from './../../../services/cotacao/OrdemCompraService';
import { OrdemCompra } from './../../../classes/cotacao/OrdemCompra';
import { environment } from 'src/environments/environment';
import { UsuarioService } from './../../../services/usuario.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Usuario } from 'src/app/classes/Usuario';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { OrdemCompraTranslate } from './ordem-compra.translate';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ordem-compra',
  templateUrl: './ordem-compra.component.html',
  styleUrls: ['./ordem-compra.component.scss']
})
export class OrdemCompraComponent extends OrdemCompraTranslate implements OnInit {
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;
  @ViewChild('ModalObs', { static: false })
  ModalObs: UiModalComponent;

  textoObs: string = "";
  OrdemCompra: OrdemCompra[] = [];
  ordemCompra: OrdemCompra;
  usuarios: Usuario[] = [];
  id: number = 0;
  ordemCompraId: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  data: Date = new Date();
  usuariId: number = 0;
  modalTitulo: string = "";
  op: number = 0;
  constructor(private OrdemCompraService: OrdemCompraService,
    private UsuarioService: UsuarioService,
    private route: ActivatedRoute,
    private toasty: CustomToastyService,
    private translate: TranslateService) {
      super(translate)
     }

  ngOnInit() {
    this.UsuarioService.getUsuarios().subscribe(
      res => {
        this.usuarios = res;
      });
    this.listar();
  }
  listar() {
    this.route.params.subscribe(res => {
      this.id = res.id;
      this.OrdemCompraService.getPorCotacao(this.id).subscribe(ret => {
        this.OrdemCompra = ret;
        console.log(ret);
      });
    });
  }
  imprimir(id: number) {
    window.open(`${environment.apiURL}/OrdemCompra/Pdf/${id}`, "_blank");
  }
  FinalizarConfirm() {
    Swal.fire({
      title: this.FINALIZAR_ESSA_ORDEM_DE_COMPRA,
      text: this.ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        let dt: string = "";
        dt = this.data.toDateString();
        this.OrdemCompraService.Finaliza(this.ordemCompraId, dt, this.usuariId).subscribe(ret => {
          this.fecharModal();
        });
      }
    });
  }
  PagamentoConfirm() {
    Swal.fire({
      title: this.INDICAR_PAGAMENTO_A_ESSA_ORDEM_DE_COMPRA,
      text: this.ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        let dt: string = "";
        dt = this.data.toDateString();
        this.OrdemCompraService.GerarPagamento(this.ordemCompraId, dt, this.usuariId).subscribe(ret => {
          this.fecharModal();
        });
      }
    });
  }
  ConferenciaConfirm() {
    Swal.fire({
      title: this.REALIZAR_CONFERENCIA_DOS_PRODUTOS_DESSA_ORDEM_DE_COMPRA,
      text: this.ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        let dt: string = "";
        dt = this.data.toDateString();
        this.OrdemCompraService.EmConferencia(this.ordemCompraId, dt, this.usuariId).subscribe(ret => {
          this.fecharModal();
        });
      }
    });
  }
  fecharModal() {
    this.Modal.hide();
    this.usuariId = 0;
    this.data = new Date();
    this.listar();
  }
  abreModal(opModal: number, idOrdemCompra: number) {
    this.op = opModal;
    this.ordemCompraId = idOrdemCompra;
    if (opModal == 1)
      this.modalTitulo = this.INDICACAO_DE_PAGAMENTO;
    else if (opModal == 2)
      this.modalTitulo = this.CONFERENCIA_DE_PRODUTOS;
    else if (opModal == 3)
      this.modalTitulo = this.FINALIZAR_ORDEM_DE_COMPRA;
    this.Modal.show();
  }
  abreModalObs(opModal: number, idOrdemCompra: number) {
    this.op = opModal;
    this.ordemCompraId = idOrdemCompra;
    this.OrdemCompraService.getPorId(this.ordemCompraId).subscribe(ret => {
      this.ordemCompra = ret;
      if (opModal == 1) {
        this.textoObs = this.ordemCompra.Observacao;
        this.modalTitulo = this.OBSERVACOES;
      }
      else if (opModal == 2) {
        this.textoObs = this.ordemCompra.ObservacaoAprovacao;
        this.modalTitulo = this.OBSERVACOES_DE_APROVACAO;
      }
    });
    this.ModalObs.show();
  }
  fechaModalObs() {
    this.textoObs = "";
    this.ModalObs.hide();
  }
  cadastraObs() {
    this.OrdemCompraService.getPorId(this.ordemCompraId).subscribe(ret => {
      this.ordemCompra = ret;
      if (this.op == 1)
        this.ordemCompra.Observacao = this.textoObs;
      else if (this.op == 2)
        this.ordemCompra.ObservacaoAprovacao = this.textoObs;
      this.OrdemCompraService.Observacoes(this.ordemCompraId,this.ordemCompra).subscribe(ret => {
        this.listar();
        this.toasty.show(this.FEITO, this.OBSERVACAO_ALTERADACADASTRADA, 'success');
        this.fechaModalObs();
      });
    });
  }
}
