import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TreeItem } from 'src/app/classes/TreeItem';

@Component({
  selector: 'app-tree-view-analitico',
  templateUrl: './tree-view-analitico.component.html',
  styleUrls: ['./tree-view-analitico.component.scss']
})
export class TreeViewAnaliticoComponent implements OnInit {
  @Input() obj: any[] = [];
  @Input() rotaBase: string;
  @Output() selecionarId = new EventEmitter<number>(); 

  mostrarArvore: boolean = false;
  items: TreeItem[] = []

  constructor() {}

  ngOnInit() {
    this.obj.forEach((n) => {
      let as: TreeItem = {
        Id: n.Id,
        Codigo: n.Codigo,
        CodigoPai: n.CodigoPai,
        Descricao: n.Descricao,
        IsAnalitico: n.IsAnalitico,
      }
      this.items.push(as)
    });

    this.mostrarArvore = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    let id = sessionStorage.getItem("ObjClickID");
    sessionStorage.setItem("ObjClickID", "");

    if(id) this.selecionarId.emit(Number(id));
  }
}
