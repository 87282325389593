import { v4 as uuidv4 } from 'uuid';

export class Parceiro {
  Id: string = uuidv4();
  Documento:string = "";
  RazaoSocial:string = "";
  CEP:string = "";
  UF:string = "";
  Cidade:string = "";
  Bairro:string = "";
  Endereco:string = "";
  Numero:string = "";
  Referencia:string = "";
  Contato:string = "";
  Telefone:string = "";
  Situacao: boolean = true;
  AreaTotal: number = 0;
}

