import { StorageService } from 'src/app/services/storage.service';
import { FazendaService } from './../../../services/fazenda.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Fazenda } from 'src/app/classes/Fazenda';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-select-fazenda',
  templateUrl: './select-fazenda.component.html',
  styleUrls: ['./select-fazenda.component.scss']
})
export class SelectFazendaComponent implements OnInit {

  @Input() isSubmit: boolean;
  @Input() fazendaId: number = 0;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() auxClass: string;
  @Input() ocultarMsgObrigatorio: boolean = false;
  @Output() fazendaChange: EventEmitter<number> = new EventEmitter();
  @Input() fazendas: Fazenda[];
  @Input() hideLabel: boolean;
  @Input() hideIcone: boolean;
  @Input() forcarTodas: boolean;

  constructor(private fazendaService: FazendaService,
              private storageService: StorageService,
              private utilsService: UtilsService) { }

  ngOnInit() {
    if(!this.fazendaId)
    {
      this.fazendaId = this.storageService.getFazenda() ? this.storageService.getFazenda().Id : 0;
      this.fazendaChange.emit(this.fazendaId);
    }

    if(!this.fazendas){
      this.fazendaService.getFazendas().subscribe(ret =>{
        this.fazendas = ret;
      }, err =>{
        this.utilsService.getErro(err);
      });
    }

    let usuario  = this.storageService.getUsuario();
    if(!usuario.PermissaoUsuario.AcessoTodasFazendas && !this.forcarTodas){
      this.fazendas = [];
      this.fazendas.push(this.storageService.getFazenda());
      this.disabled = true;
    }
  }

  onChange(){
    this.fazendaChange.emit(this.fazendaId);
  }

}
