import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Area } from 'src/app/classes/Area';
import { Cultivar } from 'src/app/classes/Cultivar';
import { Cultura } from 'src/app/classes/Cultura';
import { Planejamento } from 'src/app/classes/Planejamento';
import { CultivarService } from 'src/app/services/cultivar.service';
import { CulturaService } from 'src/app/services/cultura.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-area-config-modal',
  templateUrl: './area-config-modal.component.html',
  styles: []
})
export class AreaConfigModalComponent implements OnInit, OnChanges {

  @Input() item: Area = new Area();
  @Input() areas: Area[] = [];
  @Input() isEdit: boolean = false;
  @Input() modalOpened: EventEmitter<void>;
  @ViewChild('Modal', { static: false }) Modal: UiModalComponent;

  @Output() areaConfiguradaEvent = new EventEmitter<Area>();

  culturas: Cultura[] = [];
  planejamentos: Planejamento[] = []
  variedades: Cultivar[] = [];
  cultivar: Cultivar | null;
  isSubmit = false;
  areaInicial: Area;
  AreaUtilizadaAtual: number;
  constructor(
    private culturaService: CulturaService,
    private planejamentoService: PlanejamentoService,
    private cultivarService: CultivarService,
    private utils: UtilsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && changes.item.currentValue) {
      this.item = JSON.parse(JSON.stringify(changes.item.currentValue));
    }
    if (changes.areas && changes.areas.currentValue) {
      this.areas = JSON.parse(JSON.stringify(changes.areas.currentValue));
    }
    if (changes.isEdit && changes.isEdit.currentValue) {
      this.isEdit = JSON.parse(JSON.stringify(changes.isEdit.currentValue));
    }
    this.onModalOpened();
  }

  ngOnInit() {
    this.culturaService.getCulturasPlanejadas().subscribe(res => {
      this.culturas = res;
    }, err => this.utils.getErro(err));
    if (this.modalOpened) {
      this.modalOpened.subscribe(() => {
        // Esta função será chamada sempre que o modal for aberto
        this.onModalOpened();
      });
    }
  }

  onModalOpened() {
    if (this.isEdit) {
      this.AreaUtilizadaAtual = this.item.AreaUtilizada;
    } else {
      this.AreaUtilizadaAtual = this.item.AreaDisponivel;
    }

    this.areaInicial = JSON.parse(JSON.stringify(this.item));
    if (this.item.CulturaId != null && this.item.CulturaId > 0) {
      this.listarPlanejamentos(false);
    }
  }

  listarPlanejamentos(removerSelecao = true) {
    if (this.item.CulturaId == null) {
      this.planejamentos = [];
      return;
    }
    this.planejamentoService.listarPlanosMestrePorCultura(this.item.CulturaId).subscribe(res => {
      this.planejamentos = res;
      if (removerSelecao) {
        this.areaInicial.PlanoMestreProducaoId = this.item.PlanoMestreProducaoId;
        this.areaInicial.VariedadeId = this.item.VariedadeId;
        this.item.PlanoMestreProducaoId = null;
        this.item.VariedadeId = null;
        this.variedades = [];
      }
      else {
        if (this.item.PlanoMestreProducaoId != null && this.item.PlanoMestreProducaoId > 0) {
          let planejamento = this.planejamentos.find(x => x.Id == this.item.PlanoMestreProducaoId);
          this.getVariedadesPorPlanejamento(planejamento);
        }
      }
    }, err => this.utils.getErro(err))
  }

  getVariedadesPorPlanejamento(planejamento: Planejamento) {
    if (planejamento == null) {
      this.variedades = [];
      return;
    }
    this.cultivarService.getCultivarPorCultivarBase(planejamento.Cultivar.Id).subscribe(res => {
      this.variedades = res;
    }, err => this.utils.getErro(err))
  }

  changeCultivar(cultivarId: number) {
    if (cultivarId == null) {
      this.cultivar = null;
      return;
    }
    this.cultivarService.getCultivar(cultivarId).subscribe(res => {
      this.cultivar = res;
    }, err => this.utils.getErro(err))
  }

  changePrevisaoPlantio(dataPrevisao: Date) {
    if (!dataPrevisao) return;
    if (!this.cultivar) return;
    if (this.cultivar.Ciclo <= 0) return;
    this.item.DataPrevistaColheita = new Date(dataPrevisao.getTime() + (this.cultivar.Ciclo * 24 * 60 * 60 * 1000));
  }

  save(form: any) {
    const result = this.validarSafra();
    if (result.length > 0) {
      this.utils.handleWarning(result);
      return;
    }
    this.item.AreaUtilizada = this.AreaUtilizadaAtual;
    this.item.Selecionada = true;
    this.areaConfiguradaEvent.emit(this.item);
    this.Modal.hide();
  }


  fechar() {
    this.planejamentos = [];
    this.variedades = [];
    this.cultivar = null;
    this.item = this.areaInicial;
    this.Modal.hide();
  }

  private validarSafra(): string {
    if (this.areaUtilizadaMaiorDisponivel()) {
      return `A área plantada tem que ser menor ou igual a área disponível: ${this.item.AreaDisponivel}`;
    }
    if (this.item.CulturaId == null) {
      return `Selecione uma Cultura`;
    }
    if (this.item.PlanoMestreProducaoId == null) {
      return `Selecione um Plano Mestre`;
    }
    if (this.item.VariedadeId == null) {
      return `Selecione uma Variedade`;
    }
    if (this.item.DataPrevistaPlantio == null) {
      return `Preencha o campo Previsão de Plantio`;
    }
    if (this.item.DataPrevistaColheita == null) {
      return `Preencha o campo Previsão de Colheita`;
    }
    if (this.AreaUtilizadaAtual <= 0) {
      return `Preencha o campo Área Plantada com um valor maior que zero`;
    }
    return '';
  }

  private areaUtilizadaMaiorDisponivel(): boolean {
    let utilizada = 0;
    for (let area of this.areas) {
      utilizada += area.AreaUtilizada;
    }
    if (this.isEdit) {
      if ((utilizada - this.item.AreaUtilizada + this.AreaUtilizadaAtual) > this.item.AreaTotal)
        return true;
    } else {
      if ((utilizada + this.AreaUtilizadaAtual) > this.item.AreaTotal)
        return true;
    }

    return false;
  }

}
