import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  constructor() { }
  @Input() colDefs: ColDef[] = [];
  @Input() rowData: any[] = [];
  @Input() pagination: false;
  @Input() pageSize: number = 10;
  @Input() groupDefaultExpanded: any; // nao esta implementado

  @Input() changePageSize: boolean = true;

  @Output() cellClicked = new EventEmitter();
  @Output() cellDoubleClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();

  @Input() rowSelection: string = 'single'; // 'single' ou 'multiple'


  private _selectedRows: any[] = [];

  get selectedRows(): any[] {
    return this._selectedRows;
  }

  set selectedRows(value: any[]) {
    this._selectedRows = value;
    this.onSelectionChanged(value);
  }
  ngOnInit() {
    this.colDefs.forEach(col => {
      // if (!col.width)
      //   col.width = 150; // Valor padrão

      if (!col.headerName)
        col.headerName = col.field;
    });
  }

  onRowSelect(event: any) {
    //console.debug(this.selectedRows)
    this.cellClicked.emit(event);
  }
  onRowUnselect(event) {
    //console.debug(this.selectedRows)
  }
  onRowDoubleClick(event: any) {
    this.cellDoubleClicked.emit({ data: event });
  }
  onSelectionChanged(event: any) {
    this.selectionChanged.emit(event);
  }


}
