import { Component, OnInit } from '@angular/core';
import { AnoAgricola } from 'src/app/classes/AnoAgricola';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { AnoAgricolaService } from 'src/app/services/ano-agricola.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ano-agricola',
  templateUrl: './ano-agricola.component.html',
  styleUrls: ['./ano-agricola.component.scss']
})
export class AnoAgricolaComponent implements OnInit {

  anoAgricolas: AnoAgricola[];
  auxAnoAgricolas: AnoAgricola[];
  anoAgricola: AnoAgricola;
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  // tradução
  ERRO: string = 'Erro!';
  FEITO: string = 'Feito!';
  ANO_AGRICOLA_ALTERADO: string = 'Ano Agricola alterado!';
  ANO_AGRICOLA_CADASTRADO: string = 'Ano Agricola cadastrado!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  ANO_AGRICOLA_DELETADO: string = 'Ano Agricola deletado!';

  constructor(private AnoAgricolasService: AnoAgricolaService,
    private route: ActivatedRoute, private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private translate: TranslateService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.anoAgricola = new AnoAgricola();

    if (this.id > 0)
      this.selecionarAnoAgricola();
    else
      this.listarAnoAgricola();
  }

  selecionarAnoAgricola() {
    this.carregando = true;
    this.configurarTraducao();
    this.AnoAgricolasService.getAnoAgricola(this.id).subscribe(res => {
      this.anoAgricola = new AnoAgricola();
      this.anoAgricola = res;
      this.carregando = false;
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarAnoAgricola() {
    this.carregando = true;
    this.configurarTraducao();
    this.AnoAgricolasService.getAnosAgricolas().subscribe(
      res => {
        this.anoAgricolas = res;
        this.auxAnoAgricolas = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarAnoAgricola();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.configurarTraducao();

    if (this.anoAgricola.Id > 0)
      this.AnoAgricolasService.putAnoAgricola(this.anoAgricola.Id, this.anoAgricola).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ANO_AGRICOLA_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.AnoAgricolasService.postAnoAgricola(this.anoAgricola).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ANO_AGRICOLA_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(AnoAgricolaId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.AnoAgricolasService.deleteAnoAgricola(AnoAgricolaId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.ANO_AGRICOLA_DELETADO, 'success');
            this.listarAnoAgricola();
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }

  filtrarAnoAgricola() {
    this.anoAgricolas = this.auxAnoAgricolas.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
    );
  }

  cancelar() {
    this.router.navigate(['/gerenciador/ano-agricola']);
  }
  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.FEITO = this.translate.instant('FEITO');
    this.ANO_AGRICOLA_ALTERADO = this.translate.instant('ANO_AGRICOLA_ALTERADO');
    this.ANO_AGRICOLA_CADASTRADO = this.translate.instant('ANO_AGRICOLA_CADASTRADO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.ANO_AGRICOLA_DELETADO = this.translate.instant('ANO_AGRICOLA_DELETADO');
  }
}
