import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDatepickerModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AnimationService, AnimatorModule } from 'css-animator';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { LightboxModule } from 'ngx-lightbox';
import { NaturezaComponent } from './natureza.component';
import { CardModule } from 'src/app/theme/shared/components';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CustomFormsModule } from 'ng2-validation';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { NgxMaskModule } from 'ngx-mask';
import { SharedFarmModule } from '../../shared-farm/shared-farm.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsNaturezaComponent } from './forms-natureza/forms-natureza.component';




@NgModule({
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SharedFarmModule,
    NgSelectModule,
  ],
  declarations: [NaturezaComponent, FormsNaturezaComponent],
  exports: [NaturezaComponent, FormsNaturezaComponent],

})
export class NaturezaModule { }
