import { UtilsService } from './../../utils/utils.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { StorageService } from './../../services/storage.service';
import { AreaService } from './../../services/area.service';
import { GrupoOperacaoService } from './../../services/grupo-operacao.service';
import { GrupoOperacao } from './../../classes/GrupoOperacao';
import { SafraService } from './../../services/safra.service';
import { Safra } from './../../classes/Safra';
import { Fazenda } from './../../classes/Fazenda';
import { FazendaService } from './../../services/fazenda.service';
import { Router } from '@angular/router';
import { CustomToastyService } from './../../theme/shared/components/toasty/custom-toasty.service';
import Swal from 'sweetalert2';
import { OrdemServico, StatusOS, TipoOS } from './../../classes/OrdemServico';
import { Subscription } from 'rxjs';
import { Empresa } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { EnumStatusOS } from 'src/app/classes/OrdemServico';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { AfterViewInit, OnDestroy, HostListener, Component, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import sortyBy from 'sort-by';

import { Area } from 'src/app/classes/Area';
import { Cultura } from 'src/app/classes/Cultura';

import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.scss']
})
export class PainelComponent implements OnInit, OnChanges {

  countAgLiberacaoHoje: number = 0;
  countAgLiberacaoSemanal: number = 0;
  countConcluidoHoje: number = 0;
  countConcluidoSemanal: number = 0;

  idOSConfirmar: number = 0;
  idOSApontamento: number = 0;
  ordemServicoApontamento: OrdemServico;
  idOSAbrir: number = 0;
  ordensServico: OrdemServico[] = [];

  empresaSelecionada: Empresa;
  sub: Subscription;

  fazendas: Fazenda[];
  fazendaId: number = 0;
  fazendaSelecionada: Fazenda;
  safras: Safra[];
  auxSafras: Safra[];
  safraId: number = 0;
  gruposOperacao: GrupoOperacao[];
  grupoOperacaoId: number = 0;

  statusOS = StatusOS;
  status: number = 0;
  tipo: number = 0;
  tipoOS = TipoOS;


  areas: Area[];
  carregando: boolean;
  isAdmin: boolean;
  culturas: Cultura[];
  culturaSelecionadaId: number = 0;

  //mensagens
  feitoMessage: string = "Feito";
  ordemServicoConfirmadaMessage: string = "Ordem de serviço confirmada!";
  ordemServicoNaoEncontradaMessage: string = "Ordem de serviço confirmada!";
  visaoGeralOperacoes: string = ""
  constructor(
    private ordemServicoService: OrdemServicoService,
    private toasty: CustomToastyService,
    private empresaService: EmpresaService,
    private router: Router,
    private fazendaService: FazendaService,
    private safraService: SafraService,
    private grupoOperacaoService: GrupoOperacaoService,
    private areaService: AreaService,
    private storageService: StorageService,
    private planejamentoService: PlanejamentoService,
    private utils: UtilsService,
    private translate: TranslateService
  ) { }  


  ngOnInit() {    

    this.translate.get("FEITO").subscribe(res => {
      this.feitoMessage = res;
    });
    this.translate.get("ORDEM_SERVICO_CONFIRMADA").subscribe(res => {
      this.ordemServicoConfirmadaMessage = res;
    });
    this.translate.get("ORDEM_SERVICO_NAO_ENCONTRADA").subscribe(res => {
      this.ordemServicoNaoEncontradaMessage = res;
    });
    this.translate.get("VISAO_GERAL_OPERACOES").subscribe(res => {
      this.visaoGeralOperacoes = res;
    });
    this.loadDados();
    this.sub = this.empresaService.selecionarEmpresaEmitter.subscribe(() => {
      this.loadDados();
    });

    this.fazendaService.getFazendas().subscribe(ret => {
      this.fazendas = ret;
      this.fazendaId = this.storageService.getFazenda() ? this.storageService.getFazenda().Id : 0;
      if (!this.fazendaId)
        this.fazendaId = (this.fazendas && this.fazendas.length > 0 ? this.fazendas[0].Id : 0);

      this.selecionarFazenda(this.fazendaId);
    },
      err => {
        this.utils.getErro(err);
      });

    this.safraService.getSafras().subscribe(ret => {
      this.auxSafras = ret;
      this.safras = this.auxSafras;
    },
      err => {
        this.utils.getErro(err);
      })

    this.grupoOperacaoService.getGruposOperacao().subscribe(ret => {
      this.gruposOperacao = ret;
      this.grupoOperacaoId = 0;
    },
      err => {
        this.utils.getErro(err);
      })

    this.planejamentoService.getCulturas().subscribe(ret => {
      this.culturas = ret;
      this.culturaSelecionadaId = 0;
    },
      err => {
        this.utils.getErro(err);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadDados();
  }

  loadDados() {
    this.isAdmin = !this.storageService.getEmpresa() || this.storageService.getEmpresa().Id == 1;
    this.empresaSelecionada = this.empresaService.getEmpresaSelecionada();

    if (this.empresaSelecionada && this.empresaSelecionada.Id > 1) {
      this.ordemServicoService.count(EnumStatusOS.AguardandoLiberacao, 1).subscribe(ret => {
        this.countAgLiberacaoHoje = ret;
      },
        err => {
          this.utils.getErro(err);
        });
      this.ordemServicoService.count(EnumStatusOS.AguardandoLiberacao, 2).subscribe(ret => {
        this.countAgLiberacaoSemanal = ret;
      },
        err => {
          this.utils.getErro(err);
        });
      this.ordemServicoService.count(EnumStatusOS.Concluida, 1).subscribe(ret => {
        this.countConcluidoHoje = ret;
      },
        err => {
          this.utils.getErro(err);
        });
      this.ordemServicoService.count(EnumStatusOS.Concluida, 2).subscribe(ret => {
        this.countConcluidoSemanal = ret;
      },
        err => {
          this.utils.getErro(err);
        });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  apontamento(event?: any) {
    if (!event || event.keyCode == 13) {
      //this.carregando = true;
      this.ordemServicoService.getOrdemServico(this.idOSApontamento).subscribe(res => {
        this.ordemServicoApontamento = res;

      },
      ex =>{
        Swal.fire('Erro!', this.ordemServicoNaoEncontradaMessage, 'error');
        //this.carregando = false;
      })
    }
  }

  confirmar(event?: any) {
    if (!event || event.keyCode == 13) {
      Swal.fire({
        title: 'Confirmar a OS R' + this.idOSConfirmar.toString().padStart(5, '0') + '?',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim',
      }).then((willDelete) => {
        if (willDelete.dismiss) {

          } else {
            let ids = [this.idOSConfirmar];
            //this.carregando = true;
            this.ordemServicoService.confirmar(ids).subscribe(
              res => {
                //this.carregando = false;
                this.toasty.show(`${this.feitoMessage}!`, this.ordemServicoConfirmadaMessage, 'success');
              },
              err=>{
                this.utils.getErro(err);
              });
          }
        });
        return false;
    }
  }

  abrirOS(event) {
    if (!event || event.keyCode == 13) {
      this.ordemServicoService.getOrdemServico(this.idOSAbrir).subscribe(res => {
        this.router.navigate(['/ordem-servico/ordem-servico/' + this.idOSAbrir]);
      },
      ex =>{
        Swal.fire('Erro!', this.ordemServicoNaoEncontradaMessage, 'error');
      })
    }
  }

  selecionarFazenda(id: number) {
    this.fazendaId = id;
    //this.safras = this.auxSafras.filter(x => x.FazendaId == this.fazendaId);
    this.fazendaService.getFazenda(this.fazendaId).subscribe(ret => {
      this.fazendaSelecionada = ret;
      this.areaService.getAreasPorFazenda(this.fazendaId).subscribe(
        retArea => {
          this.areas = retArea;
          this.areas = this.areas.sort((a, b) => a.Descricao.localeCompare(b.Descricao));


          this.safraService.getSafrasPorAnoFazenda(0, this.fazendaId).subscribe(
            retSafra => {
              this.auxSafras = retSafra;
              this.safras = this.auxSafras;
              this.safraId = (this.safras.length > 0 ? this.safras[0].Id : 0);

              this.carregarDetalhamento();
            }
          )
        }
      )
    })
  }

  quebraLinha(texto: string): string {
    return texto.replace(" ", "\n");
  }

  selecionarSafra() {
    this.carregarDetalhamento();
  }



  selecionaOperacao() {
    this.carregarDetalhamento();
  }

  carregarDetalhamento() {
    let filtros: any = {};
    let fazendasFiltro: number[] = [];
    let safrasFiltro: number[] = [];
    let operacoes: number[] = [];
    let dataInicio = new Date();
    let dataFim = new Date();
    let status: EnumStatusOS[] = [];
    dataFim.setDate(dataInicio.getDate() + 365);
    dataInicio.setDate(dataInicio.getDate() - 365);
    status.push(EnumStatusOS.Cancelada);

    fazendasFiltro.push(this.fazendaId);
    safrasFiltro.push(this.safraId);

    if (this.grupoOperacaoId > 0)
      operacoes.push(this.grupoOperacaoId);

    filtros.Fazenda = fazendasFiltro;
    filtros.Safra = safrasFiltro;
    filtros.AgruparPorArea = true;
    filtros.dataInicio = dataInicio;
    filtros.dataFim = dataFim;
    filtros.Operacao = operacoes;
    filtros.Status = status;

    if (this.status == 0) {
      filtros.StatusNotIn = true;
    } else {
      filtros.StatusNotIn = false;
      status.length = 0;
      status.push(this.status)
    }

    filtros.Tipo = this.tipo;

    this.ordemServicoService.detalhamento(filtros).subscribe(
      ret => {
        this.ordensServico = ret;
        this.ordensServico = this.ordensServico.sort(sortyBy('DataPrevista'));
        this.areas.forEach(
          x => {
            x.Selecionada = this.ordensServico.filter(o => o.AreaId == x.Id).length > 0;
          });
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

}
