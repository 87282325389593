import { OrdemCompra } from './../../classes/cotacao/OrdemCompra';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrdemCompraService {

  constructor(private http: HttpClient) { }

  get(): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra`);
  }

  getPorId(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/${id}`);
  }

  post(ordemCompra: OrdemCompra): Observable<any> {
    return this.http.post(`${environment.apiURL}/OrdemCompra`, ordemCompra);
  }

  put(id: number, ordemCompra: OrdemCompra): Observable<any> {
    return this.http.put(`${environment.apiURL}/OrdemCompra/${id}`, ordemCompra);
  }
  Observacoes(id:number, ordemCompra: OrdemCompra): Observable<any> {
    return this.http.put(`${environment.apiURL}/OrdemCompra/Observacao/${id}`, ordemCompra);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/OrdemCompra/${id}`);
  }

  selecionaPorPeriodo(dataInicial: Date, dataFinal: Date): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/Periodo/${dataInicial}/${dataFinal}`);
  }
  selecionaPorPeriodoInclude(dataInicial: Date, dataFinal: Date): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/PeriodoComInclude/${dataInicial}/${dataFinal}`);
  }
  getInclude(): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/Include`);
  }
  getPorIdInclude(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/Include/${id}`);
  }
  getPorIdIncludeFornecedor(id: number, idFornecedor:number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/cotacaoForcedor/${id}/${idFornecedor}`);
  }
  getPorFornecedor(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/fornecedor/${id}`);
  }
  getPorCotacao(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/Cotacao/${id}`);
  }
  GerarPagamento(id: number, Data:string, UsuarioId:number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/GerarPagamento/${id}/${Data}/${UsuarioId}`);
  }
  GerarNf(id: number, Data:string, numNf:string, Caminho:string): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/GerarNf/${id}/${Data}/${numNf}/${Caminho}`);
  }
  EmTransporte(id: number, Data:string): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/EmTransporte/${id}/${Data}`);
  }
  EmConferencia(id: number, Data:string, UsuarioId:number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/EmConferencia/${id}/${Data}/${UsuarioId}`);
  }
  Finaliza(id: number, Data:string, UsuarioId:number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/Finaliza/${id}/${Data}/${UsuarioId}`);
  }
  getPdf(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/OrdemCompra/Pdf/${id}`);
  }
}
