import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CulturaService } from 'src/app/services/cultura.service';
import { AnoSafraService } from 'src/app/services/ano-safra.service';
import { AreaService } from 'src/app/services/area.service';
import { SafraService } from 'src/app/services/safra.service';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { Area } from 'src/app/classes/Area';
import { Safra } from 'src/app/classes/Safra';
import { Cultura } from 'src/app/classes/Cultura';
import { StorageService } from 'src/app/services/storage.service';
import { SetorService } from 'src/app/services/setor.service';
import { Setor } from 'src/app/classes/Setor';
import { Equipamento } from 'src/app/classes/Equipamento';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/classes/Usuario';

@Component({
  selector: 'app-filtro-relatorios',
  templateUrl: './filtro-relatorios.component.html',
  styleUrls: ['./filtro-relatorios.component.scss']
})
export class FiltroRelatoriosComponent implements OnInit {
  @Input() botoes: any = { 'AnoSafra': true, 'Safra': true, 'Cultura': true, 'Setor': true, 'Area': true, 'DataInicio': true, 'DataFim': true, 'Equipamento':true, 'Talhao':true, 'Operador':true };
  @Input() filtrarPeriodo: boolean;
  constructor(
    private culturaService: CulturaService,
    private anoSafraService: AnoSafraService,
    private areaService: AreaService,
    private setorService: SetorService,
    private safraService: SafraService,
    private equipamentoService:EquipamentoService,
    private usuarioService:UsuarioService,
    private storageService: StorageService
  ) { }

  @Output() filtrarEvento = new EventEmitter<FiltroRelatorio>();
  anosSafra: AnoSafra[];
  areas: Area[];
  auxAreas: Area[];
  areasSetor: Area[];
  safras: Safra[];
  culturas: Cultura[];
  setores: Setor[];
  equipamentos:Equipamento[]=[];
  operadores:Usuario[]=[];
  talhoes:any[]=[]
  auxSetores: Setor[];
  usaSetores: boolean;

  areaId: number = 0;
  setorId: number = 0;
  anoSafraId: number = 0;
  safraId: number = 0;
  culturaId: number = 0;
  equipamentoId:number;
  talhaoId:number;
  operadorId:number;
  dataInicio: Date;
  dataFim: Date;

  fazendaId: number = 0;

  ngOnInit() {
    this.usaSetores = this.storageService.getEmpresa().UsaSetores;

    this.definirDatas();

    this.fazendaId = this.storageService.getFazenda().Id;

    this.anoSafraService.getAnosSafras().subscribe(ret => {
      this.anosSafra = ret;
    });

    this.equipamentoService.getEquipamentos().subscribe(ret => {
      this.equipamentos = ret;
    });

    this.usuarioService.getUsuarios().subscribe(ret => {
      this.operadores = ret;
    });

    this.culturaService.getCulturas().subscribe(ret => {
      this.culturas = ret;
    });

    this.setorService.getSetores().subscribe(ret => {
      this.setores = ret;
      this.auxSetores = ret;
    });

    this.areaService.getAreas().subscribe(ret => {
      this.auxAreas = ret.filter(x => x.FazendaId == this.fazendaId);
    });

    this.safraService.getSafrasPorAnoFazenda(0, this.fazendaId).subscribe(ret => {
      this.safras = ret;
    });
  }

  filtrar(){

    const filtro: FiltroRelatorio = {
      fazendaId: this.fazendaId,
      areaId: this.areaId,
      anoSafraId: this.anoSafraId,
      safraId: this.safraId,
      culturaId: this.culturaId,
      dataInicio: this.dataInicio,
      dataFim: this.dataFim,
      setorId: this.setorId,
      equipamentoId:this.equipamentoId,
      talhaoId:0,
      operadorId: this.operadorId,
      filtrarPeriodo: this.filtrarPeriodo
    };

    this.filtrarEvento.emit(filtro);
  }

  filtrarAreasPorSetor() {
    this.auxAreas = this.setorId != null ? this.areas.filter(x => x.SetorId == this.setorId): this.areas;
    this.filtrar();
  }

  filtrarAreas() {
    if (this.safraId == null) {
      this.areaService.getAreas().subscribe(ret => {
        this.auxAreas = ret.filter(x => x.FazendaId == this.fazendaId);
      });

      this.filtrar();
    }

    else {
      this.areaService.getAreasPorSafra(this.safraId).subscribe(res => {
        this.auxAreas = res;
        this.areas = res;
        let ids = [];

        this.areas.forEach(area => {
          if(!ids.includes(area.SetorId)) ids.push(area.SetorId);
        });

        this.setores = this.auxSetores.filter(x => ids.includes(x.Id));

        this.filtrar();
      });
    }
  }

  filtrarSafra() {

    if (!this.anoSafraId && !this.culturaId) { //TODAS AS SAFRAS
      this.safraService.getSafrasPorAnoFazenda(0, this.fazendaId).subscribe(ret => {
        this.safras = ret;
      });
    }

    else if (!this.culturaId) { //FILTRAR POR ANO
      this.safraService.getSafrasPorAnoFazenda(this.anoSafraId, this.fazendaId).subscribe(ret => {
        this.safras = ret;
      });
    }

    else if (!this.anoSafraId) { //FILTRAR POR CULTURA
      this.safraService.getSafrasPorCultura(this.culturaId, this.fazendaId).subscribe(ret => {
        this.safras = ret;
      });
    }

    else { //FILTRAR POR ANO E CULTURA
      this.safraService.getSafrasPorAnoECultura(this.anoSafraId, this.culturaId, this.fazendaId).subscribe(ret => {
        this.safras = ret;
      });
    }
  }

  definirDatas() {
    if(this.filtrarPeriodo) {
      this.dataInicio = new Date();
      this.dataInicio.setHours(0, 0, 0, 0);
      this.dataInicio.setFullYear(this.dataInicio.getFullYear() - 1);
      this.dataFim = new Date();
      this.dataFim.setHours(0, 0, 0, 0);
    }
    else
    {
      this.dataFim = null;
      this.dataInicio = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.botoes) {
      this.areaId = null;
      this.anoSafraId = null;
      this.safraId = null;
      this.culturaId = null;

      this.definirDatas();

      const filtro: FiltroRelatorio = {
        fazendaId: this.fazendaId,
        areaId: this.areaId,
        anoSafraId: this.anoSafraId,
        safraId: this.safraId,
        culturaId: this.culturaId,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim,
        setorId: this.setorId,
        equipamentoId:this.equipamentoId,
        talhaoId:0,
        operadorId: this.operadorId,
        filtrarPeriodo: this.filtrarPeriodo
      };

      this.filtrarEvento.emit(filtro);
    }

    if(changes.filtrarPeriodo){
        this.definirDatas();
        const filtro: FiltroRelatorio = {
          fazendaId: this.fazendaId,
          areaId: this.areaId,
          anoSafraId: this.anoSafraId,
          safraId: this.safraId,
          culturaId: this.culturaId,
          dataInicio: this.dataInicio,
          dataFim: this.dataFim,
          setorId: this.setorId,
          equipamentoId:this.equipamentoId,
          talhaoId:0,
          operadorId: this.operadorId,
          filtrarPeriodo: this.filtrarPeriodo
        };
        this.filtrarEvento.emit(filtro);
    }
  }

  onAnoSafraChange(){
    this.safraId = null;
    this.culturaId = null;
    this.setorId = null;
    this.areaId = null;
    this.filtrarSafra();
  }

  onSafraChange(){
    this.culturaId = null;
    this.setorId = null;
    this.areaId = null;
    this.filtrarAreas();
  }

  onCulturaChange(){
    this.setorId = null;
    this.areaId = null;
    this.filtrarSafra();
  }

  onSetorChange(){
    this.areaId = null;
    this.filtrarAreasPorSetor();
  }

  onAreaChange(){
    this.filtrar();
  }

  onEquipamentoChange(){
    this.filtrar();
  }

  onOperadorChange(){
    this.filtrar();
  }

  onTalhaoChange(){
    this.filtrar();
  }
}

export interface FiltroRelatorio {
  fazendaId: number;
  areaId: number;
  anoSafraId: number;
  safraId: number;
  culturaId: number;
  dataInicio: Date;
  dataFim: Date;
  setorId: number;
  equipamentoId:number;
  talhaoId:number;
  operadorId:number;
  filtrarPeriodo: boolean;
}
