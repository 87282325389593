export class Bancos {
    Id: number;
    Descricao:string = "";
    CodFebraban:string = "";
    DescricaoCompleta:string = "";

    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
}
