import { ListaProdutoConsolidado } from "./ListaProdutoConsolidado";
import { Safra } from "./Safra";

export class ProdutoConsolidado{
  Id: number;
  Descricao:string = "";
  Observacao: string = "";
  ProdutoConsolidados: ListaProdutoConsolidado[] = [];
  QuantidadeProdutos: number = 0;
  Safras: Safra[] = [];
  QuantidadeSafras:number = 0;

  EmpresaId: number;
  DataCadastro: Date = new Date();
  DataAlteracao: Date = new Date();
  UsuarioCadastroId: number;
  UsuarioAlteracaoId: number;
}
