import { CentroDeResultados } from "./CentroDeResultados";
import { Natureza } from "./Natureza";
import { Projeto } from "./Projeto";

export class Rateio {
  Id: number;
  OrigemId: number;
  NaturezaId: number;
  Natureza: Natureza;
  CentroResultadoId: number;
  CentroDeResultado: CentroDeResultados;
  ProjetoId: number;
  Projeto: Projeto;
  SafraId: number;
  Percentual: number = 0;
  Valor: number = 0;
  GetNarueza:string = "";
  GetCentroResultado:string = "";
  GetProjeto:string = "";
  GetSafra:string = "";
}
