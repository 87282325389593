import {EnumStatusManutencao} from '../gestao-lite/AuxManutencaoFrota';
import {EnumTipoChecklist, EnumTipoIntervalo} from '../Checklist';
import {EnumOrigemManutencao} from '../gestao-lite/ManutencaoFrota';
export class FiltrosCustoManutencao {
    empresaId: number = 0;
    status: EnumStatusManutencao;
    tipo: EnumTipoChecklist;
    origem: EnumOrigemManutencao;
    tipoIntervalo: EnumTipoIntervalo;
    dataInicial: Date = new Date();
    dataFinal: Date = new Date();
    listaEquipamentos: number[] = [];
    listaFazendas: number[] = [];
    listaClasses: number[] = [];
    listaGrupos: number[] = [];
    tipoRelatorioCustoManutencao: EnumTipoRelatorioCustoManutencao;
}

export enum EnumTipoRelatorioCustoManutencao{
    NatTotal = 0,
    GrupoMaquinas = 1,
    Equipamentos = 2
}
