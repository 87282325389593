import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomFormsModule } from 'ng2-validation';
import { NgxMaskModule } from 'ngx-mask';
import { CardModule } from 'src/app/theme/shared/components';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { SharedFarmModule } from '../../shared-farm/shared-farm.module';
import { InserirEnderecoComponent } from './inserir-endereco/inserir-endereco.component';
import { ListarEnderecosComponent } from './listar-enderecos/listar-enderecos.component';
import { EnderecoFormInternoComponent } from './endereco-form-interno/endereco-form-interno.component';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SharedFarmModule,
    NgSelectModule,
  ],
  declarations: [InserirEnderecoComponent, ListarEnderecosComponent, EnderecoFormInternoComponent],
  exports: [InserirEnderecoComponent, ListarEnderecosComponent, EnderecoFormInternoComponent],
})
export class EnderecoModule { }
