import { ItemEnum } from "./ItemEnum";

export class OrdemServico {
  Id: number;
  Tipo: EnumTipoOS;
  DataPrevista: Date = new Date();
  DataPrevistaFinal: Date = new Date();
  DataRealizada: Date;
  DataRealizadaFinal: Date;
  AreaPrevista: number = 0;
  AtividadePlanejamentoId: number;
  PlanejamentoId: number;
  AtividadeId: number;
  TipoAplicacaoId: number;
  Status: EnumStatusOS;
  UsuarioAprovacaoId: number;
  Observacao:string = "";
  AreaPorTanque: number = 0;
  EtapaId: number;
  Vazao: number = 0;
  TipoBicoId: number;
  EquipamentoId: number;
  ImplementoId: number;
  FazendaId:number;
  SafraId:number;

  AreaId: number;
  DescricaoArea:string = "";
  DescricaoAreaGroupBy:string = "";
  DescricaoSafra:string = "";
  DescricaoSafraGroupBy:string = "";
  DescricaoFazenda:string = "";
  DescricaoEtapa:string = "";
  EmAtraso: boolean;
  Alvo:string = "";
  AgrupamentoId:number;
  OsImpressa: boolean;
  CulturaId: number;


  ProcessoPlanejamentoId: number;
  TemAdubacaoLinha: boolean;
  TemPulverizacaoSulco: boolean;
  TemTratamentoSementes:boolean = false;
  TaxaFixa: boolean;
  Espacamento: number = 0;
  SementeMt: number = 0;
  VelocidadePlantio: number = 0;
  PercentualIrrigacao: number = 0;
  PesoBalde:number = 0;
  LitrosPorCaldaHa: number = 0;
  QuantidadeLinha: number = 0;
  CapacidadeTanquePulvSulco: number = 0;
  KgSementeHa: number = 0;
  PesoSaco: number = 0;
  Lamina: number = 0;
  HorasExecutadas: number = 0;
  VolumeAgua: number = 0;
  VolumeAguaTanqueIncompleto: number = 0;
  TempoVoltaPivo: number = 0;
  VolumeLiquido: number = 0;
  QuantidadeSementePorVez: number = 0;
  Temperatura: number = 0;
  Umidade: number = 0;
  VelocidadeVento: number = 0;
  PhCalda: number = 0;

  AgruparFazenda: boolean;
  AgruparSafra: boolean;
  AgruparArea: boolean;
  AgruparEtapa: boolean;

  Checked: boolean;
  CollapsedFazenda: boolean;
  CollapsedSafra: boolean;
  CollapsedArea: boolean;
  CollapsedEtapa: boolean;

  TotalCusto:number = 0;
  DescricaoSetor:string = "";

  FuncionarioLiberacaoId:number;
  OperacaoAgricolaId:number;
  AreasMultiplas: number[] = [];
}

export enum EnumTipoOS
{
  Avulsa = 1,
  Planejada = 2
}

export enum EnumStatusOS
{
  Aberta = 1,
  AguardandoLiberacao = 2,
  Pendente = 3,
  ParcialmenteConcluida = 4,
  Concluida = 5,
  Cancelada = 6,
  Executado = 7,
}

export const StatusOS: ItemEnum[] = [
  {name: 'Aberta', value: EnumStatusOS.Aberta},
  {name: 'Aguardando Liberacao', value: EnumStatusOS.AguardandoLiberacao},
  {name: 'Pendente', value: EnumStatusOS.Pendente},
  {name: 'Parcialmente Concluída', value: EnumStatusOS.ParcialmenteConcluida},
  {name: 'Concluída', value: EnumStatusOS.Concluida},
  {name: 'Cancelada', value: EnumStatusOS.Cancelada},
  {name: 'Executado', value: EnumStatusOS.Executado}
];

export const TipoOS: ItemEnum[] = [
  {name: 'Avulsa', value: EnumTipoOS.Avulsa},
  {name: 'Planejada', value: EnumTipoOS.Planejada},
];
