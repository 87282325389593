import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgbButtonsModule, NgbCollapseModule, NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule , NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { ArchwizardModule } from 'ng2-archwizard/dist';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomFormsModule } from 'ng2-validation';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CpfCnpjPipe } from 'src/app/pipes/cpf-cnpj.pipe';
import { CustomDatepickerI18n, I18n } from 'src/app/services/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from 'src/app/services/NgbDatePTParserFormatter';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { SharedFarmModule } from '../shared-farm/shared-farm.module';
import { ErrorInfoComponent } from '../shared/error-info/error-info.component';
import { EscolhaEmpresaGridComponent } from '../shared/escolha-empresa-grid/escolha-empresa-grid.component';
import { AgenciaFormsComponent } from './agencia/agencia-forms/agencia-forms.component';
import { AgenciaComponent } from './agencia/agencia.component';
import { AlcadasAprovacaoComponent } from './alcadas-aprovacao/alcadas-aprovacao.component';
import { AreaImportComponent } from './area-import/area-import.component';
import { AtividadeComponent } from './atividade/atividade.component';
import { BancosFormsComponent } from './bancos/bancos-forms/bancos-forms.component';
import { BancosComponent } from './bancos/bancos.component';
import { CentralAprovacoesComponent } from './central-aprovacoes/central-aprovacoes.component';
import { ModalConfirmacaoComponent } from './central-aprovacoes/modal-confirmacao/modal-confirmacao.component';
import { CentroDeResultadosModule } from './centro-de-resultados/centro-de-resultados.module';
import { ChecklistModule } from './checklist/checklist.module';
import { ContaEnergiaComponent } from './conta-energia/conta-energia.component';
import { ContasBancariasFormsComponent } from './contas-bancarias/contas-bancarias-forms/contas-bancarias-forms.component';
import { ContasBancariasComponent } from './contas-bancarias/contas-bancarias.component';
import { CultivarComponent } from './cultivar/cultivar.component';
import { EmpresaInternaFormComponent } from './empresa-interna/empresa-interna-form/empresa-interna-form.component';
import { EmpresaInternaComponent } from './empresa-interna/empresa-interna.component';
import { EnderecoModule } from './endereco/endereco.module';
import { EtapaComponent } from './etapa/etapa.component';
import { ExibirNotasComponent } from './exibir-notas/exibir-notas.component';
import { FazendaComponent } from './fazenda/fazenda.component';
import { GrupoCompraModule } from './grupo-compra/grupo-compra.module';
import { GrupoOperacaoComponent } from './grupo-operacao/grupo-operacao.component';
import { GrupoTransacaoComponent } from './grupo-transacao/grupo-transacao.component';
import { IndicadoresFormComponent } from './indicadores/indicadores-form/indicadores-form.component';
import { IndicadoresComponent } from './indicadores/indicadores/indicadores.component';
import { MovimentoModule } from './movimento/movimento.module';
import { NaturezaModule } from './natureza/natureza.module';
import { ParceiroFormComponent } from './parceiro/parceiro-form/parceiro-form.component';
import { ParceiroComponent } from './parceiro/parceiro/parceiro.component';
import { AnexoPendenciaFrotaComponent } from './pendencia-frota/anexo-pendencia-frota/anexo-pendencia-frota.component';
import { PendenciaFrotaFormsComponent } from './pendencia-frota/pendencia-frota-forms/pendencia-frota-forms.component';
import { PendenciaFrotaComponent } from './pendencia-frota/pendencia-frota.component';
import { PessoaFormComponent } from './pessoa/pessoa-form/pessoa-form.component';
import { PessoaComponent } from './pessoa/pessoa.component';
import { ProcessoPlanejamentoComponent } from './processo-planejamento/processo-planejamento.component';
import { ProjetoModule } from './projeto/projeto.module';
import { RegionaisFormComponent } from './regionais/regionais-form/regionais-form.component';
import { RegionaisComponent } from './regionais/regionais/regionais.component';
import { TipoAplicacaoComponent } from './tipo-aplicacao/tipo-aplicacao.component';
import { TipoImprodutividadeComponent } from './tipo-improdutividade/tipo-improdutividade.component';
import { TiposDiagnoseFormComponent } from './tipos-diagnose/tipos-diagnose-form/tipos-diagnose-form.component';
import { TiposDiagnoseComponent } from './tipos-diagnose/tipos-diagnose/tipos-diagnose.component';
import { TransacaoFormsComponent } from './transacao/transacao-forms/transacao-forms.component';
import { TransacaoComponent } from './transacao/transacao.component';
import { UnidadeconsumidoraComponent } from './unidadeconsumidora/unidadeconsumidora.component';
import { PermissaoTransacaoComponent } from './usuario/permissao-transacao/permissao-transacao.component';
//import { SwitchPermissaoComponent } from './usuario/permissao-transacao/switch-permissao/switch-permissao.component';
import { UsuarioEmpresaComponent } from './usuario/usuario-empresa/usuario-empresa.component';
import { UsuarioFormComponent } from './usuario/usuario-form/usuario-form.component';
import { InputHoraComponent } from './usuario/usuario-horario-acesso/input-hora/input-hora.component';
import { UsuarioHorarioAcessoComponent } from './usuario/usuario-horario-acesso/usuario-horario-acesso.component';
import { UsuarioComponent } from './usuario/usuario.component';

import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalFormsComponent } from './local/local-forms/local-forms.component';
import { LocalComponent } from './local/local.component';
import { TipoDescontoProducaoComponent } from './tipo-desconto-producao/tipo-desconto-producao.component';
import { OficinaComponent } from './oficina/oficina.component';
import { OficinaFormsComponent } from './oficina/oficina-forms/oficina-forms.component';
import { ClasseComponent } from './classe/classe.component';
import { ClasseFormsComponent } from './classe/classe-forms/classe-forms.component';
import { SistemaComponent } from './sistema/sistema.component';
import { SistemaFormsComponent } from './sistema/sistema-forms/sistema-forms.component';
import { ItemSistemaComponent } from './sistema/sistema-forms/item-sistema/item-sistema.component';
import { ManutencaoOsComponent } from './manutencao-os/manutencao-os.component';
import { FormsManutencaoOsComponent } from './manutencao-os/forms-manutencao-os/forms-manutencao-os.component';
import { ModalConfirmacaoPendenciasComponent } from './manutencao-os/forms-manutencao-os/modal-confirmacao-pendencias/modal-confirmacao-pendencias.component';
import { ModalVisualizarEstoqueComponent } from './manutencao-os/forms-manutencao-os/modal-visualizar-estoque/modal-visualizar-estoque.component';
import { ModalCriarPendenciaComponent } from './manutencao-os/forms-manutencao-os/modal-criar-pendencia/modal-criar-pendencia.component';
import { ModalVisualizarAnexosPendenciasComponent } from './manutencao-os/forms-manutencao-os/modal-visualizar-anexos-pendencias/modal-visualizar-anexos-pendencias.component';
import { ModalVisualizarPlanoManutencaoComponent } from './manutencao-os/forms-manutencao-os/modal-visualizar-plano-manutencao/modal-visualizar-plano-manutencao.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [
    UsuarioComponent,
    FazendaComponent,
    UnidadeconsumidoraComponent,
    ContaEnergiaComponent,
    GrupoOperacaoComponent,
    AtividadeComponent,
    CultivarComponent,
    TipoAplicacaoComponent,
    EtapaComponent,
    GrupoTransacaoComponent,
    TipoImprodutividadeComponent,
    EmpresaInternaComponent,
    TransacaoComponent,
    PessoaComponent,
    CpfCnpjPipe,
    AlcadasAprovacaoComponent,
    TransacaoFormsComponent,
    AgenciaComponent,
    AgenciaFormsComponent,
    BancosComponent,
    BancosFormsComponent,
    ExibirNotasComponent,
    BancosComponent,
    BancosFormsComponent,
    ContasBancariasComponent,
    ContasBancariasFormsComponent,
    EmpresaInternaFormComponent,
    UsuarioEmpresaComponent,
    PermissaoTransacaoComponent,
    //SwitchPermissaoComponent,
    UsuarioFormComponent,
    AreaImportComponent,
    UsuarioHorarioAcessoComponent,
    InputHoraComponent,
    PessoaFormComponent,
    CentralAprovacoesComponent,
    ModalConfirmacaoComponent,
    ParceiroComponent,
    ParceiroFormComponent,
    ErrorInfoComponent,
    RegionaisComponent,
    RegionaisFormComponent,
    PendenciaFrotaComponent,
    AnexoPendenciaFrotaComponent,
    PendenciaFrotaFormsComponent,
    EscolhaEmpresaGridComponent,
    OficinaComponent,
    OficinaFormsComponent,
    ClasseComponent,
    ClasseFormsComponent,
    SistemaComponent,
    SistemaFormsComponent,
    ItemSistemaComponent,
    IndicadoresComponent,
    IndicadoresFormComponent,
    TiposDiagnoseComponent,
    TiposDiagnoseFormComponent,
    LocalComponent,
    LocalFormsComponent,
    ProcessoPlanejamentoComponent,
    TipoDescontoProducaoComponent,
    ManutencaoOsComponent,
    FormsManutencaoOsComponent,
    ModalConfirmacaoPendenciasComponent,
    ModalVisualizarEstoqueComponent,
    ModalCriarPendenciaComponent,
    ModalVisualizarAnexosPendenciasComponent,
    ModalVisualizarPlanoManutencaoComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SharedFarmModule,
    NgSelectModule,
    NgbTabsetModule,
    NaturezaModule,
    CentroDeResultadosModule,
    ProjetoModule,
    ArchwizardModule,
    GrupoCompraModule,
    EnderecoModule,
    NgbCollapseModule,
    NgbButtonsModule,
    NgbDropdownModule,
    MovimentoModule,
    FileUploadModule,
    SharedFarmModule,
    ChecklistModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
  })
  ],
  exports: [PessoaComponent, PessoaFormComponent, MovimentoModule, PendenciaFrotaComponent],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],
]
})
export class CadastrosModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
