export enum EnumTipoEmissao
{
    Normal = 1,
    Ajuste,
    Complementar
}

export const ListaTipoEmissao = [
  { descricao: "Normal", valor: EnumTipoEmissao.Normal },
  { descricao: "Ajuste", valor: EnumTipoEmissao.Ajuste },
  { descricao: "Complementar", valor: EnumTipoEmissao.Complementar }
];