import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cultivar } from '../classes/Cultivar';

@Injectable({
  providedIn: 'root'
})
export class CultivarService {

  constructor(private http: HttpClient) { }

  getCultivares(): Observable<any>{
    return this.http.get(`${environment.apiURL}/cultivar`)
  }

  getCultivaresPorCultura(culturaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/cultivar/listar/${culturaId}`)
  }

  getCultivaresPadrao(): Observable<any>{
    return this.http.get(`${environment.apiURL}/cultivar/cultivaresPadrao`, )
  }

  getCultivar(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/cultivar/${id}`)
  }
  
  getCultivarPorCultivarBase(cultivarBaseId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/cultivar/listarPorCultivarBase/${cultivarBaseId}`)
  }

  postCultivar(cultivar: Cultivar): Observable<any>{
    return this.http.post(`${environment.apiURL}/cultivar`, cultivar);
  }

  putCultivar(id: number, cultivar: Cultivar): Observable<any>{
    return this.http.put(`${environment.apiURL}/cultivar/${id}`, cultivar);
  }

  deleteCultivar(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/cultivar/${id}`);
  }
}
