import { ItemManutencaoFrota } from "./ItemManutencaoFrota";

export class ChecklistManutencaoFrotaItemManutencaoFrota {
  Id: number;
  ChecklistManutencaoFrotaId: number;
  ItemManutencaoFrotaId: number;
  ItemManutencaoFrota:ItemManutencaoFrota

  //auxiliar
  QtdItens:number = 0;
}
