import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ItemDescontoProducao } from "src/app/classes/gestao-lite/ItemDescontoProducao";
import { ApontamentoProducao } from "src/app/classes/planejamento-safra/ApontamentoProducao";
import { ItemPlanejamentoSafra } from "src/app/classes/planejamento-safra/ItemPlanejamentoSafra";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanejamentoSafraService {
  readonly route: string = 'PlanejamentoSafra';

  constructor(private http: HttpClient) { }

  GetItensPlanejamentoSafra(): Observable<ItemPlanejamentoSafra[] | null> {
    return this.http.get<ItemPlanejamentoSafra[]>(`${environment.apiURL}/${this.route}`);
  }

  GetApontamentos(safraId: number, areaId: number): Observable<ApontamentoProducao[] | null> {
    return this.http
      .get<ApontamentoProducao[]>(`${environment.apiURL}/${this.route}/apontamentos?SafraId=${safraId}&AreaId=${areaId}`);
  }

  GetItensDescontoProducao(id: number): Observable<ItemDescontoProducao[] | null> {
    return this.http
      .get<ItemDescontoProducao[]>(`${environment.apiURL}/${this.route}/itensDescontoProducao/${id}`);
  }

  DeleteItemDescontoProducao(id: number): Observable<void>{
    return this.http.delete<void>(`${environment.apiURL}/ItemDescontoProducao/${id}`);
  }
}
