import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { StorageService } from '../services/storage.service';
import { enumPerfil } from '../classes/Usuario';
import { EmpresaService } from '../services/empresa.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class EmpresaGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private storageService: StorageService,
    private empresaService: EmpresaService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.loginService.usuarioEstaAutenticado()) {
      if (this.storageService.getUsuario().PerfilInt == enumPerfil.Super){

        if (this.storageService.getEmpresa() != null)
          this.empresaService.setEmpresaSelecionada(this.storageService.getEmpresa());

        if (!this.empresaService.getEmpresaSelecionada()){
          Swal.fire('Erro!', 'Selecione a empresa', 'error');
          this.router.navigate(['/painel']);
        }
      }

      return true;
    }

    return true;
  }

}
