import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomFormsModule } from 'ng2-validation';
import { NgxMaskModule } from 'ngx-mask';
import { CardModule } from 'src/app/theme/shared/components';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { SharedFarmModule } from '../../shared-farm/shared-farm.module';
import { ChecklistComponent } from './checklist/checklist.component';
import { ItemChecklistComponent } from './item-checklist/item-checklist.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient} from '@angular/common/http';

@NgModule({
  declarations: [ChecklistComponent, ItemChecklistComponent],
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SharedFarmModule,
    NgSelectModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
  })
  ]
})
export class ChecklistModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}