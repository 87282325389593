import { Component, Input, OnInit } from '@angular/core';
import { TiposDiagnoseRegistroIndicador } from 'src/app/classes/tipos-diagnose/TiposDiagnoseRegistroIndicador';

@Component({
  selector: 'app-diagnose-indicador',
  templateUrl: './diagnose-indicador.component.html',
  styleUrls: ['./diagnose-indicador.component.scss']
})
export class DiagnoseIndicadorComponent implements OnInit {

  @Input() diagnoseIndicador: TiposDiagnoseRegistroIndicador;

  constructor() {

  }

  ngOnInit() {
    console.log(this.diagnoseIndicador)
  }

}
