import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AreaImport } from 'src/app/classes/AreaImport';
import { AreaImportSimpleData } from 'src/app/classes/AreaImportSimpleData';
import { AreaKmlSchemas } from 'src/app/classes/AreaKmlSchemas';
import { LayerArea } from 'src/app/classes/LayerArea';
import { AreaService } from 'src/app/services/area.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-area-import',
  templateUrl: './area-import.component.html',
  styleUrls: ['./area-import.component.scss']
})
export class AreaImportComponent implements OnInit {
  @ViewChild('mapEditor', { static: false })
  mapEditor;
  @ViewChild('inputKmlFile', { static: false })
  inputKmlFile!: ElementRef;
  @ViewChild('selectAreaTotalName', { static: false })
  selectAreaTotalName: NgSelectComponent;
  @ViewChild('selectDescricaoName', { static: false })
  selectDescricaoName: NgSelectComponent;
  @ViewChild('SelecionarSchemaModal', { static: false })
  SelecionarSchemaModal: UiModalComponent;
  @ViewChild('KMLModal', { static: false })
  KMLModal: UiModalComponent;

  areasToImport: AreaImport[] = [];
  id: number = 0;
  areaKmlSchemas: AreaKmlSchemas[] = [];
  areaKmlSchemasOnlyFloatValues: AreaKmlSchemas[] = [];
  fileId: string;
  areaTotalName: string;
  descricaoName: string;
  carregandoImportacaoKML: boolean = false;

  layersArea: LayerArea[];
  mostraMapa: boolean = false;
  carregando: boolean = false;
  constructor(
    private areaService: AreaService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router,

  ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
  }

  uploadKML(event) {
    var file = event.target.files[0];
    this.mostraMapa = false;
    this.layersArea = [];
    this.areaService.saveKmlFileExtractSchemas(file, this.id).subscribe(
      res => {
        if(res.Schemas){
          this.areaKmlSchemas = res.Schemas.map(p => new AreaKmlSchemas(p.Type, p.Name));
          this.areaKmlSchemasOnlyFloatValues = this.findFloats(this.areaKmlSchemas);
        }

        this.areasToImport = res.Areas.map(p => new AreaImport(p.Id, p.EmpresaId, p.FazendaId, p.SetorId, p.Descricao
          , p.AreaTotal, p.Irrigado, p.Proprio, p.Inativa, p.IdERP, p.AreaDisponivel, p.LayersArea, true
          , p.SimpleData ? p.SimpleData.map(q => new AreaImportSimpleData(q.Name, q.Text)) : []));
        this.fileId = res.FileId;
        this.utils.handleSuccess('Dados Carregados!');
        this.visualizarTodasAreas();
        if(this.selectAreaTotalName)
          this.selectAreaTotalName.handleClearClick();
        if(this.selectDescricaoName)
          this.selectDescricaoName.handleClearClick();
      },
      err => {
        this.utils.getErro(err);
      });
  }

  findFloats(schemas: AreaKmlSchemas[]): AreaKmlSchemas[] {
    return schemas.filter(p => p.Type === "float");
  }

  onChangeAreaTotal(schema: AreaKmlSchemas) {
    for (let i in this.areasToImport) {
      let element = this.areasToImport[i];
      let simpleData = element.SimpleData.filter(p => p.Name == schema.Name)[0]
      if (simpleData && simpleData.Text)
        element.AreaTotal = parseFloat(parseFloat(simpleData.Text).toFixed(2))
    }
  }
  onChangeDescription(schema: AreaKmlSchemas) {
    for (let i in this.areasToImport) {
      let element = this.areasToImport[i];
      let simpleData = element.SimpleData.filter(p => p.Name == schema.Name)[0]
      element.Descricao = simpleData.Text
    }
  }

  selecionarArea(area: AreaImport) {
    this.alteraLayers(area.LayersArea)
    if (this.mostraMapa) {
      setTimeout(() => {
        this.mapEditor.carregaMapa();
      }, 2000);
    }
    this.mostraMapa = true;
  }

  visualizarTodasAreas() {
    this.layersArea = []
    this.mostraMapa = true;
    for (let i in this.areasToImport) {
      let element = this.areasToImport[i];
      for (let j in JSON.parse(element.LayersArea)) {
        this.layersArea.push(JSON.parse(element.LayersArea)[j]);
      }
    }
    this.mapEditor.carregaMapa();
  }

  alteraLayers(layers: string) {
    this.layersArea = JSON.parse(layers);
  }

  saveAreas() {
    this.carregando = true;
    this.areaService.importAreaFromKmlFile(this.fileId, this.areaTotalName, this.descricaoName, this.id, this.areasToImport.filter(p => p.Incluir == true)).subscribe(
      res => {
        this.utils.handleSuccess(`${res.AreasImportadas} Áreas importadas!`)
        if(this.selectAreaTotalName)
          this.selectAreaTotalName.handleClearClick();
        if(this.selectDescricaoName)
          this.selectDescricaoName.handleClearClick();
        this.carregando = false;
        this.router.navigate([`/cadastros/fazenda/${this.id}`]).then(nav => {
          window.location.reload();
        });
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }
}
