import { GrupoTransacao } from "./GrupoTransacao";
import { ItemEnum } from "./ItemEnum";
import { ItemMovimento } from "./ItemMovimento";
import { LancamentoFinanceiro } from "./LancamentoFinanceiro";
import { Rateio } from "./Rateio";
import { Safra } from "./Safra";

export class Movimento {
  Id: number;
  EmpresaId: number;
  Serie: number = 0;
  NumeroDocumento:string = "";
  NumeroContrato:string = "";
  TransacaoId: number;
  EmpresaInternaId: number;
  Solicitante:string = "";
  UsuarioCompradorId: number;
  PessoaId: number;
  VendedorId: number;
  ClienteId: number;
  NaturezaId?: number;
  CentroDeResultadosId: number;
  ProjetoId?: number = 0;
  DataEmissao: Date = new Date();
  DataEntradaSaida: Date = new Date();
  DataPrevisaoEntrega: Date = new Date();
  DataPrevisaoCompras: Date = new Date();
  ValorBruto: number = 0;
  LocalEstoqueId: number;
  GrupoTransacao: GrupoTransacao;
  Funrural?: number = 0;
  Juros: number = 0;
  PercentualDesconto: number = 0;
  Descontos: number = 0;
  Acrescimos: number = 0;
  ValorLiquido: number = 0;
  ObservacaoComprador:string = "";
  HistoricoParaFinanceiro:string = "";
  StatusAprovacao: EnumStatusAprovacao;
  StatusMovimento: EnumStatusMovimento;
  Rateado: boolean;
  CotacaoId?: number;
  TransportadoraId: number;
  TemFrete: boolean;
  TipoFrete: EnumTipoFrete;
  ValorFrete: number = 0;
  PesoBruto: number = 0;
  PesoLiquido: number = 0;
  ChaveNfe:string = "";
  DataCadastro:  Date;
  Itens: ItemMovimento[];
  Rateios: Rateio[];
  ItemsSafra: MovimentoSafra[];
  FazendaId: number;
  GetFazenda:string = "";
  GetTransacao:string = "";
  GetNatureza:string = "";
  GetCentroDeResultados:string = "";
  GetProjeto:string = "";
  GetLocalEstoque:string = "";
  GetPessoa:string = "";
  GetCotacao:string = "";
  GetUsuarioComprador:string = "";
  GetTipoMovimento:string = "";
  Safras: Safra[];
  GetAprovador:string = "";
  GetUsuarioCadastro:string = "";
  GetStatusCotacao:string = "";
  GetStatusMovimento:string = "";
  GetStatusAprovacao:string = "";
  GetTipoMovimentoId: number;
  DataAlteracao:  Date;
  UsuarioCadastroId: number;
  UsuarioAlteracaoId: number;
  SalvarApenasCabecalho: boolean;
  NumeroContratoCliente:string = "";
  DataLimiteCarregamentoTotal: Date = new Date();

  // rateio
  ValorTotalRateio:string = "";
  PercentualTotalRateio:string = "";
  PercentualNaoRateado:string = "";
  GetEmpresaInterna:string = "";

  // lancamento financeiro
  LancamentosFinanceiros: LancamentoFinanceiro[];
  TotalParcelas:string = "";
  TotalMovimento:string = "";
  SaldoParcelar:string = "";
}

export enum EnumStatusMovimento
{
  Cadastrado = 0,
  Pendente = 1,
  ParcialmenteRecebido = 2,
  Recebido = 3,
  Cancelado = 4,
  EmAprovacao = 5,
  Aprovado = 6,
  ValidacaoPorComprador = 7,
  ValidacaoRecebimento = 8,
  LiberacaoFinanceiro = 9,
  FinanceiroLiberado = 10,
  Reprovado = 11,
  EmRevisao = 12
}
export enum EnumStatusAprovacao
{
    Cadastrado = 1,
    Aprovado,
    Reprovado,
    Revisao,
}
export enum EnumTipoFrete
{
  CIF = 1,
  FOB = 2
}

export const ListaStatusMovimento: ItemEnum[] = [
  {name: 'Pendente', value: EnumStatusMovimento.Pendente},
  {name: 'Parcialmente Recebido', value: EnumStatusMovimento.ParcialmenteRecebido},
  {name: 'Recebido', value: EnumStatusMovimento.Recebido},
  {name: 'Cancelado', value: EnumStatusMovimento.Cancelado},
  {name: 'Aprovado', value: EnumStatusMovimento.Aprovado},
  {name: 'Cadastrado', value: EnumStatusMovimento.Cadastrado},
  {name: 'Em Aprovação', value: EnumStatusMovimento.EmAprovacao},
  {name: 'Financeiro Liberado', value: EnumStatusMovimento.FinanceiroLiberado},
  {name: 'Liberação Financeiro', value: EnumStatusMovimento.LiberacaoFinanceiro},
  {name: 'Validação por Comprador', value: EnumStatusMovimento.ValidacaoPorComprador},
  {name: 'Validação Recebimento', value: EnumStatusMovimento.ValidacaoRecebimento},
  {name: 'Reprovado', value: EnumStatusMovimento.Reprovado},
  {name: 'Em Revisão', value: EnumStatusMovimento.EmRevisao},
];
export const ListaEnumStatusAprovacao: ItemEnum[] = [
  {name: 'Cadastrado', value: EnumStatusAprovacao.Cadastrado},
  {name: 'Aprovado', value: EnumStatusAprovacao.Aprovado},
  {name: 'Reprovado', value: EnumStatusAprovacao.Reprovado},
  {name: 'Revisão', value: EnumStatusAprovacao.Revisao},
];
export const ListaEnumTipoFrete: ItemEnum[] = [
  {name: 'CIF', value: EnumTipoFrete.CIF},
  {name: 'FOB', value: EnumTipoFrete.FOB},
];

export class MovimentoSafra{
  SafraId: number;
  Descricao:string = "";
  Items: ItemMovimento[];
}
