import { precoCotacaoItens } from 'src/app/classes/cotacao/precoCotacaoItens';

export class produtoSimilar{
    Id: number;
    PrecoCotacaoItensId: number;
    PrecoCotacaoItens:precoCotacaoItens;
    Nome:string = "";
    PrincipioAtivo:string
    Concetracao:string = "";
    DoseAplicacao:string = "";
    ValorUnitario: number = 0;
    UnidadeMedida:string = "";
    Fabricante:string = "";
    EmpresaId:number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId:number;
    UsuarioAlteracaoId:number;
    FlagExcluido:boolean = false;
}
