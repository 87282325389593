import { StorageService } from './../../../services/storage.service';
import { EmpresaService } from './../../../services/empresa.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Atividade } from 'src/app/classes/Atividade';
import { Empresa } from 'src/app/classes/Empresa';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { ItemOrdemServico } from 'src/app/classes/ItemOrdemServico';
import { OrdemServico, EnumStatusOS } from 'src/app/classes/OrdemServico';
import { Produto } from 'src/app/classes/Produto';
import { AtividadeService } from 'src/app/services/atividade.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { ItemOrdemServicoService } from 'src/app/services/item-ordem-servico.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { isNull } from 'lodash';
import { TipoAplicacao } from 'src/app/classes/TipoAplicacao';
import { TipoAplicacaoService } from 'src/app/services/tipo-aplicacao.service';

@Component({
  selector: 'app-liberacao',
  templateUrl: './liberacao.component.html',
  styleUrls: ['./liberacao.component.scss']
})
export class LiberacaoComponent implements OnInit, OnChanges {

  @Input() ordemServico: OrdemServico;
  @Input() inserindo: boolean;

  @Output() fechou: EventEmitter<any> = new EventEmitter();
  @Output() liberado: EventEmitter<any> = new EventEmitter();

  @ViewChild('ModalLiberacao', { static: false })
  Modal: UiModalComponent;

  atividades: Atividade[];
  produtos: Produto[];
  itensOrdemServico: ItemOrdemServico[];
  gruposProdutos: GrupoProduto[];
  public isServico: boolean = false;
  carregando: boolean = false;
  tiposAplicacao: TipoAplicacao[];
  
  //ng-select produto
  pagina: number = 2;
  termoBusca: String = '';
  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  public inputProduto$ = new Subject<string | null>();
  temPulverizacaoSulco: boolean = false;
  temAdubacaoLinha: boolean = false;
  empresa: Empresa;
  precisaoDose: number = 0;

  constructor(public ordensServicoService: OrdemServicoService,
              private atividadeService: AtividadeService,
              private produtoService: ProdutoService,
              private itemOrdemServicoService: ItemOrdemServicoService,
              private grupoProdutoService: GrupoProdutoService,
              private translate: TranslateService,
              private toasty: CustomToastyService,
              private empresaService: EmpresaService,
              private tipoAplicacaoService: TipoAplicacaoService,
              private storageService: StorageService,
              private utils: UtilsService) {
                this.inputProduto$.subscribe((newTerm) => {
                  this.pagina = 1;
                  this.fetchMore(newTerm);
                });
              }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.ordemServico){
      this.ngOnInit();
    }
  }

  ngOnInit() {

    if (this.ordemServico) {
      this.empresaService.getEmpresa(this.storageService.getEmpresa().Id).subscribe(ret =>{
        this.empresa = ret;
        this.precisaoDose = this.empresa.CasasDecimaisDose;
      })

      this.atividadeService.getAtividades().subscribe(ret =>{
        this.atividades = ret;
      });
      this.tipoAplicacaoService.getTiposAplicacao().subscribe(ret => {
        this.tiposAplicacao = ret;
      });
      this.ordensServicoService.getOrdemServico(this.ordemServico.Id).subscribe(
        ret => {
          this.ordemServico = ret;
          this.isServico = !this.getAtividade(this.ordemServico.AtividadeId).UtilizaInsumo;
          this.listarProdutos();
          this.listarItens();
          this.Modal.show();
        }, err => this.utils.getErro(err)
      );
    }
  }

  getAtividade(id: number) : Atividade {
    let nova = new Atividade();
    nova.UtilizaInsumo = true;

    if (!this.atividades)
      return nova;

    let atividades = this.atividades.filter(x =>
      x.Id==id
    );

    return atividades.length > 0 ? atividades[0] : nova;
  }

  listarItens(){
    this.carregando = true;
    this.itemOrdemServicoService.getItensOrdemServico(this.ordemServico.Id).subscribe(ret =>{
      this.itensOrdemServico = ret;
      this.temPulverizacaoSulco = this.itensOrdemServico.filter(x => x.PulverizacaoSulco).length > 0;
      this.temAdubacaoLinha = this.itensOrdemServico.filter(x => x.AdubacaoLinha).length > 0;
      this.carregando = false;
    });
  }

  listarProdutos(){
    this.produtoService.getProdutosPaginandoOS(this.ordemServico.Id, 1,'',this.isServico).subscribe(ret =>{
      this.produtos = ret.Lista;

      this.grupoProdutoService.getGruposProduto().subscribe(ret =>{
        this.gruposProdutos = ret;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    });
  }

  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProdutos.filter(x =>
      x.Id==id
    );

    let novo = new GrupoProduto();

    return grupos.length > 0 ? grupos[0] : novo;
  }

  //ng-select functions
  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }

  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;
      this.produtoService.getProdutosPaginandoOS(this.ordemServico.Id,this.pagina,this.termoBusca,this.isServico).subscribe(ret =>{
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.produtos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    }, 300);
  }

  changeProduto(itemOS: ItemOrdemServico, idProduto: number){
    if (itemOS.ProdutoId != idProduto) {
      itemOS.ProdutoId = idProduto;
      itemOS.PrecoUnitario = this.getProduto(itemOS.ProdutoId).PrecoUnitario;

      if (this.isServico && !itemOS.QtdAplicada)
        itemOS.QtdAplicada = 1;
    }

    itemOS.ValorTotal = itemOS.QtdAplicada * itemOS.PrecoUnitario;
    itemOS.QtdPrevista = itemOS.Dose * itemOS.AreaAplicacao;

    this.itemOrdemServicoService.calcDosePorTanque(itemOS.ProdutoId, itemOS.Dose, this.ordemServico.AreaPorTanque).subscribe(res => {
      itemOS.DosePorTanqueCompleto = res;

    });
  }

  timoutDose: any;
  changeDose(itemOS: ItemOrdemServico){
    if (this.timoutDose)
      clearTimeout(this.timoutDose);

    this.timoutDose = setTimeout(() => {
      this.changeProduto(itemOS, itemOS.ProdutoId);
    }, 500);
  }

  changeQuantidade(itemOS: ItemOrdemServico){
    itemOS.Dose = itemOS.QtdPrevista / itemOS.AreaAplicacao;
    this.changeDose(itemOS);
  }

  getProduto(id: number) : Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';

    if (!this.produtos)
      return novo;

    let produto = this.produtos.filter(x =>
      x.Id==id
    );

    return produto.length > 0 ? produto[0] : novo;
  }

  novoItem(){
    if (!this.ordemServico)
      return;

    if (!this.itensOrdemServico)
      this.itensOrdemServico = [];

    if (this.itensOrdemServico.length == 0 || this.itensOrdemServico[this.itensOrdemServico.length-1].ProdutoId > 0){
      let novo = new ItemOrdemServico();
      novo.OrdemServicoId = this.ordemServico.Id;
      novo.AreaAplicacao = this.ordemServico.AreaPrevista;
      novo.Id = new Date().getTime() * -1;
      this.itensOrdemServico.push(novo);
    }
  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }
  conversaoValores(){
    if(this.ordemServico.TipoBicoId)
        this.ordemServico.TipoBicoId = Number.parseInt(this.ordemServico.TipoBicoId.toString());

      if(isNaN(this.ordemServico.AreaPorTanque) || isNull(this.ordemServico.AreaPorTanque) || this.ordemServico.AreaPorTanque == null || this.ordemServico.AreaPorTanque == undefined)
        this.ordemServico.AreaPorTanque = 0;

      if(this.ordemServico.FuncionarioLiberacaoId)
        this.ordemServico.FuncionarioLiberacaoId = Number.parseInt(this.ordemServico.FuncionarioLiberacaoId.toString());

      if(this.ordemServico.TaxaFixa)
        this.ordemServico.TaxaFixa = this.ordemServico.TaxaFixa.toString() === 'true';
  }
  confirmar() {
    this.carregando = true;
    //validações
    if(this.ordemServico.TipoAplicacaoId == null || this.ordemServico.TipoAplicacaoId == 0)
    {
      if(this.ordemServico.AtividadeId && this.getAtividade(this.ordemServico.AtividadeId).UtilizaInsumo){
          this.carregando = false;
          this.utils.handleWarning('Selecione um tipo de aplicação');
          return;
      }
    }
    if(this.ordemServico.EquipamentoId == null || this.ordemServico.EquipamentoId == 0)
    {
      this.carregando = false;
      this.utils.handleWarning('Selecione um equipamento');
      return;
    }
    if(this.ordemServico.AreaPorTanque == null || this.ordemServico.AreaPorTanque == undefined || this.ordemServico.AreaPorTanque == 0)
    {
      this.ordemServico.AreaPorTanque = 0;
    }

    let tipoAplicacao = this.tiposAplicacao.filter(t => t.Id == this.ordemServico.TipoAplicacaoId)[0];
    if(tipoAplicacao != null && tipoAplicacao.ExigeVazao && (this.ordemServico.Vazao == null || this.ordemServico.Vazao == 0))
    {
      this.carregando = false;
      this.utils.handleWarning('Informe a vazão maior que zero');
      return;
    }
    if(this.ordemServico.VolumeAgua == null || this.ordemServico.VolumeAgua == 0 || isNaN(this.ordemServico.VolumeAgua))
       this.ordemServico.VolumeAgua = 0;
    else if(this.ordemServico.Vazao == null || this.ordemServico.Vazao == undefined || this.ordemServico.Vazao < 0.1 || isNaN(this.ordemServico.Vazao))
      this.ordemServico.Vazao = 1;
    if(this.ordemServico.VolumeAgua == null || this.ordemServico.VolumeAgua == undefined || this.ordemServico.VolumeAgua < 0.1 || isNaN(this.ordemServico.VolumeAgua))
    this.ordemServico.VolumeAgua = 0;
    //fim das validações
    this.ordemServico.Status = EnumStatusOS.Pendente;
    this.ordemServico.Vazao
    this.conversaoValores();
    this.ordensServicoService.putOrdemServico(this.ordemServico.Id, this.ordemServico).subscribe(
      res => {
        this.itemOrdemServicoService.postItemOrdemServico(this.itensOrdemServico.filter(i => i.ProdutoId)).subscribe(
          ret => {
            this.toasty.show(
              this.translate.instant('FEITO'),
              this.translate.instant('ORDEM_DE_SERVICO_LIBERADA'),
              'success'
            );
            this.carregando = false;
            this.Modal.hide();
            this.liberado.emit();
          });
      },
      err => {
        this.utils.getErro(err);
        this.ordemServico.Status = EnumStatusOS.AguardandoLiberacao;
        this.carregando = false;
      });
  }

  deleteItem(i:number){

  }



}
