import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Natureza } from '../classes/Natureza';
import { Projeto } from '../classes/Projeto';

@Injectable({
  providedIn: 'root'
})
export class ProjetoService {

  constructor(private http: HttpClient) { }

  getList(): Observable<any>{
    return this.http.get(`${environment.apiURL}/Projetos`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Projetos/${id}`)
  }

  getPorMovimento(movimentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Projetos/ListarPorMovimento/${movimentoId}`);
  }

  post(projeto: Projeto): Observable<any>{
    return this.http.post(`${environment.apiURL}/Projetos`, projeto);
  }

  put(id: number, projeto: Projeto): Observable<any>{
    return this.http.put(`${environment.apiURL}/Projetos/${id}`, projeto);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/Projetos/${id}`);
  }
}
