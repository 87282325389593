import { Component, Input, OnInit } from '@angular/core';

import { ColDef } from 'src/app/classes/grid/ColDef';
import { RelatorioMovimentoEstoque } from 'src/app/classes/movimento-estoque/RelatorioMovimentoEstoque';
import { UtilsService } from 'src/app/utils/utils.service';
import { JsonToXlsxConvertService } from 'src/app/services/json-to-xlsx-convert.service';

@Component({
  selector: 'app-movimento-estoque-relatorio-lista',
  templateUrl: './movimento-estoque-relatorio-lista.component.html',
  styleUrls: ['./movimento-estoque-relatorio-lista.component.scss']
})
export class MovimentoEstoqueRelatorioListaComponent implements OnInit {

  @Input() relatorio: RelatorioMovimentoEstoque[]

  coldefs: ColDef[] = [];

  constructor(
    private utils: UtilsService,
    private convertService: JsonToXlsxConvertService
  ) {
    this.configurar();
  }

  ngOnInit(): void { }
  configurar() {
    this.coldefs = [
      { field: 'Descricao', headerName: 'Descrição', flex: 1 },
      { field: 'UnidadeMedida', headerName: 'Unidade Medida', flex: 1 },
      {
        field: 'DataMovimentacao', headerName: 'Data Movimentação', flex: 1,
        valueFormatter: (params) => this.utils.formatarDataHora(params.data.DataMovimentacao)
      },
      { field: 'Origem', headerName: 'Origem', flex: 1 },
      { field: 'QuantidadeMovimentada', headerName: 'Quantidade Movimentada', flex: 1 },
      {
        field: 'PrecoCusto', headerName: 'Preço Custo', flex: 1,
        valueFormatter: (params) => this.utils.formatarDecimais(params.data.PrecoCusto)
      },
      { field: 'LocalEstoqueDescricao', headerName: 'Local Estoque', flex: 1 }
    ];
  }

  gerarRelatorio() {
    const fileName = `Relatório Produto Movimentos de Estoque - ${new Date().toLocaleString('pt-BR')}`;
    const headers = [
      'Descrição', 'Unidade Medida', 'Data Movimentação', 'Origem',
      'Quantidade Movimentada', 'Preço Custo', 'Local Estoque Descrição',
    ];
    this.relatorio = this.relatorio.map(r => this.formatarDadosRelatorio(r));
    this.convertService.gerarXLSX(this.relatorio, headers, fileName);
  }

  mostrarBotaoGerarRelatorio() {
    return this.relatorio && this.relatorio.length > 0;
  }

  private formatarDadosRelatorio(item: RelatorioMovimentoEstoque) {
    item.DataMovimentacao = new Date(item.DataMovimentacao);
    item.PrecoCusto = this.utils.formatarDecimais(item.PrecoCusto);
    return item;
  }
}
