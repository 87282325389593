import { SharedFarmModule } from './../shared-farm/shared-farm.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CustomFormsModule } from 'ng2-validation';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { NgbDatepickerModule, NgbCollapseModule, NgbDropdownModule, NgbTooltipModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfilComponent } from './perfil.component';
import { AlteraSenhaComponent } from './altera-senha/altera-senha.component';
import { CotacaoModule } from '../cotacao/cotacao.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient} from '@angular/common/http';

@NgModule({
  declarations: [
    PerfilComponent,
    AlteraSenhaComponent
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    SharedFarmModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbTabsetModule,
    NgSelectModule,
    CotacaoModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
    })
  ],
  exports: [
    PerfilComponent,
    AlteraSenhaComponent
  ]
})
export class PerfilModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
