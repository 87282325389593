import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Equipamento } from 'src/app/classes/Equipamento';
import { ListaPrioridade, ListaStatusPendenciaFrota, PendenciaFrota } from 'src/app/classes/PendenciaFrota';
import { PendenciaFrotaService } from 'src/app/services/pendencia-frota.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-pendencia-frota-forms',
  templateUrl: './pendencia-frota-forms.component.html',
  styleUrls: ['./pendencia-frota-forms.component.scss']
})
export class PendenciaFrotaFormsComponent implements OnInit, OnChanges {

  @Output() voltarEvent = new EventEmitter<any>();
  @Input() pendenciaFrota: PendenciaFrota = new PendenciaFrota();
  @Input() hideEquipamentoSelect: boolean = false;
  
  pendenciaFrotas: PendenciaFrota[];
  auxPendenciaFrotas: PendenciaFrota[];
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  listaStatus = ListaStatusPendenciaFrota;
  listaPrioridade = ListaPrioridade;
  
  @Input() equipamentos: Equipamento[] = [];

  constructor(
    private pendenciaFrotaService: PendenciaFrotaService,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private utils : UtilsService
    ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pendenciaFrota) {
      this.pendenciaFrotaService.ObterPorId(this.pendenciaFrota.Id).subscribe(res =>{
        this.pendenciaFrota = res;
      },
      err => {
        this.utils.getErro(err);
      });
    }
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
  }

  adicionarAnexo(evento){
    if(!this.pendenciaFrota.Anexos.map(c => c.Arquivo).includes(evento.Arquivo))
      this.pendenciaFrota.Anexos.push(evento);
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.pendenciaFrota.Id){
                  
      this.pendenciaFrotaService.Atualizar(this.pendenciaFrota.Id, this.pendenciaFrota).subscribe(
        res => {
          this.toasty.show('Feito!', 'Pendência Frota alterada!', 'success');
          this.carregando = false;
          this.voltarEvent.emit()
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
    else{

      this.pendenciaFrotaService.Inserir(this.pendenciaFrota).subscribe(
        res => {
          this.toasty.show('Feito!', 'Pendência Frota cadastrada!', 'success');
          this.carregando = false;
          this.voltarEvent.emit()
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
  }

  cancelar(){
    this.voltarEvent.emit()
  }
}
