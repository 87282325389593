import { Endereco } from "./Endereco"
import { ItemEnum } from "./ItemEnum"

export class Pessoa
{
    Id: number = 0;
    RazaoSocial: string = "";
    NomeFantasia: string = "";
    Tipo: EnumTipoPessoa = EnumTipoPessoa.Fisica;
    CpfCnpj: string = "";
    inscricaoEstadual: string = "";
    inscricaoMunicipal: string = "";
    Papel: string = "";
    CpfResponsavel: string = "";
    NomeResponsavel: string = "";
    Telefone: string = "";
    Email: string = "";
    Ativo: Boolean = true
    Pix: string = "";
    Banco: string = "";
    Agencia: string = "";
    Conta: string = "";
    _bindLabel: string = "";

    // endereço
    Enderecos: Endereco[] = new Array<Endereco>();
    EnderecoCorrespondenciaIgualPrincipal: boolean = true;
    EnderecoEntregaIgualPrincipal: boolean = true;
}

export enum EnumTipoPessoa{
  Fisica = 1,
  Juridica = 2
}

export const ListaEnumTipoPessoa: ItemEnum[] = [
  {name: 'Pessoa Física', value: EnumTipoPessoa.Fisica},
  {name: 'Pessoa Jurídica', value: EnumTipoPessoa.Juridica},
]




