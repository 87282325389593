import { fazendaCotacao } from '../../classes/cotacao/fazendaCotacao';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class fazendaCotacaoService {  

  constructor(private http: HttpClient) { }

  get(): Observable<any>{
    return this.http.get(`${environment.apiURL}/FazendaCotacao`)
  }

  getPorId(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/FazendaCotacao/${id}`)
  }

  getPorCotacao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/FazendaCotacao/Cotacao/${id}`)
  }

  post(fazendaCotacao: fazendaCotacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/FazendaCotacao`, fazendaCotacao);
  }

  put(id: number, fazendaCotacao: fazendaCotacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/FazendaCotacao/${id}`, fazendaCotacao);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/FazendaCotacao/${id}`);
  }

  sincronizar(fazemdas: number[], id: number): Observable<any>{
    return this.http.post(`${environment.apiURL}/FazendaCotacao/Sincronizar/${id}`, fazemdas);
  }

  getArray(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/FazendaCotacao/array/${id}`)
  }
}
