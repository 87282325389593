import { Injectable } from '@angular/core';
import { DiaHorarioAcessoUsuario } from 'src/app/classes/Usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioHorarioAcessoValidacaoSharedService {

  isValid: boolean;
  constructor() { }

  validarHorarios(diasAcessos: DiaHorarioAcessoUsuario): boolean {
    this.isValid = (
      (this.toDate(diasAcessos.Domingo.Fim) >= this.toDate(diasAcessos.Domingo.Inicio)) &&
      (this.toDate(diasAcessos.Segunda.Fim) >= this.toDate(diasAcessos.Segunda.Inicio)) &&
      (this.toDate(diasAcessos.Terca.Fim) >= this.toDate(diasAcessos.Terca.Inicio)) &&
      (this.toDate(diasAcessos.Quarta.Fim) >= this.toDate(diasAcessos.Quarta.Inicio)) &&
      (this.toDate(diasAcessos.Quinta.Fim) >= this.toDate(diasAcessos.Quinta.Inicio)) &&
      (this.toDate(diasAcessos.Sexta.Fim) >= this.toDate(diasAcessos.Sexta.Inicio)) &&
      (this.toDate(diasAcessos.Sabado.Fim) >= this.toDate(diasAcessos.Sabado.Inicio))
    );
    return this.isValid;
  }

  toDate(timeString) {
    if (timeString == undefined) return;
    const timeParts = timeString.split(":");

    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);
    const second = parseInt(timeParts[1], 10);

    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(second);

    return date;
  }
}
