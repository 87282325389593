import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Produto } from 'src/app/classes/Produto';
import { auxResumo } from 'src/app/classes/cotacao/auxResumo';
import { precoCotacao } from 'src/app/classes/cotacao/precoCotacao';
import { cotacaoService } from 'src/app/services/cotacao/cotacaoService';
import { StorageService } from 'src/app/services/storage.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import Swal from 'sweetalert2';
import { CotacaoInclude } from './../../../classes/cotacao/CotacaoInclude';
import { Fornecedores } from './../../../classes/cotacao/Fornecedores';
import { itensCotacao } from './../../../classes/cotacao/itensCotacao';
import { precoCotacaoItens } from './../../../classes/cotacao/precoCotacaoItens';
import { produtoSimilar } from './../../../classes/cotacao/produtoSimilar';
import { FornecedoresService } from './../../../services/cotacao/Fornecedores.service';
import { precoCotacaoItensService } from './../../../services/cotacao/precoCotacaoItensService';
import { precoCotacaoService } from './../../../services/cotacao/precoCotacaoService';
import { PainelFornecedorTranslate } from './painel-fornecedor.translate';

@Component({
  selector: 'app-painel-fornecedor',
  templateUrl: './painel-fornecedor.component.html',
  styleUrls: ['./painel-fornecedor.component.scss']
})
export class PainelFornecedorComponent extends PainelFornecedorTranslate implements OnInit {
  @Output() inserindoEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;
  @ViewChild('Modal2', { static: false })
  Modal2: UiModalComponent;
  @ViewChild('ModalDesistencia', { static: false })
  ModalDesistencia: UiModalComponent;
  @ViewChild('ModalNFe', { static: false })
  ModalNFe: UiModalComponent;

  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;
  id: number = 0;
  Fornecedor: Fornecedores = new Fornecedores();
  fornecedorId: number = 0;
  precoCotacaoId: number = 0;
  chaveFornecedor: string;
  cotacoes: CotacaoInclude[] = [];
  cotacao: CotacaoInclude = new CotacaoInclude();
  auxCotacoes: CotacaoInclude[] = [];
  precoCotacao: precoCotacao = new precoCotacao();
  precoCotacaoItens: precoCotacaoItens[];
  PrecoTemp: precoCotacaoItens[] = [];
  Produtos: Produto[] = [];
  itensCotacao: itensCotacao = new itensCotacao();
  produtoSimilar: produtoSimilar = new produtoSimilar();
  descontoGlobal: number[] = [];
  mensagemWhatsapp = "Olá, preciso de mais informações sobre a cotação ";
  //Expansão
  Detalhe: number = 0;
  data: Date = new Date();
  modalTitulo: string = "";
  @Input() desistencia: number = 0;

  constructor(private FornecedoresService: FornecedoresService,
    private precoCotacaoItensService: precoCotacaoItensService,
    private CotacaoService: cotacaoService,
    private precoCotacaoService: precoCotacaoService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService) {
        super(translate);
        this.cotacao = new CotacaoInclude();
        this.cotacao.auxResumo = new auxResumo();
  }

  ngOnInit() {
    this.descontoGlobal.push(0);
    this.desistencia = 0;
    console.log('Usuário: ');
    console.log(this.storageService.getUsuario());
    this.carregando = false;
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.chaveFornecedor = this.storageService.getUsuario().Login;
    this.FornecedoresService.getPorChave(this.chaveFornecedor).subscribe(ret => {
      this.Fornecedor = ret;
      this.fornecedorId = ret.Id;
      this.carregando = false;
      if (this.id > 0)
        this.selecionar(this.id);
      else
        this.listar();
    });
    this.inserindo = this.router.url.search("inserir") > 0;
    this.carregando = false;

  }

  sair() {
    this.storageService.removerUsuario();
    this.storageService.removerToken();
    this.storageService.removerEmpresa();
    this.storageService.removerFazenda();
    this.router.navigate(['/loginfornecedor']);
  }
  listar() {
    this.carregando = true;
    this.CotacaoService.getPorFornecedor(this.fornecedorId).subscribe(
      res => {
        this.cotacoes = res;
        this.auxCotacoes = res;
        this.carregando = false;
        if (this.textoBusca != '')
          this.filtrar();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
    this.carregando = false;
  }

  filtrar() {
    this.cotacoes = this.auxCotacoes.filter(x =>
      x.Titulo.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Empresa.NomeFantasia.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Empresa.RazaoSocial.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Empresa.CgcCpf.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Empresa.CgcCpf.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
    );
  }

  cancelar() {
    this.inserindo = false;
    this.id = null;
    this.inserindoEvent.emit(false);
  }

  selecionar(cotacaoId: number) {
    this.carregando = true;
    this.id = cotacaoId;
    this.precoCotacaoService.selecionaPorCotacaoFornecedor(cotacaoId, this.fornecedorId).subscribe(res => {
      this.precoCotacao = res;
    });
    this.CotacaoService.getPorIdIncludeFornecedor(cotacaoId, this.fornecedorId).subscribe(res => {
      this.cotacao = res;
      console.log(res);
      console.log(this.cotacao.Status);
      this.inserindo = true;
      this.carregando = false;
      var dif = (new Date(this.cotacao.DataLimite).valueOf() - new Date().valueOf()) / 1000;
      var display = document.querySelector('#timer'); // selecionando o timer
      this.startTimer(dif.toFixed(0), display); // iniciando o timer
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
    this.atualizaItens(cotacaoId);
  }

  atualizaItens(cotacaoId: number) {
    this.precoCotacaoItensService.selecionaPorCotacaoFornecedor(cotacaoId, this.fornecedorId).subscribe(res => {
      this.precoCotacaoItens = res;
      console.log(res);
      this.inserindo = true;
      this.carregando = false;
    },
      ex => {
        Swal.fire('Erro!', ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  salvarDesistencia(desistencia: number, motivo: string) {

    this.precoCotacao.Desistencia = desistencia;
    this.precoCotacao.MotivoDesistencia = motivo;
    console.log("preco cotacao===============" + this.cotacao.Id)
    this.precoCotacaoService.desistencia(this.cotacao.Id, this.fornecedorId, this.precoCotacao).subscribe(res => {
      this.desistencia = res;
      console.log("res ========================" + res);
      this.inserindo = false;
      this.carregando = false;
      this.fecharModalDesistencia();
      this.listar();
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })


  }

  atualizaStatusPreco(cotacaoId: number) {

    this.precoCotacaoItensService.selecionaPorCotacaoFornecedor(cotacaoId, this.fornecedorId).subscribe(res => {
      console.log(res);
      this.PrecoTemp = res;
      let i = 0;
      this.PrecoTemp.forEach(item => {
        this.precoCotacaoItens[i].StatusFornecedor = item.StatusFornecedor;
        this.precoCotacaoItens[i].ValorMenor = item.ValorMenor;
        i++;
      });
      this.inserindo = true;
      this.carregando = false;
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  save(form: any) {
    this.carregando = true;
    this.inserindo = true;
    this.precoCotacao.PrecoCotacaoItens = this.precoCotacaoItens;
    console.log(this.precoCotacao);
    this.precoCotacaoService.put(this.precoCotacao.Id, this.precoCotacao).subscribe(res => {
      this.precoCotacao = res;
      this.precoCotacao = new precoCotacao();
      this.precoCotacaoService.selecionaPorCotacaoFornecedor(this.id, this.fornecedorId).subscribe(res2 => {
        this.precoCotacao = res2;
        this.carregando = false;
        this.atualizaStatusPreco(this.id);
        this.selecionar(this.id);
        this.toasty.show('Feito!', 'Preços Enviados!', 'success');
      });
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      });

    // this.precoCotacaoItensService.salvarEtapa(this.fornecedorId, this.precoCotacaoItens).subscribe(res => {
    //   this.precoCotacaoItens = res;
    //   this.atualizaStatusPreco(this.id);
    //   this.carregando = false;
    //   this.toasty.show('Feito!', 'Preços Enviados!', 'success');
    //   //this.cancelar();
    // },
    //   ex => {
    //     Swal.fire('Erro!', ex.error.ExceptionMessage, 'error');
    //     this.carregando = false;
    //   });
  }
  CarregaProdutoSimilar(id: number) {
    this.produtoSimilar = new produtoSimilar();
    this.precoCotacaoItensService.getProdutoSimilar(id).subscribe(res => {
      if (res == null) {
        this.produtoSimilar = new produtoSimilar();
        this.produtoSimilar.PrecoCotacaoItensId = id;
      }
      else {
        this.produtoSimilar = res;
      }
    });
  }
  ProdutoSimilar(id: number) {
    this.CarregaProdutoSimilar(id);
    this.Modal.show();
  }
  fecharModalProdutos() {
    this.produtoSimilar = new produtoSimilar();
    this.Detalhe = 0;
    this.Modal.hide();
  }
  salvarProdutoSimilar() {
    this.produtoSimilar.EmpresaId = this.cotacao.EmpresaId;
    this.precoCotacaoItensService.salvarProdutoSimiliar(this.produtoSimilar.PrecoCotacaoItensId, this.produtoSimilar).subscribe(res => {
      this.produtoSimilar = res;
      this.selecionar(this.cotacao.Id);
    });
    this.Modal.hide();
  }
  startTimer(duracao: string, display: Element) {
    if (this.cotacao.Status == 1) {
      var timer: number = parseInt(duracao), minutes, seconds, horas, dias;
      var tick = 60;
      setInterval(res => {
        minutes = Math.trunc((timer / 60) % 60).toFixed(0);
        seconds = timer % 60;
        horas = Math.trunc(((timer / 60) / 60) % 24).toFixed(0);
        dias = Math.trunc(((timer / 60) / 60) / 24).toFixed(0)
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        horas = horas < 10 ? "0" + horas : horas;
        dias = dias < 10 ? "0" + dias : dias;
        this.COTACAO_FINALIZA_EM_DIAS_DIAS_HORAS_HORAS_MINUTOS_MINUTOS_SEGUNDOS_SEGUNDOS
          = this.translate.instant('COTACAO_FINALIZA_EM_DIAS_DIAS_HORAS_HORAS_MINUTOS_MINUTOS_SEGUNDOS_SEGUNDOS', { dias: dias, horas: horas, minutos: minutes, segundos: seconds });

        display.textContent = this.COTACAO_FINALIZA_EM_DIAS_DIAS_HORAS_HORAS_MINUTOS_MINUTOS_SEGUNDOS_SEGUNDOS;
        tick--;
        if (tick < 1) {
          tick = 60;
          this.atualizaStatusPreco(this.id);
        }

        if (--timer < 0) {
          timer = 0;
        }

      }, 1000);
    }
  }

  TrocaIndisponivel(id: number) {
    if (this.cotacao.Status == 1) {
      this.precoCotacaoItensService.trocaIndisponivel(id).subscribe(res => {
        this.precoCotacaoItensService.salvarEtapaSemValidar(this.fornecedorId, this.precoCotacaoItens).subscribe(res => {
        });
      },
        ex => {
          Swal.fire('Erro!', ex.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    }
  }

  exibeItens(id: number, similar: number) {
    if (similar > 0) {
      this.Detalhe = id;
      if (id == 0)
        this.produtoSimilar = new produtoSimilar();
      else
        this.CarregaProdutoSimilar(id);
    }
  }
  AabreModalDesconto() {
    this.Modal2.show();
  }
  fecharModalDesconto() {
    this.Modal2.hide();
    this.descontoGlobal[0] = 0;
  }

  AabreModalDesistencia(obj: CotacaoInclude) {
    this.cotacao = obj;
    console.log("preco cotacao===============" + this.cotacao.Id)
    this.ModalDesistencia.show();
    this.precoCotacaoId = obj.Id;
    this.modalTitulo = "Não Desejo participar da cotação."
  }
  fecharModalDesistencia() {
    this.ModalDesistencia.hide();
  }


  aplicarDesconto() {
    Swal.fire({
      title: this.DESEJA_APLICAR_OS_DESCONTOS,
      text: this.OS_PRECOS_SERAO_APLICADOS_EM_TODOS_OS_PRODUTOS_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.precoCotacaoService.aplicarDesconto(this.id, this.fornecedorId, this.descontoGlobal).subscribe(res => {
          this.selecionar(this.id);
          this.carregando = false;
          this.toasty.show(this.FEITO, this.PRECOS_ATUALIZADOS, 'success');
          //this.cancelar();
        },
          ex => {
            Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
            this.carregando = false;
          });
        this.fecharModalDesconto();
      }
    });
  }

  novo() {
    this.inserindo = true;
  }
}
