import { Transacao } from './Transacao';
export class PermissaoUsuarioTransacao {
  Id: number
  UsuarioId: number
  DataCadastro:Date;
  DataAlteracao:Date;
  UsuarioCadastroId: number;
  UsuarioAlteracaoId: number;
  FlagExcluido:boolean = false;
  TransacaoId: number;
  PermiteCriar:boolean = false;
  PermiteEditar:boolean = false;
  PermiteExcluir:boolean = false;
  PermiteVisualiza:boolean = false;
  PermiteReceber:boolean = false;
  PermiteCancelar:boolean = false;
  PermiteModificaRateio:boolean = false;
  PermiteVisualizaRastreio:boolean = false;
  PermiteClonar:boolean = false;
  PermiteIncluirItensAvulsos:boolean = false;
  PermiteImprimir:boolean = false;
  NomeTransacao:string = "";
  GrupoTransacao:string = "";
}
