import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProdutoConsolidado } from '../classes/ProdutoConsolidado';
import { ListaProdutoConsolidado } from '../classes/ListaProdutoConsolidado';

@Injectable({
  providedIn: 'root'
})
export class ProdutoConsolidadoService {

  rota:string = "ProdutoConsolidado";
  constructor(private http: HttpClient) { }

  getAll(): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.rota}`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.rota}/${id}`)
  }

  post(produtoConsolidado: ProdutoConsolidado): Observable<any>{
    return this.http.post(`${environment.apiURL}/${this.rota}`, produtoConsolidado);
  }

  put(id: number, produtoConsolidado: ProdutoConsolidado): Observable<any>{
    return this.http.put(`${environment.apiURL}/${this.rota}/${id}`, produtoConsolidado);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/${this.rota}/${id}`);
  }

  addProduto(produtoConsolidadoId:number, listaProdutoConsolidado: ListaProdutoConsolidado): Observable<any>{
    return this.http.post(`${environment.apiURL}/${this.rota}/AddProduto/${produtoConsolidadoId}`, listaProdutoConsolidado);
  }

  atualizaProduto(listaProdutoConsolidado: ListaProdutoConsolidado): Observable<any>{
    return this.http.put(`${environment.apiURL}/${this.rota}/AtualizaProduto`, listaProdutoConsolidado);
  }

  deleteProduto(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/${this.rota}/DeletaProduto/${id}`);
  }

  getListaProdutoConsolidado(produtoConsolidadoId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.rota}/ListaProdutoConsolidado/${produtoConsolidadoId}`)
  }

  addSafra(produtoConsolidadoId:number, safraId:number): Observable<any>{
    return this.http.post(`${environment.apiURL}/${this.rota}/AddSafra/${produtoConsolidadoId}/${safraId}`, null);
  }

  removeSafra(produtoConsolidadoId:number, safraId:number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/${this.rota}/RemoveSafra/${produtoConsolidadoId}/${safraId}`);
  }

  exportar(produtoConsolidadoId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.rota}/Exportar/${produtoConsolidadoId}`);
  }
}
