import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/utils/utils.service';
import { PersonalizacaoGridService } from 'src/app/services/PersonalizacaoGrid.Service';
import { EnumGrid } from 'src/app/classes/enum/EnumGrid';
import { EnumModulo } from 'src/app/classes/GrupoTransacao';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { NotasService } from 'src/app/services/notas.service';
import { invoices } from 'src/app/classes/Notas/invoices';
import { environment } from 'src/environments/environment';
import { ItemEnum } from 'src/app/classes/ItemEnum';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-exibir-notas',
  templateUrl: './exibir-notas.component.html',
  styleUrls: ['./exibir-notas.component.scss']
})
export class ExibirNotasComponent implements OnInit {
  @ViewChild('notasGrid', { static: false }) notasGrid;
  @ViewChild('ModalCarregando', { static: false }) ModalCarregando
  @ViewChild('ModalManifesto', { static: true }) ModalManifesto

  id: number = 0;
  colDefs: ColDef[] = [];
  qtdItems = 50;
  modulo = EnumModulo.Compras;
  filtro = new FiltroTela();
  inserindo: boolean;
  isSubmitAprovacao = false;
  isSubmit = false;
  loading: boolean = false;
  minHeight = 0;
  notas: invoices[] = [];
  obsModalManifesto: string = "";
  notasSelecionados: invoices[] = [];
  empresaInternaId: number = 0;
  dataInicio = new Date();
  dataFim = new Date();
  listaStatus: ItemEnum[] = [
    {
      value: 0,
      name: 'Todos'
    },
    {
      value: 1,
      name: 'Autorizada'
    },
    {
      value: 2,
      name: 'Contingência'
    },
  ];
  acoes = [
    'Manifestar',
    'Aprovação',
    'Baixar PDF'
  ];
  constructor(private utils: UtilsService,
    private PersonalizacaoGridService: PersonalizacaoGridService,
    private router: Router,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private notasService: NotasService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.dataInicio.setDate(this.dataInicio.getDate() - 1);
    this.inserindo = this.router.url.search("inserir") > 0;
    if (this.id > 0)
      this.inserindo = true
    let usuario = this.storageService.getUsuario();
    if (usuario.PermissaoUsuario == null || usuario.PermissaoUsuario.UsarGrupoCompra == false) {
      Swal.fire('Ops!', 'Usuário não tem acesso ao compras', 'warning');
      this.router.navigate(["/"]);
    }
    //this.listar();
  }

  configurar() {
    let colDefsAux: ColDef[] = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Chave', field: 'key' },
      { headerName: 'Razão Social', field: 'razao_social' },
      { headerName: 'Nome Fantasia', field: 'fantasia' },
      { headerName: 'Serie', field: 'serie' },
      { headerName: 'Data de Emissão', field: 'date_emission', flex: 1, valueFormatter: (params) => `${this.utils.formatarData(params.data.date_emission)}` },
      { headerName: 'Situação', field: 'situation' },
      { headerName: 'CNPJ Emitente', field: 'cnpj_emitter', flex: 1, valueFormatter: (params) => `${this.formatarCNPJ(params.data.cnpj_emitter)}` }
    ];
    //aplica personalizaçãodo grid
    this.PersonalizacaoGridService.GetPorUserTela(EnumGrid.NotasFiscais).subscribe(res => {
      if (res == null) {
        this.PersonalizacaoGridService.postPersonalizacao(EnumGrid.NotasFiscais, 'vazio', false, colDefsAux).subscribe(res => {
          this.colDefs = colDefsAux;
        });
      }
      else {
        colDefsAux.forEach(element => {
          let col = res.filter(x => x.field == element.field)[0];
          if (col.field == element.field) {
            //element.hide = col.hide;
          }
        });
        this.colDefs = colDefsAux;
      }
    });
    //Fim aplica personalizaçãodo grid
  }

  formatarDecimais(value) {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  formatarCNPJ(cnpj: string): string {
    // Remove todos os caracteres que não são números
    cnpj = cnpj.replace(/\D/g, '');

    // Formata o CNPJ com pontos e traço
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }

  acao(acao: string) {
    switch (acao) {
      case 'Manifestar':
        this.AbrirManifesto();
        break;
      case 'Aprovação':
        this.aprovacao();
        break;
      case 'Baixar PDF':
        this.baixarPDF();
        break;
      default:
        break;
    }
  }
  selectionChanged(event) {
    this.notasSelecionados = event;
  }

  cellClicked(row) {
    this.notasSelecionados = row.data;
  }

  cellDoubleClickedItem(event) {

  }

  oncolumnVisibleItens(event) {
    this.PersonalizacaoGridService.postPersonalizacao(EnumGrid.NotasFiscais, event.column.colId, !event.column.visible, this.colDefs).subscribe(res => {
      this.colDefs.forEach(element => {
        let col = res.filter(x => x.field == element.field)[0];
        if (col.field == element.field) {
          //element.hide = col.hide;
        }
      });
    });
  }

  listar(filtro?: FiltroTela) {
    this.ModalCarregando.show();
    this.dataInicio.setHours(0, 0, 0, 0);
    this.dataFim.setHours(0, 0, 0, 0);
    this.notasService.getNotas("", this.dataInicio, this.dataFim, this.empresaInternaId).subscribe(res => {
      this.notas = res.data.invoices;
      if (filtro) {
        this.filtro = filtro;
        if (filtro.Status == 1)
          this.notas = this.notas.filter(p => p.situation == "AUTORIZADA");
        else if (filtro.Status == 2)
          this.notas = this.notas.filter(p => p.situation == "CONTINGENCIA");

        if (filtro.Identificador)
          this.notas = this.notas.filter(p => p.key == filtro.Identificador.toString());
        if (filtro.NumeroDocumento)
          this.notas = this.notas.filter(p => p.cnpj_emitter == filtro.NumeroDocumento.replace(".", "").replace(".", "").replace("/", "").replace("-", ""));
      }
    },
      err => {
        this.utils.getErro(err);
      });
    this.configurar();
    this.ModalCarregando.hide();
  }

  AbrirManifesto() {
    this.ModalManifesto.show();
  }

  FecharManifesto() {
    this.ModalManifesto.hide();
  }

  Manifestar(acao: string) {
    this.ModalCarregando.show();
    this.notasService.mainifesto(this.notasSelecionados[0].key, acao, this.obsModalManifesto, this.empresaInternaId).subscribe(res => {
      this.utils.handleSuccess(res.toString());
    },
      err => {
        if (err.status == 200)
          this.utils.handleSuccess(err.error.text);
        else
          this.utils.getErro(err);

        this.listar(this.filtro);
        this.FecharManifesto();
        this.ModalCarregando.hide();
      });
  }

  aprovacao() {

  }

  baixarPDF() {
    if (!this.notasSelecionados[0]) {
      this.utils.handleWarning("Selecione uma nota para baixar o PDF");
      return;
    }
    window.open(`${environment.apiURL}/Notas/ObterPDF/${this.notasSelecionados[0].key}/${this.empresaInternaId}`, "_parent");
  }

  filtrar(filtro: FiltroTela) {
    this.empresaInternaId = filtro.EmpresaInternaId;
    this.dataInicio = filtro.DataEmissaoInicial;
    this.dataFim = filtro.DataEmissaoFinal;
    this.listar(filtro);
  }
  atualizar() {
    this.listar(this.filtro);
  }
}
