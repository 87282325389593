import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GrupoOperacao } from '../classes/GrupoOperacao';

@Injectable({
  providedIn: 'root'
})
export class GrupoOperacaoService {

  constructor(private http: HttpClient) { }

  getGruposOperacao(): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoOperacao`)
  }

  getGruposOperacaoEmUso(): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoOperacao/ListarParaUso`)
  }

  getGrupoOperacao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoOperacao/${id}`)
  }

  postGrupoOperacao(grupoOperacao: GrupoOperacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/grupoOperacao`, grupoOperacao);
  }

  putGrupoOperacao(id: number, grupoOperacao: GrupoOperacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/grupoOperacao/${id}`, grupoOperacao);
  }

  deleteGrupoOperacao(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/grupoOperacao/${id}`);
  }
}
