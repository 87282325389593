import { Atividade } from './../../classes/Atividade';
import { Funcionario } from './../../classes/Funcionario';
import { Equipamento } from './../../classes/Equipamento';
import { Tarefa } from './../../classes/Tarefa';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TarefaService } from 'src/app/services/tarefa.service';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { AtividadeService } from 'src/app/services/atividade.service';
import { StorageService } from 'src/app/services/storage.service';
import { Area } from 'src/app/classes/Area';
import { AreaService } from 'src/app/services/area.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-tarefas',
  templateUrl: './tarefas.component.html',
  styleUrls: ['./tarefas.component.scss']
})
export class TarefasComponent implements OnInit {

  tarefa: Tarefa;
  tarefas: Tarefa[] = [];
  equipamentos: Equipamento[];
  implementos: Equipamento[];
  funcionarios: Funcionario[];
  atividades: Atividade[];
  areas: Area[];
  auxAreas: Area[] = [];
  fazendaId: number = 0;

  id: number = 0;
  inserindo: boolean = false;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private tarefaService: TarefaService,
              private equipamentoService: EquipamentoService,
              private funcionarioService: FuncionarioService,
              private atividadeService: AtividadeService,
              private storageService: StorageService,
              private areaService: AreaService,
              private utils: UtilsService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.tarefa = new Tarefa();
    this.tarefa.DataInicio = new Date();
    this.tarefa.DataFim = new Date();
    this.fazendaId = this.storageService.getFazenda().Id;

    this.equipamentoService.getEquipamentos().subscribe(res => {
      this.equipamentos = res;
      this.implementos = this.equipamentos.filter(e => e.FlagImplemento);
      this.equipamentos = this.equipamentos.filter(e => !e.FlagImplemento);
    },
    err => {
      this.utils.getErro(err);
    });

    this.funcionarioService.getFuncionarios().subscribe(res => {
      this.funcionarios = res;
    });

    this.atividadeService.getAtividades().subscribe(res => {
      this.atividades = res;
    });

    this.areaService.getAreasPorFazenda(this.fazendaId).subscribe(res => {
      this.auxAreas = res;
      this.areas = this.auxAreas;
    });

    this.inserindo = this.router.url.search("inserir") > 0;
  }

  selecionarFazenda(){
    this.areas = this.auxAreas.filter(a => a.FazendaId == this.fazendaId );
  }

  voltarTarefa(){
    this.id = 0;
    this.inserindo = false;
    this.router.navigate(['/planejamento/tarefas/lista-tarefas']);
  }




}
