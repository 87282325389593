import { ItemApontamento } from './../classes/ItemApontamento';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ItemApontamentoService {

  constructor(private http: HttpClient) { }

  getItensApontamento(apontamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itemApontamento/listar/${apontamentoId}`)
  }

  getItensApontamentoPorOs(ordemServicoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itemApontamento/listarPorOs/${ordemServicoId}`)
  }

  getItemApontamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itemApontamento/${id}`)
  }

  postItemApontamento(itensApontamento: ItemApontamento[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/itemApontamento`, itensApontamento);
  }

  putItemApontamento(id: number, itemApontamento: ItemApontamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/itemApontamento/${id}`, itemApontamento);
  }

  deleteItemApontamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/itemApontamento/${id}`);
  }
}
