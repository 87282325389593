import { AreaImportSimpleData } from "./AreaImportSimpleData";

export class AreaImport {
    constructor(id: number,
        empresaId: number,
        fazendaId: number,
        setorId: number,
        descricao: string,
        areaTotal: number,
        irrigado: boolean,
        proprio: boolean,
        inativa: boolean,
        idERP: number,
        areaDisponivel: number,
        layersArea: string,
        incluir: boolean,
        simpleData: AreaImportSimpleData[]){
            this.Id = id;
            this.EmpresaId = empresaId;
            this.FazendaId = fazendaId;
            this.SetorId = setorId;
            this.Descricao = descricao;
            this.AreaTotal = areaTotal;
            this.Irrigado = irrigado;
            this.Proprio = proprio;
            this.Inativa = inativa;
            this.IdERP = idERP;
            this.AreaDisponivel = areaDisponivel;
            this.LayersArea= layersArea;
            this.SimpleData = simpleData;
            this.Incluir = incluir;
    }
    Id: number;
    EmpresaId: number;
    FazendaId: number;
    SetorId: number;
    Descricao:string = "";
    AreaTotal: number = 0;
    Irrigado: boolean;
    Proprio: boolean;
    Inativa: boolean;
    IdERP: number = 0;
    AreaDisponivel: number = 0;
    LayersArea:string = "";
    SimpleData: AreaImportSimpleData[];
    Incluir: boolean;
}
