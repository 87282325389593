import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Agencia } from 'src/app/classes/Agencia';
import { Bancos } from 'src/app/classes/Bancos';
import { ContaBancaria } from 'src/app/classes/ContaBancaria';
import { ListaTipoContaBancaria } from 'src/app/classes/enum/EnumTipoContaBancaria';
import { AgenciaService } from 'src/app/services/agencia.service';
import { BancosService } from 'src/app/services/banco.service';
import { ContasBancariasService } from 'src/app/services/contas-bancarias.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-contas-bancarias-forms',
  templateUrl: './contas-bancarias-forms.component.html',
  styleUrls: ['./contas-bancarias-forms.component.scss']
})
export class ContasBancariasFormsComponent implements OnInit {

  id:number;
  contaBancaria: ContaBancaria;
  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;

  litaTipo = ListaTipoContaBancaria;
  listaAgencias: Agencia[];
  listaBancos: Bancos[] = [];

  cifraMoedaCorrente: string = '';

  constructor(
    private bancosService: BancosService,
    private agenciaService: AgenciaService,
    private contasBancariasService: ContasBancariasService,
    private route: ActivatedRoute,
    private router: Router,
    private toasty: CustomToastyService,
    private utils : UtilsService) {
      this.route.params.subscribe(res => {
        this.id = res.id;
      });
    }

  ngOnInit() {
    this.contaBancaria = new ContaBancaria();
    if(this.router.url.search("inserir") > 0)
      this.contaBancaria = new ContaBancaria()
    else
      this.selecionarContaBancaria();

    this.bancosService.Listar().subscribe(res => {
      this.listaBancos = res;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
    this.agenciaService.Listar().subscribe(res => {
      this.listaAgencias = res;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });

    this.cifraMoedaCorrente = this.utils.getCifraMoeda();
  }

  selecionarContaBancaria() {
    this.carregando = true;
    this.contasBancariasService.ObterPorId(this.id).subscribe(res =>{
      this.contaBancaria = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.contaBancaria.Id > 0){
      this.contasBancariasService.Atualizar(this.contaBancaria.Id, this.contaBancaria).subscribe(
        res => {
          this.toasty.show('Feito!', 'Conta bancária alterada!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/contas-bancarias']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
    else{

      this.contasBancariasService.Inserir(this.contaBancaria).subscribe(
        res => {
          this.toasty.show('Feito!', 'Conta bancária cadastrada!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/contas-bancarias']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
  }



  cancelar(){
    this.router.navigate(['/cadastros/contas-bancarias']);
  }

}
