import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Produto } from 'src/app/classes/Produto';
import { RelatorioMovimentoEstoque } from 'src/app/classes/movimento-estoque/RelatorioMovimentoEstoque';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { FiltroProdutoMovimentoEstoque } from '../../../../classes/movimento-estoque/FiltroProdutoMovimentoEstoque';

@Component({
  selector: 'app-movimento-estoque-relatorio',
  templateUrl: './movimento-estoque-relatorio.component.html',
  styleUrls: ['./movimento-estoque-relatorio.component.scss']
})
export class MovimentoEstoqueRelatorioComponent implements OnInit {

  produtos: Produto[] = [];
  searchProduto$ = new Subject<string>();

  locaisEstoque: LocalEstoque[] = [];

  relatorio: RelatorioMovimentoEstoque[];

  filtro = new FiltroProdutoMovimentoEstoque();
  produtoSelecionadoId: number = null;
  localEstoqueSelecionadoId: number = null;
  dataInicio: Date = null;
  dataFim: Date = null;

  constructor(
    private produtoService: ProdutoService,
    private localEstoqueService: LocalEstoqueService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.localEstoqueService.getLocaisEstoque().subscribe(
      res => this.locaisEstoque = res,
      err => this.utilsService.getErro(err)
    )

    this.searchProduto$.pipe(
      debounceTime(500),
      switchMap(term => {
        if (term.length < 2) return [];
        return this.produtoService.listarPorDescricao(term);
      })
    ).subscribe(res =>
      this.produtos = res,
      err => this.utilsService.getErro(err)
    )
  }

  limparListaProdutos() {
    this.produtos = [];
  }

  listarProdutos(term: string) {
    this.searchProduto$.next(term);
  }

  buscar() {
    if (this.filtro.ProdutoId === null || this.filtro.ProdutoId === undefined) {
      this.utilsService.handleWarning("Selecione um produto para prosseguir");
      return;
    }
    this.produtoService.relatorioMovimentacao(this.filtro).subscribe(res =>
      this.relatorio = res,
      err => this.utilsService.getErro(err)
    )
  }
}
