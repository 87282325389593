import { ChamadoModule } from './components/chamado/chamado.module';
import { CotacaoModule } from './components/cotacao/cotacao.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TarefasModule } from './components/tarefas/tarefas.module';
import { SharedFarmModule } from './components/shared-farm/shared-farm.module';
import { RelatoriosModule } from './components/relatorios/relatorios.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import localeBrExtra from '@angular/common/locales/extra/pt';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PainelComponent } from './components/painel/painel.component';
import { GerenciadorModule } from './components/gerenciador/gerenciador.module';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HttpClient} from '@angular/common/http';

import { Interceptor } from './guards/interceptor/interceptor.module';
import { CadastrosModule } from './components/cadastros/cadastros.module';
import { EstoqueModule } from './components/estoque/estoque.module';
import { SelecionaEmpresaModule } from './components/seleciona-empresa/seleciona-empresa.module';
import { ToastyModule } from 'ng2-toasty';
import { PlanejamentoModule } from './components/planejamento/planejamento.module';
import { OrdemServicoModule } from './components/ordem-servico/ordem-servico.module';
import { TesteLeafletComponent } from './components/teste-leaflet/teste-leaflet.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MapaTelemetriaComponent } from './components/mapa-telemetria/mapa-telemetria.component';
import { ComentarioSafraComponent } from './components/mapa/comentario-safra/comentario-safra.component';
import { PerfilModule } from './components/perfil/perfil.module';
import { FiltrosComponent } from './components/mapa/filtros/filtros.component';
import { DetalheChamadoComponent } from './components/chamado/detalhe-chamado/detalhe-chamado.component';
import { DocumentacaoComponent } from './components/documentacao/documentacao.component';
import { SafePipe } from './components/shared-farm/pipes/safe.pipe';
import { ErpComponent } from './theme/layout/erp/erp.component';
import { NavBarErpComponent } from './theme/layout/erp/nav-bar-erp/nav-bar-erp.component';
import { ConfiguracoesComponent } from './components/configuracoes/configuracoes.component';
import { RouterModule } from '@angular/router';
import { DiagnoseModule } from './components/diagnose/diagnose.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ManutencoesComponent } from './components/manutencoes/manutencoes.component';
import { MovimentoEstoqueModule } from './components/cadastros/movimento-estoque/movimento-estoque.module';

import { NgxPaginationModule } from 'ngx-pagination';
registerLocaleData(localeBr);

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    PainelComponent,
    LoginComponent,
    TesteLeafletComponent,
    MapaComponent,
    MapaTelemetriaComponent,
    ComentarioSafraComponent,
    ErpComponent,
    NavBarErpComponent,
    ManutencoesComponent,
    ConfiguracoesComponent,
    ConfiguracoesComponent,
    FiltrosComponent,
    DocumentacaoComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    ChamadoModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    GerenciadorModule,
    CadastrosModule,
    EstoqueModule,
    PlanejamentoModule,
    OrdemServicoModule,
    SelecionaEmpresaModule,
    RelatoriosModule,
    Interceptor,
    SharedFarmModule,
    TarefasModule,
    NgSelectModule,
    ToastyModule.forRoot(),
    CurrencyMaskModule,
    CotacaoModule,
    PerfilModule,
    DiagnoseModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDHmPV5eFxpOU9mKXax4veX67OblGbQ-XA'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
    }),
    MovimentoEstoqueModule,
    NgxPaginationModule
  ],
  providers: [
    NavigationItem
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
