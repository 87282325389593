import { AgrupamentoOrdemServicoService } from './../../../../services/agrupamento-ordem-servico.service';
import { StorageService } from './../../../../services/storage.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { ItemOrdemServico } from 'src/app/classes/ItemOrdemServico';
import { ItemOrdemServicoService } from 'src/app/services/item-ordem-servico.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { Funcionario } from './../../../../classes/Funcionario';
import { Atividade } from './../../../../classes/Atividade';
import { TipoAplicacao } from './../../../../classes/TipoAplicacao';
import { OrdemServico } from './../../../../classes/OrdemServico';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Produto } from 'src/app/classes/Produto';
import Swal from 'sweetalert2';
import { UtilsService } from 'src/app/utils/utils.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { auxPossuiIntegracao } from 'src/app/classes/auxiliar/auxPossuiIntegracao';
import { auxIntegracaoService } from 'src/app/services/auxIntegracaoService';
import { ApontamentoComponent } from 'src/app/components/ordem-servico/apontamento/apontamento.component';

@Component({
  selector: 'app-detalhamento-tabela',
  templateUrl: './detalhamento-tabela.component.html',
  styleUrls: ['./detalhamento-tabela.component.scss']
})
export class DetalhamentoTabelaComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('Apontamento', { static: false }) apontamentoComponent: ApontamentoComponent;

  @Input() ordensServico: OrdemServico[];
  @Input() carregando: boolean;
  @Input() tiposAplicacao: TipoAplicacao[];
  @Input() atividades: Atividade[];
  @Input() funcionarios: Funcionario[];

  @Input() agruparPorFazenda: boolean;
  @Input() agruparPorSafra: boolean;
  @Input() agruparPorArea: boolean;
  @Input() agruparPorEtapa: boolean;

  @Input() filtros: any;
  @Output() atualizarLista: EventEmitter<any> = new EventEmitter();

  processando: boolean = false;

  ordemServicoApontamento: OrdemServico;
  ordemServicoApontamentoIndicadores: OrdemServico;
  ordemServicoLiberacao: OrdemServico;

  preventSingleClick = false;
  timer: any;
  delay: number = 0;

  osDetalhe: number = 0;

  itensOrdemServico: ItemOrdemServico[];
  produtos: Produto[];

  permissaoLiberar: boolean = false;
  auxPossuiIntegracao: auxPossuiIntegracao = new auxPossuiIntegracao();
  @Input() organizaPorSetor: boolean;

  // tradução
EXCLUIR_O_AGRUPAMENTO_DE_ORDEM_DE_SERVICO_: string = 'Excluir o Agrupamento de Ordem de Serviço #';
SE_EXCLUIR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se excluir, esta ação não poderá ser desfeita!';
VOLTAR: string = 'Voltar';
CONFIRMAR: string = 'Confirmar';
FEITO: string = 'Feito!';
AGRUPAMENTO_DE_ORDEM_DE_SERVICO_EXCLUIDO: string = 'Agrupamento de Ordem de Serviço Excluído!';

  constructor(private empresaService: EmpresaService,
              private router: Router,
              private http: HttpClient,
              private itemOrdemServicoService: ItemOrdemServicoService,
              private produtoService:ProdutoService,
              private storageService: StorageService,
              private agrupamentoOrdemServicoService: AgrupamentoOrdemServicoService,
              private utils: UtilsService,
              private integracaoService: auxIntegracaoService,
              private toasty: CustomToastyService,
              private translate: TranslateService,
              private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processando = true;
    this.ordensServico.forEach(o => {
      o.DescricaoSafraGroupBy = o.DescricaoFazenda + '-' + o.DescricaoSafra;
      o.DescricaoAreaGroupBy = o.DescricaoFazenda + '-' + o.DescricaoArea;
    });

    let fazendaAtual: String = '';
    let safraAtual: String = '';
    let areaAtual: String = '';
    let etapaAtual: String = '';

    this.ordensServico.forEach(o => {
      if (fazendaAtual != o.DescricaoFazenda) {
        o.AgruparFazenda = true;
        fazendaAtual = o.DescricaoFazenda;
      }

      if (etapaAtual != o.DescricaoEtapa) {
        o.AgruparEtapa = true;
        etapaAtual = o.DescricaoEtapa;
      }

      if (safraAtual != o.DescricaoSafraGroupBy) {
        o.AgruparSafra = true;
        safraAtual = o.DescricaoSafraGroupBy;
      }

      if (areaAtual != o.DescricaoAreaGroupBy) {
        o.AgruparArea = true;
        areaAtual = o.DescricaoAreaGroupBy;
      }
    })

    this.processando = false;
  }

  ngOnInit() {
    this.integracaoService.getIntegracao().then(res => {
      this.auxPossuiIntegracao = res;
    });
    this.permissaoLiberar = this.storageService.getUsuario().PermissaoUsuario.LiberarOS;
  }

  getAtividade(id: number): Atividade {
    if (!this.atividades)
      return new Atividade();

    let atividades = this.atividades.filter(x =>
      x.Id == id
    );

    return atividades.length > 0 ? atividades[0] : new Atividade();
  }

  getTipoAplicacao(id: number): TipoAplicacao {
    if (!this.tiposAplicacao)
      return new TipoAplicacao();

    let tiposAplicacao = this.tiposAplicacao.filter(x =>
      x.Id == id
    );

    return tiposAplicacao.length > 0 ? tiposAplicacao[0] : new TipoAplicacao();
  }

  getFuncionario(id: number): Funcionario {
    if (!this.funcionarios)
      return new Funcionario();

    let funcionarios = this.funcionarios.filter(x =>
      x.Id == id
    );

    return funcionarios.length > 0 ? funcionarios[0] : new Funcionario();
  }

  toogleFazenda(descricao: String) {
    this.ordensServico.filter(o => o.DescricaoFazenda == descricao).forEach(o => o.CollapsedFazenda = !o.CollapsedFazenda);
  }

  toogleEtapa(descricao: String) {
    this.ordensServico.filter(o => o.DescricaoEtapa == descricao).forEach(o => o.CollapsedEtapa = !o.CollapsedEtapa);
  }

  toogleSafra(descricao: String) {
    this.ordensServico.filter(o => o.DescricaoSafraGroupBy == descricao).forEach(o => o.CollapsedSafra = !o.CollapsedSafra);
  }

  toogleArea(descricao: String) {
    this.ordensServico.filter(o => o.DescricaoAreaGroupBy == descricao).forEach(o => o.CollapsedArea = !o.CollapsedArea);
  }

  imprimir(id: number){
    this.carregando = true;
    this.http.get(`${environment.apiURL}/ordemServico/gerarPDF/${this.empresaService.getEmpresaSelecionada().Id}/${id}/${this.storageService.getUsuario().Id}`,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
    if (this.storageService.getUsuario().Id != this.ordensServico.find(x => x.Id == id).UsuarioAprovacaoId)
      this.ordensServico.find(x => x.Id == id).OsImpressa = true;
  }

  abrir(id: number) {
    //window.open(`/ordem-servico/ordem-servico/${id}`, "_blank");
    this.router.navigate(['/ordem-servico/ordem-servico/' + id]);
  }

  apontamento(ordemServico: OrdemServico) {
    this.carregando = true;
    this.ordemServicoApontamento = ordemServico;
    this.apontamentoComponent.novoApontamentoComOrdem(ordemServico);
  }

  apontamentoInidicadores(ordemServico: OrdemServico) {
    this.ordemServicoApontamentoIndicadores = ordemServico;
  }
  liberacao(ordemServico: OrdemServico) {
    this.ordemServicoLiberacao = ordemServico;
  }

  doubleClick (event) {
    console.log("duplo");
  }

  adicionarERP()
  {
    if(this.auxPossuiIntegracao.possuiIntegracao)
      this.produtos.forEach(prod =>{
        if (!prod.Descricao.includes('IDERP:')) {
          prod.Descricao = prod.Descricao + ' - IDERP: ' + prod.IdERP;
        }
    });
  }

  exibeItens(id: number) {
    this.osDetalhe = id;
    this.itensOrdemServico = [];
    this.produtos = [];
    if (id > 0) {
      this.itemOrdemServicoService.getItensOrdemServico(id).subscribe(ret => {
        this.itensOrdemServico = ret;
        this.itensOrdemServico.forEach(x => {
          this.produtoService.getProduto(x.ProdutoId).subscribe(retProd => {
            this.produtos.push(retProd);
          })

        })
      })
    }
    this.adicionarERP();
  }

  getProduto(id: number): Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';

    if (!this.produtos)
      return novo;

    let produto = this.produtos.filter(x =>
      x.Id == id
    );

    return produto.length > 0 ? produto[0] : novo;
  }

  liberado() {
    this.carregando = false;
    this.ordensServico = this.ordensServico.filter(x => x.Id != this.ordemServicoLiberacao.Id);
    this.ordemServicoLiberacao = null;
    this.carregando = false;
  }

  fechado() {
    this.carregando = false;
  }
  apontado() {
    this.carregando = false;
    console.log("False");
    this.ordensServico = this.ordensServico.filter(x => x.Id != this.ordemServicoApontamento.Id);
    this.ordemServicoApontamento = null;
    if(this.ordemServicoApontamentoIndicadores != null)
      this.ordensServico = this.ordensServico.filter(x => x.Id != this.ordemServicoApontamentoIndicadores.Id);
    this.ordemServicoApontamentoIndicadores = null;
    this.carregando = false;
  }

  apontadoIndicadores() {
    this.carregando = false;
    this.ordensServico = this.ordensServico.filter(x => x.Id != this.ordemServicoApontamentoIndicadores.Id);
    this.ordemServicoApontamentoIndicadores = null;
    this.carregando = false;
  }

  imprimirAgrupamento(agrupamentoId: number) {
    this.http.get(`${environment.apiURL}/ordemServico/agrupamento/gerarPDF/${this.empresaService.getEmpresaSelecionada().Id}/${agrupamentoId}`,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      window.URL.revokeObjectURL(url);
    }, err => {
      this.utils.getErro(err)
    });
  }

  excluirAgrupamento(agrupamentoId: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.EXCLUIR_O_AGRUPAMENTO_DE_ORDEM_DE_SERVICO_ + agrupamentoId.toString().padStart(5, "0") + '?',
      text: this.SE_EXCLUIR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.VOLTAR,
      confirmButtonText: this.CONFIRMAR,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.agrupamentoOrdemServicoService.deleteAgrupamentoOrdemServico(agrupamentoId).subscribe(ret => {
          this.toasty.show(this.FEITO, this.AGRUPAMENTO_DE_ORDEM_DE_SERVICO_EXCLUIDO, 'success');
          this.recarregar();
          this.carregando = false;
        },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          })
      }
    });
  }

  recarregar() {
    this.atualizarLista.emit(this.filtros);
  }

  configurarTraducao() {
    this.EXCLUIR_O_AGRUPAMENTO_DE_ORDEM_DE_SERVICO_ = this.translate.instant('EXCLUIR_O_AGRUPAMENTO_DE_ORDEM_DE_SERVICO_');
    this.SE_EXCLUIR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_EXCLUIR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.VOLTAR = this.translate.instant('VOLTAR');
    this.CONFIRMAR = this.translate.instant('CONFIRMAR');
    this.FEITO = this.translate.instant('FEITO');
    this.AGRUPAMENTO_DE_ORDEM_DE_SERVICO_EXCLUIDO = this.translate.instant('AGRUPAMENTO_DE_ORDEM_DE_SERVICO_EXCLUIDO');
}

}
