import { StorageService } from 'src/app/services/storage.service';
import { EnumStatusOS } from 'src/app/classes/OrdemServico';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { Fazenda } from 'src/app/classes/Fazenda';
import { FazendaService } from 'src/app/services/fazenda.service';
import { AreaService } from 'src/app/services/area.service';
import { Area } from 'src/app/classes/Area';
import { Safra } from 'src/app/classes/Safra';
import { SafraService } from 'src/app/services/safra.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { RetornoCusto } from 'src/app/classes/RetornoCusto';
import { FiltroRelatorio } from '../../relatorios-componente/filtro-relatorios/filtro-relatorios.component';
import { UtilsService } from 'src/app/utils/utils.service';



interface produtoRet{
  id:number,
  nome: string,
  insumo: number,
  insumoHa:number,
  doseMedia:number
}

@Component({
  selector: 'app-custo-realizado-area',
  templateUrl: './custo-realizado-area.component.html',
  styleUrls: ['./custo-realizado-area.component.scss']
})
export class CustoRealizadoAreaComponent implements OnInit, OnChanges {

  @Input() filtro: FiltroRelatorio;

  gruposProduto: GrupoProduto[];
  auxGruposProduto: GrupoProduto[];
  fazendas: Fazenda[] = [];
  safras: Safra[] = [];
  auxSafras: Safra[] = [];
  areas: Area[] = [];
  auxAreas: Area[] = [];
  fazendaId:number;
  safraId:number;
  areaId: number = 0;
  fazendaDescricao: string = "";
  safraDescricao:string = "";
  carregando: boolean;
  processando: boolean;
  retornoCusto: RetornoCusto[] = [];
  auxRetornoCusto: RetornoCusto[] = [];

  produtosRet: produtoRet[]=[];
  auxProdutosRet: produtoRet[]=[];

  configGraficoBar: any;
  ordemAtual: string;
  idAreaSelecionado: number = 0;

  cifraMoedaCorrente: string = '';

  public options: any = {
    legend: { position: 'bottom' }
  }

  constructor(private grupoProdutoService: GrupoProdutoService,
              private areaService: AreaService,
              private safraService: SafraService,
              private ordemServicoService: OrdemServicoService,
              private storageService: StorageService,
              private utils: UtilsService
            ) { }

  ngOnInit() {
    this.grupoProdutoService.getGruposProduto().subscribe(ret=>{
      this.gruposProduto = ret;
      this.auxGruposProduto = this.gruposProduto;
      //this.gruposProduto.length = 15;
    })

    this.fazendaId = this.storageService.getFazenda().Id;
    this.fazendaDescricao = this.storageService.getFazenda().Nome;
    this.filtraSafras();
    this.areaService.getAreas().subscribe(ret =>{
      this.auxAreas = ret;
      this.filtraAreas();
    });
    this.cifraMoedaCorrente = this.utils.getCifraMoeda();
  }

  filtraSafras(){
    this.carregando = true;
    this.safraService.getSafrasPorAnoFazenda(0, this.fazendaId).subscribe(ret => {
      this.auxSafras = ret;
      this.safras = this.auxSafras.sort((x1, x2) => x2.Id - x1.Id);
      this.carregando=false;
      //Pega safra da tela inicial
      let safraOrigem = this.storageService.getSafraCusto();
      if(safraOrigem > 0){
        this.selectionarSafra(safraOrigem);
        //Limpar a safra da tela inicial
        this.storageService.setSafraCusto(0);
      }
      else if(this.safras.length > 0){
        this.selectionarSafra(this.safras[0].Id);
      }

    })
  }

  filtraAreas(){
    this.areas = this.auxAreas.filter(x=> x.FazendaId == this.fazendaId);
  }

  selectionarSafra(id: number){
    this.safraId = id;
    let safra = this.auxSafras.find(x=> x.Id == this.safraId);
    if(safra)
      this.safraDescricao = safra.Descricao;
    this.listarCustos();
  }

  listarCustos(){
    this.carregando = true;
    var filtro: any;
    if(this.filtro.filtrarPeriodo)
      filtro = {
          Status: [EnumStatusOS.Concluida],
          Periodo: 0,
          Fazenda: [this.fazendaId ? this.fazendaId : 0],
          Safra: [this.safraId ? this.safraId : 0],
          AreaId: this.filtro.areaId ? this.filtro.areaId : 0,
          AnoSafraId: this.filtro.anoSafraId ? this.filtro.anoSafraId : 0,
          DataInicio: this.filtro.dataInicio,
          DataFim: this.filtro.dataFim
      }
    else
    filtro = {
      Status: [EnumStatusOS.Concluida],
      Periodo: 0,
      Fazenda: [this.fazendaId ? this.fazendaId : 0],
      Safra: [this.safraId ? this.safraId : 0],
      AreaId: this.areaId ? this.areaId : 0
    }

    this.ordemServicoService.custo(filtro).subscribe(
      ret=>{
        this.retornoCusto = ret;
        this.auxRetornoCusto = ret;
        this.gruposProduto = this.auxGruposProduto.filter(gru => this.retornoCusto.filter(c => c.GrupoProdutoId == gru.Id).length > 0 );
        this.getGrafico();
        this.carregando = false;
      },
      err=>{
        console.log("ERRO CUSTO => ", err);
        this.carregando = false;
      });
  }

  getCusto(areaId: number, grupoProdutoId: number):number{
    let valor = 0;
    this.retornoCusto.filter(x=> x.AreaId == areaId && x.GrupoProdutoId == grupoProdutoId).forEach(x =>{
      valor += x.PrecoPorHa;
    })

    return valor;
  }

  getTotalValor(areaId):number{
    let total = 0;
    this.retornoCusto.filter(x=> x.AreaId == areaId).forEach(x =>{
      total += x.PrecoPorHa;
    })
    return total;
  }

  getTotalValorGrupo(grupoId: number):number{
    let total = 0;
    let areaTotal = 0;

    this.areas.forEach(a => {
      let areaArea = 0;
      this.retornoCusto.filter(x=> x.AreaId == a.Id).forEach(x =>{
        if(areaArea == 0)
          areaArea= x.AreaTotal;
      })
      areaTotal +=areaArea;
    })

    this.retornoCusto.filter(x=> x.GrupoProdutoId == grupoId).forEach(x =>{
      total += x.PrecoTotal;
    })

    if(areaTotal == 0)
      areaTotal = 1;

    return total/areaTotal;
  }

  getAreaPlantada(areaId):number{
    return (this.retornoCusto.filter(x=> x.AreaId == areaId).length > 0 ? this.retornoCusto.filter(x=> x.AreaId == areaId)[0].AreaTotal : 0);
  }

  getTotalAreaPlantada():number{
    let areaTotal = 0;
    this.areas.forEach(a => {
      let areaArea = 0;
      this.retornoCusto.filter(x=> x.AreaId == a.Id).forEach(x =>{
        if(areaArea == 0)
          areaArea= x.AreaTotal;
      })
      areaTotal +=areaArea;
    })
    return areaTotal;
  }

  getCustoTotal():number{
    let total = 0;
    this.retornoCusto.forEach(x =>{
      total += x.PrecoTotal;
    })
    if(Number.isNaN( total))
      return 0;
    return total;
  }

  getCustoTotalHa(): number{
    let total = this.getCustoTotal();
    let totalArea = this.getTotalAreaPlantada();
    let ret= total/totalArea;
    if(Number.isNaN( ret))
      return 0;
      return ret;
    }

  getGrafico(){
    this.carregando = true;
    let descricoes:string[] = [];
    let valores:string[] =[];
    this.produtosRet = [];
    this.auxProdutosRet = [];
    let mSeries = [];

    this.retornoCusto.filter(
      (thing, i, arr) => arr.findIndex(t => t.ProdutoId === thing.ProdutoId) === i
    ).forEach(ret=>{
      let produtoRet: produtoRet ={ id: ret.ProdutoId, nome: ret.ProdutoDescricao, insumo: ret.PrecoTotal, insumoHa: ret.PrecoPorHa, doseMedia: (ret.PrecoPorHa > 0 ? ret.PrecoTotal / ret.PrecoPorHa : 0)}
      this.produtosRet.push(produtoRet);
    });

    this.auxProdutosRet = this.produtosRet;
    this.ordenarProdutos("nome");


    this.areas.forEach(area =>{
      let total = 0;
      this.retornoCusto.filter(x => x.AreaId == area.Id).forEach(ret =>{
        total += ret.PrecoPorHa;
      })
      if(total > 0){
        descricoes.push(area.Descricao);
        valores.push(total.toFixed(2));

        mSeries.push({
          x: area.Descricao,
          y: total.toFixed(2),
          id:area.Id
        });

      }
    })

    mSeries = mSeries.sort((x1, x2) => x2.y - x1.y);


    this.configGraficoBar = {
      chart: {
        height: 450,
        type: 'bar',
        events: {
          dataPointSelection: function(event, chartContext, config) {
            let indexClicado: number = config.dataPointIndex;
            this.idAreaSelecionado = mSeries[indexClicado].id;
            document.getElementById("areaSelecionado").setAttribute('value', mSeries[indexClicado].id);
            document.getElementById("areaSelecionado").setAttribute('ng-reflect-model', mSeries[indexClicado].id);
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      colors: ['#4680ff', '#0e9e4a'],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          colors: ['#fff']
        }
      },

      series: [{
        data: mSeries,
        title: 'R$/ha'
      }],

      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: true
        },
        y: {
          title: {
            formatter: (seriesName) => 'R$/ha'
          }
        },
        marker: {
          show: false
        }
      }

    };

    this.carregando = false;

  }

  ordenarProdutos(campo:string){
    this.ordemAtual = campo;
    if(campo == "nomeDesc")
      this.produtosRet = this.produtosRet.sort((x1, x2) => (x1.nome > x2.nome ? -1 : 1))
    if(campo == "nome")
      this.produtosRet = this.produtosRet.sort((x1, x2) => (x2.nome > x1.nome ? -1 : 1))

    if(campo == "insumo")
      this.produtosRet = this.produtosRet.sort((x1, x2) => x1.insumo - x2.insumo);

    if(campo == "insumoDesc")
      this.produtosRet = this.produtosRet.sort((x1, x2) => x2.insumo - x1.insumo);

    if(campo == "insumoHa")
      this.produtosRet = this.produtosRet.sort((x1, x2) => x1.insumoHa - x2.insumoHa);
    if(campo == "insumoHaDesc")
      this.produtosRet = this.produtosRet.sort((x1, x2) => x2.insumoHa - x1.insumoHa);

    if(campo == "doseMedia")
      this.produtosRet = this.produtosRet.sort((x1, x2) => x1.doseMedia - x2.doseMedia);

    if(campo == "doseMediaDesc")
      this.produtosRet = this.produtosRet.sort((x1, x2) => x2.doseMedia - x1.doseMedia);
  }

  filtrarPorArea(){
    this.produtosRet = [];
    this.retornoCusto.filter(
      (thing, i, arr) => arr.findIndex(t => t.ProdutoId === thing.ProdutoId) === i
    ).filter(x=> (this.idAreaSelecionado == 0 || x.AreaId == this.idAreaSelecionado)).forEach(ret=>{
      let produtoRet: produtoRet ={ id: ret.ProdutoId, nome: ret.ProdutoDescricao, insumo: ret.PrecoTotal, insumoHa: ret.PrecoPorHa, doseMedia: (ret.PrecoPorHa > 0 ? ret.PrecoTotal / ret.PrecoPorHa : 0)}
      this.produtosRet.push(produtoRet);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtro) {
      if(this.filtro.safraId !== null && this.filtro.safraId > 0 && this.safraId !== this.filtro.safraId){
        this.safraId = this.filtro.safraId;
        this.areaId = null;
        this.selectionarSafra(this.safraId);
      }
      else
        this.listarCustos();

    }
  }

}

