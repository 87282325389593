import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { CentroDeResultadosService } from 'src/app/services/centro-de-resultados.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forms-centro-de-resultados',
  templateUrl: './forms-centro-de-resultados.component.html',
  styleUrls: ['./forms-centro-de-resultados.component.scss']
})
export class FormsCentroDeResultadosComponent implements OnInit {

  constructor(private centroDeResultadosService: CentroDeResultadosService,
              private utils: UtilsService,
              private router: Router,
              private location: Location) {
    this.centroDeResultadosService.getCentrosDeResultados()
    .subscribe(res => {
        this.centrosDeResultados = res;
        if (this.router.url.search('inserir') > 0) {
          document.getElementById("resetarForm").click();
        }
    }, err => {this.utils.getErro(err)})
  }

  @Input() customMask: string;

  @Input() centroDeResultados: CentroDeResultados;
  @Output() atualizarArvore = new EventEmitter<any>();
  centrosDeResultados: CentroDeResultados[];
  isSubmit: boolean;
  carregando: boolean

  ngOnInit() {

  }
  save(form: any, novo: boolean = false) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.centroDeResultados.Id > 0)
    {
      if(this.centroDeResultados.Codigo == this.centroDeResultados.CodigoPai.toString()){
        this.utils.handleWarning('Natureza pai não pode ser ele mesmo!')
          this.carregando = false;
          return;
      }

      this.centroDeResultadosService.putCentroDeResultados(this.centroDeResultados.Id, this.centroDeResultados).subscribe(
        res => {
          this.utils.handleSuccess('Centro de Resultados alterado!')
          this.atualizarArvore.emit();
          this.carregando = false;
          this.novoItem(form, res, novo);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else
      this.centroDeResultadosService.postCentroDeResultados(this.centroDeResultados).subscribe(
        res => {
          this.utils.handleSuccess('Centro de Resultados cadastrada!')
          this.atualizarArvore.emit();
          this.carregando = false;
          this.novoItem(form, res, novo);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  private novoItem(form: any, response: any, novo: boolean){
    if(novo){
      this.isSubmit = false;
      form.reset();
      this.centroDeResultados = new CentroDeResultados();
      this.centroDeResultadosService.getCentrosDeResultados()
      .subscribe(res2 => {
        this.centrosDeResultados = res2;
        this.centroDeResultados.CodigoPai = parseInt(response.Codigo);
      }, err => {this.utils.getErro(err)})
    }else
      this.router.navigate(['/cadastros/centro-de-resultados/'])
  }

  excluir(id: number, nome: string) {
    Swal.fire({
      title: 'Deletar '+ nome + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.centroDeResultadosService.deleteCentroDeResultados(id).subscribe(
            res => {
              this.utils.handleSuccess('Natureza deletada!')
              this.router.navigate(['/cadastros/centro-de-resultados'])
              this.carregando = false;
            },
            err => {
              Swal.fire('Erro!', err.message, 'error');
              this.carregando = false;
            });
        }
      });
  }
  resetarForm(form: any){
    form.resetForm();
    this.centroDeResultados = new CentroDeResultados();
  }

}
