import { ItemEnum } from '../ItemEnum';
import { Produto } from './../Produto';
import { ItemDescontoProducao } from './ItemDescontoProducao';
export class EntradaProducao {
    Id: number;
    Terceiro:boolean = false;
    DataEntrada:Date;
    FazendaId: number;
    SafraId: number;
    AreaId: number;
    Gleba:string = "";
    AreaColhida:number = 0;
    DestinoProducao:enumDestinoProducao;
    LocalEstoqueId: number;
    Ticket:string = "";
    OrdemServicoId: number;
    EquipamentoId: number;
    Motorista:string = "";
    PesoEntrada:number = 0;
    PesoSaida:number = 0;
    PesoLiquido:number = 0;
    ProdutoId: number;
    Umidade:number = 0;
    Temperatura:number = 0;
    Peneira: number = 0;
    Observacao:string = "";
    ATR:number = 0;
    TCH:number = 0;
    TAH:number = 0;

    //auxiliares do salvar
    ItensDescontoProducao:ItemDescontoProducao[];
}

export enum enumDestinoProducao {
    Interno = 1,
    Externo = 2,
    Venda = 3
}


export const DestinosProducao: ItemEnum[] = [
    {name: 'Armazem Interno', value: enumDestinoProducao.Interno},
    {name: 'Armazem Externo', value: enumDestinoProducao.Externo},
    {name: 'Venda Direta', value: enumDestinoProducao.Venda}
  ];
