import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { EnumEventoAprovacao, RegistrosAprovacoes } from 'src/app/classes/RegistrosAprovacoes';

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './modal-confirmacao.component.html',
  styleUrls: ['./modal-confirmacao.component.scss']
})
export class ModalConfirmacaoComponent implements OnInit {
  @ViewChild('ModalAprovacao', { static: true }) modalAprovacao
  @Output() alterarStatus: EventEmitter<any> = new EventEmitter();

  isSubmit: boolean = false;
  carregando: boolean = false;
  evento: EnumEventoAprovacao;
  observacao: string;

  constructor() { }

  ngOnInit() {
  }
  abrirModal(evento: EnumEventoAprovacao): void {
    this.evento = evento;
    this.modalAprovacao.show();
  }
  cancelar(): void {
    this.modalAprovacao.hide();
    this.carregando = false;
    this.observacao = '';
  }

  continuar( form: any): void {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    this.alterarStatus.emit({obs: this.observacao, evento: this.evento});
  }
}
