import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomFormsModule } from 'ng2-validation';
import { NgxMaskModule } from 'ngx-mask';
import { CardModule } from "../../../theme/shared/components/card/card.module";
import { SharedFarmModule } from '../../shared-farm/shared-farm.module';
import { GrupoCompraComponent } from './grupo-compra.component';
import { SharedModule } from '../../../theme/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SharedFarmModule,
    NgSelectModule
  ],
  declarations: [
    GrupoCompraComponent
  ],
  exports: [
    GrupoCompraComponent
  ]
})
export class GrupoCompraModule { }
