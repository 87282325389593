import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Etapa } from '../classes/Etapa';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoDestinoService {

  constructor(private http: HttpClient) { }

  listarPorIdOrigem(transacaoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ConfiguracaoDestino/listarPorIdOrigem/${transacaoId}`)
  }
}
