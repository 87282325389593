import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { AtividadeService } from './../../../services/atividade.service';
import { GrupoOperacaoService } from './../../../services/grupo-operacao.service';
import  Swal  from 'sweetalert2';
import { Atividade } from './../../../classes/Atividade';
import { GrupoOperacao } from './../../../classes/GrupoOperacao';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planejado-realizado',
  templateUrl: './planejado-realizado.component.html',
  styleUrls: ['./planejado-realizado.component.scss']
})
export class PlanejadoRealizadoComponent implements OnInit {

  periodo: number = 1;
  carregando: boolean;
  recolherFiltro: boolean = false;

  gruposOperacao: GrupoOperacao[];
  atividades: Atividade[];

  dataGantt: any[];
  tipoVisualizacao: number = 0;

  filtros: any;

  constructor(
    private grupoOperacaoService: GrupoOperacaoService,
    private atividadeService: AtividadeService,
    private planejamentoService: PlanejamentoService
  ) { }

  ngOnInit() {
    this.grupoOperacaoService.getGruposOperacao().subscribe(ret =>{
      this.gruposOperacao = ret;
      this.atividadeService.getAtividades().subscribe(ret =>{
        this.atividades = ret;
      });
    });
  }

  filtrar(filtros: any) {
    this.filtros = filtros;

    //this.ordensServico = [];
    this.carregando = true;
    this.tipoVisualizacao = filtros.tipoVisualizacao;

    this.planejamentoService.previstoRealizadoGantt(filtros).subscribe(
      res => {
        this.dataGantt = res;
        this.carregando = false;
      },
      err => {
        Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

}
