export class VwAplicacoesLavoura{
    Id: number = 0;
    EmpresaId: number = 0;
    FazendaId: number = 0;
    NomeFazenda: string = "";
    SafraId: number = 0;
    Safra: string = "";
    GrupoProduto: string = "";
    Etapa: string = "";
    DAP:number = 0;
    Produto: string = "";
    ProdutoId: number = 0;
    UnidadeMedida: string = "";
    AreaId: number = 0;
    NomeArea: string = "";
    QtdPlanejada: number = 0;
    QtdUtilizada: number = 0;
    Dose:number = 0;
    DataCriacao: Date = new Date();
    IdOS: number = 0;
    DataInicioPrevistaOS: Date = new Date();
    DataInicioRealOS: Date = new Date();
    DataFinalPrevistaOS: Date = new Date();
    DataFinalRealOS: Date = new Date();
    PrecoUnitario:number = 0;
    PrecoTotal:number = 0;
    HectareAplicacao:number = 0;
    HectarePlantado:number = 0;
}
