import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ListaEnumTipoProjeto, Projeto } from 'src/app/classes/Projeto';
import { ProjetoService } from 'src/app/services/projeto.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forms-projeto',
  templateUrl: './forms-projeto.component.html',
  styleUrls: ['./forms-projeto.component.scss']
})
export class FormsProjetoComponent implements OnInit {

  constructor(private projetoService: ProjetoService, private utils: UtilsService, private router: Router,) {
    this.projetoService.getList()
      .subscribe(res => {
        this.projetos = res;
        if (this.router.url.search('inserir') > 0)
          document.getElementById("resetarForm").click();
      }, err => { this.utils.getErro(err) })
  }

  @Input() customMask: string;

  @Input() projeto: Projeto;
  @Output() atualizarArvore = new EventEmitter<any>();
  projetos: Projeto[];
  isSubmit: boolean;
  carregando: boolean
  listaEnumTipoProjeto = ListaEnumTipoProjeto;

  ngOnInit() {
  }
  save(form: any, novo: boolean = false) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if (this.projeto.Id > 0) {
      if (this.projeto.Codigo == this.projeto.CodigoPai.toString()) {
        this.utils.handleWarning('Projeto pai não pode ser ele mesmo!')
        this.carregando = false;
        return;
      }

      this.projetoService.put(this.projeto.Id, this.projeto).subscribe(
        res => {
          this.utils.handleSuccess('Projeto alterada!')
          this.atualizarArvore.emit();
          this.carregando = false;
          this.novoItem(form, res, novo);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else
      this.projetoService.post(this.projeto).subscribe(
        res => {
          this.utils.handleSuccess('Projeto cadastrada!')
          this.atualizarArvore.emit();
          this.carregando = false;
          this.novoItem(form, res, novo);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }
  excluir(id: number, nome: string) {
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.projetoService.delete(id).subscribe(
          res => {
            this.utils.handleSuccess('Projeto deletada!')
            this.router.navigate(['/cadastros/projeto'])
            this.carregando = false;
          },
          err => {
            Swal.fire('Erro!', err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }
  private novoItem(form: any, response: any, novo: boolean){
    if(novo){
      this.isSubmit = false;
      form.reset();
      this.projeto = new Projeto();
      this.projeto.Inicial = new Date();
      this.projeto.Final = new Date();
      this.projetoService.getList()
      .subscribe(res2 => {
         this.projetos = res2;
         this.projeto.CodigoPai = parseInt(response.Codigo);
      }, err => {this.utils.getErro(err)})
    }else
      this.router.navigate(['/cadastros/projeto/'])
  }
  resetarForm(form: any){
    form.reset();
    this.projeto = new Projeto();
  }

}
