import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Cultura } from 'src/app/classes/Cultura';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { GrupoProdutoCulturaCentroCusto } from 'src/app/classes/GrupoProdutoCulturaCentroCusto';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grupo-produto',
  templateUrl: './grupo-produto.component.html',
  styleUrls: ['./grupo-produto.component.scss']
})
export class GrupoProdutoComponent implements OnInit {

  @Input() IsNovo: boolean = false;
  @Input() IsServico: boolean = false;


  gruposProduto: GrupoProduto[];
  auxGruposProduto: GrupoProduto[];
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  servicos: boolean = false;

  textoBusca: string = "";

  listaGrupoProdutoCulturaCentroCusto: GrupoProdutoCulturaCentroCusto[] = [];
  culturas: Cultura[] = [];
  integraTotvs: boolean = false;

  // tradução
  GRUPO_DE_SERVICO_ALTERADO: string = 'Grupo de Serviço alterado!';
  GRUPO_DE_PRODUTO_ALTERADO: string = 'Grupo de Produto alterado!';
  FEITO: string = 'Feito!';
  GRUPO_DE_SERVICO_CADASTRO: string = 'Grupo de Serviço cadastro!';
  GRUPO_DE_PRODUTO_CADASTRO: string = 'Grupo de Produto cadastro!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  GRUPO_DE_SERVICO_DELETADO: string = 'Grupo de Serviço deletado!';
  GRUPO_DE_PRODUTO_DELETADO: string = 'Grupo de Produto deletado!';


  configurarTraducao() {
    this.GRUPO_DE_SERVICO_ALTERADO = this.translate.instant('GRUPO_DE_SERVICO_ALTERADO');
    this.GRUPO_DE_PRODUTO_ALTERADO = this.translate.instant('GRUPO_DE_PRODUTO_ALTERADO');
    this.FEITO = this.translate.instant('FEITO');
    this.GRUPO_DE_SERVICO_CADASTRO = this.translate.instant('GRUPO_DE_SERVICO_CADASTRO');
    this.GRUPO_DE_PRODUTO_CADASTRO = this.translate.instant('GRUPO_DE_PRODUTO_CADASTRO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.GRUPO_DE_SERVICO_DELETADO = this.translate.instant('GRUPO_DE_SERVICO_DELETADO');
    this.GRUPO_DE_PRODUTO_DELETADO = this.translate.instant('GRUPO_DE_PRODUTO_DELETADO');
  }

  constructor(
    private gruposProdutoService: GrupoProdutoService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private translate: TranslateService,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private planejamentoService: PlanejamentoService
    ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
    this.servicos = this.router.url.includes('grupo-servico');
  }

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    if(this.IsNovo)
    {
      this.inserindo = true;
      if(this.IsServico)
      {
        this.servicos = true;
      }
    }
    this.integraTotvs = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Totvs_RM;
    
    this.planejamentoService.getCulturas().subscribe(ret => {
      this.culturas = ret;
      this.carregando = false;
    });
    
    this.listarGruposProduto();

    this.configurarTraducao();
  }

  listarGruposProduto() {
    this.carregando = true;
    if(this.servicos)
    {
    this.gruposProdutoService.getServicos().subscribe(
      res => {
        this.gruposProduto = res;
        this.auxGruposProduto = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarGruposProduto();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    }
    else
    {
    this.gruposProdutoService.getProdutos().subscribe(
      res => {
        this.gruposProduto = res;
        this.auxGruposProduto = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarGruposProduto();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
    }
  }

  excluir(grupoProdutoId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR + ' ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        
        this.carregando = true;
        this.gruposProdutoService.deleteGrupoProduto(grupoProdutoId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.servicos ? this.GRUPO_DE_SERVICO_DELETADO : this.GRUPO_DE_PRODUTO_DELETADO, 'success');
            this.listarGruposProduto();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  filtrarGruposProduto() {
    this.gruposProduto = this.auxGruposProduto.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
    );
  }

  cancelar(isServico: boolean) {
    if (isServico)
      this.router.navigate(['/cadastros/grupo-servico']);
    else
      this.router.navigate(['/estoques/grupo-produto']);
  }

}
