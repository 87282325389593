import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Checklist } from '../classes/Checklist';


@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(private http: HttpClient) { }

  route = "Checklist";

  Listar(): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}`)
  }

  ObterPorId(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}/${id}`)
  }

  Inserir(obj: Checklist): Observable<any>{
    return this.http.post(`${environment.apiURL}/${this.route}`, obj);
  }

  Atualizar(id: number, obj: Checklist): Observable<any>{
    return this.http.put(`${environment.apiURL}/${this.route}/${id}`, obj);
  }

  Excluir(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/${this.route}/${id}`);
  }

  getPaginando(pagina: number, busca: String): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}/listarPaginando/${pagina}/${busca}`)
  }

  ListarManutencao(): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}/listarManutencao`)
  }

}
