export class TiposDiagnoseRegistroIndicador {
  EmpresaId: number;
  TiposDiagnoseIndicadorId:string = "";
  TipoDiagnoseIndicador:string = "";
  ValorIndicador:string = "";
  UsuarioId: number;
  UsuarioNome:string = "";
  SafraId: number;
  AreaId: number;
  TipoDiagnoseId:string = "";
  Latitude: number = 0;
  Longitude: number = 0;
  DataCadastro: Date = new Date();
}
