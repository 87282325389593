export class ItemChecklistManutencaoFrota{
    Id: number;
    ManutencaoFrotaId: number;
    ChecklistManutencaoFrotaId: number;
    Descricao:string = "";
    ItemChecklistId: number;
    ChecklistId: number;
    Status: EnumStatusManutencaoFrota;
    FlagConcluido: boolean;
    IntervaloPlano: number = 0;
    ProdutoIndiciado:string = "";
    Quantidade: number = 0;
}

export enum EnumStatusManutencaoFrota{
    Pendente = 0,
    Execucao = 1,
    Pausada = 2,
    Cancelada = 3,
    ParcialmenteConcluida = 4, //
    Concluida = 5
}
