import { OrdemServicoEquipamentoApontamento } from './OrdemServicoEquipamentoApontamento';
import { ImprodutividadeEquipamento } from "./ImprodutividadeEquipamento";
import { Time } from '@angular/common';

export class EquipamentoApontamento {
    Id: number;
    Data: Date = new Date();
    HoraInicio:string = "";
    HoraFim:string = "";
    EquipamentoId: number;
    FuncionarioId: number;
    ImplementoId: number;
    Formulario:string = "";
    ApontamentoId: number;
    AgendaAtividadeId: number;
    HorimetroInicial: number = 0;
    HorimetroFinal: number = 0;
    Area: number = 0;
    HorasTrabalhadas: number = 0;
    ImprodutividadeTotal: number = 0;
    Rendimento: number = 0;
    FlagExcluido: boolean;
    AreaId: number;
    AtividadeId: number;
    OperacaoAgricolaId:number;
    //auxiliares do salvar
    OrdensServicoEquipamentoApontamento: OrdemServicoEquipamentoApontamento[];
    ImprodutividadesEquipamento: ImprodutividadeEquipamento[];
    ImprodutividadeTotalTexto:string = "";
}
