import { Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { MovimentoSrincronia } from 'src/app/classes/movimento-estoque/MovimentoSrincronia';
import { MovimentoEstoqueService } from 'src/app/services/movimento-estoque.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-<%= dasherize(name) %>',
  templateUrl: './movimento-estoque.component.html',
  styleUrls: ['./movimento-estoque.component.scss']
})
export class MovimentoEstoqueComponent implements OnInit {

  @ViewChild('filtro', { static: false }) filtro;
  coldefs: ColDef[] = [];
  dataGrid: MovimentoSrincronia[] = [];
  model: any = new Object();
  selecionados: MovimentoSrincronia[] = [];
  showForms = false;
  titulo = 'Lista';
  sincronizandoTodos: boolean = false;
  sincronizando: boolean = false;
  dots: number = 0;
  maxDots: number = 3;
  loadingInterval: any;
  showFilter: boolean = true;
  constructor(private utils: UtilsService,private elRef: ElementRef, private renderer: Renderer2, private movimentoEstoqueService: MovimentoEstoqueService) { }

  ngOnInit() {
    this.configurar();
  }

  // ag-grid
  resizeGrid(){
    const elements = this.elRef.nativeElement.querySelector('.p-datatable-scrollable-body');
    console.debug(elements);
    this.renderer.setStyle(elements, 'height', '77vh');
    // elements.forEach(element => {
    //   this.renderer.setStyle(element, 'height', '77vh');
    // });
  }
  configurar(){
    this.coldefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'MovimentoEstoqueId', headerName: 'Cod. Movimento'},
      { field: 'QtdProdutos', headerName: 'Qtd Produtos' },
      { field: 'LocalEstoque', headerName: 'Local Estoque' },
      { field: 'DataHora', headerName: 'Data Hora', valueFormatter: (params) => this.utils.formatarDataHora(params.data.DataHora) },
      { field: 'IdMovimentoErp', headerName: 'Cod. Movimento Erp' },
      { field: 'ApontamentoId', headerName: 'Cod. Apontamento' },
      { field: 'Fazenda', headerName: 'Fazenda' },
      { field: 'EmpresaInterna', headerName: 'Empresa'},
      { field: 'OrdemServicoId', headerName: 'Cod. OS', valueFormatter: (params) => this.formatOSCod(params.data.OrdemServicoId)},
      { field: '', headerName: 'Status', cellRenderer: (params) =>  params.data.IdMovimentoErp ? `<span class="badge badge-success custom-badge-grid">Sincronizado</span>` : `<span class="badge badge-danger custom-badge-grid">Não Sincronizado</span>`},
    ];
  }
  formatOSCod(param){
    if(param)
    return `${param.toString().padStart(5, '0')}`
  }
  cellDoubleClicked(event) {
    this.tituloEditar();
    this.model = event.data;
    this.showForms = true;
  }
  onCellClicked(row) {
    this.model = row.data;
  }
  selectionChanged(event) {
    this.selecionados = event;
  }


  // barra de tarefas
  toggleGrid(){
    this.showForms = !this.showForms;
    if (this.showForms) {
      if (this.model.Id > 0) {
        this.tituloEditar();
      } else
        this.tituloNovo()
    } else
      this.tituloListar();
  }

  novo(){
    this.model = new Object();
    this.showForms = true;
    this.tituloNovo();
  }

  cancelar(){
    this.showForms = false;
    this.tituloListar();
  }

  excluir(){
  }

  buscar(event){
  }

  clonar(){
  }
  toggleFilter(event: boolean){
    this.showFilter = event;
  }

  //
  private tituloListar() {
    this.titulo = 'Lista';
  }
  private tituloEditar() {
    this.titulo = 'Editar';
  }
  private tituloNovo() {
    this.titulo = 'Cadastrar';
  }

  atualizarLista(event){
    this.selecionados = [];
    this.dataGrid = event;
  }
  sincronizar(){
    if(this.selecionados.length <= 0){
      this.utils.handleWarning('Selecione um ou mais itens para sincronizar');
      return;
    }
    var tituloAtual = this.titulo;
    this.titulo = 'Sincronizando...'
    this.sincronizando = true;
    this.selecionados.forEach(item => {
      this.movimentoEstoqueService.RefazerApontamentoRM(item.MovimentoEstoqueId)
      .subscribe(res => {
        this.filtro.filtrarClick();
      this.sincronizando = false;
      this.titulo = tituloAtual;
    }, err => {
        this.sincronizando = false;
        this.utils.getErro(err)
      this.titulo = tituloAtual;
    });
    })
  }
  sincronizarTodos(){
    var tituloAtual = this.titulo;
    this.titulo = 'Sincronizando...'
    this.sincronizandoTodos = true;
    this.movimentoEstoqueService.RefazerApontamentoRMTodos()
    .subscribe(res => {
      this.filtro.filtrarClick();
      this.sincronizandoTodos = false;
      this.titulo = tituloAtual;
    }, err => {
      this.sincronizandoTodos = false;
      this.utils.getErro(err)
      this.titulo = tituloAtual;
    });
  }
}
