import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { FiltroMovimentoEstoqueAvulso } from 'src/app/classes/movimento-estoque/FiltroMovimentoEstoqueAvulso';

import { MovimentoEstoqueAvulso } from 'src/app/classes/movimento-estoque/MovimentoEstoqueAvulso';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { MovimentoEstoqueService } from 'src/app/services/movimento-estoque.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-movimento-estoque-avulso',
  templateUrl: './movimento-estoque-avulso.component.html',
  styleUrls: ['./movimento-estoque-avulso.component.scss']
})
export class MovimentoEstoqueAvulsoComponent implements OnInit {

  @ViewChild('filtro', { static: false }) filtro;

  coldefs: ColDef[] = [];
  showFilter: boolean = true;
  movimentosEstoqueAvulso: MovimentoEstoqueAvulso[] = [];
  locaisEstoque: LocalEstoque[] = [];
  filtros: FiltroMovimentoEstoqueAvulso = null;
  movimentoEstoqueAvulsoSelecionado: MovimentoEstoqueAvulso = null;
  movimentoEstoqueId: number = 0;

  insereMovimentoEstoqueAvulso: boolean = false;

  constructor(
    private movimentoEstoqueService: MovimentoEstoqueService,
    private localEstoqueService: LocalEstoqueService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.configurar();
    this.localEstoqueService.getLocaisEstoque().subscribe(
      res => this.locaisEstoque = res,
      err => this.utilsService.getErro(err)
    );
  }

  configurar() {

    this.coldefs = [
      { field: 'Id', headerName: 'Id' },
      { field: 'Fazenda', headerName: 'Fazenda' },
      { field: 'TipoMovimento', headerName: 'TipoMovimento', valueFormatter: (params) => params.data.TipoMovimento == 1 ? 'Entrada' : 'Saída' },
      { field: 'LocalEstoque', headerName: 'Local Estoque' },
      { field: 'DataMovimento', headerName: 'DataMovimento', valueFormatter: (params) => this.utilsService.formatarDataHora(params.data.DataMovimento) },
      { field: 'Total', headerName: 'Total', valueFormatter: (params) => this.utilsService.formatarDecimais(params.data.Total) },
    ]
  }
  atualizarLista(filtro: FiltroMovimentoEstoqueAvulso) {
    this.filtros = filtro;
    this.movimentoEstoqueService.listarMovimentosEstoqueAvulso(filtro).subscribe(
      res => this.movimentosEstoqueAvulso = res,
      err => this.utilsService.getErro(err)
    )
  }

  novoMovimento() {
    this.movimentoEstoqueId = 0;
    this.insereMovimentoEstoqueAvulso = true;
  }
  fecharModalEditMovimentoEstoque() {
    this.insereMovimentoEstoqueAvulso = false;
  }
  editaMovimento() {
    this.insereMovimentoEstoqueAvulso = true;
  }
  excluirItemMovimento() {
    Swal.fire({
      title: 'Deletar Movimento Estoque ' + this.movimentoEstoqueId + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.movimentoEstoqueService
          .deleteMovimentoEstoqueCompleto(this.movimentoEstoqueId)
          .subscribe(
            res => {
              this.atualizarLista(this.filtros);
            },
            error => this.utilsService.getErro(error)
            )
      }
    });
  }

  movimentoSalvo(){
    this.atualizarLista(this.filtros);
  }
  toggleFilter(event: boolean) {
    this.showFilter = event;
  }

  selectionChanged(event) {
    if (event == null) {
      this.movimentoEstoqueId = 0;
      return;
    }
    this.movimentoEstoqueId = event.Id;
  }
}
