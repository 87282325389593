import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-diagnose-card-info',
  templateUrl: './diagnose-card-info.component.html',
  styleUrls: ['./diagnose-card-info.component.scss']
})
export class DiagnoseCardInfoComponent implements OnInit {

  @Input() data: Date;
  @Input() nome: string;

  isVisible: boolean = false;


  constructor() { }

  ngOnInit() {
  }

  hover() {
    this.isVisible = !this.isVisible;
  }
}
