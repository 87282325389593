import { FuncionarioTarefa } from "./FuncionarioTarefa";
import { ItemEnum } from "./ItemEnum";

export class Tarefa {
    Id: number;
    AtividadeId : number;
    FuncionarioId: number;
    DataInicio: Date = new Date();
    DataFim: Date = new Date();
    Detalhes:string = "";
    FlagDiaInteiro: boolean;
    FlagArea: boolean;
    AreaId : number
    LocalTexto:string = "";
    EquipamentoId : number;
    ImplementoId : number;
    FlagConcluido: boolean;
    HoraOriginal:string = "";
    FuncionariosTarefa: FuncionarioTarefa[];
    Projeto:string = "";
    TipoTarefa: EnumTipoTarefa;
    OrdemServicoId: number;
    TarefaAvulsa: boolean = true;
    OperacaoAgricolaId:number;
}

export enum EnumTipoTarefa
{
    Administrativa = 1,
    Operacional = 2
}

export const ListaTipoTarefa: ItemEnum[] = [
    {name: 'Administrativa', value: EnumTipoTarefa.Administrativa},
    {name: 'Operacional', value: EnumTipoTarefa.Operacional}
];

