import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPlanejamentoComponent } from './modal-planejamento/modal-planejamento.component';
import { MovimentoConfirmacaoFormsComponent } from './movimento-confirmacao-forms/movimento-confirmacao-forms.component';
import { MovimentoEstoqueFormsComponent } from './movimento-estoque-forms/movimento-estoque-forms.component';
import { MovimentoFormsComponent } from './movimento-forms/movimento-forms.component';
import { MovimentoHeaderComponent } from './movimento-header/movimento-header.component';
import { MovimentoItemFormsComponent } from './movimento-item-forms/movimento-item-forms.component';
import { MovimentoItemSafraComponent } from './movimento-item-safra/movimento-item-safra.component';
import { MovimentoSerieComponent } from './movimento-serie/movimento-serie.component';
import { MovimentoTransacoesComponent } from './movimento-transacoes/movimento-transacoes.component';
import { MovimentoWizardComponent } from './movimento-wizard/movimento-wizard.component';
import { MovimentoComponent } from './movimento.component';
import { ModalItemAdicionalComponent } from './movimento-item-forms/modal-item-adicional/modal-item-adicional.component';
//import { SwitchItemSafraComponent } from './movimento-item-safra/switch-item-safra/switch-item-safra.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbTooltipModule, NgbTabsetModule, NgbCollapseModule, NgbButtonsModule, NgbDropdownModule, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { ArchwizardModule } from 'ng2-archwizard/dist';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomFormsModule } from 'ng2-validation';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { SharedFarmModule } from '../../shared-farm/shared-farm.module';
import { CentroDeResultadosModule } from '../centro-de-resultados/centro-de-resultados.module';
import { EnderecoModule } from '../endereco/endereco.module';
import { GrupoCompraModule } from '../grupo-compra/grupo-compra.module';
import { NaturezaModule } from '../natureza/natureza.module';
import { ProjetoModule } from '../projeto/projeto.module';
import { I18n, CustomDatepickerI18n } from 'src/app/services/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from 'src/app/services/NgbDatePTParserFormatter';
import { MovimentoFinanceiroFormsComponent } from './movimento-financeiro-forms/movimento-financeiro-forms.component';



@NgModule({
  declarations: [
    ModalPlanejamentoComponent,
    ModalItemAdicionalComponent,
    MovimentoComponent,
    MovimentoConfirmacaoFormsComponent,
    MovimentoEstoqueFormsComponent,
    MovimentoFinanceiroFormsComponent,
    MovimentoFormsComponent,
    MovimentoHeaderComponent,
    MovimentoItemFormsComponent,
    MovimentoItemSafraComponent,
    MovimentoSerieComponent,
    MovimentoTransacoesComponent,
    MovimentoWizardComponent,
    //SwitchItemSafraComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SharedFarmModule,
    NgSelectModule,
    NgbTabsetModule,
    NaturezaModule,
    CentroDeResultadosModule,
    ProjetoModule,
    ArchwizardModule,
    GrupoCompraModule,
    EnderecoModule,
    NgbCollapseModule,
    NgbButtonsModule,
    NgbDropdownModule
  ],
  exports: [
    ModalPlanejamentoComponent,
    ModalItemAdicionalComponent,
    MovimentoComponent,
    MovimentoConfirmacaoFormsComponent,
    MovimentoEstoqueFormsComponent,
    MovimentoFinanceiroFormsComponent,
    MovimentoFormsComponent,
    MovimentoHeaderComponent,
    MovimentoItemFormsComponent,
    MovimentoItemSafraComponent,
    MovimentoSerieComponent,
    MovimentoTransacoesComponent,
    MovimentoWizardComponent,
    //SwitchItemSafraComponent,
  ],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],
  ]
})
export class MovimentoModule { }
