import { OrdemServicoEquipamentoApontamento } from './../classes/OrdemServicoEquipamentoApontamento';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EquipamentoApontamento } from '../classes/EquipamentoApontamento';
import { ImprodutividadeEquipamento } from '../classes/ImprodutividadeEquipamento';

@Injectable({
  providedIn: 'root'
})
export class EquipamentoApontamentoService {

  constructor(private http: HttpClient) { }

  getEquipamentosApontamento(agendaAtividadeId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/equipamentoApontamento/listar/${agendaAtividadeId}`)
  }
  getEquipamentosApontamentoPorApontamento(apontamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/equipamentoApontamento/getPorApontamentoId/${apontamentoId}`)
  }

  getEquipamentosApontamentoPorOs(ordemServicoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/equipamentoApontamento/listarPorOs/${ordemServicoId}`)
  }

  getEquipamentoApontamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/equipamentoApontamento/${id}`)
  }

  postEquipamentoApontamento(equipamentoApontamento: EquipamentoApontamento): Observable<any>{
    return this.http.post(`${environment.apiURL}/equipamentoApontamento`, equipamentoApontamento);
  }

  putEquipamentoApontamento(id: number, equipamentoApontamento: EquipamentoApontamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/equipamentoApontamento/${id}`, equipamentoApontamento);
  }

  deleteEquipamentoApontamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/equipamentoApontamento/${id}`);
  }

  SalvarCompleto(equipamentoApontamento: EquipamentoApontamento, ordensServicoEquipamentoApontamento: OrdemServicoEquipamentoApontamento[], improdutividadesEquipamento: ImprodutividadeEquipamento[]): Observable<any>{
    equipamentoApontamento.OrdensServicoEquipamentoApontamento = ordensServicoEquipamentoApontamento;
    equipamentoApontamento.ImprodutividadesEquipamento = improdutividadesEquipamento;
    return this.http.post(`${environment.apiURL}/equipamentoApontamento/salvarCompleto`, equipamentoApontamento);
  }

  AreaApontadaPorOs(ordemServicoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/equipamentoApontamento/areaApontadaPorOs/${ordemServicoId}`)
  }

  getEquipamentosApontamentoFiltro(funcionarioId: number, equipamentoId: number, implementoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/equipamentoApontamento/listar/${funcionarioId}/${equipamentoId}/${implementoId}`)
  }

  getHorimetroFinal(equipamentoId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/equipamentoApontamento/HorimetroFinal/${equipamentoId}`)
  }

  getOrdemServicoEquipamentoApontamento(equipamentoApontamentoId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/equipamentoApontamento/OrdemServicoEquipamentoApontamento/${equipamentoApontamentoId}`)

  }
}
