import { Component, OnInit, ViewChild } from '@angular/core';
import { delay } from 'rxjs/operators';
import { Fazenda } from 'src/app/classes/Fazenda';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Produto } from 'src/app/classes/Produto';
import { EstoqueProduto } from 'src/app/classes/gestao-lite/EstoqueProduto';
import { ValoresEstoque } from 'src/app/classes/gestao-lite/ValoresEstoque';
import { FazendaService } from 'src/app/services/fazenda.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { MovimentoEstoqueService } from 'src/app/services/movimento-estoque.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-estoque',
  templateUrl: './estoque.component.html',
  styleUrls: ['./estoque.component.scss']
})
export class EstoqueComponent implements OnInit {
  @ViewChild('ModalProduto', { static: false })
  ModalProduto: UiModalComponent;
  @ViewChild('ModalLocalEstoque', { static: false })
  ModalLocalEstoque: UiModalComponent;
  @ViewChild('ModalGrupo', { static: false })
  ModalGrupo: UiModalComponent;

  estoqueProdutoList: EstoqueProduto[] = [];
  fazendas: Fazenda[] = [];
  locaisEstoque: LocalEstoque[] = [];
  gruposProduto: GrupoProduto[] = [];
  ValoresEstoque: ValoresEstoque = new ValoresEstoque;
  carregando: boolean = false;
  fazendasFiltro: number[] = [];
  locaisEstoqueFiltro: number[] = [];
  gruposProdutoFiltro: number[] = [];
  textoBusca: string = '';
  configGraficoBarGrupoProdutos: any;
  configGraficoBarMaiorValor: any;
  configGraficoPizzaFazenda: any;
  configGraficoPizzaLocal: any;
  insereMovimentoEstoque: boolean = false;
  renderizar:boolean = false;

  constructor(
    private fazendaService: FazendaService,
    private localEstoqueService: LocalEstoqueService,
    private grupoProdutoService: GrupoProdutoService,
    private movimentoEstoqueService: MovimentoEstoqueService,
    private utils: UtilsService) {

  }

  ngOnInit() {
    this.CarregaDadosGrafico();
    this.carregarFazendas();
    this.carregarEstoque();
    this.carregarLocaisEstoque();
    this.carregarGruposProduto();
    //this.getGrafico();
  }
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }
  carregarFazendas() {
    this.fazendaService.getFazendas().subscribe(
      data => {
        this.fazendas = data;
      },
      error => {
        this.utils.getErro(error);
      }
    );
  }

  carregarLocaisEstoque() {
    this.localEstoqueService.getLocaisEstoque().subscribe(
      data => {
        this.locaisEstoque = data;
      },
      error => {
        this.utils.getErro(error);
      }
    );
  }

  carregarGruposProduto() {
    this.grupoProdutoService.getGruposProduto().subscribe(
      data => {
        this.gruposProduto = data;
      },
      error => {
        this.utils.getErro(error);
      }
    );
  }
  CarregaDadosGrafico() {
    this.movimentoEstoqueService.ValoresEmEstoque().subscribe(
      data => {
        this.ValoresEstoque = data;
        this.getGrafico();
        this.renderizar = true;
      },
      error => {
        this.utils.getErro(error);
        this.carregando = false;
        this.renderizar = false;
      }
    );
  }
  carregarEstoque() {
    let filtros: any = {};

    filtros.Fazendas = this.fazendasFiltro;
    filtros.LocaisEstoque = this.locaisEstoqueFiltro;
    filtros.GruposProdutos = this.gruposProdutoFiltro;
    filtros.TextoBusca = this.textoBusca;


    this.carregando = true;
    this.movimentoEstoqueService.listarEstoquesProdutos(filtros).subscribe(
      data => {
        this.estoqueProdutoList = data;
        this.carregando = false;
      },
      error => {
        this.utils.getErro(error);
        this.carregando = false;
      }
    );
  }

  filtrarProdutos() {
    if (this.textoBusca.length >= 3)
      this.carregarEstoque();
  }

  getGrafico() {

    let mSeriesGrupo = [];
    let Id: number = 1;
    this.ValoresEstoque.ValoresPorGrupo.forEach(el => {
      mSeriesGrupo.push({ x: el.Item1, y: el.Item2, id: Id })
      Id = Id + 1;
    });
    mSeriesGrupo = mSeriesGrupo.sort((x1, x2) => x2.y - x1.y);

    let mSeriesMaiorValor = [];
    Id = 1;
    this.ValoresEstoque.Top5.forEach(el => {
      mSeriesMaiorValor.push({ x: el.Item1, y: el.Item2, id: Id })
      Id = Id + 1;
    });
    mSeriesMaiorValor = mSeriesMaiorValor.sort((x1, x2) => x2.y - x1.y);

    let mSeriesFazenda = [];
    Id = 1;
    this.ValoresEstoque.ValorFazenda.forEach(el => {
      mSeriesFazenda.push({ x: el.Item1, y: el.Item2, id: Id })
      Id = Id + 1;
    });
    mSeriesFazenda = mSeriesFazenda.sort((x1, x2) => x2.y - x1.y);

    let mSeriesLocal = [];
    Id = 1;
    this.ValoresEstoque.ValorLocal.forEach(el => {
      mSeriesLocal.push({ x: el.Item1, y: el.Item2, id: Id })
      Id = Id + 1;
    });
    mSeriesLocal = mSeriesLocal.sort((x1, x2) => x2.y - x1.y);


    let seriesFazenda: number[] = [];
    let labelsFazenda: string[] = [];
    this.ValoresEstoque.ValorFazenda.forEach(el => {
      seriesFazenda.push(el.Item2);
      labelsFazenda.push(el.Item1);
    });

    let seriesLocal: number[] = [];
    let labelsLocal: string[] = [];
    this.ValoresEstoque.ValorLocal.forEach(el => {
      seriesLocal.push(el.Item2);
      labelsLocal.push(el.Item1);
    });

    this.configGraficoBarGrupoProdutos = {
      chart: {
        height: 250,
        type: 'bar',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            let indexClicado: number = config.dataPointIndex;
            this.idAreaSelecionado = mSeriesGrupo[indexClicado].id;
          }
        },
        toolbar: {
          show: false
        },
        ShadowRoot:
        {
          show:false
        },
      },
      grid:
      {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      colors: ['#4680ff', '#0e9e4a'],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          colors: ['#fff']
        }
      },

      series: [{
        data: mSeriesGrupo,
        title: 'R$/ha'
      }],

      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: true
        },
        y: {
          title: {
            formatter: (seriesName) => 'R$/ha'
          }
        },
        marker: {
          show: false
        }
      }
    };

    this.configGraficoBarMaiorValor = {
      chart: {
        height: 250,
        type: 'bar',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            let indexClicado: number = config.dataPointIndex;
            this.idAreaSelecionado = mSeriesMaiorValor[indexClicado].id;
          }
        },
        toolbar: {
          show: false
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      colors: ['#4680ff', '#0e9e4a'],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          colors: ['#fff']
        }
      },

      series: [{
        data: mSeriesMaiorValor,
        title: 'R$/ha'
      }],

      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: true
        },
        y: {
          title: {
            formatter: (seriesName) => 'R$/ha'
          }
        },
        marker: {
          show: false
        }
      }
    };
    
    this.configGraficoPizzaFazenda = {
      chart: {
        height: 250,
        type: 'donut',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            let indexClicado: number = config.dataPointIndex;
            this.idAreaSelecionado = mSeriesFazenda[indexClicado].id;
            console.log("idAreaSelecionado: " + this.idAreaSelecionado);
          }
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        pie: {
          customScale: 0.8
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          colors: ['#fff']
        }
      },
      series: seriesFazenda,
      labels: labelsFazenda,
      legend: {
        position: 'bottom'
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: true
        },
        y: {
          title: {
            formatter: (seriesName) => 'R$/ha'
          }
        },
        marker: {
          show: false
        }
      }
    };

    this.configGraficoPizzaLocal = {
      chart: {
        height: 250,
        type: 'donut',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            let indexClicado: number = config.dataPointIndex;
            this.idAreaSelecionado = mSeriesLocal[indexClicado].id;
            console.log("idAreaSelecionado: " + this.idAreaSelecionado);
          }
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        pie: {
          customScale: 0.8
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          colors: ['#fff']
        }
      },
      series: seriesLocal,
      labels: labelsLocal,
      legend: {
        position: 'bottom'
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: true
        },
        y: {
          title: {
            formatter: (seriesName) => 'R$/ha'
          }
        },
        marker: {
          show: false
        }
      }
    };

    this.carregando = false;
  }


  inserirMovimentoProduto() {
    this.insereMovimentoEstoque = true;
  }

  fecharModalEditMovimentoEstoque() {
    this.insereMovimentoEstoque = false;
    console.log("fecharModalEditMovimentoEstoque");
  }

  novoProduto()
  {
      this.ModalProduto.show();
  }
  fecharModelProduto()
  {
    this.ModalProduto.hide();
  }

  novoGrupo()
  {
      this.ModalGrupo.show();
  }
  fecharModelGrupo()
  {
    this.ModalGrupo.hide();
  }

  novoLocalEstoque()
  {
      this.ModalLocalEstoque.show();
  }
  fecharModelLocalEstoque()
  {
    this.ModalLocalEstoque.hide();
  }

}
