import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasksService } from 'src/app/utils/masks.service';

@Component({
  selector: 'app-input-hora',
  templateUrl: './input-hora.component.html'
})
export class InputHoraComponent implements OnInit {

  isValid: boolean = true;
  @Input() horario: string;
  @Output() horarioChangeEvent = new EventEmitter<string>();
  constructor(public masks: MasksService) { }

  ngOnInit() {
  }

  validaHora(time: string){
    time = time.substring(0,5);
    if(time.match(/[0-9]/g).join('').length == 4) {
      const hora = parseInt(time.substring(0,2));
      const minuto = parseInt(time.substring(3));
      if (!(hora >= 0 && hora <= 23 && minuto >= 0 && minuto <= 59)) {
        return false;
      }
      return true;
    }else{
      return false;
    }
  }

  validaHoraDigitada(time: string){
    if (parseInt(time.substring(0,1)) > 2){
      return false;
    }
    if (parseInt(time.substring(0,2)) > 23){
      return false;
    }
    if (parseInt(time.substring(3,4)) > 5){
      return false;
    }
    return true;
  }
}
