import { precoCotacao } from './../../classes/cotacao/precoCotacao';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class precoCotacaoService {

  constructor(private http: HttpClient) { }

  get(): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacao`);
  }

  getPorId(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacao/${id}`);
  }

  post(precoCotacao: precoCotacao): Observable<any> {
    return this.http.post(`${environment.apiURL}/PrecoCotacao`, precoCotacao);
  }

  put(id: number, precoCotacao: precoCotacao): Observable<any> {
    return this.http.put(`${environment.apiURL}/PrecoCotacao/${id}`, precoCotacao);
  }
  desistencia(id: number, fornecedorId: number, precoCotacao: precoCotacao): Observable<any> {
    return this.http.put(`${environment.apiURL}/PrecoCotacao/Desistencia/${id}/${fornecedorId}`, precoCotacao);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/PrecoCotacao/${id}`);
  }

  selecionaPorCotacaoFornecedor(cotacaoId: number, fornecedorId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacao/cotacao/${cotacaoId}/${fornecedorId}`);
  }

  aplicarDesconto(cotacaoId: number, fornecedorId: number, desconto: number[]): Observable<any> {
    return this.http.post(`${environment.apiURL}/PrecoCotacao/DescontoGlobal/${cotacaoId}/${fornecedorId}`, desconto);
  }
}
