import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { TiposDiagnoseService } from 'src/app/services/tipos-diagnose/tipos-diagnose.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { Empresa } from 'src/app/classes/Empresa';
import { TiposDiagnose } from 'src/app/classes/tipos-diagnose/TiposDiagnose';
import { StorageService } from 'src/app/services/storage.service';
import { NovoTiposDiagnose } from 'src/app/classes/tipos-diagnose/NovoTiposDiagnose';

@Component({
  selector: 'app-tipos-diagnose-form',
  templateUrl: './tipos-diagnose-form.component.html',
  styleUrls: ['./tipos-diagnose-form.component.scss']
})
export class TiposDiagnoseFormComponent implements OnInit {

  form: FormGroup;
  id: string = '';
  inserindo: boolean = true;
  listaEmpresas: Empresa[] = [];
  submetido: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private tiposDiagnoseService: TiposDiagnoseService,
    private utils: UtilsService,
    private storageService: StorageService
  ) {
    this.route.params.subscribe(res => this.id = res.id);
  }

  ngOnInit() {
    this.criarForm(new NovoTiposDiagnose());
    this.setarTiposDiagnose();
  }

  get formControl() { return this.form.controls; }

  private setarTiposDiagnose() {
    if (this.router.url.search('inserir') == -1) {
      this.inserindo = false;
      this.tiposDiagnoseService.ObterPorId(this.id).subscribe(
        res => this.atualizarForm(res),
        error => this.utils.getErro(error)
      )
    }
  }

  private criarForm(tiposDiagnose: NovoTiposDiagnose) {
    this.form = this.formBuilder.group({
      Codigo: [tiposDiagnose.Codigo, [Validators.required]],
      Descricao: [tiposDiagnose.Descricao, [Validators.required]],
      OrdemRelatorioAcompanhamento: [tiposDiagnose.OrdemRelatorioAcompanhamento],
      EmpresaId: []
    });
  }

  private atualizarForm(tiposDiagnose: TiposDiagnose) {
    this.form.patchValue({
      EmpresaId: tiposDiagnose.EmpresaId,
      Codigo: tiposDiagnose.Codigo,
      Descricao: tiposDiagnose.Descricao,
      OrdemRelatorioAcompanhamento: tiposDiagnose.OrdemRelatorioAcompanhamento
    } as TiposDiagnose)
  }

  cancelar() {
    this.router.navigate(['/cadastros/tipos-diagnose']);
  }

  onSubmit() {
    this.submetido = true;
    if (!this.form.valid) return;

    let result: Observable<TiposDiagnose | null> = null;

    if (this.inserindo) {
      this.form.value.EmpresaId = this.storageService.getUsuario().EmpresaId;
      result = this.tiposDiagnoseService.Inserir(this.form.value);
    } else {
      this.form.value.Id = this.id;
      result = this.tiposDiagnoseService.Atualizar(this.form.value, this.id);
    }

    result.subscribe(
      res => {
        this.utils.handleSuccess('Tipos Diagnose salvo com sucesso!');
        this.router.navigate(['/cadastros/tipos-diagnose'])
      },
      error => this.utils.getErro(error)
    )
  }
}
