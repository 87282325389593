import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Parceiro } from 'src/app/classes/parceiro/Parceiro';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParceirosService {
  route = "Parceiros";

  constructor(private http: HttpClient) { }

  Listar(): Observable<Parceiro[]> {
    return this.http.get<Parceiro[]>(`${environment.apiURL}/${this.route}`);
  }

  ObterPorId(id: string): Observable<Parceiro | null> {
    return this.http.get<Parceiro>(`${environment.apiURL}/${this.route}/${id}`);
  }

  Inserir(parceiro: Parceiro): Observable<Parceiro | null>{
    return this.http.post<Parceiro>(`${environment.apiURL}/${this.route}`, parceiro);
  }

  Atualizar(parceiro: Parceiro, id: string): Observable<Parceiro | null>{
    return this.http.put<Parceiro>(`${environment.apiURL}/${this.route}/${id}`, parceiro);
  }

  Excluir(id: string): Observable<any>{
    return this.http.delete(`${environment.apiURL}/${this.route}/${id}`);
  }
}
