import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Atividade } from 'src/app/classes/Atividade';
import { Equipamento } from 'src/app/classes/Equipamento';
import { Funcionario } from 'src/app/classes/Funcionario';
import { ItemOrdemServico } from 'src/app/classes/ItemOrdemServico';
import { OrdemServico } from 'src/app/classes/OrdemServico';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { Produto } from 'src/app/classes/Produto';
import { TipoAplicacao } from 'src/app/classes/TipoAplicacao';
import { TipoBico } from 'src/app/classes/TipoBico';
import { AtividadeService } from 'src/app/services/atividade.service';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { TipoAplicacaoService } from 'src/app/services/tipo-aplicacao.service';
import { TipoBicoService } from 'src/app/services/tipo-bico.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-liberacao-isumos',
  templateUrl: './liberacao-isumos.component.html',
  styleUrls: ['./liberacao-isumos.component.scss']
})
export class LiberacaoIsumosComponent implements OnInit, OnChanges {

  @Input() ordemServico: OrdemServico;
  @Output() atualizaAreaItensEvent: EventEmitter<any> = new EventEmitter();

  atividades: Atividade[];
  @Input() funcionarios: Funcionario[];
  @Input() isSubmit: boolean = false;
  tiposBicos: TipoBico[];
  carregando: boolean = false;
  equipamentosAux: Equipamento[] = [];
  equipamentos: Equipamento[] = [];
  implementos: Equipamento[] = [];
  itensOrdemServico: ItemOrdemServico[];
  produtos: Produto[];
  tiposAplicacao: TipoAplicacao[] = [];
  tipoAplicacao: TipoAplicacao = new TipoAplicacao();
  processosPlanejamento: ProcessoPlanejamento[];

  usaImplemento: boolean = false;

  //campos calculados
  qtdTanques: number = 0;

  public isServico: boolean = false;

  constructor(public ordensServicoService: OrdemServicoService,
    private atividadeService: AtividadeService,
    private equipamentoService: EquipamentoService,
    private tipoBicoService: TipoBicoService,
    private tipoAplicacaoService: TipoAplicacaoService,
    private funcionarioService: FuncionarioService,
    private processoPlanejamentoService: ProcessoPlanejamentoService,
    private utils: UtilsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.ordemServico){
      if(this.ordemServico.TipoAplicacaoId)
        this.selecionaTipoAlicacao()
    }
  }

  ngOnInit() {
    this.ordensServicoService.areaChangeEvento().subscribe( data => {
      this.calculaCampos();
    });

    forkJoin([
      this.equipamentoService.getEquipamentos(),
      this.atividadeService.getAtividades(),
      this.tipoBicoService.getTiposBicos(),
      this.tipoAplicacaoService.getTiposAplicacao(),
      this.processoPlanejamentoService.getProcessosPlanejamento(),
      this.funcionarioService.getFuncionarios()
    ]).subscribe(
      res => {
        this.equipamentosAux = res[0];
        this.equipamentos = this.equipamentosAux.filter(x => !x.FlagImplemento);
        this.implementos = this.equipamentosAux.filter(x => x.FlagImplemento);

        this.atividades = res[1];
        this.tiposBicos = res[2];
        this.tiposAplicacao = res[3];
        this.tiposAplicacao = this.tiposAplicacao.sort((x1, x2) => (x1.Descricao < x2.Descricao ? -1 : 1));
        if (this.tiposAplicacao && this.ordemServico.TipoAplicacaoId)
          this.tipoAplicacao = this.tiposAplicacao.find(x => x.Id == this.ordemServico.TipoAplicacaoId);
        this.isServico = !this.getAtividade(this.ordemServico.AtividadeId).UtilizaInsumo;
        this.calculaCampos();

        this.processosPlanejamento = res[4];
        this.funcionarios = res[5];
      },
      err => this.utils.getErro(err)
    )
  }

  calculaCampos(trocaEquipamento?: boolean) {
    if (this.qtdTanques == 0)
      this.qtdTanques = 1;
    let equipamento = this.getEquipamentoPrincipal();
    setTimeout(() => {
      if (equipamento) {
        if (trocaEquipamento)
          this.ordemServico.VolumeAgua = this.volumeCaldaCoalesce([equipamento, this.implementos.find(c => c.Id == this.ordemServico.ImplementoId)]);;

        if (this.ordemServico.AreaPrevista > 0) {
          if (this.tipoAplicacao && this.tipoAplicacao.VazaoDigitada) {
            this.ordemServico.AreaPorTanque = this.ordemServico.VolumeAgua / this.ordemServico.Vazao;
            this.qtdTanques =  this.ordemServico.AreaPrevista / this.ordemServico.AreaPorTanque;
          }
          else {
            this.ordemServico.Vazao = this.ordemServico.VolumeAgua / (this.ordemServico.AreaPrevista / this.qtdTanques);
          }

          this.ordemServico.VolumeAguaTanqueIncompleto = (this.qtdTanques - Math.floor(this.qtdTanques)) * this.ordemServico.VolumeAgua;

          this.ordemServico.AreaPorTanque = this.ordemServico.AreaPrevista / this.qtdTanques;

          this.ordensServicoService.emitirEventochangeProduto();
        }
      }
    }, 300);

  }

  getEquipamentoPrincipal(): Equipamento {
    return this.getEquipamento(this.ordemServico.EquipamentoId);
  }

  getEquipamento(id: number): Equipamento {
    if (!this.equipamentosAux)
      return null;

    let equipamento = this.equipamentosAux.filter(x =>
      x.Id == id
    );

    return equipamento.length > 0 ? equipamento[0] : new Equipamento();
  }

  getAtividade(id: number): Atividade {
    let nova = new Atividade();
    nova.UtilizaInsumo = true;

    if (!this.atividades)
      return nova;

    let atividades = this.atividades.filter(x =>
      x.Id == id
    );

    return atividades.length > 0 ? atividades[0] : nova;
  }

  selecionaTipoAlicacao() {
    this.tipoAplicacao = this.tiposAplicacao.find(x => x.Id == this.ordemServico.TipoAplicacaoId);
    if(!this.tipoAplicacao)
      return;
    if (this.tipoAplicacao.ExigePhCalda && (!this.ordemServico.PhCalda || this.ordemServico.PhCalda == 0))
      this.ordemServico.PhCalda = this.tipoAplicacao.PadraoPhCalda;

    if (this.tipoAplicacao.ExigeTemperatura && (!this.ordemServico.Temperatura || this.ordemServico.Temperatura == 0))
      this.ordemServico.Temperatura = this.tipoAplicacao.PadraoTemperatura;

    if (this.tipoAplicacao.ExigeUmidade && (!this.ordemServico.Umidade || this.ordemServico.Umidade == 0))
      this.ordemServico.Umidade = this.tipoAplicacao.PadraoUmidade;

    if (this.tipoAplicacao.ExigeVelocidadeVento && (!this.ordemServico.VelocidadeVento || this.ordemServico.VelocidadeVento == 0))
      this.ordemServico.VelocidadeVento = this.tipoAplicacao.PadraoVelocidadeVento;
  }

  getProcessoPlanejamento(id: number): ProcessoPlanejamento {
    return this.processosPlanejamento.find(x => x.Id == id);
  }

  private volumeCaldaCoalesce(arg: any[]) {
    for (let i = 0; i < arg.length; i++) {
        if (arg[i] && arg[i].DoseTanque) {
            return arg[i].DoseTanque;
        }
    }
    return 0;
  }

}
