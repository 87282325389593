export class DadosTabela{
    Id: number = 0;
    ValorUn: number[];
    ValorHa: number[];
    ValorTotal: number[];
    Quantidade:number[];
    Dose: number[];
    NomeProduto: string[];
    UnProduto: string[];
    Vencedor:string[];
    PrincipioAtivo: string[];
    Fabricante: string[];
    NomeProdutoOriginal:string = "";
    Similar: boolean[];
    ValorHaMenor:number = 0;
    IndexVencedor:number = 0;
}
