import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from "@angular/core";

@Component({
  selector: "app-grafico-pizza",
  templateUrl: "./grafico-pizza.component.html",
  styleUrls: ["./grafico-pizza.component.scss"],
})
export class GraficoPizzaComponent implements OnInit, OnChanges {
  @Input() titulo: string;
  @Input() subTitulo: string;
  @Input() labels: string[];
  @Input() valores: number[];
  @Input() chartID: string;
  @Input() chartType: string = "donut";
  @Input() minHeight: number = 0;
  @Input() carregando = false;
  @Input() border = false;
  @Input() colors: string[];

  @Output() onPointSelection = new EventEmitter<any>();

  chartOptions: any;

  constructor() { }

  ngOnInit() {
    this.initChart();
  }
  
  ngOnChanges() {
    this.initChart();
  }

  initChart() {
    this.chartOptions = {
      colors: this.colors,
      series: this.valores,
      chart: {
        type: this.chartType,
        events: {
          dataPointSelection(event, chartContext, config) {
            const status = config.w.config.labels[config.dataPointIndex];
            const obj = { tipo: tipo, status: status };
            emitirEvento(obj);
          },
        },
      },
      dataLabels: {
        style: {
          fontSize: "10px",
          fontWeight: "bold",
        },
      },
      labels: this.labels,
      legend: {
        show: true,
        position: "right",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "40%",
          },
        },
      },
    };

    const tipo = this.chartID; //p1=Solicitações p2=Cotações p3=Pedidos p4=Entregas
    const emitirEvento = (obj: any) => this.onPointSelection.emit(obj);
  }
}
