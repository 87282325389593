import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Planejamento } from '../classes/Planejamento';

@Injectable({
  providedIn: 'root'
})
export class PlanejamentoService {

  public planejamentoSelecionado: Planejamento;

  constructor(private http: HttpClient) { }

  getPlanejamentos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento`)
  }

  getPlanejamentosPorSafra(safraId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/listarPorSafra/${safraId}`)
  }
  getListarPlanosAgronomico(safraId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/listarPlanosAgronomico/${safraId}`)
  }
  getPlanejamentosDetalhe(planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/ListarPlanejamentosDetalhe/${planejamentoId}`)
  }

  getPlanejamentosPorSafraArea(safraId: number, areaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/listarPorSafraArea/${safraId}/${areaId}`)
  }
  getPlanejamentosPorSafras(safras: number[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/planejamento/listarPorSafra`, safras)
  }

  getPlanosMestrePorCultura(culturaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/listarPlanoMestre/${culturaId}`)
  }
  
  listarPlanosMestrePorCultura(culturaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/listarPlanoMestrePorCultura/${culturaId}`)
  }

  getPlanejamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/${id}`)
  }

  postPlanejamento(planejamento: Planejamento): Observable<any>{
    return this.http.post(`${environment.apiURL}/planejamento`, planejamento);
  }

  putPlanejamento(id: number, planejamento: Planejamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/planejamento/${id}`, planejamento);
  }

  deletePlanejamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/planejamento/${id}`);
  }

  duplicarPlanejamento(planejamento: Planejamento): Observable<any>{
    return this.http.post(`${environment.apiURL}/planejamento/duplicar`, planejamento);
  }

  getCulturas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/listarCulturas`)
  }

  previstoRealizadoGantt(filtros: any): Observable<any>{
    return this.http.post(`${environment.apiURL}/planejamento/previsto-realizado-gantt`, filtros);
  }

  getCustoPorHa(planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/custoPorHa/${planejamentoId}`);
  }

  custo(planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/CustoPlanejado/${planejamentoId}`);
  }

  areaPlantadaPorSafra(safraId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/planejamento/areaPlantadaPorSafra/${safraId}`);
  }
}
