import { itensCotacao } from './../../../../classes/cotacao/itensCotacao';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { EnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Movimento } from 'src/app/classes/Movimento';
import { Natureza } from 'src/app/classes/Natureza';
import { Projeto } from 'src/app/classes/Projeto';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
import { CentroDeResultadosService } from 'src/app/services/centro-de-resultados.service';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { ItemMovimentoService } from 'src/app/services/item-movimento.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { MovimentoService } from 'src/app/services/movimento.service';
import { NaturezaService } from 'src/app/services/natureza.service';
import { ProjetoService } from 'src/app/services/projeto.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { MovimentoSharedService } from '../movimento-shared.service';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-movimento-wizard',
  templateUrl: './movimento-wizard.component.html',
  styleUrls: ['./movimento-wizard.component.scss']
})
export class MovimentoWizardComponent implements OnInit {

  constructor(
    private usuarioService: UsuarioService,
    private utils: UtilsService,
    private centroDeResultadoService: CentroDeResultadosService,
    private naturezaService: NaturezaService,
    private movimentoService: MovimentoService,
    private projetoService: ProjetoService,
    private router: Router,
    private localEstoqueService: LocalEstoqueService,
    private itemMovimentoService: ItemMovimentoService,
    private empresaInternaService: EmpresaInternaService,
    private changeDetectorRef: ChangeDetectorRef,
    private sharedService: MovimentoSharedService
  ) {
  }

  @Input() transacoes: Transacao[] = [];
  @Input() colDefsItems: ColDef[];
  @ViewChild('componentMovimento', { static: false }) componentMovimento
  @ViewChild('componenteItem', { static: false }) componenteItem

  @Input() id: number = 0;
  centrosResultados: CentroDeResultados[] = [];
  projetos: Projeto[] = [];
  naturezas: Natureza[] = [];
  locaisEstoque: LocalEstoque[] = [];
  movimento = new Movimento();
  transacao: Transacao = new Transacao();
  usuarios: Usuario[] = [];
  empresasIntenas: EmpresaInterna[] = [];
  titulo: string = 'Novo Movimento';
  tipoItem: number = 0;
  showSafra = false;
  showFinanceiro = false;
  showEstoque = false;
  showItens = true;
  etapaItens: boolean = false;
  etapaItensSafra: boolean = false;
  inserindo: boolean = false;
  disabledFinalizar = true;
  showFormFinanceiro = false;
  carregando = false;

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;

    this.movimento.ItemsSafra = [];
    this.changeDetectorRef.detectChanges();
    this.usuarioService.getCompradores()
      .subscribe(res => {
        this.usuarios = res;
      }, err => this.utils.getErro(err));
    this.listarEmpreasasInternas();
    this.listarCentroResultados();
    this.listarNaturezas();
    this.listarProjetos();
    this.listarLocalEstoque();

    if (this.id > 0)
      this.selecionar();
    else {
      this.etapaItens = true;
      this.etapaItensSafra = true;
    }
  }
  atualizarMovimento(movimento) {
    this.movimento = movimento;
  }
  voltar(){

    this.router.navigate([this.sharedService.getRoute()]);
  }
  selecionar() {
    this.movimentoService.get(this.id)
      .subscribe(res => {
        this.movimento = res;
        this.etapaItensSafra = true;
        this.etapaItens = true;
      })
  }
  listarEmpreasasInternas() {
    this.empresaInternaService.getEmpresasInternas().subscribe(res => {
      this.empresasIntenas = res;
    }, err => {
      this.utils.getErro(err)
    });
  }
  listarCentroResultados() {
    this.centroDeResultadoService.getCentrosDeResultados()
    .subscribe((res: CentroDeResultados[]) => {
      this.centrosResultados = res.filter(c => c.IsAnalitico);
    }, err => this.utils.getErro(err));
  }
  listarProjetos() {
    this.projetoService.getList()
    .subscribe((res: Projeto[]) => {
      this.projetos = res.filter(c => c.IsAnalitico);
    }, err => this.utils.getErro(err));
  }
  listarNaturezas() {
    this.naturezaService.getList()
    .subscribe((res: Natureza[]) => {
      this.naturezas = res.filter(c => c.IsAnalitico);;
    }, err => this.utils.getErro(err));
  }
  listarLocalEstoque() {
    this.localEstoqueService.getLocaisEstoque()
      .subscribe(res => {
        this.locaisEstoque = res;
      }, err => this.utils.getErro(err));
  }
  changeTitulo(event) {
    this.titulo = event;
  }
  setShowSafra(event) {
    this.showSafra = event;
  }
  setShowEstoque(event) {
    this.showEstoque = event;
  }
  setShowFinanceiro(event) {
    this.showFinanceiro = event;
  }
  setShowItens(event) {
    this.showItens = event;
  }
  attFinalizar(event) {
    this.disabledFinalizar = event;
  }

  estaCarregando(event) {
    console.log(this.carregando)
    //this.carregando = event;
  }
  finalizar() {
    Swal.fire({
      title: 'Deseja finalizar este Movimento?',
      text: 'o processo será colocado em aprovação e não poderá ser mais editado',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then((ret) => {
      if (!ret.dismiss)
        this.componentMovimento.salvar();
    });
  }
  acaoItem(acao: string) {
    switch (acao) {
      case 'Novo item Opcional':
        this.componenteItem.novoItemOpcional();
        break;
    }
  }

}
