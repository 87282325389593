export class Anexo{
    Id: number;
    ChamadoId: number
    Imagem: string = "";
    Descricao: string = "";
    DataAlteracao: string = "";
    UsuarioAlteracaoId: number;
    ImagemBase64: string = "";
    UsuarioCadastroId: number;
}
