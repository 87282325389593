import { TranslateService } from "@ngx-translate/core";

export class ApontamentoTranslate {
  /**
   *
   */
  constructor(translate: TranslateService) {
    this.ATENCAO = translate.instant('ATENCAO');
    this.A_AREA_INFORMADA_PARA_ESTA_OS_ULTRAPASSA_A_AREA_PREVISTA_MAXIMO_PERMITIDO_ = translate.instant('A_AREA_INFORMADA_PARA_ESTA_OS_ULTRAPASSA_A_AREA_PREVISTA_MAXIMO_PERMITIDO_');
    this.FEITO = translate.instant('FEITO');
    this.APONTAMENTO_ALTERADO = translate.instant('APONTAMENTO_ALTERADO');
    this.APONTAMENTO_CADASTRADO = translate.instant('APONTAMENTO_CADASTRADO');
    this.INTEGRACAO_ERP = translate.instant('INTEGRACAO_ERP');
    this.MOVIMENTO_DE_ESTOQUE_CRIADO_NO_ = translate.instant('MOVIMENTO_DE_ESTOQUE_CRIADO_NO_');
    this.SEU_APONTAMENTO_FOI_GERADO_MAS_O_MOVIMENTO_DE_ESTOQUE_NAO_FOI_CRIADO_NO_ = translate.instant('SEU_APONTAMENTO_FOI_GERADO_MAS_O_MOVIMENTO_DE_ESTOQUE_NAO_FOI_CRIADO_NO_');
    this.ERRO_ = translate.instant('ERRO_');
    this.FEITO = translate.instant('FEITO');
    this.ORDEM_DE_SERVICO_CONCLUIDA = translate.instant('ORDEM_DE_SERVICO_CONCLUIDA');
    this.NAO_DEFINIDO = translate.instant('NAO_DEFINIDO');

  }

  ATENCAO: string = 'Atenção!';
  A_AREA_INFORMADA_PARA_ESTA_OS_ULTRAPASSA_A_AREA_PREVISTA_MAXIMO_PERMITIDO_: string = 'A área informada para esta OS ultrapassa a área prevista. Máximo permitido: ';
  FEITO: string = 'Feito!';
  APONTAMENTO_ALTERADO: string = 'Apontamento alterado!';
  APONTAMENTO_CADASTRADO: string = 'Apontamento cadastrado!';
  INTEGRACAO_ERP: string = 'Integração ERP';
  MOVIMENTO_DE_ESTOQUE_CRIADO_NO_: string = 'Movimento de Estoque criado no ';
  SEU_APONTAMENTO_FOI_GERADO_MAS_O_MOVIMENTO_DE_ESTOQUE_NAO_FOI_CRIADO_NO_: string = 'Seu Apontamento foi gerado, mas o Movimento de Estoque não foi criado no ';
  ERRO_: string = 'ERRO: ';
  ORDEM_DE_SERVICO_CONCLUIDA: string = 'Ordem de serviço concluída!';
  NAO_DEFINIDO: string = 'Não definido';
}
