import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcessoPlanejamento } from '../classes/ProcessoPlanejamento';


@Injectable({
  providedIn: 'root'
})
export class ProcessoPlanejamentoService {

  constructor(private http: HttpClient) { }

  getProcessosPlanejamento(): Observable<any>{
    return this.http.get(`${environment.apiURL}/processoPlanejamento`)
  }

  getProcessoPlanejamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/processoPlanejamento/${id}`)
  }

  postProcessoPlanejamento(processoPlanejamento: ProcessoPlanejamento): Observable<any>{
    return this.http.post(`${environment.apiURL}/processoPlanejamento`, processoPlanejamento);
  }

  putProcessoPlanejamento(id: number, processoPlanejamento: ProcessoPlanejamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/processoPlanejamento/${id}`, processoPlanejamento);
  }

  deleteProcessoPlanejamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/processoPlanejamento/${id}`);
  }
}
