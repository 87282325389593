import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Endereco } from 'src/app/classes/Endereco';
import { EnderecoService } from 'src/app/services/endereco.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-enderecos',
  templateUrl: './listar-enderecos.component.html',
  styleUrls: ['./listar-enderecos.component.scss']
})
export class ListarEnderecosComponent implements OnInit {

  listaEndereco: Endereco[];
  @Input() listaAuxEndereco: Endereco[];

  @Output() editarEnderecoEvento = new EventEmitter<any>();
  @Output() enderecoExcluidoEvento = new EventEmitter<any>();
  @Output() apagarEnderecoEmMemoriaEvento = new EventEmitter<Endereco>();

  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  textoBusca: string = '';
  isSubmit: boolean;
  touched = false;

  constructor(
    private enderecoService: EnderecoService,
    private toasty: CustomToastyService) {
  }

  ngOnInit() {

  }

  excluir(endereco: Endereco) {
    Swal.fire({
      title: 'Deletar '+ endereco.Descricao + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          if(endereco.Id === undefined){
            this.toasty.show('Feito!', 'Endereço excluído!', 'success');
            this.apagarEnderecoEmMemoriaEvento.emit(endereco);
            this.carregando = false;
            return;
          }

          this.carregando = true;
          this.enderecoService.Excluir(endereco.Id).subscribe(
            res => {
              this.toasty.show('Feito!', 'Endereço excluído!', 'success');
              this.carregando = false;
              this.enderecoExcluidoEvento.emit(endereco.Id);
            },
            err => {
              Swal.fire('Erro!', err.message, 'error');
              this.carregando = false;
            });
        }
      });
  }

  filtrarEnderecos(){
    this.listaEndereco = this.listaAuxEndereco.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca) ||
      x.CEP.toUpperCase().includes(this.textoBusca) ||
      x.Logradouro.toUpperCase().includes(this.textoBusca)
    );
  }

  editar(endereco: Endereco) {
    this.editarEnderecoEvento.emit(endereco);
  }

}
