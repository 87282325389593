export class AuxManutencaoFrota {
    Id: number;
    EquipamentoId: number;
    TagEquipamento:string = "";
    EquipamentoDescricao:string = "";
    UltimoMedidor: number = 0;
    MedidorUltimaManutencao:number = 0;
    ChecklistId: number;
    EmpresaId: number;
    DataUltimaExecucao: Date = new Date();
    Repetir: boolean;
    Periodico:string = "";
    ChecklistString:string = "";
    TipoChecklist:string = "";
    TipoIntervalo:string = "";
    DataProximaManutencao: Date = new Date();
    TipoManutencao: EnumTipoManutencao;
    TipoManutencaoString:string = "";
    ProximoMedidor: number = 0;
    MedidorAtual: number = 0;
    MedidorRestante: number = 0;
    OrigemManutencao: EnumOrigemManutencao;
    OrigemManutencaoString:string = "";
    Status: EnumStatusManutencao;
    StatusString:string = "";
}

export enum EnumTipoManutencao {
    Preventiva = 1,
    Corretiva = 2,
    Preditiva = 3,
    Externa = 4
}

export enum EnumOrigemManutencao {
    Interna = 1,
    Externa = 2
}

export enum EnumStatusManutencao {
    EmDias = 1,
    Alerta = 2,
    Atrasado = 3
}

export enum EnumTipoIntervalo
{
    Horimetro = 1,
    Hodometro = 2,
    Dias = 3
}
