import { ItemEnum } from './ItemEnum';

export class ComentarioSafra {
    Id: number;
    AreaId: number;
    Tipo: number = 3;
    Descricao:string = "";
    Imagem:string = "";
    UploadImage:string = "";
    EmpresaId: number;
    DataCadastro: Date = new Date();
    UsuarioCadastroId: number;
    album = [];

    //auxiliares
    fotoUsuario:string = "";
    nomeUsuario:string = "";
}

export enum EnumTipoComentario {
    PragasDoencas = 1,
    Observacoes = 2,
    Diversos = 3
}

export const TipoComentario: ItemEnum[] = [
    { name: 'Pragas e Doenças', value: EnumTipoComentario.PragasDoencas },
    { name: 'Obsevações', value: EnumTipoComentario.Observacoes },
    { name: 'Diversos', value: EnumTipoComentario.Diversos }
];
