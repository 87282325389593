export class AlcadasAprovacao {
  public Id: number;
  public AprovadorId: number;
  public Valor: number = 0;
  public EmpresaSelecionadaId?: number;
  public GrupoCompraId: number;
  public Processo: number = 0;

  public GetProcesso:string = "";
}
