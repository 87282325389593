import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApontamentoIndicadores } from '../classes/ApontamentoIndicadores';
import { FiltroRelatorio } from '../components/relatorios/relatorios-componente/filtro-relatorios/filtro-relatorios.component';

@Injectable({
  providedIn: 'root'
})
export class ApontamentoIndicadoresService {

  constructor(private http: HttpClient) { }

  listar(): Observable<any>{
    return this.http.get(`${environment.apiURL}/ApontamentoIndicadores`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ApontamentoIndicadores/${id}`)
  }

  post(apontamentoIndicadores: ApontamentoIndicadores): Observable<any>{
    return this.http.post(`${environment.apiURL}/ApontamentoIndicadores`, apontamentoIndicadores);
  }

  put(id: number, apontamentoIndicadores: ApontamentoIndicadores): Observable<any>{
    return this.http.put(`${environment.apiURL}/ApontamentoIndicadores/${id}`, apontamentoIndicadores);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/ApontamentoIndicadores/${id}`);
  }

  listarPorOS(ordemServicoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ApontamentoIndicadores/listar/${ordemServicoId}`)
  }
  
  listarParaRelatorio(filtro:FiltroRelatorio): Observable<any>
  {
    return this.http.post(`${environment.apiURL}/ApontamentoIndicadores/listarRelatorio`, filtro)
  }
}
