import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnoAgricola } from '../classes/AnoAgricola';

@Injectable({
  providedIn: 'root'
})
export class AnoAgricolaService {

  public anoAgricolaSelecionado: AnoAgricola;

  constructor(private http: HttpClient) { }

  getAnosAgricolas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/anoAgricola`)
  }

  getAnoAgricola(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/anoAgricola/${id}`)
  }

  postAnoAgricola(anoAgricola: AnoAgricola): Observable<any>{
    return this.http.post(`${environment.apiURL}/anoAgricola`, anoAgricola);
  }

  putAnoAgricola(id: number, anoAgricola: AnoAgricola): Observable<any>{
    return this.http.put(`${environment.apiURL}/anoAgricola/${id}`, anoAgricola);
  }

  deleteAnoAgricola(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/anoAgricola/${id}`);
  }

  listarPorFazenda(fazendaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/anoAgricola/listarPorFazenda/${fazendaId}`);
  }
}
