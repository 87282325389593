import { ItemEnum } from './ItemEnum';
import { ItemMovimentoEstoque } from "./ItemMovimentoEstoque";

export class MovimentoEstoque {
    Id: number;
    TipoMovimento: number = 0;
    LocalEstoqueId: number;
    DataMovimento: Date = new Date();
    GrupoMovimento: number;
    CodigoOrigem: number = 0;
    ApontamentoId: number;
    AbastecimentoId: number;
    Observacao:string = "";
    IdMovimentoErp:string = "";
    FormularioApontamento:string = "";
    LocalEstoqueDestinoId:number;
    ItensMovimentoEstoque: ItemMovimentoEstoque[];
    ItensMovimentoEstoqueRemovidos: ItemMovimentoEstoque[] = [];
    RealizarSincroniaERP:boolean = true;
}


export enum EnumTipoMovimentoEstoque
{
    Entrada = 1,
    Saida = 2
}

export enum EnumGrupoMovimentoEstoque
{
    Nota = 1,
    OS = 2,
    Integracao = 3,
    Avulsa = 4,
    Transferencia = 5,
    Abastecimento = 6,
    Producao = 7,
    ManutencaoFrota = 8,
    OrdemCompra = 9
}


export const tiposMovimentoEstoque: ItemEnum[] = [
    {name: 'Entrada', value: EnumTipoMovimentoEstoque.Entrada},
    {name: 'Saída', value: EnumTipoMovimentoEstoque.Saida}
];

export const gruposMovimentoEstoque: ItemEnum[] = [
    {name: 'Nota Fiscal', value: EnumGrupoMovimentoEstoque.Nota},
    {name: 'Ordem de Serviço', value: EnumGrupoMovimentoEstoque.OS},
    {name: 'Integração ERP', value: EnumGrupoMovimentoEstoque.Integracao},
    {name: 'Avulsa', value: EnumGrupoMovimentoEstoque.Avulsa},
    {name: 'Transferência', value: EnumGrupoMovimentoEstoque.Transferencia},
    {name: 'Abastecimento', value: EnumGrupoMovimentoEstoque.Abastecimento},
    {name: 'Produção', value: EnumGrupoMovimentoEstoque.Producao},
    {name: 'Manutenção Frota', value: EnumGrupoMovimentoEstoque.ManutencaoFrota},
    {name: 'Ordem Compra', value: EnumGrupoMovimentoEstoque.OrdemCompra},
];
