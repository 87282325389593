import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Movimento } from 'src/app/classes/Movimento';
import { Safra } from 'src/app/classes/Safra';
import { Usuario } from 'src/app/classes/Usuario';
import { Cotacao } from 'src/app/classes/cotacao/cotacao';
import { cotacaoService } from 'src/app/services/cotacao/cotacaoService';
import { MovimentoService } from 'src/app/services/movimento.service';
import { SafraService } from 'src/app/services/safra.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';
import { RastreioMovimento } from '../../../classes/rastreio-movimento.model';
import { RastreioMovimentoService } from '../../../services/rastreio-movimento.service';

@Component({
  selector: 'app-rastreio-movimento',
  templateUrl: './rastreio-movimento.component.html',
  styleUrls: ['./rastreio-movimento.component.scss']
})
export class RastreioMovimentoComponent implements OnInit {


  @Input() rastreios: RastreioMovimento[] = [];
  constructor(
    private rastreioMovimentoService: RastreioMovimentoService,
    private utils: UtilsService,
    private movimentoService: MovimentoService,
    private safraService: SafraService,
    private cotacaoService: cotacaoService,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  formatarSerie(serie){
    return '#' + serie.toString().padStart(5, '0');
  }
}
