import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Natureza } from '../classes/Natureza';

@Injectable({
  providedIn: 'root'
})
export class NaturezaService {

  constructor(private http: HttpClient) { }

  getList(): Observable<any>{
    return this.http.get(`${environment.apiURL}/Naturezas`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Naturezas/${id}`)
  }

  post(natureza: Natureza): Observable<any>{
    return this.http.post(`${environment.apiURL}/Naturezas`, natureza);
  }

  put(id: number, natureza: Natureza): Observable<any>{
    return this.http.put(`${environment.apiURL}/Naturezas/${id}`, natureza);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/Naturezas/${id}`);
  }
}
