import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltrosCustoManutencao } from '../classes/dashboad-manutencao/FiltrosCustoManutencao';

@Injectable({
  providedIn: 'root'
})
export class DashboardManutencaoService {

  constructor(private http: HttpClient) { }

  getBuscaPendencia1(status: number, tipo: number, origem: number, tipoIntervalo: number, equipamentoId: number
    , dataInicial: string, dataFinal: string): Observable<any> {
    return this.http.get(`${environment.apiURL}/dashboardmanutencao/BuscaPendencia/${status}/${tipo}/${origem}/${tipoIntervalo}/${equipamentoId}/${dataInicial}/${dataFinal}`)
  }

  getBuscaPendencia2(status: number, tipo: number, origem: number, tipoIntervalo: number, dataInicial: string, dataFinal: string): Observable<any> {
    return this.http.get(`${environment.apiURL}/dashboardmanutencao/BuscaPendencia/${status}/${tipo}/${origem}/${tipoIntervalo}/${dataInicial}/${dataFinal}`)
  }

  getBuscaPendencia3(status: number, tipo: number, origem: number, tipoIntervalo: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/dashboardmanutencao/BuscaPendencia/${status}/${tipo}/${origem}/${tipoIntervalo}`)
  }

  getBuscaPendencia4(status: number, tipo: number, origem: number, tipoIntervalo: number, equipamentoId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/dashboardmanutencao/BuscaPendencia/${status}/${tipo}/${origem}/${tipoIntervalo}/${equipamentoId}`)
  }

  getPrioridades(): Observable<any> {
    return this.http.get(`${environment.apiURL}/dashboardmanutencao/Prioridades`)
  }

  postBuscaPendencia(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/BuscaPendencia`, obj);
  }

  postCustoManutencao(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/CustoManutencao`, obj);
  }

  postCustoManutencaoPorOs(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/CustoManutencaoPorOs`, obj);
  }

  postTempoManutencao(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/TempoManutencao`, obj);
  }

  postQuantidadeOSAberta(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/QuantidadeOSAberta`, obj);
  }

  postOSPorCriticidade(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/OSPorCriticidade`, obj);
  }

  postNumeroOSPorGrupoMaquina(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/NumeroOSProGrupoMaquina`, obj);
  }

  postQuantidadeOsPorSistema(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/QuantidadeOsPorSistema`, obj);
  }

  postIndicadoresOS(obj: FiltrosCustoManutencao): Observable<any> {
    return this.http.post(`${environment.apiURL}/dashboardmanutencao/IndicadoresOS`, obj);
  }
}
