import { Injectable } from "@angular/core";
import { enumPerfil, Usuario } from '../classes/Usuario';
import * as CryptoJS from 'crypto-js';
import { Empresa } from '../classes/Empresa';
import { Fazenda } from "../classes/Fazenda";
import { AnoAgricola } from "../classes/AnoAgricola";

@Injectable({
  providedIn: "root"
})

export class StorageService {
  pfx = "linkfarm_";
  constructor() { }

  setToken(token: string) {
    localStorage.setItem(this.pfx + "access_token", token);
  }

  getToken(): string {
    return localStorage.getItem(this.pfx + "access_token");
  }

  removerToken() {
    localStorage.removeItem(this.pfx + "access_token");
  }

  getUsuario(): Usuario {
    let cripUsuario = localStorage.getItem(this.pfx + "usuario");
    if (cripUsuario) {
      let usuario: Usuario = JSON.parse(this.descriptografar(cripUsuario));
      //Tratar permissões
      usuario.isSuper = usuario.PerfilInt == enumPerfil.Super || usuario.PerfilInt == enumPerfil.Master;
      if(usuario.PermissaoUsuario) {
        usuario.PermissaoUsuario.LiberarOS = usuario.PermissaoUsuario.LiberarOS || usuario.isSuper;
        usuario.PermissaoUsuario.AcessoTodasFazendas = usuario.PermissaoUsuario.AcessoTodasFazendas || usuario.isSuper;
        usuario.PermissaoUsuario.ExcluirSafraInteira = usuario.PermissaoUsuario.ExcluirSafraInteira || usuario.isSuper;
      }
      usuario.SincronizaEstoqueERP = usuario.SincronizaEstoqueERP || usuario.isSuper;
      if(usuario.PermissaoUsuario) {
        usuario.PermissaoUsuario.ReabrirOS = usuario.PermissaoUsuario.ReabrirOS || usuario.isSuper;
        usuario.PermissaoUsuario.SubstituirProdutosOSPendentes = usuario.PermissaoUsuario.SubstituirProdutosOSPendentes || usuario.isSuper;
      }
      return usuario;
    } else {
      return null;
    }
  }

  setUsuario(usuario: Usuario) {
    localStorage.setItem(this.pfx + "usuario", this.criptografar(JSON.stringify(usuario)));
  }

  removerUsuario() {
    localStorage.removeItem(this.pfx + "usuario");
  }

  getEmpresa(): Empresa {
    let empresa = localStorage.getItem(this.pfx + "empresa");
    if (empresa) {
      return JSON.parse(this.descriptografar(empresa));
    } else {
      return null;
    }
  }

  setEmpresa(empresa: Empresa) {
    localStorage.setItem(this.pfx + "empresa", this.criptografar(JSON.stringify(empresa)));
  }

  removerEmpresa() {
    localStorage.removeItem(this.pfx + "empresa");
  }

  criptografar(texto:string): string {
      return  CryptoJS.AES.encrypt(texto, 'linkCript').toString();
  }

  descriptografar(cript: string): string{
    return CryptoJS.AES.decrypt(cript, 'linkCript').toString(CryptoJS.enc.Utf8);
  }

  setModulo(modulo:string){
    sessionStorage.setItem(this.pfx + "modulo",this.criptografar(modulo));
  }

  getModulo():string{
    let modulo = sessionStorage.getItem(this.pfx + "modulo");
    if(modulo){
      return this.descriptografar(modulo);
    }
    return "operacoes";
  }

  setThreadImportId(threadId:number){
    localStorage.setItem("threadId", threadId.toString());
  }

  getThreadImportId(): number{
    let threadId = localStorage.getItem("threadId");
    if(threadId)
      return parseInt(threadId);
    return 0;
  }

  setFazenda(fazenda: Fazenda){
    localStorage.setItem(this.pfx + "fazenda", this.criptografar(JSON.stringify(fazenda)));
  }

  getFazenda(): Fazenda {
    let fazenda = localStorage.getItem(this.pfx + "fazenda");
    if (fazenda) {
      return JSON.parse(this.descriptografar(fazenda));
    } else {
      return null;
    }
  }

  removerFazenda() {
    localStorage.removeItem(this.pfx + "fazenda");
  }

  setAnoAgricola(anoAgricola: AnoAgricola){
    localStorage.setItem(this.pfx + "anoAgricola", this.criptografar(JSON.stringify(anoAgricola)));
  }

  getAnoAgricola(): AnoAgricola {
    let anoAgricola = localStorage.getItem(this.pfx + "anoAgricola");
    if (anoAgricola) {
      return JSON.parse(this.descriptografar(anoAgricola));
    } else {
      return null;
    }
  }

  getAnoAgricolaId(): number {
    let anoAgricola = this.getAnoAgricola();
    if (anoAgricola) {
      return anoAgricola.Id;
    } else {
      return 0;
    }
  }

  removerAnoAgricola() {
    localStorage.removeItem(this.pfx + "anoAgricola");
  }

  setSafraCusto(safraId:number){
    localStorage.setItem(this.pfx + "safraCusto", safraId.toString());
  }

  getSafraCusto(){
    let safraId = localStorage.getItem(this.pfx + "safraCusto");
    if(safraId)
      return parseInt(safraId)
    return 0;
  }

}
