import { Component, OnInit } from '@angular/core';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { AnoSafraService } from 'src/app/services/ano-safra.service';
import { AnoAgricolaService } from 'src/app/services/ano-agricola.service';
import { AnoAgricola } from 'src/app/classes/AnoAgricola';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ano-safra',
  templateUrl: './ano-safra.component.html',
  styleUrls: ['./ano-safra.component.scss']
})
export class AnoSafraComponent implements OnInit {

  anoSafras: AnoSafra[];
  auxAnoSafras: AnoSafra[];
  anoSafra: AnoSafra;
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;
  anosAgricolas: AnoAgricola[];

  // tradução
  ERRO: string = 'Erro!';
  ANO_SAFRA_ALTERADO: string = 'Ano Safra alterado!';
  FEITO: string = 'Feito!';
  ANO_SAFRA_CADASTRADO: string = 'Ano Safra cadastrado!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  ANO_SAFRA_DELETADO: string = 'Ano Safra deletado!';

  constructor(private AnoSafrasService: AnoSafraService,
    private route: ActivatedRoute, private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private anoAgricolaService: AnoAgricolaService,
    private translate: TranslateService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.anoSafra = new AnoSafra();
    this.anoAgricolaService.getAnosAgricolas().subscribe(res => {
      this.anosAgricolas = res;
    });

    if (this.id > 0)
      this.selecionarAnoSafra();
    else
      this.listarAnoSafra();
  }

  selecionarAnoSafra() {
    this.carregando = true;
    this.configurarTraducao();
    this.AnoSafrasService.getAnoSafra(this.id).subscribe(res => {
      this.anoSafra = new AnoSafra();
      this.anoSafra = res;
      this.carregando = false;
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarAnoSafra() {
    this.carregando = true;
    this.configurarTraducao();
    this.AnoSafrasService.getAnosSafras().subscribe(
      res => {
        this.anoSafras = res;
        this.auxAnoSafras = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarAnoSafra();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.configurarTraducao();

    if (this.anoSafra.Id > 0)
      this.AnoSafrasService.putAnoSafra(this.anoSafra.Id, this.anoSafra).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ANO_SAFRA_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.AnoSafrasService.postAnoSafra(this.anoSafra).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ANO_SAFRA_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(AnoSafraId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.AnoSafrasService.deleteAnoSafra(AnoSafraId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.ANO_SAFRA_DELETADO, 'success');
            this.listarAnoSafra();
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }

  filtrarAnoSafra() {
    this.anoSafras = this.auxAnoSafras.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
    );
  }

  cancelar() {
    this.router.navigate(['/planejamento/ano-safra']);
  }

  getAnoAgricola(anoAgricolaId: number): AnoAgricola {
    return this.anosAgricolas.find(x => x.Id == anoAgricolaId);
  }
  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.ANO_SAFRA_ALTERADO = this.translate.instant('ANO_SAFRA_ALTERADO');
    this.FEITO = this.translate.instant('FEITO');
    this.ANO_SAFRA_CADASTRADO = this.translate.instant('ANO_SAFRA_CADASTRADO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.ANO_SAFRA_DELETADO = this.translate.instant('ANO_SAFRA_DELETADO');
  }
}
