import { AtividadeService } from 'src/app/services/atividade.service';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { StorageService } from 'src/app/services/storage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Empresa, enumMoeda } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { MasksService } from 'src/app/utils/masks.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Atividade } from 'src/app/classes/Atividade';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {
  @ViewChild('ModalAtivadePadrao', { static: false })
  ModalAtivadePadrao: UiModalComponent;


  empresas: Empresa[];
  auxEmpresas: Empresa[];
  empresa: Empresa;
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  atividadeId: any = "";
  atividades: Atividade[] = [];
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  isParametros: boolean = false;
  gruposProduto: GrupoProduto[] = [];

  moedas = this.enumParaObject(enumMoeda)
  moeda: string = 'Real';

  // tradução
  ERRO: string = 'Erro!';
  ESTE_REGISTRO_NAO_PODE_SER_ALTERADO: string = 'Este registro não pode ser alterado';
  FEITO: string = 'Feito!';
  PARAMETROS_ALTERADOS: string = 'Parâmetros alterados!';
  EMPRESA_ALTERADA: string = 'Empresa alterada!';
  EMPRESA_CADASTRADA: string = 'Empresa cadastrada!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  EMPRESA_DELETADA: string = 'Empresa deletada!';
  GERAR_OS: string = 'Gerar OS?';
  GERA_TODAS_AS_ORDENS_DE_SERVICO_DE_ACORDO_COM_OS_PLANEJAMENTOS_DA_EMPRESA: string = 'Gera todas as ordens de serviço de acordo com os planejamentos da empresa!';
  ORDENS_DE_SERVICOS_GERADAS: string = 'Ordens de serviços geradas!';
  ALGUMAS_ORDENS_DE_SERVICOS_NAO_FORAM_GERADAS_POIS_NAO_FOI_ENCERRADO_O_PLANTIO_DA_SAFRA: string = 'Algumas Ordens de Serviços não foram geradas pois não foi encerrado o plantio da Safra!';
  NAO_ENCERRADAS: string = 'Não Encerradas!';
  DEU_CERTO: string = 'Deu certo';

  constructor(private empresaService: EmpresaService,
    private ordemServicoService: OrdemServicoService,
    private AtividadeService: AtividadeService,
    public masks: MasksService,
    private route: ActivatedRoute,
    private router: Router,
    private toasty: CustomToastyService,
    private storageService: StorageService,
    private grupoProdutoService: GrupoProdutoService,
    private translate: TranslateService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    this.empresa = new Empresa();
    this.configurarTraducao();

    if (this.router.url.includes("parametros") &&
      this.storageService.getEmpresa() &&
      this.storageService.getEmpresa().Id > 1) {
      this.id = this.storageService.getEmpresa().Id;
      this.isParametros = true;
    }

    if (this.id == 1) {
      Swal.fire(this.ERRO, this.ESTE_REGISTRO_NAO_PODE_SER_ALTERADO, 'error');
      this.cancelar();
    }
    if (this.id > 0)
      this.selecionarEmpresa();
    else
      this.listarEmpresas();
  }

  selecionarEmpresa() {
    this.carregando = true;
    this.configurarTraducao();
    this.empresaService.getEmpresa(this.id).subscribe(res => {
      this.empresa = new Empresa();
      this.empresa = res;
      this.moeda = this.empresa.Moeda;
      this.selecionarAtividadePadrao();
      this.grupoProdutoService.getGruposProduto().subscribe(gru => {
        this.gruposProduto = gru;
      })
      this.carregando = false;
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarEmpresas() {
    this.carregando = true;
    this.configurarTraducao();
    this.empresaService.getEmpresas().subscribe(
      res => {
        this.empresas = res;
        this.auxEmpresas = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarEmpresas();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.configurarTraducao();
    this.carregando = true;
    this.empresa.AtividadePadraoId = this.atividadeId == "" ? null : this.atividadeId.Id > 0 ? this.atividadeId.Id : this.empresa.AtividadePadraoId;
    this.empresa.Moeda = this.moeda;

    console.log(this.empresa);
    if (this.empresa.Id > 0)
      this.empresaService.putEmpresa(this.empresa.Id, this.empresa).subscribe(
        res => {
          this.empresaService.novaEmpresaEmitter.emit({ semReload: true });
          this.toasty.show(this.FEITO, (this.isParametros ? this.PARAMETROS_ALTERADOS : this.EMPRESA_ALTERADA), 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.empresaService.postEmpresa(this.empresa).subscribe(
        res => {
          this.empresaService.novaEmpresaEmitter.emit({ semReload: true });
          this.toasty.show(this.FEITO, this.EMPRESA_CADASTRADA, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(empresaId: number, fantasia: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + fantasia + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.empresaService.deleteEmpresa(empresaId).subscribe(
          res => {
            this.empresaService.novaEmpresaEmitter.emit();
            this.toasty.show(this.FEITO, this.EMPRESA_DELETADA, 'success');
            this.listarEmpresas();
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
            this.carregando = false;
          });
      }
    });
  }

  filtrarEmpresas() {
    this.empresas = this.auxEmpresas.filter(x =>
      x.RazaoSocial.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.NomeFantasia.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.CgcCpf.includes(this.textoBusca) ||
      (x.ContatoNome && x.ContatoNome.toUpperCase().includes(this.textoBusca.toUpperCase())) ||
      (x.ContatoEmail && x.ContatoEmail.toUpperCase().includes(this.textoBusca.toUpperCase())) ||
      (x.ContatoTelefone && x.ContatoTelefone.includes(this.textoBusca))
    );
  }

  cancelar() {
    this.router.navigate(['/gerenciador/empresa']);
  }

  gerarOS() {
    this.configurarTraducao();
    Swal.fire({
      title: this.GERAR_OS,
      text: this.GERA_TODAS_AS_ORDENS_DE_SERVICO_DE_ACORDO_COM_OS_PLANEJAMENTOS_DA_EMPRESA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.carregando = true;

        this.ordemServicoService.gerarOrdemServico(this.empresa.Id).subscribe(
          res => {


            this.selecionarEmpresa();
            this.toasty.show(this.FEITO, this.ORDENS_DE_SERVICOS_GERADAS, 'success');

            let retorno = res.split(';');
            if (retorno && retorno.length > 0) {
              this.toasty.show(this.NAO_ENCERRADAS, this.ALGUMAS_ORDENS_DE_SERVICOS_NAO_FORAM_GERADAS_POIS_NAO_FOI_ENCERRADO_O_PLANTIO_DA_SAFRA, 'warning');
            }

            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
            this.carregando = false;
          });
      }
    });
  }

  getBase64(event) {
    let that = this;
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      that.empresa.LogomarcaBase64 = reader.result.toString();
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  selecionarAtividadePadrao() {
    this.configurarTraducao();
    this.AtividadeService.getAtividades().subscribe(res => {
      this.atividades = [];
      this.atividades = res;
      let temp = this.atividades.filter(x => x.Id == this.empresa.AtividadePadraoId);
      this.atividadeId = temp[0].Descricao;
      console.log(this.atividadeId);
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  importarEmpresa() {
    this.configurarTraducao();
    this.empresaService.getImportarEmpresas().subscribe(
      res => {
        this.toasty.show(this.FEITO, this.DEU_CERTO, 'success');
        this.carregando = false;
      });
  }

  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.ESTE_REGISTRO_NAO_PODE_SER_ALTERADO = this.translate.instant('ESTE_REGISTRO_NAO_PODE_SER_ALTERADO');
    this.FEITO = this.translate.instant('FEITO');
    this.PARAMETROS_ALTERADOS = this.translate.instant('PARAMETROS_ALTERADOS');
    this.EMPRESA_ALTERADA = this.translate.instant('EMPRESA_ALTERADA');
    this.EMPRESA_CADASTRADA = this.translate.instant('EMPRESA_CADASTRADA');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.EMPRESA_DELETADA = this.translate.instant('EMPRESA_DELETADA');
    this.GERAR_OS = this.translate.instant('GERAR_OS');
    this.GERA_TODAS_AS_ORDENS_DE_SERVICO_DE_ACORDO_COM_OS_PLANEJAMENTOS_DA_EMPRESA = this.translate.instant('GERA_TODAS_AS_ORDENS_DE_SERVICO_DE_ACORDO_COM_OS_PLANEJAMENTOS_DA_EMPRESA');
    this.ORDENS_DE_SERVICOS_GERADAS = this.translate.instant('ORDENS_DE_SERVICOS_GERADAS');
    this.ALGUMAS_ORDENS_DE_SERVICOS_NAO_FORAM_GERADAS_POIS_NAO_FOI_ENCERRADO_O_PLANTIO_DA_SAFRA = this.translate.instant('ALGUMAS_ORDENS_DE_SERVICOS_NAO_FORAM_GERADAS_POIS_NAO_FOI_ENCERRADO_O_PLANTIO_DA_SAFRA');
    this.NAO_ENCERRADAS = this.translate.instant('NAO_ENCERRADAS');
    this.DEU_CERTO = this.translate.instant('DEU_CERTO');
  }

  private enumParaObject(input: any): { Id: string, Value: string }[] {
    return Object
      .keys(input)
      .map(key => ({ Id: key, Value: input[key] }))
  }
}
