import { ItemAgrupamentoOrdemServico } from './ItemAgrupamentoOrdemServico';
export class AgrupamentoOrdemServico {
    Id: number;
    EmpresaId:number = 0;
    IdsOrdemServico:string = "";
    EquipamentoId: number;
    ImplementoId: number;
    TipoBicoId: number;
    QtdTanques: number = 0;
    Vazao: number = 0;
    AreaPorTanque: number = 0;
    Alvo:string = "";
    Observacao:string = "";

    ValidarAgrupamento: boolean = true;
}
