import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Produto } from 'src/app/classes/Produto';
import { EnumSincronizadoEstoque, FiltroMovimentoEstoque, ListaEnumSincronizadoEstoque } from 'src/app/classes/movimento-estoque/FiltroMovimentoEstoque';
import { MovimentoSrincronia } from 'src/app/classes/movimento-estoque/MovimentoSrincronia';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { MovimentoEstoqueService } from 'src/app/services/movimento-estoque.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-movimento-estoque-filtro',
  templateUrl: './movimento-estoque-filtro.component.html',
  styleUrls: ['./movimento-estoque-filtro.component.scss']
})

export class MovimentoEstoqueFiltroComponent implements OnInit {

  @Input() recolherFiltro = false;
  @Input() minHeight = 0;
  @Output() atualizarLista: EventEmitter<boolean> = new EventEmitter();


  filtro: FiltroMovimentoEstoque = new FiltroMovimentoEstoque();
  produtos: Produto[] = [];
  empresasInterna: EmpresaInterna[] = [];
  locaisEstoque: LocalEstoque[] = [];
  arrProdutos: number[] = [];
  listaStatus = ListaEnumSincronizadoEstoque;
  constructor(private produtoService: ProdutoService,
    private empresaInternaService: EmpresaInternaService,
    private movimentoEstoqueService: MovimentoEstoqueService,
    private localEstoqueService: LocalEstoqueService,
    private storageService: StorageService,
    private utils: UtilsService) { }

  ngOnInit() {
    this.listarProdutos();
    this.listarLocalEstoque();
    this.listarEmpresaInterna();
    this.filtro = {
      ApontamentoId: null,
      DataFim: null,
      DataInicio: null,
      EmpresaInternaId: null,
      FazendaId: this.storageService.getFazenda().Id,
      MovimentoEstoqueId: null,
      OrdemServicoId: null,
      LocalEstoqueId: null,
      ProdutosId: [],
      Sincronizado: EnumSincronizadoEstoque.Todos,
    } as FiltroMovimentoEstoque;
    this.filtrarClick();
  }
  listarProdutos(){
    this.produtoService.getProdutos()
    .subscribe( res => {
      this.produtos = res;
    }, err => this.utils.getErro(err))
  }
  listarEmpresaInterna(){
    this.empresaInternaService.getEmpresasInternas()
    .subscribe( res => {
      this.empresasInterna = res;
    }, err => this.utils.getErro(err))
  }
  listarLocalEstoque(){
    this.localEstoqueService.getLocaisEstoque()
    .subscribe(res => {
      this.locaisEstoque = res;
    }, err => this.utils.getErro(err))
  }
  limparFiltros(){
    this.arrProdutos = [];
    this.filtro = new FiltroMovimentoEstoque();
  }
  filtrarClick(){
    this.filtro.ProdutosId = this.arrProdutos;
    this.movimentoEstoqueService.MovimentoSrincronia(this.filtro)
    .subscribe(res => {
      console.debug(res)
      this.atualizarLista.emit(res);
    }, err => this.utils.getErro(err));
  }
}
