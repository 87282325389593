import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select-arvore',
  templateUrl: './select-arvore.component.html',
  styleUrls: ['./select-arvore.component.scss']
})
export class SelectArvoreComponent implements OnInit {
  @Input() id: number = 0;
  @Input() objs: any;
  @Input() label: string;
  @Input() rotaBase: string;

  @Output() selecionarId = new EventEmitter<number>();

  @ViewChild('ModalArvore', { static: true }) modalArvore;

  descricao: string;
  exibirArvore: boolean = false;

  constructor() { }

  ngOnInit() {
    if(this.id)
      this.descricao = this.objs.find(c => c.Id == this.id).Descricao;
  }

  abrirModalArvore() {
    this.modalArvore.show();
    this.exibirArvore = true;
  }

  fecharModalArvore() {
    this.modalArvore.hide();
    this.exibirArvore = false;
  }

  selecionar(id: number) {
    this.descricao = this.objs.find(c => c.Id == id).Descricao;
    this.selecionarId.emit(id);
    this.fecharModalArvore();
  }
}
