import { ItemEnum } from "./ItemEnum";
export class LocalEstoque {
    Id: number;
    Descricao:string = "";
    EmpresaId: number;
    FazendaId: number;
    CodLocalEstoqueERP:string = "";
    FilialERP:string = "";
    ControlaEstoque: boolean;
    TipoEstoque: enumTipoEstoque;
    IsTerceiro: boolean;
    TipoArmazem: enumTipoArmazem;
    Capacidade: number = 0;
    TipoControlePreco: enumTipoControlePreco;
    EmpresaInternaId: number;
}

export enum enumTipoEstoque
{
    Almoxarifado = 1,
    Armazem = 2
}

export const TiposEstoque: ItemEnum[] = [
    {name: 'Almoxarifado', value: enumTipoEstoque.Almoxarifado},
    {name: 'Armazem', value: enumTipoEstoque.Armazem}
  ];

export enum enumTipoArmazem
{
    SiloMetalico = 1,
    SiloBag = 2,
    SiloBolsa = 3
}

export const TiposArmazem: ItemEnum[] = [
    {name: 'Silo Metálico', value: enumTipoArmazem.SiloMetalico},
    {name: 'Bag', value: enumTipoArmazem.SiloBag},
    {name: 'Bolsa', value: enumTipoArmazem.SiloBolsa}
    ];

export enum enumTipoControlePreco
{
    UltimoValorValido = 1,
    MediaPonderada = 2
}

export const TiposControlePreco: ItemEnum[] = [
    {name: 'Último Valor Válido', value: enumTipoControlePreco.UltimoValorValido},
    {name: 'Média Ponderada', value: enumTipoControlePreco.MediaPonderada}
    ];
