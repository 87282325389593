import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ItemOrdemServico } from '../classes/ItemOrdemServico';

@Injectable({
  providedIn: 'root'
})
export class ItemOrdemServicoService {

  constructor(private http: HttpClient) { }

  getItensOrdemServico(ordemServicoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itemOrdemServico/listar/${ordemServicoId}`)
  }

  getItensPorOrdensServico(ordensServicoId: number[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/itemOrdemServico/listarPorOrdensServico/`, ordensServicoId)
  }

  getItemOrdemServico(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itemOrdemServico/${id}`)
  }

  postItemOrdemServico(itensOrdemServico: ItemOrdemServico[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/itemOrdemServico`, itensOrdemServico);
  }

  putItemOrdemServico(id: number, itemOrdemServico: ItemOrdemServico): Observable<any>{
    return this.http.put(`${environment.apiURL}/itemOrdemServico/${id}`, itemOrdemServico);
  }

  deleteItemOrdemServico(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/itemOrdemServico/${id}`);
  }

  calcDosePorTanque(produtoId: number, dose: number, areaPorTanque: number): Observable<any>{
    let itemOs = new ItemOrdemServico();
    itemOs.ProdutoId = produtoId;
    itemOs.Dose = dose;
    itemOs.AreaAplicacao = areaPorTanque;

    return this.http.post(`${environment.apiURL}/itemOrdemServico/calcDosePorTanque`, itemOs)
  }
}
