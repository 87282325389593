export class AtividadeSafra{
    AreaId: number;
    CulturaId: number;
    VariedadeId: number;
    DataPlantio: Date = new Date();
    AreaTotal: number = 0;
    AtividadeId: number;
    AtividadeDescricao:string = "";
    DataPrevistaInicio: Date = new Date();
    DataPrevistaFim: Date = new Date();
}
