import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JustificativaManifesto } from '../classes/auxiliar/JustificativaManifesto';

@Injectable({
  providedIn: 'root'
})
export class NotasService {

  constructor(private http: HttpClient) { }

  getNotas(lastId: string, inicial: Date, final: Date, empresaInternaId:number): Observable<any> {
    let dataIn = inicial.getDate() + '-' + (inicial.getMonth()+1) + '-' + inicial.getFullYear();
    let dataFin = final.getDate() + '-' + (final.getMonth()+1) + '-' + final.getFullYear();
    if (lastId)
      return this.http.get(`${environment.apiURL}/Notas/Notas/${lastId}/${dataIn}/${dataFin}/${empresaInternaId}`)
    else
      return this.http.get(`${environment.apiURL}/Notas/Notas/${dataIn}/${dataFin}/${empresaInternaId}`)
  }

  getPDF(chave: string, empresaInternaId:number): Observable<any> {
    return this.http.get(`${environment.apiURL}/Notas/ObterPDF/${chave}/${empresaInternaId}`)
  }

  mainifesto(chave: string, acao: string, justificativaString: string, empresaInternaId:number): any {
    let justificativa = new JustificativaManifesto();
    justificativa.Texto = justificativaString;
    return this.http.post(`${environment.apiURL}/Notas/Manifesto/${chave}/${acao}/${empresaInternaId}`, justificativa)
  }
}
