import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { Produto } from 'src/app/classes/Produto';
import { auxPossuiIntegracao } from 'src/app/classes/auxiliar/auxPossuiIntegracao';
import { auxIntegracaoService } from 'src/app/services/auxIntegracaoService';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { ProdutoService } from 'src/app/services/produto.service';

@Component({
  selector: 'app-select-produto',
  templateUrl: './select-produto.component.html',
  styleUrls: ['./select-produto.component.scss']
})
export class SelectProdutoComponent implements OnInit {
  @Output() produtoChange: EventEmitter<number> = new EventEmitter();
  @Output() produtoChangeEmitProdutoCompleto: EventEmitter<Produto> = new EventEmitter();
  @Input() produtoId: number = 0;
  @Input() isServico: boolean = false;
  @Input() isSubmit: boolean;
  @Input() isDisabled: boolean = false;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() auxClass: string;
  @Input() ocultarMsgObrigatorio: boolean = false;
  //ng-select produto
  pagina: number = 2;
  termoBusca: String = '';
  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  public inputProduto$ = new Subject<string | null>();
  produtos: Produto[] = [];
  gruposProdutos: GrupoProduto[] = [];
  auxPossuiIntegracao: auxPossuiIntegracao = new auxPossuiIntegracao();
  produto: Produto;

  constructor(private produtoService: ProdutoService,
    private integracaoService: auxIntegracaoService,
    private grupoProdutoService: GrupoProdutoService) {
    this.inputProduto$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMore(newTerm);
    });
  }

  ngOnInit() {
    this.integracaoService.getIntegracao().then(res => {
      this.auxPossuiIntegracao = res;
    });
    if(this.produtoId)
      this.produtoService.getProduto(this.produtoId).subscribe(prod => {
        this.produto = prod;
        this.produtoService.getProdutosPaginando(0, 1, prod.Descricao, this.isServico, false).subscribe(ret => {
          this.produtos = ret.Lista;
          this.adicionarERP();
          this.fetchMore(prod.Descricao);
        });
      });
      else
        this.listarProdutos();
  }

  adicionarERP()
  {
    if(this.auxPossuiIntegracao.possuiIntegracao)
      this.produtos.forEach(prod =>{
        if (!prod.Descricao.includes('IDERP:')) {
          prod.Descricao = prod.Descricao + ' - IDERP: ' + prod.IdERP;
        }
    });
  }

  listarProdutos() {
    this.produtoService.getProdutosPaginando(0, 1, '', this.isServico, false).subscribe(ret => {
      this.produtos = ret.Lista;
      this.grupoProdutoService.getGruposProduto().subscribe(ret => {
        this.gruposProdutos = ret;
        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
        this.adicionarERP();
      });
    });
  }

  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProdutos.filter(x =>
      x.Id == id
    );

    let novo = new GrupoProduto();
    return grupos.length > 0 ? grupos[0] : novo;
  }

  changeProduto(idProduto: number) {
    this.produtoChange.emit(idProduto);
    this.produto = this.produtos.find(c => c.Id == idProduto);
    this.produtoChangeEmitProdutoCompleto.emit(this.produto);
  }

  //ng-select functions
  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }

  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;
      this.produtoService.getProdutosPaginando(0, this.pagina, this.termoBusca, this.isServico, false).subscribe(ret => {
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.produtos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
        this.adicionarERP();
      });
    }, 300);
  }

}
