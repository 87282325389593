import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AlcadasAprovacao } from '../classes/AlcadasAprovacao';

@Injectable({
  providedIn: 'root'
})
export class AlcadasAprovacaoService {

  constructor(private http: HttpClient) { }

  toList(): Observable<any>{
    return this.http.get(`${environment.apiURL}/AlcadasAprovacao`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/AlcadasAprovacao/${id}`)
  }

  post(obj: AlcadasAprovacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/AlcadasAprovacao`, obj);
  }

  put(id: number, obj: AlcadasAprovacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/AlcadasAprovacao/${id}`, obj);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/AlcadasAprovacao/${id}`);
  }

}
