export class Local {
    Id:string;
    Descricao:string = "";

    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
}
