import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpresaComponent } from './empresa/empresa.component';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import {CustomFormsModule} from 'ng2-validation';
import {TextMaskModule} from 'angular2-text-mask';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {NgNumberFormatterModule} from 'ng-number-formatter';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TipoLancamentoComponent } from './tipo-lancamento/tipo-lancamento.component';
import { CulturaComponent } from './cultura/cultura.component';
import { EstadioFenologicoComponent } from './estadio-fenologico/estadio-fenologico.component';
import { ClasseProdutoComponent } from './classe-produto/classe-produto.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AnoSafraComponent } from './ano-safra/ano-safra.component';
import { TipoBicoComponent } from './tipo-bico/tipo-bico.component';
import { AnoAgricolaComponent } from './ano-agricola/ano-agricola.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ParametrosComprasComponent } from './parametros-compras/parametros-compras.component';
import { ParametrosGeraisComponent } from './parametros-gerais/parametros-gerais.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient} from '@angular/common/http';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    EmpresaComponent,
    TipoLancamentoComponent,
    CulturaComponent,
    EstadioFenologicoComponent,
    ClasseProdutoComponent,
    AnoSafraComponent,
    TipoBicoComponent,
    AnoAgricolaComponent,
    ParametrosComprasComponent,
    ParametrosGeraisComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
  }),
    AutocompleteLibModule,
  ]
})
export class GerenciadorModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}