import { Usuario } from "./Usuario";

export class MBase{
  Id: number;
  DataCadastro: Date = new Date();
  DataAlteracao: Date = new Date();
  UsuarioCadastroId: number;
  UsuarioCadastro: Usuario;
  UsuarioAlteracaoId: number;
  UsuarioAlteracao: Usuario;
}
