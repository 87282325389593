export class Area {
    Id: number;
    EmpresaId: number;
    FazendaId: number;
    SetorId: number;
    Descricao:string = "";
    Sigla:string = "";
    AreaTotal: number = 0;
    Irrigado: boolean;
    Proprio: boolean;
    Inativa: boolean;
    IdERP: number;
    AreaDisponivel: number = 0;
    LayersArea:string = "";

    //auxiliares para planejamento da safra
    AreaUtilizada: number = 0;
    Selecionada: boolean = false;
    CultivarId: number;
    PlanejamentoId: number;
    ProdutividadeSimulada: number = 0;
    PrecoVendaSimulado: number = 0;
    LucroSimulado: number = 0;
    DataPrevistaPlantio: Date = new Date();
    DataPrevistaColheita: Date = new Date();
    PlanoMestreProducaoId: number;
    VariedadeId: number;
    CulturaId: number;
    FlagPlantou: boolean = false;
    EmAtraso: boolean = false;
    IdPlanejamentoSafra: number = 0;
}
