import { safraCotacao } from './../../classes/cotacao/safraCotacao';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class safraCotacaoService {  

  constructor(private http: HttpClient) { }

  get(): Observable<any>{
    return this.http.get(`${environment.apiURL}/SafrasCotacao`)
  }

  getPorId(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/SafrasCotacao/${id}`)
  }

  getPorCotacao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/SafrasCotacao/Cotacao/${id}`)
  }

  post(safraCotacao: safraCotacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/SafrasCotacao`, safraCotacao);
  }

  put(id: number, safraCotacao: safraCotacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/SafrasCotacao/${id}`, safraCotacao);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/SafrasCotacao/${id}`);
  }

  sincronizar(safras: number[], id: number): Observable<any>{
    return this.http.post(`${environment.apiURL}/SafrasCotacao/Sincronizar/${id}`, safras);
  }

  getArray(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/SafrasCotacao/array/${id}`)
  }
}
