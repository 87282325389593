import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { Equipamento } from 'src/app/classes/Equipamento';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { EnumItemMovimentoOrigem, ItemMovimento } from 'src/app/classes/ItemMovimento';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { EnumStatusMovimento, Movimento } from 'src/app/classes/Movimento';
import { Produto } from 'src/app/classes/Produto';
import { Projeto } from 'src/app/classes/Projeto';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { ItemMovimentoService } from 'src/app/services/item-movimento.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { Transacao } from 'src/app/classes/Transacao';
import { Natureza } from 'src/app/classes/Natureza';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TransacaoService } from 'src/app/services/transacao.service';
import { ProjetoService } from 'src/app/services/projeto.service';
import { SelectArvoreComponent } from 'src/app/components/shared-farm/select-arvore/select-arvore.component';
import { ModalItemAdicionalComponent } from './modal-item-adicional/modal-item-adicional.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ListaEnumTipoPagamento } from 'src/app/classes/LancamentoFinanceiro';
import { MovimentoSharedService } from '../movimento-shared.service';
import { SafraService } from 'src/app/services/safra.service';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/services/storage.service';
import { EnumLocalEstoque } from 'src/app/classes/enum/EnumLocalEstoque';
import { ColDef } from 'src/app/classes/grid/ColDef';
@Component({
  selector: 'app-movimento-item-forms',
  templateUrl: './movimento-item-forms.component.html',
  styleUrls: ['./movimento-item-forms.component.scss']
})
export class MovimentoItemFormsComponent implements OnInit {

  constructor(
    private transacaoService: TransacaoService,
    private projetoService: ProjetoService,
    private produtoService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private equipamentoService: EquipamentoService,
    private itemMovimentoService: ItemMovimentoService,
    private safraService: SafraService,
    private utils: UtilsService,
    private storageService: StorageService,
    private sharedService: MovimentoSharedService
  ) {
    this.inputProduto$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMore(newTerm);
    });
  }

  // @Output() salvar: EventEmitter<any> = new EventEmitter();
  // @Output() excluir: EventEmitter<any> = new EventEmitter();

  @Input() movimento: Movimento;
  @Input() movimentosSelecionados: Movimento[] = [];
  @Input() colDefsItems: ColDef[];
  @Input() centrosResultados: CentroDeResultados[] = [];
  @Input() locaisEstoque: LocalEstoque[] = [];
  @Input() naturezas: Natureza[] = [];
  @Input() projetos: Projeto[] = [];
  @Input() wizard: boolean = false;
  @Input() transacao: Transacao = new Transacao();
  @Input() item: ItemMovimento = new ItemMovimento();
  @Input() modalItemAdicional: false;
  @Input() formItemAdicional: false;


  @ViewChild("modelItemAdicional", { static: false })
  modelItemAdicional: ModalItemAdicionalComponent;
  @ViewChild("SelectProjetos", { static: false })
  SelectProjetos: SelectArvoreComponent;
  @ViewChild("SelectCentroResultados", { static: false })
  SelectCentroResultados: SelectArvoreComponent;
  @ViewChild("SelectNatureza", { static: false })
  SelectNatureza: SelectArvoreComponent;

  @ViewChild('itemGrid', { static: false }) itemGrid


  itemsSelecionados: ItemMovimento[] = [];
  itemSelecionado: ItemMovimento = null;
  produtos: Produto[] = [];
  equipamentos: Equipamento[] = [];
  gruposProduto: GrupoProduto[] = [];
  listaTipoPagamento = ListaEnumTipoPagamento;
  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  showForms: boolean = false;
  termoBusca: String = '';
  pagina: number = 1;
  isSubmit = false;
  showTable = false;
  isSafra = false;
  gerarFinanceiroItem = false;
  showCentroResultado = false;
  showProjeto = false;
  showLocalEstoque = false;
  public inputProduto$ = new Subject<string | null>();

  tipoMovimento: number = 0;

  ngOnInit() {
    this.tipoMovimento = this.sharedService.tipoMovimento;
    if (!this.movimento.Itens)
      this.movimento.Itens = [];
    if(this.movimento.TransacaoId != undefined)
      this.getTransacao(this.movimento.TransacaoId);
    if(this.movimento != null){
      this.getprojetos();
      this.listarProdutos();
    }
    this.listarEquipamentos();
    this.showForms = this.wizard;
    if (this.formItemAdicional)
      this.showForms = true;
  }

  getTransacao(id: number) {
    this.transacaoService.getTransacao(id).subscribe((res) => {
      let transacao: Transacao = res;
      this.transacao = transacao;
      this.gerarFinanceiroItem = transacao.GerarFinanceiroAtravesDoItemDeMovimento;
      this.showCentroResultado = transacao.CentroResultadoItem;
      this.showProjeto = transacao.ProjetoItem;
      this.showLocalEstoque = transacao.LocalEstoque == EnumLocalEstoque.Item;
      //if (transacao.BuscaProdutosSafra) {
      //this.getprojetos();
      //}
    });
  }

  getprojetos() {
    if(this.tipoMovimento === 11) {
      this.projetoService.getList().subscribe(res => {
        this.projetos = res;
      }, err => this.utils.getErro(err))
    }
    else
      this.projetoService.getPorMovimento(this.movimento.Id).subscribe((res) => {
        this.projetos = res;
      });
  }

  ngAfterViewInit() {
    this.showForms = false;
  }
  getDescricaoSafra(id: number): string {
    this.movimento.ItemsSafra.forEach(x => {
      x.Items.forEach(y => {
        if (y.Id == id) {
          console.log("true - " + y.GetSafra + " - " + y.Id);
          return y.GetSafra;
        }
      });
    });
    return "";
  }

  salvar() {
    if (!this.novoItemOpcional)
      if (!this.wizard) {
        if (this.movimentosSelecionados.length <= 0 || this.movimentosSelecionados.length > 1) {
          this.utils.handleWarning("Selecione apenas um Movimento para prosseguir!");
          return;
        }
        this.item.MovimentoId = this.movimentosSelecionados[0].Id;
      }
      else
        this.item.MovimentoId = this.movimento.Id;

    this.item.MovimentoId = this.movimento.Id;

    const formulario = new FormGroup({
      produto: new FormControl(this.item.ProdutoId, [Validators.required]),
      centroDeResultados: new FormControl(this.item.CentroDeResultadosId, this.transacao.CentroResultadoItem ? Validators.required : Validators.nullValidator),
      projeto: new FormControl(this.item.ProjetoId, this.transacao.ProjetoItem ? Validators.required : Validators.nullValidator),
    })
    if (!formulario.valid) {
      this.isSubmit = true;
      return;
    }
    if (this.item.Id > 0) {
      this.itemMovimentoService.put(this.item.Id, this.item)
        .subscribe(res => {
          this.utils.handleSuccess("Item atualizado!");
          this.finalizar();
        }, err => this.utils.getErro(err));
    } else {
      this.item.Status = EnumStatusMovimento.Pendente;
      this.itemMovimentoService.post(this.item)
        .subscribe(res => {
          this.utils.handleSuccess("Item cadastrado!");
          this.finalizar();
          if (this.formItemAdicional) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          if (this.wizard) {
            this.item = res;
            this.movimento.Itens.push(res);
            this.movimento.ValorLiquido += this.movimento.Itens.reduce((acc, curr) => acc + curr.ValorTotalLiquido, 0);
            this.movimento.ValorBruto = this.movimento.ValorLiquido - this.movimento.Descontos + this.movimento.Juros + this.movimento.Acrescimos + this.movimento.Funrural;
            this.itemGrid.setRowData(this.movimento.Itens);
          }
          else{
            this.movimentosSelecionados[0].Itens.push(res);
          }

        }, err => this.utils.getErro(err));
    }
    // } else {
    //   this.item.Status = EnumStatusMovimento.Pendente;
    //   this.movimento.Itens.push(this.item);
    //   this.finalizar();
    // }
  }
  editar() {
    if (this.itemsSelecionados.length <= 0 || this.itemsSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um Item para prosseguir!");
      return;
    }
    // if (this.itemsSelecionados[0].Origem == EnumItemMovimentoOrigem.Safra) {
    //   this.utils.handleWarning("Itens de safra nao podem ser editados!");
    //   return;
    // }
    this.item = this.itemsSelecionados[0];
    this.showForms = true;
  }
  novoItemOpcional() {
    if (this.itemsSelecionados.length <= 0 || this.itemsSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um Item para prosseguir!");
      return;
    }

    this.modelItemAdicional.openModal(this.itemsSelecionados[0]);
  }
  finalizar() {
    this.item = new ItemMovimento();
    this.showForms = false;
  }
  novo() {
    this.showForms = true;
    this.item = new ItemMovimento();
    if(this.SelectProjetos != null)
      this.SelectProjetos.descricao = null;
    if(this.SelectCentroResultados != null)
      this.SelectCentroResultados.descricao = null;
    if(this.SelectNatureza != null)
      this.SelectNatureza.descricao = null;
    this.isSafra = false;
  }
  remove() {
    if (this.itemsSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione um Item para prosseguir!");
      return;
    }

    this.itemsSelecionados.forEach(item => {
      this.itemMovimentoService.delete(item.Id)
        .subscribe(res => {
          const ItemsId = this.itemsSelecionados.map(p => p.Id);
          if (!this.wizard)
            this.movimentosSelecionados[0].Itens = this.movimentosSelecionados[0].Itens.filter(p => !ItemsId.includes(p.Id));
          else
            this.movimento.Itens = this.movimento.Itens.filter(p => !ItemsId.includes(p.Id));

          this.movimento.ValorLiquido -= this.itemsSelecionados.reduce((acc, curr) => acc + curr.ValorTotalLiquido, 0);
          this.movimento.ValorBruto = this.movimento.ValorLiquido - this.movimento.Descontos + this.movimento.Juros + this.movimento.Acrescimos + this.movimento.Funrural;
          this.utils.handleSuccess("Item excluido!");
        }, err => this.utils.getErro(err));
    })
    this.itemSelecionado = null;
  }

  onDescontoChange() {
    if (this.item.ValorTotalLiquido > 0) {
      this.item.PercentualDesconto = (this.item.ValorDesconto / this.item.ValorTotalLiquido) * 100;
      this.onValorBrutoChange();
    }
  }
  onPercentualDescontoChange() {
    if (this.item.ValorTotalLiquido > 0) {
      this.item.ValorDesconto = (this.item.PercentualDesconto / 100) * this.item.ValorTotalLiquido;
      this.onValorBrutoChange();
    }
  }
  onValorBrutoChange() {
    this.item.ValorTotalBruto = this.item.ValorTotalLiquido - this.item.ValorDesconto;
  }
  onQuantidadeChange() {
    this.item.QuantidadePendente = this.item.QuantidadePedida;
    this.item.ValorTotalLiquido = this.item.ValorUnitario * this.item.QuantidadePedida;
    this.item.ValorICMS = this.item.ValorTotalLiquido * this.item.AliquotaICMS / 100;
    this.onValorBrutoChange();
  }
  onAliquotaChange() {
    this.item.ValorICMS = this.item.ValorTotalLiquido * this.item.AliquotaICMS / 100;
  }
  selectionChanged(event) {
    this.itemsSelecionados = event;
  }
  cellDoubleClicked(event) {
    this.editar();
  }
  cellClicked({ data }) {
    this.itemSelecionado = data;
    this.isSafra = this.itemSelecionado.Origem == EnumItemMovimentoOrigem.Safra;
  }
  onProdutoChange() {
    if (this.item.ProdutoId)
      this.itemMovimentoService.getItemProduto(this.item.ProdutoId)
        .subscribe(res => {
          const pai = this.item.ItemMovimentoPaiId;
          const movimentoId = this.item.MovimentoId;
          this.item = res;
          this.item.FormaPagamento = null;
          this.item.QuantidadePendente = this.item.QuantidadeOriginal;
          this.item.ValorTotalLiquido = this.item.ValorUnitario;
          this.item.NaturezaId = this.item.NaturezaId ? this.movimento.NaturezaId : this.item.NaturezaId;
          this.item.CentroDeResultadosId = this.movimento.CentroDeResultadosId;
          this.item.ProjetoId = this.movimento.ProjetoId;
          this.item.ItemMovimentoPaiId = pai;
          if (this.novoItemOpcional)
            this.item.MovimentoId = movimentoId;
        }, err => this.utils.getErro(err));
  }
  changeType(event) {
    this.showForms = !this.showForms;
  }

  listarEquipamentos() {
    this.equipamentoService.getEquipamentos()
      .subscribe(res => {
        this.equipamentos = res;
      }, err => this.utils.getErro(err));
  }
  listarProdutos() {
    this.grupoProdutoService.getGruposProduto().subscribe(ret => {
      this.gruposProduto = ret;
      this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      this.onScrollToEndProduto();
    });
  }
  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProduto.filter(x =>
      x.Id == id
    );
    let novo = new GrupoProduto();
    return grupos.length > 0 ? grupos[0] : novo;
  }
  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }
  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;
      this.produtoService.getTodosProdutosPaginando(this.pagina, 10, 0, this.termoBusca).subscribe(ret => {

        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null)
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(ret.Lista);

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    }, 300);
  }

  selecionarNatureza(id: number) {
    this.item.NaturezaId = id;
  }

  selecionarCentroResultados(id: number) {
    this.item.CentroDeResultadosId = id;
  }

  selecionarProjeto(id: number) {
    this.item.ProjetoId = id;
    this.safraService.getPorProjeto(id.toString()).subscribe(res => {
      const safra = res;
      if (safra.Id === 0){
        Swal.fire('Aten��o!', 'O Projeto deve estar vinculado a uma safra para que seja poss�vel emitir NFe!', 'warning');
      }
    })
  }

}
