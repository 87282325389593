import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Local } from 'src/app/classes/Local';
import { LocalService } from 'src/app/services/local.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-local-forms',
  templateUrl: './local-forms.component.html',
  styleUrls: ['./local-forms.component.scss']
})
export class LocalFormsComponent implements OnInit {

  id: string;
  local: Local = new Local();
  locals: Local[];
  auxLocals: Local[];
  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  constructor(
    private localService: LocalService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private utils : UtilsService) {
      this.route.params.subscribe(res => {
        this.id = res.id;
      });
    }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.local = new Local();
    if(this.router.url.search("inserir") > 0){
      this.inserindo = true;
      this.local = new Local()
    }
    else
      this.selecionarLocal();
  }

  selecionarLocal() {
    this.carregando = true;
    this.localService.ObterPorId(this.id).subscribe(res =>{
      this.local = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(!this.local.Id && this.utils.isValidGuid(this.local.Id)){
                  
      this.localService.Atualizar(this.local.Id, this.local).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('LOCAL_ALTERADO'));
          this.carregando = false;
          this.router.navigate(['/cadastros/local']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
    else{

      this.localService.Inserir(this.local).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('LOCAL_CADASTRADO'));
          this.toasty.show('Feito!', 'Local cadastrado!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/local']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
  }

  

  cancelar(){
    this.router.navigate(['/cadastros/local']);
  }

  

}