import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EstoqueInsumo } from 'src/app/classes/EstoqueInsumo';
import { Fazenda } from 'src/app/classes/Fazenda';
import { EstoqueInsumoService } from 'src/app/services/estoque-insumo.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { GrupoProduto } from './../../../classes/GrupoProduto';
import { LocalEstoque } from './../../../classes/LocalEstoque';
import { FazendaService } from './../../../services/fazenda.service';
import { GrupoProdutoService } from './../../../services/grupo-produto.service';

@Component({
  selector: 'app-estoque-insumo',
  templateUrl: './estoque-insumo.component.html',
  styleUrls: ['./estoque-insumo.component.scss']
})
export class EstoqueInsumoComponent implements OnInit {

  carregando: boolean;
  estoquesInsumos: EstoqueInsumo[] = [];
  auxEstoquesInsumos: EstoqueInsumo[] = [];
  locaisEstoque: LocalEstoque[] = [];
  gruposProduto: GrupoProduto[] = [];
  fazendas: Fazenda[] = [];

  textoBusca: string;

  fazendaId: number = 0;
  localEstoqueId: number = 0;
  grupoEstoqueId: number = 0;
  fazendaDescricao: string = "Fazenda...";
  localDescricao: string = "Todos";
  grupoDescricao: string = "Todos";

  constructor(private estoqueInsumoService: EstoqueInsumoService,
              private fazendaService: FazendaService,
              private localEstoqueService: LocalEstoqueService,
              private utils: UtilsService,
              private translate: TranslateService,
              private grupoProdutoService: GrupoProdutoService, 
              private storageService: StorageService) { }

  ngOnInit() {
    this.fazendaId = this.storageService.getFazenda() ? this.storageService.getFazenda().Id : 0;
    this.fazendaService.getFazendas().subscribe(ret=>{
      this.fazendas = ret;
      if(!this.fazendaId)
        this.fazendaId = this.fazendas[0].Id;            
      this.fazendaDescricao = this.fazendas.find(x=> x.Id == this.fazendaId).Nome;         
      this.listarLocalEstoque();      
    })    

    this.grupoProdutoService.getGruposProduto().subscribe(ret => {
      this.gruposProduto = ret;
    })

    this.listarEstoquesInsumos();
  }

  listarLocalEstoque(){
    this.localEstoqueService.listarPorFazenda(this.fazendaId).subscribe(ret => {
      this.locaisEstoque = ret;
    })
  }

  selecionarFazenda(id: number){
    this.fazendaId = id;
    this.fazendaDescricao = this.fazendas.find(x=> x.Id == this.fazendaId).Nome;         
    this.listarLocalEstoque();
    this.listarEstoquesInsumos();
  }

  selecionarLocal(id: number){
    this.localEstoqueId = id;
    if(this.localEstoqueId > 0)
      this.localDescricao = this.locaisEstoque.find(x => x.Id == id).Descricao;
    else
      this.localDescricao = this.translate.instant('TODOS');

    this.listarEstoquesInsumos();
  }

  selecionarGrupoProduto(id: number){
    this.grupoEstoqueId = id;
    if(this.grupoEstoqueId > 0)
      this.grupoDescricao = this.gruposProduto.find(x => x.Id == id).Descricao;
    else
      this.grupoDescricao = this.translate.instant('TODOS');
      
    this.listarEstoquesInsumos();
  }

  listarEstoquesInsumos(){
    this.carregando = true;
    this.estoqueInsumoService.getEstoqueAtual(this.fazendaId, this.localEstoqueId, this.grupoEstoqueId).subscribe(
      res => {        
        this.estoquesInsumos = res;
        this.auxEstoquesInsumos = res;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

}
