import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Gleba } from '../classes/Gleba';

@Injectable({
  providedIn: 'root'
})
export class GlebaService {  

  constructor(private http: HttpClient) { }   

  getGlebas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/glebas`)
  }

  getGleba(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/glebas/${id}`)
  }

  postGleba(gleba: Gleba): Observable<any>{
    return this.http.post(`${environment.apiURL}/glebas`, gleba);
  }

  putGleba(id: number, gleba: Gleba): Observable<any>{
    return this.http.put(`${environment.apiURL}/glebas/${id}`, gleba);
  }

  deleteGleba(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/glebas/${id}`);
  }

  getGlebasPorArea(idArea: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/glebas/area/${idArea}`)
  }
}
