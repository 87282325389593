import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pessoa } from 'src/app/classes/Pessoa';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { PessoaService } from 'src/app/services/pessoa.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pessoa',
  templateUrl: './pessoa.component.html',
  styleUrls: ['./pessoa.component.scss']
})
export class PessoaComponent implements OnInit {
  constructor(
    private pessoaService: PessoaService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(res => {
      if(res.id == 'inserir')
        this.id = 0;
      else
        this.id = res.id;
    });
  }

  pessoas: Pessoa[] = [];
  id: number = 0;
  pessoa: Pessoa;
  inserindo: boolean;

  @ViewChild('Grid', { static: false }) grid: any;

  colDefs: ColDef[] = [];
  objSelecionado: Pessoa = new Pessoa();
  objSelecionadoId: number = null;
  objetosSelecionados: Pessoa[] = [];

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    this.configurar();
    this.listarPessoas();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'NomeFantasia', headerName: 'Nome Fantasia'},
      {field: 'RazaoSocial', headerName: 'Razão Social', flex: 1},
      {field: 'Tipo', headerName: 'Tipo'},
      {headerName: 'Papel', field: 'Papel', cellRenderer: (params) => this.badgesPapeis(params.data.Papel), flex: 1},
      {field: 'DocumentoFormatado', headerName: 'Cpf/Cnpj', flex: 1},
      {field: 'TelefoneFormatado', headerName: 'Telefone', flex: 1},
      {field: 'CpfResponsavelFormatado', headerName: 'CPF Responsável', flex: 1},
      {field: 'Ativo', headerName: 'Status', cellRenderer: (params) => params.data.Ativo ? `<span class="badge badge-light-success mr-1">Ativo</span>` : `<span class="badge badge-light-danger mr-1">Inativo</span>` }
    ];

  }

  novo() {
    this.id = 0;
    this.inserindo = true;
    this.pessoa = new Pessoa();
  }

  listarPessoas() {
    this.pessoaService.getList()
      .subscribe(
        res => {
          this.pessoas = res;
        },
        err => {
          this.utils.getErro(err);
        }
      );
  }

  badgesPapeis(papeis: string) {
    var arr = papeis.split(',');
    var arrbadges = arr.map(p => {
      //return `${p}`
      return `<span class="badge badge-light-secondary mr-1">${p}</span>`
    });
    return arrbadges.join('');
  }


  onPessoaCriadoEvento() {
    this.inserindo = false;
    this.id = 0;
    this.listarPessoas();
  }

  excluir() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione uma pessoa para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas uma pessoa para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar ' + this.objetosSelecionados[0].RazaoSocial + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.pessoaService.delete(this.objSelecionadoId).subscribe(
          res => {
            this.utils.handleSuccess('Pessoa excluída!')
            this.listarPessoas();
          },
          err => {
            this.utils.getErro(err);
          });
      }
    });
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/pessoa/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }


}
