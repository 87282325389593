import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstoqueInsumoService {

  constructor(private http: HttpClient) { }

  getEstoquesInsumo(): Observable<any>{
    return this.http.get(`${environment.apiURL}/estoqueInsumo`)
  }
  
  getEstoqueAtual(fazendaId: number, localEstoqueId: number, grupoEstoqueId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/estoqueInsumo/estoqueAtual/${fazendaId}/${localEstoqueId}/${grupoEstoqueId}`)
  }
}
