import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAlbum } from 'ngx-lightbox';
import { Area } from 'src/app/classes/Area';
import { ComentarioSafra, TipoComentario } from 'src/app/classes/ComentarioSafra';
import { Usuario } from 'src/app/classes/Usuario';
import { ComentarioSafraService } from 'src/app/services/comentario-safra.service';
import { StorageService } from 'src/app/services/storage.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-comentario-safra',
  templateUrl: './comentario-safra.component.html',
  styleUrls: ['./comentario-safra.component.scss']
})
export class ComentarioSafraComponent implements OnInit {

  area: Area;
  imageChangedEvent: any = '';
  comentario: ComentarioSafra;
  comentarioExcluir = new ComentarioSafra();
  imageSelected = false;
  comentarios: ComentarioSafra[];
  displayImagem: string;
  tiposComentario = TipoComentario;
  usuarioLogadoId: number = 0;
  tipo = 0;
  usuarios: Usuario[] = [];

  baseImagem: string = environment.baseApiURL + '/Assets/Imagens/ComentarioSafra/';

  // tradução
  FEITO: string = 'Feito!';
  ALVO_DELETADO: string = 'Alvo deletado!';
  DELETAR_COMENTARIO: string = 'Deletar comentário?';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  ERRO: string = 'Erro!';
  constructor(private comentarioService: ComentarioSafraService,
              private usuarioService: UsuarioService,
              private storageService: StorageService,
              private toasty: CustomToastyService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.usuarioLogadoId = this.storageService.getUsuario().Id;
    this.usuarioService.getUsuarios().subscribe(res => {
        this.usuarios = res;
      });

  }

  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.displayImagem = reader.result.toString();
      this.comentario.UploadImage = this.displayImagem.split(',')[1];
      this.comentario.Imagem = file.name;
      this.imageSelected = true;
    };
  }

  novo() {
    this.comentario = new ComentarioSafra();
  }

  cancelar() {
    this.comentario = null;
    this.imageSelected = false;
    this.displayImagem = '';
  }

  salvar() {
    if (this.comentario.Id > 0) {
      this.comentarioService.putComentario(this.comentario.Id, this.comentario).subscribe(
        res => {
          this.displayImagem = '';
          this.imageSelected = false;
          this.comentario = null;
          this.listar();
        },
        err => {
          console.error(err);
        });
    } else {
      this.comentario.AreaId = this.area.Id;
      this.comentarioService.postComentario(this.comentario).subscribe(
        res => {
          this.displayImagem = '';
          this.imageSelected = false;
          this.comentario = null;
          this.listar();
        },
        err => {
          console.error(err);
        });
    }
  }

  async listar() {
    await this.comentarioService.getComentariosPorArea(this.area.Id).subscribe(ret => {
      this.comentarios = ret;

      for (const [i, c] of this.comentarios.entries()) {
        const a = [];
        const album = {
          src:  c.Imagem,
          thumb: c.Imagem,
        } as IAlbum;
        a.push(album);
        this.comentarios[i].album = a;
        let usu = this.getUsuario(c.UsuarioCadastroId);
        if (usu) {
          this.comentarios[i].nomeUsuario = usu.Nome;
          this.comentarios[i].fotoUsuario = usu.Imagem;
        }
      }
    }, err => {
      console.error(err);
    });
  }

  getUsuario(id: number): Usuario {
    return this.usuarios.find(u => u.Id === id);
  }

  editar(item: ComentarioSafra) {
    this.comentario = item;
    this.displayImagem = item.Imagem;
  }

  excluir(id: number): any {
    this.configurarTraducao();

    this.comentarioService.deleteComentario(id).subscribe(
      res => {
        this.displayImagem = '';
        this.imageSelected = false;
        this.comentario = null;
        this.toasty.show(this.FEITO, this.ALVO_DELETADO, 'success');
        this.listar();
        return true;
      },
      err => {
        Swal.fire(this.ERRO, err.message, 'error');
        return false;
      });
  }

  confirmarExclusao(item: ComentarioSafra){
    Swal.fire({
      title: this.DELETAR_COMENTARIO,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.excluir(item.Id);
        }
      });
  }

  filtrar() {
    if(this.tipo > 0){
      this.comentarioService.getComentariosPorArea(this.area.Id).subscribe(ret => {
        this.comentarios = ret.filter(x => x.Tipo == this.tipo);

        for (const [i, c] of this.comentarios.entries()) {
          const a = [];
          const album = {
            src: c.Imagem,
            thumb: c.Imagem,
          } as IAlbum;
          a.push(album);
          this.comentarios[i].album = a;
        }
      }, err => {
        console.error(err);
      });
    }
    else {
      this.listar();
    }
  }
  configurarTraducao() {
    this.FEITO = this.translate.instant('FEITO');
    this.ALVO_DELETADO = this.translate.instant('ALVO_DELETADO');
    this.DELETAR_COMENTARIO = this.translate.instant('DELETAR_COMENTARIO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.ERRO = this.translate.instant('ERRO');
}
}
