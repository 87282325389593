import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EntradaProducao } from '../classes/gestao-lite/EntradaProducao';

@Injectable({
  providedIn: 'root'
})
export class EntradaProducaoService {

  constructor(private http: HttpClient) { }

  getEntradaProducaos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/entradaProducao`)
  }

  getEntradaProducao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/entradaProducao/${id}`)
  }

  postEntradaProducao(entradaProducao: EntradaProducao): Observable<any>{
    return this.http.post(`${environment.apiURL}/entradaProducao`, entradaProducao);
  }

  putEntradaProducao(id: number, entradaProducao: EntradaProducao): Observable<any>{
    return this.http.put(`${environment.apiURL}/entradaProducao/${id}`, entradaProducao);
  }

  deleteEntradaProducao(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/entradaProducao/${id}`);
  }

  salvarCompleto(entradaProducao: EntradaProducao): Observable<any>{
    return this.http.post(`${environment.apiURL}/entradaProducao/salvarCompleto`, entradaProducao);
  }

  listarConsolidadoSafra(safraId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/entradaProducao/listarConsolidadoSafra/${safraId}`);    
  }

  listarConsolidadoArea(safraId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/entradaProducao/listarConsolidadoArea/${safraId}`);    
  }

}
