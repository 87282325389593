import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoDescontoProducao } from '../classes/gestao-lite/TipoDescontoProducao';

@Injectable({
  providedIn: 'root'
})
export class TipoDescontoProducaoService {

  constructor(private http: HttpClient) { }

  getTiposDescontoProducao(): Observable<any>{
    return this.http.get(`${environment.apiURL}/tipoDescontoProducao`)
  }

  getTipoDescontoProducao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/tipoDescontoProducao/${id}`)
  }

  postTipoDescontoProducao(tipoDescontoProducao: TipoDescontoProducao): Observable<any>{
    return this.http.post(`${environment.apiURL}/tipoDescontoProducao`, tipoDescontoProducao);
  }

  putTipoDescontoProducao(id: number, tipoDescontoProducao: TipoDescontoProducao): Observable<any>{
    return this.http.put(`${environment.apiURL}/tipoDescontoProducao/${id}`, tipoDescontoProducao);
  }

  deleteTipoDescontoProducao(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/tipoDescontoProducao/${id}`);
  }
}
