import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { Parceiro } from 'src/app/classes/parceiro/Parceiro';
import { ParceirosService } from 'src/app/services/parceiros/parceiros.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ColDef } from 'src/app/classes/grid/ColDef';


@Component({
  selector: 'app-parceiro',
  templateUrl: './parceiro.component.html',
  styleUrls: ['./parceiro.component.scss']
})
export class ParceiroComponent implements OnInit {

  @ViewChild('Grid', { static: false }) grid

  colDefs: ColDef[] = [];
  listaParceiros: Parceiro[] = [];
  objSelecionado: Parceiro = new Parceiro();
  objSelecionadoId: string = null;
  objetosSelecionados: Parceiro[] = [];

  constructor(private router: Router,
    private parceirosService: ParceirosService,
    private utilsService: UtilsService,
    private toasty: CustomToastyService,
  ) { }

  ngOnInit() {
    this.configura();
    this.pegarParceiros();
  }

  configura() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true
      },
      { field: 'Contato', headerName: 'Nome do Contato', flex: 1 },
      { field: 'Telefone', headerName: 'Telefone do Contato', flex: 1 },
      { field: 'Documento', headerName: 'Documento', flex: 1 },
      { field: 'Cidade', headerName: 'Cidade', flex: 1 },
      { field: 'UF', headerName: 'Estado', flex: 1 },
      { field: 'Endereco', headerName: 'Endereço', flex: 1 },
      { field: 'CriadoEm', headerName: 'Cadastrado em', flex: 1, cellRenderer: (dado) => this.utilsService.formatarData(dado.value) },
      { field: 'AreaTotal', headerName: 'Área Total', flex: 1 },
    ]

  }

  pegarParceiros() {
    this.parceirosService.Listar().subscribe(
      res => this.listaParceiros = res,
      error => this.utilsService.getErro(error)
    )
  }

  novo() {
    this.router.navigate(['/cadastros/parceiros/inserir'])
  }

  excluir() {
    if (this.objetosSelecionados.length <= 0) {
      this.utilsService.handleWarning("Selecione um parceiro para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utilsService.handleWarning("Selecione apenas um parceiro para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar ' + this.objetosSelecionados[0].Contato + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.parceirosService.Excluir(this.objSelecionadoId).subscribe(
          res => {
            this.toasty.show('Feito!', 'Parceiro excluído!', 'success');
            this.pegarParceiros();
          },
          err => {
            this.utilsService.getErro(err);
          });
      }
    });
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/parceiros/' + event.data.Id]);
  }
}
