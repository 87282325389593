import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AtividadeComponent } from './components/cadastros/atividade/atividade.component';
import { CultivarComponent } from './components/cadastros/cultivar/cultivar.component';
import { EtapaComponent } from './components/cadastros/etapa/etapa.component';
import { FazendaComponent } from './components/cadastros/fazenda/fazenda.component';
import { AreaImportComponent } from './components/cadastros/area-import/area-import.component';
import { GrupoOperacaoComponent } from './components/cadastros/grupo-operacao/grupo-operacao.component';
import { TipoAplicacaoComponent } from './components/cadastros/tipo-aplicacao/tipo-aplicacao.component';
import { TipoImprodutividadeComponent } from './components/cadastros/tipo-improdutividade/tipo-improdutividade.component';
import { UnidadeconsumidoraComponent } from './components/cadastros/unidadeconsumidora/unidadeconsumidora.component';
import { UsuarioComponent } from './components/cadastros/usuario/usuario.component';
import { ChamadoComponent } from './components/chamado/chamado.component';
import { DetalheChamadoComponent } from './components/chamado/detalhe-chamado/detalhe-chamado.component';
import { AlterarSenhaFornecedorComponent } from './components/cotacao/alterar-senha-fornecedor/alterar-senha-fornecedor.component';
import { CotacaoComponent } from './components/cotacao/cotacao/cotacao.component';
import { EnvioPrecosComponent } from './components/cotacao/envio-precos/envio-precos.component';
import { LoginFornecedorComponent } from './components/cotacao/login-fornecedor/login-fornecedor.component';
import { OrdemCompraComponent } from './components/cotacao/ordem-compra/ordem-compra.component';
import { PainelFornecedorComponent } from './components/cotacao/painel-fornecedor/painel-fornecedor.component';
import { RecuperarSenhaFornecedorComponent } from './components/cotacao/recuperar-senha-fornecedor/recuperar-senha-fornecedor.component';
import { ResumoCotacaoComponent } from './components/cotacao/resumo-cotacao/resumo-cotacao.component';
import { EstoqueInsumoComponent } from './components/estoque/estoque-insumo/estoque-insumo.component';
import { GrupoProdutoComponent } from './components/estoque/grupo-produto/grupo-produto.component';
import { LocalEstoqueComponent } from './components/estoque/local-estoque/local-estoque.component';
import { ProdutoComponent } from './components/estoque/produto/produto.component';
import { AnoAgricolaComponent } from './components/gerenciador/ano-agricola/ano-agricola.component';
import { AnoSafraComponent } from './components/gerenciador/ano-safra/ano-safra.component';
import { CulturaComponent } from './components/gerenciador/cultura/cultura.component';
import { EmpresaComponent } from './components/gerenciador/empresa/empresa.component';
import { ParametrosComprasComponent } from './components/gerenciador/parametros-compras/parametros-compras.component';
import { ParametrosGeraisComponent } from './components/gerenciador/parametros-gerais/parametros-gerais.component';
import { TipoBicoComponent } from './components/gerenciador/tipo-bico/tipo-bico.component';
import { TipoLancamentoComponent } from './components/gerenciador/tipo-lancamento/tipo-lancamento.component';
import { LoginComponent } from './components/login/login.component';
import { MapaTelemetriaComponent } from './components/mapa-telemetria/mapa-telemetria.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { AgendaAtividadeComponent } from './components/ordem-servico/agenda-atividade/agenda-atividade.component';
import { DetalhamentoComponent } from './components/ordem-servico/detalhamento/detalhamento.component';
import { EquipamentoComponent } from './components/ordem-servico/equipamento/equipamento.component';
import { EscalaTrabalhoComponent } from './components/ordem-servico/escala-trabalho/escala-trabalho.component';
import { FuncionarioComponent } from './components/ordem-servico/funcionario/funcionario.component';
import { GrupoEquipamentoComponent } from './components/ordem-servico/grupo-equipamento/grupo-equipamento.component';
import { PainelComponent } from './components/painel/painel.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { PlanoMestreProducaoDetalheComponent } from './components/planejamento/plano-mestre-producao/detalhe/detalhe.component';
import { PlanoMestreProducaoComponent } from './components/planejamento/plano-mestre-producao/plano-mestre-producao.component';
import { SafraComponent } from './components/planejamento/safra/safra.component';
import { RelatoriosComponent } from './components/relatorios/relatorios-componente/relatorios.component';
import { TarefasComponent } from './components/tarefas/tarefas.component';
import { TesteLeafletComponent } from './components/teste-leaflet/teste-leaflet.component';
import { AdminGuard } from './guards/admin.guard';
import { EmpresaGuard } from './guards/empresa.guard';
import { LoginGuard } from './guards/login.guard';
import { SuperGuard } from './guards/super.guard';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { ParceiroComponent } from './components/cadastros/parceiro/parceiro/parceiro.component';
import { ParceiroFormComponent } from './components/cadastros/parceiro/parceiro-form/parceiro-form.component';
import { RegionaisComponent } from './components/cadastros/regionais/regionais/regionais.component';
import { RegionaisFormComponent } from './components/cadastros/regionais/regionais-form/regionais-form.component';
import { RelatoriosDiagnosesComponent } from './components/diagnose/relatorios-diagnoses/relatorios-diagnoses.component';
import { ProcessoPlanejamentoComponent } from './components/cadastros/processo-planejamento/processo-planejamento.component';
import { DocumentacaoComponent } from './components/documentacao/documentacao.component';
import { PlanejamentoProducaoComponent } from './components/planejamento/planejamento-producao/planejamento-producao.component';
import { ManutencoesComponent } from './components/manutencoes/manutencoes.component';
import { AbastecimentosComponent } from './components/ordem-servico/equipamento/abastecimentos/abastecimentos.component';
import { ApontamentoFrotaComponent } from './components/ordem-servico/equipamento/apontamento-frota/apontamento-frota.component';
import { CustoEquipamentoComponent } from './components/ordem-servico/equipamento/custo-equipamento/custo-equipamento.component';
import { ManutencaoComponent } from './components/ordem-servico/equipamento/manutencao/manutencao.component';
import { OficinaComponent } from './components/cadastros/oficina/oficina.component';
import { ManutencaoOsComponent } from './components/cadastros/manutencao-os/manutencao-os.component';
import { FormsManutencaoOsComponent } from './components/cadastros/manutencao-os/forms-manutencao-os/forms-manutencao-os.component';
import { MovimentoEstoqueComponent } from './components/cadastros/movimento-estoque/movimento-estoque.component';
import { OrdemServicoFormsComponent } from './components/ordem-servico/ordem-servico/componentes/ordem-servico-forms/ordem-servico-forms.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'painelfornecedor',
        component: PainelFornecedorComponent,
        data: { title: "Painel Fornecedor" },
      },
      {
        path: '',
        redirectTo: 'mapa',
        pathMatch: 'full',
        canActivate: [LoginGuard]
      },
      {
        path: 'painel',
        component: PainelComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'perfil',
        component: PerfilComponent,
        canActivate: [LoginGuard],
        children: [
        ]
      },
      {
        path: 'chamado',
        component: ChamadoComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'chamado/:id',
        component: DetalheChamadoComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'suporte',
        component: DocumentacaoComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'suporte/:filtro',
        component: DocumentacaoComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'mapa',
        component: MapaComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'telemetria',
        component: MapaTelemetriaComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'teste-leaflet',
        component: TesteLeafletComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'diagnose',
        component: RelatoriosDiagnosesComponent
      },
      {
        path: 'gerenciador',
        canActivate: [SuperGuard],
        children: [
          {
            path: 'empresa',
            component: EmpresaComponent,
            data: { title: "Empresas" },
          },
          {
            path: 'empresa/:id',
            component: EmpresaComponent,
            data: { title: "Empresas" },
          },
          {
            path: 'empresa/inserir',
            component: EmpresaComponent,
            data: { title: "Empresas" },
          },

          {
            path: 'cultura',
            component: CulturaComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Culturas" },
          },
          {
            path: 'cultura/:id',
            component: CulturaComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Culturas" },
          },
          {
            path: 'cultura/inserir',
            component: CulturaComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Culturas" },
          },

          {
            path: 'tipo-lancamento',
            component: TipoLancamentoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Tipos de Lançamento" },
          },
          {
            path: 'tipo-lancamento/:id',
            canActivate: [EmpresaGuard],
            component: TipoLancamentoComponent,
            data: { title: "Tipos de Lançamento" },
          },
          {
            path: 'tipo-lancamento/inserir',
            component: TipoLancamentoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Tipos de Lançamento" },
          },
          {
            path: 'tipo-bico',
            component: TipoBicoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Tipos de Bicos" },
          },
          {
            path: 'tipo-bico/:id',
            component: TipoBicoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Tipos de Bicos" },
          },
          {
            path: 'tipo-bico/inserir',
            component: TipoBicoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Tipos de Bicos" },
          },
          {
            path: 'ano-agricola',
            component: AnoAgricolaComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Ano Agrícola" },
          },
          {
            path: 'ano-agricola/:id',
            component: AnoAgricolaComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Ano Agrícola" },
          },
          {
            path: 'ano-agricola/inserir',
            component: AnoAgricolaComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Ano Agrícola" },
          }
        ]
      },
      {
        path: 'cadastros',
        canActivate: [LoginGuard, EmpresaGuard],
        children: [
          {
            path: 'parametros/empresa',
            component: EmpresaComponent,
            data: { title: "Parâmetros Empresa" },
            canActivate: [AdminGuard]
          },
          {
            path: 'parametros/compras',
            component: ParametrosComprasComponent,
            data: { title: "Parâmetros Compras" },
            canActivate: [AdminGuard]
          },
          {
            path: 'parametros/gerais',
            component: ParametrosGeraisComponent,
            data: { title: "Parâmetros Gerais" },
            canActivate: [AdminGuard]
          },
          {
            path: 'usuario',
            component: UsuarioComponent,
            data: { title: "Usuários" }
          },
          {
            path: 'usuario/:id',
            component: UsuarioComponent,
            data: { title: "Usuários" },
          },
          {
            path: 'usuario/inserir',
            component: UsuarioComponent,
            data: { title: "Usuários" },
          },
          {
            path: 'variedade',
            component: CultivarComponent,
            data: { title: "Variedades" },
          },
          {
            path: 'variedade/:id',
            component: CultivarComponent,
            data: { title: "Variedades" },
          },
          {
            path: 'variedade/inserir',
            component: CultivarComponent,
            data: { title: "Variedades" },
          },
          {
            path: 'fazenda',
            component: FazendaComponent,
            data: { title: "Fazendas" },
          },
          {
            path: 'fazenda/:id',
            component: FazendaComponent,
            data: { title: "Fazendas" },
          },
          {
            path: 'fazenda/inserir',
            component: FazendaComponent,
            data: { title: "Fazendas" },
          },
          {
            path: 'area-import/:id',
            component: AreaImportComponent,
            data: { title: "Importação KML" },
          },
          {
            path: 'unidade-consumidora',
            component: UnidadeconsumidoraComponent,
            data: { title: "Unidades Consuidoras" },
          },
          {
            path: 'unidade-consumidora/:id',
            component: UnidadeconsumidoraComponent,
            data: { title: "Unidades Consuidoras" },
          },
          {
            path: 'unidade-consumidora/inserir',
            component: UnidadeconsumidoraComponent,
            data: { title: "Unidades Consuidoras" },
          },

          {
            path: 'grupo-operacao',
            component: GrupoOperacaoComponent,
            data: { title: "Grupos de Operação" },
          },
          {
            path: 'grupo-operacao/:id',
            component: GrupoOperacaoComponent,
            data: { title: "Grupos de Operação" },
          },
          {
            path: 'grupo-operacao/inserir',
            component: GrupoOperacaoComponent,
            data: { title: "Grupos de Operação" },
          },
          {
            path: 'grupo-operacao/expandir/:idExpandir',
            component: GrupoOperacaoComponent,
            data: { title: "Grupos de Operação" },
          },

          {
            path: 'atividade',
            component: AtividadeComponent,
            data: { title: "Atividades" },
          },
          {
            path: 'atividade/:atividadeId',
            component: AtividadeComponent,
            data: { title: "Atividades" },
          },
          {
            path: 'atividade/inserir/:grupoId',
            component: AtividadeComponent,
            data: { title: "Atividades" },
          },

          {
            path: 'tipo-aplicacao',
            component: TipoAplicacaoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Tipos de Aplicações" },
          },
          {
            path: 'tipo-aplicacao/:id',
            component: TipoAplicacaoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Tipos de Aplicações" },
          },
          {
            path: 'tipo-aplicacao/inserir',
            component: TipoAplicacaoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Tipos de Aplicações" },
          },

          {
            path: 'etapa',
            component: EtapaComponent,
            data: { title: "Alvos" },
          },
          {
            path: 'etapa/:id',
            component: EtapaComponent,
            data: { title: "Alvos" },
          },
          {
            path: 'etapa/inserir',
            component: EtapaComponent,
            data: { title: "Alvos" },
          },

          {
            path: 'tipo-improdutividade',
            component: TipoImprodutividadeComponent,
            data: { title: "Tipos de Improdutividade" },
          },
          {
            path: 'tipo-improdutividade/:id',
            component: TipoImprodutividadeComponent,
            data: { title: "Tipos de Improdutividade" },
          },
          {
            path: 'tipo-improdutividade/inserir',
            component: TipoImprodutividadeComponent,
            data: { title: "Tipos de Improdutividade" },
          },
          {
            path: 'funcionario',
            component: FuncionarioComponent,
            data: { title: "Funcionários" },
          },
          {
            path: 'funcionario/:id',
            component: FuncionarioComponent,
            data: { title: "Funcionários" },
          },
          {
            path: 'funcionario/inserir',
            component: FuncionarioComponent,
            data: { title: "Funcionários" },
          },

          {
            path: 'escala-trabalho',
            component: EscalaTrabalhoComponent,
            data: { title: "Escalas de Trabalho" },
          },
          {
            path: 'escala-trabalho/:id',
            component: EscalaTrabalhoComponent,
            data: { title: "Grupos de Equipamento" },
          },
          {
            path: 'escala-trabalho/inserir',
            component: EscalaTrabalhoComponent,
            data: { title: "Grupos de Equipamento" },
          },
          {
            path: 'equipamento',
            component: EquipamentoComponent,
            data: { title: "Equipamentos" },
          },
          {
            path: 'equipamento/:id',
            component: EquipamentoComponent,
            data: { title: "Equipamentos" },
          },
          {
            path: 'processo-planejamento',
            component: ProcessoPlanejamentoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Processos de Planejamento" },
          },
          {
            path: 'processo-planejamento/:id',
            component: ProcessoPlanejamentoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Processos de Planejamento" },
          },
          {
            path: 'processo-planejamento/inserir',
            component: ProcessoPlanejamentoComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Processos de Planejamento" },
          },
          {
            path: 'grupo-equipamento',
            component: GrupoEquipamentoComponent,
            data: { title: "Grupos de Equipamento" },
          },
          {
            path: 'grupo-equipamento/:id',
            component: GrupoEquipamentoComponent,
            data: { title: "Grupos de Equipamento" },
          },
          {
            path: 'grupo-equipamento/inserir',
            component: GrupoEquipamentoComponent,
            data: { title: "Grupos de Equipamento" },
          },
          {
            path: 'parceiros',
            children: [
              {
                path: '',
                component: ParceiroComponent,
                data: { title: 'Parceiros' }
              },
              {
                path: 'inserir',
                component: ParceiroFormComponent,
                data: { title: 'Parceiros' }
              },
              {
                path: ':id',
                component: ParceiroFormComponent,
                data: { title: 'Parceiros' }
              }
            ]
          },
          {
            path: 'regionais',
            children: [
              {
                path: '',
                component: RegionaisComponent,
                data: { title: 'Regionais' }
              },
              {
                path: 'inserir',
                component: RegionaisFormComponent,
                data: { title: 'Regionais' }
              },
              {
                path: ':id',
                component: RegionaisFormComponent,
                data: { title: 'Regionais' }
              }
            ]
          },
        ]
      },
      {
        path: 'manutencao',
        canActivate: [LoginGuard, EmpresaGuard],
        children: [
          {
            path: 'ordem-servico',
            component: ManutencaoOsComponent,
            data: { title: "Manutenção Ordem Serviço" },
          },
          {
            path: 'ordem-servico/inserir',
            component: FormsManutencaoOsComponent,
            data: { title: "Manutenção Ordem Serviço" },
          },
          {
            path: 'ordem-servico/:id',
            component: FormsManutencaoOsComponent,
            data: { title: "Manutenção Ordem Serviço" },
          }
        ]
      },
      {
        path: 'equipamento/inserir',
        component: EquipamentoComponent,
        data: { title: "Equipamentos" },
      },
      {
        path: 'estoques',
        canActivate: [LoginGuard, EmpresaGuard],
        children: [
          {
            path: 'grupo-produto',
            component: GrupoProdutoComponent,
            data: { title: "Grupos de Produtos" },
          },
          {
            path: 'grupo-produto/:id',
            component: GrupoProdutoComponent,
            data: { title: "Grupos de Produtos" },
          },
          {
            path: 'grupo-produto/inserir',
            component: GrupoProdutoComponent,
            data: { title: "Grupos de Produtos" },
          },
          {
            path: 'estoque-insumo',
            component: EstoqueInsumoComponent,
            data: { title: "Estoques de Insumos" },
          },
          {
            path: 'produto',
            component: ProdutoComponent,
            data: { title: "Produtos" },
          },
          {
            path: 'produto/:id',
            component: ProdutoComponent,
            data: { title: "Produtos" },
          },
          {
            path: 'produto/inserir',
            component: ProdutoComponent,
            data: { title: "Produtos" },
          },
          {
            path: 'local-estoque',
            component: LocalEstoqueComponent,
            data: { title: "Locais de Estoques" },
          },
          {
            path: 'local-estoque/:id',
            component: LocalEstoqueComponent,
            data: { title: "Locais de Estoques" },
          },
          {
            path: 'local-estoque/inserir',
            component: LocalEstoqueComponent,
            data: { title: "Locais de Estoques" },
          },
        ]
      },
      {
        path: 'planejamento',
        canActivate: [LoginGuard, EmpresaGuard],
        children: [
          {
            path: 'planejamento-producao',
            component: PlanejamentoProducaoComponent,
            data: { title: 'Planejamento de Produção' }
          },

          {
            path: 'ano-safra',
            component: AnoSafraComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Anos Safra" },
          },
          {
            path: 'ano-safra/:id',
            component: AnoSafraComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Anos Safra" },
          },
          {
            path: 'ano-safra/inserir',
            component: AnoSafraComponent,
            canActivate: [EmpresaGuard],
            data: { title: "Anos Safra" },
          },
          {
            path: 'safra',
            component: SafraComponent,
            data: { title: "Safras" },
          },
          {
            path: 'safra/:id',
            component: SafraComponent,
            data: { title: "Safras" },
          },
          {
            path: 'safra/inserir',
            component: SafraComponent,
            data: { title: "Safras" },
          },
          {
            path: 'safra/informar-plantio',
            component: SafraComponent,
            data: { title: "Safras" },
          },
          {
            path: 'plano-mestre-producao',
            component: PlanoMestreProducaoComponent,
            data: { title: "Plano Mestre" },
          },
          {
            path: 'plano-mestre-producao/:id',
            component: PlanoMestreProducaoDetalheComponent,
            data: { title: "Plano Mestre" },
          },
          {
            path: 'plano-mestre-producao/inserir/:cultivarId',
            component: PlanoMestreProducaoDetalheComponent,
            data: { title: "Plano Mestre" },
          },

          {
            path: 'safra/planejamento',
            component: PlanoMestreProducaoComponent,
            data: { title: "Planejamento" },
          },
          {
            path: 'safra/planejamento/:id',
            component: PlanoMestreProducaoDetalheComponent,
            data: { title: "Planejamento" },
          },
          {
            path: 'safra/planejamento/inserir/:cultivarId',
            component: PlanoMestreProducaoDetalheComponent,
            data: { title: "Planejamento" },
          },

          {
            path: 'tarefas',
            children: [
              {
                path: '',
                redirectTo: 'lista-tarefas',
                pathMatch: 'full'
              },
              {
                path: 'lista-tarefas',
                component: TarefasComponent,
                data: { title: "Tarefas" },
              },
              {
                path: ':id',
                component: TarefasComponent,
                data: { title: "Tarefas" },
              },
              {
                path: 'inserir',
                component: TarefasComponent,
                data: { title: "Tarefas" },
              }
            ]
          },
        ]
      },
      {
        path: 'ordem-servico',
        canActivate: [LoginGuard, EmpresaGuard],
        children: [
          {
            path: 'ordem-servico',
            component: DetalhamentoComponent,
            data: { title: "Ordens de Serviço" },
          },
          {
            path: 'ordem-servico/:id',
            component: OrdemServicoFormsComponent,
            data: { title: "Ordens de Serviço" },
          },
          {
            path: 'ordem-servico/inserir',
            component: OrdemServicoFormsComponent,
            data: { title: "Ordens de Serviço" },
          },
          {
            path: 'detalhamento',
            component: DetalhamentoComponent,
            data: { title: "Detalhamento de Ordens de Serviço" },
          },
          {
            path: 'detalhamento/:status',
            component: DetalhamentoComponent,
            data: { title: "Detalhamento de Ordens de Serviço" },
          },
          {
            path: 'detalhamento/:status/:periodo',
            component: DetalhamentoComponent,
            data: { title: "Detalhamento de Ordens de Serviço" },
          },
          {
            path: 'detalhamento/safra/:safraId',
            component: DetalhamentoComponent,
            data: { title: "Detalhamento de Ordens de Serviço" },
          },
          {
            path: 'agenda-atividade',
            component: AgendaAtividadeComponent,
            data: { title: "Agenda de Atividades" },
          },
          {
            path: 'agenda-atividade/inserir',
            component: AgendaAtividadeComponent,
            data: { title: "Agenda de Atividades" },
          },
          {
            path: 'agenda-atividade/:id',
            component: AgendaAtividadeComponent,
            data: { title: "Agenda de Atividades" },
          }
        ]
      },
      {
        path: 'relatorios',
        component: RelatoriosComponent,
        canActivate: [LoginGuard, EmpresaGuard]
      },

      {
        path: 'cotacao',
        canActivate: [LoginGuard, EmpresaGuard],
        children: [
          {
            path: '',
            component: CotacaoComponent,
            data: { title: "Cotações" },
          },
          {
            path: 'resumo/:id',
            component: ResumoCotacaoComponent,
            data: { title: "Resumo da Cotacao" },
          },
          {
            path: 'cadastro',
            component: CotacaoComponent,
            data: { title: "Cadastro de Cotações" },
          },
          {
            path: 'cadastro/:idCotacao',
            component: CotacaoComponent,
            data: { title: "Cadastro de Cotações" },
          },
          {
            path: 'envio',
            component: EnvioPrecosComponent,
            data: { title: "Tomada de preços" },
          },
          {
            path: 'OrdemCompra/:id',
            component: OrdemCompraComponent,
            data: { title: "Ordem de Compras" },
          }
        ]
      },
      {
        path: 'manutencao',
        canActivate: [LoginGuard, EmpresaGuard],
        children: [
          {
            path: '',
            component: ManutencoesComponent,
            data: { title: "Módulos Configurações" },
          },
          {
            path: 'abastecimentos',
            component: AbastecimentosComponent,
            data: { title: "Abastecimentos" },
          },
          {
            path: 'apontamento-frota',
            component: ApontamentoFrotaComponent,
            data: { title: "Apontamento Frota" },
          },
          {
            path: 'custos',
            component: CustoEquipamentoComponent,
            data: { title: "Custos" },
          },
          {
            path: 'manutencao',
            component: ManutencaoComponent,
            data: { title: "Manutenção" },
          },
          {
            path: 'oficina',
            component: OficinaComponent,
            data: { title: "Oficina" },
          },
          {
            path: 'oficina/:id',
            component: OficinaComponent,
            data: { title: "Oficina" },
          },
          {
            path: 'oficina/inserir',
            component: OficinaComponent,
            data: { title: "Oficina" },
          },
        ]
      },
      {
        path: 'movimento-estoque',
        canActivate: [LoginGuard, EmpresaGuard],
        children: [
          {
            path: 'sync',
            component: MovimentoEstoqueComponent,
            data: { title: "Movimento Estoque" },
          },
        ]
      }
    ],
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'loginfornecedor',
    component: LoginFornecedorComponent,
    data: { title: "Login Fornecedor" },
  },
  {
    path: 'loginfornecedor/:token',
    component: LoginFornecedorComponent,
    data: { title: "Login Fornecedor" },
  },
  {
    path: 'alterarsenhafornecedor',
    component: AlterarSenhaFornecedorComponent,
    data: { title: "Alterar senha do Fornecedor" },
  },
  {
    path: 'recuperarsenhafornecedor',
    component: RecuperarSenhaFornecedorComponent,
    data: { title: "Recuperar senha do Fornecedor" },
  },
  {
    path: '',
    component: AuthComponent,
    children: [
    ]
  }
]
@NgModule({
      imports: [RouterModule.forRoot(routes, {
        useHash: true,
        onSameUrlNavigation: "reload"
      })],
      exports: [RouterModule]
    })
  export class AppRoutingModule { }
