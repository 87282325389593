import { ImprodutividadeEquipamento } from './../classes/ImprodutividadeEquipamento';
import { ItemApontamento } from './../classes/ItemApontamento';
import { EquipamentoApontamento } from './../classes/EquipamentoApontamento';
import { Apontamento } from './../classes/Apontamento';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApontamentoService {
  existeApontamentos(ordemServicoId: number) {
    return this.http.get(`${environment.apiURL}/apontamento/ExisteApontamentos/${ordemServicoId}`)
  }

  constructor(private http: HttpClient) { }

  getApontamentos(ordemServicoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/apontamento/listar/${ordemServicoId}`)
  }

  getApontamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/apontamento/${id}`)
  }

  postApontamento(apontamento: Apontamento): Observable<any>{
    return this.http.post(`${environment.apiURL}/apontamento`, apontamento);
  }

  putApontamento(id: number, apontamento: Apontamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/apontamento/${id}`, apontamento);
  }

  deleteApontamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/apontamento/${id}`);
  }

  SalvarCompleto(apontamento: Apontamento, equipamentoApontamento: EquipamentoApontamento, itensApontamento: ItemApontamento[], improdutividadesEquipamento: ImprodutividadeEquipamento[]): Observable<any>{
    apontamento.EquipamentoApontamento = equipamentoApontamento;
    apontamento.ItensApontamento = itensApontamento;
    apontamento.ImprodutividadesEquipamento = improdutividadesEquipamento;
    return this.http.post(`${environment.apiURL}/apontamento/salvarCompleto`, apontamento);
  }
}
