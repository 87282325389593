import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ItemSistema } from 'src/app/classes/Sistema';

@Component({
  selector: 'app-item-sistema',
  templateUrl: './item-sistema.component.html',
  styleUrls: ['./item-sistema.component.scss']
})
export class ItemSistemaComponent implements OnInit {

  itemSistema: ItemSistema = new ItemSistema();
  @Input() listaItemSistema: ItemSistema[] = [];
  @Output() itemAdicionado = new EventEmitter<ItemSistema>();
  @Output() excluirItem = new EventEmitter<number>();

  constructor(
    private translate: TranslateService,
  ) {}

  ngOnInit() { 
  }

  statusBadge(status: boolean) {
    if (status)
      return this.translate.instant('ATIVO');
    else
      return this.translate.instant('INATIVO');
  }

  save() {
    if(!this.itemSistema.Descricao) return;

    this.itemAdicionado.emit(this.itemSistema);
    this.itemSistema = new ItemSistema();
  }

  excluir(id: number) {
    this.excluirItem.emit(id);
  }

}
