import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Atividade } from "src/app/classes/Atividade";
import { Empresa } from "src/app/classes/Empresa";
import { GrupoProduto } from "src/app/classes/GrupoProduto";
import { EmpresaService } from "src/app/services/empresa.service";
import { GrupoProdutoService } from "src/app/services/grupo-produto.service";
import { StorageService } from "src/app/services/storage.service";
import { UiModalComponent } from "src/app/theme/shared/components/modal/ui-modal/ui-modal.component";
import { CustomToastyService } from "src/app/theme/shared/components/toasty/custom-toasty.service";
import { UtilsService } from "src/app/utils/utils.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-parametros-gerais",
  templateUrl: "./parametros-gerais.component.html",
  styleUrls: ["./parametros-gerais.component.scss"],
})
export class ParametrosGeraisComponent implements OnInit {
  @ViewChild('ModalAtivadePadrao', { static: false })
  ModalAtivadePadrao: UiModalComponent;

  empresa: Empresa;
  id: number = 0;
  carregando: boolean = false;
  atividadeId: any = "";
  atividades: Atividade[]=[];
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  isParametros: boolean = false;
  gruposProduto: GrupoProduto[] = [];

  exemploNatureza: string = '12345678';
  exemploCentroResultados: string = '12345678';
  exemploProjeto: string = '12345678';
  exemploDecimaisDosagem= 9999;
  exemploDecimaisQuantidade = 9999;
  exemploDecimaisValor = 9999;
  labelNatureza = false;
  labelProjeto = false;
  labelCentroResultado = false;



  constructor(private empresaService: EmpresaService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private toasty: CustomToastyService,
    private storageService: StorageService,
    private grupoProdutoService: GrupoProdutoService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.empresa = this.storageService.getEmpresa();

    if (this.router.url.includes("parametros") &&
      this.storageService.getEmpresa() &&
      this.storageService.getEmpresa().Id > 1) {
      this.id = this.storageService.getEmpresa().Id;
      this.isParametros = true;
    }

    if (this.id == 1) {
      Swal.fire('Erro!', 'Este registro não pode ser alterado', 'error');
      this.cancelar();
    }
    if (this.id > 0)
      this.selecionarEmpresa();
  }

  selecionarEmpresa() {
    this.carregando = true;
    this.empresaService.getEmpresa(this.id).subscribe(res => {
      this.empresa = new Empresa();
      this.empresa = res;
      this.carregarParametros();
      this.grupoProdutoService.getGruposProduto().subscribe(gru => {
        this.gruposProduto = gru;
      })
      this.carregando = false;
    },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      })
  }
  carregarParametros() {
    this.empresa.CasasDecimaisDose = this.empresa.CasasDecimaisDose ? this.empresa.CasasDecimaisDose  : 3;
    this.empresa.DecimaisQuantidade = this.empresa.DecimaisQuantidade ? this.empresa.DecimaisQuantidade : 2;
    this.empresa.DecimaisValor = this.empresa.DecimaisValor ? this.empresa.DecimaisValor : 2;
  }
  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    if(this.labelNatureza || this.labelCentroResultado || this.labelProjeto)
      return;

    this.carregando = true;
    if (this.empresa.Id > 0)
      this.empresaService.patchEmpresa(
        {
          Id: this.empresa.Id,
          MascaraNaturezaReceitaDespesa: this.empresa.MascaraNaturezaReceitaDespesa,
          MascaraCentroResultado: this.empresa.MascaraCentroResultado,
          MascaraProjeto: this.empresa.MascaraProjeto,
          DecimaisQuantidade: this.empresa.DecimaisQuantidade,
          DecimaisValor: this.empresa.DecimaisValor
        }).subscribe(
        res => {
          this.toasty.show('Feito!', ("Parâmetros Gerais Atualizados"), 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  cancelar() {
    this.router.navigate(['/gerenciador/empresa']);
  }

  clean(str: any): string
  {
    const res = str.replace(/[^0\W]+/g, "");
    return res;
  }

  changeLabel(value: string, labelToUpdate: string) {
    const matchResult = value.match(/0/g);
    this[labelToUpdate] = matchResult && matchResult.length > 8;
  }

  onChangeNatureza(x: string) {
    this.changeLabel(x, 'labelNatureza');
  }

  onChangeProjeto(x: string) {
    this.changeLabel(x, 'labelProjeto');
  }

  onChangeCentroResultado(x: string) {
    this.changeLabel(x, 'labelCentroResultado');
  }
}
