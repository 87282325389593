import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LancamentoFinanceiro } from '../classes/LancamentoFinanceiro';

@Injectable({
  providedIn: 'root'
})
export class LancamentoFinanceiroService {

  constructor(private http: HttpClient) { }

  getList(): Observable<any>{
    return this.http.get(`${environment.apiURL}/LancamentoFinanceiro`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/LancamentoFinanceiro/${id}`)
  }

  post(obj: LancamentoFinanceiro): Observable<any>{
    return this.http.post(`${environment.apiURL}/LancamentoFinanceiro`, obj);
  }

  put(id: number, obj: LancamentoFinanceiro): Observable<any>{
    return this.http.put(`${environment.apiURL}/LancamentoFinanceiro/${id}`, obj);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/LancamentoFinanceiro/${id}`);
  }
}
