import { Component, Input, OnInit } from '@angular/core';

import { LancamentoFinanceiro } from 'src/app/classes/LancamentoFinanceiro';
import { Movimento } from 'src/app/classes/Movimento';
import { Transacao } from 'src/app/classes/Transacao';
import { LancamentoFinanceiroService } from 'src/app/services/lancamento-financeiro.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-movimento-estoque-forms',
  templateUrl: './movimento-estoque-forms.component.html',
  styleUrls: ['./movimento-estoque-forms.component.scss']
})
export class MovimentoEstoqueFormsComponent implements OnInit {
  @Input() movimento: Movimento;
  @Input() transacao: Transacao;
  showFormFinanceiro = false;
  lancamentoFinanceiro = new LancamentoFinanceiro();
  lancamentoFinanceiroSelecionado: LancamentoFinanceiro;
  lancamentosFinanceiroSelecionados: LancamentoFinanceiro[] = [];
  showForm = false;
  constructor(private lancamentoFinanceiroService: LancamentoFinanceiroService, 
    private utils: UtilsService) { }

  ngOnInit() {
    console.log(this.movimento);
    console.log(this.transacao)
  }
  novo(){
    this.showForm = true;
    this.lancamentoFinanceiroSelecionado = new LancamentoFinanceiro();
  }
}
