import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ItemChecklist } from 'src/app/classes/ItemChecklist';
import { ItemChecklistService } from 'src/app/services/item-checklist.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-item-itemChecklist',
  templateUrl: './item-checklist.component.html',
  styleUrls: ['./item-checklist.component.scss']
})
export class ItemChecklistComponent implements OnInit {

  @ViewChild('CadastroItemModal', { static: false })
  CadastroItemModal: UiModalComponent;

  itemChecklist: ItemChecklist;
  listaItemChecklist: ItemChecklist[] = [];
  auxItemChecklists: ItemChecklist[] = [];
  @Input() checklistId: number = 0;

  id: number = 0;

  inserindo: boolean = false;
  editando: boolean = false;
  textoBusca: string = '';

  carregando: boolean = false;
  isSubmit: boolean;
  touched = false;

  //traduções
  FEITO: string = "Feito!";
  ERRO: string = "Erro!";
  ITEM_CHECKLIST_ALTERADO: string = "Item Checklist  alterado!";
  ITEM_CHECKLIST_CADASTRADO: string = "Item Checklist cadastrado!";
  CONFIRMAR_DELETE: string = "Se deletar, esta ação não poderá ser desfeita!";
  CANCELAR: string = "Cancelar";
  SIM: string = "Sim";
  ITEM_CHECKLIST_DELETADA: string = "Item Checklist deletada!";
  DELETAR_PARAM: string;

  constructor(
    private itemChecklistService: ItemChecklistService,
    private router: Router,
    private toasty: CustomToastyService,
    private translate: TranslateService
  ) {

    this.itemChecklist = new ItemChecklist();
  }

  ngOnInit() {
    this.listarItemChecklists();
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.FEITO = this.translate.instant("FEITO");
    this.ITEM_CHECKLIST_ALTERADO = this.translate.instant("ITEM_CHECKLIST_ALTERADO");
    this.ERRO = this.translate.instant("ERRO");
    this.ITEM_CHECKLIST_CADASTRADO = this.translate.instant("ITEM_CHECKLIST_CADASTRADO");

    this.carregando = true;
    this.itemChecklist.ChecklistId = parseInt(this.checklistId.toString());

    if (this.itemChecklist.Id > 0)
      this.itemChecklistService.Atualizar(this.itemChecklist.Id, this.itemChecklist).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ITEM_CHECKLIST_ALTERADO, 'success');
          this.carregando = false;
          this.CadastroItemModal.hide();
          this.listarItemChecklists();
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.itemChecklistService.Inserir(this.itemChecklist).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ITEM_CHECKLIST_CADASTRADO, 'success');
          this.carregando = false;
          this.CadastroItemModal.hide();
          this.listarItemChecklists();
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  selecionarItemChecklist() {
    this.ERRO = this.translate.instant("ERRO");

    this.carregando = true;
    this.itemChecklistService.ObterPorId(this.id).subscribe(res => {
      this.itemChecklist = new ItemChecklist();
      this.itemChecklist = res;
      this.carregando = false;
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarItemChecklists() {

    if (this.checklistId <= 0)
      return;
    this.ERRO = this.translate.instant("ERRO");

    console.log(this.checklistId)
    this.carregando = true;
    this.itemChecklistService.Listar(this.checklistId).subscribe(
      res => {
        this.listaItemChecklist = res;
        this.auxItemChecklists = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarItemChecklists();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  editar(item: ItemChecklist) {
    this.itemChecklist = item;
    this.CadastroItemModal.show();
  }



  excluir(itemChecklistId: number, nome: string) {
    this.CONFIRMAR_DELETE = this.translate.instant("CONFIRMAR_DELETE");
    this.CANCELAR = this.translate.instant("CANCELAR");
    this.SIM = this.translate.instant("SIM");
    this.FEITO = this.translate.instant("FEITO");
    this.ITEM_CHECKLIST_DELETADA = this.translate.instant("ITEM_CHECKLIST_DELETADA");
    this.DELETAR_PARAM = this.translate.instant("DELETAR_PARAM", {value: nome});

    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.CONFIRMAR_DELETE,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.itemChecklistService.Excluir(itemChecklistId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.ITEM_CHECKLIST_DELETADA, 'success');
            this.listarItemChecklists();
            this.carregando = false;
            this.CadastroItemModal.hide();
            this.router.navigate([`/cadastros/Checklist/${this.checklistId}`]);
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }

  filtrarItemChecklists() {
    this.listaItemChecklist = this.auxItemChecklists.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca)
    );
  }

  cancelar() {
    this.router.navigate([`/cadastros/Checklist/${this.checklistId}`]);
  }

  novoItem(checklistId: number) {
    this.itemChecklist = new ItemChecklist();
    this.checklistId = checklistId;
    this.inserindo = true;
    this.CadastroItemModal.show();
  }

}
