import { UsuarioService } from './../../../services/usuario.service';
import { Usuario } from './../../../classes/Usuario';
import { RelatorioAutomaticoService } from './../../../services/RelatorioAutomatico.Service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { RelatorioAutomatico } from 'src/app/classes/RelatorioAutomatico';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-historico-aplicacao-email',
  templateUrl: './historico-aplicacao-email.component.html',
  styleUrls: ['./historico-aplicacao-email.component.scss']
})
export class HistoricoAplicacaoEmailComponent implements OnInit {
  @Output() inserindoEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  RelatorioAutomaticos: RelatorioAutomatico[] = [];
  BkRelatorioAutomaticos: RelatorioAutomatico[] = [];
  AuxRelatorioAutomaticos: RelatorioAutomatico[] = [];
  RelatorioAutomatico: RelatorioAutomatico = new RelatorioAutomatico();
  Usuarios: Usuario[] = [];
  BkUsuarios: Usuario[] = [];
  AuxUsuarios: Usuario[] = [];
  carregando: boolean = false;
  inserindo: boolean = false;
  textoBusca: string = "";
  textoBuscaUser: string = "";
  id: number = 0;
  constructor(private storageService: StorageService,
    private toasty: CustomToastyService,
    private relatorioService: RelatorioAutomaticoService,
    private UsuarioService: UsuarioService) { }

  ngOnInit() {
    this.listar();
    this.UsuarioService.getUsuarios().subscribe(ret => {
      this.Usuarios = ret;
      this.BkUsuarios = ret;
      this.AuxUsuarios = ret;
    });
  }
  listar() {
    this.carregando = true;
    this.relatorioService.get().subscribe(ret => {
      this.RelatorioAutomaticos = ret;
      this.BkRelatorioAutomaticos = ret;
      this.AuxRelatorioAutomaticos = ret;
      this.carregando = false;
    });
  }
  editar(id: number) {
    this.carregando = true;
    this.inserindo = true;
    this.relatorioService.getId(id).subscribe(ret => {
      this.RelatorioAutomatico = ret;
      this.carregando = false;
      console.log(this.RelatorioAutomatico);
    });
  }

  excluir(id: number) {
    Swal.fire({
      title: 'Deletar?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.relatorioService.delete(id).subscribe(
          res => {
            this.toasty.show('Feito!', 'Relatório Excluído com sucesso!', 'success');
            this.carregando = false;
            this.listar();
          },
          err => {
            Swal.fire('Erro!', err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }
  novo() {
    this.RelatorioAutomatico = new RelatorioAutomatico();
    this.inserindo = true;
  }
  cancelar() {
    this.RelatorioAutomatico = new RelatorioAutomatico();
    this.inserindo = false;
  }
  salvar() {
    this.carregando = true;
    if (this.RelatorioAutomatico.Id > 0) {
      this.relatorioService.put(this.RelatorioAutomatico.Id, this.RelatorioAutomatico).subscribe(ret => {
        this.RelatorioAutomatico = ret;
        this.listar();
        this.carregando = false;
        Swal.fire('Atualizado!', "Item Atualizado com sucesso", 'success');
        this.inserindo = false;
      });
    }
    else {
      this.relatorioService.post(this.RelatorioAutomatico).subscribe(ret => {
        this.RelatorioAutomatico = ret;
        this.listar();
        this.carregando = false;
        Swal.fire('Inserido!', "Item Inserido com sucesso", 'success');
        this.inserindo = false;
      });
    }
  }
  filtrar() {
    if (this.textoBusca == "") {
      this.RelatorioAutomaticos = this.BkRelatorioAutomaticos;
    }
    else {
      this.RelatorioAutomaticos = this.AuxRelatorioAutomaticos.filter(x =>
        x.ListaUsuarios.filter(x => x.Usuario.Nome.toUpperCase().includes(this.textoBusca.toUpperCase())) ||
        x.ListaUsuarios.filter(x => x.Usuario.Email.toUpperCase().includes(this.textoBusca.toUpperCase())) ||
        x.ListaUsuarios.filter(x => x.Usuario.Login.toUpperCase().includes(this.textoBusca.toUpperCase())) ||
        x.ListaUsuarios.filter(x => x.Usuario.Telefone.toUpperCase().includes(this.textoBusca.toUpperCase())) ||
        x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
    }
  }
  fecharModal() {
    this.Modal.hide();
  }
  abreModal() {
    this.Modal.show();
  }
  excluirUsuario(id: number) {
    Swal.fire({
      title: 'Deletar esse Usuário?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        this.carregando = false;
        this.validarCheckBox(id);
      } else {
        this.carregando = true;
        this.relatorioService.removeUser(id).subscribe(
          res => {
            this.toasty.show('Feito!', 'Item Deletado!', 'success');
            this.carregando = false;
            this.RelatorioAutomatico.ListaUsuarios = res;
            console.log(res);
          },
          err => {
            Swal.fire('Erro!', err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }
  selecionarUsuario(id: number) {
    this.carregando = true;
    console.log(id);
    if(this.validarCheckBox(id))
    {
      let idExc:number =0;
      idExc = this.RelatorioAutomatico.ListaUsuarios.filter(x=>x.Usuario.Id == id)[0].Id;
      console.log(idExc);
      this.excluirUsuario(idExc);
    }
    else
    {
    this.relatorioService.addUser(this.RelatorioAutomatico.Id, id).subscribe(
      res => {
        this.carregando = false;
        this.RelatorioAutomatico.ListaUsuarios = res;
      },
      err => {
        Swal.fire('Erro!', err.message, 'error');
        this.carregando = false;
      });
    }
  }
  validarCheckBox(id: number): boolean  {
    let retorno: boolean = false;
    var result = this.RelatorioAutomatico.ListaUsuarios.filter(function (el) {
      return el.Usuario.Id == id;
    });
    if (result.length > 0) {
      retorno = true;
    }
    return retorno;
  }
  filtrarUsuario() {
    if (this.textoBuscaUser == "") {
      this.Usuarios = this.BkUsuarios;
    }
    else {
      this.Usuarios = this.AuxUsuarios.filter(x =>
        x.Nome.toUpperCase().includes(this.textoBuscaUser.toUpperCase()) ||
        x.Email.toUpperCase().includes(this.textoBuscaUser.toUpperCase()) ||
        x.Login.toUpperCase().includes(this.textoBuscaUser.toUpperCase()) ||
        x.Telefone.toUpperCase().includes(this.textoBuscaUser.toUpperCase()) ||
        x.Id.toString().includes(this.textoBuscaUser.toUpperCase())
      );
    }
  }
}
