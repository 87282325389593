import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuxEstoqueRM } from 'src/app/classes/AuxEstoqueRM';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { EnumTipoItemOS, ItemOrdemServico } from 'src/app/classes/ItemOrdemServico';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { EnumStatusOS, OrdemServico } from 'src/app/classes/OrdemServico';
import { Produto } from 'src/app/classes/Produto';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ItemOrdemServicoService } from 'src/app/services/item-ordem-servico.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { StorageService } from 'src/app/services/storage.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-items-ordem-servico',
  templateUrl: './items-ordem-servico.component.html',
  styleUrls: ['./items-ordem-servico.component.scss']
})
export class ItemsOrdemServicoComponent implements OnInit, OnChanges {

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  @Input() telaModal: boolean = false;
  @Input() isSubmit: boolean;
  @Input() inserindo: boolean = false;
  @Input() ordemServico: OrdemServico;
  @Input() tipo: EnumTipoItemOS;
  @Input() possuiERP: boolean = false;
  @Output() ChangeItensEvento = new EventEmitter<boolean>();

  itensOrdemServico: ItemOrdemServico[] = [];
  carregando: boolean = false;
  organizaPorSetor: boolean;
  produtos: Produto[];
  gruposProdutos: GrupoProduto[];
  valorTotalOs: number = 0;
  totalHa: number = 0;
  totalServicoHa: number = 0;
  precisaoDose: number = 2;
  AuxEstoqueRM: AuxEstoqueRM[] = [];
  locaisEstoque: LocalEstoque[] = [];

  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
    private produtoService: ProdutoService,
    private storageService: StorageService,
    private empresaService: EmpresaService,
    private ordensServicoService: OrdemServicoService,
    private itemOrdemServicoService: ItemOrdemServicoService,
    private localEstoqueService: LocalEstoqueService
  ) {
    this.organizaPorSetor = this.storageService.getEmpresa().UsaSetores;
  }
  verificarERP() {
    // verifica se empresa possui ERP
    this.possuiERP = false;
    const empresa = this.storageService.getEmpresa()
    if (empresa !== null && empresa !== undefined && empresa.ErpIntegrado !== enumErpIntegrado.Nenhum)
      this.possuiERP = true;
    else if (this.ordemServico !== null && this.ordemServico != undefined && this.ordemServico.EmpresaId > 0) {
      this.empresaService.possuiERP(this.ordemServico.EmpresaId).subscribe(data => {
        this.possuiERP = data;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.verificarERP();
    if (changes.ordemServico) {
      const previousId = changes.ordemServico.previousValue.Id ? changes.ordemServico.previousValue.Id : 0;
      const currentId = changes.ordemServico.currentValue.Id ? changes.ordemServico.currentValue.Id : 0;
      //if(previousId !== currentId) {
      this.itensOrdemServico = [];
      this.listarItens();
      //}
      const previousAtividade = changes.ordemServico.previousValue ? changes.ordemServico.previousValue.AtividadeId : null;
      const currentAtividade = changes.ordemServico.currentValue.AtividadeId;
      if (previousAtividade !== currentAtividade) {
        this.itensOrdemServico = [];
      }
      this.itensOrdemServico = this.itensOrdemServico.filter(c => !c.OrdemServicoId || c.OrdemServicoId == this.ordemServico.Id);
      this.itensOrdemServico.forEach((item, i) => {
        this.itensOrdemServico[i].AreaAplicacao = this.ordemServico.AreaPrevista;
        this.changeProduto(this.itensOrdemServico[i]);
      })
      if (this.itensOrdemServico.length == 0)
        this.listarItens();
    }
  }

  ngOnInit() {
    this.verificarERP();
    this.ordensServicoService.areaChangeEvento().subscribe(data => {
      this.itensOrdemServico.forEach(x => {
        x.AreaAplicacao = this.ordemServico.AreaPrevista;
        this.changeProduto(x);
      })
    });

    this.ordensServicoService.osSalvaEvento().subscribe(data => {
      this.ordemServico = data;
      this.save(this.ordemServico.Id);
    });

    this.ordensServicoService.changeProdutoEvento().subscribe(data => {
      if (this.itensOrdemServico)
        this.itensOrdemServico.forEach(x => {
          this.changeProduto(x)
        })
    });

    this.ordensServicoService.listagemItensEvento().subscribe(
      data => {
        this.listarItens();
      }
    )

    this.localEstoqueService.getLocaisEstoque().subscribe(ret => {
      this.locaisEstoque = ret;
    }, err => this.utils.getErro(err));

    if (!this.inserindo)
      this.listarItens();
  }

  listarItens() {
    if (!this.ordemServico.Id) return;
    this.carregando = true;
    this.itemOrdemServicoService.getItensOrdemServico(this.ordemServico.Id)
      .subscribe((ret: ItemOrdemServico[]) => {

        switch (this.tipo) {
          case EnumTipoItemOS.Insumo:
            this.itensOrdemServico = ret
              .filter(x => !x.TratamentoSementes && !x.AdubacaoLinha && !x.PulverizacaoSulco && !x.isServico)
              .sort((a, b) => a.Ordem - b.Ordem);
            break;
          case EnumTipoItemOS.Servico:
            this.itensOrdemServico = ret.filter(x => x.isServico).sort((a, b) => a.Ordem - b.Ordem);
            break;
          case EnumTipoItemOS.TratamentoSementes:
            this.itensOrdemServico = ret.filter(x => x.TratamentoSementes).sort((a, b) => a.Ordem - b.Ordem);
            break;
          case EnumTipoItemOS.AdubacaoLinha:
            this.itensOrdemServico = ret.filter(x => x.AdubacaoLinha).sort((a, b) => a.Ordem - b.Ordem);
            break;
          case EnumTipoItemOS.PulverizacaoSulco:
            this.itensOrdemServico = ret.filter(x => x.PulverizacaoSulco).sort((a, b) => a.Ordem - b.Ordem);
            break;
        }

        this.itensOrdemServico.forEach(x => {
          x.AreaAplicacao = this.ordemServico.AreaPrevista;
          this.changeProduto(x);
        })
        this.carregando = false;
      },
        err => this.utils.getErro(err));
  }

  changeServico(itemOS: ItemOrdemServico) {
    if (!itemOS.ProdutoId) return;
    if (!itemOS.AreaAplicacao) {
      this.utils.handleWarning("Defina a área de aplicação");
      return;
    }

    if (!itemOS.QtdPrevista)
      itemOS.QtdPrevista = 1;

    itemOS.ValorTotal = itemOS.QtdPrevista * itemOS.PrecoUnitario;

    this.calcularValorTotalItens();
    this.calcularTotalHa();
  }

  changeProduto(itemOS: ItemOrdemServico) {
    if (itemOS.isServico) {
      this.changeServico(itemOS)
      return;
    }
    if (!itemOS.ProdutoId) return;
    const localEstoque = this.locaisEstoque.filter(x => x.FazendaId === this.ordemServico.FazendaId);
    if (localEstoque !== null && localEstoque !== undefined && localEstoque.length > 0 && this.possuiERP)
      this.produtoService.getProdutoLocalEstoqueData(itemOS.ProdutoId, localEstoque[0].Id, this.ordemServico.DataPrevista).subscribe(
        (res: Produto) => itemOS.PrecoUnitario = res.PrecoUnitario,
        err => this.utils.getErro(err)
      )
    else if(itemOS.PrecoUnitario === 0)
      this.produtoService.getProduto(itemOS.ProdutoId).subscribe(
        (res: Produto) => itemOS.PrecoUnitario = res.PrecoUnitario,
        err => this.utils.getErro(err)
      )

    itemOS.QtdPrevista = itemOS.Dose * itemOS.AreaAplicacao;
    itemOS.ValorTotal = itemOS.Dose * itemOS.PrecoUnitario;

    this.calcularValorTotalItens();
    if (itemOS.ProdutoId && itemOS.Dose && this.ordemServico.AreaPrevista)
      this.itemOrdemServicoService.calcDosePorTanque(itemOS.ProdutoId, itemOS.Dose, this.ordemServico.AreaPrevista).subscribe(
        res => itemOS.DosePorTanqueCompleto = res,
        err => this.utils.getErro(err)
      );
    this.calcularTotalHa();
  }

  calcularValorTotalItens() {
    this.valorTotalOs = 0;
    if (this.itensOrdemServico)
      this.itensOrdemServico.forEach(i => {
        //this.changeProduto(i);
        this.valorTotalOs += i.ValorTotal;
      });
  }

  calcularTotalHa() {
    var soma = 0;
    var somaServico = 0;
    if (this.itensOrdemServico) {
      this.itensOrdemServico.filter(x => !x.isServico).forEach(p => {
        if (!Number.isNaN(p.ValorTotal)) {
          soma += p.ValorTotal;
        }
      });
    }
    this.totalHa = soma;

    this.itensOrdemServico.filter(x => x.isServico).forEach(p => {
      if (!Number.isNaN(p.ValorTotal)) {
        somaServico += p.ValorTotal;
      }
    });

    this.totalServicoHa = somaServico / this.ordemServico.AreaPrevista;

    this.emitirEvento();
  }

  private emitirEvento() {
    var hasErros: boolean = false;
    this.itensOrdemServico.forEach(item => {
      const erros = this.validarItem(item);
      if (erros.length > 0) {
        this.utils.handleWarning(erros.join('<br>'))
        hasErros = true;
      }
    });
    setTimeout(() => {
      this.ChangeItensEvento.emit(hasErros);
    }, 300);

  }

  validarItem(item: ItemOrdemServico): Array<string> {
    const requiredFields = [
      { condition: true, field: item.Ordem, message: 'Defina uma ordem para o Item!' },
      { condition: true, field: item.ProdutoId, message: 'Item sem Produto associado!' },
      { condition: !item.isServico, field: item.Dose, message: 'Defina a Dose!' },
      { condition: true, field: item.QtdPrevista, message: 'Quantidade Prevista inválida!' },
      { condition: true, field: item.PrecoUnitario, message: 'Preço Unitário inválido!' },
    ];

    const erros = requiredFields.reduce((errors, { condition, field, message }) => {
      if (condition && !field) {
        errors.push(message);
      }
      return errors;
    }, []);

    return erros ? erros : [];
  }

  getProduto(id: number): Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';

    if (!this.produtos)
      return novo;

    let produto = this.produtos.filter(x =>
      x.Id == id
    );
    return produto.length > 0 ? produto[0] : novo;
  }

  changeDose(itemOS: ItemOrdemServico) {
    if (!itemOS.AreaAplicacao) {
      this.utils.handleWarning("Defina a área de aplicação");
      return;
    }
    this.changeProduto(itemOS);
  }

  changeQuantidade(itemOS: ItemOrdemServico) {
    itemOS.Dose = itemOS.QtdPrevista / itemOS.AreaAplicacao;
    this.calcularValorTotalItens();
  }

  changeOrdens(item: ItemOrdemServico) {
    if (item.Ordem == null || item.Ordem == undefined) {
      this.utils.handleWarning(this.translate.instant('O_CAMPO_ORDEM_NAO_PODE_SER_VAZIO'));
      return;
    }
    this.changeProduto(item);
    this.itensOrdemServico.sort((a, b) => a.Ordem - b.Ordem);
    this.emitirEvento();
  }

  deleteItem(index: number) {
    let item = this.itensOrdemServico[index];
    if (item.Id < 0) {
      this.itensOrdemServico.splice(index, 1);
      this.calcularValorTotalItens();
      this.calcularTotalHa();
    }
    else {
      item.FlagExcluido = true;
      this.itemOrdemServicoService.putItemOrdemServico(item.Id, item).subscribe(res => {
        this.itensOrdemServico.splice(index, 1);
        this.calcularValorTotalItens();
        this.calcularTotalHa();
      });
    }
  }

  sincronizarErp() {
    this.carregando = true;
    this.produtoService.sincronizarErpPeriodico().subscribe(
      ret => {
        this.utils.handleSuccess(this.translate.instant('SINCRONIZACAO_REALIZADA_COM_SUCESSO'));
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  modalEstoque(ItemOrdemServico: ItemOrdemServico) {
    this.AuxEstoqueRM = [];
    this.locaisEstoque.forEach(item => {
      this.GetEstoque(ItemOrdemServico.ProdutoId, item.Id, item.Descricao);
    });
    this.Modal.show();
  }
  getFiliais(): string[] {
    const filiaisUnicas = this.AuxEstoqueRM
      .map(item => item.Filial) // Primeiro, mapeia todas as filiais
      .filter((value, index, self) => self.indexOf(value) === index); // Depois, filtra para manter apenas valores únicos
    return filiaisUnicas;
  }
  GetEstoqueFilial(local: string): AuxEstoqueRM[] {
    let retorno: AuxEstoqueRM[] = [];
    this.AuxEstoqueRM.forEach(item => {
      if (item.Filial == local) {
        retorno.push(item);
      }
    });
    return retorno;
  }
  fecharModal() {
    this.Modal.hide();
  }

  GetEstoque(produtoId: number, localId: number, nomeEstoque: string) {
    this.produtoService.geEstoqueProduto(produtoId, localId).subscribe(ret => {
      if (ret) {
        let local: AuxEstoqueRM = ret;
        local.LocalId = localId;
        local.LocalEstoqueString = nomeEstoque;
        this.AuxEstoqueRM.push(local);
      }
    });
  }
  getTotalFilial(filial: string): number {
    let retorno = this.GetEstoqueFilial(filial);
    var total: number = 0;
    retorno.forEach(item => {
      total += item.Estoque;
    });
    return total;
  }

  novoItem(novoItem?: ItemOrdemServico) {
    if (!this.ordemServico) {
      return;
    }

    if (!this.itensOrdemServico)
      this.itensOrdemServico = [];

    let itens: ItemOrdemServico[];
    if (!novoItem)
      novoItem = new ItemOrdemServico();

    novoItem.Tipo = this.tipo;

    switch (this.tipo) {
      case EnumTipoItemOS.Insumo:
        itens = this.itensOrdemServico.filter(x => !x.TratamentoSementes && !x.AdubacaoLinha && !x.PulverizacaoSulco && !x.isServico);
        novoItem.Ordem = this.getUltimaOrdem(itens) + 1;
        break;
      case EnumTipoItemOS.Servico:
        itens = this.itensOrdemServico.filter(x => x.isServico);
        novoItem.isServico = true;
        novoItem.Ordem = this.getUltimaOrdem(itens) + 1;
        break;
      case EnumTipoItemOS.TratamentoSementes:
        itens = this.itensOrdemServico.filter(x => x.TratamentoSementes);
        novoItem.TratamentoSementes = true;
        novoItem.Ordem = this.getUltimaOrdem(itens) + 1;
        break;
      case EnumTipoItemOS.AdubacaoLinha:
        itens = this.itensOrdemServico.filter(x => x.AdubacaoLinha);
        novoItem.AdubacaoLinha = true;
        novoItem.Ordem = this.getUltimaOrdem(itens) + 1;
        break;
      case EnumTipoItemOS.PulverizacaoSulco:
        itens = this.itensOrdemServico.filter(x => x.PulverizacaoSulco);
        novoItem.PulverizacaoSulco = true;
        novoItem.Ordem = this.getUltimaOrdem(itens) + 1;
        break;
    }

    if (itens.length == 0 || itens[itens.length - 1].ProdutoId > 0) {
      novoItem.OrdemServicoId = this.ordemServico.Id;
      novoItem.AreaAplicacao = this.ordemServico.AreaPrevista;
      novoItem.Id = new Date().getTime() * -1;
      this.itensOrdemServico.push(novoItem);
    }
  }

  getUltimaOrdem(itens) {
    if (!itens)
      return 0;
    let ultimaOrdem = itens.filter(x => x.Ordem > 0).sort((a, b) => b.Ordem - a.Ordem);
    return ultimaOrdem.length > 0 ? ultimaOrdem[0].Ordem : 0;
  }

  save(osId: number) {
    if (!this.itensOrdemServico)
      return;
    this.itensOrdemServico = this.itensOrdemServico.filter(c => !c.OrdemServicoId || c.OrdemServicoId == osId);
    this.itensOrdemServico.forEach(i => {
      i.OrdemServicoId = osId;
      i.Id = i.Id < 0 ? -1 : i.Id;
    });

    this.itemOrdemServicoService.postItemOrdemServico(this.itensOrdemServico).subscribe(
      res => {
        this.listarItens();
      },
      err => {
        this.utils.getErro(err);
      }
    );
  }

}
