import { SafraService } from './../../../services/safra.service';
import { FazendaService } from './../../../services/fazenda.service';
import { Safra } from './../../../classes/Safra';
import { Fazenda } from './../../../classes/Fazenda';
import { Component, OnInit } from '@angular/core';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-fim-safra',
  templateUrl: './fim-safra.component.html',
  styleUrls: ['./fim-safra.component.scss']
})
export class FimSafraComponent implements OnInit {
  fazendaId: number = 0;
  usuarioId: number = 0;
  empresaId: number = 0;
  safraId: number = 0;
  fazendas: Fazenda[] = [];
  fazendaSelecionada: number[] = [];
  safras: Safra[] = [];
  constructor(private toasty: CustomToastyService,
    private FazendaService: FazendaService,
    private SafraService: SafraService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.usuarioId = this.storageService.getUsuario().Id;
    this.empresaId = this.storageService.getEmpresa().Id;
    this.FazendaService.getFazendas().subscribe(ret => {
      this.fazendas = ret;
    });
  }
  onChangeFazenda() {
    this.FazendaService.getFazenda(this.fazendaId).subscribe(ret => {
      this.fazendaSelecionada = [];
      this.fazendaSelecionada.push(ret.Id);
      this.SafraService.getSafrasPorFazendas(this.fazendaSelecionada).subscribe(ret => {
        this.safras = ret;
      });
    });
  }
  GerarPDF() {
    window.open(`${environment.apiURL}/safra/R_HistoricoAplicacao/${this.safraId}`, "_parent");
  }
}
