import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Classe } from 'src/app/classes/Classe';
import { ClasseService } from 'src/app/services/classe.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-classe-forms',
  templateUrl: './classe-forms.component.html',
  styleUrls: ['./classe-forms.component.scss']
})
export class ClasseFormsComponent implements OnInit {

  @Output() fecharFormsEvent = new EventEmitter<any>();
  @Input() id:number;
  @Input() inserindo: boolean;
  classe: Classe;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;

  constructor(
    private classeService: ClasseService,
    private translate: TranslateService,
    private utils : UtilsService) {}

  ngOnInit() {
    this.classe = new Classe();
    if(this.inserindo)
      this.classe = new Classe()
    else
      this.selecionarClasse();
  }

  selecionarClasse() {
    this.carregando = true;
    this.classeService.ObterPorId(this.id).subscribe(res =>{
      this.classe = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.classe.Id > 0){
      this.classeService.Atualizar(this.classe.Id, this.classe).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('CLASSE_ALTERADA'));
          this.carregando = false;
          this.fecharFormsEvent.emit();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
    else{
      this.classeService.Inserir(this.classe).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('CLASSE_CADASTRADA'));
          this.carregando = false;
          this.fecharFormsEvent.emit();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }
  }

  cancelar(){
    this.fecharFormsEvent.emit();
  }

}