import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { EnumModulo } from 'src/app/classes/GrupoTransacao';
import { ItemEnum } from 'src/app/classes/ItemEnum';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({ selector: 'app-filtro-lateral-notas', 
templateUrl: './filtro-lateral-notas.component.html', 
styleUrls: ['./filtro-lateral-notas.component.scss'] })
export class FiltroLateralNotasComponent implements OnInit {

  constructor(
    private empresaInternaService: EmpresaInternaService,
    private utils: UtilsService)
    {
  }

  @Input() listaStatus: ItemEnum[];
  @Input() modulo: EnumModulo;
  @Input() filtro: FiltroTela;
  @Input() border: boolean = false;
  @Input() minHeight: number = 0;
  @Output() filtrar: EventEmitter<any> = new EventEmitter();
  @Output() atualizar: EventEmitter<any> = new EventEmitter();

  empresasIntenas: EmpresaInterna[] = [];
  recolherFiltro: boolean = false;;
  empresaInternaId: number = 0;

  ngOnInit() {
    this.filtro.DataCriacaoInicial = null;
    this.filtro.DataCriacaoFinal = null;
    this.filtro.DataEmissaoFinal = new Date();
    this.filtro.DataEmissaoInicial = new Date(this.filtro.DataEmissaoFinal.getFullYear(), this.filtro.DataEmissaoFinal.getMonth(), this.filtro.DataEmissaoFinal.getDate() - 1);
    this.filtro.Status = 0;
    this.listarEmpreasasInternas();
  }

  
  onEmpresaChange(valor: number){
    console.debug(valor);
    if(!valor)
      this.filtro.EmpresaInternaId = null;
    else{
      if(this.empresasIntenas.find(p => p.Id == valor)){
        this.filtro.EmpresaInternaId = valor;
      }else
      this.filtro.EmpresaInternaId = null;
    }
  }

  toogleFiltroClick() {
    this.recolherFiltro = !this.recolherFiltro;
  }
  filtrarClick(){
    this.filtrar.emit(this.filtro)
  }

  atualizarClick(){
    this.atualizar.emit();
  }
  
  
  listarEmpreasasInternas(){
    this.empresaInternaService.getEmpresasInternas().subscribe(res => {
      this.empresasIntenas = res;
      this.empresaInternaId = this.empresasIntenas[0].Id;
      this.onEmpresaChange(this.empresasIntenas[0].Id);
      this.filtrarClick();
    }, err => {
      this.utils.getErro(err)
    });
  }
 
 
  public limparFiltros() {
    this.filtro = {
      TransacaoId: null,
      EmpresaInternaId: null,
      DataCriacaoInicial: null,
      DataCriacaoFinal: null,
      DataEmissaoInicial: null,
      DataEmissaoFinal: null,
      NumeroDocumento: null,
      Identificador: null,
      Status: null,
      ProdutoId: null,
      Tipo: null,
    };

    this.filtrar.emit(this.filtro);
  }
}