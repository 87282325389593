import { RelatorioAutomatico } from './../classes/RelatorioAutomatico';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelatorioAutomaticoService {

  constructor(private http: HttpClient) { }

  get(): Observable<any>{
    return this.http.get(`${environment.apiURL}/RelatorioAutomatico`)
  }

  getId(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/RelatorioAutomatico/${id}`)
  }

  post(RelatorioAutomatico: RelatorioAutomatico): Observable<any>{
    return this.http.post(`${environment.apiURL}/RelatorioAutomatico`, RelatorioAutomatico);
  }

  put(id: number, RelatorioAutomatico: RelatorioAutomatico): Observable<any>{
    return this.http.put(`${environment.apiURL}/RelatorioAutomatico/${id}`, RelatorioAutomatico);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/RelatorioAutomatico/${id}`);
  }
  addUser(id:number, User:number): Observable<any>
  {
    return this.http.get(`${environment.apiURL}/RelatorioAutomatico/addUser/${id}/${User}`)
  }
  removeUser(id:number): Observable<any>
  {
    return this.http.get(`${environment.apiURL}/RelatorioAutomatico/removeUser/${id}`)
  }
}
