import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Bancos } from 'src/app/classes/Bancos';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { PersonalizacaoGridModel } from 'src/app/classes/PersonalizacaoGridModel';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { BancosService } from 'src/app/services/banco.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bancos',
  templateUrl: './bancos.component.html',
  styleUrls: ['./bancos.component.scss']
})
export class BancosComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private toasty: CustomToastyService,
    private bancosService : BancosService,
    ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  id: number = 0;

  @ViewChild('Grid', { static: false }) grid

  PersonalizacaoGrid: PersonalizacaoGridModel = new PersonalizacaoGridModel();
  qtdItems = 340;
  acoes = [
    'Editar',
    'Aprovação',
  ]
  acoesItem = [
    'Editar'
  ]
  colDefs: ColDef[] = [];
  showGrid = true;
  showGridItems = true;
  minHeight = 0;
  inserindo: boolean;
  listaBancos: Bancos[] = [];
  listaAuxBancos: Bancos[] = [];
  objSelecionado: Bancos = new Bancos();
  objSelecionadoId: number = null;
  objetosSelecionados: Bancos[] = [];
  filtro = new FiltroTela();
  isSubmit = false;
  loading: boolean = false;

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    if (this.id > 0)
      this.inserindo = true

    this.configurar();

    this.listarBancos();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'DescricaoCompleta', headerName: 'Descrição', flex: 1}
    ];

  }

  listarBancos() {
    this.bancosService.Listar()
      .subscribe(res => {
        this.listaBancos = res;
        this.listaAuxBancos = res;

        this.grid.setRowData(this.listaBancos);

      }, err => this.utils.getErro(err));
  }

  novo() {
    this.router.navigate(['/cadastros/bancos/inserir']);
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/bancos/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {

    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione um banco para prosseguir!");
      return;
    }

    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um banco para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar '+ this.objetosSelecionados[0].Descricao + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.bancosService.Excluir(this.objSelecionadoId).subscribe(
            res => {
              this.toasty.show('Feito!', 'Banco excluído!', 'success');
              this.listarBancos();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

  editar() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione um banco para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um banco para prosseguir!");
      return;
    }
    this.router.navigate([`/cadastros/bancos/${this.objSelecionadoId}`]);
  }

}
