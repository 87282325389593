import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Oficina } from 'src/app/classes/Oficina';
import { Pessoa } from 'src/app/classes/Pessoa';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { OficinaService } from 'src/app/services/oficina.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-oficina',
  templateUrl: './oficina.component.html',
  styleUrls: ['./oficina.component.scss']
})
export class OficinaComponent implements OnInit {

  DESCRICAO: string = 'Descrição';
  E_TERCEIRO: string = 'É Terceiro?';
  FORNECEDOR: string = 'Fornecedor';
  STATUS: string = 'Status';
  SIM: string = 'Sim';
  NAO: string = 'Não';
  ATIVO: string = 'Ativo';
  INATIVO: string = 'Inativo';
  SELECIONE_UMA_OFICINA_PARA_PROSSEGUIR: string = 'Selecione uma oficina para prosseguir!';
  SELECIONE_APENAS_UMA_OFICINA_PARA_PROSSEGUIR: string = 'Selecione apenas uma oficina para prosseguir!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  OFICINA_EXCLUIDA: string = 'Oficina excluída!';

  configurarTraducao() {
    this.DESCRICAO = this.translate.instant('DESCRICAO');
    this.E_TERCEIRO = this.translate.instant('E_TERCEIRO');
    this.FORNECEDOR = this.translate.instant('FORNECEDOR');
    this.STATUS = this.translate.instant('STATUS');
    this.SIM = this.translate.instant('SIM');
    this.NAO = this.translate.instant('NAO');
    this.ATIVO = this.translate.instant('ATIVO');
    this.INATIVO = this.translate.instant('INATIVO');
    this.SELECIONE_UMA_OFICINA_PARA_PROSSEGUIR = this.translate.instant('SELECIONE_UMA_OFICINA_PARA_PROSSEGUIR');
    this.SELECIONE_APENAS_UMA_OFICINA_PARA_PROSSEGUIR = this.translate.instant('SELECIONE_APENAS_UMA_OFICINA_PARA_PROSSEGUIR');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.OFICINA_EXCLUIDA = this.translate.instant('OFICINA_EXCLUIDA');
  }

  constructor(
    private pessoaService: PessoaService,
    private translate: TranslateService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private oficinaService: OficinaService,
    ) { 
      this.route.params.subscribe(res => {
        this.id = res.id;
      });
    }

  @ViewChild('Grid', { static: false }) grid: any;

  colDefs: ColDef[] = [];
  listaOficina: Oficina[] = [];
  objSelecionado: Oficina = new Oficina();
  objSelecionadoId: number = null;
  objetosSelecionados: Oficina[] = [];
  fornecedores: Pessoa[];
  id: number = 0;
  inserindo: boolean;
  carregando: boolean = true;

  ngOnInit() {
    this.configurar();
    this.listarOficina();
    this.pessoaService.getList().subscribe((res: Pessoa[]) => {
      this.fornecedores = res.filter(c => c.Papel.includes('Fornecedor'));
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }

  configurar() {
    this.configurarTraducao();
    this.colDefs = [
      {
        width: 15,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'Descricao', headerName: 'Descrição', flex: 1},
      {field: 'IsTerceiro', headerName: 'É Terceiro?', flex: 1, cellRenderer: (params) => this.isTerceiro(params.data.IsTerceiro)},
      {field: 'FornecedorId', headerName: 'Fornecedor', flex: 1, cellRenderer: (params) => this.getNomeFornecedor(params.data.FornecedorId)},
      {field: 'Ativo', headerName: 'Status', flex: 1, cellRenderer: (params) => this.statusBadge(params.data.Ativo)},
    ];

  }
  isTerceiro(isTrue) {
    if (isTrue)
      return '<span class="text-primary text-uppercase">'+ this.translate.instant('SIM') +'</span>'
    else
      return '<span class="text-primary text-uppercase">'+ this.translate.instant('NAO') +'</span>'
  }
  getNomeFornecedor(id:number): string {
    return this.fornecedores.find(c => c.Id == id).NomeFantasia;
  }
  statusBadge(status: boolean) {
    if (status)
      return '<span class="text-success text-uppercase">'+ this.translate.instant('ATIVO') +'</span>'
    else
      return '<span class="text-danger text-uppercase">'+ this.translate.instant('INATIVO') +'</span>'
  }

  listarOficina() {
    this.inserindo = false;
    this.id = 0;
    this.oficinaService.Listar()
      .subscribe(res => {
        this.listaOficina = res;
        this.grid.setRowData(this.listaOficina);
      }, err => this.utils.getErro(err));
  }

  novo() {
    this.inserindo = true;
  }

  cellDoubleClicked(event) {
    this.id = event.data.Id;
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {
    this.configurarTraducao();
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning(this.SELECIONE_UMA_OFICINA_PARA_PROSSEGUIR);
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning(this.SELECIONE_APENAS_UMA_OFICINA_PARA_PROSSEGUIR);
      return;
    }

    Swal.fire({
      title: this.DELETAR + ' '+ this.objetosSelecionados[0].Descricao + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText:  this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.oficinaService.Excluir(this.objetosSelecionados[0].Id).subscribe(
            res => {
              this.utils.handleSuccess(this.OFICINA_EXCLUIDA);
              this.listarOficina();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

}
