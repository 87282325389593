import { TranslateService } from "@ngx-translate/core";

export abstract class ClasseProdutoTranslate {
  /**
   *
   */
  constructor(translate: TranslateService) {
    this.ERRO = translate.instant('ERRO');
    this.FEITO = translate.instant('FEITO');
    this.CLASSE_DE_PRODUTO_ALTERADA = translate.instant('CLASSE_DE_PRODUTO_ALTERADA');
    this.CLASSE_DE_PRODUTO_CADASTRADA = translate.instant('CLASSE_DE_PRODUTO_CADASTRADA');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = translate.instant('CANCELAR');
    this.SIM = translate.instant('SIM');
    this.CLASSE_DE_PRODUTO_DELETADA = translate.instant('CLASSE_DE_PRODUTO_DELETADA');
  }

  DELETAR_PARAM: string;
  ERRO: string = 'Erro!';
  FEITO: string = 'Feito!';
  CLASSE_DE_PRODUTO_ALTERADA: string = 'Classe de Produto alterada!';
  CLASSE_DE_PRODUTO_CADASTRADA: string = 'Classe de Produto cadastrada!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  CLASSE_DE_PRODUTO_DELETADA: string = 'Classe de Produto deletada!';
}
