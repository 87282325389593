import { Component, OnInit } from '@angular/core';
import { Cultura } from 'src/app/classes/Cultura';
import { Empresa } from 'src/app/classes/Empresa';
import { CulturaService } from 'src/app/services/cultura.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { CulturaTranslate } from './cultura.translate';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cultura',
  templateUrl: './cultura.component.html',
  styleUrls: ['./cultura.component.scss']
})
export class CulturaComponent extends CulturaTranslate implements OnInit {

  culturas: Cultura[];
  auxCulturas: Cultura[];
  cultura: Cultura;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  icones: string[] = ['algodao','arroz','aveia','cafe','cana','feijao','mamao','milheto','milho','soja','sorgo','trigo'];


  constructor(private culturasService: CulturaService, private empresaService: EmpresaService,  private route: ActivatedRoute,
              private router: Router, private storageService: StorageService,
              private toasty: CustomToastyService,
              private translate: TranslateService) {
                super(translate)
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.cultura = new Cultura();

    if(this.id > 0)
      this.selecionarCultura();
    else
      this.listarCulturas();
  }

  selecionarCultura() {
    this.carregando = true;
    this.culturasService.getCultura(this.id).subscribe(res =>{
      this.cultura = new Cultura();
      this.cultura = res;
      this.carregando = false;
    },
    ex =>{
      Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
      this.carregando = false;
    })
  }

  listarCulturas(){
    this.carregando = true;
    this.culturasService.getCulturas().subscribe(
      res => {
        this.culturas = res;
        this.auxCulturas = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarCulturas();
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.cultura.Id > 0)
      this.culturasService.putCultura(this.cultura.Id, this.cultura).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.CULTURA_ALTERADA, 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.culturasService.postCultura(this.cultura).subscribe(
        res => {
          this.cultura = res;
          this.toasty.show(this.FEITO, this.CULTURA_CADASTRADA, 'success');
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(grupooperacaoId: number, nome: string) {
    this.DELETAR_PARAM = this.translate.instant('DELETAR_PARAM',{value: nome})
    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.culturasService.deleteCultura(grupooperacaoId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.CULTURA_DELETADA, 'success');
              this.listarCulturas();
              this.carregando = false;
            },
            err => {
              Swal.fire(this.ERRO, err.message, 'error');
              this.carregando = false;
            });
        }
      });
  }

  filtrarCulturas(){
    this.culturas = this.auxCulturas.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/gerenciador/cultura']);
  }

}
