import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ComentarioSafra } from '../classes/ComentarioSafra';

@Injectable({
  providedIn: 'root'
})
export class ComentarioSafraService {

  constructor(private http: HttpClient) { }

  getComentarios(): Observable<any> {
    return this.http.get(`${environment.apiURL}/comentarioSafra`);
  }

  getComentario(id: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/comentarioSafra/${id}`);
  }

  postComentario(comentario: ComentarioSafra): Observable<any> {
    return this.http.post(`${environment.apiURL}/comentarioSafra`, comentario);
  }

  putComentario(id: number, comentario: ComentarioSafra): Observable<any> {
    return this.http.put(`${environment.apiURL}/comentarioSafra/${id}`, comentario);
  }

  deleteComentario(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/comentarioSafra/${id}`);
  }

  getComentariosPorArea(areaId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/comentarioSafra/area/${areaId}`);
  }

  getComentariosPorFazenda(fazendaId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/comentarioSafra/fazenda/${fazendaId}`);
  }
}
