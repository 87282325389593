import { ValoresEstoqueTuple } from "./ValoresEstoqueTuple";

export class ValoresEstoque {
    QtdProdutosEstoque: number = 0;
    ValorTotalEstoque: number = 0;
    ValorTotalEstoque90dias: number = 0;
    ValoresPorGrupo:ValoresEstoqueTuple [];
    Top5:ValoresEstoqueTuple [];
    ValorFazenda:ValoresEstoqueTuple [];
    ValorLocal:ValoresEstoqueTuple [];
    constructor() {
        this.QtdProdutosEstoque = 0;
        this.ValorTotalEstoque = 0;
        this.ValorTotalEstoque90dias = 0;
        this.ValoresPorGrupo = [];
        this.Top5 = [];
        this.ValorFazenda = [];
        this.ValorLocal = [];
    }

}