import { Checklist } from "../Checklist";
import { ManutencaoFrota } from "./ManutencaoFrota";

export class ChecklistManutencaoFrota {
  Id: number;
  ChecklistId: number;
  Checklist:Checklist
  ManutencaoFrotaId: number;
  ManutencaoFrota:ManutencaoFrota
}
