import { ItemEnum } from "./ItemEnum";
import { EnumTipoPagamento } from "./LancamentoFinanceiro";
import { EnumStatusMovimento } from "./Movimento";
import { Produto } from "./Produto";

export class ItemMovimento{
  Id: number;
  ItemMovimentoPaiId: number;
  MovimentoId: number;
  Sequencial: number = 0;
  ProdutoId: number;
  Produto: Produto;
  Un:string = "";
  QuantidadePedida: number = 0;
  QuantidadeOriginal: number = 0;
  QuantidadeRecebida: number = 0;
  QuantidadePendente: number = 0;
  ValorUnitario: number = 0;
  PercentualDesconto: number = 0;
  ValorDesconto: number = 0;
  AliquotaIPI: number = 0;
  ValorIPI: number = 0;
  AliquotaICMS: number = 0;
  ValorICMS: number = 0;
  ICMS_ST: number = 0;
  ValorTotalBruto: number = 0;
  ValorTotalLiquido: number = 0;
  CodigoSituacaoTributaria:string = "";
  LocalEstoqueId: number;
  EquipamentoId: number;
  Medidor: number = 0;
  CentroDeResultadosId: number;
  ProjetoId: number;
  NaturezaId: number;
  Origem: EnumItemMovimentoOrigem;
  Status: EnumStatusMovimento;
  SafraOrigem:string = "";
  GetSafra:string = "";
  Dose: number = 0;
  Area: number = 0;
  Total: number = 0;
  GetItemPaiDescricao:string = "";
  DataVencimento: Date = new Date();
  FormaPagamento: EnumTipoPagamento;
}
export enum EnumItemMovimentoOrigem{
  Solicitacao = 1,
  Safra = 2,
  Manutencao = 3
}

export const ListaEnumItemMovimentoOrigem: ItemEnum[] = [
  {name: 'Solicitação', value: EnumItemMovimentoOrigem.Solicitacao},
  {name: 'Safra', value: EnumItemMovimentoOrigem.Safra},
  {name: 'Manutenção', value: EnumItemMovimentoOrigem.Manutencao},
];
