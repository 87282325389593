import { FuncionarioService } from './../../../services/funcionario.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Funcionario } from 'src/app/classes/Funcionario';

@Component({
  selector: 'app-select-funcionario',
  templateUrl: './select-funcionario.component.html',
  styleUrls: ['./select-funcionario.component.scss']
})
export class SelectFuncionarioComponent implements OnInit {
  @Input() isSubmit: boolean;  
  @Input() funcionarioId: number = 0;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() auxClass: string;
  @Input() ocultarMsgObrigatorio: boolean = false;
  @Output() funcionarioChange: EventEmitter<number> = new EventEmitter();
  @Input() funcionarios: Funcionario[];

  constructor(private funcionarioService: FuncionarioService) {}

  ngOnInit() {
    if(!this.funcionarios)
      this.funcionarioService.getFuncionarios().subscribe(ret => {
        this.funcionarios = ret;
      });
  }

  onChange(){   
    this.funcionarioChange.emit(this.funcionarioId);
  }

  
  filtrarFuncionario(term: string, item: Funcionario) {
    term = term.toLowerCase();
    return item.Nome.toLowerCase().indexOf(term) > -1;
  }

}
