import { Usuario } from 'src/app/classes/Usuario';
import { FazendaService } from './../../../../services/fazenda.service';
import { StorageService } from 'src/app/services/storage.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NextConfig} from '../../../../app-config';
import { Router } from '@angular/router';
import { Fazenda } from 'src/app/classes/Fazenda';
import { AnoAgricolaService } from 'src/app/services/ano-agricola.service';
import { AnoAgricola } from 'src/app/classes/AnoAgricola';

@Component({
  selector: 'app-nav-bar-erp',
  templateUrl: './nav-bar-erp.component.html',
  styleUrls: ['./nav-bar-erp.component.scss']
})
export class NavBarErpComponent implements OnInit {
  public nextConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number = 0;
  public fazendas: Fazenda[] = [];
  public fazendaAtual: Fazenda;
  public fazendaId: number = 0;
  public fazendaDescricao: string = "";
  public usuario: Usuario;
  public anosAgricolas: AnoAgricola[] = [];
  public anoAgricolaAtual: AnoAgricola;
  public anoAgricolaId: number = 0;
  public anoAgricolaDescricao: string = "Todos";

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  constructor(private storageService: StorageService,
              private router: Router,
              private fazendaService: FazendaService,
              private anoAgricolaService: AnoAgricolaService)  {
    this.nextConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    this.usuario = this.storageService.getUsuario();
    this.fazendaAtual = this.storageService.getFazenda();
    this.anoAgricolaAtual = this.storageService.getAnoAgricola();
    if(this.fazendaAtual)
      this.fazendaDescricao = this.fazendaAtual.Nome;

    if(this.anoAgricolaAtual)
      this.anoAgricolaDescricao = this.anoAgricolaAtual.Descricao;

    this.anoAgricolaService.getAnosAgricolas().subscribe(ret => {
      this.anosAgricolas = ret;
    })

    if(this.usuario.PermissaoUsuario.AcessoTodasFazendas){
      this.fazendaService.getFazendas().subscribe(ret => {
        this.fazendas = ret;

      })
    }



  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

  selecionaFazenda(fazendaId : number){
    this.fazendaService.getFazenda(fazendaId).subscribe(ret=>{
      this.storageService.setFazenda(ret);
      this.fazendaDescricao = ret.Nome;
      window.location.href = '/';
    })
  }

  selecionaAnoAgricola(anoAgricolaId : number){
    if(anoAgricolaId > 0)
      this.anoAgricolaService.getAnoAgricola(anoAgricolaId).subscribe(ret=>{
        this.storageService.setAnoAgricola(ret);
        this.anoAgricolaDescricao = ret.Descricao;
        window.location.href = '/';
      })
    else{
      this.storageService.removerAnoAgricola();
      this.anoAgricolaDescricao = "Todos";
      window.location.href = '/';
    }
  }



}

