import { EnumOrigemLancamentoFinanceiro } from "./enum/EnumOrigemLancamentoFinanceiro";
import { EnumTipoLancamentoFinanceiro } from "./enum/EnumTipoLancamentoFinanceiro";
import { EnumClassificacaoLancamentoFinanceiro } from "./enum/EnumClassificacaoLancamentoFinanceiro";
import { EnumSituacaoLancamentoFinanceiro } from "./enum/EnumSituacaoLancamentoFinanceiro";
import { EnumStatusAprovacao } from "./Movimento";
import { TiposDocumentosFinanceiros } from "./TiposDocumentosFinanceiros";
import { ItemEnum } from "./ItemEnum";

export class LancamentoFinanceiro{
Id: number;
EmpresaInternaId: number;
PessoaId: number;
Adiantamento: boolean;
Origem: EnumOrigemLancamentoFinanceiro;
OrigemId: number;
Parcela: number = 0;
TipoPagamento: EnumTipoPagamento;
NumeroDocumento:string = "";
DataEmissao: Date = new Date();
DataVencimento: Date = new Date();
DataPrevisaoBaixa : Date = new Date();
Tipo: EnumTipoLancamentoFinanceiro;
ValorOriginal: number = 0;
Classificacao: EnumClassificacaoLancamentoFinanceiro;
IsCancelado: boolean;
CompensacaoId: number;
TransacaoLancamentoId: number;
TransacaoBaixaId: number;
IrParaLCDPR: boolean
DataBaixa: Date = new Date();
Conciliado: boolean;
DataConciliacao: Date = new Date();
Contabilizado: boolean;
NumeroCheque:string = "";
TiposDocumentosFinanceirosId: number;
TiposDocumentosFinanceiros: TiposDocumentosFinanceiros;
ValorJuros: number = 0;
ValorMulta: number = 0;
ValorFunrural: number = 0;
ValorSenar: number = 0;
ValorDesconto: number = 0;
ValorAcertoAdiantamento: number = 0;
ValorAcertoCompensacao: number = 0;
ValorLiquido: number = 0;
ValorBaixado: number = 0;
ValorEmAberto: number = 0;
HistoricoGerencial:string = "";
HistoricoParaLCDPR:string = "";
HistoricoParaContabilidade:string = "";
StatusAprovacao: EnumStatusAprovacao;
Situacao: EnumSituacaoLancamentoFinanceiro;
IsRateado: boolean;
}


export enum EnumTipoPagamento{
  Boleto = 1,
  DepositoBancario = 2,
  Cheque = 3,
  Dinheiro = 4,
  Pix = 5,
  Doc= 6,
  Ted = 7,
  Transferencia = 8,
  Outro = 9
}
export const ListaEnumTipoPagamento: ItemEnum[] = [
  {name: 'Boleto', value: EnumTipoPagamento.Boleto},
  {name: 'Depósito Bancário', value: EnumTipoPagamento.DepositoBancario},
  {name: 'Cheque', value: EnumTipoPagamento.Cheque},
  {name: 'Dinheiro', value: EnumTipoPagamento.Dinheiro},
  {name: 'Pix', value: EnumTipoPagamento.Pix},
  {name: 'Doc', value: EnumTipoPagamento.Doc},
  {name: 'Ted', value: EnumTipoPagamento.Ted},
  {name: 'Transferencia', value: EnumTipoPagamento.Transferencia},
  {name: 'Outro', value: EnumTipoPagamento.Outro},
];
