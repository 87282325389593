import { TipoBico } from './../../../classes/TipoBico';
import { TipoBicoService } from './../../../services/tipo-bico.service';
import { Component, OnInit } from '@angular/core';
import { TipoAplicacao } from 'src/app/classes/TipoAplicacao';
import { TipoAplicacaoService } from 'src/app/services/tipo-aplicacao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tipo-aplicacao',
  templateUrl: './tipo-aplicacao.component.html',
  styleUrls: ['./tipo-aplicacao.component.scss']
})
export class TipoAplicacaoComponent implements OnInit {

  tiposAplicacao: TipoAplicacao[];
  auxTiposAplicacao: TipoAplicacao[];
  tipoAplicacao: TipoAplicacao;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  tiposBicos: TipoBico[];

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  // tradução
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  TIPO_DE_APLICACAO_DELETADA: string = 'Tipo de Aplicação deletada!';

  constructor(private tiposAplicacaoService: TipoAplicacaoService,
              private tipoBicoService: TipoBicoService,
              private route: ActivatedRoute, private router: Router,
              private utils: UtilsService,
              private translate: TranslateService,
              private storageService: StorageService,
              private toasty: CustomToastyService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.tipoAplicacao = new TipoAplicacao();

    this.tipoBicoService.getTiposBicos().subscribe(ret =>{
      this.tiposBicos = ret;
    });

    if(this.id > 0)
      this.selecionarTipoAplicacao();
    else
      this.listarTiposAplicacao();
  }

  selecionarTipoAplicacao() {
    this.carregando = true;
    this.tiposAplicacaoService.getTipoAplicacao(this.id).subscribe(res =>{
      this.tipoAplicacao = new TipoAplicacao();
      this.tipoAplicacao = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  listarTiposAplicacao(){
    this.carregando = true;
    this.tiposAplicacaoService.getTiposAplicacao().subscribe(
      res => {
        this.tiposAplicacao = res;
        this.auxTiposAplicacao = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarTiposAplicacao();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.tipoAplicacao.Id > 0)
      this.tiposAplicacaoService.putTipoAplicacao(this.tipoAplicacao.Id, this.tipoAplicacao).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('TIPO_DE_APLICACAO_ALTERADA'));
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.tiposAplicacaoService.postTipoAplicacao(this.tipoAplicacao).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('TIPO_DE_APLICACAO_CADASTRADA'));
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(tipoAplicacaoId: number, nome: string) {
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.TIPO_DE_APLICACAO_DELETADA = this.translate.instant('TIPO_DE_APLICACAO_DELETADA');
    Swal.fire({
      title: this.DELETAR + ' ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.tiposAplicacaoService.deleteTipoAplicacao(tipoAplicacaoId).subscribe(
            res => {
              this.utils.handleSuccess(this.TIPO_DE_APLICACAO_DELETADA)
              this.listarTiposAplicacao();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarTiposAplicacao(){
    this.tiposAplicacao = this.auxTiposAplicacao.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/tipo-aplicacao']);
  }

}
