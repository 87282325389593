import { Component, Input, OnInit } from '@angular/core';
import { Movimento } from 'src/app/classes/Movimento';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
import { TransacaoService } from 'src/app/services/transacao.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-movimento-header',
  templateUrl: './movimento-header.component.html',
  styleUrls: ['./movimento-header.component.scss']
})
export class MovimentoHeaderComponent implements OnInit {

  constructor(private utils: UtilsService, private usuarioService: UsuarioService, private transacaoService: TransacaoService) { }
  usuarioCadastro: string;
  usuarioAlteracao: string;
  usuarios: Usuario[];
  disabledSerie = true;

  @Input() movimento: Movimento = new Movimento();

  ngOnInit() {
    this.usuarioService.getUsuarios()
    .subscribe(res => {
      this.usuarios = res;
      this.usuarioCadastro = this.usuarios.find(u => u.Id == this.movimento.UsuarioCadastroId).Nome;
      this.usuarioAlteracao = this.usuarios.find(u => u.Id == this.movimento.UsuarioAlteracaoId).Nome;
      this.transacaoService.getTransacao(this.movimento.TransacaoId)
      .subscribe((transacao: Transacao) => {
        this.disabledSerie = transacao.NumeracaoAuto;
      })

    },
     err => this.utils.getErro(err));

  }
  formatarData(data){
    return this.utils.formatarData(data);
  }
}
