import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { StorageService } from './storage.service';
import { Fazenda } from '../classes/Fazenda';

@Injectable({
  providedIn: 'root'
})
export class FazendaService {

  public fazendaSelecionada: Fazenda;

  constructor(private http: HttpClient) { }

  getFazendas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/fazendas`)
  }

  getFazenda(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/fazendas/${id}`)
  }

  postFazenda(fazenda: Fazenda): Observable<any>{
    return this.http.post(`${environment.apiURL}/fazendas`, fazenda);
  }

  putFazenda(id: number, fazenda: Fazenda): Observable<any>{
    return this.http.put(`${environment.apiURL}/fazendas/${id}`, fazenda);
  }

  deleteFazenda(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/fazendas/${id}`);
  }

}
