import { ItemEnum } from './ItemEnum';
export class Fazenda {
    Id: number;
    EmpresaId: number;
    Nome:string = "";
    AreaTotal: number = 0;
    AreaIrrigada: number = 0;
    AreaSequeiro: number = 0;
    AreaTotalProdutiva: number = 0;
    AreaIrrigadaProdutiva: number = 0;
    AreaSequeiroProdutiva: number = 0;
    Latitude: number = 0;
    Longitude: number = 0;
    CodVen1Totvs: EnumCodVenTotvs;
    CodVen2Totvs: EnumCodVenTotvs;

    CodEmpSankhya:string = "";
    CodCenCusSankhya:string = "";

}

export enum EnumCodVenTotvs
{
    UsuarioLiberacao = 1,
    UsuarioApontamento = 2
}

export const codigosCodVenTotvs: ItemEnum[] = [
    {name: 'Usuário da Liberação', value: EnumCodVenTotvs.UsuarioLiberacao},
    {name: 'Usuário do Apontamento', value: EnumCodVenTotvs.UsuarioApontamento}
];
