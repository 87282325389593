import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { TreeItem } from 'src/app/classes/TreeItem';
import { CentroDeResultadosService } from 'src/app/services/centro-de-resultados.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-centro-de-resultados',
  templateUrl: './centro-de-resultados.component.html',
  styleUrls: ['./centro-de-resultados.component.scss']
})
export class CentroDeResultadosComponent implements OnInit {

  constructor(
    private centroDeResultadosService: CentroDeResultadosService,
    private utils: UtilsService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private empresaService: EmpresaService,
    private router: Router,
  ) {
    this.route.params.subscribe((res) => {
      this.id = res.id
      if (this.id > 0) {
        this.selecionar()
        this.exibirForm = true
      }
    })
  }
  centrosDeResultados: CentroDeResultados[]
  arvore: any[] = []
  items: TreeItem[] = []
  htmlTree: string
  carregando: boolean = false
  exibirForm: boolean = false
  id: number
  centroDeResultados: CentroDeResultados = new CentroDeResultados()
  mostrarArvore: boolean = false

  mask: string = "00000000";

  ngOnInit() {
    this.empresaService.getEmpresa(this.storageService.getEmpresa().Id)
    .subscribe(res => {
      const mask = res.MascaraCentroResultado;
      this.mask = mask != null ? mask : this.mask;

    })
    if (this.router.url.search('inserir') > 0) {
      this.centroDeResultados = new CentroDeResultados()
      this.exibirForm = true
    }
    this.atualizarArvore()
  }
  atualizarArvore() {
    this.items = [];
    this.mostrarArvore = false
    this.centroDeResultadosService.getCentrosDeResultados().subscribe(
      (res) => {
        this.mostrarArvore = false
        this.carregando = true
        this.centrosDeResultados = res
        this.centrosDeResultados.forEach((n) => {
          let as: TreeItem = {
            Id: n.Id,
            CodigoPai: n.CodigoPai,
            Codigo: Number(n.Codigo),
            Descricao: n.Descricao,
            IsAnalitico: n.IsAnalitico,
          }
          this.items.push(as)
        })
        this.mostrarArvore = true
        this.carregando = false
      },
      (err) => {
        this.utils.getErro(err)
        this.carregando = false
      },
    )
  }
  selecionar() {
    this.centroDeResultadosService.getCentroDeResultados(this.id).subscribe(
      (res) => {
        this.centroDeResultados = res
      },
      (err) => {
        this.utils.getErro(err)
      },
    )
  }

}
