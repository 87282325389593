import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlcadasAprovacao } from 'src/app/classes/AlcadasAprovacao';
import { GrupoCompra } from 'src/app/classes/GrupoCompra';
import { ListaEnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { Usuario } from 'src/app/classes/Usuario';
import { AlcadasAprovacaoService } from 'src/app/services/alcadas-aprovacao.service';
import { GrupoCompraService } from 'src/app/services/grupo-compra.service';
import { StorageService } from 'src/app/services/storage.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alcadas-aprovacao',
  templateUrl: './alcadas-aprovacao.component.html',
  styleUrls: ['./alcadas-aprovacao.component.scss']
})
export class AlcadasAprovacaoComponent implements OnInit {


  listaAlcadasAprovacao: AlcadasAprovacao[] = [];
  auxAlcadasAprovacao: AlcadasAprovacao[] = [];
  alcadasAprovacao: AlcadasAprovacao = new AlcadasAprovacao();
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean = false;
  textoBusca: string = "";
  perfilLogado: number = 0;

  listaTipoMovimento = ListaEnumTipoMovimento;
  listaUsuario: Usuario[] = [];
  listaGrupoCompra: GrupoCompra[] = [];

  dicUsuario: {[key: number]: Usuario} = {};
  dicGrupoCompra: {[key: number]: GrupoCompra} = {};

  cifraMoedaCorrente: string = ''

  constructor(
    private alcadasAprovacaoService: AlcadasAprovacaoService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private usuarioService: UsuarioService,
    private grupoCompraService: GrupoCompraService,
    private storageService: StorageService,
    private toasty: CustomToastyService) {
    this.route.params.subscribe(res => {
      if( res.id === 'inserir')
        this.id = 0;
      else
        this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.alcadasAprovacao = new AlcadasAprovacao();

    this.listarUsuarios();
    this.listarGrupoCompra();

    if(this.id > 0)
      this.selecionarAlcadasAprovacao();
    else
      this.listar();

    this.cifraMoedaCorrente = this.utils.getCifraMoeda();
  }

  selecionarAlcadasAprovacao() {
    this.carregando = true;
    this.alcadasAprovacaoService.get(this.id).subscribe(res =>{
      this.alcadasAprovacao = new AlcadasAprovacao();
      this.alcadasAprovacao = res;
      this.carregando = false;
    },
      err =>
        this.utils.getErro(err)
    );
  }

  listar(){
    this.carregando = true;
    this.alcadasAprovacaoService.toList().subscribe(
      res => {
        this.listaAlcadasAprovacao = res;
        this.auxAlcadasAprovacao = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrar();
      },
      err =>{
        this.utils.getErro(err);
        this.carregando = false;
      }
    );
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.alcadasAprovacao.Id > 0)
      this.alcadasAprovacaoService.put(this.alcadasAprovacao.Id, this.alcadasAprovacao).subscribe(
        res => {
          this.toasty.show('Feito!', 'Grupo de Compra alterado!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/alcadas-aprovacao']);
        },
        err =>{
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
    else {
      this.alcadasAprovacao.EmpresaSelecionadaId = this.storageService.getEmpresa().Id;
      this.alcadasAprovacaoService.post(this.alcadasAprovacao).subscribe(
        res => {
          this.toasty.show('Feito!', 'Grupo de Compra cadastrado!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/alcadas-aprovacao']);
        },
        err =>{
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
    }

  }

  excluir(alcadasAprovacaoId: number) {
    Swal.fire({
      title: 'Deletar?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.alcadasAprovacaoService.delete(alcadasAprovacaoId).subscribe(
            res => {
              this.utils.handleSuccess('Alçada Aprovação excluída!');
              this.listar();
              this.carregando = false;
            },
            err =>{
              this.utils.getErro(err);
              this.carregando = false;
            }
          );
        }
      });
  }

  filtrar(){
    this.listaAlcadasAprovacao = this.auxAlcadasAprovacao.filter(x =>
      x.Id.toString().includes(this.textoBusca)
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/alcadas-aprovacao']);
  }

  listarUsuarios(){
    this.usuarioService.getUsuarios().subscribe({
      next: (res) => {
          this.listaUsuario = res;
          this.dicUsuario = {};
          res.forEach(objeto => {
            this.dicUsuario[objeto.Id] = objeto;
          });
      },
      error: (err) => {
        this.utils.getErro(err);
      },
      complete: () => {
        console.log('Busca usuario concluída.');
      }
    });
  }

  listarGrupoCompra() {
    this.grupoCompraService.toList().subscribe({
      next: (res) => {
          this.listaGrupoCompra = res;
          this.dicGrupoCompra  = {};
          res.forEach(objeto => {
            this.dicGrupoCompra[objeto.Id] = objeto;
          });
      },
      error: (err) => {
        this.utils.getErro(err);
      },
      complete: () => {
        console.log('Busca GrupoCompra concluída.');
      }
    });
  }

}
