export class TiposDiagnosePragas
{
    TipoDiagnoseId:string = "";
    EmpresaId: number;
    UsuarioNome:string = "";
    SafraId: number;
    AreaId: number;
    PragaNome:string = "";
    PragaNomeCientifico:string = "";
    IncidenciaPressao:string = "";
    Latitude: number | null;
    Longitude: number | null;
    Tipo: string | null;
    DataCadastro: Date = new Date();
}

