import { OrdemServicoModule } from './../ordem-servico/ordem-servico.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule, NgbCollapseModule, NgbDropdownModule, NgbTooltipModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { TextMaskModule } from 'angular2-text-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { RouterModule, Routes } from '@angular/router';
import { CustomFormsModule } from 'ng2-validation';
import { SharedModule } from './../../theme/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanejadoRealizadoComponent } from './planejado-realizado/planejado-realizado.component';
import { PlanejadoRealizaoGanttComponent } from './planejado-realizado/planejado-realizao-gantt/planejado-realizao-gantt.component';
import { ProdutividadeComponent } from './produtividade/produtividade.component';
import { HorasProdutivasParadasComponent } from './produtividade/horas-produtivas-paradas/horas-produtivas-paradas.component';
import { HorasParadasTipoImprodutividadeComponent } from './produtividade/horas-paradas-tipo-improdutividade/horas-paradas-tipo-improdutividade.component';
import {ChartModule} from 'angular2-chartjs';
import { CustoComponent } from './custo/custo.component';
import { CustoRealizadoAreaComponent } from './custo/custo-realizado-area/custo-realizado-area.component';
import { FimSafraComponent } from './fim-safra/fim-safra.component';
import { ResumoSafraComponent } from './resumo-safra/resumo-safra.component';
import { HistoricoAplicacaoEmailComponent } from './historico-aplicacao-email/historico-aplicacao-email.component';
import { RelatoriosComponent } from './relatorios-componente/relatorios.component';
import { SharedFarmModule } from '../shared-farm/shared-farm.module';
import { FiltroRelatoriosComponent } from './relatorios-componente/filtro-relatorios/filtro-relatorios.component';
import { RelatorioApontamentoIndicadoresComponent } from './relatorio-apontamento-indicadores/relatorio-apontamento-indicadores.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OSPlanejadaRealizadaComponent } from './OSPlanejadaRealizada/OSPlanejadaRealizada.component';
import { GridOSPlanejadaRealizadaComponent } from './OSPlanejadaRealizada/grid-OSPlanejadaRealizada/grid-OSPlanejadaRealizada.component';



@NgModule({
  declarations: [
    PlanejadoRealizadoComponent,
    PlanejadoRealizaoGanttComponent,
    ProdutividadeComponent,
    HorasProdutivasParadasComponent,
    HorasParadasTipoImprodutividadeComponent,
    CustoComponent,
    CustoRealizadoAreaComponent,
    FimSafraComponent,
    ResumoSafraComponent,
    HistoricoAplicacaoEmailComponent,
    RelatoriosComponent,
    FiltroRelatoriosComponent,
    RelatorioApontamentoIndicadoresComponent,
    OSPlanejadaRealizadaComponent,
    GridOSPlanejadaRealizadaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbTabsetModule,
    NgSelectModule,
    OrdemServicoModule,
    ChartModule,
    SharedFarmModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
    })
  ]
})
export class RelatoriosModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
