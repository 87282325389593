import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { StatusSafra } from 'src/app/classes/Safra';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { ItemPlanejamentoSafra } from 'src/app/classes/planejamento-safra/ItemPlanejamentoSafra';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-itens-planejamento-safra',
  templateUrl: './itens-planejamento-safra.component.html',
  styleUrls: ['./itens-planejamento-safra.component.scss']
})
export class ItensPlanejamentoSafraComponent implements OnInit {

  @ViewChild('Grid', { static: false }) grid: any;

  @Input() itensPlanejamento: ItemPlanejamentoSafra[] = [];
  @Input() carregando: boolean = true;

  @Output() itemSelecionado = new EventEmitter<ItemPlanejamentoSafra>();

  colDefs: ColDef[] = [];
  objSelecionado: ItemPlanejamentoSafra = new ItemPlanejamentoSafra();

  constructor(
    private utils: UtilsService,
  ) {

  }

  ngOnInit() {
    this.configurar();

  }

  configurar() {
    this.colDefs = [
      {
        width: 35,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'NomeArea', headerName: 'Nome Area', flex: 1 },
      { field: 'Hectares', headerName: 'Hectares', flex: 1 },
      { field: 'Variedade', headerName: 'Variedade', flex: 1 },
      {
        field: 'DataPlantio', headerName: 'Data Plantio', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarData(params.data.DataPlantio)}`
      },
      {
        field: 'ProducaoKg', headerName: 'Produção Kg', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.ProducaoKg)}`
      },
      {
        field: 'ProducaoSc', headerName: 'Produção Sc', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarDecimais(Math.floor(params.data.ProducaoKg / 60))}`
      },
      {
        field: 'ProducaoTon', headerName: 'Produção Ton', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.ProducaoKg / 1000)}`
      },
      {
        field: 'Status', headerName: 'Status Safra', flex: 1,
        valueFormatter: (params) => `${StatusSafra.find(s => s.value == params.data.Status).name}`
      },
    ];
  }

  onCellClicked({ data }) {
    this.objSelecionado = data;
    this.itemSelecionado.emit(this.objSelecionado);
  }
}
