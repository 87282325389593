export class PendenciaFrota {
  Id:string = "";
  EquipamentoId: number;
  DataAbertura: Date = new Date();
  DataConclusao?: Date = new Date();
  Prioridade: EnumPrioridade;
  Status: EnumStatusPendenciaFrota;
  Descricao:string = "";
  ManutencaoFrotaId:number;

  Anexos: AnexoPendenciaFrota[] = [];
}

export enum EnumPrioridade {
  Normal = 1,
  Critico = 2,
  Urgente = 3
}

export const ListaPrioridade = [
  { descricao: "Normal", valor: EnumPrioridade.Normal },
  { descricao: "Crítico", valor: EnumPrioridade.Critico },
  { descricao: "Urgente", valor: EnumPrioridade.Urgente }
];

export enum EnumStatusPendenciaFrota {
  Pendente = 1,
  EmAndamento = 2,
  Concluido = 3
}

export const ListaStatusPendenciaFrota = [
  { descricao: "Pendente", valor: EnumStatusPendenciaFrota.Pendente },
  { descricao: "Em Andamento", valor: EnumStatusPendenciaFrota.EmAndamento },
  { descricao: "Concluído", valor: EnumStatusPendenciaFrota.Concluido }
];

export class AnexoPendenciaFrota {
  Id:string = "";
  PendenciaFrotaId:string = "";
  Arquivo:string = "";
  UploadArquivo:string = "";
}
