import { Atividade } from "./Atividade";

export class Empresa {
    Id: number;
    RazaoSocial:string = "";
    NomeFantasia:string = "";
    CgcCpf?:string = "";
    ContatoNome?:string = "";
    ContatoTelefone?:string = "";
    ContatoEmail?:string = "";
    AreaTotal?: number = 0;
    Ativa:boolean = false;
    DataCadastro?:string = "";
    DiasParaOS?: number = 0;
    DiasParaAlerta?: number = 0;
    DataUltimoJobOS?: Date = new Date();
    QtdOsGerada?: number = 0;
    LogomarcaBase64?:string = "";

    ErpIntegrado?: enumErpIntegrado;
    GrupoPadraoId?: number = 0;
    AtividadePadraoId:number = 0;
    ControlaEstoque?: boolean;
    GeracaoOsAutomatica?: boolean;
    PermiteEstoqueNegativo?: boolean;
    UsaSetores?: boolean;
    UsaTarefas: boolean;
    UsaGestaoLite: boolean;
    PermiteVariasAtividadesPorEtapa: boolean;

    //Sankhya
    ServerSankhya?:string = "";
    UsuarioSankhya?:string = "";
    SenhaSankhya?:string = "";
    DataSincSankhya?: Date = new Date();
    CodParcSankhya?:string = "";
    CodTipOperSankhya?:string = "";
    CodTipVendaSankhya?:string = "";
    CodNatSankhya?:string = "";

    //TotvsRM
    ServerTotvsRm?:string = "";
    UsuarioTotvsRm?:string = "";
    SenhaTotvsRm?:string = "";
    CodSistemaTotvsRm?:string = "";
    CodColigadaTotvsRm?: number = 0;
    DataSincTotvsRm?: Date = new Date();
    SerieTotvsRM?:string = "";

    CodigoTipoMovimentoERP?:string = "";
    StatusMovimentoERP?:string = "";

    //Compras
    UsarSimilar:boolean = false;

    CasasDecimaisDose?:number = 3;
    UsaTelemetriaIrrigaPrime?:boolean = false;

    //Mascara
    MascaraNaturezaReceitaDespesa?:string = "";
    MascaraCentroResultado?:string = "";
    MascaraProjeto?:string = "";
    DecimaisQuantidade?:number = 2;
    DecimaisValor?:number = 2;

    Foto?:string = "";
    AgrupaPlanoComMesmoIntervalo:boolean = false;
    UsarIntervaloFixoPlanoManutencao:boolean = false;

    Moeda:string = "";
}


export enum enumErpIntegrado
{
    Nenhum = 0,
    Sankhya = 1,
    Totvs_RM = 2,
    Totvs_Protheus = 3,
    LinksisERP = 4
}

export enum enumMoeda {
  Real = 'Real',
  Dolar = 'Dólar'
}
