import { MovimentoEstoque } from './../classes/MovimentoEstoque';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltroMovimentoEstoque } from '../classes/movimento-estoque/FiltroMovimentoEstoque';
import { MovimentoEstoqueAvulso } from '../classes/movimento-estoque/MovimentoEstoqueAvulso';
import { FiltroMovimentoEstoqueAvulso } from '../classes/movimento-estoque/FiltroMovimentoEstoqueAvulso';


@Injectable({
  providedIn: 'root'
})
export class MovimentoEstoqueService {


  constructor(private http: HttpClient) { }

  getMovimentosEstoque(): Observable<any>{
    return this.http.get(`${environment.apiURL}/movimentoEstoque`)
  }

  getMovimentoEstoque(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/movimentoEstoque/${id}`)
  }

  getMovimentoEstoqueCompleto(id: number): Observable<MovimentoEstoque>{
    return this.http.get<MovimentoEstoque>(`${environment.apiURL}/movimentoEstoque/Completo/${id}`)
  }

  postMovimentoEstoque(movimentoEstoque: MovimentoEstoque): Observable<any>{
    return this.http.post(`${environment.apiURL}/movimentoEstoque`, movimentoEstoque);
  }
  MovimentoSrincronia(filtroMovimentoEstoque: FiltroMovimentoEstoque): Observable<any>{
    return this.http.post(`${environment.apiURL}/movimentoEstoque/MovimentoSrincronia`, filtroMovimentoEstoque);
  }
  RefazerApontamentoRM(movimentoId: number): Observable<any>{
    return this.http.post(`${environment.apiURL}/movimentoEstoque/RefazerApontamentoRM/${movimentoId}`, null);
  }
  RefazerApontamentoRMTodos(): Observable<any>{
    return this.http.post(`${environment.apiURL}/movimentoEstoque/RefazerApontamentoRM`, null);
  }

  inserirMovimentoCompleto(movimentoEstoque: MovimentoEstoque): Observable<any>{
    return this.http.post(`${environment.apiURL}/movimentoEstoque/inserirCompleto`, movimentoEstoque);
  }

  atualizaMovimentoCompleto(movimentoEstoque: MovimentoEstoque): Observable<any> {
    return this.http.put(`${environment.apiURL}/movimentoEstoque/atualizaCompleto`, movimentoEstoque);
  }

  putMovimentoEstoque(id: number, movimentoEstoque: MovimentoEstoque): Observable<any>{
    return this.http.put(`${environment.apiURL}/movimentoEstoque/${id}`, movimentoEstoque);
  }

  deleteMovimentoEstoque(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/movimentoEstoque/${id}`);
  }

  deleteMovimentoEstoqueCompleto(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/movimentoEstoque/Completo/${id}`);
  }

  listarPorLocalEstoque(localEstoqueId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/movimentoEstoque/localEstoque/${localEstoqueId}`)
  }

  listarEstoquesProdutos(filtros: any): Observable<any>{
    return this.http.post(`${environment.apiURL}/movimentoEstoque/estoqueProdutos`, filtros)
  }

  ValoresEmEstoque(): Observable<any>{
    return this.http.get(`${environment.apiURL}/movimentoEstoque/ValoresEmEstoque/`)
  }

  listarMovimentosEstoqueAvulso(filtro: FiltroMovimentoEstoqueAvulso): Observable<MovimentoEstoqueAvulso[] | null>{
    return this.http.post<MovimentoEstoqueAvulso[] | null>(`${environment.apiURL}/movimentoEstoque/Avulso`, filtro);
  }

  RefazerApontamentoRMApontamento(apontamentoId: number): Observable<any>{
    return this.http.post(`${environment.apiURL}/movimentoEstoque/RefazerApontamentoRM/apontamento/${apontamentoId}`, null);
  }
}
