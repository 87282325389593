import { Injectable } from '@angular/core';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';

@Injectable({
  providedIn: 'root'
})
export class CustomToastyService {

  toastOptions: ToastOptions = {
    title: 'title',
    msg: 'msg',
    showClose: false,
    timeout: 5000,
    theme: 'bootstrap',
    onAdd: (toast: ToastData) => {
      /* added */
    },
    onRemove: (toast: ToastData) => {
      /* removed */
    }
  };

  constructor(private toastyService: ToastyService) { }

  show(title: string, msg: string, type: string) {
    this.toastOptions.title = title;
    this.toastOptions.msg = msg;

    switch (type) {
      case 'default': this.toastyService.default(this.toastOptions); break;
      case 'info': this.toastyService.info(this.toastOptions); break;
      case 'success': this.toastyService.success(this.toastOptions); break;
      case 'wait': this.toastyService.wait(this.toastOptions); break;
      case 'error': this.toastyService.error(this.toastOptions); break;
      case 'warning': this.toastyService.warning(this.toastOptions); break;
    }
  }
}
