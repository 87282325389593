import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private elementRef: ElementRef, private renderer: Renderer2) {
  }
  @Input() item: any;
  @Input() rotaBase: string;
  @Output() nodeClick = new EventEmitter<any>();


  ngOnInit() {
    
  }

  selecionar(id){
    document.querySelectorAll(`.tree-link`).forEach(n => { n.classList.remove('selected')})
    document.getElementById(`link-item-${id}`).classList.add('selected');
  }
  toggleNodes(){
    var icon = document.getElementById(`icon-item-${this.item.Id}`);
    var children = document.querySelector(`#id-item-${this.item.Id} ul`);

    if(children.classList.contains('tree-hide')){
      children.classList.replace('tree-hide', 'tree-show');
      icon.classList.replace('folder-closed', 'folder');
    }
    else{
      children.classList.replace('tree-show', 'tree-hide');
      icon.classList.replace('folder', 'folder-closed');
    }
  }

  onClick() {
    if (this.rotaBase === "/cadastros/movimento") {
      if (this.item.IsAnalitico) {
        let id = this.item.Id;
        sessionStorage.setItem("ObjClickID", id);
      }
    }

    else {
      this.router.navigate([`${this.rotaBase}/${this.item.Id}`]);
      this.selecionar(this.item.Id);
    }
  }
}
