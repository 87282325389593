import { Pessoa } from '../Pessoa';
export class MovimentoCotacao {
    Titulo: String = "";
    CompradorId : number;
    DataLimite: Date = new Date();
    Observacao: string = "";
    FornecedoresId : number[] = [];
    Fornecedores : Pessoa[] = [];
    MovimentosId: number[] = [];
}
