import { Component, OnInit, ViewChild } from '@angular/core';
import { ManutencaoFrotaService } from 'src/app/services/manutencao-frota.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-modal-visualizar-plano-manutencao',
  templateUrl: './modal-visualizar-plano-manutencao.component.html',
  styleUrls: ['./modal-visualizar-plano-manutencao.component.scss']
})
export class ModalVisualizarPlanoManutencaoComponent implements OnInit {
  @ViewChild('Modal', { static: true }) modal;
  data: any = {};

  constructor(private service: ManutencaoFrotaService, private utils: UtilsService) { }

  ngOnInit() {
  }
  abrirModal(id: number){
    this.service.exibirPlanoManutencaoFrota(id)
    .subscribe(res => {
      this.data = res.Data;
      console.debug(res)
      this.modal.show();
    }, err => this.utils.getErro(err));
  }
  cancelar(): void {
    this.modal.hide();
  }

}
