import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { TreeViewComponent } from './tree-view.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [TreeViewComponent, TreeNodeComponent],
  exports: [TreeViewComponent, TreeNodeComponent],

})
export class TreeViewModule { }
