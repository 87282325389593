import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { Empresa } from '../classes/Empresa';
import { StorageService } from './storage.service';
import { enumPerfil } from '../classes/Usuario';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  private empresaSelecionada: Empresa;
  selecionarEmpresaEmitter: EventEmitter<any> = new EventEmitter();
  novaEmpresaEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getEmpresas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/empresas`)
  }

  getEmpresa(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/empresas/${id}`)
  }

  postEmpresa(empresa: Empresa): Observable<any>{
    return this.http.post(`${environment.apiURL}/empresas`, empresa);
  }

  putEmpresa(id: number, empresa: Empresa): Observable<any>{
    return this.http.put(`${environment.apiURL}/empresas/${id}`, empresa);
  }

  patchEmpresa(empresa: any): Observable<any>{
    return this.http.post(`${environment.apiURL}/empresas/AtualizarParametrosGerais`, empresa);
  }

  deleteEmpresa(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/empresas/${id}`);
  }

  setEmpresaSelecionada(empresa: Empresa){
    this.empresaSelecionada = empresa;

    this.storageService.setEmpresa(empresa);

    let usuario = this.storageService.getUsuario();

    if (usuario.PerfilInt == enumPerfil.Super || usuario.PerfilInt == enumPerfil.Master){
      usuario.PerfilInt = empresa.Id == 1 ? enumPerfil.Master : enumPerfil.Super;

      this.storageService.setUsuario(usuario);
    }

    this.selecionarEmpresaEmitter.emit({
      empresa: empresa
    });
  }

  getEmpresaSelecionada(): Empresa{
    return this.storageService.getEmpresa();
  }

  getEmpresasPorUsuarioId(id: number): Observable<EmpresasUsuarios[] | null> {
    return this.http.get<EmpresasUsuarios[]>(`${environment.apiURL}/empresas/usuarioId/${id}`);
  }
  getImportarEmpresas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/empresas/importarEmprsas`);
  }

}
