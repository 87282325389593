export class PermissaoUsuario {
  Id:number = 0;
  UsuarioId:number;
  AcessaModuloOperacoes: boolean = false;
  AcessoModuloCompras: boolean = false;
  AlterarParametrizacao: boolean = false;
  AcessoTodasFazendas: boolean = false;

  // os
  IncluirOS: boolean = false;
  DuplicarOS: boolean = false;
  EditarOS: boolean = false;
  ExcluirOS: boolean = false;
  LiberarOS: boolean = false;
  ConcluirOS: boolean = false;
  ReabrirOS: boolean = false;
  ReativarOS: boolean = false;
  CriarOSRetroativa: boolean = false;
  ApontarOS: boolean = false;
  ExcluirApontamentoOS: boolean = false;
  SubstituirProdutosOSPendentes: boolean = false;

  // cadastro funcionarios
  IncluirFuncionario: boolean = false;
  EditarFuncionario: boolean = false;
  ExcluirFuncionario: boolean = false;

  // grupo de equipamento
  IncluirGrupoEquipamento: boolean = false;
  EditarGrupoEquipamento: boolean = false;
  ExcluirGrupoEquipamento: boolean = false;

  // equipamentos
  IncluirEquipamento: boolean = false;
  EditarEquipamento: boolean = false;
  ExcluirEquipamento: boolean = false;

  // operacoes agricolas
  IncluirOperacoes: boolean = false;
  ExcluirOperacoes: boolean = false;
  IncluirMotivoParadas: boolean = false;
  EditarMotivoParadas: boolean = false;
  ExcluirMotivoParadas: boolean = false;

  // fazenda e areas
  IncluirFazenda: boolean = false;
  EditarFazenda: boolean = false;
  ExcluirFazenda: boolean = false;
  IncluirArea: boolean = false;
  EditarArea: boolean = false;
  ExcluirArea: boolean = false;
  ImportarMapaArea: boolean = false;
  DesenharMapaArea: boolean = false;

  // variedades
  IncluirVariedade: boolean = false;
  ExcluirVariedade: boolean = false;

  // grupo de produtos e produtos
  IncluirGrupoProduto: boolean = false;
  EditarGrupoProduto: boolean = false;
  ExcluirGrupoProduto: boolean = false;
  IncluirProduto: boolean = false;
  EditarProduto: boolean = false;
  ExcluirProduto: boolean = false;

  // tipos de aplicacao
  incluirTipoAplicacao: boolean = false;
  EditarTipoAplicacao: boolean = false;
  ExcluirTipoAplicacao: boolean = false;

  // plano agronomico
  VisualizarPlanoAgronomico: boolean = false;
  ConfigurarPlanoAgronomico: boolean = false;
  DuplicarPlanoAgronomico: boolean = false;
  ExcluirPlanoAgronomico: boolean = false;
  SubstituirProdutoPlano: boolean = false;

  // safras
  IncluirAnoSafra: boolean = false;
  EditarAnoSafra: boolean = false;
  ExcluirAnoSafra: boolean = false;
  IncluirSafra: boolean = false;
  EditarSafra: boolean = false;
  ExcluirSafra: boolean = false;
  ExcluirSafraInteira: boolean = false;
  AlterarStatusSafra: boolean = false;
  FinalizarSafra: boolean = false;
  AlterarSafraCriadaOutroUsuario: boolean = false;

  //compras
  UsarGrupoCompra: boolean = false;
  Comprador: boolean = false;
  AcessoSolicitacaoCompras: boolean = false;
  AcessoCotacao: boolean = false;
  AcessoPedidoCompras: boolean = false;
  AcessoTransacoes: boolean = false;
  PermiteEnviarCotacao: boolean = false;
  PermiteFinalizarCotacao: boolean = false;
  AcessoPedidoVendas: boolean = false;

}
