import { AgrupamentoOrdemServico } from './../../../classes/AgrupamentoOrdemServico';
import { TipoBico } from './../../../classes/TipoBico';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { GrupoOperacao } from './../../../classes/GrupoOperacao';
import { GrupoOperacaoService } from './../../../services/grupo-operacao.service';
import { CustomToastyService } from './../../../theme/shared/components/toasty/custom-toasty.service';
import { UiModalComponent } from './../../../theme/shared/components/modal/ui-modal/ui-modal.component';
import { Funcionario } from './../../../classes/Funcionario';
import { FuncionarioService } from './../../../services/funcionario.service';
import { TipoAplicacaoService } from './../../../services/tipo-aplicacao.service';
import { TipoAplicacao } from './../../../classes/TipoAplicacao';
import { AtividadeService } from './../../../services/atividade.service';
import { Atividade } from './../../../classes/Atividade';
import Swal from 'sweetalert2';
import { OrdemServicoService } from './../../../services/ordem-servico.service';
import { OrdemServico, EnumStatusOS } from './../../../classes/OrdemServico';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Equipamento } from 'src/app/classes/Equipamento';
import { ItemOrdemServico } from 'src/app/classes/ItemOrdemServico';
import { Produto } from 'src/app/classes/Produto';
import { Subject } from 'rxjs';
import { ProdutoService } from 'src/app/services/produto.service';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { ItemOrdemServicoService } from 'src/app/services/item-ordem-servico.service';
import { TipoBicoService } from 'src/app/services/tipo-bico.service';
import { ItemAgrupamentoOrdemServico } from 'src/app/classes/ItemAgrupamentoOrdemServico';
import { AgrupamentoOrdemServicoService } from 'src/app/services/agrupamento-ordem-servico.service';
import { environment } from 'src/environments/environment';
import { EmpresaService } from 'src/app/services/empresa.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-detalhamento',
  templateUrl: './detalhamento.component.html',
  styleUrls: ['./detalhamento.component.scss']
})
export class DetalhamentoComponent implements OnInit, AfterViewInit {

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;
  status: EnumStatusOS[] = [2];
  periodo: number = 1;
  OSsAvulsaId: string;
  carregando: boolean;
  recolherFiltro: boolean = false;

  ordensServico: OrdemServico[];
  auxOrdensServico: OrdemServico[];
  gruposOperacao: GrupoOperacao[];
  atividades: Atividade[];
  tiposAplicacao: TipoAplicacao[];
  funcionarios: Funcionario[];


  filtros: any;

  agruparPorFazenda: boolean;
  agruparPorSafra: boolean;
  agruparPorArea: boolean;
  agruparPorEtapa: boolean;

  isSubmit: boolean;
  idsModal: number[];
  confirmando: boolean;

  idOSAbrir: number = 0;

  //Agrupamento de OS
  areaPrevistoAgrupamento: number = 0;
  equipamentos: Equipamento[];
  implementos: Equipamento[];
  tiposBicos: TipoBico[];
  itensOrdemServico: ItemOrdemServico[];
  produtos: Produto[];
  gruposProdutos: GrupoProduto[];
  equipamentoSelecionado: Equipamento;
  implementoSelecionado: Equipamento;
  volumeCalda: number = 0;
  qtdTanques: number = 1;
  public isServico: boolean = false;
  areaPorTanque: number = 0;
  tipoBicoId: number = 0;
  vazao: number = 0;
  validarAgrupamento: boolean = true;

  //Select
  pagina: number = 2;
  termoBusca: String = '';
  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  public inputProduto$ = new Subject<string | null>();
  agrupamentoOrdemServico: AgrupamentoOrdemServico;

  acessoRapido: boolean = false;
  organizaPorSetor: boolean;
  casasDecimaisDose: number = 2;
  // tradução
  ERRO: string = 'Erro!';
  NENHUMA_ORDEM_DE_SERVICO_SELECIONADA: string = 'Nenhuma ordem de serviço selecionada!';
  FEITO: string = 'Feito!';
  ORDENS_DE_SERVICOS_CONFIRMADAS: string = 'Ordens de serviços confirmadas!';
  ATENCAO: string = 'Atenção!';
  SELECIONE__OU_MAIS_ORDENS_DE_SERVICO: string = 'Selecione 2 ou mais Ordens de Serviço';
  ORDEM_DE_SERVICO_NAO_ENCONTRADA: string = 'Ordem de serviço não encontrada!';

  constructor(
    private ordemServicoService: OrdemServicoService,
    private storageService: StorageService,
    private tipoAplicacaoService: TipoAplicacaoService,
    private funcionarioService: FuncionarioService,
    private grupoOperacaoService: GrupoOperacaoService,
    private atividadeService: AtividadeService,
    private toasty: CustomToastyService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private equipamentoService: EquipamentoService,
    private produtoService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private itemOrdemServicoService: ItemOrdemServicoService,
    private tipoBicoService: TipoBicoService,
    private agrupamentoService: AgrupamentoOrdemServicoService,
    private empresaService: EmpresaService,
    private utils: UtilsService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.route.params.subscribe(res => {
      let statusString: String = res.status;
      if (statusString && statusString != '') {
        this.status = [];
        statusString.split(',').forEach(s => {
          if(!Number.isNaN(parseInt(s)))
            this.status.push(parseInt(s));
        });
      }
      if (res.periodo)
        this.periodo = res.periodo;
    });
    this.route.queryParams.subscribe(params => {
      if(params['OSsAvulsaId'])
        this.OSsAvulsaId = params['OSsAvulsaId'];
    });
    this.inputProduto$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMore(newTerm);
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.casasDecimaisDose = this.storageService.getEmpresa().CasasDecimaisDose;
    this.grupoOperacaoService.getGruposOperacaoEmUso().subscribe(ret => {
      this.gruposOperacao = ret;
      this.atividadeService.getAtividadesParaUso().subscribe(ret => {
        this.atividades = ret;
      });
    });

    this.tipoAplicacaoService.getTiposAplicacao().subscribe(ret => {
      this.tiposAplicacao = ret;
    });

    this.funcionarioService.getFuncionarios().subscribe(ret => {
      this.funcionarios = ret;
    });

    if (this.OSsAvulsaId){
      this.ordemServicoService.detalhamento({OSsAvulsaId: this.OSsAvulsaId}).subscribe(
        res => {
          this.ordensServico = res;
          this.auxOrdensServico = res;
          this.organizaPorSetor = this.storageService.getEmpresa().UsaSetores;
          setTimeout(() => {
            this.acessoRapido = false;
          }, 300);
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else if (this.status || this.periodo)
      this.filtrar({
        Status: this.status,
        Periodo: this.periodo
      });

  }

  async filtrar(filtros: any) {
    this.filtros = filtros;
    this.ordensServico = [];
    this.carregando = true;
    this.agruparPorFazenda = filtros.AgruparPorFazenda;
    this.agruparPorSafra = filtros.AgruparPorSafra;
    this.agruparPorArea = filtros.AgruparPorArea;
    this.agruparPorEtapa = filtros.AgruparPorEtapa;

    try {
      const res = await this.ordemServicoService.detalhamento(filtros).toPromise();
      this.ordensServico = res;
      this.auxOrdensServico = res;
      this.organizaPorSetor = this.storageService.getEmpresa().UsaSetores;
      this.carregando = false;
    } catch (err) {
      this.utils.getErro(err);
      this.carregando = false;
    }
  }

  getAtividade(id: number): Atividade {
    if (!this.atividades)
      return new Atividade();

    let atividades = this.atividades.filter(x =>
      x.Id == id
    );
    return atividades.length > 0 ? atividades[0] : new Atividade();
  }

  getTipoAplicacao(id: number): TipoAplicacao {
    if (!this.tiposAplicacao)
      return new TipoAplicacao();

    let tiposAplicacao = this.tiposAplicacao.filter(x =>
      x.Id == id
    );

    return tiposAplicacao.length > 0 ? tiposAplicacao[0] : new TipoAplicacao();
  }

  getFuncionario(id: number): Funcionario {
    if (!this.funcionarios)
      return new Funcionario();

    let funcionarios = this.funcionarios.filter(x =>
      x.Id == id
    );

    return funcionarios.length > 0 ? funcionarios[0] : new Funcionario();
  }

  confirmar(ordemServico?: OrdemServico) {
    this.configurarTraducao();
    this.idsModal = [];
    if (ordemServico) {
      this.idsModal.push(ordemServico.Id);
    } else {
      this.ordensServico.filter(o => o.Checked).forEach(o => this.idsModal.push(o.Id));
    }

    if (this.idsModal.length == 0) {
      Swal.fire(this.ERRO, this.NENHUMA_ORDEM_DE_SERVICO_SELECIONADA, 'error');
      return;
    }

    this.carregando = true;
    this.ordemServicoService.confirmar(this.idsModal).subscribe(
      res => {
        this.ordensServico
          .filter(o => o.Checked)
          .forEach(o => {
            o.Status = EnumStatusOS.Pendente;
          });
        this.carregando = false;
        this.toasty.show(this.FEITO, this.ORDENS_DE_SERVICOS_CONFIRMADAS, 'success');
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  agrupar() {

    this.configurarTraducao();
    this.equipamentoSelecionado;
    this.carregando = true;
    this.idsModal = [];
    this.itensOrdemServico = [];
    this.areaPrevistoAgrupamento = 0;
    this.vazao = 0;
    this.qtdTanques = 0;
    this.areaPorTanque = 0;

    if (this.ordensServico.filter(o => o.Checked).length < 2) {
      this.toasty.show(this.ATENCAO, this.SELECIONE__OU_MAIS_ORDENS_DE_SERVICO, 'warning');
      this.carregando = false;
      return;
    }

    if (!this.equipamentos)
      this.equipamentoService.getEquipamentos().subscribe((ret: Equipamento[]) => {
        this.equipamentos = ret.filter(x => !x.FlagImplemento);
        this.implementos = ret.filter(x => x.FlagImplemento)
      });

    if (!this.tiposBicos)
      this.tipoBicoService.getTiposBicos().subscribe(ret => {
        this.tiposBicos = ret;
      });


    this.ordensServico.filter(o => o.Checked).forEach(o => {
      this.areaPrevistoAgrupamento += o.AreaPrevista;
      this.idsModal.push(o.Id)
      this.vazao += o.Vazao;
      this.areaPorTanque += o.AreaPorTanque;
      this.produtoService.getProdutosPaginandoOS(o.Id, 1, '', this.isServico).subscribe(ret => {
        ret.Lista.forEach(prod => {
          if (!this.produtos)
            this.produtos = [];
          if (!this.produtos.find(x => x.Id == prod.Id))
            this.produtos.push(prod);
        });

        this.grupoProdutoService.getGruposProduto().subscribe(retg => {
          this.gruposProdutos = retg;
          this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);

          this.itemOrdemServicoService.getItensOrdemServico(o.Id).subscribe(reti => {
            reti.forEach(it => {
              if (this.itensOrdemServico.find(x => x.ProdutoId == it.ProdutoId)) {
                this.itensOrdemServico.find(x => x.ProdutoId == it.ProdutoId).QtdPrevista += it.QtdPrevista;
                this.itensOrdemServico.find(x => x.ProdutoId == it.ProdutoId).AreaAplicacao += it.AreaAplicacao;
              }
              else
                this.itensOrdemServico.push(it);
            });
            this.itensOrdemServico = this.itensOrdemServico.sort((a, b) => a.Ordem - b.Ordem);
            this.carregando = false;
          });
        });
      });


    });

    this.Modal.show();
  }

  calculaTanques() {
    this.volumeCalda = 0;
    if (this.vazao == 0)
      return;
      if (this.equipamentoSelecionado) {
        this.volumeCalda = this.volumeCaldaCoalesce([this.equipamentoSelecionado, this.implementoSelecionado]);
        if(!this.volumeCalda){
          this.qtdTanques = 1;
          this.areaPorTanque = this.areaPrevistoAgrupamento;
        }
        else if (this.areaPrevistoAgrupamento > 0){
          this.qtdTanques = parseFloat((this.vazao  / this.volumeCalda * this.areaPrevistoAgrupamento).toFixed(this.casasDecimaisDose));
          this.areaPorTanque = this.areaPrevistoAgrupamento / this.qtdTanques;
        }
        for (let index = 0; index < this.itensOrdemServico.length; index++) {
          this.changeDose(this.itensOrdemServico[index]);
        }
      }

  }


  fechar() {
    this.equipamentoSelecionado;
    if (this.agrupamentoOrdemServico)
      this.filtrar(this.filtros);
    this.agrupamentoOrdemServico = new AgrupamentoOrdemServico();
    this.Modal.hide();
  }

  selecionarTodos() {
    this.ordensServico
      .filter(o => o.Status == EnumStatusOS.AguardandoLiberacao || o.Status == EnumStatusOS.Pendente)
      .forEach(o => o.Checked = true);
  }

  abrirOS(event, click: boolean) {
    this.configurarTraducao();
    if (!event || event.keyCode == 13 || click) {
      this.ordemServicoService.getOrdemServico(this.idOSAbrir).subscribe(res => {
        this.router.navigate(['/ordem-servico/ordem-servico/' + this.idOSAbrir]);
      },
        ex => {
          Swal.fire(this.ERRO, this.ORDEM_DE_SERVICO_NAO_ENCONTRADA, 'error');
        })
    }
  }

  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;
      this.produtoService.getProdutosPaginandoOS(0, this.pagina, this.termoBusca, this.isServico).subscribe(ret => {
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.produtos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    }, 300);
  }

  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }

  listarProdutos(osId: number) {
    this.produtoService.getProdutosPaginandoOS(osId, 1, '', this.isServico).subscribe(ret => {
      ret.Lista.forEach(prod => {
        if (!this.produtos)
          this.produtos = [];
        if (!this.produtos.find(x => x.Id == prod.Id))
          this.produtos.push(prod);
      });

      this.grupoProdutoService.getGruposProduto().subscribe(ret => {
        this.gruposProdutos = ret;
        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
        this.carregando = false;
      });
    });
  }

  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProdutos.filter(x =>
      x.Id == id
    );

    let novo = new GrupoProduto();

    return grupos.length > 0 ? grupos[0] : novo;
  }

  changeProduto(itemOS: ItemOrdemServico, idProduto: number) {
    if (itemOS.ProdutoId != idProduto) {
      itemOS.ProdutoId = idProduto;
      itemOS.PrecoUnitario = this.getProduto(itemOS.ProdutoId).PrecoUnitario;

      if (this.isServico && !itemOS.QtdAplicada)
        itemOS.QtdAplicada = 1;
    }

    itemOS.ValorTotal = itemOS.QtdAplicada * itemOS.PrecoUnitario;
    itemOS.QtdPrevista = itemOS.Dose * itemOS.AreaAplicacao;



    this.itemOrdemServicoService.calcDosePorTanque(itemOS.ProdutoId, itemOS.Dose, this.areaPorTanque).subscribe(res => {
      itemOS.DosePorTanqueCompleto = res;
    });
  }

  getProduto(id: number): Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';

    if (!this.produtos)
      return novo;

    let produto = this.produtos.filter(x =>
      x.Id == id
    );

    return produto.length > 0 ? produto[0] : novo;
  }

  changeDose(itemOS: ItemOrdemServico) {
    this.changeProduto(itemOS, itemOS.ProdutoId);
  }

  deleteItem(index: number) {
    let item = this.itensOrdemServico[index];

    if (item.Id < 0)
      this.itensOrdemServico.splice(index, 1);
    else
      this.itensOrdemServico[index].FlagExcluido = true;

    /*if (index > this.itensOrdemServico.filter(i => !i.FlagExcluido).length - 1)
      this.novoItem();*/
  }

  confirmarAgrupamento() {
    if(!this.equipamentoSelecionado) {
      this.utils.handleWarning('Selecione um equipamento!');
      return;
    }
    if(this.equipamentoSelecionado && !this.vazao) {
      this.utils.handleWarning('Defina uma Vazão!');
      return;
    }
    this.carregando = true;
    this.agrupamentoOrdemServico = new AgrupamentoOrdemServico();
    this.agrupamentoOrdemServico.IdsOrdemServico = "," + this.idsModal.map(String).toString() + ",";
    this.agrupamentoOrdemServico.EquipamentoId = this.equipamentoSelecionado.Id;
    this.agrupamentoOrdemServico.ImplementoId = this.implementoSelecionado ? this.implementoSelecionado.Id : 0;
    this.agrupamentoOrdemServico.TipoBicoId = this.tipoBicoId;
    this.agrupamentoOrdemServico.QtdTanques = this.qtdTanques;
    this.agrupamentoOrdemServico.Vazao = this.vazao;
    this.agrupamentoOrdemServico.ValidarAgrupamento = this.validarAgrupamento;
    let itensAgrupamentoOrdemServico: ItemAgrupamentoOrdemServico[] = [];

    this.itensOrdemServico.filter(x => !x.FlagExcluido).forEach(x => {
      let item: ItemAgrupamentoOrdemServico = new ItemAgrupamentoOrdemServico();
      item.ProdutoId = x.ProdutoId;
      item.Dose = x.Dose;
      item.DosePorTanque = x.DosePorTanqueCompleto;
      item.AreaAplicacao = x.AreaAplicacao;
      item.AreaReal = x.AreaReal;
      item.Quantidade = x.Quantidade;
      item.QtdRetirada = x.QtdRetirada;
      item.QtdRetorno = x.QtdRetorno;
      item.QtdAplicada = x.QtdAplicada;
      item.QtdPrevista = x.QtdPrevista;
      item.PrecoUnitario = x.PrecoUnitario;
      item.ValorTotal = x.ValorTotal;
      itensAgrupamentoOrdemServico.push(item);
    });

    this.agrupamentoService.postAgrupamentoOrdemServico(this.agrupamentoOrdemServico, itensAgrupamentoOrdemServico)
      .subscribe(ret => {
        this.agrupamentoOrdemServico = ret;
        this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    });

  }

  imprimirAgrupamento() {
    this.carregando = true;
    this.http.get(`${environment.apiURL}/ordemServico/agrupamento/gerarPDF/${this.empresaService.getEmpresaSelecionada().Id}/${this.agrupamentoOrdemServico.Id}`,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }

  BuscarOS(busca){
    this.ordensServico = this.auxOrdensServico.filter(x => x.Id.toString().includes(busca))
  }

  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.NENHUMA_ORDEM_DE_SERVICO_SELECIONADA = this.translate.instant('NENHUMA_ORDEM_DE_SERVICO_SELECIONADA');
    this.FEITO = this.translate.instant('FEITO');
    this.ORDENS_DE_SERVICOS_CONFIRMADAS = this.translate.instant('ORDENS_DE_SERVICOS_CONFIRMADAS');
    this.ATENCAO = this.translate.instant('ATENCAO');
    this.SELECIONE__OU_MAIS_ORDENS_DE_SERVICO = this.translate.instant('SELECIONE__OU_MAIS_ORDENS_DE_SERVICO');
    this.ORDEM_DE_SERVICO_NAO_ENCONTRADA = this.translate.instant('ORDEM_DE_SERVICO_NAO_ENCONTRADA');
  }

  private volumeCaldaCoalesce(arg: any[]) {
    for (let i = 0; i < arg.length; i++) {
        if (arg[i] && arg[i].DoseTanque) {
            return arg[i].DoseTanque;
        }
    }
    return 0;
  }
}
