import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Natureza } from 'src/app/classes/Natureza';
import { Projeto } from 'src/app/classes/Projeto';
import { TreeItem } from 'src/app/classes/TreeItem';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ProjetoService } from 'src/app/services/projeto.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-projeto',
  templateUrl: './projeto.component.html',
  styleUrls: ['./projeto.component.scss']
})
export class ProjetoComponent implements OnInit {

  constructor(
    private projetoService: ProjetoService,
    private storageService: StorageService,
    private utils: UtilsService,
    private empresaService: EmpresaService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe((res) => {
      this.id = res.id
      if (this.id > 0) {
        this.selecionar()
        this.mostrarForm = true
      }
    })
  }
  projetos: Projeto[]
  arvore: any[] = []
  items: TreeItem[] = []
  htmlTree: string
  carregando: boolean = false
  mostrarForm: boolean = false
  id: number
  projeto: Projeto = new Projeto()
  mostrarArvore: boolean = false
  mask: string = "00000000";

  ngOnInit() {
    this.empresaService.getEmpresa(this.storageService.getEmpresa().Id)
    .subscribe(res => {
      const mask = res.MascaraProjeto;
      this.mask = mask != null ? mask : this.mask;

    })
    if (this.router.url.search('inserir') > 0) {
      this.projeto = new Projeto();
      this.projeto.Inicial = new Date();
      this.projeto.Final = new Date();
      this.mostrarForm = true
    }
    this.atualizarArvore()
  }
  atualizarArvore() {
    this.items = [];
    this.mostrarArvore = false
    this.projetoService.getList().subscribe(
      (res) => {
        this.mostrarArvore = false
        this.carregando = true
        this.projetos = res
        this.projetos.forEach((n) => {
          let as: TreeItem = {
            Id: n.Id,
            Codigo: Number(n.Codigo),
            CodigoPai: n.CodigoPai,
            Descricao: n.Descricao,
            IsAnalitico: n.IsAnalitico,
          }
          this.items.push(as)
        })
        this.mostrarArvore = true
        this.carregando = false
      },
      (err) => {
        this.utils.getErro(err)
        this.carregando = false
      },
    )
  }
  selecionar() {
    this.projetoService.get(this.id).subscribe(
      (res) => {
        this.projeto = res
      },
      (err) => {
        this.utils.getErro(err)
      },
    )
  }

}
