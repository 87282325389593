import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';

import { ApontamentoProducao } from 'src/app/classes/planejamento-safra/ApontamentoProducao';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { UtilsService } from 'src/app/utils/utils.service';
import { EntradaProducaoService } from 'src/app/services/entrada-producao.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-apontamentos-producao',
  templateUrl: './apontamentos-producao.component.html',
  styleUrls: ['./apontamentos-producao.component.scss']
})
export class ApontamentosProducaoComponent implements OnInit {

  @ViewChild('Grid', { static: false }) grid: any;

  @Input() apontamentos: ApontamentoProducao[] = [];
  @Input() carregando: boolean = true;

  @Output() novoApontamento = new EventEmitter<boolean>();
  @Output() exibirAnexoApontamento = new EventEmitter<number>();
  @Output() editarApontamento = new EventEmitter<number>();
  @Output() apontamentoExcluido = new EventEmitter<void>();

  colDefs: ColDef[] = [];
  objSelecionado: ApontamentoProducao = null;
  acoes = [
    'Exibir Anexos',
  ]
  SELECIONE_APONTAMENTO: string = 'Selecione um apontamento'

  constructor(
    private entradaProducaoService: EntradaProducaoService,
    private translate: TranslateService,
    private utils: UtilsService,
    private toasty: CustomToastyService,
  ) { }

  ngOnInit() {
    this.configurar();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'Id', headerName: 'Id', flex: 1 },
      {
        field: 'Data', headerName: 'Data', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarData(params.data.Data)}`
      },
      { field: 'Produto', headerName: 'Produto', flex: 1 },
      { field: 'UnidadeMedida', headerName: 'Unidade Medida', flex: 1 },
      {
        field: 'QuantidadeBruta', headerName: 'Quantidade Bruta', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.QuantidadeBruta)}`
      },
      {
        field: 'QuantidadeDesconto', headerName: 'Quantidade Desconto', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.QuantidadeDesconto)}`
      },
      {
        field: 'QuantidadeLiquida', headerName: 'Quantidade Liquida', flex: 1,
        valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.QuantidadeLiquida)}`
      },
    ];
  }

  selectionChanged(event) {
    this.objSelecionado = event;
  }

  novo() {
    this.novoApontamento.emit(true);
  }

  excluir() {
    if (this.objSelecionado == null) {
      this.utils.handleInfo(this.SELECIONE_APONTAMENTO);
      return;
    }
    let APONTAMENTO = this.translate.instant("APONTAMENTO");
    let CONFIRMAR_DELETE = this.translate.instant("CONFIRMAR_DELETE");
    let CANCELAR = this.translate.instant("CANCELAR");
    let SIM = this.translate.instant("SIM");
    let APONTAMENTO_DELETADO = this.translate.instant("APONTAMENTO_DELETADO");
    let FEITO = this.translate.instant("FEITO");
    let DELETAR_PARAM = this.translate.instant("DELETAR_PARAM", { value: `${APONTAMENTO} Id: ${this.objSelecionado.Id}` });

    Swal.fire({
      title: DELETAR_PARAM,
      text: CONFIRMAR_DELETE,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: CANCELAR,
      confirmButtonText: SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.entradaProducaoService.deleteEntradaProducao(this.objSelecionado.Id)
          .subscribe(
            res => {
              this.toasty.show(FEITO, APONTAMENTO_DELETADO, 'success');
              this.apontamentoExcluido.emit();
            }
          )
      }
    });
  }

  editar(event) {
    this.editarApontamento.emit(event.data.Id);
  }

  acao(acao: string) {
    switch (acao) {
      case 'Exibir Anexos':
        this.exibirAnexo();
        break;
      default:
        break;
    }
  }

  exibirAnexo() {
    if (this.objSelecionado == null) {
      this.utils.handleInfo(this.SELECIONE_APONTAMENTO);
      return;
    }
    this.exibirAnexoApontamento.emit(this.objSelecionado.Id);
  }
}
