import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Equipamento } from 'src/app/classes/Equipamento';
import { PendenciaFrota } from 'src/app/classes/PendenciaFrota';

@Component({
  selector: 'app-modal-criar-pendencia',
  templateUrl: './modal-criar-pendencia.component.html',
  styleUrls: ['./modal-criar-pendencia.component.scss']
})
export class ModalCriarPendenciaComponent implements OnInit {
  @Output() voltarEvent = new EventEmitter<any>();

  @ViewChild('ModalCriarPendencia', { static: true }) modal;
  pendenciaFrota: PendenciaFrota = new PendenciaFrota();

  constructor(
  ) { }

  ngOnInit() {
  }

  abrirModal(pendenciaFrota: PendenciaFrota){
    this.pendenciaFrota = pendenciaFrota;
    this.modal.show();
  }
  cancelar(): void {
    this.modal.hide();
    this.voltarEvent.emit()
  }
}
