import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EnumTipoChecklist } from 'src/app/classes/Checklist';
import { Classe } from 'src/app/classes/Classe';
import { Fazenda } from 'src/app/classes/Fazenda';
import { GrupoEquipamento } from 'src/app/classes/GrupoEquipamento';
import { EnumOrigemManutencao, EnumStatusManutencao, EnumTipoIntervalo } from 'src/app/classes/gestao-lite/AuxManutencaoFrota';
import { ClasseService } from 'src/app/services/classe.service';
import { DashboardManutencaoService } from 'src/app/services/dashboard-manutencao.service';
import { FazendaService } from 'src/app/services/fazenda.service';
import { GrupoEquipamentoService } from 'src/app/services/grupo-equipamento.service';
import * as moment from 'moment';
import { EnumTipoRelatorioCustoManutencao, FiltrosCustoManutencao } from 'src/app/classes/dashboad-manutencao/FiltrosCustoManutencao';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-dashboard-manutencao',
  templateUrl: './dashboard-manutencao.component.html',
  styleUrls: ['./dashboard-manutencao.component.scss']
})
export class DashboardManutencaoComponent implements OnInit, AfterViewInit {

  showCustos = true;
  showPreventivas = true;
  showIndicadores = true;
  showOrdemServico = true;
  //campos dos filtros selecionados

  fazendas: Fazenda[] = [];
  fazendaSelect: [] = [];

  classes: Classe[] = [];
  classeSelect: [] = [];

  grupos: GrupoEquipamento[] = [];
  grupoSelect: [] = [];

  dataFim: Date = new Date();
  dataInicio: Date = new Date();

  //objetos de cada grafico

  //manutenções preventivas -- inicio
  carregandoStatusPreventivas = true;
  labelsStatusPreventivas: string[] = ["Em dia", "Em alerta", "Em atraso"];
  dadosStatusPreventivas: number[] = [];
  dadosStatusPreventivasTotal: number = 0;

  labelsStatusInspencoes: string[] = ["Em dia", "Em alerta", "Em atraso"];
  dadosStatusInspencoes: number[] = [];
  dadosStatusInspencoesTotal: number = 0;
  carregandoStatusInspencoes = true;

  categoriesPendencias: string[] = new Array<string>();
  dataPendencias: any[] = [];
  carregandoPendencias = true;
  //manutenções preventivas -- fim

  // custos -- inicio
  categoriesCustoManutencaoPorNAT: string[] = new Array<string>();
  dataCustoManutencaoPorNAT: any[] = [];
  carregandoCustoManutencaoPorNAT = true;

  categoriesCustoManutencaoProGrupoMaquina: string[] = new Array<string>();
  dataCustoManutencaoProGrupoMaquina: any[] = [];
  carregandoCustoManutencaoProGrupoMaquina = true;

  categoriesCustoManutencaoPorEquipamento: string[] = new Array<string>();
  dataCustoManutencaoPorEquipamento: any[] = [];
  carregandoCustoManutencaoPorEquipamento = true;

  custoMedioPreventiva: number = 0;
  custoMedioCorretiva: number = 0;
  custoMedioPreditiva: number = 0;
  custoMedioExterno: number = 0;
  carregandocustoMedio = true;
  // custos -- fim

  //indicadores -- inicio
  categoriesIndicadoresTempoManutencaoInterna: string[] = new Array<string>();
  dataIndicadoresTempoManutencaoInterna: any[] = [];
  carregandoIndicadoresTempoManutencaoInterna = true;

  categoriesIndicadoresTempoManutencaoExterna: string[] = new Array<string>();
  dataIndicadoresTempoManutencaoExterna: any[] = [];
  carregandoIndicadoresTempoManutencaoExterna = true;

  indicadoresChaveDisponibilidade: number = 0;
  indicadoresChaveMTTR_Pendencias: number = 0;
  indicadoresChaveMTTR_OS: number = 0;
  indicadoresChaveMTBF: number = 0;
  indicadoresChaveTempoInatividade: number = 0;
  carregandoindicadoresChave = true;
  //indicadores -- fim

  //ordens serviços -- inicio
  categoriesOrdensServicosPorTipo: string[] = new Array<string>();
  dataOrdensServicosPorTipo: any[] = [];
  carregandoOrdensServicosPorTipo = true;

  categoriesOrdensServicosPorGrupoMaquinas: string[] = new Array<string>();
  dataOrdensServicosPorGrupoMaquinas: any[] = [];
  carregandoOrdensServicosPorGrupoMaquinas = true;

  categoriesOrdensServicosPorSistema: string[] = new Array<string>();
  dataOrdensServicosPorSistema: any[] = [];
  carregandoOrdensServicosPorSistema = true;

  categoriesOrdensServicosPorCriticidade: string[] = new Array<string>();
  dataOrdensServicosPorCriticidade: any[] = [];
  carregandoOrdensServicosPorCriticidade = true;
  //ordens serviços -- fim

  minHeightPadraoGraficos: number = 250;
  constructor(private dashboardManutencaoService: DashboardManutencaoService,
    private fazendaService: FazendaService,
    private classeService: ClasseService,
    private grupoEquipamentoService: GrupoEquipamentoService,
    private utils: UtilsService
    ) { }

  ngOnInit() {
    this.carregarDadosFiltros();
    this.carregarGraficos();
  }

  ngAfterViewInit() {

  }

  carregarDadosFiltros() {
    this.fazendaService.getFazendas().subscribe(res => {
      this.fazendas = res
    }, err => this.utils.getErro(err));

    this.classeService.Listar().subscribe(res => {
      this.classes = res
    }, err => this.utils.getErro(err));

    this.grupoEquipamentoService.getGrupoEquipamentos().subscribe(res => {
      this.grupos = res
    }, err => this.utils.getErro(err));
  }

  carregarGraficos() {
    this.limparDadosGraficos();
    this.carregarGraficoStatusPreventivas();
    this.carregarGraficoStatusInspencoes();
    this.carregarGraficoPendencias();

    this.carregarGraficoCustoManutencaoPorNAT();
    this.carregarGraficoCustoManutencaoProGrupoMaquina();
    this.carregarGraficoCustoManutencaoPorEquipamento();
    this.carregarGraficoCustoManutencaoCustoMedioPorTipoOS();

    this.carregarGraficoIndicadoresChave();
    this.carregarGraficoIndicadoresTempoManutencaoInterna();
    this.carregarGraficoIndicadoresTempoManutencaoExterna();

    this.carregarOrdensServicosPorTipo();
    this.carregarOrdensServicosPorGrupoDeMaquinas();
    this.carregarOrdensServicosPorSistema();
    this.carregarOrdensServicosPorCriticidade();
  }

  montarFiltro(enumStatusManutencao: EnumStatusManutencao, enumTipoChecklist: EnumTipoChecklist, enumOrigemManutencao: EnumOrigemManutencao
    , enumTipoIntervalo: EnumTipoIntervalo, enumTipoRelatorioCustoManutencao: EnumTipoRelatorioCustoManutencao): FiltrosCustoManutencao {
    let filtrosCustoManutencao = new FiltrosCustoManutencao();

    filtrosCustoManutencao.empresaId = 0;
    filtrosCustoManutencao.status = enumStatusManutencao;
    filtrosCustoManutencao.tipo = enumTipoChecklist;
    filtrosCustoManutencao.origem = enumOrigemManutencao;
    filtrosCustoManutencao.tipoIntervalo = enumTipoIntervalo;
    filtrosCustoManutencao.dataInicial = this.dataInicio;
    filtrosCustoManutencao.dataFinal = this.dataFim;
    filtrosCustoManutencao.listaFazendas = this.fazendaSelect;
    filtrosCustoManutencao.listaClasses = this.classeSelect;
    filtrosCustoManutencao.listaGrupos = this.grupoSelect;
    filtrosCustoManutencao.tipoRelatorioCustoManutencao = enumTipoRelatorioCustoManutencao;

    return filtrosCustoManutencao;
  }

  carregarGraficoStatusPreventivas() {
    this.carregandoStatusPreventivas = true;
    this.dashboardManutencaoService.getBuscaPendencia2(0, EnumTipoChecklist.Preventivo, 0, 0, moment(this.dataInicio).format('YYYY-MM-DD'), moment(this.dataFim).format('YYYY-MM-DD'))
      .subscribe(res => {
        this.dadosStatusPreventivas.push(res.EmDias);
        this.dadosStatusPreventivas.push(res.Alerta);
        this.dadosStatusPreventivas.push(res.Atrasado);
        this.dadosStatusPreventivasTotal = res.EmDias + res.Alerta + res.Atrasado;
        this.carregandoStatusPreventivas = false;
      }, err => this.utils.getErro(err));
  }

  carregarGraficoStatusInspencoes() {
    this.carregandoStatusInspencoes = true;
    this.dashboardManutencaoService.getBuscaPendencia2(0, EnumTipoChecklist.Inspecao, 0, 0, moment(this.dataInicio).format('YYYY-MM-DD'), moment(this.dataFim).format('YYYY-MM-DD')).subscribe(res => {
      this.dadosStatusInspencoes.push(res.EmDias);
      this.dadosStatusInspencoes.push(res.Alerta);
      this.dadosStatusInspencoes.push(res.Atrasado);
      this.dadosStatusInspencoesTotal = res.EmDias + res.Alerta + res.Atrasado;
      this.carregandoStatusInspencoes = false;
    }, err => this.utils.getErro(err));
  }

  carregarGraficoPendencias() {
    this.carregandoPendencias = true;
    this.dashboardManutencaoService.getPrioridades().subscribe(res => {
      this.categoriesPendencias.push("Urgente");
      this.dataPendencias.push({ x: "", y: res.Urgente, fillColor: "#FABB5A" });

      this.categoriesPendencias.push("Critico");
      this.dataPendencias.push({ x: "", y: res.Critico, fillColor: "#F6554D" });

      this.categoriesPendencias.push("Normal");
      this.dataPendencias.push({ x: "", y: res.Normal, fillColor: "#142F64" });

      this.categoriesPendencias.push("Baixa");
      this.dataPendencias.push({ x: "", y: res.Baixo, fillColor: "#329B36" });

      this.carregandoPendencias = false;

    }, err => this.utils.getErro(err));
  }

  carregarGraficoCustoManutencaoPorNAT() {
    this.carregandoCustoManutencaoPorNAT = true;
    let filtro = this.montarFiltro(null, null, null, null, EnumTipoRelatorioCustoManutencao.NatTotal);

    this.dashboardManutencaoService.postCustoManutencao(filtro)
      .subscribe(res => {
        //custoManutencao returna uma tuple, onde Item1 é o titulo e Item2 é o valor
        if (res && res.Dados) {
          for (let i = 0; i < res.Dados.length; i++) {
            this.categoriesCustoManutencaoPorNAT.push(res.Dados[i].Item1);
            this.dataCustoManutencaoPorNAT.push({ x: "", y: res.Dados[i].Item2, fillColor: "#FABB5A" });
          }
        }

        this.carregandoCustoManutencaoPorNAT = false;
      }, err => this.utils.getErro(err));
  }

  carregarGraficoCustoManutencaoProGrupoMaquina() {
    this.carregandoCustoManutencaoProGrupoMaquina = true;
    let filtro = this.montarFiltro(null, null, null, null, EnumTipoRelatorioCustoManutencao.GrupoMaquinas);

    this.dashboardManutencaoService.postCustoManutencao(filtro)
      .subscribe(res => {
        //custoManutencao returna uma tuple, onde Item1 é o titulo e Item2 é o valor
        if (res && res.Dados) {
          for (let i = 0; i < res.Dados.length; i++) {
            this.categoriesCustoManutencaoProGrupoMaquina.push(res.Dados[i].Item1);
            this.dataCustoManutencaoProGrupoMaquina.push({ x: "", y: res.Dados[i].Item2, fillColor: "#FABB5A" });
          }
        }
        this.carregandoCustoManutencaoProGrupoMaquina = false;
      }, err => this.utils.getErro(err));
  }

  carregarGraficoCustoManutencaoPorEquipamento() {
    this.carregandoCustoManutencaoPorEquipamento = true;
    let filtro = this.montarFiltro(null, null, null, null, EnumTipoRelatorioCustoManutencao.Equipamentos);

    this.dashboardManutencaoService.postCustoManutencao(filtro)
      .subscribe(res => {
        //custoManutencao returna uma tuple, onde Item1 é o titulo e Item2 é o valor
        if (res && res.Dados) {
          for (let i = 0; i < res.Dados.length; i++) {
            this.categoriesCustoManutencaoPorEquipamento.push(res.Dados[i].Item1);
            this.dataCustoManutencaoPorEquipamento.push({ x: "", y: res.Dados[i].Item2, fillColor: "#FABB5A" });
          }
        }
        this.carregandoCustoManutencaoPorEquipamento = false;
      }, err => this.utils.getErro(err));
  }

  carregarGraficoCustoManutencaoCustoMedioPorTipoOS() {
    this.carregandocustoMedio = true;
    let filtro = this.montarFiltro(null, null, null, null, null);
    this.dashboardManutencaoService.postCustoManutencaoPorOs(filtro)
      .subscribe(res => {
        if (res) {
          this.custoMedioPreventiva = res.Preventiva;
          this.custoMedioCorretiva = res.Corretiva;
          this.custoMedioPreditiva = res.Preditiva;
          this.custoMedioExterno = res.Externa;
        }
        this.carregandocustoMedio = false;
      }, err => this.utils.getErro(err));
  }

  carregarGraficoIndicadoresTempoManutencaoInterna() {
    this.carregandoIndicadoresTempoManutencaoInterna = true;
    let filtro = this.montarFiltro(null, null, EnumOrigemManutencao.Interna, null, null);

    this.dashboardManutencaoService.postTempoManutencao(filtro)
      .subscribe(res => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            this.categoriesIndicadoresTempoManutencaoInterna.push(res[i].Titulo);
            this.dataIndicadoresTempoManutencaoInterna.push({ x: "", y: res[i].Tempo, fillColor: "#41D535" });
          }
        }
        this.categoriesIndicadoresTempoManutencaoInterna.push("teste");
        this.dataIndicadoresTempoManutencaoInterna.push({ x: "", y: 21, fillColor: "#41D535" });

        this.categoriesIndicadoresTempoManutencaoInterna.push("teste 2");
        this.dataIndicadoresTempoManutencaoInterna.push({ x: "", y: 25, fillColor: "#41D535" });

        this.categoriesIndicadoresTempoManutencaoInterna.push("teste 3");
        this.dataIndicadoresTempoManutencaoInterna.push({ x: "", y: 32, fillColor: "#41D535" });
        
        this.categoriesIndicadoresTempoManutencaoInterna.push("teste 4");
        this.dataIndicadoresTempoManutencaoInterna.push({ x: "", y: 13, fillColor: "#41D535" });

        this.carregandoIndicadoresTempoManutencaoInterna = false;
      }, err => this.utils.getErro(err));
  }

  carregarGraficoIndicadoresTempoManutencaoExterna() {
    this.carregandoIndicadoresTempoManutencaoExterna = true;
    let filtro = this.montarFiltro(null, null, EnumOrigemManutencao.Externa, null, null);

    this.dashboardManutencaoService.postTempoManutencao(filtro)
      .subscribe(res => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            this.categoriesIndicadoresTempoManutencaoExterna.push(res[i].Titulo);
            this.dataIndicadoresTempoManutencaoExterna.push({ x: "", y: res[i].Tempo, fillColor: "#41D535" });
          }
        }
        this.carregandoIndicadoresTempoManutencaoExterna = false;
      }, err => this.utils.getErro(err));
  }

  carregarGraficoIndicadoresChave() {
    this.carregandoindicadoresChave = true;
    let filtro = this.montarFiltro(null, null, null, null, null);
    this.dashboardManutencaoService.postIndicadoresOS(filtro)
      .subscribe(res => {
        if (res) {
          this.indicadoresChaveDisponibilidade = res.Disponibilidade;
          this.indicadoresChaveMTTR_Pendencias = res.MTTR_Pendencias;
          this.indicadoresChaveMTTR_OS = res.MTTR_OS;
          this.indicadoresChaveMTBF = res.MTBF;
          this.indicadoresChaveTempoInatividade = res.TempoInatividade;
        }
        this.carregandoindicadoresChave = false;
      }, err => this.utils.getErro(err));
  }

  carregarOrdensServicosPorTipo() {
    this.carregandoOrdensServicosPorTipo = true;
    let filtro = this.montarFiltro(null, null, null, null, null);

    this.dashboardManutencaoService.postQuantidadeOSAberta(filtro)
      .subscribe(res => {
        if (res) {
          this.categoriesOrdensServicosPorTipo.push("Corretiva");
          this.dataOrdensServicosPorTipo.push({ x: "", y: res.Corretiva, fillColor: "#003B80" });

          this.categoriesOrdensServicosPorTipo.push("Preventiva");
          this.dataOrdensServicosPorTipo.push({ x: "", y: res.Preventiva, fillColor: "#003B80" });

          this.categoriesOrdensServicosPorTipo.push("Preditiva");
          this.dataOrdensServicosPorTipo.push({ x: "", y: res.Preditiva, fillColor: "#003B80" });

          this.categoriesOrdensServicosPorTipo.push("Externa");
          this.dataOrdensServicosPorTipo.push({ x: "", y: res.Externa, fillColor: "#003B80" });
        }
        this.carregandoOrdensServicosPorTipo = false;
      }, err => this.utils.getErro(err));
  }

  carregarOrdensServicosPorGrupoDeMaquinas() {
    this.carregandoOrdensServicosPorGrupoMaquinas = true;
    let filtro = this.montarFiltro(null, null, null, null, null);

    this.dashboardManutencaoService.postNumeroOSPorGrupoMaquina(filtro)
      .subscribe(res => {
        if (res && res.Dados) {
          for (let i = 0; i < res.Dados.length; i++) {
            this.categoriesOrdensServicosPorGrupoMaquinas.push(res.Dados[i].Item1);
            this.dataOrdensServicosPorGrupoMaquinas.push({ x: "", y: res.Dados[i].Item2, fillColor: "#003B80" });
          }
        }
        this.carregandoOrdensServicosPorGrupoMaquinas = false;
      }, err => this.utils.getErro(err));
  }

  carregarOrdensServicosPorSistema() {
    this.carregandoOrdensServicosPorSistema = true;
    let filtro = this.montarFiltro(null, null, null, null, null);

    this.dashboardManutencaoService.postQuantidadeOsPorSistema(filtro)
      .subscribe(res => {
        if (res && res.Dados) {
          for (let i = 0; i < res.Dados.length; i++) {
            this.categoriesOrdensServicosPorSistema.push(res.Dados[i].Item1);
            this.dataOrdensServicosPorSistema.push({ x: "", y: res.Dados[i].Item2, fillColor: "#003B80" });
          }
        }
        this.carregandoOrdensServicosPorSistema = false;
      }, err => this.utils.getErro(err));
  }

  carregarOrdensServicosPorCriticidade() {
    this.carregandoOrdensServicosPorCriticidade = true;
    let filtro = this.montarFiltro(null, null, null, null, null);

    this.dashboardManutencaoService.postOSPorCriticidade(filtro)
      .subscribe(res => {
        if (res) {
          this.categoriesOrdensServicosPorCriticidade.push("Urgente");
          this.dataOrdensServicosPorCriticidade.push({ x: "", y: res.Urgente, fillColor: "#FABB5A" });

          this.categoriesOrdensServicosPorCriticidade.push("Critico");
          this.dataOrdensServicosPorCriticidade.push({ x: "", y: res.Critico, fillColor: "#F6554D" });

          this.categoriesOrdensServicosPorCriticidade.push("Normal");
          this.dataOrdensServicosPorCriticidade.push({ x: "", y: res.Normal, fillColor: "#142F64" });

          this.categoriesOrdensServicosPorCriticidade.push("Baixo");
          this.dataOrdensServicosPorCriticidade.push({ x: "", y: res.Baixo, fillColor: "#329B36" });
        }
        this.carregandoOrdensServicosPorCriticidade = false;
      }, err => this.utils.getErro(err));
  }

  limparDadosGraficos() {
    //manutenções preventivas -- inicio
    this.carregandoStatusPreventivas = true;
    this.dadosStatusPreventivas = [];
    this.dadosStatusPreventivasTotal = 0;

    this.dadosStatusInspencoes = [];
    this.dadosStatusInspencoesTotal = 0;
    this.carregandoStatusInspencoes = true;

    this.categoriesPendencias = new Array<string>();
    this.dataPendencias = [];
    this.carregandoPendencias = true;
    //manutenções preventivas -- fim

    // custos -- inicio
    this.categoriesCustoManutencaoPorNAT = new Array<string>();
    this.dataCustoManutencaoPorNAT = [];
    this.carregandoCustoManutencaoPorNAT = true;

    this.categoriesCustoManutencaoProGrupoMaquina = new Array<string>();
    this.dataCustoManutencaoProGrupoMaquina = [];
    this.carregandoCustoManutencaoProGrupoMaquina = true;

    this.categoriesCustoManutencaoPorEquipamento = new Array<string>();
    this.dataCustoManutencaoPorEquipamento = [];
    this.carregandoCustoManutencaoPorEquipamento = true;

    this.custoMedioPreventiva = 0;
    this.custoMedioCorretiva = 0;
    this.custoMedioPreditiva = 0;
    this.custoMedioExterno = 0;
    this.carregandocustoMedio = true;
    // custos -- fim

    //indicadores -- inicio
    this.categoriesIndicadoresTempoManutencaoInterna = new Array<string>();
    this.dataIndicadoresTempoManutencaoInterna = [];
    this.carregandoIndicadoresTempoManutencaoInterna = true;

    this.categoriesIndicadoresTempoManutencaoExterna = new Array<string>();
    this.dataIndicadoresTempoManutencaoExterna = [];
    this.carregandoIndicadoresTempoManutencaoExterna = true;

    this.indicadoresChaveDisponibilidade = 0;
    this.indicadoresChaveMTTR_Pendencias = 0;
    this.indicadoresChaveMTTR_OS = 0;
    this.indicadoresChaveMTBF = 0;
    this.indicadoresChaveTempoInatividade = 0;
    this.carregandoindicadoresChave = true;
    //indicadores -- fim

    //ordens serviços -- inicio
    this.categoriesOrdensServicosPorTipo = new Array<string>();
    this.dataOrdensServicosPorTipo = [];
    this.carregandoOrdensServicosPorTipo = true;

    this.categoriesOrdensServicosPorGrupoMaquinas = new Array<string>();
    this.dataOrdensServicosPorGrupoMaquinas = [];
    this.carregandoOrdensServicosPorGrupoMaquinas = true;

    this.categoriesOrdensServicosPorSistema = new Array<string>();
    this.dataOrdensServicosPorSistema = [];
    this.carregandoOrdensServicosPorSistema = true;

    this.categoriesOrdensServicosPorCriticidade = new Array<string>();
    this.dataOrdensServicosPorCriticidade = [];
    this.carregandoOrdensServicosPorCriticidade = true;
    //ordens serviços -- fim
  }

  listar(data) {
    console.log(data);
  }

  listarInspencoes(data) {
    console.log(data);
  }

  changeFazenda(fazenda) {
    this.carregarGraficos();
  }

  changeClasse(classe) {
    this.carregarGraficos();
  }

  changeGrupo(grupo) {
    this.carregarGraficos();
  }

  changeDataInicio(dataInicio) {
    this.dataInicio = dataInicio;
    this.carregarGraficos();
  }

  changeDataFim(dataFim) {
    this.dataFim = dataFim;
    this.carregarGraficos();
  }
}
