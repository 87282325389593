import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Area } from 'src/app/classes/Area';
import { AreaService } from 'src/app/services/area.service';

@Component({
  selector: 'app-select-area',
  templateUrl: './select-area.component.html',
  styleUrls: ['./select-area.component.scss']
})
export class SelectAreaComponent implements OnInit {
  @Input() isSubmit: boolean;
  @Input() fazendaId: number = 0;
  @Input() areaId: number = 0;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() auxClass: string;
  @Input() ocultarMsgObrigatorio: boolean = false;
  @Output() areaChange: EventEmitter<number> = new EventEmitter();
  @Input() areas: Area[];

  constructor(private areaService: AreaService) { }

  ngOnInit() {
    if(!this.areas && this.fazendaId > 0)
      this.areaService.getAreasPorFazenda(this.fazendaId).subscribe(ret =>{
        this.areas = ret;
      });
  }

  onChange(){   
    this.areaChange.emit(this.areaId);
  }

  filtrarArea(term: string, item: Area) {
    term = term.toLowerCase();
    return item.Descricao.toLowerCase().indexOf(term) > -1;
  }

}
