import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import cep from 'cep-promise';
import { Endereco } from 'src/app/classes/Endereco';

@Component({
  selector: 'app-endereco-form-interno',
  templateUrl: './endereco-form-interno.component.html',
  styleUrls: ['./endereco-form-interno.component.scss']
})
export class EnderecoFormInternoComponent implements OnInit {

  @Input() endereco: Endereco;

  @Input() id: number = 0;
  @Input() empresaInternaId: number = 0;
  @Input() pessoaId: number = 0;
  @Input() inserindo: boolean = false;
  @Input() editando: boolean = false;
  principal: boolean = true;

  carregando: boolean = false;
  isSubmit: boolean;
  touched = false;

  meuForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder) {
    this.meuForm = this.formBuilder.group({
      Id:[0],
      Descricao: [''],
      CEP: ['', Validators.required],
      UF: ['', Validators.required],
      Cidade: ['', Validators.required],
      Bairro: ['', Validators.required],
      Logradouro: ['', Validators.required],
      Numero: [''],
      Complemento: [''],
      Referencia: [''],
      Tipo: [1],
      PessoaId: [],
      EmpresaInternaId: []
    });
  }

  ngOnInit() {

  }

  buscaCep() {
    cep(this.meuForm.value.CEP)
    .then(res => {
          this.meuForm.patchValue({
          UF: res.state,
          Cidade: res.city,
          Bairro:  res.neighborhood,
          Logradouro: res.street,
        });
      }
    ).catch();
  }

  atualizarEndereco(endereco: Endereco){
    console.debug('stoy aki', endereco)
    if(endereco && endereco.CEP)
    this.meuForm = this.formBuilder.group({
      Id:[0],
      Descricao: [endereco.Descricao],
      CEP: [endereco.CEP, Validators.required],
      UF: [endereco.UF, Validators.required],
      Cidade: [endereco.Cidade, Validators.required],
      Bairro: [endereco.Bairro, Validators.required],
      Logradouro: [endereco.Logradouro, Validators.required],
      Numero: [endereco.Numero],
      Complemento: [endereco.Complemento],
      Referencia: [endereco.Referencia],
      Tipo: [1],
      PessoaId: [endereco.PessoaId],
      EmpresaInternaId: []
    });
  }

}
