import { FornecedoresService } from './../../../services/cotacao/Fornecedores.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recuperar-senha-fornecedor',
  templateUrl: './recuperar-senha-fornecedor.component.html',
  styleUrls: ['./recuperar-senha-fornecedor.component.scss']
})
export class RecuperarSenhaFornecedorComponent implements OnInit {
  erros: string;
  carregando: boolean = false;
  chave: string;
  constructor(private FornecedoresService: FornecedoresService,
    private router: Router) { }

  ngOnInit() {
  }
  login() {
    this.carregando = true;
    this.FornecedoresService.recuperarSenha(this.chave).subscribe(
      data => {
        this.carregando = false;
        this.router.navigate(["/alterarsenhafornecedor"]);
      },
      ops => {
        this.carregando = false;
        this.erros = ops.error;
        if (ops.Message)
          this.erros = ops.Message;
      }
    );
  }

  onKeyDown(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
}
