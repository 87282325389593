import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AreaUltimaModificacao } from 'src/app/classes/tipos-diagnose/AreaUltimaModificacao';
import * as _ from 'lodash';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss']
})
export class AsideMenuComponent implements OnInit {

  @Input() areasUltimaModificacao: AreaUltimaModificacao[];
  @Output() areaSelecionada = new EventEmitter<number>();

  areasUltimaModificacaoOrdenado: AreaUltimaModificacao[];

  constructor() { }

  ngOnInit() {
    this.ordenarAreas();
  }

  selecionarArea(areaId: number) {
    this.areaSelecionada.emit(areaId);
  }

  private ordenarAreas() {
    this.areasUltimaModificacaoOrdenado = _.orderBy(this.areasUltimaModificacao, (item) => this.pegarData(item.UltimaAtualizacao), ['desc']);
  }

  private pegarData(data: Date): string {
    if (data == null) return new Date(99999999).toLocaleString('pt-BR');
    return data.toLocaleString('pt-BR');
  }
}
