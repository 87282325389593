import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FiltroVwAplicacoesLavoura } from 'src/app/classes/FiltroVwAplicacoesLavoura';
import { VwAplicacoesLavoura } from 'src/app/classes/VwAplicacoesLavoura';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { VwAplicacoesLavouraService } from 'src/app/services/VwAplicacoesLavoura.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-grid-OSPlanejadaRealizada',
  templateUrl: './grid-OSPlanejadaRealizada.component.html',
  styleUrls: ['./grid-OSPlanejadaRealizada.component.scss']
})
export class GridOSPlanejadaRealizadaComponent implements OnInit {
  @Input() vwAplicacoesLavoura: VwAplicacoesLavoura[];
  @Input() filtro: FiltroVwAplicacoesLavoura;
  @Input() refres: boolean = false;
  @Output() toggleFilter = new EventEmitter<any>();
  @Output() imprimirEvent = new EventEmitter<any>();

  constructor(private router: Router,
    private translate: TranslateService,
    private utils: UtilsService,
    private vwAplicacoesLavouraService: VwAplicacoesLavouraService
  ) { }
  @ViewChild('Grid', { static: false }) grid: any;
  colDefs: ColDef[] = [
    {
      width: 45,
      lockPosition: true,
      lockVisible: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {field: 'Id', headerName: '#', flex: 1},
    {field: 'NomeFazenda', headerName: 'Fazenda', flex: 1},
    {field: 'Safra', headerName: 'Safra', flex: 1 },
    {field: 'GrupoProduto', headerName: 'Grupo de Produto', flex: 1},
    {field: 'DAP', headerName: 'DAP', flex: 1},
    {field: 'Produto', headerName: 'Produto', flex: 1 },
    {field: 'UnidadeMedida', headerName: 'Unidade de Medida', flex: 1},
    {field: 'NomeArea', headerName: 'Área', flex: 1 },
    {field: 'QtdPlanejada', headerName: 'Qtd Planejada', flex: 1},
    {field: 'QtdUtilizada', headerName: 'Qtd Utilizada', flex: 1},
    {field: 'Dose', headerName: 'Qtd Dose', flex: 1},
    {field: 'PrecoUnitario', headerName: 'Preço Unitário', flex: 1},
    {field: 'PrecoTotal', headerName: 'Preço Total', flex: 1},
    {field: 'HectareAplicacao', headerName: 'Hectare de Aplicação', flex: 1},
    {field: 'HectarePlantado', headerName: 'Hectare Plantado', flex: 1},
    {field: 'DataInicioPrevistaOS', headerName: 'Data Inicio Previsto', flex: 1},
    {field: 'DataInicioRealOS', headerName: 'Data Inicio Real', flex: 1},
    {field: 'DataFinalPrevistaOS', headerName: 'Data Final Prevista', flex: 1},
    {field: 'DataFinalRealOS', headerName: 'Data Final Real', flex: 1},
    {field: 'DataCriacao', headerName: 'Data de Criação', flex: 1}
  ];
  objSelecionado: VwAplicacoesLavoura = new VwAplicacoesLavoura();
  objSelecionadoId: number = 0;
  objetosSelecionados: VwAplicacoesLavoura[] = [];
  ngOnInit() {
    this.carregar();
  }

  carregar()
  {
    if(this.filtro == null || this.filtro == undefined || typeof this.filtro == 'string' )
      this.filtro = new FiltroVwAplicacoesLavoura();
    this.vwAplicacoesLavouraService.get(this.filtro).subscribe((res) => {
      this.vwAplicacoesLavoura = res;
    }, (error) => {
      this.utils.getErro(error);
    });
  }

  novo() {

  }

  cellDoubleClicked(event) {

  }

  onCellClicked(row) {

  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {
  }

  filtrar(event) {
      this.toggleFilter.emit(event);
  }
  imprimir(event) {
    this.imprimirEvent.emit(event);
  }
}
