import { TipoImprodutividade } from 'src/app/classes/TipoImprodutividade';
import { ImprodutividadeEquipamento } from 'src/app/classes/ImprodutividadeEquipamento';
import { TipoImprodutividadeService } from 'src/app/services/tipo-improdutividade.service';
import { ImprodutividadeEquipamentoService } from 'src/app/services/improdutividade-equipamento.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Equipamento } from 'src/app/classes/Equipamento';
import { EquipamentoApontamento } from 'src/app/classes/EquipamentoApontamento';
import { Funcionario } from 'src/app/classes/Funcionario';
import { EquipamentoApontamentoService } from 'src/app/services/equipamento-apontamento.service';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { FuncionarioService } from 'src/app/services/funcionario.service';

interface resultadoGrafico {
  nome: string;
  valor: number;
}

@Component({
  selector: 'app-horas-paradas-tipo-improdutividade',
  templateUrl: './horas-paradas-tipo-improdutividade.component.html',
  styleUrls: ['./horas-paradas-tipo-improdutividade.component.scss']
})


export class HorasParadasTipoImprodutividadeComponent implements OnInit {
  equipamentosApontamento: EquipamentoApontamento[];
  improdutividadesEquipamento: ImprodutividadeEquipamento[]
  tiposImprodutividade: TipoImprodutividade[] = [];
  funcionarios: Funcionario[];
  equipamentos: Equipamento[];
  @Input() funcionarioId:number;
  @Input() equipamentoId:number;
  @Input() implementoId:number;
  totalTrabalhadas: number = 0;
  totalParadas:number;
  resultados: resultadoGrafico[];

  carregando: boolean;

  public barBasicChartData: any;
  public barBasicChartOption: any;
  @ViewChild('barBasicChart', {static: false}) barBasicChart: ElementRef; // used barStackedChart, barHorizontalChart
  public barBasicChartTag: CanvasRenderingContext2D;

  public options: any = {
    legend: { position: 'bottom' }
  }

  constructor(private equipamentoApontamentoService: EquipamentoApontamentoService,
              private improdutividadeEquipamentoService: ImprodutividadeEquipamentoService,
              private tipoImprodutividadeService: TipoImprodutividadeService,
              private funcionarioService: FuncionarioService,
              private equipamentoService: EquipamentoService) { }

  ngOnInit() {
    if(!this.funcionarioId)
      this.funcionarioId = 0;
    if(!this.equipamentoId)
      this.equipamentoId = 0;
    if(!this.implementoId)
      this.implementoId = 0;

    this.tipoImprodutividadeService.getTiposImprodutividade().subscribe(ret => {
      this.tiposImprodutividade = ret;
      this.calcular();
    })
  }

  getTipoImprodutividade(id: number): TipoImprodutividade{
    return this.tiposImprodutividade.find(x => x.Id == id);
  }

  calcular(){
    let descricoes:string[] = [];
    let valores:number[] =[];
    this.improdutividadesEquipamento = [];
    this.carregando = true;
    this.totalParadas = 0;
    this.totalTrabalhadas = 0;
    this.equipamentoApontamentoService.getEquipamentosApontamentoFiltro(this.funcionarioId, this.equipamentoId, this.implementoId).subscribe(ret=>{
      this.equipamentosApontamento = ret;
      this.equipamentosApontamento.forEach(x =>{
        this.totalParadas += (x.ImprodutividadeTotal);
        this.totalTrabalhadas += x.HorasTrabalhadas;
        this.improdutividadeEquipamentoService.getImprodutividadesEquipamento(x.Id).subscribe(imp =>{
          let improdutividades: ImprodutividadeEquipamento[] = imp;
          improdutividades.forEach(item => {
            this.improdutividadesEquipamento.push(item);
          });
        })
      })
    })

    this.tiposImprodutividade.forEach(tipo=>{
      let totalImprodutividade = 0;
      this.improdutividadesEquipamento.forEach(imp=> {
        if(imp.TipoImprodutividadeId == tipo.Id)
          if(imp.HorimetroInicial > 0)
            totalImprodutividade += imp.HorimetroFinal - imp.HorimetroInicial;
          else{
            totalImprodutividade += (new Date(imp.HoraFim).getTime() - new Date(imp.HoraInicio).getTime());
        }
      })
      descricoes.push(tipo.Descricao);
      valores.push(Math.floor(Math.random()*(20+1)));
    })

    setTimeout(()=>{
      /* bar basic chart */
        const barBasicTag = (((this.barBasicChart.nativeElement as HTMLCanvasElement).children));
        this.barBasicChartTag = ((barBasicTag['bar_basic_chart']).lastChild).getContext('2d');
        // used bar_stacked_chart, bar_horizontal_chart
        const abc = (this.barBasicChartTag).createLinearGradient(0, 300, 0, 0);
        abc.addColorStop(0, '#4680ff');
        abc.addColorStop(1, '#4680ff');
        const def = (this.barBasicChartTag).createLinearGradient(0, 300, 0, 0);
        def.addColorStop(0, '#0e9e4a');
        def.addColorStop(1, '#0e9e4a');

        this.barBasicChartData = {
          labels: descricoes,
          datasets: [{
            label: 'Tipos de Improdutividade',
            data: valores,
            borderColor: abc,
            backgroundColor: abc,
            hoverborderColor: abc,
            hoverBackgroundColor: abc,
          }]
        };

        this.barBasicChartOption = {
          barValueSpacing: 20,
          legend: { position: 'bottom' }

        };
      }, 1000)

  }

}
