import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AtividadePlanejamento } from '../classes/AtividadePlanejamento';

@Injectable({
  providedIn: 'root'
})
export class AtividadePlanejamentoService {

  constructor(private http: HttpClient) { }

  getAtividadesPorPlanejamentos(planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividadePlanejamento/listar/${planejamentoId}`)
  }

  getAtividadesPorPlanejamentosComColheita(planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividadePlanejamento/listarColheita/${planejamentoId}`)
  }
  
  getAtividadePlanejamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividadePlanejamento/${id}`)
  }

  postAtividadePlanejamento(atividadePlanejamento: AtividadePlanejamento): Observable<any>{
    return this.http.post(`${environment.apiURL}/atividadePlanejamento`, atividadePlanejamento);
  }

  putAtividadePlanejamento(id: number, atividadePlanejamento: AtividadePlanejamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/atividadePlanejamento/${id}`, atividadePlanejamento);
  }

  deleteAtividadePlanejamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/atividadePlanejamento/${id}`);
  }

  alterarDAPPorEtapa(planejamentoId: number, etapaId: number, novaEtapaId: number, dap: number, dapNovo: number, estadioFenologicoId: number, processoPlanejamento: number, referenciaDAP: number): Observable<any>{
    return this.http.put(`${environment.apiURL}/atividadePlanejamento/alterarDap/${planejamentoId}/${etapaId}/${novaEtapaId}/${dap}/${dapNovo}/${estadioFenologicoId}/${processoPlanejamento}/${referenciaDAP}`, null);
  }

  getQuantidadeAplicacaoPlanejada(planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividadePlanejamento/quantidadeAplicacao/${planejamentoId}`);
  }

  getAtividadePlanejamentoPadrao(): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividadePlanejamento/padrao/`)
  }

  deletarPorProcessoPlanejamento(processoPlanejamentoId: number, planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividadePlanejamento/deletarPorProcessoPlanejamento/${planejamentoId}/${processoPlanejamentoId}`);
  }
  getDiasAposPlantioParaColheita(planejamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividadePlanejamento/GetDiasAposPlantioParaColheita/${planejamentoId}`);
  }
}
