import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FiltroRelatorio } from '../components/relatorios/relatorios-componente/filtro-relatorios/filtro-relatorios.component';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService {

  constructor(private http: HttpClient) { }

  getCustoRealizadoInsumos(filtro: FiltroRelatorio) {
    return this.http.get(`${environment.apiURL}/relatorios/CustoRealizadoInsumos?${this.toQueryString(filtro)}`, { responseType: 'blob' });
  }

  getR_InsumosSafraArea(filtro: FiltroRelatorio) {
    let dataStringInicio = filtro.dataInicio.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    let dataStringFim = filtro.dataFim.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    return this.http.get(`${environment.apiURL}/safra/R_InsumosSafraArea/${filtro.safraId}/${filtro.areaId != null ? filtro.areaId : 0}/${filtro.setorId != null ? filtro.setorId : 0}?DtInical=${dataStringInicio}&DtFinal=${dataStringFim}`, { responseType: 'blob' });
  }

  getR_FimSafra(filtro: FiltroRelatorio) {
    return this.http.get(`${environment.apiURL}/safra/R_FimSafra/${filtro.safraId}`, { responseType: 'blob' });
  }

  getR_HistoricoAplicacao(filtro: FiltroRelatorio) {
    return this.http.get(`${environment.apiURL}/safra/R_HistoricoAplicacao/${filtro.safraId}`, { responseType: 'blob' });
  }

  getApontamentoIndicadores(filtro: FiltroRelatorio) {
    return this.http.get(`${environment.apiURL}/ApontamentoIndicadores/listar/${this.formatarData(filtro.dataInicio)}/${this.formatarData(filtro.dataFim)}/${filtro.equipamentoId}/${filtro.talhaoId}/${filtro.safraId}/${filtro.culturaId}/${filtro.operadorId}`, { responseType: 'blob' });
  }

  formatarData(data: Date): string {
    var year = data.getFullYear().toString();
    var month = (data.getMonth() + 1).toString().padStart(2, '0');
    var day = data.getDate().toString().padStart(2, '0');
    return year + month + day;
  }

  ToDateString(data: Date):string
  {
    return data.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  private toQueryString(obj: any): string {
    const parts = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        if (obj[key] instanceof Date) {
          const dateStr = this.formatarData(obj[key]);
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(dateStr)}`);
        } else {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
      }
    }
    return parts.join('&');
  }

}
