import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-empresa-interna',
  templateUrl: './empresa-interna.component.html',
  styleUrls: ['./empresa-interna.component.scss']
})
export class EmpresaInternaComponent implements OnInit {

  empresasInternas: EmpresaInterna[];

  colDefs: ColDef[] = [];
  objSelecionado: EmpresaInterna = new EmpresaInterna();
  objSelecionadoId: number = null;
  objetosSelecionados: EmpresaInterna[] = [];

  constructor(
    private empresaInternaService: EmpresaInternaService,
    private utils: UtilsService,
    private router: Router,
    private toasty: CustomToastyService) {
  }

  ngOnInit() {
    this.listarEmpresasInternas();
    this.configurar();
  }

  listarEmpresasInternas() {
    this.empresaInternaService.getEmpresasInternas().subscribe(
      res => {
        this.empresasInternas = res;
      },
      err => {
        this.utils.getErro(err);
      });
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'RazaoSocial', headerName: 'Razão Social'},
      {field: 'NomeFantasia', headerName: 'Nome Fantasia'},
      {field: 'TipoDesc', headerName: 'Tipo'},
      {field: 'DocumentoFormatado', headerName: 'Documento'},
      {field: 'InscricaoEstadual', headerName: 'Inscrição Estadual'},
      {field: 'InscricaoMunicipal', headerName: 'Inscrição Municipal'},
      {field: 'MatrizDesc', headerName: 'Matriz'},
      {field: 'Cnae', headerName: 'Cnae'},
      {field: 'CpfResponsavelFormatado', headerName: 'CPF Responsável'},
      {field: 'NomeResponsavel', headerName: 'Nome Responsável'},
      {field: 'TelefoneFormatado', headerName: 'Telefone'},
      {field: 'Email', headerName: 'Email'},
      {field: 'Ativo', headerName: 'Ativo', valueFormatter: (params) => this.booleanoToString(params.data.Ativo)},
      {field: 'DataCadastro', headerName: 'Data Cadastro', valueFormatter: (params) => this.utils.formatarData(params.data.DataCadastro)}
    ];

  }

  booleanoToString(t: boolean) {
    return t ? 'Sim' : 'Não';
  }

  formatarDocumento(doc: string) {
    if (doc.length == 11) {
      return doc.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }

    if (doc.length == 14) {
      return doc.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }
  }

  formatarTelefone(tel: string) {
    return tel.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  }

  excluir() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione uma empresa para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas uma empresa para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar ' + this.objSelecionado.NomeFantasia + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.empresaInternaService.deleteEmpresaInterna(this.objSelecionadoId).subscribe(
          res => {
            this.toasty.show('Feito!', 'Empresa deletada!', 'success');
            this.listarEmpresasInternas();
          },
          err => {
            this.utils.getErro(err);
          });
      }
    });
  }

  novo() {
    this.router.navigate(['/cadastros/empresa-interna/inserir']);
  }

  cellDoubleClicked(event){
    this.router.navigate(['/cadastros/empresa-interna/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }
}
