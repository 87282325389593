import { Fazenda } from 'src/app/classes/Fazenda';
import { StorageService } from 'src/app/services/storage.service';
import { AfterViewInit, Component, OnDestroy, OnInit, OnChanges, SimpleChanges, HostListener, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import "leaflet-draw";
import { LayerArea } from 'src/app/classes/LayerArea';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { TranslateService } from '@ngx-translate/core';
const iconRetinaUrl = 'assets/images/arker-icon-2x.png';
const iconUrl = 'assets/images/marker-icon.png';
const shadowUrl = 'assets/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;


@Component({
  selector: 'app-mapa-editor',
  templateUrl: './mapa-editor.component.html',
  styleUrls: ['./mapa-editor.component.scss']
})
export class MapaEditorComponent implements OnInit, OnDestroy {
  @ViewChild('GeoJsonModal', { static: false })
  GeoJsonModal: UiModalComponent;

  @Input() public layersArea: any;
  @Input() fazenda: Fazenda;
  @Input() editando: boolean;
  @Input() showButtons: boolean = true;
  @Output() layersAreaChange: EventEmitter<string> = new EventEmitter();
  @Output() editandoChange: EventEmitter<boolean> = new EventEmitter();
  public map;

  // tradução
  CANCELAR_DESENHO: string = 'Cancelar desenho';
  CANCELAR: string = 'Cancelar';
  EXCLUIR_ULTIMO_PONTO_DESENHADO: string = 'Excluir último ponto desenhado';
  EXCLUIR_ULTIMO_PONTO: string = 'Excluir último ponto';
  FINALIZAR_DESENHO: string = 'Finalizar desenho';
  FINALIZAR: string = 'Finalizar';
  SALVAR_ALTERACOES: string = 'Salvar alterações';
  SALVAR: string = 'Salvar';
  CANCELAR_EDICAO_DESCARTAR_TODAS_AS_ALTERACOES: string = 'Cancelar edição, descartar todas as alterações';
  EXCLUIR_TODOS_OS_DESENHOS: string = 'Excluir todos os desenhos';
  EXCLUIR_TODOS: string = 'Excluir todos';
  CLIQUE_PARA_COMECAR_A_DESENHAR: string = 'Clique para começar a desenhar';
  CLIQUE_PARA_CONTINUAR_A_DESENHAR: string = 'Clique para continuar a desenhar';
  CLIQUE_NO_PONTO_INICIAL_PARA_FINALIZAR: string = 'Clique no ponto inicial para finalizar';
  CLIQUE_E_ARRASTE_PARA_DESENHAR_O_CIRCULO: string = 'Clique e arraste para desenhar o círculo';
  SOLTE_O_MOUSE_PARA_TERMINAR_O_DESENHO: string = 'Solte o mouse para terminar o desenho';
  CLIQUE_PRA_INSERIR_UMA_MARCA: string = 'Clique pra inserir uma marca';
  DESENHE_UM_POLIGONO: string = 'Desenhe um polígono';
  DESENHE_UM_CIRCULO: string = 'Desenhe um círculo';
  ADICIONE_UMA_MARCACAO: string = 'Adicione uma marcação';
  EDITAR: string = 'Editar';
  SEM_DESENHOS_PARA_EDITAR: string = 'Sem desenhos para editar';
  EXCLUIR: string = 'Excluir';
  SEM_DESENHOS_PARA_EXCLUIR: string = 'Sem desenhos para excluir';
  ARRASTE_AS_ALCAS_OU_O_MARCADOR_PARA_EDITAR_O_ELEMENTO: string = 'Arraste as alças ou o marcador para editar o elemento';
  CLIQUE_EM_CANCELAR_PARA_DESCARTAR_AS_ALTERACOES: string = 'Clique em cancelar para descartar as alterações';
  CLIQUE_NUMA_AREA_PARA_EXCLUIR: string = 'Clique numa área para excluir';
  MAIS_ZOOM: string = 'Mais Zoom';
  MENOS_ZOOM: string = 'Menos Zoom';

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    if (this.layersArea)
      sessionStorage.setItem("layersArea", JSON.stringify(this.layersArea))
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem("layersArea");
  }

  ngAfterViewInit() {
    this.carregaMapa();
    this.configurarTraducao();
    setTimeout(() => {
      this.carregaMapa();
    }, 2000);
  }

  editar(zoom?: number) {
    this.editando = true;
    this.carregaMapa(zoom);
    this.editandoChange.emit(true);
  }

  concluirEdicao() {
    this.editando = false;
    this.layersAreaChange.emit(sessionStorage.getItem("layersArea"));
    this.editandoChange.emit(false);
    this.layersArea = JSON.parse(sessionStorage.getItem("layersArea"));
    this.carregaMapa();
  }

  carregaMapa(zoom?: number) {
    var editableLayers = new L.FeatureGroup();
    var layerArea: LayerArea;
    var cor: string = "#006600";
    var corBorda: string = "#bada55";
    L.drawLocal.draw.toolbar.actions.title = this.CANCELAR_DESENHO;
    L.drawLocal.draw.toolbar.actions.text = this.CANCELAR;
    L.drawLocal.draw.toolbar.undo.title = this.EXCLUIR_ULTIMO_PONTO_DESENHADO;
    L.drawLocal.draw.toolbar.undo.text = this.EXCLUIR_ULTIMO_PONTO;
    L.drawLocal.draw.toolbar.finish.title = this.FINALIZAR_DESENHO;
    L.drawLocal.draw.toolbar.finish.text = this.FINALIZAR;

    L.drawLocal.edit.toolbar.actions.save.title = this.SALVAR_ALTERACOES;
    L.drawLocal.edit.toolbar.actions.save.text = this.SALVAR;
    L.drawLocal.edit.toolbar.actions.cancel.title = this.CANCELAR_EDICAO_DESCARTAR_TODAS_AS_ALTERACOES;
    L.drawLocal.edit.toolbar.actions.cancel.text = this.CANCELAR;
    L.drawLocal.edit.toolbar.actions.clearAll.title = this.EXCLUIR_TODOS_OS_DESENHOS;
    L.drawLocal.edit.toolbar.actions.clearAll.text = this.EXCLUIR_TODOS;

    L.drawLocal.draw.handlers.polygon.tooltip.start = this.CLIQUE_PARA_COMECAR_A_DESENHAR;
    L.drawLocal.draw.handlers.polygon.tooltip.cont = this.CLIQUE_PARA_CONTINUAR_A_DESENHAR;
    L.drawLocal.draw.handlers.polygon.tooltip.end = this.CLIQUE_NO_PONTO_INICIAL_PARA_FINALIZAR;

    L.drawLocal.draw.handlers.circle.tooltip.start = this.CLIQUE_E_ARRASTE_PARA_DESENHAR_O_CIRCULO;
    L.drawLocal.draw.handlers.simpleshape.tooltip.end = this.SOLTE_O_MOUSE_PARA_TERMINAR_O_DESENHO;

    L.drawLocal.draw.handlers.marker.tooltip.start = this.CLIQUE_PRA_INSERIR_UMA_MARCA;

    L.drawLocal.draw.toolbar.buttons.polygon = this.DESENHE_UM_POLIGONO;
    L.drawLocal.draw.toolbar.buttons.circle = this.DESENHE_UM_CIRCULO;
    L.drawLocal.draw.toolbar.buttons.marker = this.ADICIONE_UMA_MARCACAO;
    L.drawLocal.edit.toolbar.buttons.edit = this.EDITAR;
    L.drawLocal.edit.toolbar.buttons.editDisabled = this.SEM_DESENHOS_PARA_EDITAR;
    L.drawLocal.edit.toolbar.buttons.remove = this.EXCLUIR;
    L.drawLocal.edit.toolbar.buttons.removeDisabled = this.SEM_DESENHOS_PARA_EXCLUIR;

    L.drawLocal.edit.handlers.edit.tooltip.text = this.ARRASTE_AS_ALCAS_OU_O_MARCADOR_PARA_EDITAR_O_ELEMENTO;
    L.drawLocal.edit.handlers.edit.tooltip.subtext = this.CLIQUE_EM_CANCELAR_PARA_DESCARTAR_AS_ALTERACOES;
    L.drawLocal.edit.handlers.remove.tooltip.text = this.CLIQUE_NUMA_AREA_PARA_EXCLUIR;


    if (this.map) {
      this.map.off();
      this.map.remove();
    }

    var googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', { maxZoom: 20, subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] });
    var satelite = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { maxZoom: 19 });

    let latitude: number = 0;
    let longitude: number = 0;
    if (this.layersArea && this.layersArea.length > 0 && this.layersArea[0].latlng) {
      if(this.layersArea[0].tipo == "polygon"){
        latitude = this.layersArea[0].latlng[0][0]
        longitude = this.layersArea[0].latlng[0][1]
      } else {
          latitude = this.layersArea[0].latlng[0]
          longitude = this.layersArea[0].latlng[1]
      }
    }
    else if (this.fazenda && this.fazenda.Latitude && this.fazenda.Longitude) {
      latitude = this.fazenda.Latitude;
      longitude = this.fazenda.Longitude;
    }
    else {
      //Coordenadas Paracatu
      latitude = -17.217256772367985;
      longitude = -46.87184929847718;
    }

    let zoomMapa = zoom ? zoom : 14;

    this.map = L.map('map', {
      center: [latitude, longitude],
      zoom: zoomMapa,
      zoomControl: false,
      attributionControl: false,
      layers: googleSat
    });

    L.control.zoom({ zoomInTitle: this.MAIS_ZOOM, zoomOutTitle: this.MENOS_ZOOM, position: 'topright' }).addTo(this.map);

    if (this.layersArea && this.layersArea.length > 0) {
      this.layersArea.forEach(layer => {
        if (layer.tipo == "circle") {
          var circulo = L.circle(layer.latlng, { radius: layer.raio, color: cor, fillColor: corBorda, fillOpacity: 0.9 }).addTo(this.map);
          if (this.editando)
            editableLayers.addLayer(circulo);
        }
        else if (layer.tipo == "polygon") {
          var polygon = L.polygon(layer.latlng, { color: cor, fillColor: corBorda, fillOpacity: 0.9 }).addTo(this.map);
          if (this.editando)
            editableLayers.addLayer(polygon);
        }
        else if (layer.tipo == "marker") {
          var marker = L.marker(layer.latlng).addTo(this.map);
          if (this.editando)
            editableLayers.addLayer(marker);
        }
      })
    }

    this.map.addLayer(editableLayers);

    if (this.editando) {
      var options = {
        position: 'topright',
        draw: {
          polyline: false,
          polygon: {
            allowIntersection: false,
            drawError: {
              color: '#e1e100',
              message: '<strong>Ops!<strong> Este ponto é inválido!'
            },
            shapeOptions: {
              color: cor,
              fillColor: corBorda,
              fillOpacity: 0.9
            }
          },
          circle: {
            shapeOptions: {
              color: cor,
              fillColor: corBorda,
              fillOpacity: 0.9
            }
          },
          marker: false,
          rectangle: false,
          circlemarker: false
        },
        edit: {
          featureGroup: editableLayers,
          remove: true
        }
      };

      var drawControl = new L.Control.Draw(options);
      this.map.addControl(drawControl);

      this.map.on('draw:created', function (e) {
        var layer = e.layer;
        editableLayers.addLayer(layer);
        this.layersArea = [];
        editableLayers.getLayers().forEach(x => {
          if (x._mRadius) {
            layerArea = { tipo: "circle", latlng: [x._latlng.lat, x._latlng.lng], raio: x._mRadius };
            this.layersArea.push(layerArea);
          }
          else if (x._latlngs) {
            layerArea = { tipo: "polygon", latlng: [], raio: 0 };
            x.getLatLngs().forEach(ll => {
              ll.forEach(y => {
                layerArea.latlng.push([y.lat, y.lng])
              });
            });
            this.layersArea.push(layerArea);
          }
          else if (x._icon) {
            layerArea = { tipo: "marker", latlng: [x._latlng.lat, x._latlng.lng], raio: x._mRadius };
            this.layersArea.push(layerArea);
          }
        });
        sessionStorage.setItem("layersArea", JSON.stringify(this.layersArea));
      });

      this.map.on('draw:edited', function (e) {
        this.layersArea = [];
        editableLayers.getLayers().forEach(x => {
          if (x._mRadius) {
            layerArea = { tipo: "circle", latlng: [x._latlng.lat, x._latlng.lng], raio: x._mRadius };
            this.layersArea.push(layerArea);
          }
          else if (x._latlngs) {
            layerArea = { tipo: "polygon", latlng: [], raio: 0 };
            x.getLatLngs().forEach(ll => {
              ll.forEach(y => {
                layerArea.latlng.push([y.lat, y.lng])
              });
            });
            this.layersArea.push(layerArea);
          }
          else if (x._icon) {
            layerArea = { tipo: "marker", latlng: [x._latlng.lat, x._latlng.lng], raio: x._mRadius };
            this.layersArea.push(layerArea);
          }
        });
        sessionStorage.setItem("layersArea", JSON.stringify(this.layersArea));


      });


      this.map.on('draw:deleted', function (e) {
        this.layersArea = [];
        editableLayers.getLayers().forEach(x => {
          if (x._mRadius) {
            layerArea = { tipo: "circle", latlng: [x._latlng.lat, x._latlng.lng], raio: x._mRadius };
            this.layersArea.push(layerArea);
          }
          else if (x._latlngs) {
            layerArea = { tipo: "polygon", latlng: [], raio: 0 };
            x.getLatLngs().forEach(ll => {
              ll.forEach(y => {
                layerArea.latlng.push([y.lat, y.lng])
              });
            });
            this.layersArea.push(layerArea);
          }
          else if (x._icon) {
            layerArea = { tipo: "marker", latlng: [x._latlng.lat, x._latlng.lng], raio: x._mRadius };
            this.layersArea.push(layerArea);
          }
        });
        sessionStorage.setItem("layersArea", JSON.stringify(this.layersArea));
      });
    }
  }

  importarGeoJson() {
    this.GeoJsonModal.show();
  }

  uploadGeoJson(event) {
    this.layersArea = [];
    console.log(event.target.files[0]);
    let that = this;
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (e) => {
      console.log('string ', reader.result.toString());

      var geojson = JSON.parse(reader.result.toString());
      geojson.features.forEach(x => {
        if (x.geometry.type == "Polygon") {
          let layerArea = { tipo: "polygon", latlng: [], raio: 0 };
          x.geometry.coordinates[0].forEach(y => {
            layerArea.latlng.push([y[1], y[0]])
          });
          this.layersArea.push(layerArea);
        }
        else if (x.geometry.type == "Point") {
          let layerArea = { tipo: "marker", latlng: [x.geometry.coordinates[1], x.geometry.coordinates[0]], raio: x._mRadius };
          this.layersArea.push(layerArea);
        }
        else if (x.geometry.type == "LineString") {
          let layerArea = { tipo: "polygon", latlng: [], raio: 0 };
          x.geometry.coordinates.forEach(y => {
            layerArea.latlng.push([y[1], y[0]])
          });
          this.layersArea.push(layerArea);
        }
        else if (x.geometry.type == "MultiPolygon") {
          let layerArea = { tipo: "polygon", latlng: [], raio: 0 };
          x.geometry.coordinates[0][0].forEach(y => {
            layerArea.latlng.push([y[1], y[0]])
          });
          this.layersArea.push(layerArea);
        }
      });
      sessionStorage.setItem("layersArea", JSON.stringify(this.layersArea));
      var geojsonLayer = L.geoJSON(geojson).addTo(this.map);
      this.map.fitBounds(geojsonLayer.getBounds());
      this.GeoJsonModal.hide();
      this.editar(12);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  configurarTraducao() {
    this.translate.stream(['CANCELAR_DESENHO', 'CANCELAR', 'EXCLUIR_ULTIMO_PONTO_DESENHADO', 'EXCLUIR_ULTIMO_PONTO', 'FINALIZAR_DESENHO', 'FINALIZAR', 'SALVAR_ALTERACOES'
      , 'SALVAR', 'CANCELAR_EDICAO_DESCARTAR_TODAS_AS_ALTERACOES', 'EXCLUIR_TODOS_OS_DESENHOS', 'EXCLUIR_TODOS', 'CLIQUE_PARA_COMECAR_A_DESENHAR', 'CLIQUE_PARA_CONTINUAR_A_DESENHAR'
      , 'CLIQUE_NO_PONTO_INICIAL_PARA_FINALIZAR', 'CLIQUE_E_ARRASTE_PARA_DESENHAR_O_CIRCULO', 'SOLTE_O_MOUSE_PARA_TERMINAR_O_DESENHO', 'CLIQUE_PRA_INSERIR_UMA_MARCA', 'DESENHE_UM_POLIGONO'
      , 'DESENHE_UM_CIRCULO', 'ADICIONE_UMA_MARCACAO', 'EDITAR', 'SEM_DESENHOS_PARA_EDITAR', 'EXCLUIR', 'SEM_DESENHOS_PARA_EXCLUIR', 'ARRASTE_AS_ALCAS_OU_O_MARCADOR_PARA_EDITAR_O_ELEMENTO'
      , 'CLIQUE_EM_CANCELAR_PARA_DESCARTAR_AS_ALTERACOES', 'CLIQUE_NUMA_AREA_PARA_EXCLUIR', 'MAIS_ZOOM', 'MENOS_ZOOM']).subscribe(p => {
        this.CANCELAR_DESENHO = p['CANCELAR_DESENHO'];
        this.CANCELAR = p['CANCELAR'];
        this.EXCLUIR_ULTIMO_PONTO_DESENHADO = p['EXCLUIR_ULTIMO_PONTO_DESENHADO'];
        this.EXCLUIR_ULTIMO_PONTO = p['EXCLUIR_ULTIMO_PONTO'];
        this.FINALIZAR_DESENHO = p['FINALIZAR_DESENHO'];
        this.FINALIZAR = p['FINALIZAR'];
        this.SALVAR_ALTERACOES = p['SALVAR_ALTERACOES'];
        this.SALVAR = p['SALVAR'];
        this.CANCELAR_EDICAO_DESCARTAR_TODAS_AS_ALTERACOES = p['CANCELAR_EDICAO_DESCARTAR_TODAS_AS_ALTERACOES'];
        this.EXCLUIR_TODOS_OS_DESENHOS = p['EXCLUIR_TODOS_OS_DESENHOS'];
        this.EXCLUIR_TODOS = p['EXCLUIR_TODOS'];
        this.CLIQUE_PARA_COMECAR_A_DESENHAR = p['CLIQUE_PARA_COMECAR_A_DESENHAR'];
        this.CLIQUE_PARA_CONTINUAR_A_DESENHAR = p['CLIQUE_PARA_CONTINUAR_A_DESENHAR'];
        this.CLIQUE_NO_PONTO_INICIAL_PARA_FINALIZAR = p['CLIQUE_NO_PONTO_INICIAL_PARA_FINALIZAR'];
        this.CLIQUE_E_ARRASTE_PARA_DESENHAR_O_CIRCULO = p['CLIQUE_E_ARRASTE_PARA_DESENHAR_O_CIRCULO'];
        this.SOLTE_O_MOUSE_PARA_TERMINAR_O_DESENHO = p['SOLTE_O_MOUSE_PARA_TERMINAR_O_DESENHO'];
        this.CLIQUE_PRA_INSERIR_UMA_MARCA = p['CLIQUE_PRA_INSERIR_UMA_MARCA'];
        this.DESENHE_UM_POLIGONO = p['DESENHE_UM_POLIGONO'];
        this.DESENHE_UM_CIRCULO = p['DESENHE_UM_CIRCULO'];
        this.ADICIONE_UMA_MARCACAO = p['ADICIONE_UMA_MARCACAO'];
        this.EDITAR = p['EDITAR'];
        this.SEM_DESENHOS_PARA_EDITAR = p['SEM_DESENHOS_PARA_EDITAR'];
        this.EXCLUIR = p['EXCLUIR'];
        this.SEM_DESENHOS_PARA_EXCLUIR = p['SEM_DESENHOS_PARA_EXCLUIR'];
        this.ARRASTE_AS_ALCAS_OU_O_MARCADOR_PARA_EDITAR_O_ELEMENTO = p['ARRASTE_AS_ALCAS_OU_O_MARCADOR_PARA_EDITAR_O_ELEMENTO'];
        this.CLIQUE_EM_CANCELAR_PARA_DESCARTAR_AS_ALTERACOES = p['CLIQUE_EM_CANCELAR_PARA_DESCARTAR_AS_ALTERACOES'];
        this.CLIQUE_NUMA_AREA_PARA_EXCLUIR = p['CLIQUE_NUMA_AREA_PARA_EXCLUIR'];
        this.MAIS_ZOOM = p['MAIS_ZOOM'];
        this.MENOS_ZOOM = p['MENOS_ZOOM'];
        this.carregaMapa();
      });
  }
}
