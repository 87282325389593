import { Component, OnInit } from '@angular/core';
import { Equipamento } from 'src/app/classes/Equipamento';
import { Funcionario } from 'src/app/classes/Funcionario';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { FuncionarioService } from 'src/app/services/funcionario.service';

@Component({
  selector: 'app-produtividade',
  templateUrl: './produtividade.component.html',
  styleUrls: ['./produtividade.component.scss']
})
export class ProdutividadeComponent implements OnInit {
  funcionarios: Funcionario[];
  equipamentos: Equipamento[];
  implementos: Equipamento[];
  funcionarioId:number;
  equipamentoId:number;
  implementoId:number;
  constructor(private funcionarioService: FuncionarioService,
              private equipamentoService: EquipamentoService) { }

  ngOnInit() {
    this.funcionarioService.getFuncionarios().subscribe(ret=> {
      this.funcionarios = ret;
    })

    this.equipamentoService.getEquipamentos().subscribe(ret => {
      this.equipamentos = ret;
      this.implementos = this.equipamentos.filter(x => x.FlagImplemento)
    })
  }

}
