import { TranslateService } from "@ngx-translate/core";

export abstract class OrdemCompraTranslate {
  /**
   *
   */
  constructor(translate: TranslateService) {
    this.FINALIZAR_ESSA_ORDEM_DE_COMPRA = translate.instant('FINALIZAR_ESSA_ORDEM_DE_COMPRA');
    this.ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = translate.instant('CANCELAR');
    this.SIM = translate.instant('SIM');
    this.INDICAR_PAGAMENTO_A_ESSA_ORDEM_DE_COMPRA = translate.instant('INDICAR_PAGAMENTO_A_ESSA_ORDEM_DE_COMPRA');
    this.REALIZAR_CONFERENCIA_DOS_PRODUTOS_DESSA_ORDEM_DE_COMPRA = translate.instant('REALIZAR_CONFERENCIA_DOS_PRODUTOS_DESSA_ORDEM_DE_COMPRA');
    this.INDICACAO_DE_PAGAMENTO = translate.instant('INDICACAO_DE_PAGAMENTO');
    this.CONFERENCIA_DE_PRODUTOS = translate.instant('CONFERENCIA_DE_PRODUTOS');
    this.FINALIZAR_ORDEM_DE_COMPRA = translate.instant('FINALIZAR_ORDEM_DE_COMPRA');
    this.OBSERVACOES = translate.instant('OBSERVACOES');
    this.OBSERVACOES_DE_APROVACAO = translate.instant('OBSERVACOES_DE_APROVACAO');
    this.FEITO = translate.instant('FEITO');
    this.OBSERVACAO_ALTERADACADASTRADA = translate.instant('OBSERVACAO_ALTERADACADASTRADA');
  }

  FINALIZAR_ESSA_ORDEM_DE_COMPRA: string = 'Finalizar essa Ordem de Compra?';
  ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  INDICAR_PAGAMENTO_A_ESSA_ORDEM_DE_COMPRA: string = 'Indicar pagamento a essa Ordem de Compra?';
  REALIZAR_CONFERENCIA_DOS_PRODUTOS_DESSA_ORDEM_DE_COMPRA: string = 'Realizar conferência dos produtos dessa Ordem de Compra?';
  INDICACAO_DE_PAGAMENTO: string = 'Indicação de Pagamento';
  CONFERENCIA_DE_PRODUTOS: string = 'Conferência de Produtos';
  FINALIZAR_ORDEM_DE_COMPRA: string = 'Finalizar Ordem de Compra';
  OBSERVACOES: string = 'Observações';
  OBSERVACOES_DE_APROVACAO: string = 'Observações de Aprovação';
  FEITO: string = 'Feito!';
  OBSERVACAO_ALTERADACADASTRADA: string = 'Observação Alterada/Cadastrada!';
}
