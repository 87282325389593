import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Area } from 'src/app/classes/Area';
import { LocalEstoque, enumTipoEstoque } from 'src/app/classes/LocalEstoque';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { Safra } from 'src/app/classes/Safra';
import { DestinosProducao, EntradaProducao, enumDestinoProducao } from 'src/app/classes/gestao-lite/EntradaProducao';
import { ItemDescontoProducao } from 'src/app/classes/gestao-lite/ItemDescontoProducao';
import { TipoDescontoProducao } from 'src/app/classes/gestao-lite/TipoDescontoProducao';
import { AreaService } from 'src/app/services/area.service';
import { EntradaProducaoService } from 'src/app/services/entrada-producao.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { PlanejamentoSafraService } from 'src/app/services/planejamento-safra/planejamento-safra.service';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { SafraService } from 'src/app/services/safra.service';
import { TipoDescontoProducaoService } from 'src/app/services/tipo-desconto-producao.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-entrada-producao',
  templateUrl: './entrada-producao.component.html',
  styleUrls: ['./entrada-producao.component.scss']
})
export class EntradaProducaoComponent implements OnInit {
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  @Output() fechou: EventEmitter<any> = new EventEmitter();
  @Output() sucesso: EventEmitter<EntradaProducao> = new EventEmitter();

  @Input() entradaProducaoId: number = null;

  isSubmit: boolean = false;
  entradaProducao: EntradaProducao = new EntradaProducao();
  safras: Safra[] = [];
  areas: Area[] = [];
  locaisEstoque: LocalEstoque[] = [];
  tiposDescontoProducao: TipoDescontoProducao[] = [];
  itensDescontoProducao: ItemDescontoProducao[] = [];
  processosPlanejamento: ProcessoPlanejamento[] = [];
  destinosProducao = DestinosProducao;
  totalPesoBruto: number = 0;
  totalDesconto: number = 0;
  totalPesoLiquido: number = 0;
  cadastrarTipoDescontoProducao: boolean = false;
  carregando: boolean = false;

  constructor(private entradaProducaoService: EntradaProducaoService,
    private safraService: SafraService,
    private areaService: AreaService,
    private translate: TranslateService,
    private localEstoqueService: LocalEstoqueService,
    private tipoDescontoProducaoService: TipoDescontoProducaoService,
    private ordemServicoService: OrdemServicoService,
    private processoPlanejamentoService: ProcessoPlanejamentoService,
    private planejamentoSafraService: PlanejamentoSafraService,
    private utils: UtilsService) { }

  ngOnInit() {
    this.tipoDescontoProducaoService.getTiposDescontoProducao().subscribe(ret => {
      this.tiposDescontoProducao = ret;
    });

    this.processoPlanejamentoService.getProcessosPlanejamento().subscribe(ret => {
      this.processosPlanejamento = ret;
    });

    if (this.entradaProducaoId != null) {
      this.carregarItensEdicao(this.entradaProducaoId);
      this.recalcularTotais();
    } else {
      this.entradaProducao.DataEntrada = new Date();
      this.entradaProducao.DestinoProducao = enumDestinoProducao.Interno;
    }
  }

  ngAfterViewInit() {
    this.Modal.show();
  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

  selecionarFazenda(fazendaId: number) {
    this.entradaProducao.FazendaId = fazendaId;
    this.safraService.getSafrasPorFazendas([fazendaId]).subscribe(ret => {
      this.safras = ret;
    });

    this.localEstoqueService.listarPorFazenda(fazendaId).subscribe(ret => {
      this.locaisEstoque = ret;
      this.locaisEstoque = this.locaisEstoque.filter(x => x.TipoEstoque == enumTipoEstoque.Armazem);
    });
  }

  selecionarSafra() {
    this.areaService.getAreasPorSafra(this.entradaProducao.SafraId).subscribe(ret => {
      this.areas = ret;
    });
  }

  selecionarArea() {
    let ordensServico: any[] = [];
    let processosId: number[] = [];
    this.processosPlanejamento.forEach(processoPlanejamento => {
      if (processoPlanejamento.Colheita) {
        processosId.push(processoPlanejamento.Id);
      }
    });

    let filtro: any = {
      Periodo: 0,
      Fazenda: [this.entradaProducao.FazendaId],
      Safra: [this.entradaProducao.SafraId],
      AreaId: this.entradaProducao.AreaId,
      ProcessosPlanejamento: processosId
    }

    this.ordemServicoService.detalhamento(filtro).subscribe(ret => {
      ordensServico = ret;
      if (ordensServico.length > 0) {
        this.entradaProducao.OrdemServicoId = ordensServico[0].Id;
      }
      else {
        this.entradaProducao.OrdemServicoId = null;
        this.utils.handleWarning(this.translate.instant('NAO_EXISTE_ORDEM_DE_SERVICO_DE_COLHEITA_PARA_A_SAFRA_E_AREA_SELECIONADA'));
      }
    });
  }

  selecionarEquipamento(equipamentoId: number) {
    this.entradaProducao.EquipamentoId = equipamentoId;
  }

  alterarPeso() {
    setTimeout(() => {
      this.entradaProducao.PesoLiquido = this.entradaProducao.PesoEntrada - this.entradaProducao.PesoSaida;
    }, 100);

    setTimeout(() => {
      this.recalcularTotais();
    }, 500);
  }

  selecionarProduto(produtoId: number) {
    this.entradaProducao.ProdutoId = produtoId;
  }

  novoItemDescontoProducao() {
    this.itensDescontoProducao.push(new ItemDescontoProducao());
  }

  novoTipoDescontoProducao() {
    this.cadastrarTipoDescontoProducao = true;
  }

  fecharTipoDescontoProducao() {
    this.cadastrarTipoDescontoProducao = false;
  }

  changeTipoDescontoProducao(tipoDescontoProducao: TipoDescontoProducao) {
    this.fecharTipoDescontoProducao();
    this.tiposDescontoProducao.push(tipoDescontoProducao);
  }

  changeDesconto(itemDescontoProducao: ItemDescontoProducao) {
    setTimeout(() => {
      itemDescontoProducao.Quantidade = this.entradaProducao.PesoLiquido * itemDescontoProducao.Percentual / 100;
    }, 100);

    setTimeout(() => {
      this.recalcularTotais();
    }, 500);
  }

  recalcularTotais() {
    this.totalPesoBruto = 0;
    this.totalDesconto = 0;
    this.totalPesoLiquido = 0;
    this.itensDescontoProducao.forEach(itemDescontoProducao => {
      this.totalDesconto += itemDescontoProducao.Quantidade;
    });
    this.totalPesoLiquido = this.entradaProducao.PesoLiquido - this.totalDesconto;
    this.totalPesoBruto = this.entradaProducao.PesoLiquido;
  }

  salvarEntradaProducao() {
    this.isSubmit = true;
    this.carregando = true;
    this.entradaProducao.ItensDescontoProducao = this.itensDescontoProducao;
    var result: Observable<any>;

    if (this.entradaProducaoId == null) {
      result = this.entradaProducaoService.salvarCompleto(this.entradaProducao);
    } else {
      result = this.entradaProducaoService
        .putEntradaProducao(this.entradaProducaoId, this.entradaProducao);
    }

    result.subscribe(ret => {
      this.utils.handleSuccess(this.translate.instant('ENTRADA_DE_PRODUCAO_SALVA_COM_SUCESSO'));
      this.sucesso.emit(this.entradaProducao);
      this.fechar();
    }, err => {
      this.utils.getErro(err);
    });
  }

  private carregarItensEdicao(entradaProducaoId: number) {
    this.entradaProducaoService.getEntradaProducao(entradaProducaoId)
      .subscribe(
        res => {
          this.entradaProducao = res;
          this.areaService.getAreasPorSafra(this.entradaProducao.SafraId).subscribe(ret => {
            this.areas = ret;
          });
        },
        err => this.utils.getErro(err)
      );

    this.planejamentoSafraService.GetItensDescontoProducao(entradaProducaoId)
      .subscribe(
        res => this.itensDescontoProducao = res,
        err => this.utils.getErro(err)
      );
    this.recalcularTotais();
  }
}
