export class ItemAgrupamentoOrdemServico {
    Id: number;
    ProdutoId: number;
    AgrupamentoOrdemServicoId: number;
    Dose: number = 0;
    DosePorTanque: number = 0;
    AreaAplicacao: number = 0;
    AreaReal: number = 0;
    Quantidade: number = 0;
    QtdRetirada: number = 0;
    QtdRetorno: number = 0;
    QtdAplicada: number = 0;
    QtdPrevista: number = 0;
    PrecoUnitario: number = 0;
    ValorTotal: number = 0;
}
