import { AfterViewInit, Component, OnDestroy, OnInit, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import * as L from 'leaflet';
import "leaflet-draw";


const iconRetinaUrl = 'assets/images/arker-icon-2x.png';
const iconUrl = 'assets/images/marker-icon.png';
const shadowUrl = 'assets/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

interface layerArea {
  tipo: string;
  latlng: number[][];
  raio: number;
}

@Component({
  selector: 'app-teste-leaflet',
  templateUrl: './teste-leaflet.component.html',
  styleUrls: ['./teste-leaflet.component.scss']
})
export class TesteLeafletComponent implements OnInit, AfterViewInit, OnDestroy,OnChanges {
  public map;
  public layerArea: layerArea;
  public lslayersArea: layerArea[];
  clicked: any;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('clk-c-',this.clicked);
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    var editableLayers = new L.FeatureGroup();
    var layersArea: layerArea[] = [];
    var layerArea : layerArea;

    //to prevent errors
    if (this.map) {
      //to remove any initialization of a previous map
      this.map.off();
      this.map.remove();
    }

    //for dark mode view
    var dark = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', { maxZoom: 19 });
    //for original view
    var original = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 19 });
    //for satellite view
    var satellite = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { maxZoom: 19 });

    var googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{maxZoom: 20,subdomains:['mt0','mt1','mt2','mt3']});



    L.drawLocal.draw.toolbar.actions.title = 'Cancelar desenho';
    L.drawLocal.draw.toolbar.actions.text = 'Cancelar';
    L.drawLocal.draw.toolbar.undo.title = 'Excluir último ponto desenhado';
    L.drawLocal.draw.toolbar.undo.text = 'Excluir último ponto';
    L.drawLocal.draw.toolbar.finish.title = 'Finalizar desenho';
    L.drawLocal.draw.toolbar.finish.text = 'Finalizar';

    L.drawLocal.edit.toolbar.actions.save.title = 'Salvar alterações';
    L.drawLocal.edit.toolbar.actions.save.text = 'Salvar';
    L.drawLocal.edit.toolbar.actions.cancel.title = 'Cancelar edição, descartar todas as alterações';
    L.drawLocal.edit.toolbar.actions.cancel.text = 'Cancelar';
    L.drawLocal.edit.toolbar.actions.clearAll.title = 'Excluir todos os desenhos';
    L.drawLocal.edit.toolbar.actions.clearAll.text = 'Excluir todos';

    L.drawLocal.draw.handlers.polygon.tooltip.start = 'Clique para começar a desenhar';
    L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Clique para continuar a desenhar';
    L.drawLocal.draw.handlers.polygon.tooltip.end = 'Clique no ponto inicial para finalizar';

    L.drawLocal.draw.handlers.circle.tooltip.start = 'Clique e arraste para desenhar o círculo';
    L.drawLocal.draw.handlers.simpleshape.tooltip.end = 'Solte o mouse para terminar o desenho';

    L.drawLocal.draw.handlers.marker.tooltip.start = 'Clique pra inserir uma marca';

    L.drawLocal.draw.toolbar.buttons.polygon = 'Desenhe um polígono';
    L.drawLocal.draw.toolbar.buttons.circle = 'Desenhe um círculo';
    L.drawLocal.draw.toolbar.buttons.marker = 'Adicione uma marcação';
    L.drawLocal.edit.toolbar.buttons.edit = 'Editar';
    L.drawLocal.edit.toolbar.buttons.editDisabled = 'Sem desenhos para editar';
    L.drawLocal.edit.toolbar.buttons.remove = 'Excluir';
    L.drawLocal.edit.toolbar.buttons.removeDisabled = 'Sem desenhos para excluir';

    L.drawLocal.edit.handlers.edit.tooltip.text = 'Arraste as alças ou o marcador para editar o elemento';
    L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Clique em cancelar para descartar as alterações';
    L.drawLocal.edit.handlers.remove.tooltip.text = 'Clique numa área para excluir';


    // declaring the leaflet map
    this.map = L.map('map', {
      //choose the default view coordinates
      center: [-16.962890748088704,-46.53369555106444],
      //choose the zooming level
      zoom: 14,
      zoomControl: false,
      //to remove the attribution
      attributionControl: false,
      //to add predefined layers
      layers: googleSat
    });

    L.control.zoom({zoomInTitle : 'Mais Zoom',zoomOutTitle: 'Menos Zoom',position:'topright'}).addTo(this.map);

    /*var baseMaps = {
      "Satelite": satellite,
      "Dark": dark,
      "Original": original
    }; L.control.layers(baseMaps).addTo(this.map);*/

    var marker = L.marker([-17.760682983418558, -46.5375413201040540]).addTo(this.map);
    marker.bindTooltip("Fazenda<br>Lagoa<br>Grande",{direction: 'top', permanent:false, opacity:0.7}).openTooltip();


    //var polygon1 = L.polygon(latlngs, { color: 'green', fillColor: '#006600', fillOpacity: 0.9 }).addTo(this.map);
    //polygon1.bindTooltip("<b>Pivot 02</b> <br> 80ha",{direction: 'top', permanent:false, opacity:0.7}).openTooltip();
    //this.map.fitBounds(polygon1.getBounds());

    var latlngs = [[-46.52830693870783,-16.95079000786178],[-46.5291303,-16.9485504],[-46.5291288,-16.948587],[-46.5290878,-16.9492649],[-46.5290218,-16.9498919],[-46.5289344,-16.9505682],[-46.5289283,-16.9507377],[-46.5289104,-16.9508662],[-46.5288888,-16.9508962],[-46.528856,-16.9509119],[-46.5288191,-16.9509168],[-46.5285406,-16.9508504],[-46.5284306,-16.9508404],[-46.5284141,-16.9508332],[-46.5284087,-16.9508256],[-46.5283705,-16.9508347],[-46.5283574,-16.9508306],[-46.5283517,-16.9508216],[-46.5283015742898,-16.95080443982149],[-46.52830693870783,-16.95079000786178]];
    var ltInv ="[";
    var ltLngs = [];
    latlngs.forEach(x => {
      ltLngs.push([x[1],x[0]]);
      if(ltInv != "[")
        ltInv +=",";
      ltInv += "["+x[1]+"," +x[0]+"]";
    })
    ltInv += "]";

    console.log(ltInv);

    var polygon2 = L.polygon(ltLngs, { color: 'green' }).addTo(this.map);
    this.map.fitBounds(polygon2.getBounds());

    var circulo = L.circle([-17.760457600339254, -46.55353546142579], {radius: 524.05927182143654, color: 'green', fillColor: '#006600', fillOpacity: 0.9}).on('click', function(e) {
      //alert("foi");
      sessionStorage.setItem("AreaClickID", "10");
    }).addTo(this.map);
    circulo.bindTooltip("<b>Pivot 01</b> <br> 120ha",{direction: 'top', permanent:false, opacity:0.7}).openTooltip();

    editableLayers.addLayer(circulo);

    /*var popup = L.popup()
    .setLatLng([-17.760457600339254, -46.55353546142579])
    .setContent('<p>Hello world!<br />This is a nice popup.</p>')
    .openOn(this.map);*/

    /*if(sessionStorage.getItem("editableLayers"))
      editableLayers = sessionStorage.getItem("editableLayers");*/

    this.map.addLayer(editableLayers);

    var options = {
      position: 'topright',
      draw: {
        polyline: false,
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: '#e1e100', // Color the shape will turn when intersects
            message: '<strong>Ops!<strong> Este ponto é inválido!' // Message that will show when intersect
          },
          shapeOptions: {
            color: '#006600',
            fillColor: '#bada55',
            fillOpacity: 0.9
          }
        },
        circle: {
          shapeOptions: {
            color: '#006600',
            fillColor: '#bada55',
            fillOpacity: 0.9
          }
        },
        marker: {
          icon: iconDefault
        }, // Turns off this drawing tool
        rectangle: false,
        circlemarker: false
      },
      edit: {
        featureGroup: editableLayers, //REQUIRED!!
        remove: true
      }
    };

    var drawControl = new L.Control.Draw(options);
    this.map.addControl(drawControl);

    if(sessionStorage.getItem("editableLayers"))
      editableLayers = sessionStorage.getItem("editableLayers");

    this.map.on('draw:created', function (e) {

      console.log("layer =>", e);
      var type = e.layerType,
        layer = e.layer;

      if (type === 'marker') {
        // Do marker specific actions
        console.log("layerM =>", e);
        console.log("layerMlat =>", layer._latlng.lat);
        console.log("layerMlng =>", layer._latlng.lng);
      }

      if (type === 'circle') {
        // Do marker specific actions
        /*console.log("layerC =>", e);
        console.log("layerClat =>", layer._latlng.lat);
        console.log("layerClng =>", layer._latlng.lng);
        console.log("layerCRaio =>", layer._mRadius);*/
        layerArea = {tipo:"circle", latlng:[layer._latlng.lat,layer._latlng.lng], raio:layer._mRadius};
        layersArea.push(layerArea);

      }

      if (type === 'polygon') {
        // Do marker specific actions
        /*console.log("layerP =>", e);
        console.log("layerPlatlngs =>", layer.getLatLngs());   */

        layerArea = {tipo:"polygon", latlng:[], raio:0};
        layer.getLatLngs().forEach(x => {
          x.forEach(y => {
            layerArea.latlng.push([y.lat, y.lng])
          });
        });

        layersArea.push(layerArea);
      }

      //console.log("layerL =>", layer);
      // Do whatever else you need to. (save to db; add to map etc)
      editableLayers.addLayer(layer);

      layersArea = [];
      editableLayers.getLayers().forEach(x =>{
        console.log("Edit editableLayers -- ", x);
        if(x._mRadius){
          layerArea = {tipo:"circle", latlng:[x._latlng.lat,x._latlng.lng], raio:x._mRadius};
          layersArea.push(layerArea);
        }
        else if(x._latlngs){
          layerArea = {tipo:"polygon", latlng:[], raio:0};
          x.getLatLngs().forEach(ll => {
            ll.forEach(y => {
              layerArea.latlng.push([y.lat, y.lng])
            });
          });
          layersArea.push(layerArea);
        }
      });
      sessionStorage.setItem("layersArea",JSON.stringify(layersArea))

    });

    this.map.on('draw:edited', function (e) {
      var layers = e.layers;
      console.log("layerEdit =>", e);
      console.log("editableLayers => ", editableLayers);
      layers.eachLayer(function (layer) {
        console.log("Edit Layer -- ", layer);
      });

      layersArea = [];
      editableLayers.getLayers().forEach(x =>{
        console.log("Edit editableLayers -- ", x);
        if(x._mRadius){
          layerArea = {tipo:"circle", latlng:[x._latlng.lat,x._latlng.lng], raio:x._mRadius};
          layersArea.push(layerArea);
        }
        else if(x._latlngs){
          layerArea = {tipo:"polygon", latlng:[], raio:0};
          x.getLatLngs().forEach(ll => {
            ll.forEach(y => {
              layerArea.latlng.push([y.lat, y.lng])
            });
          });
          layersArea.push(layerArea);
        }
      });
      sessionStorage.setItem("layersArea",JSON.stringify(layersArea))


    });


    this.map.on('draw:deleted', function (e) {
      var layers = e.layers;
      console.log("layerDelete =>", e);
      console.log("editableLayers => ", editableLayers);

      /*layers.eachLayer(function (layer) {
        console.log("Delete Layer -- ", layer);
      });*/

      layersArea = [];
      editableLayers.getLayers().forEach(x =>{

        if(x._mRadius){
          layerArea = {tipo:"circle", latlng:[x._latlng.lat,x._latlng.lng], raio:x._mRadius};
          layersArea.push(layerArea);
        }
        else if(x._latlngs){
          layerArea = {tipo:"polygon", latlng:[], raio:0};
          x.getLatLngs().forEach(ll => {
            ll.forEach(y => {
              layerArea.latlng.push([y.lat, y.lng])
            });
          });
          layersArea.push(layerArea);
        }
      });
      sessionStorage.setItem("layersArea",JSON.stringify(layersArea))

    });

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    var areaId = sessionStorage.getItem("AreaClickID");
    sessionStorage.setItem("AreaClickID", "");
    if(areaId)
      console.log("area clicada: ",areaId);
  }
}
