import { Component, OnInit } from '@angular/core';
import { ContaEnergia } from 'src/app/classes/ContaEnergia';
import { ContaEnergiaService } from 'src/app/services/contaEnergia.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/services/storage.service';
import { FazendaService } from 'src/app/services/fazenda.service';
import { Fazenda } from 'src/app/classes/Fazenda';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { UnidadeConsumidoraService } from 'src/app/services/unidadeConsumidora.service';
import { UnidadeConsumidora } from 'src/app/classes/UnidadeConsumidora';
import { TipoLancamento } from 'src/app/classes/TipoLancamento';
import { TipoLancamentoService } from 'src/app/services/tipoLancamento.service';
import { ItemContaEnergia } from 'src/app/classes/ItemContaEnergia';
import { ItemContaEnergiaService } from 'src/app/services/itemContaEnergia.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-conta-energia',
  templateUrl: './conta-energia.component.html',
  styleUrls: ['./conta-energia.component.scss']
})
export class ContaEnergiaComponent implements OnInit {

  contasEnergia: ContaEnergia[];
  itensContaEnergia: ItemContaEnergia[];
  auxContasEnergia: ContaEnergia[];
  contaEnergia: ContaEnergia;
  fazendaId: number = 0;
  fazendas: Fazenda[];
  unidadesConsumidoras: UnidadeConsumidora[];
  auxUnidadesConsumidoras: UnidadeConsumidora[];
  tiposLancamento: TipoLancamento[];
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  dataReferencia: NgbDateStruct;
  dataMedicaoIni: NgbDateStruct;
  dataMedicaoFin: NgbDateStruct;
  valorTotal: number = 0;


  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  FEITO: string = "Feito!";
  ERRO: string = "Erro!";
  PELO_MENOS_LANCAMENTO_DEVE_SER_INFORMADO: string = "Pelo menos um Lançamento deve ser informado!";
  CONTA_ENERGIA_ALTERADA: string = "Conta de Energia alterada!";
  CONTA_ENERGIA_CADASTRADA: string = "Conta de Energia cadastrada!";
  CONFIRMAR_DELETE: string = "Se deletar, esta ação não poderá ser desfeita!";
  CANCELAR: string = "Cancelar";
  SIM: string = "Sim";
  CONTA_ENERGIA_DELETADA: string = "Conta de Energia deletada!";
  LANCAMENTO_DELETADO: string = "Lançamento deletado!";
  DELETAR_PARAM: string;

  constructor(private contaEnergiaService: ContaEnergiaService, private itemContaEnergiaService: ItemContaEnergiaService,
    private fazendaService: FazendaService, private route: ActivatedRoute, private router: Router, private storageService: StorageService,
    private unidadeConsumidraService: UnidadeConsumidoraService, private tipoLancamentoService: TipoLancamentoService,
    private toasty: CustomToastyService,
    private translate: TranslateService
  ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.ERRO = this.translate.instant("ERRO");
    
    this.fazendaId = 0;
    this.valorTotal = 0;
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.contaEnergia = new ContaEnergia();
    if(this.inserindo) {
      this.itensContaEnergia = [];
      this.itensContaEnergia.push(new ItemContaEnergia());
    }
    this.fazendaService.getFazendas().subscribe(ret => {
      this.fazendas = ret;
    },
      err=>{
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
      });

    this.unidadeConsumidraService.getUnidadesConsumidoras().subscribe(
      ret => {
        this.auxUnidadesConsumidoras = ret;
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
      }
    );

    this.tipoLancamentoService.getTiposLancamento().subscribe(
      ret => {
        this.tiposLancamento = ret;
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
      }
    );


    if (this.id > 0)
      this.selecionarContaEnergia();
    else
      this.listarContasEnergia();
  }

  retornaDataForm(data: Date): NgbDateStruct {
    return {
      year: Number(data.toString().substr(0, 4)),
      month: Number(data.toString().substr(5, 2)) - 1,
      day: Number(data.toString().substr(8, 2))
    };
  }

  retornaData(data: NgbDateStruct): Date {
    return new Date(data.year, data.month, data.day);
  }

  selecionarContaEnergia() {
    this.ERRO = this.translate.instant("ERRO");
    this.carregando = true;
    this.contaEnergiaService.getContaEnergia(this.id).subscribe(res => {
      this.contaEnergia = new ContaEnergia();
      this.contaEnergia = res;
      this.dataReferencia = this.retornaDataForm(this.contaEnergia.Referencia);
      this.dataMedicaoIni = this.retornaDataForm(this.contaEnergia.PeriodoMedicaoIni);
      this.dataMedicaoFin = this.retornaDataForm(this.contaEnergia.PeriodoMedicaoFin);
      this.listarItensContaEnergia();
      this.carregarUnidadesConsumidoras();
      this.carregando = false;
    },
      ex =>{
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarContasEnergia() {
    this.ERRO = this.translate.instant("ERRO");
    this.carregando = true;
    this.contaEnergiaService.getContasEnergia().subscribe(
      res => {
        this.contasEnergia = res;
        this.auxContasEnergia = res;
        this.carregando = false;
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    this.FEITO = this.translate.instant("FEITO");
    this.ERRO = this.translate.instant("ERRO");
    this.PELO_MENOS_LANCAMENTO_DEVE_SER_INFORMADO = this.translate.instant("PELO_MENOS_LANCAMENTO_DEVE_SER_INFORMADO");
    this.CONTA_ENERGIA_ALTERADA = this.translate.instant("CONTA_ENERGIA_ALTERADA");
    this.CONTA_ENERGIA_CADASTRADA = this.translate.instant("CONTA_ENERGIA_CADASTRADA");

    if (!this.itensContaEnergia || this.itensContaEnergia.length <= 0){
      Swal.fire(this.ERRO, this.PELO_MENOS_LANCAMENTO_DEVE_SER_INFORMADO, 'error');
      this.isSubmit = true;
      return;

    }
    this.carregando = true;
    this.contaEnergia.Referencia = this.retornaData(this.dataReferencia);
    this.contaEnergia.PeriodoMedicaoIni = this.retornaData(this.dataMedicaoIni);
    this.contaEnergia.PeriodoMedicaoFin = this.retornaData(this.dataMedicaoFin)

    if (this.contaEnergia.Id > 0)
      this.contaEnergiaService.putContaEnergia(this.contaEnergia.Id, this.contaEnergia).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.CONTA_ENERGIA_ALTERADA, 'success');
          this.saveItens();
          this.carregando = false;
        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.contaEnergiaService.postContaEnergia(this.contaEnergia).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.CONTA_ENERGIA_CADASTRADA, 'success');
          this.contaEnergia = res;
          this.saveItens();
          this.carregando = false;

        },
        err => {
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
  }

  saveItens() {

    this.carregando = true;
    this.ERRO = this.translate.instant("ERRO");

    this.itensContaEnergia.forEach(x => {
      x.ContaEnergiaId = this.contaEnergia.Id;
      if (x.Id > 0)
        this.itemContaEnergiaService.putItemContaEnergia(x.Id, x).subscribe(
          res => {
            console.log("ITEM " + x.Id + " ALTERADO");
          },
          err => {
            Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          });
      else
        this.itemContaEnergiaService.postItemContaEnergia(x).subscribe(
          res => {
            x = res;
            console.log("ITEM " + x.Id + " INSERIDO");
          },
          err => {
            Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          });

      this.carregando = true;
    });
  }

  excluir(contaEnergiaId: number) {
    this.ERRO = this.translate.instant("ERRO");
    this.FEITO = this.translate.instant("FEITO");
    this.CONFIRMAR_DELETE = this.translate.instant("CONFIRMAR_DELETE");
    this.CANCELAR = this.translate.instant("CANCELAR");
    this.SIM = this.translate.instant("SIM");
    this.CONTA_ENERGIA_DELETADA = this.translate.instant("CONTA_ENERGIA_DELETADA");
    this.DELETAR_PARAM = this.translate.instant("DELETAR_PARAM", {value: contaEnergiaId});

    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.CONFIRMAR_DELETE,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.contaEnergiaService.deleteContaEnergia(contaEnergiaId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.CONTA_ENERGIA_DELETADA, 'success');
            this.listarContasEnergia();
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }

  filtrarContasEnergia() {
    this.contasEnergia = this.auxContasEnergia.filter(x =>
      (this.fazendaId == 0 || (this.fazendaId && x.FazendaId == this.fazendaId)) &&
      (
        x.Id.toString().includes(this.textoBusca) ||
        x.Referencia.toString().includes(this.textoBusca) ||
        this.getNomeFazenda(x.FazendaId).toUpperCase().includes(this.textoBusca.toUpperCase()) ||
        this.getNomeUnidade(x.UnidadeConsumidoraId).toUpperCase().includes(this.textoBusca.toUpperCase())
      )
    );
  }

  cancelar() {
    this.router.navigate(['/lancamentos/conta-energia']);
  }

  carregarUnidadesConsumidoras() {
    this.unidadesConsumidoras = this.auxUnidadesConsumidoras.filter(x => x.FazendaId == this.contaEnergia.FazendaId);
  }

  getNomeFazenda(idFazenda: number): string {
    return this.fazendas.find(x => x.Id == idFazenda).Nome;
  }

  getNomeUnidade(idUnidadeConsumidora: number): string {
    return this.auxUnidadesConsumidoras.find(x => x.Id == idUnidadeConsumidora).DescricaoInstalacao;
  }

  getNomeTipoLancamento(idTipoLancamento: number): string {
    return this.tiposLancamento.find(x => x.Id == idTipoLancamento).Descricao;
  }

  addLancamento() {
    this.itensContaEnergia.push(new ItemContaEnergia());
    this.getValorTotal();
  }

  delLancamento(i) {
    this.ERRO = this.translate.instant("ERRO");
    this.FEITO = this.translate.instant("FEITO");
    this.CONFIRMAR_DELETE = this.translate.instant("CONFIRMAR_DELETE");
    this.CANCELAR = this.translate.instant("CANCELAR");
    this.SIM = this.translate.instant("SIM");
    this.LANCAMENTO_DELETADO = this.translate.instant("LANCAMENTO_DELETADO");

    if (this.itensContaEnergia[i].Id > 0) {
      Swal.fire({
        title: 'Deletar Lançamento '+ this.getNomeTipoLancamento(this.itensContaEnergia[i].TipoLancamentoId) + '?',
        text: this.CONFIRMAR_DELETE,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText:  this.CANCELAR,
        confirmButtonText: this.SIM,
      }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.itemContaEnergiaService.deleteItemContaEnergia(this.itensContaEnergia[i].Id).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.LANCAMENTO_DELETADO, 'success');
              this.listarItensContaEnergia();
              this.carregando = false;
            },
            err => {
              Swal.fire(this.ERRO, err.message, 'error');
              this.carregando = false;
            });
        }
      });
    }
    else
      this.itensContaEnergia.splice(i, 1);

    this.getValorTotal();
  }

  listarItensContaEnergia() {
    this.ERRO = this.translate.instant("ERRO");
    this.carregando = true;
    this.itemContaEnergiaService.getitensContaEnergiaPorContaEnergia(this.contaEnergia.Id).subscribe(
      res => {
        this.itensContaEnergia = res;
        this.getValorTotal();
        this.carregando = false;
      },
      err => {
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });


  }

  getValorTotal() {
    this.valorTotal = 0;
    this.itensContaEnergia.forEach(x => {
      if (x.Kwh > 0)
        x.CustoUnitario = x.Valor / x.Kwh;

      let tipoLancamento: TipoLancamento = this.tiposLancamento.find(t => t.Id == x.TipoLancamentoId);
      if (tipoLancamento.AfetaCusto)
        if (tipoLancamento.Desconto)
          this.valorTotal -= x.Valor;
        else
          this.valorTotal += x.Valor;
    })

  }


}
