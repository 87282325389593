import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Endereco, EnumTipoEndereco } from 'src/app/classes/Endereco';
import { Pessoa, ListaEnumTipoPessoa, EnumTipoPessoa } from 'src/app/classes/Pessoa';
import { PessoaService } from 'src/app/services/pessoa.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { EnderecoFormInternoComponent } from '../../endereco/endereco-form-interno/endereco-form-interno.component';

@Component({
  selector: 'app-pessoa-form',
  templateUrl: './pessoa-form.component.html',
  styleUrls: ['./pessoa-form.component.scss']
})
export class PessoaFormComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('enderecoFormInternoComponentPrincipal', { static: false }) enderecoFormInternoComponentPrincipal: EnderecoFormInternoComponent;

  private enderecoFormInternoComponentCorrespondencia: EnderecoFormInternoComponent;
  @ViewChild('enderecoFormInternoComponentCorrespondencia', { static: false }) set content1(content1: EnderecoFormInternoComponent) {
    if (content1) {
      this.enderecoFormInternoComponentCorrespondencia = content1;
      if (!this.pessoa.EnderecoCorrespondenciaIgualPrincipal)
        this.enderecoFormInternoComponentCorrespondencia.meuForm.patchValue(this.enderecoCorrespondencia);
    }
  }

  private enderecoFormInternoComponentEntrega: EnderecoFormInternoComponent;
  @ViewChild('enderecoFormInternoComponentEntrega', { static: false }) set content(content: EnderecoFormInternoComponent) {
    if (content) {
      this.enderecoFormInternoComponentEntrega = content;
      if (!this.pessoa.EnderecoEntregaIgualPrincipal)
        this.enderecoFormInternoComponentEntrega.meuForm.patchValue(this.enderecoEntrega);
    }
  }
  @Input() cotacao: boolean = false;
  @Output() PessoaCriadoEvento = new EventEmitter<any>();
  endereco: Endereco;
  enderecoPrincipal = new Endereco();
  enderecoEntrega = new Endereco();
  enderecoCorrespondencia = new Endereco();

  public isCollapsed: boolean;

  isSubmit: boolean;
  @Input() id: number = 0;
  @Input() inserindo: boolean;
  pessoas: Pessoa[] = [];
  pessoa: Pessoa;
  auxPessoas: Pessoa[] = [];
  carregando: boolean = false;
  listaTipos = ListaEnumTipoPessoa;
  listaPapeis = ['Funcionário', 'Cliente', 'Fornecedor', 'Transportadora', 'Motorista', 'Banco', 'Vendedor']
  pessoaListaPapeis = [];
  mascaraCpfCnpj = '';
  status = '';
  carregandoDadosCNPJ = false;

  telefoneInvalido = false;
  cpfInvalido = false;

  constructor(private pessoaService: PessoaService,
    private utils: UtilsService,
    private router: Router) { 
    this.enderecoPrincipal = new Endereco();
    this.enderecoEntrega = new Endereco();
    this.enderecoCorrespondencia = new Endereco();
    this.isCollapsed = true;}

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    this.pessoa = new Pessoa();
  }

  ngAfterViewInit() {
    if (this.id > 0 && this.cotacao == false) {
      this.selecionarPessoa();
      if (this.pessoa.Tipo === EnumTipoPessoa.Fisica)
        this.mascaraCpfCnpj = "000.000.000-00"
      else if (this.pessoa.Tipo === EnumTipoPessoa.Juridica)
        this.mascaraCpfCnpj = "00.000.000/0000-00"
    }

    if(this.cotacao) {
      this.pessoa = new Pessoa();
      this.pessoa.Papel = 'Fornecedor';
      return;
    }
  }
  telefoneOnChange(){
    if(this.pessoa.Telefone){
      this.telefoneInvalido = true;
    }else
    this.telefoneInvalido = false;

  }
  cpfOnChange(){
    if(this.pessoa.CpfResponsavel){
      this.cpfInvalido = true;
    }else
    this.cpfInvalido = false;

  }

  buscarCNPJ(){
    if(this.pessoa.Tipo === EnumTipoPessoa.Juridica && this.pessoa.CpfCnpj.length == 14){
      this.carregandoDadosCNPJ = true;
      this.pessoaService.getCNPJ(this.pessoa.CpfCnpj)
      .subscribe(res => {
        if(res.CpfCnpj){
          this.pessoa = res;
          if(this.pessoa.Enderecos.length > 0){
            this.enderecoPrincipal = this.pessoa.Enderecos[0];
            if(this.inserindo)
              this.pessoa.Enderecos = [];
            this.enderecoFormInternoComponentPrincipal.atualizarEndereco(this.enderecoPrincipal);
          }
        }

      this.carregandoDadosCNPJ = false;
      }, err => {
        this.utils.getErro(err);
      this.carregandoDadosCNPJ = false;
      })
    }
    else if(this.pessoa.Tipo == EnumTipoPessoa.Fisica){
    }
  }

  selecionarPessoa() {

    if(this.cotacao) {
      this.pessoa = new Pessoa();
      this.pessoa.Papel = 'Fornecedor';
      return;
    }

    if(this.id == 0) {
      this.pessoa = new Pessoa();
      return;
    }

    this.carregando = true;
    this.pessoaService.get(this.id).subscribe(
      res => {
        this.pessoa = res;
        this.enderecoPrincipal = res.Enderecos.filter(c => c.Tipo == EnumTipoEndereco.Principal)[0];
        this.enderecoFormInternoComponentPrincipal.meuForm.patchValue(this.enderecoPrincipal);

        if (!this.pessoa.EnderecoEntregaIgualPrincipal) {
          this.enderecoEntrega = res.Enderecos.filter(c => c.Tipo == EnumTipoEndereco.Entrega)[0];
        }
        if (!this.pessoa.EnderecoCorrespondenciaIgualPrincipal) {
          this.enderecoCorrespondencia = res.Enderecos.filter(c => c.Tipo == EnumTipoEndereco.Correspondencia)[0];
        }
        
        this.pessoaListaPapeis = this.pessoa.Papel.split(',');
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    )
  }

  changePapel(obj: string[]) {
    this.pessoa.Papel = '';
    obj.forEach(p => {
      this.pessoa.Papel += p + ',';
    })
    this.pessoa.Papel = this.pessoa.Papel.slice(0, -1);
  }

  changeTipo(tipo: EnumTipoPessoa) {
    switch (tipo) {
      case EnumTipoPessoa.Fisica:
        this.mascaraCpfCnpj = "000.000.000-00"
        break;
      case EnumTipoPessoa.Juridica:
        this.mascaraCpfCnpj = "00.000.000/0000-00"
        break;
    }
  }
  save(form: any) {
    debugger;
    console.debug(form);
    form.controls.CpfResponsavel.clearValidators();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    if (this.enderecoFormInternoComponentPrincipal.meuForm.invalid) {
      this.enderecoFormInternoComponentPrincipal.isSubmit = true;
      Swal.fire('Ops!', 'O Endereço Principal é obrigatório!', 'warning');
      this.carregando = false;
      return;
    }
    this.pessoa.Enderecos = [];
    this.enderecoPrincipal = this.enderecoFormInternoComponentPrincipal.meuForm.value;
    this.enderecoPrincipal.Tipo = EnumTipoEndereco.Principal;
    this.pessoa.Enderecos.push(this.enderecoPrincipal);

    if (!this.pessoa.EnderecoEntregaIgualPrincipal) {
      if (this.enderecoFormInternoComponentEntrega.meuForm.invalid) {
        this.enderecoFormInternoComponentEntrega.isSubmit = true;
        Swal.fire('Ops!', 'Preencha o Endereço de Entrega corretamente!', 'warning');
        this.carregando = false;
        return;
      }
      this.enderecoEntrega = this.enderecoFormInternoComponentEntrega.meuForm.value;
      this.enderecoEntrega.Tipo = EnumTipoEndereco.Entrega;
      this.pessoa.Enderecos.push(this.enderecoEntrega);
    }

    if (!this.pessoa.EnderecoCorrespondenciaIgualPrincipal) {
      if (this.enderecoFormInternoComponentCorrespondencia.meuForm.invalid) {
        this.enderecoFormInternoComponentCorrespondencia.isSubmit = true;
        Swal.fire('Ops!', 'Preencha o Endereço de Correspondencia corretamente!', 'warning');
        this.carregando = false;
        return;
      }
      this.enderecoCorrespondencia = this.enderecoFormInternoComponentCorrespondencia.meuForm.value;
      this.enderecoCorrespondencia.Tipo = EnumTipoEndereco.Correspondencia;
      this.pessoa.Enderecos.push(this.enderecoCorrespondencia);
    }
    this.carregando = true;
    if (this.pessoa.Id > 0) {
      this.pessoaService.put(this.pessoa.Id, this.pessoa).subscribe(
        res => {
          this.utils.handleSuccess("Pessoa alterada!");
          this.pessoa = res;
          this.inserindo = false;
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
      });
    } else {
      this.pessoaService.post(this.pessoa).subscribe(
        res => {
          this.utils.handleSuccess("Pessoa cadastrada!");
          this.carregando = false;
          this.pessoa = res;
          this.inserindo = false;
          this.carregando = false;
          this.PessoaCriadoEvento.emit();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
    }
  }

  cancelar() {
    this.inserindo = false;
    this.router.navigate(['/cadastros/pessoa']);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id) {
      this.selecionarPessoa();
    }
  }

}
