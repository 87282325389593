import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoAplicacao } from '../classes/TipoAplicacao';

@Injectable({
  providedIn: 'root'
})
export class TipoAplicacaoService {

  constructor(private http: HttpClient) { }

  getTiposAplicacao(): Observable<any>{
    return this.http.get(`${environment.apiURL}/tipoAplicacao`)
  }

  getTipoAplicacao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/tipoAplicacao/${id}`)
  }

  postTipoAplicacao(tipoAplicacao: TipoAplicacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/tipoAplicacao`, tipoAplicacao);
  }

  putTipoAplicacao(id: number, tipoAplicacao: TipoAplicacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/tipoAplicacao/${id}`, tipoAplicacao);
  }

  deleteTipoAplicacao(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/tipoAplicacao/${id}`);
  }
}
