import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IAlbum, IEvent, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent} from 'ngx-lightbox';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-gallery-custom',
  templateUrl: './gallery-custom.component.html',
  styleUrls: ['./gallery-custom.component.scss']
})
export class GalleryCustomComponent implements OnInit {

  @Output() excluirImagem = new EventEmitter();
  @Input() albums: Array<any>;
  private subscription: Subscription;
  constructor(private lightbox: Lightbox, private lightboxEvent: LightboxEvent, private lighboxConfig: LightboxConfig) {
    this.albums = this.albums ? this.albums : [];
    lighboxConfig.fadeDuration = 1;
  }

  ngOnInit() {
    console.log(this.albums)
  }

  open(index: number): void {
    console.log(this.albums)
    this.subscription = this.lightboxEvent.lightboxEvent$.subscribe((event: IEvent) => this._onReceivedEvent(event));
    this.lightbox.open(this.albums, index, { wrapAround: true, showImageNumberLabel: true });
  }

  deleteImagem(id){
    this.excluirImagem.emit({id: id})
  }
  private _onReceivedEvent(event: IEvent): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this.subscription.unsubscribe();
    }
  }


}
