import { Component, OnInit } from '@angular/core';
import { GrupoEquipamento } from 'src/app/classes/GrupoEquipamento';
import { Custos } from 'src/app/classes/gestao-lite/Custos';
import { FiltroCusto } from 'src/app/classes/gestao-lite/FiltroCusto';
import { GrupoEquipamentoService } from 'src/app/services/grupo-equipamento.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-tabela-relatorio',
  templateUrl: './tabela-relatorio.component.html',
  styleUrls: ['./tabela-relatorio.component.scss']
})
export class TabelaRelatorioComponent implements OnInit {
  dataInicio: Date;
  dataFim: Date;
  grupoId: number;
  textoBusca: string;

  custos: Custos[];
  auxCustos: Custos[];
  grupos: GrupoEquipamento[];

  constructor(private grupoEquipamentoService: GrupoEquipamentoService, private utilsService: UtilsService) {}

  ngOnInit() {
    let filtro: FiltroCusto;
    this.grupoEquipamentoService.getListaCustos(filtro).subscribe((res) => {
      this.custos = res;
      this.custos = this.custos.filter(c => c.CustoTotalGeral > 0);
      this.auxCustos = this.custos;
    });

    this.grupoEquipamentoService.getGrupoEquipamentos().subscribe((res) => {
      this.grupos = res;
    });
  }

  filtrarPorData() {
    let filtro = new FiltroCusto();
    filtro.DataInicio = this.dataInicio;
    filtro.DataFim = this.dataFim;

    this.grupoEquipamentoService.getListaCustos(filtro).subscribe((res) => {
      this.custos = res;
      this.auxCustos = res;
    },
    (error) => this.utilsService.getErro(error));
  }

  filtrarPorGrupo() {
    let filtro = new FiltroCusto();
    filtro.GrupoEquipamentoId = this.grupoId;

    this.grupoEquipamentoService.getListaCustos(filtro).subscribe((res) => {
      this.custos = res;
      this.auxCustos = res;
    },
    (error) => this.utilsService.getErro(error));
  }

  filtrarCustos() {
    this.custos = this.auxCustos.filter(c =>
      c.Equipamento.toUpperCase().includes(this.textoBusca.toUpperCase()));
  }
}
