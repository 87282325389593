import { ItemEnum } from "../ItemEnum";

export class FiltroMovimentoEstoque{
  OrdemServicoId: number;
  FazendaId: number;
  DataInicio: Date = new Date();
  DataFim: Date = new Date();
  ApontamentoId: number;
  ProdutosId: number[];
  Sincronizado: EnumSincronizadoEstoque;
  EmpresaInternaId: number;
  MovimentoEstoqueId: number;
  LocalEstoqueId: number
}

export enum EnumSincronizadoEstoque{
  Todos = 0,
  Sincronizado = 1,
  NaoSincronizado = 2
}

export const ListaEnumSincronizadoEstoque: ItemEnum[] = [
  {name: 'Todos', value: EnumSincronizadoEstoque.Todos},
  {name: 'Sincronizado', value: EnumSincronizadoEstoque.Sincronizado},
  {name: 'Não Sincronizado', value: EnumSincronizadoEstoque.NaoSincronizado},
];
