//TODO: Desmembrar componente e refatorar o código para melhorar a legibilidade e manutenção
import { GrupoProdutoService } from './../../services/grupo-produto.service';
import { ProdutoService } from './../../services/produto.service';
import { ItemOrdemServicoService } from './../../services/item-ordem-servico.service';
import { ItemOrdemServico, ItemOrdemServicoAux } from 'src/app/classes/ItemOrdemServico';
import { AtividadeService } from 'src/app/services/atividade.service';
import { UtilsService } from './../../utils/utils.service';
import { CultivarService } from 'src/app/services/cultivar.service';
import { Cultivar } from 'src/app/classes/Cultivar';
import { AreaService } from './../../services/area.service';
import { StorageService } from 'src/app/services/storage.service';
import { Fazenda } from 'src/app/classes/Fazenda';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import "leaflet-draw";
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Area } from 'src/app/classes/Area';
import { EnumStatusSafra, Safra } from 'src/app/classes/Safra';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { SafraService } from 'src/app/services/safra.service';
import { Cultura } from 'src/app/classes/Cultura';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { OrdemServico, EnumStatusOS, StatusOS } from 'src/app/classes/OrdemServico';
import Swal from 'sweetalert2';
import { Planejamento } from 'src/app/classes/Planejamento';
import { Atividade } from 'src/app/classes/Atividade';
import { Produto } from 'src/app/classes/Produto';
import { RetornoCusto, RetornoCustoGrupo } from 'src/app/classes/RetornoCusto';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { environment } from 'src/environments/environment';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { enumPerfil, Usuario } from 'src/app/classes/Usuario';
import { Router } from '@angular/router';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { GrupoOperacaoService } from 'src/app/services/grupo-operacao.service';
import { TelemetriaService } from 'src/app/services/telemetria.service';
import { Equipamento } from 'src/app/classes/Equipamento';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { Observable } from 'rxjs';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { AnoSafraService } from 'src/app/services/ano-safra.service';
import { Setor } from 'src/app/classes/Setor';
import { SetorService } from 'src/app/services/setor.service';
import { ComentarioSafraComponent } from './comentario-safra/comentario-safra.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AtividadePlanejamentoService } from 'src/app/services/atividade-planejamento.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { OrdemServicoAvulsaModalComponent } from '../ordem-servico/ordem-servico/componentes/ordem-servico-avulsa-modal/ordem-servico-avulsa-modal.component';
import { ApontamentoComponent } from 'src/app/components/ordem-servico/apontamento/apontamento.component';

//Função para usar no mapa
declare function getArrows(arrLatlngs: any, color: any, arrowCount: any, mapObj: any): any;
declare function setText(text: any, options: any): any;

const iconRetinaUrl = 'assets/images/marker-icon-2x.png';
const iconUrl = 'assets/images/marker-icon.png';
const shadowUrl = 'assets/images/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

interface ResumoOperacao {
  EquipamentoId: number;
  EquipamentoDescricao: string;
  EquipamentoIdIrrigaPrime: string;
  ImplementoId: number;
  ImplementoDescricao: string;
  OrdemServicoId: number;
  OrdemServicoEtapa: String;
  OrdemServicoStatus: EnumStatusOS;
  OrdemServicoDataInicio: Date;
  SafraDescricao: String;
  AreaDescricao: String;
  OperadorId?: number;
  OperadorNome?: string;
}


@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})
export class MapaComponent implements OnInit {

  @ViewChild('Apontamento', { static: false }) apontamentoComponent: ApontamentoComponent;

  @ViewChild('ModalOS', { static: false }) ordemServicoModalFormsComponent: OrdemServicoAvulsaModalComponent;

  abrirModalOS() {
    this.ordemServicoModalFormsComponent.showModalOS();
  }


  @ViewChild(ComentarioSafraComponent, { static: false }) comentarioSafraComponent: ComentarioSafraComponent;

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;
  @ViewChild('ModalRelease', { static: false })
  ModalRelease: UiModalComponent;
  public map;
  areaSelecionadaId: number = 0;
  fazenda: Fazenda;
  areas: Area[];
  areaSelecionada: Area = new Area;
  mostrarDetalhe: boolean = false;
  safras: Safra[];
  safra: Safra;
  safraId: number = 0;
  carregando: boolean;
  isAdmin: boolean;
  culturas: Cultura[];
  culturaSelecionadaId: number = 0;
  ordensServico: OrdemServico[] = [];
  planejamentos: Planejamento[] = [];
  planejamentosSelecionados: Planejamento[] = [];
  variedades: Cultivar[] = [];
  custoPlanejado: number = 0;
  custoRealizado: number = 0;
  custoTotalPlanejado: number = 0;
  custoTotalRealizado: number = 0;
  totalAreaSelecionada: number = 0;
  anoSafraId: number = 0;
  anosSafra: AnoSafra[];

  atividades: Atividade[] = [];
  ordensAplicacao: OrdemServico[] = [];
  ordensAplicacaoPendentes: OrdemServico[] = [];
  itensOrdemServico: ItemOrdemServico[] = [];
  itensOrdemServicoSelecionados: ItemOrdemServico[] = [];
  osDetalhe: number = 0;
  produtos: Produto[] = [];
  retornoCusto: RetornoCusto[] = [];
  auxRetornoCusto: RetornoCusto[] = [];
  retornoCustoPrevisto: RetornoCusto[] = [];
  auxRetornoCustoPrevisto: RetornoCusto[] = [];
  produtosCusto: RetornoCusto[];
  produtosCustoGrupo: RetornoCustoGrupo[] = [];
  gruposParaExibir: number[] = [];
  gruposExpandidos: boolean = false;

  pendenciasParaExibir: number[] = [];
  pendenciasExpandidas: boolean = false;
  itemOrdemServicoPendencias: ItemOrdemServicoAux[] = [];

  historicoAplicacoesParaExibir: number[] = [];
  historicoAplicacoesExpandidas: boolean = false;
  itemOrdemServicoHistoricoAplicacoes: ItemOrdemServicoAux[] = [];

  produtosCustoPrevisto: RetornoCusto[] = [];
  gruposProduto: GrupoProduto[];
  auxGruposProduto: GrupoProduto[];
  grupoDetalhe: number = 0;

  permissaoLiberar: boolean = false;
  ordemServicoApontamento: OrdemServico;
  ordemServicoLiberacao: OrdemServico;

  gruposOperacao: GrupoOperacao[];
  grupoOperacaoId: number = 0;
  mostrarSoPendentes: boolean = false;
  areasComPendencias: Area[] = [];
  mostrarPendenciasAtrasadas: boolean = false;
  qtdPendenteExecucao = 0;
  qtdPendenteLiberacao = 0;
  qtdParcialmenteExecutada = 0;
  statusFiltroPendentes: number = 0;
  temTelemetria: boolean = false;

  organizaPorSetor: boolean = false;
  setores: Setor[];
  setorId: number = 0;

  //Telemetria
  telemetriaEquipamento: any = [];
  historicosTelemetria: HistoricoTelemetria[] = [];
  equipamentos: Equipamento[] = [];
  baseIcone: string = environment.baseApiURL + '/Assets/Icones/';
  coresLinha: string[] = ["#00BFFF", "#00FF00", "#DAA520", "#BC8F8F", "#F5DEB3", "#FF6347"];
  markersEquipamento: any[] = [];
  pointsEquipamento: any[] = [];
  anoAgricolaId: number = 0;
  usuarioLogado: Usuario;
  quantidadeAplicacaoPlanejamento: number = 0;
  planoAgronomicoSelecionado: string = '';
  resumosOperacao: ResumoOperacao[] = [];
  auxExibeRastroEquipamento: number[] = [];

  filtros = false;
  pausarEquipamento: boolean = false;

  listaStatus = StatusOS;

  // moeda
  cifraMoedaCorrente: string;

  // tradução
  MAIS_ZOOM: string = 'Mais Zoom';
  MENOS_ZOOM: string = 'Menos Zoom';
  LEGENDA: string = 'Legenda';
  NAO_PLANTADO: string = 'Não plantado';
  EM_DIA: string = 'Em dia';
  PENDENTE: string = 'Pendente';
  CANCELAR_A_ORDEM_DE_SERVICO: string = 'Cancelar a Ordem de Serviço';
  SE_CANCELAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se cancelar, esta ação não poderá ser desfeita!';
  VOLTAR: string = 'Voltar';
  CONFIRMAR: string = 'Confirmar';
  ORDEM_DE_SERVICO_CANCELADA: string = 'Ordem de Serviço Cancelada!';
  FEITO: string = 'Feito!';
  EQUIPAMENTO: string = 'Equipamento:';
  IMPLEMENTO: string = 'Implemento:';
  OCULTAR_RASTRO: string = 'Ocultar rastro';
  EXIBIR_RASTRO: string = 'Exibir rastro';
  DATA_PREVISTA_ALTERADA_COM_SUCESSO: string = 'Data prevista alterada com sucesso!';

  private subscription;

  constructor(private setorService: SetorService,
    private storageService: StorageService,
    private areaService: AreaService,
    private ordemServicoService: OrdemServicoService,
    private safraService: SafraService,
    private planejamentoService: PlanejamentoService,
    private cultivarService: CultivarService,
    private utils: UtilsService,
    private atividadeService: AtividadeService,
    private itemOrdemServicoService: ItemOrdemServicoService,
    private produtoService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private toasty: CustomToastyService,
    private router: Router,
    private http: HttpClient,
    private grupoOperacaoService: GrupoOperacaoService,
    private telemetriaService: TelemetriaService,
    private equipamentoService: EquipamentoService,
    private anoSafraService: AnoSafraService,
    private usuarioService: UsuarioService,
    private atividadePlanejamentoService: AtividadePlanejamentoService,
    private translate: TranslateService) { }
  ngOnInit() {
    this.fazenda = this.storageService.getFazenda();
    this.permissaoLiberar = this.storageService.getUsuario().PermissaoUsuario.LiberarOS;
    this.usuarioLogado = this.storageService.getUsuario();
    this.isAdmin = this.usuarioLogado.Perfil == enumPerfil.Admin || this.usuarioLogado.isSuper;
    this.temTelemetria = this.storageService.getEmpresa().UsaTelemetriaIrrigaPrime;
    this.anoAgricolaId = this.storageService.getAnoAgricolaId();

    this.planejamentoService.getCulturas().subscribe(ret => {
      this.culturas = ret;
      this.culturaSelecionadaId = 0;
    },
      err => {
        this.utils.getErro(err);
      });

    this.cultivarService.getCultivares().subscribe(ret => {
      this.variedades = ret;
    },
      err => {
        this.utils.getErro(err);
      })

    this.grupoOperacaoService.getGruposOperacao().subscribe(ret => {
      this.gruposOperacao = ret;
      this.grupoOperacaoId = 0;
    },
      err => {
        this.utils.getErro(err);
      })

    this.atividadeService.getAtividades().subscribe(
      ret => {
        this.atividades = ret;
      },
      err => {
        this.utils.getErro(err);
      })

    this.grupoProdutoService.getGruposProduto().subscribe(ret => {
      this.auxGruposProduto = ret;
    })

    this.equipamentoService.getEquipamentos().subscribe((ret) => {
      this.equipamentos = ret;
    });

    this.cifraMoedaCorrente = this.utils.getCifraMoeda();
  }

  ngAfterViewInit() {
    if (this.fazenda) {
      this.organizaPorSetor = this.storageService.getEmpresa().UsaSetores;
      if (this.organizaPorSetor) {
        this.setorService.getSetoresPorFazenda(this.fazenda.Id).subscribe(ret => {
          this.setores = ret;
        });
      }
      this.areaService.getAreasPorFazenda(this.fazenda.Id).subscribe(ret => {
        this.areas = ret;
        this.anoSafraService.getAnosSafrasPorAnoAgricola(this.anoAgricolaId).subscribe(ret1 => {
          this.anosSafra = ret1;
          if (this.anosSafra && this.anosSafra.length > 0) {
            this.anoSafraId = this.anosSafra[0].Id;
          }
          this.listarSafraPorAno();
        })
      },
        err => {
          this.utils.getErro(err);
        })
    }
    else
      this.configurarTraducaoEIniciarMapa();
    //this.iniciarMapa();

    if (!this.usuarioLogado.LeuRelease)
      this.ModalRelease.show();

  }

  listarSafraPorAno() {
    this.safraService.getSafrasPorAnoFazenda(this.anoSafraId, this.fazenda.Id).subscribe(ret => {
      this.safras = ret;
      if (this.safras && this.safras.length > 0) {
        this.safraId = this.safras[0].Id;
        this.selecionarSafra();
      }
      else {
        this.iniciarMapaVazio();
      }
    },
      err => {
        this.iniciarMapaVazio();
      })
  }

  iniciarMapaVazio() {
    this.ordensServico = [];
    this.areas.forEach(
      x => {
        x.Selecionada = false;
        x.EmAtraso = false;
      });
    this.configurarTraducaoEIniciarMapa();
    //this.iniciarMapa();
  }

  iniciarMapa() {
    let corNaoPlantado = "#B0B0B0";
    let corVerde = "#0D8557";
    let corAmarela = "#FFD54F";
    let corVermelha = "#C62828";
    let corBorda = "#CBCBCB";
    let corTexto = "#fff"
    let corTooltip = "#000";
    let opacityBorda = 0.5;
    let opacityFundo = 0.95;
    let larguraBorda = 3;

    if (this.map) {
      this.map.off();
      this.map.remove();
    }
    var satellite = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { maxZoom: 19 });
    var googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', { maxZoom: 20, subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] });

    let latitude: number = 0;
    let longitude: number = 0;
    if (this.fazenda && this.fazenda.Latitude && this.fazenda.Longitude) {
      latitude = this.fazenda.Latitude;
      longitude = this.fazenda.Longitude;
    }
    else {
      latitude = -17.217256772367985; //-17.760682983418558;
      longitude = -46.87184929847718; //-46.537541320104054;
    }

    this.map = L.map('map', {
      center: [latitude, longitude],
      zoom: 13,
      zoomControl: false,
      attributionControl: false,
      layers: googleSat
    });

    L.control.zoom({ zoomInTitle: this.MAIS_ZOOM, zoomOutTitle: this.MENOS_ZOOM, position: 'topright' }).addTo(this.map);

    var legend = L.control({ position: "bottomleft" });

    var map = this.map;

    let leg = this.LEGENDA
    let naoPlantado = this.NAO_PLANTADO
    let emDia = this.EM_DIA
    let pendente = this.PENDENTE

    legend.onAdd = function (map) {
      var div = L.DomUtil.create("div", "legenda-map");
      div.innerHTML += "<h4>" + leg + "</h4>";
      div.innerHTML += '<i class="fas fa-circle" style="color: ' + corNaoPlantado + '"></i>' + naoPlantado + '<br>';
      div.innerHTML += '<i class="fas fa-circle" style="color: ' + corVerde + '"></i>' + emDia + '<br>';
      div.innerHTML += '<i class="fas fa-circle" style="color: ' + corVermelha + '"></i>' + pendente + '<br>';
      return div;
    };

    legend.addTo(map);

    //Marcação da Fazenda
    if (this.fazenda && this.fazenda.Latitude && this.fazenda.Longitude) {
      var marker = L.marker([latitude, longitude]).addTo(this.map);
      marker.bindTooltip(this.fazenda.Nome, { direction: 'top', permanent: false, opacity: 0.7 });
    }

    if (this.areas && this.areas.length > 0) {
      this.areas.forEach(area => {
        if (area.LayersArea) {
          let corFundo = (area.Selecionada ? (!area.EmAtraso ? corVerde : corVermelha) : corAmarela);
          let iconeCultura = "assets/images/transparent.png";
          //let iconeCultura = (area.Selecionada && area.CulturaId > 0 && this.getCultura(area.CulturaId) ? "assets/images/cultura/" + this.getCultura(area.CulturaId).Icone + "_w.png" : "assets/images/transparent.png");
          let iconMarker = L.divIcon({
            className: 'div-equipamento-icon',
            // html: '<img class="div-image" style="width: 20px; height: 20px;" src="' + iconeCultura + '"/>' +
            //   '<div class="div-label-equipamento" style="color: '+corTexto+'; font-size: 10px; width: 80px; margin-left: -35px; ' + (iconeCultura.includes('transparent') ? 'margin-top: -15px;' : '') + ' text-align:center; ">' + (area.Sigla ? area.Sigla : "") + '</div>'
            html: '<div class="div-label-equipamento" style="color: ' + corTexto + '; font-size: 10px; width: 80px; margin-left: -45px; text-align:center; ">' + (area.Sigla ? area.Sigla : "") + '</div>'
          });
          let layers = JSON.parse(area.LayersArea);
          layers.forEach(layer => {
            if (layer.tipo == "circle") {
              var circulo = L.circle(layer.latlng, { radius: layer.raio, color: corBorda, weight: larguraBorda, opacity: opacityBorda, lineJoin: 'round', fillColor: corFundo, fillOpacity: opacityFundo })
                .setStyle('box-shadow: 0px 2px 8px 0px rgba(255, 255, 255, 0.4) !important;')
                .on('click', function (e) {
                  sessionStorage.setItem("AreaClickID", area.Id.toString());//clique
                }).addTo(this.map);

              circulo.bindTooltip("<span style='color: " + corTooltip + "'><b>" + area.Descricao + "</b> <small>(" + area.AreaTotal.toString() + " ha)</small></span>", { direction: 'top', permanent: false, opacity: 0.95, sticky: true });

              //Marcador para label da área
              let marker = L.marker(layer.latlng,
                {
                  clickable: true,
                  draggable: false,
                  icon: iconMarker
                }).on('click', function (e) {
                  sessionStorage.setItem("AreaClickID", area.Id.toString());//clique
                }).addTo(this.map);

            }
            else if (layer.tipo == "polygon") {
              var polygon = L.polygon(layer.latlng, { color: corBorda, weight: larguraBorda, opacity: opacityBorda, lineJoin: 'round', fillColor: corFundo, fillOpacity: opacityFundo })
                .setStyle('box-shadow: 0px 2px 8px 0px rgba(255, 255, 255, 0.4) !important;')
                .on('click', function (e) {
                  sessionStorage.setItem("AreaClickID", area.Id.toString());//clique
                }).addTo(this.map);
              polygon.bindTooltip("<span style='color: " + corTooltip + "'><b>" + area.Descricao + "</b> <small>(" + area.AreaTotal.toString() + " ha)</small></span>", { direction: 'top', permanent: false, opacity: 0.95, sticky: true });

              //Marcador para label da área

              L.marker([this.getCentroPoligono(layer.latlng).lat, this.getCentroPoligono(layer.latlng).lng],
                {
                  clickable: true,
                  draggable: false,
                  icon: iconMarker
                }).on('click', function (e) {
                  sessionStorage.setItem("AreaClickID", area.Id.toString());//clique
                }).addTo(this.map);

            }
            else if (layer.tipo == "marker") {
              var marker = L.marker(layer.latlng).addTo(this.map);
              marker.bindTooltip("<span style='color: " + corTexto + "'>" + area.Descricao + "</span>", { direction: 'top', permanent: false, opacity: 0.95 });
            }
          });
        }
      })
    }
    document.getElementById('map').click();
    if (this.temTelemetria) {
      //this.carregarTelemetria();
      // Chama intervalo de 3 segundos para atualizar a telemetria
      this.subscription = Observable.interval(1000)
        .subscribe((val) => {
          //this.atualizarTelemetria();
        });
    }

    //Aplicar div none para as labels dos equipamentos
    var divs = document.getElementsByClassName('div-label-equipamento');
    // Itere sobre todas as divs
    for (var i = 0; i < divs.length; i++) {
      // Faz um cast seguro para HTMLElement
      var div = divs[i] as HTMLElement;
      div.style.display = 'none';
    }

    //verificar zoom do mapa
    this.map.on('zoomend', function () {
      var zoomLevel = this.getZoom();

      // Obtenha todas as divs com a classe 'div-label-equipamento'
      var divs = document.getElementsByClassName('div-label-equipamento');
      // Itere sobre todas as divs
      for (var i = 0; i < divs.length; i++) {
        // Faz um cast seguro para HTMLElement
        var div = divs[i] as HTMLElement;
        if (zoomLevel >= 15) {
          div.style.display = 'block';
        } else {
          div.style.display = 'none';
        }
      }
    });
  }

  filtrarPorSetor() {
    if (this.fazenda) {
      this.areaService.getAreasPorFazenda(this.fazenda.Id).subscribe(ret => {
        this.areas = ret;
        if (this.setorId != 0)
          this.areas = this.areas.filter(x => x.SetorId == this.setorId);
        //this.iniciarMapa();
        this.configurarTraducaoEIniciarMapa();
        this.carregarDetalhamento();
      },
        err => {
          this.utils.getErro(err);
        })
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    var areaId = sessionStorage.getItem("AreaClickID");
    sessionStorage.setItem("AreaClickID", "");
    if (areaId) {
      this.carregarOs(areaId);
    }
  }

  fecharModal() {
    this.areaSelecionadaId = null;
    this.Modal.hide();
  }

  carregarOs(areaId) {
    this.filtros = false;
    this.carregando = true;
    this.custoPlanejado = 0;
    this.custoRealizado = 0;
    this.areaSelecionadaId = parseInt(areaId);
    this.areaSelecionada = this.areas.find(x => x.Id == areaId);
    this.mostrarSoPendentes = false;
    this.mostrarDetalhe = true;
    this.quantidadeAplicacaoPlanejamento = 0;
    this.planejamentoService.getPlanejamentosPorSafraArea(this.safraId, areaId).subscribe(ret => {
      this.planejamentosSelecionados = ret;
      this.ordemServicoService.custoPorSafra(this.fazenda.Id, areaId, this.safraId).subscribe(ret2 => {
        this.custoRealizado = ret2.custoRealizado;
        this.ordensAplicacao = this.getAplicacoesArea(areaId, true);
        this.ordensAplicacaoPendentes = this.getOrdensServicoArea(areaId, false);
        this.ordensAplicacao = this.ordensAplicacao.sort((x1, x2) => (x1.DataRealizada < x2.DataRealizada ? -1 : 1));
        this.ordensAplicacaoPendentes = this.ordensAplicacaoPendentes.sort((x1, x2) => (x1.DataPrevista < x2.DataPrevista ? -1 : 1));
        this.comentarioSafraComponent.area = this.areaSelecionada;
        this.comentarioSafraComponent.listar();
        this.carregando = false;
      })

      if (this.planejamentosSelecionados == null) {
        this.gruposProduto = null;
        this.custoTotalPlanejado = null;
        this.totalAreaSelecionada = null;
        return;
      }

      this.planejamentosSelecionados.forEach(planejamento => {

        this.planejamentoService.getPlanejamento(planejamento.PlanoMestreProducaoId).subscribe(ret => {
          planejamento.DescricaoPlanoMestreProducao = ret.Descricao;
        });

        this.atividadePlanejamentoService.getQuantidadeAplicacaoPlanejada(planejamento.Id).subscribe(ret => {
          this.quantidadeAplicacaoPlanejamento += ret;
        })

        this.planejamentoService.getCustoPorHa(planejamento.Id).subscribe(ret => {
          this.custoPlanejado += ret;
        })

        this.planejamentoService.custo(planejamento.Id).subscribe(
          ret => {
            this.retornoCustoPrevisto = ret;
            this.auxRetornoCustoPrevisto = ret;
            this.listarCustos(areaId);
            this.carregando = false;
          },
          err => {
            console.log("ERRO CUSTO => ", err);
            this.carregando = false;
          });

        let dataColheita = this.getDataPlantio(planejamento);
        this.atividadePlanejamentoService.getDiasAposPlantioParaColheita(planejamento.Id).subscribe(ret => {
          if (ret > 0) {
            dataColheita.setDate(dataColheita.getDate() + ret);
            planejamento.DataPrevistaColheita = dataColheita
          }
          else {
            let variedade = this.getVariedade(planejamento.CultivarId);
            if (variedade)
              dataColheita.setDate(dataColheita.getDate() + variedade.Ciclo);
            planejamento.DataPrevistaColheita = dataColheita
          }
        });
      })
    },
      err => {
        this.utils.getErro(err);
      })
  }

  getPlanejamentoAgronomico(planejamentoId: number) {
    this.planejamentoService.getPlanejamento(planejamentoId).subscribe(ret => {
      let planejamento: Planejamento = ret;
      return planejamento;
    });
  }

  getIdadeLavoura(planejamento): number {
    var hoje = new Date()
    hoje = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate());
    let dias = this.utils.getDiasEntreDatas(this.getDataPlantio(planejamento), hoje);
    return dias > 0 ? dias : 0;
  }

  getEncerrou(dataPrevisaoColheita): boolean {
    var hoje = new Date()
    var colheita = new Date(dataPrevisaoColheita);
    hoje = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate());

    if (this.safra.Status == EnumStatusSafra.Finalizada && hoje > colheita)
      return true;
    else
      return false;
  }

  getPrevisaoColheita(planejamento: Planejamento): Date {
    let variedade = this.getVariedade(planejamento.CultivarId);
    let dataColheita = this.getDataPlantio(planejamento);
    if (variedade)
      dataColheita.setDate(dataColheita.getDate() + variedade.Ciclo);
    return dataColheita;
  }

  getDataPlantio(planejamento: Planejamento): Date {
    if (planejamento.DataPrevistaPlantio.toString()) {
      return new Date(planejamento.DataPrevistaPlantio.toString());
    }
    return new Date(planejamento.DataRealPlantio.toString() == '0001-01-01T00:00:00' ? planejamento.DataPrevistaPlantio.toString() : planejamento.DataRealPlantio.toString());
  }

  getAplicacoesArea(areaId: number, concluidas: boolean): OrdemServico[] {
    let ordens: OrdemServico[] = [];
    let ordensServicoId: number[] = [];
    this.ordensServico.filter(o => o.AreaId == areaId && (concluidas ? o.Status == EnumStatusOS.Concluida : o.Status != EnumStatusOS.Concluida)).forEach(o => {
      ordens.push(o);
      ordensServicoId.push(o.Id);
    })
    return ordens;
  }

  getOrdensServicoArea(areaId: number, concluidas: boolean): OrdemServico[] {
    let ordens: OrdemServico[] = [];
    let ordensServicoId: number[] = [];
    this.ordensServico.filter(o => o.AreaId == areaId && (concluidas ? o.Status == EnumStatusOS.Concluida : o.Status != EnumStatusOS.Concluida)).forEach(o => {
      ordens.push(o);
      ordensServicoId.push(o.Id);
    })
    return ordens;
  }

  getQtdAplicacoes(areaId: number, concluidas: boolean): number {
    let atividadesId: number[] = [];
    let atividadesAplicacao: number[] = [];

    this.ordensServico.filter(o => o.AreaId == areaId && (concluidas ? o.Status == EnumStatusOS.Concluida : o.Status != EnumStatusOS.Concluida)).forEach(o => {
      atividadesId.push(o.AtividadeId);
    });

    this.atividades.filter(a => atividadesId.includes(a.Id)).forEach(a => {
      if (a.InformarTipoAplicacao) atividadesAplicacao.push(a.Id);
    })

    return atividadesAplicacao.length;
  }

  exibeItens(id: number) {
    this.osDetalhe = id;
    this.itensOrdemServico = [];
    this.produtos = [];
    if (id > 0) {
      this.itemOrdemServicoService.getItensOrdemServico(id).subscribe(ret => {
        this.itensOrdemServico = ret;
        this.itensOrdemServico.forEach(x => {
          this.produtoService.getProduto(x.ProdutoId).subscribe(retProd => {
            this.produtos.push(retProd);
          })
        })
      })
    }
  }

  getProduto(id: number): Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';

    if (!this.produtos)
      return novo;

    let produto = this.produtos.filter(x =>
      x.Id == id
    );

    return produto.length > 0 ? produto[0] : novo;
  }

  descarregarOs() {
    this.areaSelecionadaId = null;
    this.areaSelecionada = new Area;
    this.planejamentosSelecionados = [];
    this.ordensAplicacao = [];
    this.itensOrdemServico = [];
    this.produtos = [];
    this.mostrarDetalhe = false;
    this.mostrarSoPendentes = false;
  }

  onKeyDown(event) {
    if (event.keyCode === 27) {
      this.descarregarOs();
    }
  }

  selecionarSafra() {
    this.safraService.getSafra(this.safraId).subscribe(ret => {
      this.safra = ret;
    });
    this.carregarDetalhamento();
  }

  selecionaOperacao() {
    this.carregarDetalhamento();
  }

  carregarDetalhamento() {
    this.areaSelecionadaId = 0;
    this.areaSelecionada = new Area;
    let filtros: any = {};
    let fazendasFiltro: number[] = [];
    let safrasFiltro: number[] = [];
    let operacoes: number[] = [];
    let dataInicio = new Date();
    let dataFim = new Date();
    let status: EnumStatusOS[] = [];
    dataFim.setDate(dataInicio.getDate() + (365 * 2));
    dataInicio.setDate(dataInicio.getDate() - (365 * 2));

    fazendasFiltro.push(this.fazenda.Id);
    safrasFiltro.push(this.safraId);

    if (this.grupoOperacaoId > 0)
      operacoes.push(this.grupoOperacaoId);

    status.push(EnumStatusOS.Cancelada);

    filtros.Fazenda = fazendasFiltro;
    filtros.Safra = safrasFiltro;
    filtros.AgruparPorArea = true;
    filtros.dataInicio = dataInicio;
    filtros.dataFim = dataFim;
    filtros.Operacao = operacoes;
    filtros.Status = status;
    filtros.StatusNotIn = true;
    filtros.Cultura = this.culturaSelecionadaId;

    this.ordemServicoService.detalhamento(filtros).subscribe(
      ret => {
        this.ordensServico = ret;
        this.areas.forEach(
          x => {
            x.Selecionada = this.ordensServico.filter(o => o.AreaId == x.Id).length > 0;
            x.EmAtraso = this.ordensServico.filter(o => o.AreaId == x.Id && o.EmAtraso && o.Status != 5).length > 0;
            x.CulturaId = this.ordensServico.filter(o => o.AreaId == x.Id).length > 0 ? this.ordensServico.filter(o => o.AreaId == x.Id)[0].CulturaId : 0;
          });

        //this.iniciarMapa();
        this.configurarTraducaoEIniciarMapa();

        this.resumosOperacao = [];

        this.ordensServico.forEach((x) => {
          let equipamento = this.equipamentos.find(eq => eq.Id == x.EquipamentoId);
          let implemento = this.equipamentos.find(eq => eq.Id == x.ImplementoId);
          if (equipamento) {
            let resumoOperacao: ResumoOperacao = {
              EquipamentoId: equipamento.Id,
              EquipamentoDescricao: equipamento.Descricao,
              EquipamentoIdIrrigaPrime: equipamento.IdIrrigaPrime,
              ImplementoId: x.ImplementoId,
              ImplementoDescricao: (implemento ? implemento.Descricao : ''),
              OrdemServicoId: x.Id,
              OrdemServicoEtapa: x.DescricaoEtapa,
              OrdemServicoStatus: x.Status,
              OrdemServicoDataInicio: x.DataPrevista,
              SafraDescricao: x.DescricaoSafra,
              AreaDescricao: x.DescricaoArea,
            };
            this.resumosOperacao.push(resumoOperacao);
          }
        });

      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  listarCustos(areaId: number) {
    this.carregando = true;
    let filtro: any = {
      Periodo: 0,
      Fazenda: [this.fazenda.Id],
      Safra: [this.safraId],
      Status: [EnumStatusOS.Cancelada],
      StatusNotIn: true,
      AreaId: areaId
    }

    this.ordemServicoService.custo(filtro).subscribe(
      ret => {
        this.retornoCusto = ret;
        this.auxRetornoCusto = ret;
        this.custosPorArea(areaId);
        this.carregando = false;
      },
      err => {
        console.log("ERRO CUSTO => ", err);
        this.carregando = false;
      });
  }

  custosPorArea(areaId: number) {
    this.custoTotalPlanejado = 0;
    this.custoTotalRealizado = 0;
    this.totalAreaSelecionada = 0;
    this.auxRetornoCusto = this.retornoCusto.filter(c => c.AreaId == areaId);
    this.auxRetornoCustoPrevisto = this.retornoCustoPrevisto.filter(c => c.AreaId == areaId);

    this.gruposProduto = this.auxGruposProduto.filter(gru => this.auxRetornoCusto.filter(c => c.GrupoProdutoId == gru.Id).length > 0 || this.auxRetornoCustoPrevisto.filter(c => c.GrupoProdutoId == gru.Id).length > 0);
    this.gruposProduto.forEach(grupo => {
      grupo.CustoPrevisto = 0;
      grupo.CustoRealizado = 0;
      grupo.CustoPrevistoHa = 0;
      grupo.CustoRealizadoHa = 0;
      //custo realizado
      this.auxRetornoCusto.filter(c => c.GrupoProdutoId == grupo.Id).forEach(custo => {
        if (custo.Status == 5) {
          grupo.CustoRealizado += custo.PrecoTotal;
          grupo.CustoRealizadoHa += (grupo.CustoRealizado / custo.AreaPlantada);
          this.totalAreaSelecionada = custo.AreaPlantada;
        }
      })
      //custo previsto
      this.auxRetornoCustoPrevisto.filter(c => c.GrupoProdutoId == grupo.Id).forEach(custo => {
        grupo.CustoPrevisto += custo.PrecoTotal;
        grupo.CustoPrevistoHa += custo.PrecoPorHa;
        this.custoTotalPlanejado += custo.PrecoTotal;
      })

      this.custoTotalRealizado += grupo.CustoRealizado;
    })
    this.gruposProduto = this.gruposProduto.sort((x1, x2) => x2.CustoRealizadoHa - x1.CustoRealizadoHa);
  }

  exibirItensCusto(areaId: number, grupoId: number) {
    this.produtosCusto = [];
    this.grupoDetalhe = grupoId;
    if (this.gruposParaExibir.filter(x => x == grupoId).length == 0) {
      this.gruposParaExibir.push(grupoId);
    }
    if (this.grupoDetalhe > 0 && this.produtosCustoGrupo.filter(x => x.GrupoId == grupoId).length == 0) {
      this.retornoCusto.filter(x => x.AreaId == areaId && x.GrupoProdutoId == grupoId).forEach(x => {
        if (!this.produtosCusto.find(p => p.ProdutoId == x.ProdutoId))
          this.produtosCusto.push(x);
      });

      this.retornoCustoPrevisto.filter(x => x.AreaId == areaId && x.GrupoProdutoId == grupoId).forEach(x => {
        if (!this.produtosCusto.find(p => p.ProdutoId == x.ProdutoId))
          this.produtosCusto.push(x);
      });
      let grupo = new RetornoCustoGrupo();
      grupo.GrupoId = grupoId;
      grupo.RetornoCusto = this.produtosCusto;
      this.produtosCustoGrupo.push(grupo);
    }
  }

  getProdutoCusto(id: number) {
    return this.produtosCustoGrupo.filter(x => x.GrupoId == id)[0].RetornoCusto;
  }

  removerGrupo(grupoId) {
    if (this.gruposParaExibir.filter(x => x == grupoId).length > 0) {
      this.gruposParaExibir = this.gruposParaExibir.filter(x => x != grupoId);
    }

  }

  exibeProdutoCusto(id: number) {
    return this.gruposParaExibir.filter(x => x == id).length > 0;
  }

  toggleExpandirTodosGrupos() {
    this.gruposExpandidos = !this.gruposExpandidos;
    for (let grupo in this.gruposProduto) {
      if (this.gruposExpandidos) {
        this.exibirItensCusto(this.areaSelecionadaId, this.gruposProduto[grupo].Id)
      } else {
        this.removerGrupo(this.gruposProduto[grupo].Id)
      }
    }
  }

  toggleExpandirTodasPendencias() {
    this.pendenciasExpandidas = !this.pendenciasExpandidas;
    for (let ordemServico in this.ordensAplicacaoPendentes) {
      if (this.pendenciasExpandidas) {
        this.exibeItensPendencia(this.ordensAplicacaoPendentes[ordemServico].Id)
      } else {
        this.removerPendencia(this.ordensAplicacaoPendentes[ordemServico].Id)
      }
    }
  }
  exibePendencia(id: number) {
    return this.pendenciasParaExibir.filter(x => x == id).length > 0;
  }

  removerPendencia(id) {
    if (this.pendenciasParaExibir.filter(x => x == id).length > 0) {
      this.pendenciasParaExibir = this.pendenciasParaExibir.filter(x => x != id);
    }

  }

  exibeItensPendencia(id: number) {
    this.osDetalhe = id;
    if (this.pendenciasParaExibir.filter(x => x == id).length == 0) {
      this.pendenciasParaExibir.push(id);
    }
    this.itensOrdemServico = [];
    if (id > 0 && this.itemOrdemServicoPendencias.filter(x => x.OrdemServicoId == id).length == 0) {
      this.itemOrdemServicoService.getItensOrdemServico(id).subscribe(ret => {
        this.itensOrdemServico = ret;
        this.itensOrdemServico.forEach(x => {
          this.produtoService.getProduto(x.ProdutoId).subscribe(retProd => {
            this.produtos.push(retProd);
          })
        })
        let grupo = new ItemOrdemServicoAux();
        grupo.OrdemServicoId = id;
        grupo.ItemOrdemServico = this.itensOrdemServico;
        this.itemOrdemServicoPendencias.push(grupo);
      })
    }
  }

  getItensPendencia(id: number) {
    let pendencias = this.itemOrdemServicoPendencias.filter(x => x.OrdemServicoId == id);
    if (pendencias != null && pendencias != undefined && pendencias.length > 0) {
      if (pendencias[0].ItemOrdemServico != null && pendencias[0].ItemOrdemServico != undefined && pendencias[0].ItemOrdemServico.length > 0)
        return this.itemOrdemServicoPendencias.filter(x => x.OrdemServicoId == id)[0].ItemOrdemServico;
      else
        return [];
    }
    else
      return [];

  }

  toggleExpandirTodosHistoricosAplicacoes() {
    this.historicoAplicacoesExpandidas = !this.historicoAplicacoesExpandidas;
    for (let ordemServico in this.ordensAplicacao) {
      if (this.historicoAplicacoesExpandidas) {
        this.exibeItensHistoricosAplicacoes(this.ordensAplicacao[ordemServico].Id)
      } else {
        this.removerHistoricosAplicacoes(this.ordensAplicacao[ordemServico].Id)
      }
    }
  }

  exibeHistoricosAplicacoes(id: number) {
    return this.historicoAplicacoesParaExibir.filter(x => x == id).length > 0;
  }

  removerHistoricosAplicacoes(id) {
    if (this.historicoAplicacoesParaExibir.filter(x => x == id).length > 0) {
      this.historicoAplicacoesParaExibir = this.historicoAplicacoesParaExibir.filter(x => x != id);
    }
  }

  exibeItensHistoricosAplicacoes(id: number) {
    this.osDetalhe = id;
    if (this.historicoAplicacoesParaExibir.filter(x => x == id).length == 0) {
      this.historicoAplicacoesParaExibir.push(id);
    }
    this.itensOrdemServico = [];
    if (id > 0 && this.itemOrdemServicoHistoricoAplicacoes.filter(x => x.OrdemServicoId == id).length == 0) {
      this.itemOrdemServicoService.getItensOrdemServico(id).subscribe(ret => {
        this.itensOrdemServico = ret;
        this.itensOrdemServico.forEach(x => {
          this.produtoService.getProduto(x.ProdutoId).subscribe(retProd => {
            this.produtos.push(retProd);
          })
        })
        let grupo = new ItemOrdemServicoAux();
        grupo.OrdemServicoId = id;
        grupo.ItemOrdemServico = this.itensOrdemServico;
        this.itemOrdemServicoHistoricoAplicacoes.push(grupo);
      })
    }
  }

  getItensHistoricoAplicacoes(id: number) {
    return this.itemOrdemServicoHistoricoAplicacoes.filter(x => x.OrdemServicoId == id)[0].ItemOrdemServico;
  }

  getCustoTotalProdutoRealizado(areaId: number, grupoId: number, produtoId: number) {
    let custo = 0;
    this.retornoCusto.filter(x => x.AreaId == areaId && x.GrupoProdutoId == grupoId && x.ProdutoId == produtoId).forEach(x => {
      if (x.Status == 5)
        custo += x.PrecoTotal;
    });
    return custo;
  }

  getCustoTotalProdutoPrevisto(areaId: number, grupoId: number, produtoId: number) {
    let custo = 0;
    this.retornoCustoPrevisto.filter(x => x.AreaId == areaId && x.GrupoProdutoId == grupoId && x.ProdutoId == produtoId).forEach(x => {
      custo += x.PrecoTotal;
    });
    return custo;
  }

  getCustoTotalProdutoRealizadoHa(areaId: number, grupoId: number, produtoId: number) {
    let custo = 0;
    this.retornoCusto.filter(x => x.AreaId == areaId && x.GrupoProdutoId == grupoId && x.ProdutoId == produtoId).forEach(x => {
      if (x.Status == 5)
        custo += x.PrecoPorHa;
    });
    return custo;
  }

  getCustoTotalProdutoPrevistoHa(areaId: number, grupoId: number, produtoId: number) {
    let custo = 0;
    this.retornoCustoPrevisto.filter(x => x.AreaId == areaId && x.GrupoProdutoId == grupoId && x.ProdutoId == produtoId).forEach(x => {
      custo += x.PrecoPorHa;
    });
    return custo;
  }

  getCultura(id: number): Cultura {
    if (this.culturas == null || this.culturas.length == 0)
      return null;
    return this.culturas.find(x => x.Id == id);
  }

  getVariedade(id: number): Cultivar {
    return this.variedades.find(x => x.Id == id);
  }

  imprimir(id: number) {
    this.carregando = true;
    this.http.get(`${environment.apiURL}/ordemServico/gerarPDF/${this.fazenda.EmpresaId}/${id}/${this.storageService.getUsuario().Id}`, { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }

  apontamento(ordemServico: OrdemServico) {
    this.ordemServicoApontamento = ordemServico;
    this.apontamentoComponent.novoApontamentoComOrdem(ordemServico);
  }

  liberacao(ordemServico: OrdemServico) {
    this.ordemServicoLiberacao = ordemServico;
  }

  liberado() {
    let areaId = this.ordemServicoLiberacao.AreaId;
    this.ordemServicoLiberacao = null;
    this.carregarDetalhamento();
    this.carregarOs(areaId);
  }

  apontado() {
    let areaId = this.ordemServicoApontamento.AreaId;
    this.ordemServicoApontamento = null;
    this.carregarDetalhamento();
    this.carregarOs(areaId);

  }

  selecionarCultura() {
    this.carregarDetalhamento();
  }

  canclelarOs(id: number) {
    this.configurarTraducao();

    Swal.fire({
      title: this.CANCELAR_A_ORDEM_DE_SERVICO + ' #' + id.toString().padStart(5, "0") + '?',
      text: this.SE_CANCELAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.VOLTAR,
      confirmButtonText: this.CONFIRMAR,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.ordemServicoService.getOrdemServico(id).subscribe(ret => {
          let ordemServico: OrdemServico = ret;
          ordemServico.Status = EnumStatusOS.Cancelada;
          this.ordemServicoService.putOrdemServico(ordemServico.Id, ordemServico).subscribe(
            ret => {
              this.toasty.show(this.FEITO, this.ORDEM_DE_SERVICO_CANCELADA, 'success');
              this.carregarDetalhamento();
              this.carregarOs(ordemServico.AreaId);
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            })
        })
      }
    });
  }

  acessarCusto() {
    this.storageService.setSafraCusto(this.safraId);
    this.router.navigate(['/relatorios']);
  }

  listarPendencias() {
    this.areasComPendencias = [];
    this.carregando = true;
    this.mostrarSoPendentes = true;
    this.mostrarDetalhe = true;
    this.ordensAplicacaoPendentes = [];
    this.ordensServico.filter(o => o.Status != EnumStatusOS.Concluida && (!this.mostrarPendenciasAtrasadas || o.EmAtraso) && (this.statusFiltroPendentes == 0 || o.Status == this.statusFiltroPendentes)).forEach(o => {
      this.ordensAplicacaoPendentes.push(o);
      if (!this.areasComPendencias.find(x => x.Id == o.AreaId))
        this.areasComPendencias.push(this.areas.find(x => x.Id == o.AreaId));
    });
    this.ordensAplicacaoPendentes.sort((a, b) => {
      return new Date(a.DataPrevista).getTime() - new Date(b.DataPrevista).getTime();
    });
    this.qtdParcialmenteExecutada = this.ordensServico.filter(o => o.Status == EnumStatusOS.ParcialmenteConcluida).length;
    this.qtdPendenteExecucao = this.ordensServico.filter(o => o.Status == EnumStatusOS.Pendente).length;
    this.qtdPendenteLiberacao = this.ordensServico.filter(o => o.Status == EnumStatusOS.AguardandoLiberacao).length;
    this.carregando = false;
  }

  carregarTelemetria() {
    this.telemetriaService.getTelemetria().subscribe((ret) => {
      if (!ret) return;
      this.telemetriaEquipamento = JSON.parse(ret);
      let i = 0;
      this.telemetriaEquipamento.forEach(tel => {
        let equipamento = this.equipamentos.find(eq => eq.IdIrrigaPrime == tel.DISP_ID);
        tel.LABEL = (equipamento && equipamento.Tag ? equipamento.Tag : tel.NAME);
        tel.OPTIONS = { animation: 1 };
        tel.ICON = (equipamento && equipamento.IconeIrrigaPrime ? this.baseIcone + equipamento.IconeIrrigaPrime : 'assets/images/trator.png');
        tel.COR = (equipamento && equipamento.CorRotaIrrigaPrime ? equipamento.CorRotaIrrigaPrime : this.coresLinha[i]);
        if (i == this.coresLinha.length - 1)
          i = 0;
        else
          i++;

        if (this.historicosTelemetria && this.historicosTelemetria.length > 0) {
          let historico: HistoricoTelemetria = { AUX_1: tel.DATA_1, AUX_2: tel.DATA_2, DISP_ID: tel.DISP_ID, DT_UPDATE: tel.TIMESTAMP, ID_ROTA: 0, LAT_2: parseFloat(tel.LAT), LON_2: parseFloat(tel.LON) };
          this.historicosTelemetria.push(historico);
        }

        //inserir rastro
        //this.inserirPolylineEquipamento(tel);
      });

      //inserir marcador
      this.inserirMarcadorEquipamento();
    });
  }

  atualizarTelemetria() {
    let pausaEquipamento = sessionStorage.getItem("pausarEquipamento");

    if (!pausaEquipamento || !pausaEquipamento.includes("true"))
      this.telemetriaService.getTelemetria().subscribe((ret) => {
        if (ret) {
          let auxTelemetriaEquipamento = JSON.parse(ret);
          let i = 0;
          this.telemetriaEquipamento.forEach(tel => {
            let auxTel = auxTelemetriaEquipamento.find(aux => aux.DISP_ID == tel.DISP_ID);
            tel.LAT = auxTel.LAT;
            tel.LON = auxTel.LON;
            tel.DATA_1 = auxTel.DATA_1;
            tel.DATA_2 = auxTel.DATA_2;
            tel.TIMESTAMP = auxTel.TIMESTAMP;
            let equipamento = this.equipamentos.find(eq => eq.IdIrrigaPrime == tel.DISP_ID);
            tel.LABEL = (equipamento && equipamento.Tag ? equipamento.Tag : tel.NAME);
            tel.OPTIONS = { animation: 1 };
            tel.ICON = (equipamento && equipamento.IconeIrrigaPrime ? this.baseIcone + equipamento.IconeIrrigaPrime : 'assets/images/trator.png');
            tel.COR = (equipamento && equipamento.CorRotaIrrigaPrime ? equipamento.CorRotaIrrigaPrime : this.coresLinha[i]);
            if (i == this.coresLinha.length - 1)
              i = 0;
            else
              i++;

            let idsHistorico = sessionStorage.getItem("exibeRastroEquipamento");

            if (idsHistorico && idsHistorico.includes(tel.DISP_ID.toString()) && this.historicosTelemetria && this.historicosTelemetria.length > 0) {
              let historico: HistoricoTelemetria = { AUX_1: tel.DATA_1, AUX_2: tel.DATA_2, DISP_ID: tel.DISP_ID, DT_UPDATE: tel.TIMESTAMP, ID_ROTA: 0, LAT_2: parseFloat(tel.LAT), LON_2: parseFloat(tel.LON) };
              this.historicosTelemetria.push(historico);

              if (!this.pointsEquipamento || this.pointsEquipamento.length == 0 || !this.pointsEquipamento.find(p => p.DISP_ID == tel.DISP_ID))
                this.inserirPolylineEquipamento(tel);
            }
          });

          //inserir marcador
          this.inserirMarcadorEquipamento();
          //this.atualizarMarcadorEquipamento();
        }
      });
  }

  carregarHistoricoTelemetria(data: string) {
    this.telemetriaService.getHistoricoTelemetria(data).subscribe((ret) => {
      ret.forEach(his => {
        let historico: HistoricoTelemetria = { AUX_1: his.AUX_1, AUX_2: his.AUX_2, DISP_ID: his.DISP_ID, DT_UPDATE: his.DT_UPDATE, ID_ROTA: his.ID_ROTA, LAT_2: parseFloat(his.LAT_2), LON_2: parseFloat(his.LON_2) };
        this.historicosTelemetria.push(historico);
      });

    });
  }

  carregarHistoricoTelemetriaEquipamento(data: string, itemTelemetriaEquipamento: any) {
    let idsHistorico = sessionStorage.getItem("exibeRastroEquipamento");

    //Verifica se foi removido algum rastro do equipamento
    this.auxExibeRastroEquipamento.forEach(x => {
      if (!idsHistorico || !idsHistorico.includes(x.toString())) {
        let index = this.auxExibeRastroEquipamento.indexOf(x);
        this.auxExibeRastroEquipamento.splice(index, 1);
        //remove rastros existentes
        this.pointsEquipamento.filter(p => p.DISP_ID == x).forEach((pt) => {
          this.map.removeLayer(pt);
        });
      }
    });

    let idIrrigaPrime = itemTelemetriaEquipamento.DISP_ID;

    let exibeRastroEquipamento = idsHistorico && idsHistorico.includes("," + idIrrigaPrime.toString() + ",");
    let rastoJaCarregado = this.auxExibeRastroEquipamento.find(his => his == idIrrigaPrime);

    if (exibeRastroEquipamento && !rastoJaCarregado) {
      this.auxExibeRastroEquipamento.push(idIrrigaPrime);
      this.telemetriaService.getHistoricoTelemetriaEquipamento(data, idIrrigaPrime).subscribe((ret) => {
        ret.forEach(his => {
          let historico: HistoricoTelemetria = { AUX_1: his.AUX_1, AUX_2: his.AUX_2, DISP_ID: his.DISP_ID, DT_UPDATE: his.DT_UPDATE, ID_ROTA: his.ID_ROTA, LAT_2: parseFloat(his.LAT_2), LON_2: parseFloat(his.LON_2) };
          this.historicosTelemetria.push(historico);
        });
        this.inserirPolylineEquipamento(itemTelemetriaEquipamento);
      });
    }
  }

  inserirMarcadorEquipamento() {
    this.markersEquipamento.forEach((x) => {
      this.map.removeLayer(x);
    });
    this.markersEquipamento = [];
    this.telemetriaEquipamento.forEach(tel => {
      // Criando ícones dos tratores
      let marker = L.marker([tel.LAT, tel.LON],
        {
          clickable: true,
          draggable: false,
          icon: L.divIcon({
            className: 'div-equipamento-icon',
            html: '<img id="equi-' + tel.DISP_ID + '" class="div-image" style="width: 30px; height: 30px;" src="' + tel.ICON + '"/>' +
              '<div class="div-label-equipamento" style="color: #fff; font-size: 10px; font-weight: bold; width: 60px">' + tel.LABEL + '</div>'
          })
        }
      );

      let equipamento = this.equipamentos.find(eq => eq.IdIrrigaPrime == tel.DISP_ID);
      let infoEquipamentoOS = "<b>Equipamento:</b> " + equipamento.Descricao + "<br>";;

      if (this.resumosOperacao && this.resumosOperacao.length > 0) {
        let hoje = new Date();
        this.resumosOperacao.forEach(res => {
          let dataInicio = new Date(res.OrdemServicoDataInicio.toString());
          if (res.EquipamentoId == equipamento.Id
            && dataInicio.getFullYear() == hoje.getFullYear()
            && dataInicio.getMonth() == hoje.getMonth()
            && dataInicio.getDate() == hoje.getDate()) {
            if (res.ImplementoDescricao)
              infoEquipamentoOS += "<b>Implemento:</b> " + res.ImplementoDescricao + "<br>";
            infoEquipamentoOS += "<b>OS:</b> " + res.OrdemServicoId + " - " + res.OrdemServicoEtapa + "<br>";
            infoEquipamentoOS += "<b>Data:</b> " + moment(dataInicio).format('DD/MM/YYYY') + "<br>";
            infoEquipamentoOS += "<b>Status:</b> " + StatusOS.find(s => s.value == res.OrdemServicoStatus).name + "<br>";
          }
        })
      }

      let botoesEquipamentos = "";
      let idsHistorico = sessionStorage.getItem("exibeRastroEquipamento");
      if (idsHistorico != null && idsHistorico.includes("," + tel.DISP_ID.toString() + ",")) {
        botoesEquipamentos += "<a href=\"javascript:\" onClick=\"sessionStorage.setItem('exibeRastroEquipamento','" + idsHistorico.replace("," + tel.DISP_ID.toString() + ",", '') + "'); sessionStorage.setItem(\'pausarEquipamento\', \'false\');\">Ocultar rastro</a> &nbsp;";
      } else {
        idsHistorico = (idsHistorico ? idsHistorico + "," + tel.DISP_ID.toString() + "," : "," + tel.DISP_ID.toString() + ",");
        botoesEquipamentos += "<a href=\"javascript:\" onClick=\"sessionStorage.setItem('exibeRastroEquipamento','" + idsHistorico + "'); sessionStorage.setItem(\'pausarEquipamento\', \'false\');\">Exibir rastro</a> &nbsp;";
      }

      botoesEquipamentos += " &nbsp;<a href=\"javascript:\" onClick=\"sessionStorage.setItem(\'pausarEquipamento\', \'false\'); testeDeFunction();\" translate>FECHAR</a>";

      // Adding popup to the marker
      marker.bindPopup(infoEquipamentoOS + " <br> " + botoesEquipamentos, { closeOnClick: false, autoClose: false, closeButton: false }).openPopup();

      // Adding marker to the map
      marker.on('click', function (e) {
        sessionStorage.setItem("pausarEquipamento", "true");
      }).addTo(this.map);
      this.markersEquipamento.push(marker);

      //rastro equipamento
      let hoje = new Date();
      this.carregarHistoricoTelemetriaEquipamento(hoje.getFullYear() + '-' + (hoje.getMonth() + 1) + '-' + hoje.getDate(), tel);

    });
  }

  atualizarMarcadorEquipamento() {
    this.telemetriaEquipamento.forEach(tel => {
      let marker = this.markersEquipamento.find(m => m._latlng.lat == tel.LAT && m._latlng.lng == tel.LON);
      if (marker) {
        marker.setLatLng([tel.LAT, tel.LON]);
      }
    });
  }


  inserirPolylineEquipamento(itemTelemetriaEquipamento: any) {
    //remove rastros existentes
    this.pointsEquipamento.filter(x => x.DISP_ID == itemTelemetriaEquipamento.DISP_ID).forEach((x) => {
      this.map.removeLayer(x);
    });
    //rastro equipamento
    let pointList: any[] = [];
    this.historicosTelemetria.filter(his => his.DISP_ID == itemTelemetriaEquipamento.DISP_ID).forEach(htel => {
      pointList.push(new L.LatLng(htel.LAT_2, htel.LON_2));
    })

    if (pointList.length > 0) {
      var polyline = new L.Polyline(pointList, {
        color: itemTelemetriaEquipamento.COR,
        weight: 3,
        opacity: 0.5,
        smoothFactor: 1,
        lineJoin: 'arcs',
      });

      polyline.addTo(this.map);



      // draw 5 arrows per line



      polyline.DISP_ID = itemTelemetriaEquipamento.DISP_ID;

      this.pointsEquipamento.push(polyline);
    }
  }

  subtrairDatas(dataAtual: Date, dataAnterior: Date): number {
    const diffMs = new Date(dataAtual).getTime() - new Date(dataAnterior).getTime();
    //msPorDia = 1000 * 60 * 60 * 24 = 86400000
    return Math.round(diffMs / 86400000);
  }

  filtrarSafras() {
    this.listarSafraPorAno();
  }

  getAreaDescricao() {
    var ret = this.areaSelecionada.Descricao;
    if (this.planejamentosSelecionados)
      if (this.planejamentosSelecionados.length > 0) {
        ret = ret + ' - ' + this.planejamentosSelecionados.filter(x => x.AreaId == this.areaSelecionada.Id).map(x => x.AreaPlantada) + 'Ha';
      }
    return ret;
  }

  getCustoTotal(): number {
    var total = 0;
    for (let v of this.ordensAplicacao) {
      total += v.TotalCusto;
    }

    return total / this.areaSelecionada.AreaTotal;
  }

  getMediaVariacao(): number {
    let total = 0.0;
    for (const v of this.ordensAplicacao) {
      total += this.subtrairDatas(v.DataRealizada, v.DataPrevista);
    }

    return total / this.ordensAplicacao.length;
  }

  getCustoTotalPendente(): number {
    var total = 0;
    for (let v of this.ordensAplicacaoPendentes) {
      total += v.TotalCusto;
    }

    return total / this.areaSelecionada.AreaTotal;
  }


  marcarReleaseLida() {
    let usuario: Usuario = this.storageService.getUsuario();
    this.usuarioService.getUsuario(usuario.Id).subscribe((ret) => {
      usuario = ret;
      usuario.LeuRelease = true;
      this.usuarioService.putUsuario(usuario.Id, usuario).subscribe((ret) => {
        this.usuarioService.getUsuario(usuario.Id).subscribe((ret) => {
          this.storageService.setUsuario(ret);
        });
      });
    });
    this.ModalRelease.hide();
  }
  onAnoSafraEvento(anoSafraId: number) {
    this.anoSafraId = anoSafraId;
    this.filtrarSafras();
  }

  onSafrasEvento(safraId: number) {
    this.safraId = safraId;
    this.selecionarSafra();
  }

  onCulturasEvento(culturaSelecionadaId: number) {
    this.culturaSelecionadaId = culturaSelecionadaId;
    this.selecionarCultura();
  }

  onGruposOperacaoEvento(grupoOperacaoId: number) {
    this.grupoOperacaoId = grupoOperacaoId;
    this.selecionaOperacao();
  }

  onSetoresEvento(setorId: number) {
    this.setorId = setorId;
    this.filtrarPorSetor();
  }

  // getCentroPoligono(latlngs: any[]) {
  //   let x1: number = 0; let y1: number = 0; let x2: number = 0; let y2: number = 0;
  //   let centro = { lat: 0, lng: 0 };
  //   let totalLat = 0;
  //   let totalLng = 0;
  //   latlngs.forEach(latlng => {
  //     let lat: number = Number.parseFloat(latlng[0]);
  //     let lng: number = Number.parseFloat(latlng[1]);
  //     if (x1 == 0)
  //       x1 = lat;
  //     else
  //       x1 = (x1 > lat ? lat : x1);

  //     if (y1 == 0)
  //       y1 = lng;
  //     else
  //       y1 = (y1 > lng ? lng : y1);

  //     if (x2 == 0)
  //       x2 = lat;
  //     else
  //       x2 = (x2 < lat ? lat : x2);

  //     if (y2 == 0)
  //       y2 = lng;
  //     else
  //       y2 = (y2 < lng ? lng : y2);

  //   });
  //   let x_1: number = x1;
  //   let y_1: number = y1;
  //   centro.lat = x_1 + ((x2 - x1) / 2);
  //   centro.lng = y_1 + ((y2 - y1) / 2);
  //   return centro;
  // }

  getCentroPoligono(latlngs: any[]) {
    let centro = { lat: 0, lng: 0 };
    let totalLat = 0;
    let totalLng = 0;
    let totalPoints = latlngs.length;

    latlngs.forEach(latlng => {
      let lat: number = Number.parseFloat(latlng[0]);
      let lng: number = Number.parseFloat(latlng[1]);

      totalLat += lat;
      totalLng += lng;
    });

    centro.lat = totalLat / totalPoints;
    centro.lng = totalLng / totalPoints;

    return centro;
  }

  changeDataPrevista(item: OrdemServico, dataPrevista: Date) {
    this.configurarTraducao();
    item.DataPrevista = dataPrevista;
    this.ordemServicoService.atualizarDtPrevista(item.Id, moment(dataPrevista).format('YYYY-MM-DD'))
      .subscribe(
        res => {
          this.utils.handleSuccess(this.DATA_PREVISTA_ALTERADA_COM_SUCESSO);
        },
        err => {
          this.utils.getErro(err);
        }
      )
  }

  configurarTraducaoEIniciarMapa() {
    this.translate.stream(['MAIS_ZOOM', 'MENOS_ZOOM', 'LEGENDA', 'NAO_PLANTADO', 'EM_DIA', 'PENDENTE']).subscribe(p => {
      this.MAIS_ZOOM = p['MAIS_ZOOM'];
      this.MENOS_ZOOM = p['MENOS_ZOOM'];
      this.LEGENDA = p['LEGENDA'];
      this.NAO_PLANTADO = p['NAO_PLANTADO'];
      this.EM_DIA = p['EM_DIA'];
      this.PENDENTE = p['PENDENTE'];

      this.iniciarMapa();
    });
  }

  configurarTraducao() {
    this.CANCELAR_A_ORDEM_DE_SERVICO = this.translate.instant('CANCELAR_A_ORDEM_DE_SERVICO');
    this.SE_CANCELAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_CANCELAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.VOLTAR = this.translate.instant('VOLTAR');
    this.CONFIRMAR = this.translate.instant('CONFIRMAR');
    this.ORDEM_DE_SERVICO_CANCELADA = this.translate.instant('ORDEM_DE_SERVICO_CANCELADA');
    this.FEITO = this.translate.instant('FEITO');
    this.EQUIPAMENTO = this.translate.instant('EQUIPAMENTO');
    this.IMPLEMENTO = this.translate.instant('IMPLEMENTO');
    this.OCULTAR_RASTRO = this.translate.instant('OCULTAR_RASTRO');
    this.EXIBIR_RASTRO = this.translate.instant('EXIBIR_RASTRO');
    this.DATA_PREVISTA_ALTERADA_COM_SUCESSO = this.translate.instant('DATA_PREVISTA_ALTERADA_COM_SUCESSO');
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

}
