import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrupoProdutoComponent } from './grupo-produto/grupo-produto.component';
import { LocalEstoqueComponent } from './local-estoque/local-estoque.component';
import { ProdutoComponent } from './produto/produto.component';
import { EstoqueInsumoComponent } from './estoque-insumo/estoque-insumo.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CustomFormsModule } from 'ng2-validation';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { SelecionaEmpresaModule } from '../seleciona-empresa/seleciona-empresa.module';
import { SharedFarmModule } from '../shared-farm/shared-farm.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MovimentacaoProdutoComponent } from './produto/movimentacao-produto/movimentacao-produto.component';
import { MovimentoModule } from '../cadastros/movimento/movimento.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient} from '@angular/common/http';
import { ProdutoFormsComponent } from './produto/produto-forms/produto-forms.component';
import { Produto } from 'src/app/classes/Produto';
import { LocalEstoqueFormsComponent } from './local-estoque/local-estoque-forms/local-estoque-forms.component';
import { GrupoProdutoFormsComponent } from './grupo-produto/grupo-produto-forms/grupo-produto-forms.component';
import { GridProdutosConsolidadosComponent } from './produtosConsolidados/grid-produtos-consolidados/grid-produtos-consolidados.component';
import { ProdutoConsolidadoComponent } from './produtosConsolidados/produtoConsolidadocomponent';
import { SelectsafraComponent } from '../shared-farm/select-safra/select-safra.component';

@NgModule({
  declarations: [
    GrupoProdutoComponent,
    LocalEstoqueComponent,
    ProdutoComponent,
    EstoqueInsumoComponent,
    MovimentacaoProdutoComponent,
    ProdutoFormsComponent,
    LocalEstoqueFormsComponent,
    GrupoProdutoFormsComponent,
    ProdutoConsolidadoComponent,
    GridProdutosConsolidadosComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    SelecionaEmpresaModule,
    SharedFarmModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgSelectModule,
    MovimentoModule,
    NgbTabsetModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
  })
  ],
  exports: [
    ProdutoComponent,
    ProdutoFormsComponent,
    GrupoProdutoComponent,
    GrupoProdutoFormsComponent,
    LocalEstoqueComponent,
    LocalEstoqueFormsComponent,
    EstoqueInsumoComponent,
    GridProdutosConsolidadosComponent,
    ProdutoConsolidadoComponent,
    NgbDropdownModule,
    NgSelectModule,
    MovimentoModule,
    NgbTabsetModule
  ]
  })
export class EstoqueModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
