import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Indicador } from 'src/app/classes/indicadores/Indicador';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndicadoresService {

  readonly route: string = 'TiposDiagnoseIndicador';

  constructor(private http: HttpClient) { }

  Listar(): Observable<Indicador[] | null> {
    return this.http.get<Indicador[]>(`${environment.apiURL}/${this.route}`);
  }

  ObterPorId(id: string): Observable<Indicador | null> {
    return this.http.get<Indicador>(`${environment.apiURL}/${this.route}/${id}`);
  }

  Inserir(indicador: Indicador): Observable<Indicador | null> {
    return this.http.post<Indicador>(`${environment.apiURL}/${this.route}`, indicador);
  }

  Atualizar(indicador: Indicador, id: string): Observable<Indicador | null> {
    return this.http.put<Indicador>(`${environment.apiURL}/${this.route}/${id}`, indicador);
  }

  Excluir(id: string): Observable<any> {
    return this.http.delete(`${environment.apiURL}/${this.route}/${id}`);
  }
}
