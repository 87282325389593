import { ItemEnum } from "./ItemEnum";
import { Produto } from "./Produto";

export class ItemOrdemServico {
    Id: number;
    ProdutoId: number;
    OrdemServicoId: number;
    Dose: number = 0;
    DosePorTanqueIncompleto: number = 0;
    DosePorTanqueCompleto: number = 0;
    AreaAplicacao: number = 0;
    AreaReal: number = 0;
    Quantidade: number = 0;
    QtdRetirada: number = 0;
    QtdRetorno: number = 0;
    QtdAplicada: number = 0;
    QtdPrevista: number = 0;
    PrecoUnitario: number = 0;
    ValorTotal: number = 0;
    FlagExcluido: boolean;
    PulverizacaoSulco: boolean;
    AdubacaoLinha: boolean;
    TratamentoSementes: boolean;
    Semente: boolean;
    Ordem: number = 0;
    isServico: boolean;
    Tipo: EnumTipoItemOS;

    Produto: Produto;
}

export enum EnumTipoItemOS
{
  Insumo = 1,
  Servico = 2,
  AdubacaoLinha = 3,
  PulverizacaoSulco = 4,
  TratamentoSementes = 5,
}

export const StatusOS: ItemEnum[] = [
  {name: 'Insumo', value: EnumTipoItemOS.Insumo},
  {name: 'Serviço', value: EnumTipoItemOS.Servico},    
  {name: 'Adubacão em Linha', value: EnumTipoItemOS.AdubacaoLinha},
  {name: 'Pulverização no Sulco', value: EnumTipoItemOS.PulverizacaoSulco},
  {name: 'Tratamento de Sementes', value: EnumTipoItemOS.TratamentoSementes},    
];

export class ItemOrdemServicoAux{
  ItemOrdemServico: ItemOrdemServico[] = [];
  OrdemServicoId: number;
}