import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CulturaProduto } from '../classes/CulturaProduto';

@Injectable({
  providedIn: 'root'
})
export class CulturaProdutoService {  

  constructor(private http: HttpClient) { }   

  getCulturasEnergia(produtoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/culturaproduto/listar/${produtoId}`)
  }

  getCulturaProduto(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/culturaproduto/${id}`)
  }

  postCulturaProduto(culturaProduto: CulturaProduto): Observable<any>{
    return this.http.post(`${environment.apiURL}/culturaproduto`, culturaProduto);
  }

  putCulturaProduto(id: number, culturaProduto: CulturaProduto): Observable<any>{
    return this.http.put(`${environment.apiURL}/culturaproduto/${id}`, culturaProduto);
  }

  deleteCulturaProduto(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/culturaproduto/${id}`);
  }
}
