import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
//import { SwitchPermissaoComponent } from './switch-permissao/switch-permissao.component';
import { PermissaoUsuarioTransacao } from 'src/app/classes/PermissaoUsuarioTransacao';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { TransacaoService } from 'src/app/services/transacao.service';
import { StorageService } from 'src/app/services/storage.service';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-permissao-transacao',
  templateUrl: './permissao-transacao.component.html',
  styleUrls: ['./permissao-transacao.component.scss'],
  //entryComponents: [SwitchPermissaoComponent]
})
export class PermissaoTransacaoComponent implements OnInit {

  @ViewChild('ModalTransacao', { static: false })
  ModalTransacao: UiModalComponent;

  @ViewChild('transacoesGrid', { static: false }) transacoesGrid
  @Input() usuario: Usuario;
 transacoes: Transacao[] = [];
  colDefs: ColDef[] = [];
  @Input() showGrid = false;
  auxTransacoes: Transacao[] = [];
  selecionados: PermissaoUsuarioTransacao[] = [];

  constructor(private usuarioService: UsuarioService, private transacaoService: TransacaoService, private utils: UtilsService, private storageSerice: StorageService) { }

  ngOnInit() {
    this.configurar();
    this.transacaoService.getTransacoesTotais()
    .subscribe(res => {
      this.transacoes = res;
    })
  }

  configurar() {
    this.colDefs = [
      //{ field: 'GrupoTransacao', rowGroup: true, hide: true, showRowGroup: true },
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'NomeTransacao', headerName: 'Transações', width: 400 },
    //{ field: 'PermiteVisualiza', headerName: 'Visualizar', flex: 1, cellRenderer: `<app-switch-permissao params="{ propriedade: 'PermiteVisualiza', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) }"></app-switch-permissao>`, cellRendererParams: { propriedade: 'PermiteVisualiza', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    //   { field: 'PermiteCriar', headerName: 'Incluir', flex: 1, cellRenderer: SwitchPermissaoComponent, cellRendererParams: { propriedade: 'PermiteCriar', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    //   { field: 'PermiteEditar', headerName: 'Editar', flex: 1, cellRenderer: SwitchPermissaoComponent, cellRendererParams: { propriedade: 'PermiteEditar', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    //   { field: 'PermiteCancelar', headerName: 'Cancelar', flex: 1, cellRenderer: SwitchPermissaoComponent, cellRendererParams: { propriedade: 'PermiteCancelar', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    //   { field: 'PermiteExcluir', headerName: 'Excluir', flex: 1, cellRenderer: SwitchPermissaoComponent, cellRendererParams: { propriedade: 'PermiteExcluir', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    //   { field: 'PermiteClonar', headerName: 'Duplicar', flex: 1, cellRenderer: SwitchPermissaoComponent, cellRendererParams: { propriedade: 'PermiteClonar', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    //   { field: 'PermiteImprimir', headerName: 'Imprimir', flex: 1, cellRenderer: SwitchPermissaoComponent, cellRendererParams: { propriedade: 'PermiteImprimir', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    //   { field: 'PermiteReceber', headerName: 'Receber', flex: 1, cellRenderer: SwitchPermissaoComponent, cellRendererParams: { propriedade: 'PermiteReceber', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    { field: 'PermiteVisualiza', headerName: 'Visualizar', flex: 1, cellRendererParams: { propriedade: 'PermiteVisualiza', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    { field: 'PermiteCriar', headerName: 'Incluir', flex: 1, cellRendererParams: { propriedade: 'PermiteCriar', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    { field: 'PermiteEditar', headerName: 'Editar', flex: 1, cellRendererParams: { propriedade: 'PermiteEditar', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    { field: 'PermiteCancelar', headerName: 'Cancelar', flex: 1, cellRendererParams: { propriedade: 'PermiteCancelar', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    { field: 'PermiteExcluir', headerName: 'Excluir', flex: 1, cellRendererParams: { propriedade: 'PermiteExcluir', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    { field: 'PermiteClonar', headerName: 'Duplicar', flex: 1, cellRendererParams: { propriedade: 'PermiteClonar', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    { field: 'PermiteImprimir', headerName: 'Imprimir', flex: 1, cellRendererParams: { propriedade: 'PermiteImprimir', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },
    { field: 'PermiteReceber', headerName: 'Receber', flex: 1, cellRendererParams: { propriedade: 'PermiteReceber', callbackFunction: this.onSwitchChange.bind(this), marcarColunas: this.marcarTodosColuna.bind(this) } },

  ]
    if(this.usuario.permissaoUsuarioTransacaos != undefined)
      this.addRowGeral();
    this.atualizarAuxTransacoes();
  }
  acao(acao) {
    switch (acao) {
      case 'Marcar Todos':
        this.marcarTodos();
        break;
    }
  }
  addRowGeral() {
    if(this.usuario.permissaoUsuarioTransacaos.filter(p => p.NomeTransacao == `Geral`).length == 0)
      this.usuario.permissaoUsuarioTransacaos.unshift({ NomeTransacao: 'Geral' } as PermissaoUsuarioTransacao)
  }
  marcarTodos() {
    this.selecionados.filter(c => c.NomeTransacao != 'Geral').forEach(p => {
      for (const prop in p) {
        if (p.hasOwnProperty(prop) && typeof p[prop] === 'boolean') {
          p[prop] = true;
        }
      }
      this.usuario.permissaoUsuarioTransacaos.forEach(c => {
        if (c.Id == p.Id) {
          c = p;
          return
        }
      })
      this.onSwitchChange(p)
    })
    this.transacoesGrid.setRowData(this.usuario.permissaoUsuarioTransacaos);
  }
  marcarTodosColuna(prop) {
    this.usuario.permissaoUsuarioTransacaos.filter(c => c.NomeTransacao != 'Geral').forEach(p => {
      p[prop] = true;
      this.onSwitchChange(p)
    });
  }

  onSwitchChange(obj: PermissaoUsuarioTransacao) {
    this.usuarioService.putPermissaoUsuarioTransacaos(obj.Id, obj.UsuarioId, obj)
      .subscribe(res => {
        this.usuario = res;
        this.addRowGeral();
        if (this.usuario.Id == this.storageSerice.getUsuario().Id)
          this.storageSerice.setUsuario(this.usuario)
      }, err => this.utils.getErro(err))
  }
  onCellClicked() { }
  selectionChanged(event) {
    this.selecionados = event;
  }
  selecionarPermissaoTransacao(transacao: Transacao) {
    let index = this.transacoes.indexOf(transacao);
    this.transacoes.splice(index, 1);
    let permissaoUsuarioTransacao = new PermissaoUsuarioTransacao();
    permissaoUsuarioTransacao.TransacaoId = transacao.Id;
    permissaoUsuarioTransacao.UsuarioId = this.usuario.Id;
    permissaoUsuarioTransacao.PermiteVisualiza = true;
    this.usuarioService.postPermissaoUsuarioTransacaos(this.usuario.Id, permissaoUsuarioTransacao).subscribe(
      res => {
        this.usuario = res;
        if (this.usuario.Id == this.storageSerice.getUsuario().Id)
          this.storageSerice.setUsuario(this.usuario)
        this.atualizarAuxTransacoes();
      },
      err => {
        this.utils.getErro(err);
      }
    );
  }
  atualizarAuxTransacoes() {
    this.auxTransacoes = this.transacoes.filter(p => !this.usuario.permissaoUsuarioTransacaos.find(x => x.TransacaoId == p.Id))
  }

  abrirModalPermissaoTransacao() {
    this.atualizarAuxTransacoes();
    this.ModalTransacao.show();
  }
  fecharModalPermissaoTransacao() {
    this.ModalTransacao.hide();
  }

  removerPermissaoTransacao() {
    if (this.selecionados.length <= 0) {
      this.utils.handleWarning("Selecione um ou mais Movimentos para prosseguir!");
      return;
    }
    this.selecionados.forEach(permissaoUsuarioTransacao => {
      this.usuarioService.deletePermissaoUsuarioTransacaos(permissaoUsuarioTransacao.Id, this.usuario.Id).subscribe(
        res => {
          this.usuario = res;
          this.transacaoService.getTransacoesTotais().subscribe(
            res => {
              this.transacoes = res;
              let aux: Transacao[] = res;
              this.transacoes.forEach(transacao => {
                this.usuario.permissaoUsuarioTransacaos.forEach((item, index) => {
                  if (item.TransacaoId == transacao.Id)
                    aux.splice(index, 1);
                });
              });
              this.transacoes = aux;
            },
            err => {
              this.utils.getErro(err);
            }
          );
        },
        err => {
          this.utils.getErro(err);
        }
      );
    });
  }
  filtrar(prop, value){

  }
}
