import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Cultura } from 'src/app/classes/Cultura';
import { EstadioFenologico } from 'src/app/classes/EstadioFenologico';
import { EmpresaService } from 'src/app/services/empresa.service';
import { EstadioFenologicoService } from 'src/app/services/estadio-fenologico.service';
import { StorageService } from 'src/app/services/storage.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-estadio-fenologico',
  templateUrl: './estadio-fenologico.component.html',
  styleUrls: ['./estadio-fenologico.component.scss']
})
export class EstadioFenologicoComponent implements OnInit {

  @ViewChild('EstadioModal', { static: false })
  EstadioModal: UiModalComponent;
  @Input() cultura: Cultura;

  estadiosFenologicos: EstadioFenologico[];
  auxEstadiosFenologicos: EstadioFenologico[];
  estadioFenologico: EstadioFenologico;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  // tradução
  ESTADIO_FENOLOGICO_ALTERADO: string = 'Estádio Fenológico alterado!';
  ESTADIO_FENOLOGICO_CADASTRADO: string = 'Estádio Fenológico cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  ESTADIO_FENOLOGICO_DELETADO: string = 'Estádio Fenológico deletado!';

  configurarTraducao() {
    this.ESTADIO_FENOLOGICO_ALTERADO = this.translate.instant('ESTADIO_FENOLOGICO_ALTERADO');
    this.ESTADIO_FENOLOGICO_CADASTRADO = this.translate.instant('ESTADIO_FENOLOGICO_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.ESTADIO_FENOLOGICO_DELETADO = this.translate.instant('ESTADIO_FENOLOGICO_DELETADO');
  }

  constructor(private estadioFenologicoService: EstadioFenologicoService, 
              private empresaService: EmpresaService,
              private route: ActivatedRoute, 
              private router: Router, 
              private translate: TranslateService,
              private utils: UtilsService,
              private storageService: StorageService,
              private toasty: CustomToastyService) {
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.estadioFenologico = new EstadioFenologico();

    if(this.id > 0)
      this.selecionarEstadioFenologico();
    else
      this.listarEstadiosFenologicos();
  }

  selecionarEstadioFenologico() {
    this.carregando = true;
    this.estadioFenologicoService.getEstadioFenologico(this.id).subscribe(res =>{
      this.estadioFenologico = new EstadioFenologico();
      this.estadioFenologico = res;
      this.carregando = false;
    },
    err =>{
      this.utils.getErro(err);
      this.carregando = false;
    })
  }

  listarEstadiosFenologicos(){
    this.carregando = true;
    this.estadioFenologicoService.getEstadiosFenologicos().subscribe(
      res => {
        this.estadiosFenologicos = res;
        this.estadiosFenologicos = this.estadiosFenologicos.filter(x => x.CulturaId == this.cultura.Id);
        this.auxEstadiosFenologicos = this.estadiosFenologicos;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarEstadiosFenologicos();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.estadioFenologico.Id > 0)
      this.estadioFenologicoService.putEstadioFenologico(this.estadioFenologico.Id, this.estadioFenologico).subscribe(
        res => {
          this.utils.handleSuccess(this.ESTADIO_FENOLOGICO_ALTERADO);
          this.carregando = false;
          this.EstadioModal.hide();
          this.listarEstadiosFenologicos();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.estadioFenologicoService.postEstadioFenologico(this.estadioFenologico).subscribe(
        res => {
          this.utils.handleSuccess(this.ESTADIO_FENOLOGICO_CADASTRADO);
          this.carregando = false;
          this.EstadioModal.hide();
          this.listarEstadiosFenologicos();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  novo() {
    this.estadioFenologico = new EstadioFenologico();
    this.estadioFenologico.CulturaId = this.cultura.Id;
    this.EstadioModal.show();
  }

  selecionar(estadioFenologico: EstadioFenologico) {
    this.estadioFenologico = new EstadioFenologico();
    this.estadioFenologico.Id = estadioFenologico.Id;
    this.estadioFenologico.Descricao = estadioFenologico.Descricao;
    this.estadioFenologico.DescricaoCompleta = estadioFenologico.DescricaoCompleta;
    this.estadioFenologico.CulturaId = estadioFenologico.CulturaId;
    this.estadioFenologico.EmpresaId = estadioFenologico.EmpresaId;
    this.EstadioModal.show();
  }

  excluir(grupooperacaoId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR + ' '+ nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.estadioFenologicoService.deleteEstadioFenologico(grupooperacaoId).subscribe(
            res => {
              this.utils.handleSuccess(this.ESTADIO_FENOLOGICO_DELETADO);
              this.listarEstadiosFenologicos();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarEstadiosFenologicos(){
    this.estadiosFenologicos = this.auxEstadiosFenologicos.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

}
