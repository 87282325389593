import { Component, OnInit } from '@angular/core';

import { EntradaProducao } from 'src/app/classes/gestao-lite/EntradaProducao';
import { ApontamentoProducao } from 'src/app/classes/planejamento-safra/ApontamentoProducao';
import { FiltrosPlanejamentoSafra } from 'src/app/classes/planejamento-safra/FiltrosPlanejamentoSafra';
import { ItemPlanejamentoSafra } from 'src/app/classes/planejamento-safra/ItemPlanejamentoSafra';
import { PlanejamentoSafraService } from 'src/app/services/planejamento-safra/planejamento-safra.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-planejamento-producao',
  templateUrl: './planejamento-producao.component.html',
  styleUrls: ['./planejamento-producao.component.scss']
})
export class PlanejamentoProducaoComponent implements OnInit {

  filtro: FiltrosPlanejamentoSafra = null;
  modalEntradaProducao: boolean = false;
  entradaProducaoId: number = null;
  entradaProducao: number = null;
  exibirAnexos: boolean = false;

  carregandoItens: boolean = true;
  carregandoApontamentos: boolean = true;
  itensPlanejamento: ItemPlanejamentoSafra[] = [];
  itemPlanejamento: ItemPlanejamentoSafra = null;
  itensPlanejamentoFiltro: ItemPlanejamentoSafra[] = [];
  apontamentos: ApontamentoProducao[] = []

  constructor(
    private planejamentoSafraService: PlanejamentoSafraService,
    private utils: UtilsService,
  ) { }

  ngOnInit() {
    this.listarItensPlanejamento();
  }

  itemSelecionado(event: ItemPlanejamentoSafra) {
    this.itemPlanejamento = event;
    this.listarApontamentos(event.SafraId, event.AreaId);
  }

  filtrarItens(filtro: FiltrosPlanejamentoSafra) {
    this.filtro = filtro;
    this.filtrarEmLista();
  }

  novoApontamento(event) {
    this.entradaProducao = null;
    this.modalEntradaProducao = true;
  }

  editarApontamento(event) {
    this.entradaProducaoId = event;
    this.entradaProducao = event;
    this.modalEntradaProducao = true;
  }

  exibirAnexoApontamento(event) {
    this.entradaProducaoId = event;
    this.exibirAnexos = true;
  }

  fecharEntradaProducao() {
    this.modalEntradaProducao = false;
  }

  fecharExibirAnexo() {
    this.exibirAnexos = false;
  }

  itemDescontoRemovido() {
    this.atualizarListaApontamentos(this.itemPlanejamento.SafraId, this.itemPlanejamento.AreaId);
    this.atualizarItensPlanejamento();
  }

  apontamentoExcluido() {
    this.atualizarListaApontamentos(this.itemPlanejamento.SafraId, this.itemPlanejamento.AreaId);
    this.atualizarItensPlanejamento();
  }

  novoApontamentoSucesso(event: EntradaProducao) {
    this.atualizarListaApontamentos(event.SafraId, event.AreaId);
    this.atualizarItensPlanejamento();
  }

  private listarItensPlanejamento() {
    this.planejamentoSafraService.GetItensPlanejamentoSafra()
      .subscribe(
        res => {
          this.itensPlanejamento = res;
          this.carregandoItens = false;
          this.itensPlanejamentoFiltro = res;
        },
        err => this.utils.getErro(err)
      )
  }

  private atualizarItensPlanejamento() {
    this.planejamentoSafraService.GetItensPlanejamentoSafra()
      .subscribe(
        res => {
          this.itensPlanejamento = res;
          this.carregandoItens = false;
          this.filtrarEmLista();
        },
        err => this.utils.getErro(err)
      )
  }

  private listarApontamentos(safraId: number, areaId: number) {
    this.planejamentoSafraService.GetApontamentos(safraId, areaId)
      .subscribe(
        res => {
          this.apontamentos = res;
          this.carregandoApontamentos = false;
        },
        err => this.utils.getErro(err)
      )
  }

  private filtrarEmLista() {
    this.itensPlanejamentoFiltro = this.itensPlanejamento.filter(
      i => {
        return (this.filtro.CulturaId ? i.CulturaId === this.filtro.CulturaId : true) &&
          (this.filtro.FazendaId ? i.FazendaId == this.filtro.FazendaId : true) &&
          (this.filtro.SafraId ? i.SafraId == this.filtro.SafraId : true) &&
          (this.filtro.StatusSafra ? i.Status == this.filtro.StatusSafra : true);
      }
    );
  }

  private atualizarListaApontamentos(safraId: number, areaId: number) {
    this.planejamentoSafraService.GetApontamentos(safraId, areaId)
      .subscribe(
        res => this.apontamentos = res,
        err => this.utils.getErro(err)
      );
  }
}
