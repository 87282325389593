import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageService } from 'src/app/services/storage.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { catchError } from 'rxjs/operators';
import { UtilsService } from 'src/app/utils/utils.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class InterceptorModule implements HttpInterceptor {
  constructor(private armazenamento: StorageService, 
    private empresaService: EmpresaService,
    private utils: UtilsService) {

  }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.armazenamento.getToken()) {
      let cloneReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this.armazenamento.getToken())
      });

      if (this.armazenamento.getUsuario() && this.armazenamento.getUsuario().PerfilInt == 1 && this.empresaService.getEmpresaSelecionada()){
        cloneReq = cloneReq.clone({
          headers: cloneReq.headers.set('EmpresaId', this.empresaService.getEmpresaSelecionada().Id+'')
        });
      }

      return next.handle(cloneReq);
    } else {
      return next.handle(req);
    }
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorModule,
      multi: true,
    },
  ],
})
export class Interceptor { }
