import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoTransacao } from 'src/app/classes/GrupoTransacao';
import { MovimentoSerie } from 'src/app/classes/MovimentoSerie';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { GrupoTransacaoService } from 'src/app/services/grupo-transacao.service';
import { MovimentoSerieService } from 'src/app/services/movimento-serie.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transacao',
  templateUrl: './transacao.component.html',
  styleUrls: ['./transacao.component.scss']
})
export class TransacaoComponent implements OnInit {

  transacoes: Transacao[];
  auxTransacoes: Transacao[];
  transacao: Transacao;
  carregando: boolean = false;
  form: any;
  textoBusca: string = "";


  listaUsuario: Usuario[];
  listaGrupoTransacao: GrupoTransacao[];

  @ViewChild('Grid', { static: false }) grid

  colDefs: ColDef[] = [];
  showGrid = true;
  showGridItems = true;
  minHeight = 0;
  objSelecionado: Transacao = new Transacao();
  objSelecionadoId: number = null;
  objetosSelecionados: Transacao[] = [];
  loading: boolean = false;


  constructor(
    private transacaoService: TransacaoService,
    private grupoTransacaoService: GrupoTransacaoService,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute,
    private router: Router,
    private toasty: CustomToastyService,
    private utils : UtilsService) {
  }

  ngOnInit() {
    this.listarTransacoes();
    this.listarUsuarios();
    this.listarGrupoTransacoes();
    this.configurar();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'Numero', headerName: 'Número', width: 60},
      {field: 'Descricao', headerName: 'Descrição'},
      {field: 'GrupoTransacaoDesc', headerName: 'Grupo'},
      {field: 'DataCadastro', headerName: 'Data Cadastro', valueFormatter: (params) => this.utils.formatarData(params.data.DataCadastro)},
      // configuracoes gerais
      {field: 'TipoItemDesc', headerName: 'Tipo Item'},
      {field: 'ExigeCentroResultado', headerName: 'Exige Centro de Resultado', valueFormatter: (params) => this.booleanoToString(params.data.ExigeCentroResultado)},
      {field: 'ExigeProjeto', headerName: 'Exige Projeto', valueFormatter: (params) => this.booleanoToString(params.data.ExigeProjeto)},
      {field: 'CentroResultadoItem', headerName: 'Centro Resultado Item', valueFormatter: (params) => this.booleanoToString(params.data.CentroResultadoItem)},
      {field: 'ProjetoItem', headerName: 'Projeto Item', valueFormatter: (params) => this.booleanoToString(params.data.ProjetoItem)},
      {field: 'InformarEquipamentoItem', headerName: 'Informar Equipamento Item', valueFormatter: (params) => this.booleanoToString(params.data.InformarEquipamentoItem)},
      {field: 'InformaFazendaItem', headerName: 'Informa Fazenda Item', valueFormatter: (params) => this.booleanoToString(params.data.InformaFazendaItem)},
      {field: 'RateiaAutomaticamentoItem', headerName: 'Rateia Automaticamente Item', valueFormatter: (params) => this.booleanoToString(params.data.RateiaAutomaticamentoItem)},
      {field: 'PermiteRecebimentoParcial', headerName: 'Permite Recebimento Parcial', valueFormatter: (params) => this.booleanoToString(params.data.PermiteRecebimentoParcial)},
      {field: 'PermiteAlterarConfirmar', headerName: 'Permite Alterar Confirmar', valueFormatter: (params) => this.booleanoToString(params.data.PermiteAlterarConfirmar)},
      {field: 'NumeracaoAuto', headerName: 'Numeração Auto', valueFormatter: (params) => this.booleanoToString(params.data.NumeracaoAuto)},
      {field: 'BaseNumeracaoDesc', headerName: 'Base Numeracão'},
      {field: 'TipoNumeracaoDesc', headerName: 'Tipo Numeracão'},
      {field: 'ValidarNumeracao', headerName: 'Validar Numeração', valueFormatter: (params) => this.booleanoToString(params.data.ValidarNumeracao)},
      {field: 'PrestacaoServico', headerName: 'Prestação Serviço', valueFormatter: (params) => this.booleanoToString(params.data.PrestacaoServico)},
      // compras
      {field: 'ExigeCotacao', headerName: 'Exige Cotação', valueFormatter: (params) => this.booleanoToString(params.data.ExigeCotacao)},
      {field: 'BuscaProdutosSafra', headerName: 'Busca Produtos Safra', valueFormatter: (params) => this.booleanoToString(params.data.BuscaProdutosSafra)},
      {field: 'SugereFornecedoresHistoricoCompras', headerName: 'Sugere Fornecedores Histórico Compras', valueFormatter: (params) => this.booleanoToString(params.data.SugereFornecedoresHistoricoCompras)},
      {field: 'QuantidadeFornecedoresSugerir', headerName: 'Quantidade Fornecedores Sugerir', valueFormatter: (params) => this.booleanoToString(params.data.QuantidadeFornecedoresSugerir)},
      {field: 'ExigePrazoEntrega', headerName: 'Exige Prazo Entrega', valueFormatter: (params) => this.booleanoToString(params.data.ExigePrazoEntrega)},
      {field: 'CompradorPadraoDesc', headerName: 'Comprador Padrão'},
      {field: 'AprovadorPadraoDesc', headerName: 'Aprovador Padrão'},
      {field: 'PrazoPadraoAtendimento', headerName: 'Prazo Padrão Atendimento', valueFormatter: (params) => this.booleanoToString(params.data.PrazoPadraoAtendimento)},
      {field: 'RequerAprovacao', headerName: 'Requer Aprovação', valueFormatter: (params) => this.booleanoToString(params.data.RequerAprovacao)},
      {field: 'UsaAlcadaAprovacao', headerName: 'Usa Alçada Aprovação', valueFormatter: (params) => this.booleanoToString(params.data.UsaAlcadaAprovacao)},
      // estoque
      {field: 'ReposicaoEstoque', headerName: 'Reposição Estoque', valueFormatter: (params) => this.booleanoToString(params.data.ReposicaoEstoque)},
      {field: 'EfeitoSobreEstoqueDesc', headerName: 'EfeitoSobreEstoqueDesc'},
      {field: 'ReservaEstoqueConfirmacao', headerName: 'Reserva Estoque Confirmação', valueFormatter: (params) => this.booleanoToString(params.data.ReservaEstoqueConfirmacao)},
      {field: 'AceitaProdutoRepetido', headerName: 'Aceita Produto Repetido', valueFormatter: (params) => this.booleanoToString(params.data.AceitaProdutoRepetido)},
      {field: 'ValidaEstoqueMaximo', headerName: 'Valida Estoque Máximo', valueFormatter: (params) => this.booleanoToString(params.data.ValidaEstoqueMaximo)},
      {field: 'LocalEstoqueDesc', headerName: 'LocalEstoqueDesc'},
      //financeiro
      {field: 'EfeitoFinanceiroDesc', headerName: 'Efeito Financeiro'},
      {field: 'TipoFinanceiroDesc', headerName: 'Tipo Financeiro'},
      {field: 'ExigeAutorizacaoPagamento', headerName: 'Exige Autorizacao Pagamento', valueFormatter: (params) => this.booleanoToString(params.data.ExigeAutorizacaoPagamento)},
      {field: 'ExigeAutorizaBaixa', headerName: 'Exige Autoriza Baixa', valueFormatter: (params) => this.booleanoToString(params.data.ExigeAutorizaBaixa)},
      {field: 'UsaDataPrevisaoBaixa', headerName: 'Usa Data Previsao Baixa', valueFormatter: (params) => this.booleanoToString(params.data.UsaDataPrevisaoBaixa)},
      {field: 'ExigeHistoricoFinanceiro', headerName: 'Exige Historico Financeiro', valueFormatter: (params) => this.booleanoToString(params.data.ExigeHistoricoFinanceiro)},
      {field: 'HistoricoPadraoFinanceiro', headerName: 'HistoricoPadraoFinanceiro'},
      {field: 'GerarFinanceiroAtravesDoItemDeMovimento', headerName: 'Gerar Financeiro Atraves Do Item De Movimento', valueFormatter: (params) => this.booleanoToString(params.data.GerarFinanceiroAtravesDoItemDeMovimento)},
      // contabilidade
      {field: 'Contabiliza', headerName: 'Contabiliza', valueFormatter: (params) => this.booleanoToString(params.data.Contabiliza)},
      {field: 'ExigeComplementoContabilidade', headerName: 'Exige Complemento Contabilidade', valueFormatter: (params) => this.booleanoToString(params.data.ExigeComplementoContabilidade)},
      {field: 'ComplementoContabilidade', headerName: 'ComplementoContabilidade'},
      // NFe
      {field: 'EnviaNfe', headerName: 'Envia Nfe', valueFormatter: (params) => this.booleanoToString(params.data.EnviaNfe)},
      {field: 'ModeloDocumento', headerName: 'ModeloDocumento'},
      {field: 'TransacaoDenegadaId', headerName: 'Transação Denegada Id'},
      {field: 'TransacaoPendenteRetornoId', headerName: 'Transação Pendente RetornoId'},
      {field: 'TipoEmissaoDesc', headerName: 'TipoEmissaoDesc'},
      // fiscal
      {field: 'NaoAfetaLCDPR', headerName: 'Não Afeta LCDPR', valueFormatter: (params) => this.booleanoToString(params.data.NaoAfetaLCDPR)},
      {field: 'TemIPI', headerName: 'Tem IPI', valueFormatter: (params) => this.booleanoToString(params.data.TemIPI)},
      {field: 'TemICMS', headerName: 'Tem ICMS', valueFormatter: (params) => this.booleanoToString(params.data.TemICMS)},
      {field: 'TemICMSST', headerName: 'Tem ICMSST', valueFormatter: (params) => this.booleanoToString(params.data.TemICMSST)},
      // notificacoes
      {field: 'EnviaNotificacaoInclusao', headerName: 'Envia Notificação Inclusão', valueFormatter: (params) => this.booleanoToString(params.data.EnviaNotificacaoInclusao)},
      {field: 'NotificarUsuarioCriacaoRecebimento', headerName: 'Notificar Usuario Criação No Recebimento', valueFormatter: (params) => this.booleanoToString(params.data.NotificarUsuarioCriacaoRecebimento)},
      {field: 'NotificarUsuarioCriacaoNaAprovacao', headerName: 'Notificar Usuario Criação Na Aprovação', valueFormatter: (params) => this.booleanoToString(params.data.NotificarUsuarioCriacaoNaAprovacao)},
      {field: 'NotificarUsuarioCriacaoNaReprovacao', headerName: 'Notificar Usuario Criação Na Reprovação', valueFormatter: (params) => this.booleanoToString(params.data.NotificarUsuarioCriacaoNaReprovacao)},
      {field: 'NotificarUsuarioCriacaoNaRevisao', headerName: 'Notificar Usuario Criação Na Revisão', valueFormatter: (params) => this.booleanoToString(params.data.NotificarUsuarioCriacaoNaRevisao)}
    ];
    console.debug(this.colDefs)

  }

  booleanoToString(t: boolean) {
    return t ? 'Sim' : 'Não';
  }

  listarTransacoes(){
    this.carregando = true;
    this.transacaoService.getTransacoesTotais().subscribe(
      res => {
        this.transacoes = res;
        this.auxTransacoes = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarTransacoes();

      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }


  filtrarTransacoes(){
    this.transacoes = this.auxTransacoes.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca)
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/transacao']);
  }

  listarUsuarios(){
    this.usuarioService.getUsuarios().subscribe({
      next: (res) => {
          this.listaUsuario = res;
      },
      error: (err) => {
        this.utils.getErro(err);
      },
    });
  }



  listarGrupoTransacoes(){
    this.grupoTransacaoService.getList().subscribe({
      next: (res) => {
          this.listaGrupoTransacao = res
      },
      error: (err) => {
        this.utils.getErro(err);
      },
    });
  }

  novo() {
    this.router.navigate(['/cadastros/transacao/inserir']);
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/transacao/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {

    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione uma Transação para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas uma Transação para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar '+ this.objetosSelecionados[0].Descricao + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.transacaoService.deleteTransacao(this.objetosSelecionados[0].Id).subscribe(
            res => {
              this.toasty.show('Feito!', 'Transação excluída!', 'success');
              this.listarTransacoes();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

  editar() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione uma Transação para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas uma Transação para prosseguir!");
      return;
    }
    this.router.navigate([`/cadastros/transacao/${this.objSelecionadoId}`]);
  }

}
