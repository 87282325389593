import { ChamadoComponent } from './chamado.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CustomFormsModule } from 'ng2-validation';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { NgbDatepickerModule, NgbDateParserFormatter, NgbDatepickerI18n, NgbTooltipModule, NgbCollapseModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { I18n, CustomDatepickerI18n } from 'src/app/services/CustomDatepickerI18n';
import { NgbDatePTParserFormatter } from 'src/app/services/NgbDatePTParserFormatter';
import { SharedFarmModule } from '../shared-farm/shared-farm.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TinymceModule} from 'angular2-tinymce';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ReactiveFormsModule } from '@angular/forms';
import { NovoChamadoComponent } from './novo-chamado/novo-chamado.component';
import { FiltroChamadoComponent } from './filtro-chamado/filtro-chamado.component';
import { DetalheChamadoComponent } from './detalhe-chamado/detalhe-chamado.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient} from '@angular/common/http';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [
    ChamadoComponent,
    NovoChamadoComponent,
    FiltroChamadoComponent,
    DetalheChamadoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SharedFarmModule,
    TinymceModule,
    NgbModule,
    NgSelectModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTabsetModule,
    ReactiveFormsModule,
    FileUploadModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
    })
  ],
  providers: [
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],
]
})
export class ChamadoModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
