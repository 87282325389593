import { Movimento } from '../classes/Movimento';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltroTela } from '../classes/FiltroTela';
import { CriarNotaEntradaRequest } from '../classes/auxiliar/CriarNotaEntradaRequest';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class MovimentoService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  getList(): Observable<any>{
    return this.http.get(`${environment.apiURL}/Movimento`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Movimento/${id}`)
  }

  post(movimento: Movimento): Observable<any>{
    return this.http.post(`${environment.apiURL}/Movimento`, movimento);
  }

  put(id: number, final:boolean, movimento: Movimento): Observable<any>{
    return this.http.put(`${environment.apiURL}/Movimento/${id}/${final}`, movimento);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/Movimento/${id}`);
  }
  podeAprovar(id: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Movimento/PodeAprovar/${id}`);
  }
  aprovar(id: number, tipo: number, texto: string): Observable<any>{
    return this.http.post(`${environment.apiURL}/Movimento/Aprovar/${id}/${tipo}`, {Texto: texto});
  }
  receber(transacaoId: number, movimento: Movimento): Observable<any>{
    return this.http.post(`${environment.apiURL}/Movimento/Receber/${transacaoId}`, movimento);
  }
  criarNotaEntrada(request: CriarNotaEntradaRequest): Observable<any>{
    return this.http.post(`${environment.apiURL}/Movimento/CriarNotaEntrada`, request);
  }
  puxaSafra(movimnetoId: number, safras: number[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/Movimento/PuxaSafra/${movimnetoId}`, {SafraIds: safras});
  }
  deleteItemSafra(movimnetoId: number, safraId: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/Movimento/PuxaSafra/${movimnetoId}/${safraId}`);
  }
  getGraficoQuantitativo(tipo: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Movimento/GraficoQuantitativo/${tipo}`);
  }
  getDadosGrafico(): Observable<any>{
    return this.http.get(`${environment.apiURL}/Movimento/DadosGrafico`);
  }
  getPaginando(pagina:number, itensPorPagina: number, tipo: number = 0): Observable<any>{

    if(tipo)
      return this.http.get(`${environment.apiURL}/Movimento/listarPaginando/${tipo}/${pagina}/${itensPorPagina}`)

    return this.http.get(`${environment.apiURL}/Movimento/listarPaginando/${pagina}/${itensPorPagina}`)
  }

  getPaginandoTipado(pagina:number, itensPorPagina: number, tipo:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Movimento/listarPaginando/${tipo}/${pagina}/${itensPorPagina}`)
  }
  getPaginandoDescendente(pagina:number, itensPorPagina: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/Movimento/listarPaginadoDescendente/${pagina}/${itensPorPagina}`)
  }
  gerar(transacaoId: number): Observable<any>{
    return this.http.post(`${environment.apiURL}/Movimento/Gerar/${transacaoId}`, null)
  }
  clonar(id:number): Observable<any>{
    return this.http.post(`${environment.apiURL}/Movimento/Clonar`, id)
  }

  listarPaginado(filtro: any): Observable<any> {
    return this.http.get(`${environment.apiURL}/Movimento/listarPaginando?${this.utils.toQueryString(filtro)}`)
  }
}
