import { TranslateService } from '@ngx-translate/core';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { StorageService } from 'src/app/services/storage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/classes/Usuario';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { MasksService } from 'src/app/utils/masks.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { UsuarioFormComponent } from './usuario-form/usuario-form.component';
import { PermissaoUsuario } from 'src/app/classes/PermissaoUsuario';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { PermissaoUsuarioTransacao } from 'src/app/classes/PermissaoUsuarioTransacao';
import { ColDef } from 'src/app/classes/grid/ColDef';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  @ViewChild('ModalCopiaPermissao', { static: false })
  ModalCopiaPermissao: UiModalComponent;
  @ViewChild('ModalCopiaPermissaoTransacao', { static: false })
  ModalCopiaPermissaoTransacao: UiModalComponent;
  usuarios: Usuario[];
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  objSelecionado: Usuario = new Usuario();
  objSelecionadoId: number = null;
  objetosSelecionados: Usuario[] = [];
  selectedId: number = 0;
  isSubmit: boolean = false;

  colDefs: ColDef[] = [
    {
      width: 45,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: 'Nome', headerName: 'Nome' },
    { field: 'Email', headerName: 'E-mail' },
    { field: 'Telefone', headerName: 'Telefone' },
    { field: 'Login', headerName: 'Login' },
    { field: 'Perfil', headerName: 'Perfil', valueFormatter: (params) => params.data.Perfil == 1 ? 'Super' : params.data.Perfil == 2 ? 'Administrador' : 'Usuário' },
    { field: 'Ativo', headerName: 'Status', width: 70, cellRenderer: (params) => params.data.Ativo ? `<span class="badge badge-light-success custom-badge-grid">Ativo</span>` : `<span class="badge badge-light-danger custom-badge-grid">Inativo</span>` }
  ];

  // tradução
  USUARIO_ALTERADO: string = 'Usuário alterado!';
  USUARIO_CADASTRADO: string = 'Usuário cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  USUARIO_DELETADO: string = 'Usuário deletado!';


  configurarTraducao() {
    this.USUARIO_ALTERADO = this.translate.instant('USUARIO_ALTERADO');
    this.USUARIO_CADASTRADO = this.translate.instant('USUARIO_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.USUARIO_DELETADO = this.translate.instant('USUARIO_DELETADO');
  }

  constructor(private usuarioService: UsuarioService,
    private empresaService: EmpresaService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private translate: TranslateService,
    private utils: UtilsService,
    public masks: MasksService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    if (this.id > 0)
      this.inserindo = true;
    else
      this.listarUsuarios();
  }

  listarUsuarios() {
    this.carregando = true;
    this.usuarioService.getUsuarios().subscribe(
      res => {
        this.usuarios = res;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }
  excluir() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione um usuário para prosseguir!");
      return;
    }
  }

  save(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;

      this.carregando = true;

      if (this.objSelecionado.Id > 0)
        this.usuarioService.putUsuario(this.objSelecionado.Id, this.objSelecionado).subscribe(
          res => {
            this.utils.handleSuccess(this.USUARIO_ALTERADO);
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      else
        this.usuarioService.postUsuario(this.objSelecionado).subscribe(
          res => {
            this.utils.handleSuccess(this.USUARIO_CADASTRADO);
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });



    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um usuário para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Excluir ' + this.objetosSelecionados[0].Nome + '?',
      text: 'Se excluir, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.usuarioService.deleteUsuario(this.objSelecionadoId).subscribe(
          res => {
            this.utils.handleSuccess(this.USUARIO_DELETADO);
            this.listarUsuarios();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  novo() {
    this.router.navigate([`/cadastros/usuario/inserir`]);
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/usuario/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  editar() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione um usuário para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas um usuário para prosseguir!");
      return;
    }

    this.router.navigate(['/cadastros/usuario/' + this.objetosSelecionados[0].Id]);
  }

  acao(acao: string) {
    switch (acao) {
      case "Editar":
        this.editar();
        break;
      case 'Permissões Usuário':
        if (this.objetosSelecionados.length <= 0) {
          this.utils.handleWarning("Selecione um usuário para prosseguir!");
          break;
        }
        if (this.objetosSelecionados.length > 1) {
          this.utils.handleWarning("Selecione apenas um usuário para prosseguir!");
          break;
        }
        this.abrirModalCopiaPermissao();
        break;
      case 'Permissões Transações':
        if (this.objetosSelecionados.length <= 0) {
          this.utils.handleWarning("Selecione um usuário para prosseguir!");
          break;
        }
        if (this.objetosSelecionados.length > 1) {
          this.utils.handleWarning("Selecione apenas um usuário para prosseguir!");
          break;
        }
        this.abrirModalCopiaPermissaoTransacao();
        break;
      default:
        break
    }
  }

  abrirModalCopiaPermissao() {
    this.ModalCopiaPermissao.show();
  }

  fecharModalCopiaPermissao() {
    this.selectedId = null;
    this.ModalCopiaPermissao.hide();
  }

  onCopiarPermissao(id) {
    let usuario = this.objetosSelecionados[0];
    const permissao = this.usuarios.filter(u => u.Id == id).map(e => e.PermissaoUsuario)[0];
    permissao.UsuarioId = id;
    usuario.PermissaoUsuario = permissao;
    this.usuarioService.putUsuario(this.objetosSelecionados[0].Id, usuario).subscribe(res => {
      this.utils.handleSuccess('Permissão copiada');
    }, err => this.utils.getErro(err));
    this.fecharModalCopiaPermissao();
  }

  abrirModalCopiaPermissaoTransacao() {
    this.ModalCopiaPermissaoTransacao.show();
  }

  fecharModalCopiaPermissaoTransacao() {
    this.selectedId = null;
    this.ModalCopiaPermissaoTransacao.hide();
  }

  onCopiarPermissaoTransacao(id) {
    let usuario = this.objetosSelecionados[0];
    const usuarioId = this.objetosSelecionados[0].Id;

    usuario.permissaoUsuarioTransacaos.forEach(p => {
      this.usuarioService.deletePermissaoUsuarioTransacaos(p.Id, p.UsuarioId).subscribe(res => {

      }, err => this.utils.getErro(err));
    })

    let permissao = this.usuarios.filter(u => u.Id == id).map(e => e.permissaoUsuarioTransacaos)[0];

    setTimeout(() => {
      permissao.forEach(p => {
        var aux = p;
        aux.Id = 0;
        aux.UsuarioId = usuarioId;
        this.usuarioService.postPermissaoUsuarioTransacaos(usuarioId, aux).subscribe(res => {
          this.utils.handleSuccess(`Permissão transação ${p.NomeTransacao} copiada com sucesso!`);
        }, err => this.utils.getErro(err));
      })
    }, 1500);

    this.listarUsuarios();
    this.fecharModalCopiaPermissaoTransacao();
  }
}
