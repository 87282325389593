export enum EnumTipoItem {
  Produto = 1,
  Servico,
  Ambos
}

export const ListaTipoItem = [
  { descricao: "Produto", valor: EnumTipoItem.Produto },
  { descricao: "Servico", valor: EnumTipoItem.Servico },
  { descricao: "Ambos", valor: EnumTipoItem.Ambos }
];