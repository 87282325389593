import { TranslateService } from "@ngx-translate/core";

export abstract class CulturaTranslate{
  /**
   *
   */
  constructor(translate: TranslateService) {
    this.ERRO = translate.instant('ERRO');
    this.FEITO = translate.instant('FEITO');
    this.CULTURA_ALTERADA = translate.instant('CULTURA_ALTERADA');
    this.CULTURA_CADASTRADA = translate.instant('CULTURA_CADASTRADA');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = translate.instant('CANCELAR');
    this.SIM = translate.instant('SIM');
    this.CULTURA_DELETADA = translate.instant('CULTURA_DELETADA');
  }
  DELETAR_PARAM: string;
  ERRO: string = 'Erro!';
  FEITO: string = 'Feito!';
  CULTURA_ALTERADA: string = 'Cultura alterada!';
  CULTURA_CADASTRADA: string = 'Cultura cadastrada!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  CULTURA_DELETADA: string = 'Cultura deletada!';
}
