export class ListaProdutoConsolidado{
  Id: number;
  ProdutoConsolidadoId: number;
  SafraId: number;
  ProdutoId: number;
  Quantidade: number = 0;
  ValorUnitario: number = 0;
  AreaAplicacao: number = 0;
  DoseMedia: number = 0;
  Dose: number = 0;
  QuantidadeAplicacao: number = 0;
  QuantidadeNecessaria: number = 0;
  ValorTotal: number = 0;
  DescricaoSafra: string = "";
  DescricaoProduto: string = "";

  EmpresaId: number;
  DataCadastro: Date = new Date();
  DataAlteracao: Date = new Date();
  UsuarioCadastroId: number;
  UsuarioAlteracaoId: number;
}
