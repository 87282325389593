import { Component, OnInit, ViewChild } from '@angular/core';
import { AnexoPendenciaFrota, PendenciaFrota } from 'src/app/classes/PendenciaFrota';
import { PendenciaFrotaService } from 'src/app/services/pendencia-frota.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-visualizar-anexos-pendencias',
  templateUrl: './modal-visualizar-anexos-pendencias.component.html',
  styleUrls: ['./modal-visualizar-anexos-pendencias.component.scss']
})
export class ModalVisualizarAnexosPendenciasComponent implements OnInit {

  @ViewChild('ModalVisualizarAnexosPendencia', { static: true }) modal;
  pendenciaFrota: PendenciaFrota = new PendenciaFrota();
  
  constructor(private pendenciaFrotaService: PendenciaFrotaService,
    private utils: UtilsService) { }

  ngOnInit() {
  }

  abrirModal(pendenciaFrota){
    this.pendenciaFrota = pendenciaFrota;
    this.modal.show();
  }
  cancelar(): void {
    this.modal.hide();
  }

  download(arquivo: string) {
    this.pendenciaFrotaService.getAnexo(arquivo);
  }

  excluir(anexo: AnexoPendenciaFrota) {

    if(!anexo.Id){
      const indice = this.pendenciaFrota.Anexos.findIndex(item => item.Id === anexo.Id);
      if (indice !== -1) {
        this.pendenciaFrota.Anexos.splice(indice, 1);
      }
      return;
    }

    Swal.fire({
      title: 'Deletar '+ anexo.Arquivo + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.pendenciaFrotaService.deleteAnexo(anexo.Id).subscribe(res =>{
          const indice = this.pendenciaFrota.Anexos.findIndex(item => item.Id === anexo.Id);
          if (indice !== -1) {
            this.pendenciaFrota.Anexos.splice(indice, 1);
          }
        },
        err => {
          this.utils.getErro(err);
        });
      }
    });
  }
}
