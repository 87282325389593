import { EnumTipoMovimento } from "./GrupoTransacao";
import { ItemEnum } from "./ItemEnum"
import { ItemMovimento } from "./ItemMovimento";
import { MBaseEmpresa } from "./MBaseEmpresa";
import { Usuario } from "./Usuario"
import { itensCotacao } from "./cotacao/itensCotacao";

export class RegistrosAprovacoes extends MBaseEmpresa{
  CodigoOrigem: number
  AprovadorId: number
  Aprovador: Usuario
  Valor: number
  DataEvento: Date
  Evento: EnumEventoAprovacao
  TipoEvento: EnumTipoMovimento
  EmpresaInternaId: number
  EmpresaInternaDescricao: string
  Descricao: string
  DateEmissao: Date
  ItensMovimentos: ItemMovimento[]
  ItensCotacao: itensCotacao[]
  Observacao:string = "";
  GetTipoEvento:string = "";
}

export enum EnumEventoAprovacao
{
    Cadastrado = 0,
    Aprovado = 1,
    Reprovado = 2,
    Revisar = 3,
    EmAprovacao = 4,
}

export enum EnumTipoEventoAprovacao
{
    SolicitacaoCompras = 1,
    Cotacao = 2,
    PedidoCompras = 3,
    Pagamento = 4
}

export const ListaEnumEventoAprovacao: ItemEnum[] = [
  {name: 'Aprovado', value: EnumEventoAprovacao.Aprovado},
  {name: 'Cadastrado', value: EnumEventoAprovacao.Cadastrado},
  {name: 'Reprovado', value: EnumEventoAprovacao.Reprovado},
  {name: 'Revisar', value: EnumEventoAprovacao.Revisar},
];
export const ListaEnumTipoEventoAprovacao: ItemEnum[] = [
  {name: 'Cotação', value: EnumTipoEventoAprovacao.Cotacao},
  {name: 'Pagamento', value: EnumTipoEventoAprovacao.Pagamento},
  {name: 'Pedido de Compra', value: EnumTipoEventoAprovacao.PedidoCompras},
  {name: 'Solicitação de Compra', value: EnumTipoEventoAprovacao.SolicitacaoCompras},
];
