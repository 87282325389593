import { EmpresaService } from './empresa.service';
import { StorageService } from './storage.service';
import { auxPossuiIntegracao } from '../classes/auxiliar/auxPossuiIntegracao';
import { Empresa, enumErpIntegrado } from '../classes/Empresa';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class auxIntegracaoService {
  constructor(private empresaService: EmpresaService,
    private storageService: StorageService) {   }

  getIntegracao():Promise<auxPossuiIntegracao> {
    return new Promise((resolve, reject) => {
      try {
        let retorno = new auxPossuiIntegracao();
        this.empresaService.getEmpresa(this.storageService.getEmpresa().Id).subscribe(ret => {
          let empresa: Empresa = ret;
          retorno.possuiIntegracao = empresa.ErpIntegrado > enumErpIntegrado.Nenhum;
          retorno.integracaoRM = empresa.ErpIntegrado;
          retorno.ultimaSincronizacao = empresa.ErpIntegrado == enumErpIntegrado.Totvs_RM ? empresa.DataSincTotvsRm : empresa.DataSincSankhya;
          retorno.permissaoSincronizaEstoqueERP = this.storageService.getUsuario().SincronizaEstoqueERP;
          resolve(retorno);
        }, erro => {
          reject(new auxPossuiIntegracao());
        });
      } catch (error) {
        reject(new auxPossuiIntegracao());
      }
    });
  }
}
