export class FiltroTela{
   TransacaoId: number;
   EmpresaInternaId: number;
   DataCriacaoInicial: Date = new Date();
   DataCriacaoFinal: Date = new Date();
   DataEmissaoInicial: Date = new Date();
   DataEmissaoFinal: Date = new Date();
   NumeroDocumento:string = "";
   Identificador: number = 0;
   Status: number = 0;
   ProdutoId: number;
   Tipo: number = 0;
}
