import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/services/storage.service';
import { Usuario } from 'src/app/classes/Usuario';
import { LoginService } from 'src/app/services/login.service';
import { Empresa } from 'src/app/classes/Empresa';
import { Subscription } from 'rxjs';
import { EmpresaService } from 'src/app/services/empresa.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  usuario: Usuario = new Usuario();

  empresas: Empresa[];
  empresaSelecionada: Empresa = new Empresa();

  sub: Subscription;

  public modalRedefinirSenha: boolean = false;
  public modalRedefinirSenhaFornecedor: boolean = false;

  currentIdioma: string;

  constructor(private storageService: StorageService,
    private loginService: LoginService,
    private empresaService: EmpresaService,
    private router: Router,
    private translate: TranslateService,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.usuario = this.storageService.getUsuario();
    if (this.usuario && this.usuario.Idioma){
      this.currentIdioma = this.usuario.Idioma;
      this.translate.setDefaultLang(this.currentIdioma);
    }
    else
      this.currentIdioma = this.translate.getDefaultLang()

    if (this.storageService.getEmpresa() != null) {
      this.empresaSelecionada = this.storageService.getEmpresa();
      this.empresaService.setEmpresaSelecionada(this.empresaSelecionada);
    }

    this.atualizarEmpresas(false);

    this.sub = this.empresaService.novaEmpresaEmitter.subscribe((obj) => {
      this.atualizarEmpresas(obj.semReload);
    })

  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  atualizarEmpresas(semReload: boolean){
    this.empresaService.getEmpresas().subscribe(ret =>{
      this.empresas = ret;
      let id = 1;
      if (this.storageService.getEmpresa() != null)
        id = this.storageService.getEmpresa().Id;

      this.changeEmpresa(id, semReload);
    });
  }

  changeEmpresa(id: number, semReload?: boolean){
    if (this.empresaSelecionada.Id != id){
      this.empresaSelecionada = this.empresas.filter(e => e.Id==id)[0];
      this.empresaService.setEmpresaSelecionada(this.empresaSelecionada);

      if (!semReload)
        window.location.href = '/'; //this.router.navigate(['/painel']);
    }
  }

  sair(){
    this.loginService.logout();
  }

  fecharModalRedefinirSenha(){
    this.modalRedefinirSenha = false;
    this.modalRedefinirSenhaFornecedor = false;
  }

  selecionaIdioma(idioma) {
    this.currentIdioma = idioma;
    this.translate.setDefaultLang(idioma);
    this.translate.use(idioma)
    this.usuarioService.definirIdioma(idioma).subscribe(res =>{
      this.usuario.Idioma = idioma;
      this.storageService.setUsuario(this.usuario);
    })
  }
}
