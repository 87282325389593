export class AgendaAtividade{
    Id: number;
    FazendaId: number;
    FuncionarioId: number;
    EquipamentoId: number;
    Data: Date = new Date();
    OrdensServicoId:string = "";
    Status: EnumStatusAgenda;
    TotalArea: number = 0;
    TotalAreaApontada: number = 0;
    AgendaAtividadeOrigemId: number;
    AreaId: number;
    AtividadeId: number;
    OperacaoAgricolaId:number;
}

export enum EnumStatusAgenda
{
  Aberta = 1,
  Concluida = 2
}
