//import { SwitchItemSafraComponent } from './switch-item-safra/switch-item-safra.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { ItemMovimento } from 'src/app/classes/ItemMovimento';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Movimento } from 'src/app/classes/Movimento';
import { Natureza } from 'src/app/classes/Natureza';
import { Projeto } from 'src/app/classes/Projeto';
import { Safra } from 'src/app/classes/Safra';
import { Transacao } from 'src/app/classes/Transacao';
import { ItemMovimentoService } from 'src/app/services/item-movimento.service';
import { MovimentoService } from 'src/app/services/movimento.service';
import { SafraService } from 'src/app/services/safra.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { PersonalizacaoGridService } from 'src/app/services/PersonalizacaoGrid.Service';
import { EnumGrid } from 'src/app/classes/enum/EnumGrid';
import { ColDef } from 'src/app/classes/grid/ColDef';

export class MovimentoSafra {
  SafraId: number = 0;
  Descricao: string;
  FazendaDescricao: string;
  Items: ItemMovimento[];
}

@Component({
  selector: 'app-movimento-item-safra',
  templateUrl: './movimento-item-safra.component.html',
  styleUrls: ['./movimento-item-safra.component.scss'],
  //entryComponents: [SwitchItemSafraComponent]
})
export class MovimentoItemSafraComponent implements OnInit {

  constructor(
    private safraService: SafraService,
    private movimentoService: MovimentoService,
    private itemMovimentoService: ItemMovimentoService,
    private utils: UtilsService,
    private PersonalizacaoGridService: PersonalizacaoGridService,
    private router: Router) { }
  @Input() movimento: Movimento;
  @Input() transacao: Transacao = new Transacao();
  @Input() projetos: Projeto[] = [];
  @Input() centrosResultados: CentroDeResultados[] = [];
  @Input() naturezas: Natureza[] = [];
  @Input() locaisEstoque: LocalEstoque[] = [];
  @Input() colDefsItems: ColDef[];
  @ViewChild('Modal', { static: true }) modal;
  @ViewChild('ModalQtd', { static: true }) modalQtd;
  @ViewChild('safrasMovimentoGrid', { static: false }) safrasMovimentoGrid;
  @ViewChild('itemsSafrasMovimentoGrid', { static: false }) itemsSafrasMovimentoGrid;
  @ViewChild('modalPlanejamento', { static: false }) modalPlanejamento

  colDefsSafra: ColDef[] = [];
  colDefsItemSafra: ColDef[] = [];
  inserindo = false;
  safras: Safra[] = [];
  // safrasMovimento: MovimentoSafra[] = [];
  safrasSelecionadas: MovimentoSafra[] = [];
  safraMovimentoSelecionada: MovimentoSafra;
  itemSelecionado: ItemMovimento = new ItemMovimento();
  safraId: number = 0;
  auxItems: ItemMovimento[] = [];
  isSubmitSafra = false;
  isSubmitQtd = false;
  showTable:boolean = false;
  safrasSelect = [];
  carregando = false;

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;

    this.colDefsSafra = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Safra', field: 'Descricao', flex: 1 },
      { headerName: 'Fazenda', field: '', flex: 1, valueFormatter: (params) => this.fazendaDesc(params.data.SafraId) },
      { headerName: 'Area Total (Ha)', field: 'Total', flex: 1, cellStyle: {'text-align': 'right'}, valueFormatter: (params) => this.utils.formatarDecimais(this.totalSafra(params.data.SafraId)) }
    ];

    const colunaQtdPedida = { headerName: 'Qtd Pedida', field: 'QuantidadePedida', flex: 1, sort: 'desc', cellStyle: {'text-align': 'right'}, valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.QuantidadePedida)}` } as ColDef;
    let colDefsItemSafra = [...this.colDefsItems].filter(p => p.field != colunaQtdPedida.field)
    colDefsItemSafra.splice(2, 0, { headerName: 'Qtd Original', field: 'QuantidadeOriginal', flex: 1, cellStyle: {'text-align': 'right'}, valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.QuantidadeOriginal)}` });
    colDefsItemSafra.push({ field: 'Dose', flex: 1, cellStyle: {'text-align': 'right'}, valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.Dose)}` });
    colDefsItemSafra.push({ headerName: 'Area(Ha)', field: 'Area', flex: 1, cellStyle: {'text-align': 'right'}, valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.Area)}` });
    //colDefsItemSafra.push( {headerName: 'Incluir', field: '', flex: 1, cellRenderer: SwitchItemSafraComponent, cellRendererParams: {callbackFunction: this.onSwitchChange.bind(this)}});
    colDefsItemSafra.splice(3, 0, colunaQtdPedida);
    this.colDefsItemSafra = colDefsItemSafra;
    //aplica personalizaçãodo grid
    this.PersonalizacaoGridService.GetPorUserTela(EnumGrid.ItemSafra).subscribe(res => {
      if (res == null) {
        this.PersonalizacaoGridService.postPersonalizacao(EnumGrid.ItemSafra, 'vazio', false, colDefsItemSafra).subscribe(res => {
          this.colDefsItemSafra = colDefsItemSafra;
        });
      }
      else {
        colDefsItemSafra.forEach(element => {
          let col = res.filter(x => x.field == element.field)[0];
          if (col.field == element.field) {
            //element.hide = col.hide;
          }
        });
        this.colDefsItemSafra = colDefsItemSafra;
      }
    });
    //Fim aplica personalizaçãodo grid
    this.listarSafras();
    // let colunaSafra = {field: 'SafraId', flex: 1} as ColDef;
    // this.colDefsItems.push(colunaSafra);
  }

  oncolumnVisibleItens(event) {
    this.PersonalizacaoGridService.postPersonalizacao(EnumGrid.ItemSafra, event.column.colId, !event.column.visible, this.colDefsItems).subscribe(res => {
      this.colDefsItemSafra.forEach(element => {
        let col = res.filter(x => x.field == element.field)[0];
        if (col.field == element.field) {
          //element.hide = col.hide;
        }
      });
    });
  }

  onSwitchChange(obj) {
    obj.item.QuantidadePedida = obj.value ? obj.item.QuantidadeOriginal : 0;
    this.itemMovimentoService.put(obj.item.Id, obj.item)
      .subscribe(res => {
        const index = this.safraMovimentoSelecionada.Items.findIndex(p => p.Id == obj.item.Id);
        this.safraMovimentoSelecionada.Items[index] = obj.item;
        this.auxItems = this.safraMovimentoSelecionada.Items;
        this.itemsSafrasMovimentoGrid.setRowData(this.auxItems);
      })
  }
  novo() {
    this.modal.show();
  }
  action(event){
    switch (event) {
      case 'Planejamento':
        if (this.safrasSelecionadas.length <= 0) {
          this.utils.handleWarning("Selecione uma safra para prosseguir!");
          return;
        }
        console.debug('vai listar')
        this.modalPlanejamento.listarPlanejamentos();
        this.modalPlanejamento.open();
        break;
      default:
        break;
    }
  }
  totalSafra(id): number {
    let retorno = 0;
    const items = this.movimento.ItemsSafra.find(p => p.SafraId == id).Items;
    items.forEach(p => retorno += p.Total);
    return retorno;

  }
  listarSafras() {
    this.safraService.getSafrasNaoIniciado()
      .subscribe(res => {
        this.safras = res;
        this.showTable = true;
      }, err => this.utils.getErro(err));
  }
  listarItemsSafra() {

  }
  fazendaDesc(id: number): string {
    console.debug(id)
    if (id != null || id != undefined) {
      let safra = this.safras.find(p => p.Id == id);
      if (safra)
        return safra.DescricaoFazenda;
      else
        return '';
    }
    return '';
  }
  adicionar(form: any) {
    if (this.safrasSelect.length <= 0) {
      this.isSubmitSafra = true;
      return;
    }
    this.carregando = true;
    this.movimentoService.puxaSafra(this.movimento.Id, this.safrasSelect)
      .subscribe(items => {
        if (items.length <= 0) {
          this.utils.handleWarning('Não há produtos nessa Safra!');
          return;
        }
        // let safraId = this.safraId;
        // let desc = this.safras.find(p => p.Id == safraId).Descricao;
        // let safra =  {
        //   SafraId: safraId,
        //   Descricao: desc,
        //   Items: items
        // } as MovimentoSafra;
        items.forEach(p => {
          if(p.Items.length > 0){
            if(this.movimento.ItemsSafra == null)
              this.movimento.ItemsSafra = [];
            this.movimento.ItemsSafra.push(p);
            this.movimento.ValorLiquido += p.Items.reduce((acc, curr) => acc + curr.ValorTotalLiquido, 0);
          }
        });
        this.safrasMovimentoGrid.setRowData(this.movimento.ItemsSafra)
        this.safraId = null;
        this.utils.handleSuccess("Itens de safra adicionados!");

        this.movimento.ValorBruto = this.movimento.ValorLiquido - this.movimento.Descontos + this.movimento.Juros + this.movimento.Acrescimos + this.movimento.Funrural;
        this.modal.hide();
        this.safrasSelect = [];
        this.safrasMovimentoGrid.selectFirstRow();
        this.auxItems = this.movimento.ItemsSafra[0].Items;
        this.carregando = false;
      }, err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }
  remove() {
    if (this.safrasSelecionadas.length <= 0) {
      this.utils.handleWarning("Selecione uma ou mais safras para prosseguir!");
      return;
    }
    this.safrasSelecionadas.forEach(p => {
      this.movimentoService.deleteItemSafra(this.movimento.Id, p.SafraId)
        .subscribe(res => {
          this.movimento.ItemsSafra = this.movimento.ItemsSafra.filter(y => y.SafraId != p.SafraId);
          this.safrasMovimentoGrid.setRowData(this.movimento.ItemsSafra);
          this.auxItems = [];
          this.movimento.ValorLiquido -= res;
          this.movimento.ValorBruto = this.movimento.ValorLiquido - this.movimento.Descontos + this.movimento.Juros + this.movimento.Acrescimos + this.movimento.Funrural;
          this.utils.handleSuccess("Itens de safra removidos!");
        }, err => this.utils.getErro(err));
    })
  }
  salvarItem(form: any) {
    if (!form.valid) {
      this.isSubmitSafra = true;
      return;
    }
    this.itemMovimentoService.put(this.itemSelecionado.Id, this.itemSelecionado)
      .subscribe(res => {
        const index = this.safraMovimentoSelecionada.Items.findIndex(p => p.Id == this.itemSelecionado.Id);
        if (index != -1) {
          this.safraMovimentoSelecionada.Items.splice(index, 1, this.itemSelecionado);
          this.auxItems = this.safraMovimentoSelecionada.Items;
          this.itemsSafrasMovimentoGrid.setRowData(this.auxItems);
        }
        this.modalQtd.hide();
        this.utils.handleSuccess("Quantidade atualizada!");
      }, err => this.utils.getErro(err));
  }
  cellClicked(row) {
    this.safraMovimentoSelecionada = row.data;
    this.auxItems = row.data.Items;
  }
  cellDoubleClickedItem(event) {
    this.modalQtd.show();
  }
  cellClickedItem(row) {
    this.itemSelecionado = row.data;
  }
  selectionChanged(event) {
    this.safrasSelecionadas = event;
  }
}
