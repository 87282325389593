import { Planejamento } from './Planejamento';
import { EstadioFenologico } from './EstadioFenologico';

export class Etapa {
    Id: number;
    Descricao:string = "";
    DiasAposPlantio: number = 0;
    ReferenciaDAP: number = 0;
    EstadioFenologico: EstadioFenologico;
    EstadioFenologicoId: number;
    Index: number = 0;
    Collapsed: boolean;
    Planejamento:Planejamento;
    PlanejamentoId: number;
    getReferenciaDAP:string = "";
    ProcessoPlanejamentoId: number;
}
