import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListaProdutoConsolidado } from 'src/app/classes/ListaProdutoConsolidado';
import { ProdutoConsolidado } from 'src/app/classes/ProdutoConsolidado';
import { Safra } from 'src/app/classes/Safra';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { ProdutoConsolidadoService } from 'src/app/services/produtoConsolidado.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-produtoConsolidado',
  templateUrl: './produtoConsolidado.component.html',
  styleUrls: ['./produtoConsolidado.component.scss']
})
export class ProdutoConsolidadoComponent implements OnInit {
  @ViewChild('ModalProduto', { static: false })
  ModalProduto: UiModalComponent;

  ProdutoConsolidado: ProdutoConsolidado = new ProdutoConsolidado();
  ProdutosConsolidado: ProdutoConsolidado[] = [];
  listaProdutoConsolidadoId: number = 0;
  listaProdutoConsolidado: ListaProdutoConsolidado = new ListaProdutoConsolidado();
  carregando: boolean = false;
  editando: Boolean = false;
  safraId: number = 0;
  safraSelecionada: Safra;
  idEdiar: number = 0;
  textoBusca: string = '';
  renderizar: boolean = false;
  @ViewChild('Grid', { static: false }) grid: any;
  colDefs: ColDef[] = [
    {
      width: 45,
      lockPosition: true,
      lockVisible: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: 'DescricaoProduto', headerName: 'Produto', flex: 1 },
    { field: 'DescricaoSafra', headerName: 'Safra', flex: 1 },
    { field: 'ValorUnitario', headerName: 'Valor Unitário', flex: 1 },
    { field: 'ValorTotal', headerName: 'Valor Total', flex: 1 },
    { field: 'DoseMedia', headerName: 'Dose', flex: 1 },
    { field: 'QuantidadeAplicacao', headerName: 'Quantidade', flex: 1 }
  ];
  colDefsSafras: ColDef[] = [
    {
      width: 45,
      lockPosition: true,
      lockVisible: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: 'Id', headerName: '#', flex: 1 },
    { field: 'Descricao', headerName: 'Safra', flex: 1 },
    { field: 'DescricaoFazenda', headerName: 'Fazenda', flex: 1 }
  ];
  constructor(
    private produtoConsolidadoService: ProdutoConsolidadoService,
    private utils: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService) {

  }

  ngOnInit() {
    this.carregando = true;
    const id = this.route.snapshot.paramMap.get('id');
    if (id == 'inserir') {
      this.editando = true;
      this.idEdiar = 0;
    } else if (Number.parseInt(id)) {
      this.editando = true;
      this.idEdiar = Number.parseInt(id);
      this.produtoConsolidadoService.get(this.idEdiar).subscribe(res => {
        this.ProdutoConsolidado = res;
        this.carregando = false;
      }, error => {
        this.utils.getErro(error);
      });
    }
    else {
      this.editando = false;
      this.idEdiar = 0;
    }
    this.carregando = false;
  }

  filtrarProdutos() {
    this.carregando = true;
    setTimeout(() => {
      this.carregando = false;
    }, 1000);
  }

  novaListaProduto() {
    this.editando = true;
  }

  fechaNovaListaProduto() {
    this.editando = false;
  }

  fecharModelProduto() {
    this.ModalProduto.hide();
  }
  cancelar() {
    this.router.navigate(['/estoques/produto-consolidado']);
  }
  save(form: any) {
    if (this.idEdiar == 0) {
      this.produtoConsolidadoService.post(this.ProdutoConsolidado).subscribe(res => {
        this.utils.handleSuccess("Produto Consolidado salvo com sucesso");
        this.atualizaGrid();
      }, error => {
        this.utils.getErro(error);
      });
    }
    else {
      this.produtoConsolidadoService.put(this.idEdiar, this.ProdutoConsolidado).subscribe(res => {
        this.utils.handleSuccess("Produto Consolidado salvo com sucesso");
        this.atualizaGrid();
      }, error => {
        this.utils.getErro(error);
      });
    }
  }

  changeSafra(safra: Safra) {
    this.safraId = safra.Id;
  }

  adicionarProdutosSafra() {
    if (this.safraId > 0) {
      if (this.ProdutoConsolidado.Id == 0 || this.ProdutoConsolidado.Id == null || this.ProdutoConsolidado.Id == undefined) {
        this.utils.handleWarning("Deverá salvar a lista antes de adicionar uma safra");
        return;
      }
      this.produtoConsolidadoService.addSafra(this.ProdutoConsolidado.Id, this.safraId).subscribe(res => {
        this.utils.handleSuccess("Produtos da safra adicionados com sucesso");
        this.ModalProduto.hide();
      }, error => {
        this.utils.getErro(error);
      });
    }
    else {
      this.utils.handleWarning("Selecione uma safra para adicionar produtos");
    }
  }

  novo() {
    this.listaProdutoConsolidado = new ListaProdutoConsolidado();
    this.ModalProduto.show();
  }

  cellDoubleClicked(event) {
    this.listaProdutoConsolidado = event.data;
    if (this.listaProdutoConsolidado != undefined || this.listaProdutoConsolidado != null)
      this.listaProdutoConsolidadoId = this.listaProdutoConsolidado.Id;
    else
      this.listaProdutoConsolidadoId = 0;
    if (this.listaProdutoConsolidadoId > 0)
      this.ModalProduto.show();
  }

  onCellClicked(row) {
    if (row.data.Id != undefined || row.data.Id != null)
      this.listaProdutoConsolidadoId = row.data.Id;
    else
      this.listaProdutoConsolidadoId = 0;
  }

  selectionChanged(event) {
    this.listaProdutoConsolidado = event;
    if (this.listaProdutoConsolidado != undefined || this.listaProdutoConsolidado != null)
      this.listaProdutoConsolidadoId = this.listaProdutoConsolidado.Id;
    else
      this.listaProdutoConsolidadoId = 0;
  }

  excluir() {
    Swal.fire({
      title: this.translate.instant('DELETAR') + ' ' + this.listaProdutoConsolidado.DescricaoProduto + '?',
      text: this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA'),
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('CANCELAR'),
      confirmButtonText: this.translate.instant('SIM'),
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        if (this.safraId > 0) {
          if (this.listaProdutoConsolidadoId == 0 || this.listaProdutoConsolidadoId == null || this.listaProdutoConsolidadoId == undefined) {
            this.utils.handleWarning("Deverá salvar a lista antes de remover um produto");
            return;
          }
          this.produtoConsolidadoService.deleteProduto(this.listaProdutoConsolidadoId).subscribe(res => {
            this.utils.handleSuccess("Produto removido com sucesso");
            this.ModalProduto.hide();
          }, error => {
            this.utils.getErro(error);
          });
        }
        else {
          this.utils.handleWarning("Selecione umm produto para remover");
        }
      }
    });
  }

  excluirSafra() {
    Swal.fire({
      title: this.translate.instant('DELETAR_TODOS_PRODUTOS_SAFRA') + ' ' + this.safraSelecionada.Descricao + '?',
      text: this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA'),
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('CANCELAR'),
      confirmButtonText: this.translate.instant('SIM'),
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        if (this.safraId > 0) {
          if (this.ProdutoConsolidado.Id == 0 || this.ProdutoConsolidado.Id == null || this.ProdutoConsolidado.Id == undefined) {
            this.utils.handleWarning("Deverá salvar a lista antes de remover uma safra");
            return;
          }
          this.produtoConsolidadoService.removeSafra(this.ProdutoConsolidado.Id, this.safraId).subscribe(res => {
            this.utils.handleSuccess("Produtos da safra removidos com sucesso");
            this.ModalProduto.hide();
          }, error => {
            this.utils.getErro(error);
          });
        }
        else {
          this.utils.handleWarning("Selecione uma safra para remover os produtos");
        }
      }
    });
  }

  selectionChangedSafra(event) {
    this.safraSelecionada = event;
    if (this.safraSelecionada != undefined || this.safraSelecionada != null)
      this.safraId = this.safraSelecionada.Id;
    else
      this.safraId = 0;
  }

  onCellClickedSafra(row) {
    if (row.data.Id != undefined || row.data.Id != null)
      this.safraId = row.data.Id;
    else
      this.safraId = 0;
  }
  changeProduto(event) {
    this.listaProdutoConsolidado.ProdutoId = event.Id;
  }

  saveProduto() {
    if (this.listaProdutoConsolidado.ProdutoId == 0) {
      this.utils.handleWarning("Selecione um produto");
      return;
    }
    if (this.listaProdutoConsolidado.Quantidade == 0) {
      this.utils.handleWarning("Informe a quantidade");
      return;
    }
    if (this.listaProdutoConsolidado.ValorUnitario == 0) {
      this.utils.handleWarning("Informe o valor");
      return;
    }
    if (this.listaProdutoConsolidado.DoseMedia == 0) {
      this.utils.handleWarning("Informe a dose");
      return;
    }
    if (this.listaProdutoConsolidado.AreaAplicacao == 0) {
      this.utils.handleWarning("Informe a Área de aplicação");
      return;
    }
    this.carregando = true;
    if (this.listaProdutoConsolidado.Id == null || this.listaProdutoConsolidado.Id == undefined)
      this.listaProdutoConsolidado.Id = 0;
    this.listaProdutoConsolidado.ProdutoConsolidadoId = this.ProdutoConsolidado.Id;
    console.log(this.listaProdutoConsolidado);
    if (this.listaProdutoConsolidado.Id == 0) {
      this.produtoConsolidadoService.addProduto(this.ProdutoConsolidado.Id, this.listaProdutoConsolidado).subscribe(res => {
        this.utils.handleSuccess("Produto adicionado com sucesso");
        this.atualizaGrid();
        this.carregando = false;
        this.ModalProduto.hide();
      }, error => {
        this.utils.getErro(error);
      });
    }
    else {
      this.produtoConsolidadoService.atualizaProduto(this.listaProdutoConsolidado).subscribe(res => {
        this.utils.handleSuccess("Produto atualizado com sucesso");
        this.atualizaGrid();
        this.carregando = false;
        this.ModalProduto.hide();
      }, error => {
        this.utils.getErro(error);
      });
    }

    this.carregando = false;
  }

  atualizaGrid() {
    this.produtoConsolidadoService.get(this.ProdutoConsolidado.Id).subscribe(res => {
      this.ProdutoConsolidado = res;
      this.grid.refresh();
    }, error => {
      this.utils.getErro(error);
    });
  }
  exportar() {
    if (this.ProdutoConsolidado.Id > 0) {
      this.carregando = true;
      this.produtoConsolidadoService.exportar(this.ProdutoConsolidado.Id).subscribe(res => {
        this.utils.handleSuccess("Arquivo gerado com sucesso");
        res.subscribe((response: any) => {
          // Cria um elemento de link
          var element = document.createElement('a');
          element.setAttribute('href', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response);
          element.setAttribute('download', 'LisxtaConsolidada_'+this.ProdutoConsolidado.Id+'.xlsx');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          this.carregando = false;
        }, err => {
          this.utils.getErro(err);
        });
      }, error => {

        if (error.status == 200) {
          this.utils.handleSuccess("Arquivo gerado com sucesso");
          let response = error.error.text;

          // Cria um elemento de link
          var element = document.createElement('a');
          element.setAttribute('href', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response);
          element.setAttribute('download', 'LisxtaConsolidada_'+this.ProdutoConsolidado.Id+'.xlsx');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
        else {
          this.utils.getErro(error)
          this.carregando = false;
        }
      });
      this.carregando = false;
    }
  }
}
