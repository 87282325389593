import { TranslateService } from "@ngx-translate/core";

export abstract class ProcessoPlanejamentoTranslate {
  /**
   *
   */
  constructor(translate: TranslateService) {
    this.FEITO = translate.instant('FEITO');
    this.PROCESSO_PLANEJAMENTO_ALTERADO = translate.instant('PROCESSO_PLANEJAMENTO_ALTERADO');
    this.PROCESSO_PLANEJAMENTO_CADASTRADO = translate.instant('PROCESSO_PLANEJAMENTO_CADASTRADO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = translate.instant('CANCELAR');
    this.SIM = translate.instant('SIM');
    this.PROCESSO_DELETADO = translate.instant('PROCESSO_DELETADO');
  }

  FEITO: string = 'Feito!';
  PROCESSO_PLANEJAMENTO_ALTERADO: string = 'Processo Planejamento alterado!';
  PROCESSO_PLANEJAMENTO_CADASTRADO: string = 'Processo Planejamento cadastrado!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  PROCESSO_DELETADO: string = 'Processo deletado!';
  DELETAR_PARAM: string;
}
