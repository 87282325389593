import { TranslateService } from "@ngx-translate/core";

export abstract class PainelFornecedorTranslate {
  /**
   *
   */
  constructor(translate: TranslateService) {
    this.INDICAR_NFE = translate.instant('INDICAR_NFE');
    this.ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = translate.instant('CANCELAR');
    this.SIM = translate.instant('SIM');
    this.FEITO = translate.instant('FEITO');
    this.NFE_ALTERADACADASTRADA = translate.instant('NFE_ALTERADACADASTRADA');
    this.INICIAR_TRASNPORTE = translate.instant('INICIAR_TRASNPORTE');
    this.ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = translate.instant('CANCELAR');
    this.SIM = translate.instant('SIM');
    this.FEITO = translate.instant('FEITO');
    this.INDICACAO_DE_TRASNPORTE_REALIZADO = translate.instant('INDICACAO_DE_TRASNPORTE_REALIZADO');
    this.ERRO = translate.instant('ERRO');
    this.PRECOS_ENVIADOS = translate.instant('PRECOS_ENVIADOS');
    //this.COTACAO_FINALIZA_EM_DIAS_DIAS_HORAS_HORAS_MINUTOS_MINUTOS_SEGUNDOS_SEGUNDOS = translate.instant('COTACAO_FINALIZA_EM_DIAS_DIAS_HORAS_HORAS_MINUTOS_MINUTOS_SEGUNDOS_SEGUNDOS');
    this.DESEJA_APLICAR_OS_DESCONTOS = translate.instant('DESEJA_APLICAR_OS_DESCONTOS');
    this.OS_PRECOS_SERAO_APLICADOS_EM_TODOS_OS_PRODUTOS_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('OS_PRECOS_SERAO_APLICADOS_EM_TODOS_OS_PRODUTOS_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.PRECOS_ATUALIZADOS = translate.instant('PRECOS_ATUALIZADOS');
  }
  INDICAR_NFE: string = 'Indicar NFe';
  ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  FEITO: string = 'Feito!';
  NFE_ALTERADACADASTRADA: string = 'NFe Alterada/Cadastrada!';
  INICIAR_TRASNPORTE: string = 'Iniciar Trasnporte?';
  INDICACAO_DE_TRASNPORTE_REALIZADO: string = 'Indicação de Trasnporte Realizado';
  ERRO: string = 'Erro!';
  PRECOS_ENVIADOS: string = 'Preços Enviados!';
  COTACAO_FINALIZA_EM_DIAS_DIAS_HORAS_HORAS_MINUTOS_MINUTOS_SEGUNDOS_SEGUNDOS: string;
  DESEJA_APLICAR_OS_DESCONTOS: string = 'Deseja aplicar os descontos?';
  OS_PRECOS_SERAO_APLICADOS_EM_TODOS_OS_PRODUTOS_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'os preços serão aplicados em todos os produtos, esta ação não poderá ser desfeita!';
  PRECOS_ATUALIZADOS: string = 'Preços Atualizados!';
}
