import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GrupoProduto } from '../classes/GrupoProduto';

@Injectable({
  providedIn: 'root'
})
export class GrupoProdutoService {

  constructor(private http: HttpClient) { }

  getGruposProduto(): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoProduto`)
  }

  getGrupoProduto(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoProduto/${id}`)
  }

  getServicos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoProduto/listarServicos`)
  }

  getProdutos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoProduto/listarProdutos`)
  }

  postGrupoProduto(grupoProduto: GrupoProduto): Observable<any>{
    return this.http.post(`${environment.apiURL}/grupoProduto`, grupoProduto);
  }

  putGrupoProduto(id: number, grupoProduto: GrupoProduto): Observable<any>{
    return this.http.put(`${environment.apiURL}/grupoProduto/${id}`, grupoProduto);
  }

  deleteGrupoProduto(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/grupoProduto/${id}`);
  }
}
