export class EscalaTrabalho {
    Id: number;
    Descricao:string = "";
    Turno: EnumTurno;
    Jornada:string = "";
}

export enum EnumTurno
{
    Diurno = 1,
    Noturno = 2
}
