import { ChecklistManutencaoFrotaItemManutencaoFrota } from "./ChecklistManutencaoFrotaItemManutencaoFrota";
import { EnumStatusManutencaoFrota } from "./ManutencaoFrota";

export class ItemManutencaoFrota{
  Id: number;
  ManutencaoFrotaId: number;
  ProdutoId: number;
  Quantidade: number = 0;
  QuantidadeFinalizada: number = 0;
  QuantidadePendente: number = 0;
  QuantidadeBaixar: number = 0;
  ValorUnitario: number = 0;
  ValorTotal: number = 0;
  IsServico: boolean;
  LocalEstoqueId: number;
  Checklist: ChecklistManutencaoFrotaItemManutencaoFrota[];
  Status: EnumStatusManutencaoFrota;
  StatusUltimaManutencaoId: number;
  StatusString:string = "";
  UnidadeMedida:string = "";
  ProdutoDescricao:string = "";
  LocalEstoqueDescricao:string = "";
  UltimaTroca:string = "";
  Utilizacao:string = "";
  VidaUtil: number = 0;

}
