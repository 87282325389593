export enum EnumEfeitoSobreEstoque
{
    Nenhum = 0,
    Aumenta,
    Diminui
}

export const ListaEfeitoSobreEstoque = [
  { descricao: "Nenhum", valor: EnumEfeitoSobreEstoque.Nenhum },
  { descricao: "Aumenta", valor: EnumEfeitoSobreEstoque.Aumenta },
  { descricao: "Diminui", valor: EnumEfeitoSobreEstoque.Diminui }
];