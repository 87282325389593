import { Component, OnInit } from '@angular/core';
import { EscalaTrabalho } from 'src/app/classes/EscalaTrabalho';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { EscalaTrabalhoService } from 'src/app/services/escala-trabalho.service';
import Swal from 'sweetalert2';
import { Empresa } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-escala-trabalho',
  templateUrl: './escala-trabalho.component.html',
  styleUrls: ['./escala-trabalho.component.scss']
})
export class EscalaTrabalhoComponent implements OnInit {

  escalasTrabalho: EscalaTrabalho[];
  auxEscalasTrabalho: EscalaTrabalho[];
  escalaTrabalho: EscalaTrabalho;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  // tradução
  FEITO: string = 'Feito!';
  ESCALA_DE_TRABALHO_ALTERADA: string = 'Escala de Trabalho alterada!';
  ESCALA_DE_TRABALHO_CADASTRADA: string = 'Escala de Trabalho cadastrada!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  ESCALA_DE_TRABALHO_DELETADA: string = 'Escala de Trabalho deletada!';

  configurarTraducao() {
    this.FEITO = this.translate.instant('FEITO');
    this.ESCALA_DE_TRABALHO_ALTERADA = this.translate.instant('ESCALA_DE_TRABALHO_ALTERADA');
    this.ESCALA_DE_TRABALHO_CADASTRADA = this.translate.instant('ESCALA_DE_TRABALHO_CADASTRADA');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.ESCALA_DE_TRABALHO_DELETADA = this.translate.instant('ESCALA_DE_TRABALHO_DELETADA');
  }

  constructor(private escalasTrabalhoService: EscalaTrabalhoService, private empresaService: EmpresaService,
              private route: ActivatedRoute, private router: Router, private storageService: StorageService,
              private utils: UtilsService, private translate: TranslateService,
              private toasty: CustomToastyService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.escalaTrabalho = new EscalaTrabalho();

    if(this.id > 0)
      this.selecionarEscalaTrabalho();
    else
      this.listarescalasTrabalho();
  }

  selecionarEscalaTrabalho() {
    this.carregando = true;
    this.escalasTrabalhoService.getEscalaTrabalho(this.id).subscribe(res =>{
      this.escalaTrabalho = new EscalaTrabalho();
      this.escalaTrabalho = res;
      console.log(this.escalaTrabalho);
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  listarescalasTrabalho(){
    this.carregando = true;
    this.escalasTrabalhoService.getEscalaTrabalhos().subscribe(
      res => {
        this.escalasTrabalho = res;
        this.auxEscalasTrabalho = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarEscalasTrabalho();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    if(this.escalaTrabalho.Jornada.length === 5)
    this.escalaTrabalho.Jornada = `${this.escalaTrabalho.Jornada}:00`;
    this.configurarTraducao();

    this.carregando = true;

    if(this.escalaTrabalho.Id > 0)
      this.escalasTrabalhoService.putEscalaTrabalho(this.escalaTrabalho.Id, this.escalaTrabalho).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ESCALA_DE_TRABALHO_ALTERADA, 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.escalasTrabalhoService.postEscalaTrabalho(this.escalaTrabalho).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ESCALA_DE_TRABALHO_CADASTRADA, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(escalaTrabalhoId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title:  this.DELETAR + ' '+ nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText:  this.CANCELAR,
      confirmButtonText:  this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.escalasTrabalhoService.deleteEscalaTrabalho(escalaTrabalhoId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.ESCALA_DE_TRABALHO_DELETADA, 'success');
              this.listarescalasTrabalho();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarEscalasTrabalho(){
    this.escalasTrabalho = this.auxEscalasTrabalho.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/escala-trabalho']);
  }
}
