import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnoSafra } from '../classes/AnoSafra';

@Injectable({
  providedIn: 'root'
})
export class AnoSafraService {

  public anoSafraSelecionado: AnoSafra;

  constructor(private http: HttpClient) { }

  getAnosSafras(): Observable<any>{
    return this.http.get(`${environment.apiURL}/anoSafra`)
  }

  getAnosSafrasPorAnoAgricola(anoAgricolaId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/anoSafra/anoAgricola/${anoAgricolaId}`)
  }

  getAnoSafra(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/anoSafra/${id}`)
  }

  postAnoSafra(anoSafra: AnoSafra): Observable<any>{
    return this.http.post(`${environment.apiURL}/anoSafra`, anoSafra);
  }

  putAnoSafra(id: number, anoSafra: AnoSafra): Observable<any>{
    return this.http.put(`${environment.apiURL}/anoSafra/${id}`, anoSafra);
  }

  deleteAnoSafra(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/anoSafra/${id}`);
  }
}
