import { Component, Input, OnInit } from "@angular/core";
import { Movimento } from "src/app/classes/Movimento";
import { Transacao } from "src/app/classes/Transacao";
import { DestinoTransacaoService } from "src/app/services/destino-transacao.service";
import { MovimentoService } from "src/app/services/movimento.service";
import { UtilsService } from "src/app/utils/utils.service";

@Component({
  selector: "app-movimento-transacoes",
  templateUrl: "./movimento-transacoes.component.html",
  styleUrls: ["./movimento-transacoes.component.scss"],
})
export class MovimentoTransacoesComponent implements OnInit {
  @Input() movimento: Movimento;

  transacoes: Transacao[] = [];
  transacaoId: number = 0;

  constructor(
    private destinoService: DestinoTransacaoService,
    private movimentoService: MovimentoService,
    private utils: UtilsService,
  ) {}

  ngOnInit() {
    console.log("chamando transacoes", this.movimento);
    this.destinoService.getDestinosPorIdOrigem(this.movimento.TransacaoId).subscribe({
        next: (res) => {
          this.transacoes = res;
        },
      });
  }

  enviar() {
    this.movimentoService.receber(this.transacaoId, this.movimento).subscribe({
      next: (res) => {
        this.utils.handleSuccess("Movimento alterado!");
      },
      error: (err) => {
        this.utils.getErro(err);
      },
    });
  }
}
