import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { Fazenda } from 'src/app/classes/Fazenda';
import { LocalEstoque, TiposEstoque, TiposArmazem, enumTipoEstoque } from 'src/app/classes/LocalEstoque';
import { FazendaService } from 'src/app/services/fazenda.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-local-estoque-forms',
  templateUrl: './local-estoque-forms.component.html',
  styleUrls: ['./local-estoque-forms.component.scss']
})
export class LocalEstoqueFormsComponent implements OnInit {

  @Input() IsNovo: boolean = false;
  @Output() cancelarEvent = new EventEmitter<any>();

  locaisEstoques: LocalEstoque[];
  auxLocalEstoques: LocalEstoque[];
  localEstoque: LocalEstoque;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";

  perfilLogado: number = 0;

  fazendas: Fazenda[];
  integraERP: boolean = false;
  integraTotvs: boolean = false;
  tiposEstoque = TiposEstoque;
  tiposArmazem = TiposArmazem;
  tipoArmazem = enumTipoEstoque.Armazem;

  // translate
  LOCAL_DE_ESTOQUE_ALTERADO: string = 'Local de Estoque alterado!';
  LOCAL_DE_ESTOQUE_CADASTRADO: string = 'Local de Estoque cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  SIM: string = 'Sim';
  CANCELAR: string = 'Cancelar';
  FEITO: string = 'Feito!';
  LOCAL_DE_ESTOQUE_DELETADO: string = 'Local de Estoque deletado!';

  constructor(private localEstoquesService: LocalEstoqueService, 
              private route: ActivatedRoute,
              private router: Router, 
              private storageService: StorageService,
              private utils: UtilsService,
              private translate: TranslateService,
              private toasty: CustomToastyService,) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;

    this.localEstoque = new LocalEstoque();
    this.integraERP = this.storageService.getEmpresa().ErpIntegrado > enumErpIntegrado.Nenhum;
    this.integraTotvs = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Totvs_RM;

    if(this.id > 0)
      this.selecionarLocalEstoque();

     
  }

  configureTraducao() {
    this.LOCAL_DE_ESTOQUE_ALTERADO = this.translate.instant('LOCAL_DE_ESTOQUE_ALTERADO');
    this.LOCAL_DE_ESTOQUE_CADASTRADO = this.translate.instant('LOCAL_DE_ESTOQUE_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.SIM = this.translate.instant('SIM');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.FEITO = this.translate.instant('FEITO');
    this.LOCAL_DE_ESTOQUE_DELETADO = this.translate.instant('LOCAL_DE_ESTOQUE_DELETADO');
  }

  selecionarLocalEstoque() {
    this.carregando = true;
    this.localEstoquesService.getLocalEstoque(this.id).subscribe(res =>{
      this.localEstoque = new LocalEstoque();
      this.localEstoque = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  save(form: any) {
    this.configureTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    
    this.localEstoque.TipoEstoque = Number(this.localEstoque.TipoEstoque);
    this.localEstoque.TipoArmazem = Number(this.localEstoque.TipoArmazem);
    
    if(this.localEstoque.Id > 0)
      this.localEstoquesService.putLocalEstoque(this.localEstoque.Id, this.localEstoque).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.LOCAL_DE_ESTOQUE_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.localEstoquesService.postLocalEstoque(this.localEstoque).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.LOCAL_DE_ESTOQUE_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  cancelar(){
    this.cancelarEvent.emit();
  }

}
