import { Component, Input, OnInit } from '@angular/core';
import { TiposDiagnoseComentarios } from 'src/app/classes/tipos-diagnose/TiposDiagnoseComentarios';

@Component({
  selector: 'app-diagnose-comentario',
  templateUrl: './diagnose-comentario.component.html',
  styleUrls: ['./diagnose-comentario.component.scss']
})
export class DiagnoseComentarioComponent implements OnInit {


  @Input() diagnoseComentario: TiposDiagnoseComentarios

  constructor() { }

  ngOnInit() {
  }

}
