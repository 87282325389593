import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Agencia } from 'src/app/classes/Agencia';
import { FiltroTela } from 'src/app/classes/FiltroTela';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { AgenciaService } from 'src/app/services/agencia.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agencia',
  templateUrl: './agencia.component.html',
  styleUrls: ['./agencia.component.scss']
})
export class AgenciaComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private router: Router,
    private toasty: CustomToastyService,
    private agenciaService : AgenciaService,
    ) { }

  @ViewChild('Grid', { static: false }) grid: any;

  colDefs: ColDef[] = [];
  listaAgencia: Agencia[] = [];
  objSelecionado: Agencia = new Agencia();
  objSelecionadoId: number = null;
  objetosSelecionados: Agencia[] = [];

  ngOnInit() {
    this.configurar();
    this.listarAgencia();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'Descricao', headerName: 'Descrição', flex: 1},
      {field: 'BancoDescricao', headerName: 'Banco', flex: 1}
    ];

  }

  listarAgencia() {
    this.agenciaService.Listar()
      .subscribe(res => {
        this.listaAgencia = res;
        this.grid.setRowData(this.listaAgencia);
      }, err => this.utils.getErro(err));
  }

  novo() {
    this.router.navigate(['/cadastros/agencia/inserir']);
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/agencia/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning("Selecione uma agência para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Selecione apenas uma agência para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar '+ this.objetosSelecionados[0].Descricao + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.agenciaService.Excluir(this.objSelecionadoId).subscribe(
            res => {
              this.toasty.show('Feito!', 'Agência excluída!', 'success');
              this.listarAgencia();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

}
