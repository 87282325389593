export class ApontamentoFrota {
  public Id: number;
  public EquipamentoId: number;
  public DataHora: Date = new Date();
  public ResponsavelId: number;
  public ValorMedidor: number = 0;

  public ResponsavelString:string = "";
  public EquipamentoString:string = "";
}
