import { UsuarioService } from 'src/app/services/usuario.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Usuario } from 'src/app/classes/Usuario';
import { EmpresaService } from 'src/app/services/empresa.service';
import { Empresa } from 'src/app/classes/Empresa';
import { FazendaService } from 'src/app/services/fazenda.service';
import Swal from 'sweetalert2';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() inserindoEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  usuario: Usuario = new Usuario();
  empresa: Empresa = new Empresa();
  erros: string;
  carregando: boolean = false;
  senhaForm: SenhaForm = {
    email: "",
    token: "",
    senha: "",
    rsenha: ""
  }

  trocaSenha = false;


  // tradução
DIGITE_O_EMAIL_OU_NOME_DE_USUARIO: string = 'Digite o e-mail ou nome de usuário';
INICIAR_RECUPERACAO: string = 'Iniciar Recuperação';
CANCELAR: string = 'Cancelar';
ENVIADO: string = 'Enviado';
FOI_ENVIADO_UM_EMAIL_PARA: string = 'Foi enviado um e-mail para';
COM_AS_INFORMACOES_PARA_RECUPERACAO_DA_SENHA: string = 'com as informações para recuperação da senha';
INCORRETO: string = 'incorreto';
SENHAS_DIGITADAS_NAO_CONFEREM: string = 'Senhas digitadas não conferem';
SUCESSO: string = 'Sucesso';
SENHA_TROCADA_AGORA_UTILIZE_ESSA_NOVA_SENHA_PARA_LOGAR: string = 'Senha trocada, agora utilize essa nova senha para logar';

  constructor(private loginService: LoginService,
    private storageService: StorageService,
    private empresaService: EmpresaService,
    private UsuarioService: UsuarioService,
    private router: Router,
    private route: ActivatedRoute,
    private fazendaService: FazendaService,
    private translate: TranslateService,
    private utils: UtilsService) {
  }

  ngOnInit() {

  }

  login() {
    if(this.usuario.Login == null || this.usuario.Login == "" || this.usuario.Senha == null || this.usuario.Senha == "")
      {
        this.erros = "Preencha os campos de login e senha";
        return;
      }
    this.carregando = true;
    this.loginService.logar(this.usuario.Login, this.usuario.Senha).subscribe(
      data => {
        this.storageService.setToken(data.access_token);
        this.loginService.getUsuarioLogado().subscribe(
          res => {
            this.usuario = res;
            this.storageService.setUsuario(this.usuario);
            if (this.usuario.EmpresaId) {
              this.empresaService.getEmpresa(this.usuario.EmpresaId).subscribe(
                ret => {
                  this.empresa = new Empresa();
                  this.empresa = ret;
                  if (!this.empresa.Ativa) {
                    this.erros = "EMPRESA INATIVA";
                    this.loginService.logout();
                  }
                  this.storageService.setEmpresa(this.empresa);

                  if (this.usuario.FazendaId) {
                    this.fazendaService.getFazenda(this.usuario.FazendaId).subscribe(faz => {
                      this.storageService.setFazenda(faz);
                        this.router.navigate(["/"]);
                    })
                  }
                  else {
                    this.carregando = false;
                    this.router.navigate(["/"]);
                  }
                },
                err => this.utils.getErro(err)
              );
            } else {
              this.carregando = false;
              this.storageService.setEmpresa({
                Id: 0,
                RazaoSocial: "Super",
                NomeFantasia: "Super",
                Ativa: true,
                UsarSimilar: false,
                AtividadePadraoId: 0,
                UsaGestaoLite: false,
                UsaTarefas: false,
                PermiteVariasAtividadesPorEtapa: false,
                UsarIntervaloFixoPlanoManutencao: false,
                AgrupaPlanoComMesmoIntervalo: false,
                Moeda: "real"
              });
              this.router.navigate(["/"]);
            }
          },
          ops => {
            this.carregando = false;
            this.erros = ops.error;
            if (ops.error.message)
              this.erros = ops.error.message;
          }
        );

      },
      erro => {
        this.carregando = false;
        this.utils.getErro(erro);
        this.erros = erro.error;
        if (erro.error.message)
          this.erros = erro.error.message;
        if (erro.error.error_description)
          this.erros = erro.error.error_description;
      });
  }

  onKeyDown(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
  RecuperarSenha() {
    this.configurarTraducao();
    this.Modal.show();
  }
  fecharModal() {
    this.Modal.hide();
    this.senhaForm = {
      email: "",
      token: "",
      senha: "",
      rsenha: ""
    }
    this.trocaSenha = false;
  }

  trocarSenha() {
    this.configurarTraducao();
    if(this.trocaSenha) {
      if(this.senhaForm.senha != this.senhaForm.rsenha)
      {
        Swal.fire(this.INCORRETO, this.SENHAS_DIGITADAS_NAO_CONFEREM, 'error');
      }
      else
      {
        this.UsuarioService.trocarSenha(this.senhaForm).subscribe(ret=>{
        Swal.fire(this.SUCESSO, this.SENHA_TROCADA_AGORA_UTILIZE_ESSA_NOVA_SENHA_PARA_LOGAR, 'success');
        this.fecharModal();
        },
        err => this.utils.getErro(err)
        );

      }
    }
    else
    {
      this.UsuarioService.gerarTokenRecuperacao(this.senhaForm.email).subscribe(ret => {
        this.senhaForm.email = ret.email;
        this.trocaSenha = true;
        Swal.fire(this.ENVIADO, this.FOI_ENVIADO_UM_EMAIL_PARA + this.senhaForm.email.substring(0, 3) + '*******@' + this.senhaForm.email.substring(this.senhaForm.email.indexOf("@") + 1) + this.COM_AS_INFORMACOES_PARA_RECUPERACAO_DA_SENHA, 'success');
      },err => this.utils.getErro(err));
    }

  }

  configurarTraducao() {
    this.DIGITE_O_EMAIL_OU_NOME_DE_USUARIO = this.translate.instant('DIGITE_O_EMAIL_OU_NOME_DE_USUARIO');
    this.INICIAR_RECUPERACAO = this.translate.instant('INICIAR_RECUPERACAO');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.ENVIADO = this.translate.instant('ENVIADO');
    this.FOI_ENVIADO_UM_EMAIL_PARA = this.translate.instant('FOI_ENVIADO_UM_EMAIL_PARA');
    this.COM_AS_INFORMACOES_PARA_RECUPERACAO_DA_SENHA = this.translate.instant('COM_AS_INFORMACOES_PARA_RECUPERACAO_DA_SENHA');
    this.INCORRETO = this.translate.instant('INCORRETO');
    this.SENHAS_DIGITADAS_NAO_CONFEREM = this.translate.instant('SENHAS_DIGITADAS_NAO_CONFEREM');
    this.SUCESSO = this.translate.instant('SUCESSO');
    this.SENHA_TROCADA_AGORA_UTILIZE_ESSA_NOVA_SENHA_PARA_LOGAR = this.translate.instant('SENHA_TROCADA_AGORA_UTILIZE_ESSA_NOVA_SENHA_PARA_LOGAR');
}
}

export interface SenhaForm {
  email: string;
  token: string;
  senha: string;
  rsenha: string;
}
