import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EstadioFenologico } from '../classes/EstadioFenologico';

@Injectable({
  providedIn: 'root'
})
export class EstadioFenologicoService {

  constructor(private http: HttpClient) { }

  getEstadiosFenologicos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/estadioFenologico`)
  }

  getEstadioFenologico(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/estadioFenologico/${id}`)
  }

  postEstadioFenologico(estadioFenologico: EstadioFenologico): Observable<any>{
    return this.http.post(`${environment.apiURL}/estadioFenologico`, estadioFenologico);
  }

  putEstadioFenologico(id: number, estadioFenologico: EstadioFenologico): Observable<any>{
    return this.http.put(`${environment.apiURL}/estadioFenologico/${id}`, estadioFenologico);
  }

  deleteEstadioFenologico(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/estadioFenologico/${id}`);
  }
}
