import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessoPlanejamento, ReferenciasDAP } from 'src/app/classes/ProcessoPlanejamento';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { ProcessoPlanejamentoTranslate } from './processo-planejamento.translate';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-processo-planejamento',
  templateUrl: './processo-planejamento.component.html',
  styleUrls: ['./processo-planejamento.component.scss']
})
export class ProcessoPlanejamentoComponent extends ProcessoPlanejamentoTranslate implements OnInit {

  processoPlanejamento: ProcessoPlanejamento = new ProcessoPlanejamento();
  processosPlanejamento: ProcessoPlanejamento[] = [];
  auxProcessosPlanejamento: ProcessoPlanejamento[] = [];
  carregando: boolean = false;
  isSubmit: boolean;
  textoBusca: string = "";
  id: number = 0;
  inserindo: boolean;
  listaReferenciasDAP = ReferenciasDAP;

  constructor(private processoPlanejamentoService: ProcessoPlanejamentoService,
    private toasty: CustomToastyService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private translate: TranslateService) {
      super(translate)
      this.route.params.subscribe(res => {
        this.id = res.id;
      });
    }

  ngOnInit() {
    this.inserindo = this.router.url.search("inserir") > 0;
    if(this.id > 0)
      this.selecionarProcessoPlanejamento();
    else
      this.listarProcessosPlanejamento();
  }

  selecionarProcessoPlanejamento() {
    this.carregando = true;
    this.processoPlanejamentoService.getProcessoPlanejamento(this.id).subscribe((processoPlanejamento: ProcessoPlanejamento) => {
      this.processoPlanejamento = processoPlanejamento;
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }

  listarProcessosPlanejamento() {
    this.carregando = true;
    this.processoPlanejamentoService.getProcessosPlanejamento().subscribe((processosPlanejamento: ProcessoPlanejamento[]) => {
      this.processosPlanejamento = processosPlanejamento;
      this.auxProcessosPlanejamento = processosPlanejamento;
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.processoPlanejamento.Id > 0)
      this.processoPlanejamentoService.putProcessoPlanejamento(this.processoPlanejamento.Id, this.processoPlanejamento).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.PROCESSO_PLANEJAMENTO_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else {
      this.processoPlanejamento.ReferenciaDAP = Number(this.processoPlanejamento.ReferenciaDAP);
      this.processoPlanejamentoService.postProcessoPlanejamento(this.processoPlanejamento).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.PROCESSO_PLANEJAMENTO_CADASTRADO, 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/processo-planejamento']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }

  }

  excluir(ProcessoPlanejamentoId: number, nome: string) {
    this.DELETAR_PARAM = this.translate.instant('DELETAR_PARAM', { value: nome});
    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.processoPlanejamentoService.deleteProcessoPlanejamento(ProcessoPlanejamentoId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.PROCESSO_DELETADO, 'success');
              this.listarProcessosPlanejamento();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarProcessosPlanejamento(){
    this.processosPlanejamento = this.auxProcessosPlanejamento.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca)
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/processo-planejamento']);
  }

  editar(id: number){
    this.router.navigate(['/cadastros/processo-planejamento/' + id]);
  }

  getDescricaoReferenciaDAP(id: number){
    let descricao = this.listaReferenciasDAP.filter(x => x.value == id)[0].name;
    return descricao;
  }
}
