import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-linha-tempo',
  templateUrl: './linha-tempo.component.html',
  styleUrls: ['./linha-tempo.component.scss']
})
export class LinhaTempoComponent implements OnInit {

  @Input() diagnoses: any[];

  diagnosesFiltro: any[] = [];
  grupoDiagnosesPorData: any;
  filtroAberto: boolean = false;
  filtrado: boolean = false;
  autores: string[] = [];

  autorSelecionado: string = null;
  dataInicioSelecionada: Date = null;
  dataFimSelecionada: Date = null;

  constructor(
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.grupoDiagnosesPorData = this.agruparPorData(this.diagnoses);
    this.pegarAutores();
  }

  asIsOrder = (a, b) => 1;

  toggleFiltro = () => this.filtroAberto = !this.filtroAberto;
  fecharFiltro = () => this.filtroAberto = false;

  private pegarAutores() {
    this.diagnoses.forEach(d => this.autores.push(d.UsuarioNome));
    this.autores = Array.from(new Set(this.autores));
  }

  private agruparPorData(diagnoses: any[]): any {
    const sort = _.orderBy(diagnoses,
      (item) => item.DataCadastro.toLocaleString('pt-BR'), ['desc']);

    return _.groupBy(sort,
      (item) => new Date(item.DataCadastro).toLocaleDateString('pt-BR'));
  }

  private filtrarEmLista() {
    this.diagnosesFiltro = this.diagnoses.filter(
      d => {
        return (this.autorSelecionado ? d.UsuarioNome === this.autorSelecionado : true) &&
          (this.dataInicioSelecionada ? d.DataCadastro >= this.dataInicioSelecionada : true) &&
          (this.dataFimSelecionada ? d.DataCadastro <= this.dataFimSelecionada : true);
      }
    );
    this.grupoDiagnosesPorData = this.agruparPorData(this.diagnosesFiltro);
  }

  alterarAutor(event) {
    if (event == null) return;
    this.autorSelecionado = event;
  }

  alterarDataInicio(event) {
    if (event == null) return;
    this.dataInicioSelecionada = event;
  }

  alterarDataFim(event) {
    if (event == null) return;
    this.dataFimSelecionada = event;
  }

  onFiltrar() {
    if (this.isNullOrEmpty(this.autorSelecionado) && this.isNullOrEmpty(this.dataInicioSelecionada) && this.isNullOrEmpty(this.dataFimSelecionada)) {
      this.utilsService.handleWarning('Adicione um filtro para poder filtra');
      return;
    }
    this.filtrarEmLista();
    this.filtrado = true;
    this.filtroAberto = false;
  }

  limparFiltro() {
    this.autorSelecionado = null;
    this.dataInicioSelecionada = null;
    this.dataFimSelecionada = null;
    this.filtrarEmLista();
    this.filtrado = false;
    this.filtroAberto = false;
  }

  private isNullOrEmpty(element: any) {
    let isNull = element == null;
    let isEmpty = false;
    if (typeof (element) == 'string') {
      isEmpty = element.trim().length == 0;
    }
    return isNull || isEmpty;
  }
}
