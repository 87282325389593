import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { MovimentoSerie } from 'src/app/classes/MovimentoSerie';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { MovimentoSerieService } from 'src/app/services/movimento-serie.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-movimento-serie',
  templateUrl: './movimento-serie.component.html',
  styleUrls: ['./movimento-serie.component.scss']
})
export class MovimentoSerieComponent implements OnInit {


  coldefs: ColDef[] = [];
  empresasInterna: EmpresaInterna[] = [];
  movimentoSerie = new MovimentoSerie();
  selecionados: MovimentoSerie[] = [];
  movimentoSeries: MovimentoSerie[] = null;
  showForms = false;
  isSubmit = false;
  titulo = 'Lista Series'

  constructor(
    private empresaInternaService: EmpresaInternaService,
    private movimentoSerieService: MovimentoSerieService,
    private utils: UtilsService
  ) {
   }

  ngOnInit() {
    this.listarEmpresas();
     this.listarMovimentoSeries();
  }


  configurarGrid() {
    this.coldefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Empresa', field: 'EmpresaInternaId', flex: 1, valueFormatter: (params) => this.empresaInternaDesc(params.data.EmpresaInternaId) },
      { headerName: 'Serie', field: 'CodigoSerie', flex: 1 },
      { headerName: 'Descrição', field: 'Descricao', flex: 1 },
      { headerName: 'Ultimo Registro', field: 'Inicial', flex: 1 },
    ];
  }

  listarEmpresas() {
    this.empresaInternaService.getEmpresasInternas()
      .subscribe(res => {
        this.empresasInterna = res;
        this.configurarGrid();
      }, err => this.utils.getErro(err));
  }

  listarMovimentoSeries() {
    this.movimentoSerieService.getList()
      .subscribe(res => {
        this.movimentoSeries = res;
      }, err => this.utils.getErro(err));
  }

  empresaInternaDesc(id: number): string {
    if (id != null || id != undefined) {
      let empresaInterna = this.empresasInterna.find(p => p.Id == id);
      return empresaInterna != undefined ? empresaInterna.NomeFantasia : id.toString();
    }
    return id + '';
  }

  toggleGrid() {
    this.showForms = !this.showForms;
    if (this.showForms) {
      if (this.movimentoSerie.Id > 0) {
        this.tituloEditar();
      } else
        this.tituloNovo()
    } else
      this.tituloListar();
  }
  novo() {
    this.movimentoSerie = new MovimentoSerie();
    this.showForms = true;
    this.tituloNovo();
  };
  cancelar() {
    this.showForms = false;
    this.tituloListar();
  };
  salvar() {
    if(!this.showForms)
      return

    const form = new FormGroup({
        empresa: new FormControl(this.movimentoSerie.EmpresaInternaId, [Validators.required]),
        codigoSerie: new FormControl(this.movimentoSerie.CodigoSerie, [Validators.required]),
        desc: new FormControl(this.movimentoSerie.Descricao, [Validators.required]),
        inicial: new FormControl(this.movimentoSerie.Inicial, [Validators.required]),
    })

    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    if (this.movimentoSerie.Id > 0) {
      this.movimentoSerieService.put(this.movimentoSerie.Id, this.movimentoSerie)
        .subscribe(res => {
          this.finalizarSalvar(false);
        }, err => this.utils.getErro(err));
    } else {
      this.movimentoSerieService.post(this.movimentoSerie)
        .subscribe(res => {
          this.finalizarSalvar(true);
        }, err => this.utils.getErro(err));
    }
  }
  finalizarSalvar(novo: boolean){
    this.utils.handleSuccess(`Serie ${novo ? 'cadastrada' : 'atualizada'} com sucesso!`);
    this.listarMovimentoSeries();
    this.showForms = false;
    this.movimentoSerie = new MovimentoSerie();
  }
  cellDoubleClicked(event) {
    this.showForms = true;
  }
  onCellClicked(row) {
    this.movimentoSerie = row.data;
  }
  selectionChanged(event) {
    this.selecionados = event;
  }

  private tituloListar() {
    this.titulo = 'Lista Series';
  }
  private tituloEditar() {
    this.titulo = 'Editar Serie';
  }
  private tituloNovo() {
    this.titulo = 'Cadastrar Serie';
  }
}
