import { ItemEnum } from "./ItemEnum"

export class Natureza
{
    Id: number
    Codigo: string
    Descricao: string
    CodigoPai: number
    IsAnalitico: boolean
    Tipo: EnumTipoNatureza
    IncidiResultadoCaixa: boolean
    IncidiResultadoEconomico: boolean
    IncidiLCDPR: boolean

    //

    _codigo: number = 0;
}
export enum EnumTipoNatureza{
  Receita = 1,
  Despesa = 2,
  Ambos = 3
}
export const ListaEnumTipoNatureza: ItemEnum[] = [
  {name: 'Receita', value: EnumTipoNatureza.Receita},
  {name: 'Despesa', value: EnumTipoNatureza.Despesa},
  {name: 'Ambos', value: EnumTipoNatureza.Ambos},
];
