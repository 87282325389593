import { Component, OnInit } from '@angular/core';
import { Cultura } from 'src/app/classes/Cultura';
import { PlanejamentoService } from 'src/app/services/planejamento.service';

@Component({
  selector: 'app-plano-mestre-producao',
  templateUrl: './plano-mestre-producao.component.html',
  styleUrls: ['./plano-mestre-producao.component.scss']
})
export class PlanoMestreProducaoComponent implements OnInit {

  culturas: Cultura[];
  culturaSelecionada: Cultura;

  public carregando: boolean = false;

  constructor(private planejamentoService: PlanejamentoService) {
    this.carregando = true;
    this.planejamentoService.getCulturas().subscribe(ret =>{
      this.culturas = ret;
      this.carregando = false;
    });
  }

  ngOnInit() {
  }

}
