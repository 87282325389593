import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges, Input } from '@angular/core';

import "dhtmlx-gantt";
import { gantt } from 'dhtmlx-gantt';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-planejado-realizao-gantt',
  templateUrl: './planejado-realizao-gantt.component.html',
  styleUrls: ['./planejado-realizao-gantt.component.scss']
})
export class PlanejadoRealizaoGanttComponent implements OnInit, OnChanges {
  @ViewChild('GanttContainer', { static: false })
  ganttContainer: ElementRef;

  pipe = new DatePipe('pt-BR');

  @Input() tipoVisualizacao: number = 0;//1 - diario, 2 - semanal, 3 - mensal

  @Input() data: any[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.tipoVisualizacao)
      this.tipoVisualizacao = 3;

    let links = [];

    let data = [];
    if (this.data)
      data = this.data;

    if (this.tipoVisualizacao == 1) {
      gantt.config.scale_height = 36 * 2;
      gantt.config.scales = [
        {unit: "month", step: 1, format: function(date) {
          let mes = date.getMonth()+1;

          switch (mes) {
            case 1: return 'Janeiro';
            case 2: return 'Fevereiro';
            case 3: return 'Março';
            case 4: return 'Abril';
            case 5: return 'Maio';
            case 6: return 'Junho';
            case 7: return 'Julho';
            case 8: return 'Agosto';
            case 9: return 'Setembro';
            case 10: return 'Outubro';
            case 11: return 'Novembro';
            case 12: return 'Dezembro';
          }
        }},
        {unit: "day", step: 1, format: "%d"}
      ];
    } else if (this.tipoVisualizacao == 2) {
      gantt.config.scale_height = 36 * 2;
      gantt.config.scales = [
        {unit: "month", step: 1, format: function(date) {
          let mes = date.getMonth()+1;

          switch (mes) {
            case 1: return 'Janeiro';
            case 2: return 'Fevereiro';
            case 3: return 'Março';
            case 4: return 'Abril';
            case 5: return 'Maio';
            case 6: return 'Junho';
            case 7: return 'Julho';
            case 8: return 'Agosto';
            case 9: return 'Setembro';
            case 10: return 'Outubro';
            case 11: return 'Novembro';
            case 12: return 'Dezembro';
          }
        }},
        {unit: "week", step: 1, format: function (date) {
          var endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
          return date.getDate() + "-" + endDate.getDate();
        }}
      ];
    } else {
      gantt.config.scale_height = 36;
      gantt.config.scales = [
        {unit: "month", step: 1, format: function(date) {
          let mes = date.getMonth()+1;

          switch (mes) {
            case 1: return 'Janeiro';
            case 2: return 'Fevereiro';
            case 3: return 'Março';
            case 4: return 'Abril';
            case 5: return 'Maio';
            case 6: return 'Junho';
            case 7: return 'Julho';
            case 8: return 'Agosto';
            case 9: return 'Setembro';
            case 10: return 'Outubro';
            case 11: return 'Novembro';
            case 12: return 'Dezembro';
          }
        }}
      ];
    }

    gantt.clearAll();
    gantt.parse({data, links});

    gantt.plugins({
      marker: true
    });

    var dateToStr = gantt.date.date_to_str(gantt.config.task_date);
    gantt.addMarker({
      start_date: new Date(),
      css: "today",
      text: "Hoje",
      title: dateToStr(new Date())
    });

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    gantt.init(this.ganttContainer.nativeElement);
    gantt.config.readonly = true;
    gantt.config.date_grid = "%d/%m/%Y";
    gantt.config.date_format = "%d/%m/%Y %H:%i";

    gantt.templates.format_date = function(date){
      return date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear()+' '+date.getHours().toString().padStart(2,'0')+':'+date.getMinutes();
    };


    gantt.templates.task_class = function(start, end, task){
      if(task.customClass){
          return task.customClass;
      }
      return "";
    };

    var gridColumns = {
      columns: [
        {name:"text", label:"Nome", tree: true, width: 250 },
        {name:"start_date", label:"Início", align: "center", template:function(obj){
            return obj.start_date.getDate().toString().padStart(2,'0')+'/'+(obj.start_date.getMonth()+1).toString().padStart(2,'0')+'/'+(obj.start_date.getFullYear()-2000)
          }
        },
        {name:"end_date", width:'60', label:"Fim", align: "center", template:function(obj){
          let dataFim: Date = new Date(obj.start_date);
          dataFim.setDate(obj.start_date.getDate() + obj.duration - 1);
          return dataFim.getDate().toString().padStart(2,'0')+'/'+(dataFim.getMonth()+1).toString().padStart(2,'0')+'/'+(dataFim.getFullYear()-2000)
        }},
        {name:"area", width:'65', label:"Ha Prev.", align: "center", template:function(obj){
            return obj.AreaPlantada.toFixed(2).replace(',','').replace('.',',')
          }
        },
        {name:"areaReal", width:'65', label:"Ha Real", align: "center", template:function(obj){
            return obj.AreaRealizada.toFixed(2).replace(',','').replace('.',',')
          }
        },
        {name:"progress", width:'40', label:"%", align: "center", template:function(obj){
            return obj.progress*100
          }
        }
      ]
    };

    gantt.config.layout = {
      css: "gantt_container",
      rows: [
        {
          cols: [
            {view: "grid", width: 480, bind: "task", scrollY: "scrollVer", config: gridColumns},
            {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
            //{view: "grid", width: 165, bind: "task", scrollY: "scrollVer", config: secondGridColumns},
            {view: "scrollbar", id: "scrollVer"}
          ]

        },
        {view: "scrollbar", id: "scrollHor", height: 20}
      ]
    };
  }

}
