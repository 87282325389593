import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Sistema } from '../classes/Sistema';

@Injectable({
  providedIn: 'root'
})
export class SistemaService {

  constructor(private http: HttpClient) { }

  route = "sistema";

  Listar(): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}`)
  }

  ObterPorId(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/${this.route}/${id}`)
  }

  Inserir(obj: Sistema): Observable<any>{
    return this.http.post(`${environment.apiURL}/${this.route}`, obj);
  }

  Atualizar(id: number, obj: Sistema): Observable<any>{
    return this.http.put(`${environment.apiURL}/${this.route}/${id}`, obj);
  }

  Excluir(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/${this.route}/${id}`);
  }
  
  ExcluirItem(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/${this.route}/DeletarItem/${id}`);
  }

}
