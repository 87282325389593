import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoTransacao, ListaEnumModulo, ListaEnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { GrupoTransacaoService } from 'src/app/services/grupo-transacao.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grupo-transacao',
  templateUrl: './grupo-transacao.component.html',
  styleUrls: ['./grupo-transacao.component.scss']
})
export class GrupoTransacaoComponent implements OnInit {

  constructor(
    private grupoTransacaoService: GrupoTransacaoService,
    private utils: UtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  isSubmit: boolean;
  id:number;
  grupoTransacoes: GrupoTransacao[] = [];
  selecionados: GrupoTransacao[] = [];
  grupoTransacao: GrupoTransacao;
  auxGrupoTransacoes: GrupoTransacao[] = [];
  carregando: boolean = false;
  listaModulos = ListaEnumModulo;
  listaTipoMovimentos = ListaEnumTipoMovimento;
  coldefs: ColDef[] = [];
  titulo = 'Lista';
  showForms = false;
  ngOnInit() {
    this.grupoTransacao = new GrupoTransacao();
    this.configurar();
    this.listarGrupoTransacao();
  }
  configurar(){
    this.coldefs = [
      {headerName: 'Descrição', flex: 1, field: 'Descricao'},
      {headerName: 'Módulo', flex: 1, field: 'Modulo', cellRenderer: (params) => this.moduloDesc(params.data.Modulo)},
      {headerName: 'Tipo', flex: 1, cellRenderer: (params) => `<span class="badge badge-light-secondary custom-badge-grid">${params.data.GetTipoMovimento}</span>`},

    ]
  }
  listarGrupoTransacao(){
    this.carregando = true;
    this.grupoTransacaoService.getList()
    .subscribe(
      res => {
        this.grupoTransacoes = res;
        this.auxGrupoTransacoes = res;
        this.carregando = false;
        },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    );
  }
  moduloDesc(modulo){
    switch(modulo){
      case 1:
        return  '<span class="badge badge-light-secondary custom-badge-grid">Compras</span>'
      case 2:
        return '<span class="badge badge-light-secondary custom-badge-grid">Vendas</span>'
      case 3:
        return '<span class="badge badge-light-secondary custom-badge-grid">Financeiro</span>'
      case 4:
        return '<span class="badge badge-light-secondary custom-badge-grid">Estoque</span>'

    }
  }

  buscar(event){
    this.grupoTransacoes = this.auxGrupoTransacoes.filter(x =>
      x.Descricao.toUpperCase().includes(event.toUpperCase())
      );
  }
  excluir(id: number, nome: string) {
    Swal.fire({
      title: 'Deletar Grupos de transação selecionados?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.selecionados.forEach(item => {
            this.carregando = true;
            this.grupoTransacaoService.delete(item.Id).subscribe(
              res => {
                this.utils.handleSuccess(`Grupo Transação "${item.Descricao}" deletado!`)
                this.listarGrupoTransacao();
                this.carregando = false;
              },
              err => {
                this.utils.getErro(err);
                this.carregando = false;
              });
          })
        }
      });
  }
  salvar(){
    var form = new FormGroup({
        Descricao: new FormControl(this.grupoTransacao.Descricao, Validators.required),
        Modulo: new FormControl(this.grupoTransacao.Modulo, Validators.required),
        TipoMovimento: new FormControl(this.grupoTransacao.TipoMovimento, Validators.required),
      })
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.grupoTransacao.Id > 0)
      this.grupoTransacaoService.put(this.grupoTransacao.Id, this.grupoTransacao).subscribe(
        res => {
          this.utils.handleSuccess("Grupo Transação alterada!");
          this.carregando = false;
          this.finalizar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.grupoTransacaoService.post(this.grupoTransacao).subscribe(
        res => {
          this.utils.handleSuccess("rupo Transação cadastrada!");
          this.carregando = false;
          this.finalizar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }
  finalizar(){
    this.listarGrupoTransacao();
    this.tituloListar()
    this.showForms = false;
  }
  cancelar(){
    this.showForms = false;
    this.grupoTransacao = new GrupoTransacao();
  }

  novo(){
    this.grupoTransacao = new GrupoTransacao();
    this.tituloNovo();
    this.showForms = true;
  }
  toggleGrid() {
    this.showForms = !this.showForms;
    if (this.showForms) {
      if (this.grupoTransacao.Id > 0) {
        this.tituloEditar();
      } else
        this.tituloNovo()
    } else
      this.tituloListar();
  }

  cellDoubleClicked(event) {
    this.showForms = true;
  }
  onCellClicked(row) {
    this.grupoTransacao = row.data;
  }
  selectionChanged(event) {
    this.selecionados = event;
  }

  private tituloListar() {
    this.titulo = 'Lista';
  }
  private tituloEditar() {
    this.titulo = 'Editar';
  }
  private tituloNovo() {
    this.titulo = 'Cadastrar';
  }

}
