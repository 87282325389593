import { Component, OnInit } from '@angular/core';
import { UnidadeConsumidora } from 'src/app/classes/UnidadeConsumidora';
import { UnidadeConsumidoraService } from 'src/app/services/unidadeConsumidora.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/services/storage.service';
import { FazendaService } from 'src/app/services/fazenda.service';
import { Fazenda } from 'src/app/classes/Fazenda';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unidadeconsumidora',
  templateUrl: './unidadeconsumidora.component.html',
  styleUrls: ['./unidadeconsumidora.component.scss']
})
export class UnidadeconsumidoraComponent implements OnInit {

  unidadesConsumidoras: UnidadeConsumidora[];
  auxUnidadesConsumidoras: UnidadeConsumidora[];
  unidadeConsumidora: UnidadeConsumidora;
  fazendas: Fazenda[];
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  dataAniversario: NgbDateStruct;


  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  // tradução
  PONTA_INICIAL: string = 'Ponta Inicial';
  UNIDADE_CONSUMIDORA_ALTERADA: string = 'Unidade Consumidora alterada!';
  UNIDADE_CONSUMIDORA_CADASTRADA: string = 'Unidade Consumidora cadastrada!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  UNIDADE_CONSUMIDORA_DELETADA: string = 'Unidade Consumidora deletada!';


  configurarTraducao() {
    this.PONTA_INICIAL = this.translate.instant('PONTA_INICIAL');
    this.UNIDADE_CONSUMIDORA_ALTERADA = this.translate.instant('UNIDADE_CONSUMIDORA_ALTERADA');
    this.UNIDADE_CONSUMIDORA_CADASTRADA = this.translate.instant('UNIDADE_CONSUMIDORA_CADASTRADA');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.UNIDADE_CONSUMIDORA_DELETADA = this.translate.instant('UNIDADE_CONSUMIDORA_DELETADA');
  }

  constructor(private unidadeConsumidoraService: UnidadeConsumidoraService,
    private fazendaService: FazendaService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private utils: UtilsService,
    private translate: TranslateService,
    private toasty: CustomToastyService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.unidadeConsumidora = new UnidadeConsumidora();
    this.fazendaService.getFazendas().subscribe(ret => {
      this.fazendas = ret;
    },
      err => {
        this.utils.getErro(err);
      });


    if (this.id > 0)
      this.selecionarUnidadeConsumidora();
    else
      this.listarUnidadesConsumidoras();
  }

  selecionarUnidadeConsumidora() {
    this.carregando = true;
    this.unidadeConsumidoraService.getUnidadeConsumidora(this.id).subscribe(res => {
      this.unidadeConsumidora = new UnidadeConsumidora();
      this.unidadeConsumidora = res;
      this.dataAniversario = {
        year: Number(this.unidadeConsumidora.AniversarioConta.toString().substr(0, 4)),
        month: Number(this.unidadeConsumidora.AniversarioConta.toString().substr(5, 2)) - 1,
        day: Number(this.unidadeConsumidora.AniversarioConta.toString().substr(8, 2))
      };

      var datePipe = new DatePipe('pt-br');
      //this.unidadeConsumidora.HorarioPontaInicial = datePipe.transform(this.unidadeConsumidora.HorarioPontaInicial, 'MM/DD/YYYY');



      this.carregando = false;
    },
      ex => {
        this.utils.getErro(ex);
        this.carregando = false;
      })
  }

  listarUnidadesConsumidoras() {
    this.carregando = true;
    this.unidadeConsumidoraService.getUnidadesConsumidoras().subscribe(
      res => {
        this.unidadesConsumidoras = res;
        this.auxUnidadesConsumidoras = res;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.unidadeConsumidora.AniversarioConta = new Date(this.dataAniversario.year, this.dataAniversario.month, this.dataAniversario.day);
    if (this.unidadeConsumidora.Id > 0)
      this.unidadeConsumidoraService.putUnidadeConsumidora(this.unidadeConsumidora.Id, this.unidadeConsumidora).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('UNIDADE_CONSUMIDORA_ALTERADA'));
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.unidadeConsumidoraService.postUnidadeConsumidora(this.unidadeConsumidora).subscribe(
        res => {
          this.utils.handleSuccess(this.translate.instant('UNIDADE_CONSUMIDORA_CADASTRADA'));
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  excluir(unidadeConsumidoraId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR + ' ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.unidadeConsumidoraService.deleteUnidadeConsumidora(unidadeConsumidoraId).subscribe(
          res => {
            this.utils.handleSuccess(this.UNIDADE_CONSUMIDORA_DELETADA);
            this.listarUnidadesConsumidoras();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  filtrarUnidadesConsumidoras() {
    this.unidadesConsumidoras = this.auxUnidadesConsumidoras.filter(x =>
      x.DescricaoInstalacao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.IdInstalacao.toUpperCase().includes(this.textoBusca.toUpperCase())
    );
  }

  cancelar() {
    this.router.navigate(['/cadastros/unidade-consumidora']);
  }

  getNomeFazenda(idFazenda: number): string {
    return this.fazendas.find(x => x.Id == idFazenda).Nome;
  }

}
