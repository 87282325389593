import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImprodutividadeEquipamento } from '../classes/ImprodutividadeEquipamento';

@Injectable({
  providedIn: 'root'
})
export class ImprodutividadeEquipamentoService {

  constructor(private http: HttpClient) { }

  getImprodutividadesEquipamento(equipamentoApontamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/improdutividadeEquipamento/listar/${equipamentoApontamentoId}`)
  }

  getImprodutividadeEquipamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/improdutividadeEquipamento/${id}`)
  }

  postImprodutividadeEquipamento(improdutividadesEquipamento: ImprodutividadeEquipamento[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/improdutividadeEquipamento`, improdutividadesEquipamento);
  }

  putImprodutividadeEquipamento(id: number, improdutividadeEquipamento: ImprodutividadeEquipamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/improdutividadeEquipamento/${id}`, improdutividadeEquipamento);
  }

  deleteImprodutividadeEquipamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/improdutividadeEquipamento/${id}`);
  }
}
