export class MovimentoSerie {
  Id: number;
  Empresa: number;
  DataCadastro: Date = new Date();
  DataAlteracao: Date = new Date();
  EmpresaInternaId: number;
  CodigoSerie:string = "";
  Descricao:string = "";
  Inicial: number = 0;
}
