import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import { RelatoriosDiagnosesComponent } from "./relatorios-diagnoses/relatorios-diagnoses.component";
import { LinhaTempoComponent } from "./linha-tempo/linha-tempo.component";
import { MenuFiltroComponent } from "./menu-filtro/menu-filtro.component";
import { CommonModule } from "@angular/common";
import { DiagnoseAudioComponent } from './tipos/diagnose-audio/diagnose-audio.component';
import { DiagnoseComentarioComponent } from './tipos/diagnose-comentario/diagnose-comentario.component';
import { DiagnoseFotosComponent } from './tipos/diagnose-fotos/diagnose-fotos.component';
import { DiagnosePragasComponent } from './tipos/diagnose-pragas/diagnose-pragas.component';
import { AsideMenuComponent } from './aside-menu/aside-menu.component';
import { GrupoMenuItemComponent } from './aside-menu/grupo-menu-item/grupo-menu-item.component';
import { DiagnoseCardInfoComponent } from './shared/diagnose-card-info/diagnose-card-info.component';
import { DiagnoseIndicadorComponent } from './tipos/diagnose-indicador/diagnose-indicador.component';

@NgModule({
    declarations: [
        RelatoriosDiagnosesComponent,
        MenuFiltroComponent,
        LinhaTempoComponent,
        DiagnoseAudioComponent,
        DiagnoseComentarioComponent,
        DiagnoseFotosComponent,
        DiagnosePragasComponent,
        DiagnoseIndicadorComponent,
        AsideMenuComponent,
        GrupoMenuItemComponent,
        DiagnoseCardInfoComponent,
    ],
    exports: [],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
    ]
})
export class DiagnoseModule { }
