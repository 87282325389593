import { Component, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AreaUltimaModificacao } from 'src/app/classes/tipos-diagnose/AreaUltimaModificacao';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-grupo-menu-item',
  templateUrl: './grupo-menu-item.component.html',
  styleUrls: ['./grupo-menu-item.component.scss']
})
export class GrupoMenuItemComponent implements OnInit {

  @Input() area: AreaUltimaModificacao;
  @Output() areaSelecionada = new EventEmitter<number>();

  collapsed = false;
  tempoDecorrido = '';

  constructor(
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.calcularTempoDecorrido();
  }

  selecionarArea(area: AreaUltimaModificacao) {
    this.areaSelecionada.emit(area.AreaId);
  }

  calcularTempoDecorrido() {
    this.tempoDecorrido = this.area.UltimaAtualizacao == null ? 'Sem Diagnoses para essa area' : this.utilsService.calcularTempoDecorrido(this.area.UltimaAtualizacao);
  }
}
