import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-struct';
import { TranslateService } from '@ngx-translate/core';
import { ApontamentoFrota } from 'src/app/classes/ApontamentoFrota';
import { Usuario } from 'src/app/classes/Usuario';
import { ApontamentoFrotaService } from 'src/app/services/apontamento-frota.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-apontamento-frota',
  templateUrl: './apontamento-frota.component.html',
  styleUrls: ['./apontamento-frota.component.scss']
})
export class ApontamentoFrotaComponent implements OnInit {

  @Input() equipamentoId: number = 0;

  apontamentoFrota: ApontamentoFrota;
  listaApontamentoFrota: ApontamentoFrota[] = [];
  auxApontamentoFrotas: ApontamentoFrota[] = [];

  listaUsuario: Usuario[] = [];

  id: number = 0;

  hora: NgbTimeStruct = {hour: 0, minute: 0, second: 0};

  inserindo: boolean = false;
  editando: boolean = false;
  textoBusca: string = '';
  
  carregando: boolean = false;
  isSubmit: boolean;

  // tradução
  APONTAMENTO_ALTERADO: string = 'Apontamento alterado!';
  APONTAMENTO_CADASTRADO: string = 'Apontamento cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  APONTAMENTO_EXCLUIDO: string = 'Apontamento excluído!';

  configurarTraducao() {
      this.APONTAMENTO_ALTERADO = this.translate.instant('APONTAMENTO_ALTERADO');
      this.APONTAMENTO_CADASTRADO = this.translate.instant('APONTAMENTO_CADASTRADO');
      this.DELETAR = this.translate.instant('DELETAR');
      this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
      this.CANCELAR = this.translate.instant('CANCELAR');
      this.SIM = this.translate.instant('SIM');
      this.APONTAMENTO_EXCLUIDO = this.translate.instant('APONTAMENTO_EXCLUIDO');
  }

  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
    private router: Router,
    private apontamentoFrotaService: ApontamentoFrotaService,
    private usuarioService: UsuarioService,
    private toasty: CustomToastyService) {
    }

  ngOnInit() { 
    
    this.apontamentoFrota = new ApontamentoFrota();

      if(this.equipamentoId > 0)
        this.listar();
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    const data = new Date(this.apontamentoFrota.DataHora);
    data.setHours(this.hora.hour -3);
    data.setMinutes(this.hora.minute);
    this.apontamentoFrota.DataHora = data;

    this.apontamentoFrota.EquipamentoId = this.equipamentoId;
    this.carregando = true;

    if (this.apontamentoFrota.Id > 0) {
      this.configurarTraducao();
      this.apontamentoFrotaService.Atualizar(this.apontamentoFrota.Id, this.apontamentoFrota).subscribe(
        res => {
          this.utils.handleSuccess(this.APONTAMENTO_ALTERADO);
          this.apontamentoFrota = new ApontamentoFrota();
          this.listar();
          this.hora = {hour: 0, minute: 0, second: 0};
          this.carregando = false;
          this.isSubmit = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else
      this.apontamentoFrotaService.Inserir(this.apontamentoFrota).subscribe(
        res => {
          this.utils.handleSuccess(this.APONTAMENTO_CADASTRADO);
          this.listaApontamentoFrota.push(res);
          this.apontamentoFrota = new ApontamentoFrota();
          this.listar();
          this.hora = {hour: 0, minute: 0, second: 0};
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  selecionar() {
    this.carregando = true;
    this.apontamentoFrotaService.ObterPorId(this.id).subscribe(res =>{
      this.apontamentoFrota = res;
      const data = new Date(res.DataHora);
      this.hora = {
        hour: data.getHours(), 
        minute: data.getMinutes(),
        second: 0
      };
      this.carregando = false;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }

  listar(){
    this.carregando = true;
    this.apontamentoFrotaService.Listar(this.equipamentoId).subscribe(
      res => {
        this.listaApontamentoFrota = res;
        this.auxApontamentoFrotas = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrar();

        this.usuarioService.getUsuarios().subscribe(res => {
          this.listaUsuario = res
        })
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }



  excluir(id: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText:  this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.apontamentoFrotaService.Excluir(id).subscribe(
            res => {
              this.utils.handleSuccess(this.APONTAMENTO_EXCLUIDO);
              this.listar();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  editar(id: number) {
    this.id = id;
    this.selecionar();
  }

  filtrar(){
    this.listaApontamentoFrota = this.auxApontamentoFrotas.filter(x =>
      x.Id.toString().includes(this.textoBusca)
    );
  }

  cancelar(){
    this.router.navigate([`/ordem-servico/equipamento/${this.equipamentoId}`]);
  }
}
