import { PermissaoUsuario } from "./PermissaoUsuario";
import { PermissaoUsuarioTransacao } from "./PermissaoUsuarioTransacao";

export class Usuario {
    Id: number;
    EmpresaId: number;
    Nome:string = "";
    Email:string = "";
    Telefone:string = "";
    Whatsapp:string = "";
    Login:string = "";
    Senha:string = "";
    SenhaDecript:string = "";
    Perfil: number = 0;
    PerfilInt:number = 0;
    isSuper: boolean;
    IsFornecedor: boolean;
    Ativo: boolean;
    FazendaId: number;
    SincronizaEstoqueERP: boolean;
    IdUsuarioERP:string = "";
    LoginUsuarioERP:string = "";
    LeuRelease: boolean;
    TokenTemporario:string = "";
    ExpiracaoToken:Date;
    SenhaAntiga:string = "";
    DataNascimento:string;
    Imagem:string = "";
    PermissaoUsuario: PermissaoUsuario = new PermissaoUsuario();
    permissaoUsuarioTransacaos: PermissaoUsuarioTransacao[];
    ModuloPadrao: number = 0;
    DiaHorarioAcessoUsuario: DiaHorarioAcessoUsuario = new DiaHorarioAcessoUsuario();
    RegionalId:string = "";
    ParceiroId:string = "";
    Idioma:string = "";
}

export class RedefinirSenha{
  NovaSenha:string = "";
  UsuarioId: number;
  SenhaAtual:string = "";
  ConfirmNovaSenha:string = "";
}
export enum enumPerfil
{
    Super = 1,
    Admin = 2,
    Usuario = 3,
    Master = 4
}

export class DiaHorarioAcessoUsuario {
  Id: number;
  UsuarioId: number;

  Segunda: HorarioAcesso = new HorarioAcesso();
  Terca: HorarioAcesso = new HorarioAcesso();
  Quarta: HorarioAcesso = new HorarioAcesso();
  Quinta: HorarioAcesso = new HorarioAcesso();
  Sexta: HorarioAcesso = new HorarioAcesso();
  Sabado: HorarioAcesso = new HorarioAcesso();
  Domingo: HorarioAcesso = new HorarioAcesso();
}

export class HorarioAcesso {
  Inicio:string;
  Fim:string;
}
