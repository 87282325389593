import { Endereco } from "./Endereco";

export class EmpresaInterna {
    Id: number;
    RazaoSocial:string = "";
    NomeFantasia:string = "";
    Tipo: number = 0;
    Documento?:string = "";

    InscricaoEstadual?:string = "";
    InscricaoMunicipal?:string = "";
    MatrizId?: number;
    Cnae?:string = "";
    CpfResponsavel:string = "";
    NomeResponsavel:string = "";
    Telefone:string = "";
    Email:string = "";
    Ativo: boolean = true;
    DataCadastro: Date = new Date();
    LogoBase64?:string = "";


    TransacaoPadraoSaidasDePecas?: number = 0;

    // endereço
    Enderecos: Endereco[] = [];
    EnderecoCorrespondenciaIgualPrincipal: boolean = true;
    EnderecoCobrancaIgualPrincipal: boolean = true;

    // MBaseEmpresa
    EmpresaId: number;

    //Integração TecnoSpeed
    EmailTecnospeed:string = "";
    SenhaTecnospeed:string = "";
}


export enum enumTipoEmpresaInterna
{
    Fisica = 1,
    Juridica = 2
}
