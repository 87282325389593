import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { TipoLancamento } from '../classes/TipoLancamento';

@Injectable({
  providedIn: 'root'
})
export class TipoLancamentoService {

  constructor(private http: HttpClient) { }

  getTiposLancamento(): Observable<any>{
    return this.http.get(`${environment.apiURL}/tiposlancamento`)
  }

  getTipoLancamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/tiposlancamento/${id}`)
  }

  postTipoLancamento(tipoLancamento: TipoLancamento): Observable<any>{
    return this.http.post(`${environment.apiURL}/tiposlancamento`, tipoLancamento);
  }

  putTipoLancamento(id: number, tipoLancamento: TipoLancamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/tiposlancamento/${id}`, tipoLancamento);
  }

  deleteTipoLancamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/tiposlancamento/${id}`);
  }

}
