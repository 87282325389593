import { Component, Input, OnInit } from '@angular/core';

import { Usuario } from 'src/app/classes/Usuario';
import { EnumOrigemManutencao, EnumTipoManutencao, ManutencaoFrota } from 'src/app/classes/gestao-lite/ManutencaoFrota';
import { FrotaService } from 'src/app/services/frota.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-manutencoes',
  templateUrl: './manutencoes.component.html',
  styleUrls: ['./manutencoes.component.scss']
})
export class ManutencoesComponent implements OnInit {
  @Input() equipamentoId: number = 0;
  carregando: boolean = false;
  manutencoes: ManutencaoFrota[] = [];
  usuarios: Usuario[] = [];
  idManutencao: number = 0;
  showManutencao: boolean = false;
  mediaPorManutencao: number = 0;
  somaManutencao: number = 0;

  constructor(private frotaService: FrotaService,
    private usuarioService: UsuarioService,
    private utils: UtilsService) {

  }

  ngOnInit() {
    this.usuarioService.getUsuarios().subscribe(
      res => {
        this.usuarios = res;
      },
      err => {
        this.utils.getErro(err);
      });

    this.listarManutencoes();
  }

  getUsuarioNome(id: number) {
    let usr = this.usuarios.find(x => x.Id == id);
    if (usr)
      return usr.Nome;
    return '';
  }

  getOrigemManutencao(id: number) {
    if (id == EnumOrigemManutencao.Externa)
      return "Externa";
    else if (id == EnumOrigemManutencao.Interna)
      return "Interna";
    else
      return "Desconhecido";
  }

  getTipoManutencao(id: number) {
    if (id == EnumTipoManutencao.Preventiva)
      return "Preventiva";
    else if (id == EnumTipoManutencao.Corretiva)
      return "Corretiva";    
    else if (id == EnumTipoManutencao.Preditiva)
      return "Preditiva";
    else
      return "Desconhecido";
  }

  listarManutencoes() {
    this.carregando = true;
    this.frotaService.getManutencaoEquipamento(this.equipamentoId).subscribe(
      res => {
        this.manutencoes = res;
        let i = 0;
        this.manutencoes.forEach(f => {
          i++;
          this.somaManutencao += f.CustoTotal;
        });
        if (i > 0)
          this.mediaPorManutencao = this.somaManutencao / i;
        this.carregando = false;
      },
      err => {
        this.carregando = false;
        this.utils.getErro(err);
      });
  }
  editar(id: number) {
    this.idManutencao = id;
    this.showManutencao = true;
  }

  fecharManutencao() {
    this.showManutencao = false;
    this.idManutencao = 0;
    this.listarManutencoes();
  }
}
