import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Empresa } from 'src/app/classes/Empresa';
import { RegionalEmpresa } from 'src/app/classes/regionais/RegionalEmpresa';
import { Regional } from 'src/app/classes/regionais/Regional';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionaisService {

  route = "Regionais";

  constructor(private http: HttpClient) { }

  Listar(): Observable<Regional[]> {
    return this.http.get<Regional[]>(`${environment.apiURL}/${this.route}`);
  }

  ListarPorParceiroId(parceiroId: string): Observable<Regional[] | null> {
    return this.http.get<Regional[]>(`${environment.apiURL}/${this.route}/parceiroId/${parceiroId}`);
  }

  ObterPorId(id: string): Observable<Regional | null> {
    return this.http.get<Regional>(`${environment.apiURL}/${this.route}/${id}`);
  }

  ObterEmpresasRelacionadas(id: string): Observable<{ Empresa: Empresa[], Regionais: Regional[] }> {
    return this.http.get<{ Empresa: Empresa[], Regionais: Regional[] }>(`${environment.apiURL}/Empresas/ObterEmpresaRegionais?RegionalId=${id}`);
  }

  Inserir(regional: Regional, empresas: Empresa[]): Observable<Regional | null> {
    let result: Regional;
    const request = this.http.post<Regional>(`${environment.apiURL}/${this.route}`, regional);
    request.subscribe(
      res => {
        result = res;
        this.RelacionarEmpresaARegional(regional.Id, empresas).subscribe(
          res => { },
          error => { return of(null) }
        )
      },
    )

    return of(result);
  }

  Atualizar(regional: Regional, id: string,
    empresas: Empresa[], empresasRelacionadas: Empresa[]): Observable<Regional | null> {
    const remover = empresasRelacionadas.filter(
      item => !empresas.includes(item));
    const adicionar = empresas.filter(
      item => !empresasRelacionadas.includes(item));
    const request = this.http.put<Regional>(`${environment.apiURL}/${this.route}/${id}`, regional);

    this.RelacionarOuDesrelacionarSeNecessario(regional.Id, remover, false);
    this.RelacionarOuDesrelacionarSeNecessario(regional.Id, adicionar, true);
    return request.pipe(
      catchError(() => of(null))
    );
  }

  Excluir(id: string): Observable<any> {
    return this.http.delete(`${environment.apiURL}/${this.route}/${id}`);
  }

  private RelacionarEmpresaARegional(regionalId: string, empresas: Empresa[]): Observable<boolean> {
    const url = `${environment.apiURL}/Empresas/RelacionarEmpresaRegionais`;
    return from(empresas).pipe(
      mergeMap(emp =>
        this.http.post(url, this.GerarRegionalEmpresa(regionalId, emp.Id))),
      map(() => true),
      catchError(error => {
        return of(false);
      })
    );
  }

  private DesrelacionarEmpresaARegional(regionalId: string, empresas: Empresa[]): Observable<boolean> {
    const url = `${environment.apiURL}/Empresas/DesrelacionarEmpresaRegionais`;
    return from(empresas).pipe(
      mergeMap(emp => this.http.delete(`${url}/${emp.Id}/${regionalId}`)),
      map(() => true),
      catchError(error => {
        return of(false);
      })
    )
  }

  private RelacionarOuDesrelacionarSeNecessario(regionalId: string, empresas: Empresa[], adicionar: boolean) {
    if (empresas.length > 0) {
      const request = adicionar ? this.RelacionarEmpresaARegional(regionalId, empresas) : this.DesrelacionarEmpresaARegional(regionalId, empresas);
      request.subscribe(
        () => { },
        () => { return of(null) }
      )
    }
  }

  private GerarRegionalEmpresa(regionalId: string, empresaId: number): RegionalEmpresa {
    return {
      EmpresaId: empresaId,
      RegionalId: regionalId
    }
  }
}
