export class Classe {
    Id: number;
    Descricao:string = "";
    Ativo: boolean = true;

    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
}
