import { EnumStatusSafra, StatusSafra } from './../../../../classes/Safra';
import { EtapaService } from './../../../../services/etapa.service';
import { Etapa } from 'src/app/classes/Etapa';
import { AtividadePlanejamento } from 'src/app/classes/AtividadePlanejamento';
import { StorageService } from 'src/app/services/storage.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { Safra } from 'src/app/classes/Safra';
import { SafraService } from 'src/app/services/safra.service';
import Swal from 'sweetalert2';
import { Fazenda } from 'src/app/classes/Fazenda';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Cultura } from 'src/app/classes/Cultura';
import { Area } from 'src/app/classes/Area';
import { AreaService } from 'src/app/services/area.service';
import { Planejamento } from 'src/app/classes/Planejamento';
import { Cultivar } from 'src/app/classes/Cultivar';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { FazendaService } from 'src/app/services/fazenda.service';
import { AnoSafraService } from 'src/app/services/ano-safra.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/classes/Usuario';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { UtilsService } from 'src/app/utils/utils.service';
import { ItensAtividade } from 'src/app/classes/ItensAtividade';
import { AtividadePlanejamentoService } from 'src/app/services/atividade-planejamento.service';
import { AtividadeSafra } from 'src/app/classes/AtividadeSafra';
import { AnoAgricolaService } from 'src/app/services/ano-agricola.service';
import { AnoAgricola } from 'src/app/classes/AnoAgricola';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { Setor } from 'src/app/classes/Setor';
import { SetorService } from 'src/app/services/setor.service';
import { TranslateService } from '@ngx-translate/core';
import { AreaConfigModalComponent } from './area-config-modal/area-config-modal.component';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { AuxPlanejamentoSafraAreas } from 'src/app/classes/auxiliar/AuxPlanejamentoSafraAreas';
import { isNull } from 'lodash';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

interface resumo{
  culturaId: number;
  culturaDescricao: string;
  grupoProdutoId: number;
  grupoProdutoDescricao: string;
  area: number;
}

@Component({
  selector: 'app-safra-tab-lateral',
  templateUrl: './safra-tab-lateral.component.html',
  styleUrls: ['./safra-tab-lateral.component.scss']
})
export class SafraTabLateralComponent implements OnInit {
  @ViewChild('ModalExcluirSafra', { static: false })
  ModalExcluirSafra: UiModalComponent;
  @ViewChild('Modal', { static: true }) modal;
  @ViewChild('ModalEdit', { static: true }) modalEdit;
  modalOpened = new EventEmitter<void>();
  @Input() carregando: boolean = false;
  @Input() anosSafras: AnoSafra[];
  @Input() fazendas: Fazenda[];
  @Input() culturas: Cultura[];
  @Input() cultivares: Cultivar[];
  @Input() planejamentoExcluido: Planejamento;

  @Output() changeSafra: EventEmitter<Safra> = new EventEmitter();
  @Output() changeFazenda: EventEmitter<Fazenda> = new EventEmitter();
  @Output() edicaoSafra: EventEmitter<boolean> = new EventEmitter();
  @Output() iniciarSafra = new EventEmitter();


  fazendaSelecionada : Fazenda;
  anoSafraSelecionado : AnoSafra;
  safraSelecionada: Safra;
  anoSafraEdit: AnoSafra;
  safras: Safra[];
  areas: Area[];
  setores: Setor[];
  auxAreas: Area[];
  textoBuscaArea: string;
  safraModal: Safra = new Safra();
  areasSafra: Area[];
  planosMestre: Planejamento[];
  areaTotalSafra: number = 0;
  public isSubmit: boolean;
  public isSubmitNovaSafra: boolean;
  public isSubmitEdit: boolean;
  mostraEdicaoSafra: boolean;
  resumos: resumo[];
  atividadesSafra: AtividadeSafra[];
  itensAtividades: ItensAtividade[];
  areaIrrigada:Area[] = [];
  areaSequeiro:Area[] = [];
  informarPlantio: boolean = false;
  usuario: Usuario = new Usuario();
  areasPlanejamento:AuxPlanejamentoSafraAreas[] = [];
  controleIdPlanejamentoSafra: number = 1;
  integraTotvs: boolean = false;
  integraSankhya: boolean = false;

  etapas: Etapa[] = [];

  statusSafraAtivo: string = "Status";
  listaStatusSafra = StatusSafra;
  carregandoSaveAnoSafra: boolean = false;
  carregandoSaveEditAnoSafra: boolean = false;
  novoAnoSafra: AnoSafra;
  anosAgricolas: AnoAgricola[];
  processosPlanejamento: ProcessoPlanejamento[];
  usaSetor: boolean = false;

  areaConfig: Area = new Area();
  areasConfig: Area[] = [];
  isEditArea: boolean = false;
  @ViewChild('AreaConfigModal', { static: false }) AreaConfigModal: AreaConfigModalComponent;

  statusFiltroSafra: number = 0;
  // tradução
ERRO: string = 'Erro!';
FEITO: string = 'Feito!';
ANO_SAFRA_DELETADO: string = 'Ano Safra deletado!';
NAO_E_POSSIVEL_EXCLUIR_UM_ANO_SAFRA_QUE_POSSUI_SAFRAS: string = 'Não é possível excluir um ano safra que possui safras!';
SIM: string = 'Sim';
CANCELAR: string = 'Cancelar';
SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
ANO_SAFRA_ALTERADO: string = 'Ano Safra alterado!';
ANO_SAFRA_CADASTRADO: string = 'Ano Safra cadastrado!';
SAFRA_REABERTA: string = 'Safra Reaberta!';
SAFRA_FINALIZADA: string = 'Safra finalizada!';
FINALIZAR_ESTA_SAFRA: string = 'Finalizar esta Safra?';
SAFRA_DELETADA: string = 'Safra deletada!';
SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_CASO_JA_TENHAM_ORDENS_DE_SERVICOS_GERADAS_TAMBEM_SERAO_EXCLUIDAS: string = 'Se deletar, esta ação não poderá ser desfeita! Caso já tenham Ordens de Serviços geradas, também serão excluídas.';
SAFRA_CADASTRADA: string = 'Safra cadastrada!';
REABRIR_ESTA_SAFRA: string = 'Reabrir esta Safra?';

  constructor(private safraService: SafraService,
    private anoAgricolaService: AnoAgricolaService,
    private areaService: AreaService,
    private fazendaService: FazendaService,
    private anoSafraService: AnoSafraService,
    private planejamentoService: PlanejamentoService,
    private toasty: CustomToastyService,
    private router: Router,
    private storageService: StorageService,
    private utils: UtilsService,
    private atividadePlanejamentoService: AtividadePlanejamentoService,
    private etapaService: EtapaService,
    private setorService: SetorService,
    private translate: TranslateService,
    private processoPlanejamentoService: ProcessoPlanejamentoService) { }

  ngOnInit() {
    this.anoSafraEdit = new AnoSafra();
    this.informarPlantio = this.router.url.includes('informar-plantio');
    this.integraTotvs = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Totvs_RM;
    this.integraSankhya = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Sankhya;
    this.usaSetor = this.storageService.getEmpresa().UsaSetores;
    this.planosMestre =[];
    this.planejamentoService.getPlanosMestrePorCultura(0).subscribe(ret =>{
      this.planosMestre = ret;
    },
    err => {
      this.utils.getErro(err);
      this.carregando = false;
    });

    this.etapaService.getEtapas().subscribe(ret =>{
      this.etapas = ret;
    });

    this.usuario = this.storageService.getUsuario();

    this.novoAnoSafra = new AnoSafra();
    this.anoAgricolaService.getAnosAgricolas().subscribe(res => {
      this.anosAgricolas = res;
    });

    this.processoPlanejamentoService.getProcessosPlanejamento().subscribe(res => {
      this.processosPlanejamento = res;
    });
    this.onLoad();

  }

  ngOnChanges() {
    this.onLoad();
  }

  onLoad() {
    if (this.anoSafraService.anoSafraSelecionado == null) {
      if (this.anoSafraSelecionado == null && this.anosSafras != null && this.anosSafras.length > 0)
        this.selecionarAnoSafra(this.anosSafras[this.anosSafras.length-1], true);
    } else
      this.selecionarAnoSafra(this.anoSafraService.anoSafraSelecionado, true);

    if (this.fazendaService.fazendaSelecionada == null) {
      if (this.fazendaSelecionada == null && this.fazendas != null && this.fazendas.length > 0){
        if(this.storageService.getFazenda())
          this.selecionarFazenda(this.storageService.getFazenda(), true);
        else
          this.selecionarFazenda(this.fazendas[this.fazendas.length-1], true);
      }
    } else
      this.selecionarFazenda(this.fazendaService.fazendaSelecionada, true);

    this.listarSafras();
  }

  listarPlanejamentos(culturaId:number): Planejamento[]{
    if (!culturaId  || culturaId <= 0){
      return [];
    }
    let auxCultivares: number[] = [];
    this.cultivares.filter(x => x.CulturaId == culturaId).forEach(x=>{
      auxCultivares.push(x.Id);
    });
    let auxPlanosMestre: Planejamento[] = [];
    auxPlanosMestre = this.planosMestre.filter(x => x.CulturaId == culturaId);
    return auxPlanosMestre;
  }

  removerAreaConfigurada(index: number, area: Area){
    let areasConfig = this.areasPlanejamento.filter(x => x.AreaId == area.Id);
    areasConfig[0].Areas.splice(index, 1);
    if(area.Irrigado)
      this.areaIrrigada = this.calculaAreaRestante(this.areaIrrigada, area, areasConfig[0].Areas);
    else
      this.areaSequeiro = this.calculaAreaRestante(this.areaSequeiro, area, areasConfig[0].Areas);
  }

    configurarArea(item: Area, isEdit: boolean, openModal: boolean = true) {
    if(item.CulturaId == null || item.CulturaId == undefined || item.CulturaId == 0){
      item.CulturaId = this.safraModal.CulturaId;
    }
    if(item.AreaUtilizada == 0)
       item.AreaUtilizada = item.AreaDisponivel;

    //calcula área disponível para o modal
    let areasConfiguradas = this.calculaAreaRestante(this.areas, item, this.getAreasConfiguradas(item.Id));
    this.areasConfig = areasConfiguradas.filter(x => x.Id == item.Id);
    item.AreaDisponivel = areasConfiguradas.filter(x => x.Id == item.Id)[0].AreaDisponivel;
    this.areaConfig = item;
    this.isEditArea = isEdit;

    if(openModal)
      this.abrirAreaConfigModal();
  }

  abrirAreaConfigModal(){
    this.AreaConfigModal.Modal.show();
    this.modalOpened.emit();
  }
  selecionarSafra(safra: Safra) {
    if (this.safraSelecionada == null || safra == null || this.safraSelecionada.Id != safra.Id) {
      this.safraSelecionada = safra;
      if(safra)
        this.safraModal.CulturaId = safra.CulturaId;
      this.changeSafra.emit(this.safraSelecionada);
      this.safraService.safraSelecionada = this.safraSelecionada;
    }
  }

  selecionarFazenda(fazenda: Fazenda, onload?: boolean) {
    if (this.fazendaSelecionada == null || this.fazendaSelecionada.Id != fazenda.Id) {
      if (!onload){
        this.safraService.safraSelecionada = null;
        this.selecionarSafra(null);
      }
      this.fazendaSelecionada = fazenda;
      this.changeFazenda.emit(this.fazendaSelecionada);
      this.fazendaService.fazendaSelecionada = fazenda;

      this.listarSafras();
    }
  }

  selecionarFazendaEdit(fazendaId: number) {
    let fazenda: Fazenda;
    fazenda = this.fazendas.find(x => x.Id == fazendaId);
    this.selecionarFazenda(fazenda);
  }

  selecionarAnoSafra(anoSafra: AnoSafra, onload?: boolean) {
    if (this.anoSafraSelecionado == null || this.anoSafraSelecionado.Id != anoSafra.Id) {
      if (!onload){
        this.safraService.safraSelecionada = null;
        this.selecionarSafra(null);
      }
      this.anoSafraSelecionado = anoSafra;
      this.anoSafraService.anoSafraSelecionado = anoSafra;

      this.listarSafras();
    }
  }
  alteraFiltroStatusSafra(status: number = 0){
    this.statusFiltroSafra = status;
    this.alterarStatusSafra(this.statusFiltroSafra)
    this.listarSafras();
  }

  listarSafras(){
    if (this.anoSafraSelecionado == null)
      return;

    if (this.fazendaSelecionada == null)
      return;

    this.carregando = true;
    this.safraService.getSafrasPorAnoFazenda(this.anoSafraSelecionado.Id, this.fazendaSelecionada.Id, this.statusFiltroSafra, true).subscribe(
      res => {
        this.safras = res;

        let safra = this.safraService.safraSelecionada == null ? this.safras != null && this.safras.length > 0 ? this.safras[0] : null : this.safraService.safraSelecionada;
        this.selecionarSafra(safra);

        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    );

      if(this.usaSetor){
        this.carregando = true;
        this.setorService.getSetoresPorFazenda(this.fazendaSelecionada.Id).subscribe(
          res => {
            this.setores = res;
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }

      this.carregando = true;
      this.areaService.getAreasPorFazendaPorAno(this.fazendaSelecionada.Id, 0).subscribe(
        res => {
          this.areas = res;
          this.auxAreas = res;
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  inserirSafra(){
    this.isSubmit = false;
    this.safraModal = new Safra();
    this.safraModal.AnoSafraId = this.anoSafraSelecionado.Id;
    this.safraModal.FazendaId = this.fazendaSelecionada.Id;
    this.areasSafra = [];
    this.areasPlanejamento = [];
    this.areaTotalSafra = 0;

    this.areas = this.areas.filter(a => a.PlanejamentoId == null);

    this.areas.forEach(a => {
      a.Selecionada = false;
      //a.AreaUtilizada = a.AreaDisponivel;
    });
    this.preencheAreaPorFazenda();
    this.auxAreas = this.areas;

    //this.ModalSafra.show();
    this.mostraEdicaoSafra = true;
    this.edicaoSafra.emit(this.mostraEdicaoSafra);
  }

  cancelarEdicaoSafra(){
    this.mostraEdicaoSafra = false;
    this.edicaoSafra.emit(this.mostraEdicaoSafra);
  }

  getCultura(id: number) : Cultura {
    if (!this.culturas)
      return new Cultura();

    let cultura = this.culturas.filter(x =>
      x.Id==id
    );

    return cultura.length > 0 ? cultura[0] : new Cultura();
  }

  changeCulturaModal(){
    /*if (this.safraModal.Id == null){
      this.safraModal.Descricao = `Safra de ${this.getCultura(this.safraModal.CulturaId).Descricao} ${this.anoSafraSelecionado.Descricao}`;
    }*/
    //this.listarPlanejamentos(this.safraModal.CulturaId);
  }
  nextPreenche(){
    let item:Area;
    if(this.areaIrrigada.length > 0){
      item = this.areaIrrigada[0];
    }
    else if(this.areaSequeiro.length > 0){
      item = this.areaSequeiro[0];
    }
    if(item){
      this.configurarArea(item, false, false);
    }
    item = new Area();
    this.filtrarAreas("");
  }
  preencheAreaPorFazenda(){
    this.areaIrrigada = this.getAreaPorFazenda(true);
    this.areaSequeiro = this.getAreaPorFazenda(false);
    this.areaIrrigada = this.areaIrrigada.map(x => {x.AreaDisponivel = x.AreaTotal; x.AreaUtilizada = 0; return x;});
    this.areaSequeiro = this.areaSequeiro.map(x => {x.AreaDisponivel = x.AreaTotal; x.AreaUtilizada = 0; return x;});
  }

  getAreasConfig(irrigado:boolean) : Area[]{
    let retorno:Area[] = [];
    this.areasPlanejamento.forEach(x => {
    let area = this.areas.filter(a => a.Id == x.AreaId && a.Irrigado == irrigado);
    if(!isNull(area) && area.length > 0)
     x.Areas.forEach(c => {c.Selecionada = true;
       retorno.push(c);
      });
    });
    return retorno;
  }

  getAreaPorFazenda(irrigado: boolean) : Area[] {
    if (!this.areas || !this.fazendaSelecionada)
      return [];

    let area = this.areas.filter(x =>
      x.FazendaId==this.fazendaSelecionada.Id && x.Irrigado == irrigado && !x.Inativa
    );

    //area.sort((a, b) => a.Descricao.localeCompare(b.Descricao));

    return area;
  }
  calculaAreaRestante(areas:Area[], area:Area, areasConfiguradas:Area[]) : Area[]{
    areas.forEach(a => {
      if(a.Id == area.Id)
      {
        a.AreaUtilizada = 0;
        let areaConfig = areasConfiguradas.filter(x=>x.Id == area.Id);
        areaConfig.forEach(x => {
          a.AreaUtilizada += x.AreaUtilizada;
        });
        a.AreaDisponivel = Number((area.AreaTotal - a.AreaUtilizada).toFixed(2));
      }
    });
    return areas;
  }

  changeArea(areaRef: Area, validar: boolean = true){
    //cria uma copia da área para não alterar a original
    let area:Area = JSON.parse(JSON.stringify(areaRef));
    //verifica se a área já foi configurada antes
    const areaExistente = this.areasPlanejamento.find(a => a.AreaId === area.Id);

    //caso não exista, adicionar a área selecionada ao objeto de controle
    if((areaExistente == null || isNull(areaExistente)) && area.Selecionada){
        let areaConfig:Area[] = [];
        area.IdPlanejamentoSafra = this.controleIdPlanejamentoSafra;
        this.controleIdPlanejamentoSafra++;
        areaConfig.push(area);
        this.areasPlanejamento.push({AreaId: area.Id, Areas: areaConfig});
    }
    else
    {
      //caso a área tenha sido marcada, adicionar a área ao objeto de controle
      let areaConfig:Area[] = this.areasPlanejamento.find(a => a.AreaId === area.Id).Areas;
      let areaAtual = areaConfig.find(a => a.IdPlanejamentoSafra === area.IdPlanejamentoSafra);
      if(areaAtual == null || isNull(areaAtual)){
        areaAtual = area;
        areaAtual.IdPlanejamentoSafra = this.controleIdPlanejamentoSafra;
        this.controleIdPlanejamentoSafra++;
        areaConfig.push(areaAtual);
      }
      else{
        areaAtual = area;
        areaConfig = areaConfig.map(obj => obj.IdPlanejamentoSafra === area.IdPlanejamentoSafra ? areaAtual : obj);
      }
      areaExistente.Areas = areaConfig;
      this.areasPlanejamento.map(obj => obj.AreaId === area.Id ? areaExistente : obj);
    }
    let areaConfig:Area[] = this.areasPlanejamento.find(a => a.AreaId === area.Id).Areas;
    let areaAtual = areaConfig.find(a => a.IdPlanejamentoSafra === area.IdPlanejamentoSafra);

    if(areaAtual.Irrigado)
      this.areaIrrigada = this.calculaAreaRestante(this.areaIrrigada, areaAtual, areaConfig);
    else
      this.areaSequeiro = this.calculaAreaRestante(this.areaSequeiro, areaAtual, areaConfig);

      this.areaTotalSafra = this.calcAreaTotalSafra();

      if(this.areaSequeiro.filter(x => x.AreaDisponivel < 0).length > 0 || this.areaIrrigada.filter(x => x.AreaDisponivel < 0).length > 0){
        this.utils.handleWarning("A area utlizada configurada ultrapassa o saldo disponível para essa área.");
        this.configurarArea(areaRef, false);
     }

    // let areaAtual = this.areasSafra.filter(a => a.Id == area.Id && (a.PlanejamentoId == null || a.PlanejamentoId == area.PlanejamentoId));
    // if (areaAtual.length > 0)
    //   if (area.Selecionada)
    //     areaAtual[0].AreaUtilizada = area.AreaUtilizada;
    //   else
    //     this.areasSafra.splice(this.areasSafra.indexOf(area), 1);
    // else
    //   if (area.Selecionada)
    //   {
    //       this.areasSafra.push(area);
    //       let areas:Area[] = [];
    //       areas.push(area);
    //       this.areasPlanejamento.push({AreaId: area.Id, Areas: areas});
    //   }




  //   let totalArea = 0;
  //   this.areasSafra.filter(a => a.Id == area.Id).forEach(a => {totalArea += a.AreaUtilizada;});
  //   if (areaAtual[0].AreaUtilizada < area.AreaDisponivel) {
  //     let saldo = area.AreaDisponivel - areaAtual[0].AreaUtilizada;
  //     //this.areaIrrigada.push({ descricao, saldo });
  //     // Ordenar o array pela descrição da área
  //     this.areaIrrigada.sort((a, b) => a.Descricao.localeCompare(b.Descricao));
  // }
  //   this.areaTotalSafra = this.calcAreaTotalSafra();
  //   if (!area.Selecionada)
  //     area.AreaUtilizada = area.AreaDisponivel;
  }

  getAreasConfiguradas(id:number) : Area[]{
    if(this.areasPlanejamento == null || this.areasPlanejamento.length == 0 || isNull(this.areasPlanejamento))
      return [];
    let areasConfig = this.areasPlanejamento.filter(x => x.AreaId == id);
    if(areasConfig == null || isNull(areasConfig) || areasConfig.length === 0)
      return [];
    let areas = areasConfig[0].Areas;
    if(areas == null || isNull(areas) || areas.length === 0)
      return [];
    if(areas == null || isNull(areas))
      return [];
    return areas;
  }

  calcAreaTotalSafra():number {
    let total = 0;
    this.areasPlanejamento.forEach(a => {
      a.Areas.forEach(x => {
        total += x.AreaUtilizada;
      });
    });
    return total;
    // let total = 0;
    // this.areasSafra.forEach(a => {
    //   if (a.AreaUtilizada > a.AreaDisponivel)
    //     a.AreaUtilizada = a.AreaDisponivel;

    //   total += a.AreaUtilizada;
    // });

    // return total;
  }

  timoutArea: any;
  changeAreaPlantada(area: Area){
    if (this.timoutArea)
      clearTimeout(this.timoutArea);

    this.timoutArea = setTimeout(() => {
      if (area.AreaUtilizada != 0){
        area.Selecionada = true;
        this.changeArea(area);
      }
    }, 1000);
  }

  changeCultivar(area: Area){
    if (area.AreaUtilizada != 0){
      area.Selecionada = true;
      this.changeArea(area);
    }

    this.changePrevisaoPlantio(area, area.DataPrevistaPlantio);
  }

  changeCultivarGeral(){
    if(this.safraModal.PlanoMestreProducaoId && this.auxAreas){
      this.auxAreas.forEach(x => {
        x.PlanoMestreProducaoId = this.safraModal.PlanoMestreProducaoId
      })
    }
  }
  changePrevisaoPlantio(area: Area, dataPrevisao: Date) {
    if (dataPrevisao) {
      area.DataPrevistaPlantio = dataPrevisao;
      let cultivar = this.cultivares.filter(c => c.Id == area.VariedadeId)[0];
      if (cultivar)
        area.DataPrevistaColheita = new Date(dataPrevisao.getTime() + (cultivar.Ciclo*24*60*60*1000));
    }
  }

  filtrarAreas(textoBusca: string){
    this.textoBuscaArea = textoBusca;
    this.areas = this.auxAreas.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBuscaArea.toUpperCase()) ||
      x.Id.toString().includes(this.textoBuscaArea)
      );
    this.areaIrrigada = this.getAreaPorFazenda(true);
    this.areaSequeiro = this.getAreaPorFazenda(false);
  }

  private filtraAreaComPredicate(predicate: (a: Area) => boolean, mensagem: string): string{
    let areasConfig = this.getAreasConfig(true).concat(this.getAreasConfig(false));
    const areasFilter = areasConfig.filter(predicate);
    if(areasFilter.length == 0) return ''

    const areasDescricao = areasFilter
      .map(a => a.Descricao)
      .join(', ');

    return `${mensagem} ${areasDescricao}`
  }

  private semPrevisaoPlantio(): string {
    const mensagem = 'previsão de plantio para a seguintes Áreas : ';
    return this.filtraAreaComPredicate(a => a.Selecionada && !a.DataPrevistaPlantio, mensagem);
  }

  private semVariedades(): string {
    const mensagem = 'variedade para a seguintes Áreas: ';
    return this.filtraAreaComPredicate(a => a.Selecionada && !a.VariedadeId, mensagem);
  }

  private validar(): string{
    let areasConfig:Area[] = this.getAreasConfig(true).concat(this.getAreasConfig(false));
    if(areasConfig.length == 0) return 'Selecione uma Area'
    if(this.semPrevisaoPlantio().length > 0) return this.semPrevisaoPlantio()
    if(this.semVariedades().length > 0) return this.semVariedades()
    return '';
  }

  adicionarSafra(form: any) {
    if(this.validar().length > 0){
      this.utils.handleWarningSwal('O corrija as informações abaixo', this.validar());
            this.isSubmit = true;
      return;
    }

    this.configurarTraducao();
    let areasConfig:Area[] = this.getAreasConfig(true).concat(this.getAreasConfig(false));
    this.carregando = true;
    this.areasSafra = areasConfig;
    let planejamentos: Planejamento[] = [];
    areasConfig.forEach(a => {
      let planejamento: Planejamento = new Planejamento();
      if(a.PlanejamentoId)
        planejamento.Id = a.PlanejamentoId;
      else
        planejamento.Id = 0;
      planejamento.AreaId = a.Id;
      planejamento.AreaPlantada = a.AreaUtilizada;
      planejamento.Descricao = this.getCultura(this.safraModal.CulturaId).Descricao;
      planejamento.CulturaId = a.CulturaId;
      planejamento.CultivarId = a.VariedadeId;
      planejamento.ProdutividadeSimulada = a.ProdutividadeSimulada;
      planejamento.PrecoVendaSimulado = a.PrecoVendaSimulado;
      planejamento.LucroSimulado = a.LucroSimulado;
      planejamento.DataPrevistaPlantio = a.DataPrevistaPlantio;
      planejamento.PlanoMestreProducaoId = a.PlanoMestreProducaoId;
      planejamento.FlagPlantou = a.FlagPlantou;
      planejamentos.push(planejamento);
    });

    this.safraModal.Planejamentos = planejamentos;

    this.safraModal.AtividadesSafra = this.atividadesSafra;

    this.safraService.postSafra(this.safraModal).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.SAFRA_CADASTRADA, 'success');
          this.carregando = false;

          this.safraModal = res;

          if (!this.safraModal.Id)
            this.addSafra(this.safraModal);
          else {
            this.safraSelecionada = this.safraModal;
            this.selecionarSafra(new Safra());
          }

          this.selecionarSafra(this.safraModal);

          this.mostraEdicaoSafra = false;
          this.edicaoSafra.emit(this.mostraEdicaoSafra);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  atualizarSomenteSafra(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    let areasConfig:Area[] = this.getAreasConfig(true).concat(this.getAreasConfig(false));
    this.areasSafra = areasConfig;
    this.carregando = true;
    this.safraService.putSafra(this.safraModal.Id, this.safraModal).subscribe(
      res => {
        this.toasty.show('Feito!', 'Safra atualizada!', 'success');
        this.carregando = false;
        this.safraSelecionada = this.safraModal;
        this.selecionarSafra(new Safra());
        this.mostraEdicaoSafra = false;
        this.edicaoSafra.emit(this.mostraEdicaoSafra);
        this.listarSafras();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }


  getCultivaresPorCultura(culturaId: number) : Cultivar[] {
    if (!this.cultivares || !culturaId)
      return [];

    let cultivares = this.cultivares.filter(x =>
      x.CulturaId==culturaId
    );

    return cultivares;
  }

  getVariedadesPorPlanejamento(planejamentoId: number) : Cultivar[] {
    if (!this.planosMestre || !planejamentoId || !this.cultivares)
      return [];

    let plano = this.planosMestre.find(x => x.Id == planejamentoId);

    let variedades = this.cultivares.filter(x =>
      x.CultivarBaseId == plano.CultivarId
    );

    return variedades;
  }

  addSafra(nova: Safra): Safra{
    if (!this.safras)
      this.safras = [];

    this.safras.push(nova);
    return nova;
  }

  excluirSafra(safra: Safra) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar Safra "'+ safra.Descricao + '"?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_CASO_JA_TENHAM_ORDENS_DE_SERVICOS_GERADAS_TAMBEM_SERAO_EXCLUIDAS,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.safraService.deleteSafra(safra.Id).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.SAFRA_DELETADA, 'success');
              this.onLoad();
              this.selecionarSafra(null);
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  editarSafra(safra: Safra){
    this.isSubmit = false;
    this.safraModal = safra;
    this.safraModal.CulturaId = safra.CulturaId;
    this.areasSafra = [];
    this.areasPlanejamento = [];
    this.areas = this.areas.filter(a => a.PlanejamentoId == null);

    this.areas.forEach(a => {
      a.Selecionada = false;
      a.AreaUtilizada = 0;
    });

    this.carregando = true;
    this.planejamentoService.getPlanejamentosPorSafra(safra.Id).subscribe(
      res => {
        let planejamentos : Planejamento[] = res;
        this.preencheAreaPorFazenda();
        let area_:Area;
        if(!isNull(this.areaIrrigada) && this.areaIrrigada.length>0)
          area_ = this.areaIrrigada[0];
        else if(!isNull(this.areaSequeiro) && this.areaSequeiro.length>0)
          area_ = this.areaSequeiro[0];

        planejamentos.forEach(p => {
          let area = this.areas.filter(a => a.Id == p.AreaId)[0];

          if (area){
            let novaArea = new Area();

            novaArea.Id = area.Id > 0 ? area.Id : 0;
            novaArea.FazendaId = area.FazendaId;
            novaArea.Descricao = area.Descricao;
            novaArea.AreaTotal = area.AreaTotal;
            novaArea.Irrigado = area.Irrigado;
            novaArea.Inativa = area.Inativa;

            novaArea.PlanejamentoId = p.Id;
            novaArea.AreaDisponivel = area.AreaDisponivel - p.AreaPlantada;
            novaArea.AreaUtilizada = p.AreaPlantada;
            novaArea.Selecionada = true;
            novaArea.CultivarId = p.CultivarId;
            novaArea.ProdutividadeSimulada = p.ProdutividadeSimulada;
            novaArea.PrecoVendaSimulado = p.PrecoVendaSimulado;
            novaArea.LucroSimulado = p.LucroSimulado;
            novaArea.DataPrevistaPlantio = p.DataPrevistaPlantio;
            novaArea.DataPrevistaColheita = p.DataPrevistaColheita;
            novaArea.PlanoMestreProducaoId = p.PlanoMestreProducaoId;
            novaArea.CulturaId = p.CulturaId;
            novaArea.VariedadeId = p.CultivarId;
            novaArea.FlagPlantou = p.FlagPlantou;

            this.safraModal.FazendaId = area.FazendaId;

            //Tratar área disponível
            let areaConfig = this.areas.filter(a => a.Id == area.Id)[0];
            areaConfig.AreaDisponivel = areaConfig.AreaDisponivel - p.AreaPlantada;
            areaConfig.AreaUtilizada = areaConfig.AreaUtilizada + p.AreaPlantada;
            areaConfig.Selecionada = area.AreaUtilizada > 0;
            //this.areas.push(novaArea);
            this.changeArea(novaArea);

            this.auxAreas = this.areas;
          }
        });
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });

    this.mostraEdicaoSafra = true;
    this.edicaoSafra.emit(this.mostraEdicaoSafra);
  }

  prepararAjustesAtividades(){
    this.atividadesSafra = [];
    let areasConfiguradas:Area[] = this.getAreasConfig(true).concat(this.getAreasConfig(false));
    areasConfiguradas.filter(x => x.FazendaId==this.fazendaSelecionada.Id && x.Selecionada).forEach(a => {
      let atividadesPlamento:AtividadePlanejamento[] = [];
      this.atividadePlanejamentoService.getAtividadesPorPlanejamentos(a.PlanoMestreProducaoId).subscribe(
        res => {
          atividadesPlamento = res;
          atividadesPlamento.forEach(atividade => {
            if(!atividade.DiasAposPlantio && (!this.getProcessoPlanejamento(atividade.ProcessoPlanejamentoId)
              || !this.getProcessoPlanejamento(atividade.ProcessoPlanejamentoId).Plantio)){
              let atividadeSafra = new AtividadeSafra();
              atividadeSafra.AreaId = a.Id;
              atividadeSafra.CulturaId = a.CulturaId;
              atividadeSafra.VariedadeId = a.VariedadeId;
              atividadeSafra.DataPlantio = a.DataPrevistaPlantio;
              atividadeSafra.DataPrevistaInicio = a.DataPrevistaPlantio;
              atividadeSafra.DataPrevistaFim = a.DataPrevistaPlantio;
              atividadeSafra.AtividadeId = atividade.Id;
              atividadeSafra.AtividadeDescricao = this.getEtapa(atividade.EtapaId).Descricao;
              this.atividadesSafra.push(atividadeSafra);
            }
          });
        });
    });
  }

  getEtapa(id:number):Etapa{
    return this.etapas.find(x => x.Id == id);
  }

  alterarStatusSafra(status: number){
    this.safraService.safraSelecionada = null;
    switch(status){
      case EnumStatusSafra.NaoIniciada:
        this.statusSafraAtivo = "Não iniciada";
        break;
      case EnumStatusSafra.EmAndamento:
        this.statusSafraAtivo = "Em andamento";
        break;
      case EnumStatusSafra.Finalizada:
        this.statusSafraAtivo = "Finalizada";
        break;
      default:
        this.statusSafraAtivo = "Status";
        break;
    }
  }

  finalizarSafra(item: Safra){
    this.configurarTraducao();
    Swal.fire({
      title: this.FINALIZAR_ESTA_SAFRA,
      // text: 'Deseja realmente finalizar esta Safra',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {}

        else {
          item.Status = 3;

          this.safraService.putSafra(item.Id, item).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.SAFRA_FINALIZADA, 'success');
              this.carregando = false;
              this.selecionarSafra(item);
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  reabrirSafra(item: Safra){
    this.configurarTraducao();
    Swal.fire({
      title: this.REABRIR_ESTA_SAFRA,
      // text: 'Deseja realmente finalizar esta Safra',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {}

        else {
          item.Status = EnumStatusSafra.EmAndamento;

          this.safraService.putSafra(item.Id, item).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.SAFRA_REABERTA, 'success');
              this.carregando = false;
              this.selecionarSafra(item);
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  atualizarStatusSafra(safra: Safra){
    this.selecionarSafra(safra)
    this.iniciarSafra.emit(safra);
  }

  calcularResumo(){
    this.resumos = [];
    let menorData: Date;
    let maiorData: Date;
    this.culturas.forEach(c => {
      var auxResumo = <resumo>{};
      auxResumo.culturaId = c.Id
      auxResumo.culturaDescricao = c.Descricao;
      auxResumo.area = 0;

      this.getAreasConfig(true).forEach(x =>{
        if(x.Selecionada && x.CulturaId == c.Id){
          auxResumo.area += x.AreaUtilizada;
          if(!menorData || x.DataPrevistaPlantio < menorData)
            menorData = x.DataPrevistaPlantio;

          if(!maiorData || x.DataPrevistaColheita > maiorData)
            maiorData = x.DataPrevistaColheita;
        }
      })

      this.getAreasConfig(false).forEach(x =>{
        if(x.Selecionada && x.CulturaId == c.Id){
          auxResumo.area += x.AreaUtilizada;
          if(!menorData || x.DataPrevistaPlantio < menorData)
            menorData = x.DataPrevistaPlantio;

          if(!maiorData || x.DataPrevistaColheita > maiorData)
            maiorData = x.DataPrevistaColheita;
        }
      })
      this.resumos.push(auxResumo);
    })
    this.safraModal.DataInicio = menorData;
    this.safraModal.DataFim =  maiorData;
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmitNovaSafra = true;
      return;
    }
    this.configurarTraducao();
    this.carregandoSaveAnoSafra = true;

    this.anoSafraService.postAnoSafra(this.novoAnoSafra).subscribe(
      res => {
        this.toasty.show(this.FEITO, this.ANO_SAFRA_CADASTRADO, 'success');
        this.carregandoSaveAnoSafra = false;
        this.anoSafraService.getAnosSafras().subscribe(ret =>{
          this.anosSafras = ret;
          this.selecionarAnoSafra(this.anosSafras[this.anosSafras.length-1], true);
        });
        this.modal.hide();
        this.novoAnoSafra = new AnoSafra();
        form.resetForm();

      },
      err => {
        this.utils.getErro(err);
        this.carregandoSaveAnoSafra = false;
      });
  }

  openEdit(item: AnoSafra){
    this.anoSafraEdit = {...item} as AnoSafra;
    this.modalEdit.show();
  }

  saveEdit(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmitEdit = true;
      return;
    }
    this.carregandoSaveEditAnoSafra = true;

    this.anoSafraService.putAnoSafra(this.anoSafraEdit.Id, this.anoSafraEdit).subscribe(
      res => {
        this.toasty.show(this.FEITO, this.ANO_SAFRA_ALTERADO, 'success');
        this.carregandoSaveEditAnoSafra = false;
        this.anoSafraService.getAnosSafras().subscribe(ret =>{
          this.anosSafras = ret;
          this.anoSafraSelecionado = this.anoSafraEdit;
        });
        this.modalEdit.hide();
      },
      err => {
        this.utils.getErro(err);
        this.carregandoSaveEditAnoSafra = false;
      });
  }

  excluirAnoSafra(AnoSafraId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar '+ nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregandoSaveEditAnoSafra = true;

          if(this.safras.filter(x => x.AnoSafraId == AnoSafraId).length > 0)
          {
            Swal.fire(this.ERRO, this.NAO_E_POSSIVEL_EXCLUIR_UM_ANO_SAFRA_QUE_POSSUI_SAFRAS, 'error');
            this.carregandoSaveEditAnoSafra = false;
            return;
          }

          this.anoSafraService.deleteAnoSafra(AnoSafraId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.ANO_SAFRA_DELETADO, 'success');
              this.anoSafraService.getAnosSafras().subscribe(ret =>{
                this.anosSafras = ret;
                this.selecionarAnoSafra(this.anosSafras[this.anosSafras.length-1], true);
              });
              this.modalEdit.hide();
              this.carregandoSaveEditAnoSafra = false;
            },
            err => {
              Swal.fire(this.ERRO, err.message, 'error');
              this.carregandoSaveEditAnoSafra = false;
            });
        }
      });
  }

  getProcessoPlanejamento(id: number): ProcessoPlanejamento{
    if(this.processosPlanejamento)
      return this.processosPlanejamento.filter(x => x.Id == id)[0];
    return null;
  }

  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.FEITO = this.translate.instant('FEITO');
    this.ANO_SAFRA_DELETADO = this.translate.instant('ANO_SAFRA_DELETADO');
    this.NAO_E_POSSIVEL_EXCLUIR_UM_ANO_SAFRA_QUE_POSSUI_SAFRAS = this.translate.instant('NAO_E_POSSIVEL_EXCLUIR_UM_ANO_SAFRA_QUE_POSSUI_SAFRAS');
    this.SIM = this.translate.instant('SIM');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.ANO_SAFRA_ALTERADO = this.translate.instant('ANO_SAFRA_ALTERADO');
    this.ANO_SAFRA_CADASTRADO = this.translate.instant('ANO_SAFRA_CADASTRADO');
    this.SAFRA_FINALIZADA = this.translate.instant('SAFRA_FINALIZADA');
    this.FINALIZAR_ESTA_SAFRA = this.translate.instant('FINALIZAR_ESTA_SAFRA');
    this.SAFRA_DELETADA = this.translate.instant('SAFRA_DELETADA');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_CASO_JA_TENHAM_ORDENS_DE_SERVICOS_GERADAS_TAMBEM_SERAO_EXCLUIDAS = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA_CASO_JA_TENHAM_ORDENS_DE_SERVICOS_GERADAS_TAMBEM_SERAO_EXCLUIDAS');
    this.SAFRA_CADASTRADA = this.translate.instant('SAFRA_CADASTRADA');
    this.SAFRA_REABERTA = this.translate.instant('SAFRA_REABERTA');
    this.REABRIR_ESTA_SAFRA = this.translate.instant('REABRIR_ESTA_SAFRA');
  }
}
