import { Component, Input, OnInit } from '@angular/core';
import { TiposDiagnoseFotos } from 'src/app/classes/tipos-diagnose/TiposDiagnoseFotos';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-diagnose-fotos',
  templateUrl: './diagnose-fotos.component.html',
  styleUrls: ['./diagnose-fotos.component.scss']
})
export class DiagnoseFotosComponent implements OnInit {

  @Input() diagnoseFoto: TiposDiagnoseFotos;

  serverUrl: string;

  constructor() {
    this.serverUrl = environment.apiURL.replace('api/v1', '');
  }

  ngOnInit() {
  }

}
