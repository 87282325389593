import { precoCotacaoItens } from 'src/app/classes/cotacao/precoCotacaoItens';
import { Produto } from './../Produto';

export class itensCotacao{
    Id: number = 0;
    CotacaoId: number;
    ProdutoId: number;
    Produto:Produto;
    PrincipioAtivo:string = "";
    UnidadeMedida:string = "";
    DoseOriginal: number = 0;
    AreaTotalAplicacao: number = 0;
    QuantidadeAjustada: number = 0;
    PrecoMinimo: number = 0;
    PrecoMaximo: number = 0;
    PrazoEntrega: number = 0;
    PrecoAlvo:number = 0;
    GrupoProduto:string = "";
    PrecoCotacaoItens:precoCotacaoItens[];
    VencedorCotacaoId: number;
    QuantidadeOriginal: number = 0;
    ValorVencedor: number = 0;
    Frete: number = 0;
    PrecoCotacaoItensVencedorId:number;
    MotivoAlteracao:string = "";
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId:number;
    UsuarioAlteracaoId:number;
    FlagExcluido:boolean = false;
}
