import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Transacao } from '../classes/Transacao';

@Injectable({
  providedIn: 'root'
})
export class TransacaoService {

  public transacaoSelecionada: Transacao;

  constructor(private http: HttpClient) { }

  getTransacoesPorTipo(tipo: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/transacao/tipo/${tipo}`)
  }

  getTransacoes(): Observable<any>{
    return this.http.get(`${environment.apiURL}/transacao/permitido`)
  }
  getTransacoesPermitidasPorTipo(tipo: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/transacao/permitido/${tipo}`)
  }

  getTransacoesTotais(): Observable<any>{
    return this.http.get(`${environment.apiURL}/transacao`)
  }

  getTransacao(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/transacao/${id}`)
  }

  postTransacao(transacao: Transacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/transacao`, transacao);
  }

  putTransacao(id: number, transacao: Transacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/transacao/${id}`, transacao);
  }

  deleteTransacao(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/transacao/${id}`);
  }

  getNova(): Observable<any>{
    return this.http.get(`${environment.apiURL}/transacao/ObterNumerosOcupados`)
  }

}
