import { StorageService } from 'src/app/services/storage.service';
import { FazendaService } from '../../../services/fazenda.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Fazenda } from 'src/app/classes/Fazenda';

@Component({
  selector: 'app-barra-tarefas-itens',
  templateUrl: './barra-tarefas-itens.component.html',
  styleUrls: ['./barra-tarefas-itens.component.scss'],
})
export class BarraTarefaItensComponent implements OnInit {

  @Input() itens = 0;
  @Input() index: number = 0;
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() plus: EventEmitter<any> = new EventEmitter();
  @Output() changeType: EventEmitter<any> = new EventEmitter();
  @Output() preview: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() first: EventEmitter<any> = new EventEmitter();
  @Output() end: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<number> = new EventEmitter();
  @Input() icons: string[] = [];
  @Input() actions: string[] = []
  @Input() file: any;
  @Input() gridType: string = 'grid';
  @Input() customClass: string = '';

  constructor() { }

  ngOnInit() {

  }

  onSave(){
    this.save.emit();
  }

  onRemove(){
    this.remove.emit();
  }

  onPreview(){
    if(this.index > 0) {
      this.index--;
    }
    this.preview.emit(this.index);
  }

  onNext(){
    if(this.index < this.itens) {
      this.index++;
    }
    this.next.emit(this.index);
  }

  onFirst(){
    this.index = 0;
    this.first.emit(this.index);
  }

  onEnd(){
    this.index = this.itens;
    this.end.emit(this.index);
  }

  onPlus(){
    this.plus.emit();
  }

  onAction(id: number){
    this.action.emit(id);
  }

  onChangeType(){
    this.gridType = this.gridType == 'grid' ? 'form' : 'grid';
    this.changeType.emit(this.gridType);
  }
}
