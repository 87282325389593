import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { TiposDiagnose } from 'src/app/classes/tipos-diagnose/TiposDiagnose';
import { TiposDiagnoseService } from 'src/app/services/tipos-diagnose/tipos-diagnose.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ColDef } from 'src/app/classes/grid/ColDef';


@Component({
  selector: 'app-tipos-diagnose',
  templateUrl: './tipos-diagnose.component.html',
  styleUrls: ['./tipos-diagnose.component.scss']
})
export class TiposDiagnoseComponent implements OnInit {

  @ViewChild('Grid', { static: false }) grid

  colDefs: ColDef[] = [];
  listaTiposDiagnose: TiposDiagnose[] = [];
  objSelecionado: TiposDiagnose = new TiposDiagnose();
  objSelecionadoId: string = null;
  objsSelecionados: TiposDiagnose[] = [];

  constructor(
    private router: Router,
    private tiposDiagnoseService: TiposDiagnoseService,
    private utilsService: UtilsService,
    private toasty: CustomToastyService
  ) { }

  ngOnInit() {
    this.configura();
    this.pegarTiposDiagnose();
  }

  private configura() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true
      },
      { field: 'Codigo', headerName: 'Código', flex: 1 },
      { field: 'Descricao', headerName: 'Descrição', flex: 1 },
      { field: 'OrdemRelatorioAcompanhamento', headerName: 'Ordem Relatório Acompanhamento', flex: 1 },
    ]
  }

  private pegarTiposDiagnose() {
    this.tiposDiagnoseService.Listar().subscribe(
      res => this.listaTiposDiagnose = res,
      error => {
        if (error.status == 404) return;
        this.utilsService.getErro(error)
      }
    )
  }

  novo() {
    this.router.navigate(['/cadastros/tipos-diagnose/inserir']);
  }

  excluir() {

    if (this.objsSelecionados.length <= 0 || this.objSelecionadoId == null) {
      this.utilsService.handleWarning("Selecione um Tipos Diagnose para prosseguir!");
      return;
    }
    if (this.objsSelecionados.length > 1) {
      this.utilsService.handleWarning("Selecione apenas um Tipos Diagnose para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar ' + this.objsSelecionados[0].Descricao + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.tiposDiagnoseService.Excluir(this.objSelecionadoId).subscribe(
          res => {
            this.toasty.show('Feito!', 'Tipos diagnose excluído!', 'success');
            this.pegarTiposDiagnose()
          },
          err => {
            this.utilsService.getErro(err);
          });
      }
    });
  }

  selectionChanged(event) {
    this.objsSelecionados = event;
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  cellDoubleClicked(event) {
    this.router.navigate([`/cadastros/tipos-diagnose/${event.data.Id}`]);
  }
}
