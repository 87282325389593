import { Component, OnInit } from '@angular/core'
import {
  Chamado,
  EnumCriticidadeChamado,
  EnumModuloChamado,
  EnumStatusChamado,
  EnumTipoChamado,
} from 'src/app/classes/chamado/Chamado'
import { StorageService } from '../../services/storage.service'

import { Usuario } from 'src/app/classes/Usuario'
import { ChamadoService } from 'src/app/services/chamados.service'
import Swal from 'sweetalert2'
import { Anexo } from 'src/app/classes/chamado/Anexo'
import { UtilsService } from 'src/app/utils/utils.service'
import * as moment from 'moment'
import { UsuarioService } from 'src/app/services/usuario.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-chamado',
  templateUrl: './chamado.component.html',
  styleUrls: ['./chamado.component.scss'],
})
export class ChamadoComponent implements OnInit {
  public size = 'large-view';
  public showView = false
  public basicContent: string;
  public albums: any;
  public chamados: Chamado[] = [];
  public usuarioLogado: Usuario;
  public usuarios: Usuario[] = [];
  public chamadoAtivo: Chamado = new Chamado();
  public recolherFiltro: boolean = false;
  public carregandoFiltro: boolean = false;
  public carregando: boolean = false;

  constructor(
    private storageService: StorageService,
    private usuarioService: UsuarioService,
    private chamadoService: ChamadoService,
    private utils: UtilsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.usuarioLogado = this.storageService.getUsuario();

    this.usuarioService.getUsuarios().subscribe(
      res => {
        this.usuarios = res;
        this.listarChamados();
      },
      err => {
        this.utils.getErro(err);
      })
  }

  listarChamados() {
    this.carregando = true;
    this.chamadoService.get().subscribe(
      (res) => {
        this.chamados = res;
        this.chamados.sort((a, b) => Date.parse(b.DataCadastro) - Date.parse(a.DataCadastro))
        this.carregando = false;
        this.chamados.forEach(p => {
          this.carregando = true;

          if(this.usuarioLogado.isSuper){
            this.usuarioService.findUsuario(p.UsuarioCadastroId)
            .subscribe(
              res => {
                p.UsuarioCadastro = res;
              },
              err => {
                this.utils.getErro(err);
              }
            )
          }else{
            p.UsuarioCadastro = this.getUsuario(p.UsuarioCadastroId);
          }

          this.chamadoService.getComentarios(p.Id).subscribe(
            (res) => {
              p.Comentarios = res;
              p.QtdComentarios = p.Comentarios.length;
              this.carregando = false;
            },
            (err) => {
              this.utils.getErro(err)
              this.carregando = false;
            },
          )
        });

      },
      (err) => {
        this.utils.getErro(err)
      },
    )
  }

  getUsuario(id: number): Usuario {
    return this.usuarios.find(p => p.Id == id);
  }

  filtrar(event: any) {
    this.chamadoService.filtrar(event.modulo, event.tipo, event.status, event.criticidade, event.dataInicio, event.dataFim, event.empresa)
      .subscribe(
        res => {
          this.chamados = res;
          this.chamados.sort((a, b) => Date.parse(b.DataCadastro) - Date.parse(a.DataCadastro));
          this.chamados.forEach(p => {
            this.carregando = true;
            if(this.usuarioLogado.isSuper){
              this.usuarioService.findUsuario(p.UsuarioCadastroId)
              .subscribe(
                res => {
                  p.UsuarioCadastro = res;
                },
                err => {
                  this.utils.getErro(err);
                }
              )
            }else{
              p.UsuarioCadastro = this.getUsuario(p.UsuarioCadastroId);
            }
            this.chamadoService.getComentarios(p.Id).subscribe(
              (res) => {
                p.Comentarios = res;
                p.QtdComentarios = p.Comentarios.length;
                this.carregando = false;
              },
              (err) => {
                this.utils.getErro(err)
                this.carregando = false;
              },
            )
          });

        },
        err => {
          this.utils.getErro(err)
        }
      )
  }

  concluirCriacaoChamado() {
    this.listarChamados();
    const msg = `${this.usuarioLogado.Nome}, ${this.translateService.instant('SEU_REGISTRO_FOI_SALVO_COM_SUCESSO_O_CODIGO_PARA_ACOMPANHAMENTO_E')} #${this.chamados[0].Id}. ${this.translateService.instant('EM_ATE__HORAS_VOCE_RECEBERA_UM_PRIMEIRO_FEEDBACK_CONTE_SEMPRE_CONOSCO')}. `;
    this.utils.handleSuccess(msg);
  }

  excluirChamado(chamado: Chamado) {
    chamado.Excluindo = true;
    this.chamadoService.delete(chamado.Id)
      .subscribe(
        res => {
          this.utils.handleSuccess(`${this.translateService.instant('CHAMADO')} #${chamado.Id} ${this.translateService.instant('EXCLUIDO_COM_SUCESSO')}!`);
          chamado.Excluindo = false;
          this.listarChamados();
        },
        err => {
          this.utils.getErro(err);
          chamado.Excluindo = false
        }
      )
  }

  popularAlbum(anexos: Anexo[]) {
    this.albums = []
    anexos.forEach((p) => {
      const album = {
        src: p.Imagem,
        caption: p.Descricao,
        thumb: p.Imagem,
      }
      this.albums.push(album)
    })
  }

  tipoChamado(tipo: EnumTipoChamado): string[]{
    // cor, icone, tooltip

    let duvida: string = this.translateService.instant("DUVIDA");
    let sugestao: string = this.translateService.instant("SUGESTAO");
    let erro: string = this.translateService.instant("ERRO");

    switch(tipo){
      case EnumTipoChamado.Duvida || 'Duvida':
        return ['text-primary', 'help', duvida]
        break;
      case EnumTipoChamado.Sugestao || 'Sugestao':
        return ['text-info', 'info', sugestao]
        break;
      case EnumTipoChamado.Erro || 'Erro':
        return ['text-danger', 'bug_report', erro]
        break;
    }
  }

  moduloChamado(modulo: EnumModuloChamado): string[]{
    // icone, tooltip

    let compras: string = this.translateService.instant("COMPRAS");
    let manutecao: string = this.translateService.instant("MANUTECAO");
    let operacao: string = this.translateService.instant("OPERACAO");

    switch(modulo){
      case EnumModuloChamado.Compras:
        return ['fa fa-shopping-basket', compras]
        break;
      case EnumModuloChamado.Manutencoes:
        return ['fa fa-tools', manutecao]
        break;
      case EnumModuloChamado.Operacoes:
        return['fa fa-tractor',  operacao]
        break;
    }
  }

  formatarDataAtualizacao(chamado: Chamado): string {
    const dtLastUpdateChamado = chamado.DataAlteracao;
    const ultimoComentario = chamado.Comentarios && chamado.Comentarios.length > 0 ? chamado.Comentarios.sort((a, b) => Date.parse(a.DataAlteracao) - Date.parse(b.DataAlteracao)).reverse()[0] : null;
    const dtLastUpdateComentario = ultimoComentario ? ultimoComentario.DataAlteracao : null;
    const ultimoAnexo = chamado.Anexos && chamado.Anexos.length > 0 ? chamado.Anexos.sort((a, b) => Date.parse(a.DataAlteracao) - Date.parse(b.DataAlteracao)).reverse()[0] : null;
    const dtLastUpdateAnexo = ultimoAnexo ? ultimoAnexo.DataAlteracao : null;
    const atualizacoes = [dtLastUpdateChamado, dtLastUpdateComentario, dtLastUpdateAnexo].filter(Boolean);
    const maiorData = new Date(Math.max(...atualizacoes.map(data => Date.parse(data))));
    return this.utils.calcularTempoDecorrido(maiorData);
  }

  formatarData(data: string): string {
    return moment(data).format('DD-MM-YYYY');
  }

  maiorData(date1Str: string, date2Str: string, date3Str: string): Date {
    const date1 = new Date(date1Str).getTime();
    const date2 = new Date(date2Str).getTime();
    const date3 = new Date(date3Str).getTime();

    if (date1 > date2 && date1 > date3) {
      return new Date(date1Str);
    } else if (date2 > date1 && date2 > date3) {
      return new Date(date2Str);
    } else {
      return new Date(date3Str);
    }
  }

  criticidadeBadge(obj: EnumCriticidadeChamado) {
    switch (obj) {
      case EnumCriticidadeChamado.Alto:
        return '<span class="badge m-r-5 badge-danger">Crítico</span>'
        break
      case EnumCriticidadeChamado.Medio:
        return '<span class="badge m-r-5 badge-warning">Importante</span>'
        break
      case EnumCriticidadeChamado.Baixo:
        return '<span class="badge m-r-5 badge-success">Normal</span>'
        break
      default:
        break
    }
  }

  statusChamado(chamado: Chamado): string {
    switch (chamado.Status) {
      case EnumStatusChamado.Aberto:
        return '';
        break;
      case EnumStatusChamado.EmAndamento:
        return 'inprogress-tic';
        break;
      case EnumStatusChamado.Concluido:
        return 'close-tic';
        break;

      default:
        break;
    }
  }

}


