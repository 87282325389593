import { Component, OnInit } from '@angular/core';
import {FrotaService} from '../../../../services/frota.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { AuxManutencaoFrotaTotalizadores } from 'src/app/classes/gestao-lite/AuxManutencaoFrotaTotalizadores';
import {AuxManutencaoFrota, EnumStatusManutencao} from '../../../../classes/gestao-lite/AuxManutencaoFrota';
import { Equipamento } from 'src/app/classes/Equipamento';
import { EquipamentoService } from 'src/app/services/equipamento.service';

@Component({
  selector: 'app-status-manutencoes',
  templateUrl: './status-manutencoes.component.html',
  styleUrls: ['./status-manutencoes.component.scss']
})
export class StatusManutencoesComponent implements OnInit {
  categories: string[] = new Array<string>();
  data: number[] = new Array<number>();
  showGrafico = false;
  carregando = false;
  public chartOptions: any;
  manutencoes:AuxManutencaoFrotaTotalizadores;
  listaManutencoes:AuxManutencaoFrota[];
  auxListaManutencoes:AuxManutencaoFrota[];
  dataInicio: Date;
  dataFim: Date;
  textoBusca: string;
  status:number = 0;
  tipo:number = 0;
  origem:number = 0;
  tipoIntervalo:number = 0;
  equipamentoId:number = 0;
  equipamentos: Equipamento[];

  constructor(private FrotaService:FrotaService,
    private EquipamentoService: EquipamentoService,
    private utils: UtilsService,) { }

  ngOnInit() {
    this.listarEquipamentos();
    this.filtrar();
  }

  listarEquipamentos()
  {
    this.EquipamentoService.getEquipamentos().subscribe(res=>{
      this.equipamentos = res;
      this.equipamentos.push({ Id: 0, Descricao: "Todos" } as Equipamento);
    }, err=>{
      this.utils.getErro(err);
    });
  }
  pieChart()
  {
    this.chartOptions = {
      chart: {
        height: 350,
        type: 'pie',
      },
      labels: ['Em Dias', 'Alerta', 'Atrasadas'],
      series: this.data,
      colors: ['#0e9e4a', '#ffa21d', '#ff5252'],
      legend: {
        show: true,
        position: 'bottom',
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 400
          },
          legend: {
            position: 'bottom'
          }
        }
      }] 
    }
  }
  filtrar() {
    this.carregando = true;
    this.showGrafico = false;
    this.FrotaService.getStatusManutencao(this.status,this.tipo,this.origem,this.tipoIntervalo,this.equipamentoId).subscribe(res=>{
      this.manutencoes = res;
      this.listaManutencoes = this.manutencoes.Manutencao;
      this.auxListaManutencoes = this.listaManutencoes;
      this.data = [];
      this.data.push(this.manutencoes.EmDias);
      this.data.push(this.manutencoes.Alerta);
      this.data.push(this.manutencoes.Atrasado);
      this.pieChart();
      this.showGrafico = true;
      this.carregando = false;
    }, err=>{
      this.carregando=false;
      this.utils.getErro(err);
    });
  }

  filtrarEquipamentos() {
    this.listaManutencoes = this.auxListaManutencoes.filter(c =>
      c.EquipamentoDescricao.toUpperCase().includes(this.textoBusca.toUpperCase()));
  }
}
