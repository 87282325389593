import { Fornecedores } from './Fornecedores';

export class FornecedorCotacao{
    Id: number;
    CotacaoId: number;
    FornecedorId: number;
    Fornecedor: Fornecedores;
    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId:number;
    UsuarioAlteracaoId:number;
    FlagExcluido:boolean = false;
}
