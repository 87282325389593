import { Component, Input, OnInit } from '@angular/core';

import { AbastecimentoService } from 'src/app/services/abastecimento.service';
import { Abastecimento } from './../../../../classes/gestao-lite/Abastecimento';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { Usuario } from 'src/app/classes/Usuario';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-abastecimentos',
  templateUrl: './abastecimentos.component.html',
  styleUrls: ['./abastecimentos.component.scss']
})
export class AbastecimentosComponent implements OnInit {
  @Input() equipamentoId: number = 0;
  carregando: boolean = false;
  abastecimentos: Abastecimento[] = [];
  usuarios: Usuario[] = [];
  showAbastecimento: boolean = false;
  idAbastecimento: number = 0;

  // tradução
DELETAR_ABASTECIMENTO: string = 'Deletar abastecimento?';
SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
CANCELAR: string = 'Cancelar';
SIM: string = 'Sim';

  constructor(private abastecimentoService: AbastecimentoService,
    private usuarioService: UsuarioService,
    private utils: UtilsService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.usuarioService.getUsuarios().subscribe(
      res => {
        this.usuarios = res;
      },
      err => {
        this.utils.getErro(err);
      });

    this.listarAbastecimentos();
  }

  listarAbastecimentos(){
    this.carregando = true;
    this.abastecimentoService.listarPorEquipamento(this.equipamentoId).subscribe(
      res => {
        this.abastecimentos = res;
        this.carregando = false;
      },
      err => {
        this.carregando = false;
        this.utils.getErro(err);
      });
  }

  getUsuarioNome(id: number){
    let usr =  this.usuarios.find(x => x.Id == id);
    if(usr)
      return usr.Nome;    
    return '';
  }

  editar(id:number){
    this.idAbastecimento = id;
    this.showAbastecimento = true;
  }

  fecharAbastecimento(){
    this.showAbastecimento = false;
    this.idAbastecimento = 0;
    this.listarAbastecimentos();
  } 

  excluir(id: number) {    
    this.configurarTraducao();
    let DataAbastecimento = this.abastecimentos.find(x => x.Id == id).DataAbastecimento;
    Swal.fire({
      title: this.DELETAR_ABASTECIMENTO,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.abastecimentoService.deleteAbastecimento(id).subscribe(
            res => {
              this.carregando = false;
              this.listarAbastecimentos();
            },
            err => {
              this.carregando = false;
              this.utils.getErro(err);
            });
        }
      });
  }

  configurarTraducao() {
    this.DELETAR_ABASTECIMENTO = this.translate.instant('DELETAR_ABASTECIMENTO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
}
}
