import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {PersonalizacaoGridModel} from 'src/app/classes/PersonalizacaoGridModel';
import { ColDef } from '../classes/grid/ColDef';

@Injectable({
  providedIn: 'root'
})
export class PersonalizacaoGridService {

  constructor(private http: HttpClient) { }

  GetPorUserTela(tela: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/PersonalizacaoGrid/${tela}`)
  }

  postPersonalizacao(telaId:number, coluna:string, hide:boolean, PersonalizacaoGrid: ColDef[]): Observable<any>{
    return this.http.post(`${environment.apiURL}/PersonalizacaoGrid/${telaId}/${coluna}/${hide}`, PersonalizacaoGrid);
  }
}
