import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Equipamento } from 'src/app/classes/Equipamento';
import { Funcionario } from 'src/app/classes/Funcionario';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Oficina } from 'src/app/classes/Oficina';
import { PendenciaFrota } from 'src/app/classes/PendenciaFrota';
import { Produto } from 'src/app/classes/Produto';
import { EnumStatusManutencaoFrota, ItemChecklistManutencaoFrota } from 'src/app/classes/gestao-lite/ItemChecklistManutencaoFrota';
import { ItemManutencaoFrota } from 'src/app/classes/gestao-lite/ItemManutencaoFrota';
import { ListaTipoManutencao, ManutencaoFrota } from 'src/app/classes/gestao-lite/ManutencaoFrota';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { ApontamentoFrotaService } from 'src/app/services/apontamento-frota.service';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { ItemChecklistManutencaoFrotaService } from 'src/app/services/item-checklist-manutencao-frota.service';
import { ItemManutencaoFrotaService } from 'src/app/services/item-mautencao-frota.service';
import { ManutencaoFrotaService } from 'src/app/services/manutencao-frota.service';
import { PendenciaFrotaService } from 'src/app/services/pendencia-frota.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-forms-manutencao-os',
  templateUrl: './forms-manutencao-os.component.html',
  styleUrls: ['./forms-manutencao-os.component.scss']
})
export class FormsManutencaoOsComponent implements OnInit {

  @Input() produtos: Produto[];
  @Input() oficinas: Oficina[];
  @Input() locaisEstoque: LocalEstoque[];
  @Input() funcionarios: Funcionario[];
  @Input() manutencao: ManutencaoFrota = new ManutencaoFrota();
  @Output() attModel = new EventEmitter();
  @Output() attLista = new EventEmitter();
  @Output() confirmacaoPendencias = new EventEmitter();
  @Output() visualizarEstoque = new EventEmitter();
  @Output() criarPendenciaFrota = new EventEmitter();
  @Output() visualizarAnexosPendenciaFrota = new EventEmitter();
  @Output() visualizarPlanoManutencao = new EventEmitter();

  icon_valid = `<span class="material-icons" style="color: green">check_circle_outline</span>`
  icon_invalid = `<span class="material-icons" style="color: red;">highlight_off</span>`
  icon_valid_color = `#4f8c06`
  icon_invalid_color = `#ff5252`

  equipamento: Equipamento = new Equipamento();
  ultimoMedidor: number = 0;
  variacaoMedidor: number = 0;

  // itensManutencaoFrota: ItemManutencaoFrota[] = [];
  cabecalhoValido: boolean;
  coldefItensPlanoManutencao: ColDef[] = [];
  coldefItensPendencia: ColDef[] = [];
  coldefOs: ColDef[] = [];
  selecionadosPlanoManutencao: ItemChecklistManutencaoFrota[] = [];
  selecionadosPendencia: PendenciaFrota[] = [];
  selecionadosOS: ItemManutencaoFrota[] = [];
  showFormsProduto = false;
  showFormsPlanoManutencao = false;
  newItemProduto: ItemManutencaoFrota = new ItemManutencaoFrota();
  listaTipoManutencao = ListaTipoManutencao;

  constructor(private equipamentoService: EquipamentoService,
    private manutencaoFrotaService: ManutencaoFrotaService,
    private itemChecklistManutencaoFrotaService: ItemChecklistManutencaoFrotaService,
    private itemManutecaoFrotaService: ItemManutencaoFrotaService,
    private apontamentoFrotaService: ApontamentoFrotaService,
    private pendenciaFrotaService: PendenciaFrotaService,
    private produtoService: ProdutoService,
    private utils: UtilsService,
    private elRef: ElementRef) { }

  ngOnInit() {
    this.configurarGrid();
    this. calcIntens();
    this.attModel.emit(this.manutencao)
  }
  configurarGrid() {
    this.coldefItensPlanoManutencao = [{
      width: 45,
      lockPosition: true,
      lockVisible: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: "", headerName: "Plano" },
    { field: "IntervaloPlano", headerName: "Intervalo do Plano", valueFormatter: (params) => this.utils.formatarDecimais(params.data.IntervaloPlano)},
    { field: "Id", headerName: "Id" },
    { field: "Descricao", headerName: "Descrição da Atividade" },
    { field: "ProdutoIndiciado", headerName: "Produto Indicado" },
    { field: "Quantidade", headerName: "Quantidade", valueFormatter: (params) => this.utils.formatarDecimais(params.data.Quantidade) },
    { field: "Status", headerName: "Status", valueFormatter: (params) => this.planoManutencaoStatus(params.data.Status)},
    ]
    this.coldefItensPendencia = [{
      width: 45,
      lockPosition: true,
      lockVisible: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: "Id", headerName: "Id" },
    { field: "DataAbertura", headerName: "Data Abertura", valueFormatter: (params) => this.utils.formatarData(params.data.DataAbertura) },
    { field: "", headerName: "Tempo", valueFormatter: (params) => this.calcTempo(params.data.Tempo) },
    { field: "Descricao", headerName: "Descrição da Pendência" },
    ]
    this.coldefOs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: "", headerName: "Id Atividade", hide: !this.manutencao.Id },
      { field: "", headerName: "Id Pendência", hide: !this.manutencao.Id },
      { field: "", headerName: "Tipo", hide: !this.manutencao.Id, cellRenderer: (params) => params.data.IsServico ? "Serviço" : "Produto" },
      { field: "Id", headerName: "Cod. Item", hide: !this.manutencao.Id },
      { field: "ProdutoDescricao", headerName: "Descrição Item" },
      { field: "UnidadeMedida", headerName: "Und" },
      { field: "Quantidade", headerName: "Quantidade" },
      { field: "ValorUnitario", headerName: "Preço" },
      { field: "ValorTotal", headerName: "Valor Total", hide: !this.manutencao.Id },
      { field: "LocalEstoqueDescricao", headerName: "Local Estoque", hide: !this.manutencao.Id },
      { field: "StatusString", headerName: "Status", hide: !this.manutencao.Id },
      { field: "UltimaTroca", headerName: "Ultima Troca", hide: !this.manutencao.Id },
      { field: "Utilizacao", headerName: "Utilização", hide: !this.manutencao.Id },
      { field: "VidaUtil", headerName: "Vida Útil", hide: !this.manutencao.Id },
    ]
  }
  getIcon(valid: boolean): string {
    if (valid)
      return this.icon_valid;
    else
      return this.icon_invalid;
  }
  getIconColor(valid: boolean): string {
    if (valid)
      return this.icon_valid_color;
    else
      return this.icon_invalid_color;
  }
  planoManutencaoStatus(status: EnumStatusManutencaoFrota){
    switch(status){
      case EnumStatusManutencaoFrota.Pendente:
        return 'Pendente';
      case EnumStatusManutencaoFrota.Execucao:
        return 'Execução';
      case EnumStatusManutencaoFrota.Pausada:
        return 'Pausada';
      case EnumStatusManutencaoFrota.Cancelada:
        return 'Cancelada';
      case EnumStatusManutencaoFrota.ParcialmenteConcluida:
        return 'Parcialmente Concluída';
      case EnumStatusManutencaoFrota.Concluida:
        return 'Concluída';
    }
  }
  selecionarEquipamento(equipamentoId: number) {
    this.manutencao.EquipamentoId = equipamentoId;
    this.equipamentoService.getEquipamento(equipamentoId).subscribe(ret => {
      this.equipamento = ret;
      this.apontamentoFrotaService.GetUltimoMedidor(equipamentoId).subscribe(ret => {
        this.ultimoMedidor = ret;
      });
    });
  }
  calcularVariacaoMedidor() {
    this.variacaoMedidor = this.manutencao.MedidorAtual - this.ultimoMedidor;
  }

  selecionarProduto(produtoId: number) {
    this.newItemProduto.ProdutoId = produtoId;
    this.produtoService.getProduto(produtoId).subscribe((produto) => {
      this.newItemProduto.Quantidade = 1;
      this.newItemProduto.ValorUnitario = produto.PrecoUnitario;
      this.changeTotal();
    });
  }

  changeTotal() {
    this.newItemProduto.ValorTotal = this.newItemProduto.Quantidade * this.newItemProduto.ValorUnitario;
    this.calcIntens();
  }

  calcIntens() {
    this.manutencao.CustoTotalProdutos = 0;
    this.manutencao.CustoTotalServicos = 0;
    this.manutencao.ItensManutencaoFrota.forEach((item) => {
      if (item.IsServico) {
        if (item.ValorTotal > 0)
          this.manutencao.CustoTotalServicos += item.ValorTotal;
      } else {
        if (item.ValorTotal > 0)
          this.manutencao.CustoTotalProdutos += item.ValorTotal;
      }
    });
    this.manutencao.CustoTotal = this.manutencao.CustoTotalProdutos + this.manutencao.CustoTotalServicos;
  }
  calcTempo(horas: number): string {
    var dias = horas / 24;
    horas = horas - (dias*24);    //var segundos= depois.diff(antes, 'seconds');
    return `${dias.toFixed(0)}d ${parseInt(horas.toFixed(2))}h`
  }

  //Plano Manutencao
  excluirPlanoManutencao() {
    var ids = this.selecionadosPlanoManutencao.map(s => s.Id);
    ids.forEach(p => {
      this.itemChecklistManutencaoFrotaService.excluir(p)
        .subscribe(res => {
          this.utils.handleSuccess("Item excluído com sucesso!");
          this.manutencao.ItensChecklist = this.manutencao.ItensChecklist.filter(item => !ids.includes(item.Id));
  }, err => this.utils.getErro(err));
    });
  }
  buscarPlanoManutencao(evento) { }
  cellClickedPlanoManutencao() { };
  cellDoubleClickedPlanoManutencao() { };
  selectionChangedPlanoManutencao(event) {
    this.selecionadosPlanoManutencao = event;
  };
  visualizarEstoqueChecklist() {
    if (this.selecionadosPlanoManutencao.length <= 0) {
      this.utils.handleWarning("Selecione um item para continuar");
      return;
    }
    if (this.selecionadosPlanoManutencao.length > 1) {
      this.utils.handleWarning("Selecione apenas um item para continuar");
      return;
    }
    this.visualizarEstoque.emit(0);
  }
  abrirModalFinalizarChecklist() {
    if (this.selecionadosPlanoManutencao.length != 1) {
      this.utils.handleWarning("Selecione um item para continuar");
      return;
    }
    this.confirmacaoPendencias.emit({ manutencaoId: this.manutencao.Id, itemId: this.selecionadosPlanoManutencao[0].Id, tipo: 2 });
  }
  visualizarChecklist(){
    if (this.selecionadosPlanoManutencao.length != 1) {
      this.utils.handleWarning("Selecione um item para continuar");
      return;
    }
    this.visualizarPlanoManutencao.emit(this.selecionadosPlanoManutencao[0].Id)
  }
  //Pendencia
  excluirPendencia() {
    var ids = this.selecionadosPendencia.map(s => s.Id);
    this.manutencao.Pendencias = this.manutencao.Pendencias.filter(item => !ids.includes(item.Id));
    ids.forEach(p => {
      this.pendenciaFrotaService.Excluir(p)
        .subscribe(res => {
          this.utils.handleSuccess("Pendencia excluída com sucesso!");
        }, err => this.utils.getErro(err));
    });
  };
  buscarPendencia() { };
  criarPendencia() {
    let pendenciaFrota = new PendenciaFrota();
    pendenciaFrota.ManutencaoFrotaId = this.manutencao.Id;
    pendenciaFrota.EquipamentoId = this.manutencao.EquipamentoId;
    this.criarPendenciaFrota.emit(pendenciaFrota);
  }
  cellClickedPendencia() { };
  cellDoubleClickedPendencia() { };
  selectionChangedPendencia(event) {
    this.selecionadosPendencia = event;
  };
  abrirModalFinalizarPendencia() {
    if (this.selecionadosPendencia.length != 1) {
      this.utils.handleWarning("Selecione um item para continuar");
      return;
    }
    this.confirmacaoPendencias.emit({ manutencaoId: this.manutencao.Id, itemId: this.selecionadosPendencia[0].Id, tipo: 1 });
  }
  abrirModalVisualizarAnexosPendenciaFrota() {
    if (this.selecionadosPendencia.length != 1) {
      this.utils.handleWarning("Selecione um item para continuar");
      return;
    }
    this.visualizarAnexosPendenciaFrota.emit(this.selecionadosPendencia[0]);
  }
  //Produto
  addItemProduto() {
    var produto = this.produtos.find(p => p.Id == this.newItemProduto.ProdutoId)
    this.newItemProduto.ProdutoDescricao = produto.Descricao;
    this.newItemProduto.UnidadeMedida = produto.UnidadeMedida;
    if(this.manutencao.Id){
      this.newItemProduto.ManutencaoFrotaId = this.manutencao.Id;
      this.itemManutecaoFrotaService.postItemManutencaoFrota(this.newItemProduto)
      .subscribe(res => {
        this.utils.handleSuccess("Item salvo com sucesso!");
        this.manutencao.ItensManutencaoFrota.push(res);
        res.ProdutoDescricao = produto.Descricao;
      });
    }else{
      this.manutencao.ItensManutencaoFrota.push(this.newItemProduto);
    }


    this.newItemProduto = new ItemManutencaoFrota();
    this.showFormsProduto = false;
    this.calcIntens();
  };
  excluirProduto() {
    if (this.selecionadosOS.length <= 0) {
      this.utils.handleWarning("Selecione um item para continuar");
      return;
    }
    var ids = this.selecionadosOS.map(s => s.ProdutoId);
    this.manutencao.ItensManutencaoFrota = this.manutencao.ItensManutencaoFrota.filter(item => !ids.includes(item.ProdutoId));
    ids.forEach(p => {
      this.itemManutecaoFrotaService.deleteItemManutencaoFrotae(p)
        .subscribe(res => {
          this.utils.handleSuccess("Item excluído com sucesso!");
        }, err => this.utils.getErro(err));
    });
  };
  buscarProduto() { };
  cellClickedProduto() { };
  cellDoubleClickedProduto() { };
  selectionChangedProduto(event) {
    this.selecionadosOS = event;
  };
  visualizarEstoqueProduto() {
    console.debug(this.selecionadosOS)
    if(!this.validarSelecaoUnica(this.selecionadosOS))
      return;

    console.debug(this.selecionadosOS)
    this.visualizarEstoque.emit(this.selecionadosOS[0].ProdutoId);
  }
  validarSelecaoUnica(arr: any[]): boolean {
    if (this.selecionadosOS.length <= 0) {
      this.utils.handleWarning("Selecione um item para continuar");
      return false;
    }
    if (this.selecionadosOS.length > 1) {
      this.utils.handleWarning("Selecione apenas um item para continuar");
      return false;
    }
    return true;
  }
}
