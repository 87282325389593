import { Empresa } from 'src/app/classes/Empresa';
import { Cotacao } from 'src/app/classes/cotacao/cotacao';
import { Fornecedores } from './Fornecedores';
import { precoCotacaoItens } from './precoCotacaoItens';
export class precoCotacao{
    Id: number;
    FornecedorId: number;
    Fornecedor:Fornecedores
    Data: Date = new Date();
    DiasVencimento: number = 0;
    TipoFrete:string = "";
    PrazoEntrega: number = 0;
    PercDescontoAntecipacao: number = 0;
    CotacaoId:number = 0;
    Cotacao:Cotacao;
    Global:boolean = false;
    PrecoCotacaoItens:precoCotacaoItens[];
    EmpresaId:number;
    PercDesconto:number = 0;
    ValorDesconto:number = 0;
    CondicoesPagamento:string = "";
    ValorFrete:number = 0;
    Empresa:Empresa;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId:number;
    UsuarioAlteracaoId:number;
    FlagExcluido:boolean = false;
    Desistencia: number = 0;
    MotivoDesistencia:string = "";
}
