import { OrdemServico } from './../../classes/OrdemServico';
import { Equipamento } from 'src/app/classes/Equipamento';
import { EquipamentoService } from './../../services/equipamento.service';
import { TelemetriaService } from './../../services/telemetria.service';
import { Area } from './../../classes/Area';
import { Fazenda } from './../../classes/Fazenda';
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { AreaService } from 'src/app/services/area.service';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/interval';
import { environment } from 'src/environments/environment';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { EnumStatusOS } from 'src/app/classes/OrdemServico';
import { UtilsService } from 'src/app/utils/utils.service';
import { CultivarService } from 'src/app/services/cultivar.service';
import { SafraService } from 'src/app/services/safra.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { AtividadeService } from 'src/app/services/atividade.service';
import { ItemOrdemServicoService } from 'src/app/services/item-ordem-servico.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { Router } from '@angular/router';
import { GrupoOperacaoService } from 'src/app/services/grupo-operacao.service';
import { enumPerfil } from 'src/app/classes/Usuario';
import { Cultura } from 'src/app/classes/Cultura';
import { Cultivar } from 'src/app/classes/Cultivar';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { Atividade } from 'src/app/classes/Atividade';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { Safra } from 'src/app/classes/Safra';

interface LayerArea {
  areaId: number;
  descricao: string;
  tipo: string;
  lng: number ;
  lat: number;
  latlng: any;
  raio:number;
  corFundo: string;
  corBorda: string;
  label: any;
}

interface ResumoOperacao{
  EquipamentoId: number;
  EquipamentoDescricao: string;
  EquipamentoIdIrrigaPrime: string;
  OrdemServicoId: number;
  OrdemServicoEtapa: String;
  SafraDescricao: String;
  AreaDescricao: String;
  OperadorId?: number;
  OperadorNome?: string;
}


@Component({
  selector: 'app-mapa-telemetria',
  templateUrl: './mapa-telemetria.component.html',
  styleUrls: ['./mapa-telemetria.component.scss']
})
export class MapaTelemetriaComponent implements OnInit {

  fazenda: Fazenda;
  areas: Area[];
  layersAreas: LayerArea[] = [];
  telemetriaEquipamento: any = [];
  historicosTelemetria: HistoricoTelemetria[] = [];

  cores: string[] = ["#00BFFF","#00FF00","#DAA520","#BC8F8F","#F5DEB3","#FF6347"]

  lat = -16.962890748088704;
  lng = -46.53369555106444;

  equipamentos: Equipamento[] = [];
  ordensServico: OrdemServico[] = [];
  resumosOperacao: ResumoOperacao[] = [];

  baseIcone: string = environment.baseApiURL + '/Assets/Icones/';

  //filtros
  grupoOperacaoId: number = 0;
  gruposOperacao: GrupoOperacao[];

  //map antigo
  permissaoLiberar: boolean = false;
  isAdmin: boolean;
  temTelemetria: boolean = false;
  culturas: Cultura[];
  culturaSelecionadaId: number = 0;

  variedades: Cultivar[] = [];
  atividades: Atividade[]= [];
  gruposProduto: GrupoProduto[];
  auxGruposProduto: GrupoProduto[];
  safras: Safra[];
  safraId:number;
  areaSelecionadaId: number = 0;
  areaSelecionada: Area = new Area;
  mostrarDetalhe: boolean = false;


  corVerde = "#006600";
  corAmarela ="#439921";
  corVermelha = "#FF2C2C";
  corVermelhaClara = "#ff5959";


  constructor(private storageService: StorageService,
    private areaService: AreaService,
    private telemetriaService: TelemetriaService,
    private equipamentoService: EquipamentoService,
    private ordemServicoService: OrdemServicoService,
    private utils: UtilsService,
    private safraService: SafraService,
    private planejamentoService: PlanejamentoService,
    private cultivarService: CultivarService,
    private atividadeService: AtividadeService,
    private itemOrdemServicoService: ItemOrdemServicoService,
    private produtoService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private toasty: CustomToastyService,
    private router: Router,
    private grupoOperacaoService: GrupoOperacaoService
    ) { }

  ngOnInit() {
    this.fazenda = this.storageService.getFazenda();
    this.lat = this.fazenda.Latitude;
    this.lng = this.fazenda.Longitude;
    this.permissaoLiberar = this.storageService.getUsuario().PermissaoUsuario.LiberarOS;
    this.isAdmin = this.storageService.getUsuario().Perfil == enumPerfil.Admin || this.storageService.getUsuario().isSuper;
    this.temTelemetria = this.storageService.getEmpresa().UsaTelemetriaIrrigaPrime;

    this.planejamentoService.getCulturas().subscribe((ret) =>{
      this.culturas = ret;
      this.culturaSelecionadaId = 0;
    },
    (err) =>{
      this.utils.getErro(err);
    });

    this.cultivarService.getCultivares().subscribe((ret) =>{
      this.variedades = ret;
    },
    (err) =>{
      this.utils.getErro(err);
    })

    this.grupoOperacaoService.getGruposOperacao().subscribe((ret) => {
      this.gruposOperacao = ret;
      this.grupoOperacaoId = 0;
    },
    (err) =>{
      this.utils.getErro(err);
    })

    this.atividadeService.getAtividades().subscribe((ret) =>{
      this.atividades = ret;
    },
    (err) =>{
      this.utils.getErro(err);
    })

    this.grupoProdutoService.getGruposProduto().subscribe((ret) =>{
      this.auxGruposProduto = ret;
    })


    this.equipamentoService.getEquipamentos().subscribe((ret) =>{
      this.equipamentos = ret;
    });

    this.carregarTelemetria();
    //Chama intervalo de 3 segundos para atualizar a telemetria
    /*let sub = Observable.interval(1000)
    .subscribe((val) => {
      this.atualizarTelemetria();
    });*/
    let hoje = new Date();
    this.carregarHistoricoTelemetria(hoje.getFullYear() + '-'+ (hoje.getMonth() + 1) + '-' + hoje.getDate());
  }

  ngAfterViewInit() {
    if(this.fazenda)
      this.areaService.getAreasPorFazenda(this.fazenda.Id).subscribe(ret => {
        this.areas = ret;
        this.safraService.getSafrasPorAnoFazenda(0, this.fazenda.Id).subscribe(ret=>{
          this.safras = ret;
          if(this.safras && this.safras.length > 0){
            this.safraId = this.safras[0].Id;
            this.selecionarSafra();
          }
          else{
            this.carregarMapAreas();
          }
        },
        (errSafra) => {
          this.carregarMapAreas();
        })
      },
      err=>{
        this.utils.getErro(err);
      })
    else
      this.carregarMapAreas();
  }

  selecionarSafra(){
    this.carregarDetalhamento();
  }

  selecionaOperacao(){
    this.carregarDetalhamento();
  }

  selecionarCultura(){
    this.carregarDetalhamento();
  }

  carregarMapAreas(){
    this.layersAreas = [];
    if(this.areas)
      this.areas.forEach(area => {
        if(area.LayersArea)
        {
          let auxCorBorda = (area.Selecionada ? (!area.EmAtraso ? this.corAmarela : this.corVermelhaClara) : "#bcb242");
          let auxCorFundo = (area.Selecionada ? (!area.EmAtraso ? this.corVerde : this.corVermelha) : "#BDB76B");

          let layers = JSON.parse(area.LayersArea);
          layers.forEach(layer => {
            let layerArea: LayerArea = {  areaId: area.Id,
                                          descricao: area.Descricao,
                                          tipo: layer.tipo,
                                          lat: layer.latlng[0],
                                          lng: layer.latlng[1],
                                          latlng: layer.latlng,
                                          raio: layer.raio,
                                          corFundo: auxCorFundo,
                                          corBorda: auxCorBorda,
                                          label: {text: area.Descricao , color: '#fff',  fontSize: '9px'}
                                        };

            if(layer.tipo == 'polygon'){
              let latlngs = [];
              layer.latlng.forEach(latlng => {
                latlngs.push({lat: latlng[0], lng: latlng[1]});
              });
              layerArea.latlng = latlngs;
              layerArea.lat = this.getCentroPoligono(latlngs).lat;
              layerArea.lng = this.getCentroPoligono(latlngs).lng;
            }
            this.layersAreas.push(layerArea);
          });
        }
      })
  }

  mapReady(map) {
    map.setOptions({
      zoomControl: "true",
      zoomControlOptions: {
        position: 3,
        style: 1
      },

    });
  }

  carregarTelemetria(){
      this.telemetriaService.getTelemetria().subscribe((ret) =>{
        this.telemetriaEquipamento = JSON.parse(ret);
        let i = 0;
        this.telemetriaEquipamento.forEach(tel => {
          let equipamento = this.equipamentos.find(eq => eq.IdIrrigaPrime == tel.DISP_ID);
          tel.LABEL = {text: (equipamento && equipamento.Tag ? equipamento.Tag : tel.NAME) , color: 'white', fontWeight: 'bold', fontSize: '10px'};
          tel.OPTIONS = {animation: 1};
          let icone = (equipamento && equipamento.IconeIrrigaPrime ? this.baseIcone + equipamento.IconeIrrigaPrime : 'assets/images/trator.png');
          tel.ICON = {url: icone, scaledSize: {width: 30, height: 30}, labelOrigin: {x: 15, y: 35}};
          tel.COR = (equipamento && equipamento.CorRotaIrrigaPrime ? equipamento.CorRotaIrrigaPrime : this.cores[i]);
          if(i == this.cores.length - 1)
            i = 0;
          else
            i++;

          if(this.historicosTelemetria && this.historicosTelemetria.length > 0){
            let historico: HistoricoTelemetria = {AUX_1: tel.DATA_1, AUX_2: tel.DATA_2, DISP_ID: tel.DISP_ID, DT_UPDATE: tel.TIMESTAMP, ID_ROTA: 0, LAT_2: parseFloat(tel.LAT), LON_2: parseFloat(tel.LON)};
            this.historicosTelemetria.push(historico);
          }

        });
      });
  }

  atualizarTelemetria(){
    this.telemetriaService.getTelemetria().subscribe((ret) =>{
      let auxTelemetriaEquipamento = JSON.parse(ret);
      let i = 0;
      this.telemetriaEquipamento.forEach(tel => {
        let auxTel = auxTelemetriaEquipamento.find(aux => aux.DISP_ID == tel.DISP_ID);
        tel.LAT = auxTel.LAT;
        tel.LON = auxTel.LON;
        tel.DATA_1 = auxTel.DATA_1;
        tel.DATA_2 = auxTel.DATA_2;
        tel.TIMESTAMP = auxTel.TIMESTAMP;
        let equipamento = this.equipamentos.find(eq => eq.IdIrrigaPrime == tel.DISP_ID);
        tel.LABEL = {text: (equipamento && equipamento.Tag ? equipamento.Tag : tel.NAME) , color: 'white', fontWeight: 'bold', fontSize: '10px'};
        tel.OPTIONS = {animation: 1};
        let icone = (equipamento && equipamento.IconeIrrigaPrime ? this.baseIcone + equipamento.IconeIrrigaPrime : 'assets/images/trator.png');
        tel.ICON = {url: icone, scaledSize: {width: 30, height: 30}, labelOrigin: {x: 15, y: 35}};
        tel.COR = (equipamento && equipamento.CorRotaIrrigaPrime ? equipamento.CorRotaIrrigaPrime : this.cores[i]);
        if(i == this.cores.length - 1)
          i = 0;
        else
          i++;

        if(this.historicosTelemetria && this.historicosTelemetria.length > 0){
          let historico: HistoricoTelemetria = {AUX_1: tel.DATA_1, AUX_2: tel.DATA_2, DISP_ID: tel.DISP_ID, DT_UPDATE: tel.TIMESTAMP, ID_ROTA: 0, LAT_2: parseFloat(tel.LAT), LON_2: parseFloat(tel.LON)};
          this.historicosTelemetria.push(historico);
        }

      });
    });
  }

  carregarHistoricoTelemetria(data: string){
    this.telemetriaService.getHistoricoTelemetria(data).subscribe((ret) =>{
      ret.forEach(his => {
        let historico: HistoricoTelemetria = {AUX_1: his.AUX_1, AUX_2: his.AUX_2, DISP_ID: his.DISP_ID, DT_UPDATE: his.DT_UPDATE, ID_ROTA: his.ID_ROTA, LAT_2: parseFloat(his.LAT_2), LON_2: parseFloat(his.LON_2)};
        this.historicosTelemetria.push(historico);
      });

    });
  }


  clicaArea(idArea:number){
    console.log("Clicou na area => ", idArea);
  }

  getCentroPoligono(latlngs: any[]){
    let x1 = 0; let y1 = 0; let x2 = 0; let y2 = 0;
    let centro = {lat: 0, lng: 0};
    let totalLat = 0;
    let totalLng = 0;
    latlngs.forEach(latlng => {
      if(x1 == 0)
        x1 = latlng.lat;
      else
        x1 = (x1 > latlng.lat? latlng.lat : x1);

      if(y1 == 0)
        y1 = latlng.lng;
      else
        y1 = (y1 > latlng.lng? latlng.lng : y1);

      if(x2 == 0)
        x2 = latlng.lat;
      else
        x2 = (x2 < latlng.lat? latlng.lat : x2);

      if(y2 == 0)
        y2 = latlng.lng;
      else
        y2 = (y2 < latlng.lng? latlng.lng : y2);

    });

    centro.lat = x1 + ((x2 - x1) / 2);
    centro.lng = y1 + ((y2 - y1) / 2);
    return centro;
  }

  carregarDetalhamento(){
    this.areaSelecionadaId = 0;
    this.areaSelecionada = new Area;


    let safrasFiltro: number[] = [];

    let dataInicio = new Date();
    let dataFim = new Date();
    let status: EnumStatusOS[] = [];
    dataFim.setDate(dataInicio.getDate() + 365);
    dataInicio.setDate(dataInicio.getDate() - 365);


    let filtros: any = {};
    let fazendasFiltro: number[] = [];
    let operacoes: number[] = [];
    let hoje = new Date();


    fazendasFiltro.push(this.fazenda.Id);
    safrasFiltro.push(this.safraId);

    if(this.grupoOperacaoId > 0)
      operacoes.push(this.grupoOperacaoId);


    status.push(EnumStatusOS.Cancelada);

    filtros.Fazenda = fazendasFiltro;
    filtros.Safra = safrasFiltro;
    filtros.AgruparPorArea = true;
    filtros.dataInicio = dataInicio;
    filtros.dataFim = dataFim;
    filtros.Operacao = operacoes;
    filtros.Status = status;
    filtros.StatusNotIn = true;
    filtros.Cultura = this.culturaSelecionadaId;

    this.ordemServicoService.detalhamento(filtros).subscribe(
      (ret) =>{
        this.ordensServico = ret;
        if(this.ordensServico)
        {
          this.areas.forEach(
            (x) => {
              x.Selecionada = this.ordensServico.filter(o=> o.AreaId == x.Id).length > 0;
              x.EmAtraso = this.ordensServico.filter(o=> o.AreaId == x.Id && o.EmAtraso && o.Status != 5).length > 0;
            });

          this.carregarMapAreas();
          this.resumosOperacao = [];

          this.ordensServico.forEach((x) => {
            let equipamento = this.equipamentos.find(eq => eq.Id == x.EquipamentoId);
            if(equipamento){
              let resumoOperacao: ResumoOperacao = {
                                                    EquipamentoId: equipamento.Id,
                                                    EquipamentoDescricao: equipamento.Descricao,
                                                    EquipamentoIdIrrigaPrime: equipamento.IdIrrigaPrime,
                                                    OrdemServicoId: x.Id,
                                                    OrdemServicoEtapa: x.DescricaoEtapa,
                                                    SafraDescricao: x.DescricaoSafra,
                                                    AreaDescricao: x.DescricaoArea,
                                                  };
              this.resumosOperacao.push(resumoOperacao);
            }
          });
        }
      },
      err => {
        this.utils.getErro(err);
      });
  }

}
