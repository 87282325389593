import { TranslateService } from "@ngx-translate/core";

export abstract class TipoImprodutividadeTranslate {
  /**
   *
   */
  constructor(translate: TranslateService) {
    this.ERRO = translate.instant('ERRO');
    this.FEITO = translate.instant('FEITO');
    this.TIPO_DE_IMPRODUTIVIDADE_ALTERADO = translate.instant('TIPO_DE_IMPRODUTIVIDADE_ALTERADO');
    this.TIPO_DE_IMPRODUTIVIDADE_CADASTRADO = translate.instant('TIPO_DE_IMPRODUTIVIDADE_CADASTRADO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = translate.instant('CANCELAR');
    this.SIM = translate.instant('SIM');
    this.TIPO_DE_IMPRODUTIVIDADE_DELETADO = translate.instant('TIPO_DE_IMPRODUTIVIDADE_DELETADO');

  }

  ERRO: string = 'Erro!';
  FEITO: string = 'Feito!';
  TIPO_DE_IMPRODUTIVIDADE_ALTERADO: string = 'Tipo de Improdutividade alterado!';
  TIPO_DE_IMPRODUTIVIDADE_CADASTRADO: string = 'Tipo de Improdutividade cadastrado!';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  TIPO_DE_IMPRODUTIVIDADE_DELETADO: string = 'Tipo de Improdutividade deletado!';
  DELETAR_PARAM: string;

}
