import { TranslateService } from "@ngx-translate/core";

export class BuscaOrdemServicoTranslate{

  /**
   *
   */
  constructor(translate: TranslateService) {
    this.ERRO = translate.instant('ERRO');
  }
  ERRO: string = 'Erro!';
}
