import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EnumStatusSafra, Safra } from 'src/app/classes/Safra';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { Cultura } from 'src/app/classes/Cultura';
import { CulturaService } from 'src/app/services/cultura.service';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { AnoSafraService } from 'src/app/services/ano-safra.service';
import { Planejamento } from 'src/app/classes/Planejamento';
import { Area } from 'src/app/classes/Area';
import { AreaService } from 'src/app/services/area.service';
import { Fazenda } from 'src/app/classes/Fazenda';
import { FazendaService } from 'src/app/services/fazenda.service';
import { Cultivar } from 'src/app/classes/Cultivar';
import { CultivarService } from 'src/app/services/cultivar.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';
import { SafraService } from 'src/app/services/safra.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-safra',
  templateUrl: './safra.component.html',
  styleUrls: ['./safra.component.scss']
})
export class SafraComponent implements OnInit {
  @ViewChild('ModalSimular', { static: false })
  ModalSimular: UiModalComponent;
  @ViewChild('ModalConfigurar', { static: false })
  ModalConfigurar: UiModalComponent;
  @ViewChild('ModalSimularSafra', { static: false })
  ModalSimularSafra: UiModalComponent;

  carregando: boolean = false;
  anosSafras: AnoSafra[];
  fazendas: Fazenda[];
  culturas: Cultura[];
  cultivares: Cultivar[];

  safraSelecionada: Safra;
  planejamentos: Planejamento[];
  auxPlanejamentos: Planejamento[];
  areas: Area[];
  areaTotalSafra: number = 0;

  fazenda: Fazenda;

  filtroStatus: number = 0;
  filtroArea: number = 0;
  filtroIrrigado: number = 0;

  filtroStatusAtivo: string = "Por status";
  filtroAreaAtivo: string = "Por área";
  filtroIrrigadoAtivo: string = "Por tipo de área";

  planejamentoSimulado: Planejamento = new Planejamento();
  planejamentoConfigurado: Planejamento = new Planejamento();
  planejamentoExcluido: Planejamento = new Planejamento();
  planosMestre: Planejamento[];
  public isSubmit: boolean;
  perfilUsuario:number = 0;
  mostraEdicaoSafra: boolean = false;
  informarPlantio: boolean = false;
  statusNaoIniciada: number = EnumStatusSafra.NaoIniciada;

  // tradução
SIM: string = 'Sim';
NAO: string = 'Não';
VOCE_SERA_REDIRECIONADO_PARA_A_TELA_DE_ORDENS_DE_SERVICO_: string = 'Você será redirecionado para a tela de Ordens de Serviço, ';
ACESSAR_ORDENS_DE_SERVICO: string = 'Acessar Ordens de Serviço?';
ORDENS_DE_SERVICO_GERADAS_COM_SUCESSO: string = 'Ordens de Serviço geradas com sucesso';
TODAS_AS_ORDENS_DE_SERVICO_DESSA_SAFRA_FORAM_GERADAS: string = 'Todas as Ordens de Serviço dessa Safra foram geradas';
CANCELAR: string = 'Cancelar';
AO_INICIAR_A_SAFRA_TODAS_AS_ORDENS_DE_SERVICO_SERAO_GERADAS_BASEADO_NOS_DIAS_DE_REFERENCIA_E_NA_PREVISAO_DE_PLANTIO_DESEJA_CONTINUAR: string = 'Ao iniciar a safra, todas as Ordens de Serviço serão geradas baseado nos Dias de Referência e na Previsão de Plantio. Deseja continuar?';
INICAR_SAFRA: string = 'Iniciar safra?';
SAFRA_JA_INICIADA: string = 'Safra já iniciada!';
ATENCAO: string = 'Atenção!';
NENHUMA_SAFRA_SELECIONADA: string = 'Nenhuma safra selecionada!';
PLANTIO_ENCERRADO: string = 'Plantio encerrado!';
FEITO: string = 'Feito!';
OS_EXCLUIDAS_COM_SUCESSO: string = 'OS Excluídas com sucesso';
TODAS_AS_OS_DESSA_SAFRA_FORAM_EXCLUIDAS: string = 'Todas as O.S. dessa safra foram Excluídas';
SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
DELETAR_TODAS_OS_DESSA_SAFRA: string = 'Deletar todas O.S. dessa safra?';
OS_GERADAS_COM_SUCESSO: string = 'OS Geradas com sucesso';
TODAS_AS_OS_DESSA_SAFRA_FORAM_GERADAS: string = 'Todas as O.S. dessa safra foram geradas';
ESSA_ACAO_PODERA_LEVAR_UM_TEMPO_E_SUBSTITUIR_AS_OS_JA_EXISTENTES: string = 'Essa ação poderá levar um tempo e substituir as O.S. já existentes!';
GERAR_TODAS_OS_DESSA_SAFRA: string = 'Gerar todas O.S. dessa safra?';
SEQUEIRO: string = 'Sequeiro';
IRRIGADO: string = 'Irrigado';
POR_TIPO_DE_AREA: string = 'Por tipo de área';
POR_AREA: string = 'Por área';
NAO_CONFIGURADOS: string = 'Não configurados';
CONFIGURADOS: string = 'Configurados';
POR_STATUS: string = 'Por status';

  constructor(private areaService: AreaService,
              private anoSafraService: AnoSafraService,
              private fazendaService: FazendaService,
              private culturaService: CulturaService,
              private cultivarService: CultivarService,
              private ordemServicoService: OrdemServicoService,
              private planejamentoService: PlanejamentoService,
              private route: ActivatedRoute,
              private router: Router, private storageService: StorageService,
              private toasty: CustomToastyService,
              private utils: UtilsService,
              private safraService: SafraService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.anoSafraService.anoSafraSelecionado = null;
    this.perfilUsuario = this.storageService.getUsuario().Perfil;
    this.informarPlantio = this.router.url.includes('informar-plantio');

    this.culturaService.getCulturas().subscribe(ret =>{
      this.culturas = ret;
    });

    this.cultivarService.getCultivares().subscribe(ret =>{
      this.cultivares = ret;
    });

    this.anoSafraService.getAnosSafras().subscribe(ret =>{
      this.anosSafras = ret;
    });

    this.areaService.getAreas().subscribe(ret =>{
      this.areas = ret;
    });

    this.fazendaService.getFazendas().subscribe(ret =>{
      this.fazendas = ret;
    });
  }

  selecionarSafra(safra: Safra){
    this.safraSelecionada = safra;
    this.listarPlanejamentos();
  }

  selecionarFazenda(fazenda: Fazenda){
    this.fazenda = fazenda;
  }

  listarPlanejamentos(){
    if (this.safraSelecionada == null || !this.safraSelecionada.Id)
      return;

    this.carregando = true;
    this.planejamentoService.getPlanejamentosPorSafra(this.safraSelecionada.Id).subscribe(
      res => {
        this.planejamentos = res;
        this.auxPlanejamentos = this.planejamentos;
        this.carregando = false;

        this.areaTotalSafra = 0;
        this.planejamentos.forEach(p => {
          p.DescricaoArea = this.getArea(p.AreaId).Descricao;
          this.areaTotalSafra += p.AreaPlantada;
          if(!p.DataRealPlantio)
          p.DataRealPlantio = p.DataPrevistaPlantio;
      });
      this.planejamentos.sort((a, b) => a.DescricaoArea.localeCompare(b.DescricaoArea));

        this.calculaLucroSafraTotal();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  filtrar(){
    this.configurarTraducao();
    this.filtroStatusAtivo = this.POR_STATUS;

    if (this.filtroStatus == 1)
      this.filtroStatusAtivo = this.CONFIGURADOS;
    else if (this.filtroStatus == 2)
      this.filtroStatusAtivo = this.NAO_CONFIGURADOS;

    this.filtroAreaAtivo = this.POR_AREA;

    if (this.filtroArea > 0)
      this.filtroAreaAtivo = this.getArea(this.filtroArea).Descricao;

    this.filtroIrrigadoAtivo = this.POR_TIPO_DE_AREA;

    if (this.filtroIrrigado == 1)
      this.filtroIrrigadoAtivo = this.IRRIGADO;
    else if (this.filtroIrrigado == 2)
      this.filtroIrrigadoAtivo = this.SEQUEIRO;

    this.planejamentos = this.auxPlanejamentos.filter(x =>
      ((this.filtroStatus == 1 && x.QtdAtividades > 0) || (this.filtroStatus == 2 && x.QtdAtividades <= 0) || this.filtroStatus == 0) &&
      (x.AreaId == this.filtroArea || this.filtroArea == 0) &&
      (this.filtroIrrigado == 0 || (this.filtroIrrigado == 1 && this.getArea(x.AreaId).Irrigado) || (this.filtroIrrigado == 2 && !this.getArea(x.AreaId).Irrigado))
    );
  }

  filtrarStatus(status){
    this.filtroStatus = status;
    this.filtrar();
  }

  filtrarArea(areaId){
    this.filtroArea = areaId;
    this.filtrar();
  }

  filtrarIrrigado(irrigado){
    this.filtroIrrigado = irrigado;
    this.filtrar();
  }

  limparFiltros(){
    this.filtroStatus = 0;
    this.filtroArea = 0;
    this.filtroIrrigado = 0;
    this.filtrar();
  }

  getArea(id: number) : Area {
    if (!this.areas)
      return new Area();

    let area = this.areas.filter(x =>
      x.Id==id
    );

    return area.length > 0 ? area[0] : new Area();
  }

  getAreaPorFazenda() : Area[] {
    if (!this.areas || !this.fazenda)
      return [];

    let area = this.areas.filter(x =>
      x.FazendaId==this.fazenda.Id
    );

    return area;
  }

  getCultivar(id: number) : Cultivar {
    if (!this.cultivares)
      return new Cultivar();

    let cultivar = this.cultivares.filter(x =>
      x.Id==id
    );

    return cultivar.length > 0 ? cultivar[0] : new Cultivar();
  }

  getCultura(id: number) : Cultura {
    if (!this.culturas)
      return new Cultura();

    let cultura = this.culturas.filter(x =>
      x.Id==id
    );

    return cultura.length > 0 ? cultura[0] : new Cultura();
  }

  deletar(id: number, index: number, nome: string){
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar '+ nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.planejamentoService.deletePlanejamento(id).subscribe(
          res => {
            this.planejamentoExcluido = this.planejamentos.filter(p=>p.Id==id)[0];
            this.planejamentos.splice(index, 1);
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
        }
      });
  }

  simular(planejamento: Planejamento){
    this.isSubmit = false;
    this.planejamentoSimulado = new Planejamento();
    this.planejamentoSimulado.Id = planejamento.Id;
    this.planejamentoSimulado.CustoPorHA = planejamento.CustoPorHA;
    this.planejamentoSimulado.ProdutividadeSimulada = planejamento.ProdutividadeSimulada;
    this.planejamentoSimulado.PrecoVendaSimulado = planejamento.PrecoVendaSimulado;
    this.planejamentoSimulado.LucroSimulado = planejamento.LucroSimulado;
    this.ModalSimular.show();
  }

  confirmarSimulacao(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    let planejamento = this.planejamentos.filter(p => p.Id == this.planejamentoSimulado.Id)[0];

    this.calculaLucro();
    planejamento.ProdutividadeSimulada = this.planejamentoSimulado.ProdutividadeSimulada;
    planejamento.PrecoVendaSimulado = this.planejamentoSimulado.PrecoVendaSimulado;
    planejamento.LucroSimulado = this.planejamentoSimulado.LucroSimulado;

    this.carregando = true;
    this.planejamentoService.putPlanejamento(this.planejamentoSimulado.Id, planejamento).subscribe(
      res => {
        this.ModalSimular.hide();
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  calculaLucro(){
    this.planejamentoSimulado.LucroSimulado = (this.planejamentoSimulado.ProdutividadeSimulada * this.planejamentoSimulado.PrecoVendaSimulado) - this.planejamentoSimulado.CustoPorHA;
  }

  simularSafra(){
    this.isSubmit = false;
    this.calculaLucroSafraTotal();
    this.ModalSimularSafra.show();
  }

  gerarOsSafra(){
    this.configurarTraducao();
    this.isSubmit = false;
    if (!this.safraSelecionada || !this.planejamentos)
      return;

      Swal.fire({
        title: this.GERAR_TODAS_OS_DESSA_SAFRA,
        text: this.ESSA_ACAO_PODERA_LEVAR_UM_TEMPO_E_SUBSTITUIR_AS_OS_JA_EXISTENTES,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: this.CANCELAR,
        confirmButtonText: this.SIM,
      }).then((willDelete) => {
          if (willDelete.dismiss) {

          } else {
            this.carregando = true;
            this.ordemServicoService.gerarOrdemServicoPorSafra(this.safraSelecionada.Id).subscribe(
            res => {
              this.carregando = false;
              this.toasty.show(this.OS_GERADAS_COM_SUCESSO, this.TODAS_AS_OS_DESSA_SAFRA_FORAM_GERADAS, 'success');
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
          }
        });
  }

  excluirOsSafra(){
    this.configurarTraducao();
    this.isSubmit = false;
    if (!this.safraSelecionada || !this.planejamentos)
      return;

    Swal.fire({
      title: this.DELETAR_TODAS_OS_DESSA_SAFRA,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.ordemServicoService.deleteOrdemServicoPorSafra(this.safraSelecionada.Id).subscribe(
          res => {
            this.carregando = false;
            this.toasty.show(this.OS_EXCLUIDAS_COM_SUCESSO, this.TODAS_AS_OS_DESSA_SAFRA_FORAM_EXCLUIDAS, 'success');
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
        }
      });
  }


  timoutSafra: any;
  calculaLucroSafra(planejamento: Planejamento){
    if (this.timoutSafra)
      clearTimeout(this.timoutSafra);

    this.timoutSafra = setTimeout(() => {
      if (planejamento)
        this.atualizaSimulacao(planejamento);
      else {
        this.planejamentos.forEach(p => {
          p.ProdutividadeSimulada = this.safraSelecionada.ProdutividadeSimulada;
          p.PrecoVendaSimulado = this.safraSelecionada.PrecoVendaSimulado;

          this.atualizaSimulacao(p);
        });
      }

      this.calculaLucroSafraTotal();
    }, 300);
  }

  atualizaSimulacao(planejamento: Planejamento) {
    planejamento.LucroSimulado = (planejamento.ProdutividadeSimulada * planejamento.PrecoVendaSimulado) - planejamento.CustoPorHA;

    this.planejamentoService.putPlanejamento(planejamento.Id, planejamento).subscribe(
      res => {
      },
      err => {
        this.utils.getErro(err);
      });
  }

  calculaLucroSafraTotal() {
    if (!this.safraSelecionada || !this.planejamentos)
      return;

    this.safraSelecionada.AreaTotal = 0;
    this.safraSelecionada.LucroSimulado = 0;
    this.safraSelecionada.CustoPorHA = 0;
    this.safraSelecionada.PrecoVendaSimulado = 0;
    this.safraSelecionada.ProdutividadeSimulada = 0;

    let custoTotal = 0;
    let produtividadeTotal = 0;
    let precoTotal = 0;
    let lucroTotal = 0;

    this.planejamentos.filter(p => p.LucroSimulado != 0).forEach(p => {
      lucroTotal += p.LucroSimulado * p.AreaPlantada;
      custoTotal += p.CustoPorHA * p.AreaPlantada;
      produtividadeTotal += p.ProdutividadeSimulada * p.AreaPlantada;
      precoTotal += p.PrecoVendaSimulado * p.AreaPlantada;

      this.safraSelecionada.AreaTotal += p.AreaPlantada;
    });

    this.safraSelecionada.ProdutividadeSimulada = produtividadeTotal / this.safraSelecionada.AreaTotal;
    this.safraSelecionada.PrecoVendaSimulado = precoTotal / this.safraSelecionada.AreaTotal;
    this.safraSelecionada.CustoPorHA = custoTotal / this.safraSelecionada.AreaTotal;
    this.safraSelecionada.LucroSimulado = lucroTotal / this.safraSelecionada.AreaTotal;
    this.safraSelecionada.LucroTotalSimulado = this.safraSelecionada.LucroSimulado * this.safraSelecionada.AreaTotal;
  }

  replicarProdutividade(planejamento: Planejamento){
    this.planejamentos.filter(p => p.CultivarId == planejamento.CultivarId).forEach(p => {
      p.ProdutividadeSimulada = planejamento.ProdutividadeSimulada;
      this.atualizaSimulacao(p);
    });

    this.calculaLucroSafraTotal();
  }

  replicarPrecoVenda(planejamento: Planejamento){
    this.planejamentos.filter(p => p.CultivarId == planejamento.CultivarId).forEach(p => {
      p.PrecoVendaSimulado = planejamento.PrecoVendaSimulado;
      this.atualizaSimulacao(p);
    });

    this.calculaLucroSafraTotal();
  }

  configurar(planejamento: Planejamento) {
    if (!planejamento.PlanoMestreProducaoId){
      this.isSubmit = false;
      this.planejamentoConfigurado = planejamento;
      this.carregando = true;
      this.planejamentoService.getPlanosMestrePorCultura(this.safraSelecionada.CulturaId).subscribe(ret =>{
        this.planosMestre = ret;
        this.planosMestre = this.planosMestre.filter(p => p.CultivarId == planejamento.CultivarId && p.QtdAtividades > 0);
        this.carregando = false;
        this.ModalConfigurar.show();
      });
    } else
      this.router.navigate(['/planejamento/safra/planejamento/'+planejamento.Id]);
  }

  confirmarConfiguracao(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    this.carregando = true;
    this.planejamentoService.putPlanejamento(this.planejamentoConfigurado.Id, this.planejamentoConfigurado).subscribe(
      res => {
        this.ModalConfigurar.hide();
        this.carregando = false;

        this.router.navigate(['/planejamento/safra/planejamento/'+this.planejamentoConfigurado.Id]);
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  alterarEdicaoSafra(mostrar: boolean){
    this.mostraEdicaoSafra = mostrar;
    this.listarPlanejamentos();
  }

  changeDataPlantio(area: Planejamento, dataPrevisao: Date) {
    if (dataPrevisao) {
      area.DataRealPlantio = dataPrevisao;
      area.FlagPlantou = true;
      this.ConfirmarPlantio(area);
    }
  }

  ConfirmarPlantio(area: Planejamento){
    this.configurarTraducao();
    setTimeout(() => {
      if(area.FlagPlantou)
      this.planejamentoService.putPlanejamento(area.Id, area).subscribe(
        ret => {
          this.toasty.show(this.FEITO, this.PLANTIO_ENCERRADO, 'success');
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

    }, 300);
  }

  IniciarSafra(){
    this.configurarTraducao();
    if(!this.safraSelecionada)
    {
      this.toasty.show(this.ATENCAO, this.NENHUMA_SAFRA_SELECIONADA, 'warning');
      return;
    }

    if(this.safraSelecionada.Status && this.safraSelecionada.Status != EnumStatusSafra.NaoIniciada){
      this.toasty.show(this.ATENCAO, this.SAFRA_JA_INICIADA, 'warning');
      return;
    }

    Swal.fire({
      title: this.INICAR_SAFRA,
      text: this.AO_INICIAR_A_SAFRA_TODAS_AS_ORDENS_DE_SERVICO_SERAO_GERADAS_BASEADO_NOS_DIAS_DE_REFERENCIA_E_NA_PREVISAO_DE_PLANTIO_DESEJA_CONTINUAR,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (!willDelete.dismiss) {
          this.safraSelecionada.Status = EnumStatusSafra.EmAndamento;
          this.safraService.putSafra(this.safraSelecionada.Id, this.safraSelecionada).subscribe(
            res => {
              this.ordemServicoService.gerarOrdemServicoPorSafra(this.safraSelecionada.Id).subscribe(
              ret => {
                this.carregando = true;
                this.toasty.show(this.ORDENS_DE_SERVICO_GERADAS_COM_SUCESSO,this.TODAS_AS_OS_DESSA_SAFRA_FORAM_GERADAS, 'success');
                this.selecionarSafra(this.safraSelecionada);
                setTimeout(() => {
                  this.RedirecionarOS();
                }, 2000);
              },
              err => {
                this.utils.getErro(err);
                this.carregando = false;
              });
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
    });
  }

  RedirecionarOS(){
    this.configurarTraducao();
    Swal.fire({
      title: this.ACESSAR_ORDENS_DE_SERVICO,
      text: this.VOCE_SERA_REDIRECIONADO_PARA_A_TELA_DE_ORDENS_DE_SERVICO_,
      type: 'success',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.NAO,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (!willDelete.dismiss) {
          this.router.navigate(['/ordem-servico/detalhamento/safra/'+this.safraSelecionada.Id]);
        }
    });
  }

  configurarTraducao() {
    this.SIM = this.translate.instant('SIM');
    this.NAO = this.translate.instant('NAO');
    this.VOCE_SERA_REDIRECIONADO_PARA_A_TELA_DE_ORDENS_DE_SERVICO_ = this.translate.instant('VOCE_SERA_REDIRECIONADO_PARA_A_TELA_DE_ORDENS_DE_SERVICO_');
    this.ACESSAR_ORDENS_DE_SERVICO = this.translate.instant('ACESSAR_ORDENS_DE_SERVICO');
    this.ORDENS_DE_SERVICO_GERADAS_COM_SUCESSO = this.translate.instant('ORDENS_DE_SERVICO_GERADAS_COM_SUCESSO');
    this.TODAS_AS_ORDENS_DE_SERVICO_DESSA_SAFRA_FORAM_GERADAS = this.translate.instant('TODAS_AS_ORDENS_DE_SERVICO_DESSA_SAFRA_FORAM_GERADAS');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.AO_INICIAR_A_SAFRA_TODAS_AS_ORDENS_DE_SERVICO_SERAO_GERADAS_BASEADO_NOS_DIAS_DE_REFERENCIA_E_NA_PREVISAO_DE_PLANTIO_DESEJA_CONTINUAR = this.translate.instant('AO_INICIAR_A_SAFRA_TODAS_AS_ORDENS_DE_SERVICO_SERAO_GERADAS_BASEADO_NOS_DIAS_DE_REFERENCIA_E_NA_PREVISAO_DE_PLANTIO_DESEJA_CONTINUAR');
    this.INICAR_SAFRA = this.translate.instant('INICAR_SAFRA');
    this.SAFRA_JA_INICIADA = this.translate.instant('SAFRA_JA_INICIADA');
    this.ATENCAO = this.translate.instant('ATENCAO');
    this.NENHUMA_SAFRA_SELECIONADA = this.translate.instant('NENHUMA_SAFRA_SELECIONADA');
    this.PLANTIO_ENCERRADO = this.translate.instant('PLANTIO_ENCERRADO');
    this.FEITO = this.translate.instant('FEITO');
    this.OS_EXCLUIDAS_COM_SUCESSO = this.translate.instant('OS_EXCLUIDAS_COM_SUCESSO');
    this.TODAS_AS_OS_DESSA_SAFRA_FORAM_EXCLUIDAS = this.translate.instant('TODAS_AS_OS_DESSA_SAFRA_FORAM_EXCLUIDAS');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.DELETAR_TODAS_OS_DESSA_SAFRA = this.translate.instant('DELETAR_TODAS_OS_DESSA_SAFRA');
    this.OS_GERADAS_COM_SUCESSO = this.translate.instant('OS_GERADAS_COM_SUCESSO');
    this.TODAS_AS_OS_DESSA_SAFRA_FORAM_GERADAS = this.translate.instant('TODAS_AS_OS_DESSA_SAFRA_FORAM_GERADAS');
    this.ESSA_ACAO_PODERA_LEVAR_UM_TEMPO_E_SUBSTITUIR_AS_OS_JA_EXISTENTES = this.translate.instant('ESSA_ACAO_PODERA_LEVAR_UM_TEMPO_E_SUBSTITUIR_AS_OS_JA_EXISTENTES');
    this.GERAR_TODAS_OS_DESSA_SAFRA = this.translate.instant('GERAR_TODAS_OS_DESSA_SAFRA');
    this.SEQUEIRO = this.translate.instant('SEQUEIRO');
    this.IRRIGADO = this.translate.instant('IRRIGADO');
    this.POR_TIPO_DE_AREA = this.translate.instant('POR_TIPO_DE_AREA');
    this.POR_AREA = this.translate.instant('POR_AREA');
    this.NAO_CONFIGURADOS = this.translate.instant('NAO_CONFIGURADOS');
    this.CONFIGURADOS = this.translate.instant('CONFIGURADOS');
    this.POR_STATUS = this.translate.instant('POR_STATUS');
}
}
