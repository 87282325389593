import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ItemContaEnergia } from '../classes/ItemContaEnergia';

@Injectable({
  providedIn: 'root'
})
export class ItemContaEnergiaService {  

  constructor(private http: HttpClient) { }   

  getItensContaEnergia(): Observable<any>{
    return this.http.get(`${environment.apiURL}/itenscontaenergia`)
  }

  getItemContaEnergia(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itenscontaenergia/${id}`)
  }

  postItemContaEnergia(itemContaEnergia: ItemContaEnergia): Observable<any>{
    return this.http.post(`${environment.apiURL}/itenscontaenergia`, itemContaEnergia);
  }

  putItemContaEnergia(id: number, itemContaEnergia: ItemContaEnergia): Observable<any>{
    return this.http.put(`${environment.apiURL}/itenscontaenergia/${id}`, itemContaEnergia);
  }

  deleteItemContaEnergia(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/itenscontaenergia/${id}`);
  }

  getitensContaEnergiaPorContaEnergia(idConta: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/itenscontaenergia/contaenergia/${idConta}`)
  }
}
