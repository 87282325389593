import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PendenciaFrota } from 'src/app/classes/PendenciaFrota';
import { ItemManutencaoFrota } from 'src/app/classes/gestao-lite/ItemManutencaoFrota';
import { ManutencaoFrota } from 'src/app/classes/gestao-lite/ManutencaoFrota';
import { ItemChecklistManutencaoFrotaService } from 'src/app/services/item-checklist-manutencao-frota.service';
import { PendenciaFrotaService } from 'src/app/services/pendencia-frota.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-modal-confirmacao-pendencias',
  templateUrl: './modal-confirmacao-pendencias.component.html',
  styleUrls: ['./modal-confirmacao-pendencias.component.scss']
})
export class ModalConfirmacaoPendenciasComponent implements OnInit {
  @ViewChild('ModalConfirmacao', { static: true }) modalConfirmacao;


  @Input() itemId: number = 0;
  @Input() manutencaoId: number = 0;
  @Input() tipo: number = 0;
  // tipos
  // 1 - Pendencia
  // 2  - Checklist

  carregando = false;
  produtoId: number = 0;
  observacao: string;
  vincularProduto = false;
  constructor(
    private pendenciaFortaService: PendenciaFrotaService,
    private itemChecklistManutencaoFrotaService: ItemChecklistManutencaoFrotaService,
    private utils: UtilsService) { }

  ngOnInit() {
  }
  abrirModal(manutencaoId: number, itemId: number, tipo: number): void {
    this.manutencaoId = manutencaoId;
    this.itemId = itemId;
    this.tipo = tipo;
    this.modalConfirmacao.show();
  }
  cancelar(): void {
    this.modalConfirmacao.hide();
  }
  finalizar(): void {
    this.carregando = true;
    var obj = {
      Id: this.itemId,
      Descricao: this.observacao,
      ManutencaoFrotaId: this.manutencaoId,
      ProdutoId: this.produtoId,
      Quantidade: 0
    };

    console.debug('data: ', obj);
    switch (this.tipo) {
      case 1:
        this.finalizarPendencia(obj);
        break;
      case 2:
        this.finalizarChecklist(obj);
        break;
    }
  }
  finalizarPendencia(obj: any) {
    this.pendenciaFortaService.finalizar(obj)
      .subscribe(res => {
        this.utils.handleSuccess("Pendencia finalizada com sucesso");
        this.carregando = false;
      }, err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }
  finalizarChecklist(obj: any) {
    console.debug("entrou finalizar")
    this.itemChecklistManutencaoFrotaService.finalizar(obj)
      .subscribe(res => {
        this.utils.handleSuccess("Checklist finalizado com sucesso");
        this.carregando = false;
      }, err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }
}
