import { TiposArmazem, TiposEstoque, enumTipoArmazem, enumTipoEstoque } from './../../../classes/LocalEstoque';
import { Component, Input, OnInit } from '@angular/core';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { Fazenda } from 'src/app/classes/Fazenda';
import { FazendaService } from 'src/app/services/fazenda.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-local-estoque',
  templateUrl: './local-estoque.component.html',
  styleUrls: ['./local-estoque.component.scss']
})
export class LocalEstoqueComponent implements OnInit {

  @Input() IsNovo: boolean = false;


  locaisEstoques: LocalEstoque[];
  auxLocalEstoques: LocalEstoque[];
  localEstoque: LocalEstoque;
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";

  perfilLogado: number = 0;

  fazendas: Fazenda[];
  integraERP: boolean = false;
  integraTotvs: boolean = false;
  tiposEstoque = TiposEstoque;
  tiposArmazem = TiposArmazem;
  tipoArmazem = enumTipoEstoque.Armazem;

  // translate
  LOCAL_DE_ESTOQUE_ALTERADO: string = 'Local de Estoque alterado!';
  LOCAL_DE_ESTOQUE_CADASTRADO: string = 'Local de Estoque cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  SIM: string = 'Sim';
  CANCELAR: string = 'Cancelar';
  FEITO: string = 'Feito!';
  LOCAL_DE_ESTOQUE_DELETADO: string = 'Local de Estoque deletado!';

  constructor(private localEstoquesService: LocalEstoqueService, 
    private fazendaService: FazendaService,
    private route: ActivatedRoute, 
    private router: Router, 
    private storageService: StorageService,
    private utils: UtilsService,
    private translate: TranslateService,
    private toasty: CustomToastyService,) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    if (this.IsNovo) {
      this.inserindo = true;
    }
    this.localEstoque = new LocalEstoque();
    let empresa = this.storageService.getEmpresa();
    if (empresa != null) {
      this.integraERP = empresa.ErpIntegrado > enumErpIntegrado.Nenhum;
      this.integraTotvs = empresa.ErpIntegrado == enumErpIntegrado.Totvs_RM;
    }

    this.fazendaService.getFazendas().subscribe(ret => {
      this.fazendas = ret;
    });
    

    this.listarLocalEstoques();
  }

  configureTraducao() {
    this.LOCAL_DE_ESTOQUE_ALTERADO = this.translate.instant('LOCAL_DE_ESTOQUE_ALTERADO');
    this.LOCAL_DE_ESTOQUE_CADASTRADO = this.translate.instant('LOCAL_DE_ESTOQUE_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.SIM = this.translate.instant('SIM');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.FEITO = this.translate.instant('FEITO');
    this.LOCAL_DE_ESTOQUE_DELETADO = this.translate.instant('LOCAL_DE_ESTOQUE_DELETADO');
  }


  listarLocalEstoques() {
    this.carregando = true;
    this.localEstoquesService.getLocaisEstoque().subscribe(
      res => {
        this.locaisEstoques = res;
        this.auxLocalEstoques = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarLocalEstoques();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  excluir(grupooperacaoId: number, nome: string) {
    this.configureTraducao();
    Swal.fire({
      title: this.DELETAR + ' ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.localEstoquesService.deleteLocalEstoque(grupooperacaoId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.LOCAL_DE_ESTOQUE_DELETADO, 'success');
            this.listarLocalEstoques();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  filtrarLocalEstoques() {
    this.locaisEstoques = this.auxLocalEstoques.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
    );
  }

  cancelar() {
    this.router.navigate(['/estoques/local-estoque']);
  }

  getFazenda(id: number): Fazenda {
    let fazenda = this.fazendas.filter(x =>
      x.Id == id
    );

    return fazenda.length > 0 ? fazenda[0] : new Fazenda();
  }
  
  getTipoEstoque(id: number): string {
    let tipo = this.tiposEstoque.filter(x =>
      x.value == id
    );

    return tipo.length > 0 ? tipo[0].name : "";
  }
  /*
    getTipoArmazem(id: number) : string {
      let tipo = this.tiposArmazem.filter(x =>
        x.value == id
      );
  
      return tipo.length > 0 ? tipo[0].name : "";
    }
    */

}
