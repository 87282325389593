import { GrupoOperacaoService } from './../../../../services/grupo-operacao.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Planejamento } from 'src/app/classes/Planejamento';
import { Cultivar } from 'src/app/classes/Cultivar';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { CultivarService } from 'src/app/services/cultivar.service';
import Swal from 'sweetalert2';
import { Etapa } from 'src/app/classes/Etapa';
import { EtapaService } from 'src/app/services/etapa.service';
import { Atividade } from 'src/app/classes/Atividade';
import { EstadioFenologico } from 'src/app/classes/EstadioFenologico';
import { AtividadeService } from 'src/app/services/atividade.service';
import { EstadioFenologicoService } from 'src/app/services/estadio-fenologico.service';
import { TipoAplicacao } from 'src/app/classes/TipoAplicacao';
import { TipoAplicacaoService } from 'src/app/services/tipo-aplicacao.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AtividadePlanejamentoService } from 'src/app/services/atividade-planejamento.service';
import { AtividadePlanejamento } from 'src/app/classes/AtividadePlanejamento';
import { Produto } from 'src/app/classes/Produto';
import { ItensAtividade } from 'src/app/classes/ItensAtividade';
import { ProdutoService } from 'src/app/services/produto.service';
import { ItensAtividadeService } from 'src/app/services/itens-atividade.service';
import { Safra } from 'src/app/classes/Safra';
import { SafraService } from 'src/app/services/safra.service';
import { Area } from 'src/app/classes/Area';
import { AreaService } from 'src/app/services/area.service';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { Cultura } from 'src/app/classes/Cultura';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';

@Component({
  selector: 'app-detalhe',
  templateUrl: './detalhe.component.html',
  styleUrls: ['./detalhe.component.scss']
})
export class PlanoMestreProducaoDetalheComponent implements OnInit {
  @ViewChild('ModalPlanejamento', { static: false })
  ModalPlanejamento: UiModalComponent;

  planejamento: Planejamento;
  cultivar: Cultivar;
  id:number;
  cultivarId:number;
  inserindo: boolean = false;
  carregando: boolean = false;
  carregandoEtapas: boolean = false;
  visualizacaoPorEtapa: boolean = false;

  etapas: Etapa[];
  etapaSelecionada: Etapa;
  atividades: Atividade[];
  estadiosFenologicos: EstadioFenologico[];
  tiposAplicacoes: TipoAplicacao[];
  atividadesPlanejamento: AtividadePlanejamento[];
  produtos: Produto[];
  sementes: Produto[];
  servicos: Produto[];
  itensAtividades: ItensAtividade[];
  processosPlanejamento: ProcessoPlanejamento[];

  public custoTotalEtapa: number = 0;
  public custoTotalPlanejamento: number = 0;

  safraSelecionada: Safra;
  areaSelecionada: Area;

  filtroOperacaoAtivo: string = "Por operação";
  filtroOperacao: number = 0;
  gruposOperacao: GrupoOperacao[];
  cultura: Cultura;

  form: any;
  isSubmit: boolean;

  quantidadeAplicacaoPlanejamento: number = 0;

  constructor(
      private planejamentoService: PlanejamentoService,
      private cultivarService: CultivarService,
      private etapaService: EtapaService,
      private atividadeService: AtividadeService,
      private estadioFenologicoService: EstadioFenologicoService,
      private tipoAplicacaoService: TipoAplicacaoService,
      private atividadePlanejamentoService: AtividadePlanejamentoService,
      private produtoService: ProdutoService,
      private itensAtividadeService: ItensAtividadeService,
      private safraService: SafraService,
      private areaService: AreaService,
      private grupoOperacaoService: GrupoOperacaoService,
      private ordemServicoService: OrdemServicoService,
      private route: ActivatedRoute,
      private router: Router,
      private processoPlanejamentoService: ProcessoPlanejamentoService,
      private utils: UtilsService
    ) {
    this.route.params.subscribe(res => {
      this.id = res.id;
      this.cultivarId = res.cultivarId;
    });
  }

  ngOnInit() {
    this.carregandoEtapas = true;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.planejamento = new Planejamento();
    this.planejamento.Padrao = true;
    this.planejamento.CultivarId = this.cultivarId;

    if(this.id > 0)
      this.selecionarPlanejamento();
    else
      this.selecionarCultivar();

    this.atividadePlanejamentoService.getQuantidadeAplicacaoPlanejada(this.id).subscribe(ret => {
      this.quantidadeAplicacaoPlanejamento = ret;
    });

    this.atividadeService.getAtividadesParaUso().subscribe(ret =>{
      this.atividades = ret;
    });

    this.tipoAplicacaoService.getTiposAplicacao().subscribe(ret =>{
      this.tiposAplicacoes = ret;
    });

    this.produtoService.getProdutosPaginandoSemCultivar((this.id ? this.id : 0), 1, '', false, false).subscribe(ret =>{
      this.produtos = ret.Lista;
    });

    this.produtoService.getProdutosPaginando((this.id ? this.id : 0), 1, '', false, true).subscribe(ret =>{
      this.sementes = ret.Lista;
    });

    this.produtoService.getProdutosPaginando((this.id ? this.id : 0), 1, '', true, false).subscribe(ret =>{
      this.servicos = ret.Lista;
    });

    this.grupoOperacaoService.getGruposOperacaoEmUso().subscribe(ret =>{
      this.gruposOperacao = ret;
    });

    this.processoPlanejamentoService.getProcessosPlanejamento().subscribe(ret =>{
      this.processosPlanejamento = ret;
    });
  }

  ngOnChanges(){
    this.carregandoEtapas = true;
    this.listarEtapas();
  }

  insereEtapas(etapa: Etapa){
    this.etapaSelecionada = etapa;
    this.carregandoEtapas = true;
    this.listarEtapas();
  }

  listarEtapas(){
    this.etapaService.getEtapas().subscribe(ret =>{
      let etapas: Etapa[] = ret;

      if (this.id > 0){
        //this.etapas = etapas.filter(x=> x.PlanejamentoId == this.id);
        this.atividadePlanejamentoService.getAtividadesPorPlanejamentos(this.id).subscribe(ret =>{
          let atividadesPlanejamento = ret;
          this.atividadesPlanejamento = [];

          atividadesPlanejamento.forEach(ativ => {
            let adicionar = true;
            if (this.filtroOperacao > 0) {
              let atividade = this.atividades.filter(a => a.Id == ativ.AtividadeId);

              if (atividade.length > 0 && atividade[0].GrupoOperacaoId != this.filtroOperacao) {
                adicionar = false;
              }
            }

            if (adicionar) {
              this.atividadesPlanejamento.push(ativ);

              let etapa = etapas.filter(e => e.Id == ativ.EtapaId)[0];
              etapa.Collapsed = true;
              etapa.ProcessoPlanejamentoId = ativ.ProcessoPlanejamentoId;

              if (etapa.DiasAposPlantio && etapa.DiasAposPlantio != ativ.DiasAposPlantio){
                let novaEtapa = new Etapa();
                novaEtapa.Id = etapa.Id;
                novaEtapa.Descricao = etapa.Descricao;
                novaEtapa.ProcessoPlanejamentoId = etapa.ProcessoPlanejamentoId;
                etapas.push(novaEtapa);
                etapa = novaEtapa;
              }

              if (!etapa.DiasAposPlantio) {
                etapa.DiasAposPlantio = ativ.DiasAposPlantio;

                if (ativ.EstadioFenologicoId == null) {
                  etapa.EstadioFenologico = new EstadioFenologico();
                  etapa.EstadioFenologico.Descricao = "-";
                } else
                  etapa.EstadioFenologico = this.estadiosFenologicos.filter(x => x.Id == ativ.EstadioFenologicoId)[0];
              }
            }
          });

          this.etapas = etapas;
          this.carregandoEtapas = false;
        });

        this.itensAtividadeService.getItensPorPlanejamento(this.id).subscribe(ret =>{
          this.itensAtividades = ret;
        });
      } else {
        this.etapas = etapas;
        this.carregandoEtapas = false;
      }
    });
  }

  selecionarCultivar(){
    this.cultivarService.getCultivar(this.cultivarId).subscribe(ret =>{
      this.cultivar = ret;

      if (!this.id) {
        this.planejamento.Descricao = this.cultivar.Descricao;

        this.planejamentoService.postPlanejamento(this.planejamento).subscribe(
          res => {
            this.planejamento = res;
            this.router.navigate(['/planejamento/plano-mestre-producao/'+ res.Id]);
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }

      this.estadioFenologicoService.getEstadiosFenologicos().subscribe(ret =>{
        this.estadiosFenologicos = []
        let estadioFen = new EstadioFenologico();
        estadioFen.Id = -1;
        estadioFen.Descricao = "-";
        this.estadiosFenologicos.push(estadioFen);
        if (ret)
          ret.forEach(estadio => {
            if (estadio.CulturaId == this.cultivar.CulturaId)
            this.estadiosFenologicos.push(estadio);
          });

        this.listarEtapas();
      });
    });
  }

  selecionarPlanejamento() {
    this.carregando = true;
    this.planejamentoService.getPlanejamento(this.id).subscribe(res =>{
      this.planejamento = res;

      this.cultivarId = this.planejamento.CultivarId;
      this.selecionarCultivar();

      if (this.planejamento.SafraId) {
        this.safraService.getSafra(this.planejamento.SafraId).subscribe(ret =>{
          this.safraSelecionada = ret;
          this.carregando = false;
        });

        this.areaService.getArea(this.planejamento.AreaId).subscribe(ret =>{
          this.areaSelecionada = ret;
          this.carregando = false;
        });
      } else
        this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  editarPlanejamento(){
    this.isSubmit = false;
    this.ModalPlanejamento.show();
  }

  salvarPlanejamento(form: any){
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    this.carregando = true;
    if(this.planejamento.Id > 0)
      this.planejamentoService.putPlanejamento(this.planejamento.Id, this.planejamento).subscribe(
        res => {
          this.carregando = false;
          this.ModalPlanejamento.hide();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.planejamentoService.postPlanejamento(this.planejamento).subscribe(
        res => {
          this.carregando = false;
          this.ModalPlanejamento.hide();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  toogleVisualizacao() {
    this.visualizacaoPorEtapa = !this.visualizacaoPorEtapa;
  }

  filtrarOperacao(grupoId: number) {
    this.filtroOperacaoAtivo = 'Por operação';

    if (grupoId > 0)
      this.filtroOperacaoAtivo = this.gruposOperacao.filter(g => g.Id == grupoId)[0].Descricao;

    this.filtroOperacao = grupoId;

    this.listarEtapas();
  }

  incluirAtividadePadrao(atividadeSalva: AtividadePlanejamento){
    if(!this.atividadesPlanejamento)
      this.atividadesPlanejamento = [];
    this.atividadesPlanejamento.push(atividadeSalva);
  }

  excluirEtapa(etapa: Etapa){
    Swal.fire({
      title: 'Deletar etapa ' + etapa.Descricao+ '?',
      text: 'Todas as atividades desta etapa serão deletadas!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (!willDelete.dismiss) {
          this.carregando = true;
          this.etapaService.deleteCompleto(etapa.Id, this.planejamento.Id).subscribe(
            res => {
              this.utils.handleSuccess('Etapa excluída com sucesso!');
              this.listarEtapas();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.listarEtapas();
              this.carregando = false;
            });
        }
      });
  }
  gerarOS(){
    if(!this.planejamento.SafraId){
      this.utils.handleWarning('Este planejamento não é de uma safra!');
      return;
    }

    Swal.fire({
      title: 'Gerar Ordens de Serviço da área?',
      text: 'Esta opção vai gerar todas as Ordens de Serviço que ainda não foram geradas para essa área!',
      type: 'info',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (!willDelete.dismiss) {
          this.carregando = true;
          this.ordemServicoService.gerarOrdemServicoPorArea(this.planejamento.AreaId).subscribe(
            res => {
              this.utils.handleSuccess('Ordens de Serviço geradas com sucesso!');
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

}
