import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Funcionario } from 'src/app/classes/Funcionario';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-funcionario',
  templateUrl: './modal-funcionario.component.html',
  styleUrls: ['./modal-funcionario.component.scss']
})
export class ModalFuncionarioComponent implements OnInit {
  @Output() selecionaFuncionario: EventEmitter<number> = new EventEmitter();
  @Output() selecionaVarios: EventEmitter<number[]> = new EventEmitter();
  @Input() funcionarios: Funcionario[];
  @Output() fechou: EventEmitter<any> = new EventEmitter();

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  carregando: boolean = false;
    
  constructor(private funcionarioService: FuncionarioService) { }

  ngOnInit() {    
    this.funcionarioService.getFuncionarios().subscribe(ret => {
      this.funcionarios = ret;
      this.Modal.show();
    });
  }

  selecionar(id: number){   
    this.selecionaFuncionario.emit(id);
    this.fechar();
  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

  selecionarVarios(){
    let ids: number[] = [];
    this.funcionarios.forEach(f => {
      if(f.Checked)
        ids.push(f.Id);
    });

    this.selecionaVarios.emit(ids);    
    this.fechar();
  }

}
