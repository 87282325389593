import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { ProdutoService } from 'src/app/services/produto.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-modal-visualizar-estoque',
  templateUrl: './modal-visualizar-estoque.component.html',
  styleUrls: ['./modal-visualizar-estoque.component.scss']
})
export class ModalVisualizarEstoqueComponent implements OnInit {
  @ViewChild('ModalEstoque', { static: true }) modal;

  constructor(private produtoService: ProdutoService, private utils: UtilsService) { }
  data: any[] = [];
  coldefs: ColDef[] = [];
  ngOnInit() {
    this.coldefs = [
      {field: 'DescricaoLocalEstoque', headerName: 'Local Estoque', width: 250},
      {field: 'Quantidade', width: 250},
    ]
  }

  abrirModal(produtoId: number){
    this.produtoService.quantidadeEstoque(produtoId)
    .subscribe(res => {
      this.data = res;
      console.debug(res)
      this.modal.show();
    }, err => this.utils.getErro(err));
  }
  cancelar(): void {
    this.modal.hide();
  }

}
