import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { ItemMovimento } from 'src/app/classes/ItemMovimento';
import { Movimento } from 'src/app/classes/Movimento';
import { Produto } from 'src/app/classes/Produto';
import { ProdutoService } from 'src/app/services/produto.service';
import { MovimentoItemFormsComponent } from '../movimento-item-forms.component';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { Natureza } from 'src/app/classes/Natureza';
import { Projeto } from 'src/app/classes/Projeto';
import { CentroDeResultadosService } from 'src/app/services/centro-de-resultados.service';
import { ProjetoService } from 'src/app/services/projeto.service';
import { NaturezaService } from 'src/app/services/natureza.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-modal-item-adicional',
  templateUrl: './modal-item-adicional.component.html',
  styleUrls: ['./modal-item-adicional.component.scss']
})
export class ModalItemAdicionalComponent implements OnInit {

  @ViewChild('Modal', { static: true }) modal
  @ViewChild('itemFormComponent', { static: false }) itemFormComponent
  centrosResultados: CentroDeResultados[] = [];
  locaisEstoque: LocalEstoque[] = [];
  naturezas: Natureza[] = [];
  projetos: Projeto[] = [];
  @Input() produtos: Produto[] = [];
  @Input() gruposProduto: GrupoProduto[] = [];
  @Input() wizard: boolean;
  @Input() movimento: Movimento;
  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  termoBusca: String = '';
  pagina: number = 1;
  item = new ItemMovimento();

  itemSelecionado: ItemMovimento;

  constructor(
    private produtoService: ProdutoService,
    private centroDeResultadoService: CentroDeResultadosService,
    private projetoService: ProjetoService,
    private naturezaService: NaturezaService,
    private localEstoqueService: LocalEstoqueService,
    private utils: UtilsService
    ) { }

  ngOnInit() {
    this.listarCentroResultados();
    this.listarProjetos();
    this.listarNaturezas();
    this.listarLocalEstoque();
  }

  listarCentroResultados(){
    this.centroDeResultadoService.getCentrosDeResultados()
    .subscribe(res => {
      this.centrosResultados = res;
    }, err => this.utils.getErro(err));
  }
  listarProjetos(){
    this.projetoService.getList()
    .subscribe(res => {
      this.projetos = res;
    }, err => this.utils.getErro(err));
  }
  listarNaturezas(){
    this.naturezaService.getList()
    .subscribe(res => {
      this.naturezas = res;
    }, err => this.utils.getErro(err));
  }
  listarLocalEstoque() {
    this.localEstoqueService.getLocaisEstoque()
      .subscribe(res => {
        this.locaisEstoque = res;
      }, err => this.utils.getErro(err));
  }
  openModal(item){
    this.itemSelecionado = item;
    this.item = new ItemMovimento();
    this.item.MovimentoId = this.movimento.Id;
    this.item.ItemMovimentoPaiId = this.itemSelecionado.Id;
    this.modal.show();
  }
  closeModal(){
    this.modal.hide();
  }
  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore();
  }
  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;
      this.produtoService.getTodosProdutosPaginando(this.pagina, 10, 0, this.termoBusca).subscribe(ret => {

        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.produtos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      });
    }, 300);
  }

  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProduto.filter(x =>
      x.Id == id
    );
    let novo = new GrupoProduto();
    return grupos.length > 0 ? grupos[0] : novo;
  }
  salvar(){
    this.itemFormComponent.salvar();
  }

}
