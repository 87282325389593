import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ParametrosCompras } from '../classes/ParametrosCompras';

@Injectable({
  providedIn: 'root'
})
export class ParametrosComprasService {

  constructor(private http: HttpClient) { }

  getList(): Observable<any>{
    return this.http.get(`${environment.apiURL}/ParametrosCompras`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ParametrosCompras/${id}`)
  }

  post(parametrosCompras: ParametrosCompras): Observable<any>{
    return this.http.post(`${environment.apiURL}/ParametrosCompras`, parametrosCompras);
  }

  put(id: number, parametrosCompras: ParametrosCompras): Observable<any>{
    return this.http.put(`${environment.apiURL}/ParametrosCompras/${id}`, parametrosCompras);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/ParametrosCompras/${id}`);
  }
}
