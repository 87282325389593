export class ApontamentoIndicadores{
    Id: number;
    OrdemServicoId: number;
    CoeficienteVariacaoDispersao: number = 0;
    PesoApontado: number = 0;
    PesoReal: number = 0;
    VariacaoPeso: number = 0;
    Data:Date;
    DataCadastro:Date;
    DataAlteracao:Date;
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
    Temperatura:number = 0;
    Umidade:number = 0;
    VelocidadeVento:number = 0;
    PhCalda:number = 0;
}
