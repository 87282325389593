import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Equipamento } from 'src/app/classes/Equipamento';
import { Fazenda } from 'src/app/classes/Fazenda';
import { Funcionario } from 'src/app/classes/Funcionario';
import { ImprodutividadeEquipamento } from 'src/app/classes/ImprodutividadeEquipamento';
import { TipoImprodutividade } from 'src/app/classes/TipoImprodutividade';
import { EquipamentoApontamentoService } from 'src/app/services/equipamento-apontamento.service';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { FazendaService } from 'src/app/services/fazenda.service';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { TipoImprodutividadeService } from 'src/app/services/tipo-improdutividade.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AgendaAtividade, EnumStatusAgenda } from './../../../classes/AgendaAtividade';
import { Area } from './../../../classes/Area';
import { Atividade } from './../../../classes/Atividade';
import { EquipamentoApontamento } from './../../../classes/EquipamentoApontamento';
import { OrdemServico } from './../../../classes/OrdemServico';
import { AgendaAtividadeService } from './../../../services/agenda-atividade.service';
import { AreaService } from './../../../services/area.service';
import { AtividadeService } from './../../../services/atividade.service';
import { EmpresaService } from './../../../services/empresa.service';
import { OrdemServicoService } from './../../../services/ordem-servico.service';
import { PlanejamentoService } from './../../../services/planejamento.service';
import * as t from './agenda-atividade.translate';

@Component({
  selector: 'app-agenda-atividade',
  templateUrl: './agenda-atividade.component.html',
  styleUrls: ['./agenda-atividade.component.scss']
})
export class AgendaAtividadeComponent extends t.AgendaAtividadeTranslate implements OnInit{


  agenda: AgendaAtividade;
  agendasAtividade: AgendaAtividade[];
  auxAgendasAtividade: AgendaAtividade[];
  equipamentos: Equipamento[];
  fazendas: Fazenda[];
  funcionarios: Funcionario[];
  ordensServico: OrdemServico[] = [];
  atividades: Atividade[];
  areas: Area[];
  equipamentosApontamento: EquipamentoApontamento[];
  improdutividadesEquipamento: ImprodutividadeEquipamento[];
  tiposImprodutividade: TipoImprodutividade[];
  funcionarioId: number = 0;
  isSubmit: boolean;
  carregando: boolean;
  pesquisarOs: boolean;
  totalArea: number = 0;
  id:number;
  inserindo: boolean;
  apontar:boolean;
  apontamentoAvulso:boolean;
  inserindoApontamento: boolean = true;
  apontamentoSelecionado:EquipamentoApontamento;
  textoBusca: string;
  funcionarioFilter: number = 0;
  fazendaFiltro: number = 0;
  equipamentoFiltro: number = 0;
  statusFiltro: number = 1;
  dataInicioFiltro: Date;
  dataFimFiltro: Date;

  @ViewChild('ModalNovaAtividade', { static: false })
  ModalNovaAtividade: UiModalComponent;
  auxAgenda: AgendaAtividade = new AgendaAtividade();
  auxOrdensServico: OrdemServico[] = [];
  auxTotalArea: number = 0;

  fazendaDescricao: string =this.TODAS;
  operadorDescricao: string = this.TODOS;
  equipamentoDescricao: string = this.TODOS;
  statusDescricao: string = this.ABERTO;
  periodoDescricao: string = this.TODOS;

  constructor(private agendaAtividadeService: AgendaAtividadeService,
              private funcionarioService: FuncionarioService,
              private equipamentoService: EquipamentoService,
              private fazendaService: FazendaService,
              private atividadeService:AtividadeService,
              private toasty: CustomToastyService,
              private route: ActivatedRoute,
              private router: Router,
              private http: HttpClient,
              private ordemServicoService: OrdemServicoService,
              private areaService: AreaService,
              private planejamentoService: PlanejamentoService,
              private empresaService: EmpresaService,
              private equipamentoApontamentoService: EquipamentoApontamentoService,
              private tipoImprodutividadeService: TipoImprodutividadeService,
              private utils: UtilsService,
              private translate: TranslateService) {
                super(translate)
                this.route.params.subscribe(res => {
                  this.id = res.id;
                });
              }

  ngOnInit() {
    this.configurarTraducao();
    this.inserindo = this.router.url.search("inserir") > 0;
    this.pesquisarOs = false;
    this.totalArea = 0;
    this.ordensServico = [];
    this.agenda = new AgendaAtividade();
    this.agenda.Data = new Date();
    this.agenda.Status = EnumStatusAgenda.Aberta;
    this.fazendaService.getFazendas().subscribe(ret => {
      this.fazendas = ret;
    },
    err => {
      this.utils.getErro(err);
    })
    this.funcionarioService.getFuncionarios().subscribe(ret =>{
      this.funcionarios = ret;
    });

    this.equipamentoService.getEquipamentos().subscribe(ret =>{
      this.equipamentos = ret;
    })

    this.atividadeService.getAtividades().subscribe(ret =>{
      this.atividades = ret;
    });

    this.areaService.getAreas().subscribe(ret =>{
      this.areas = ret;
    })

    this.tipoImprodutividadeService.getTiposImprodutividade().subscribe(ret => {
      this.tiposImprodutividade = ret;
    });

    this.listarAgendas();

    if(this.id > 0)
      this.selecionarAgenda(this.id);
  }

  configurarTraducao() {

  }
  selecionarAgenda(id: number){
    this.carregando = true;
    this.totalArea = 0;
    this.agendaAtividadeService.getAgendaAtividade(id).subscribe(
      ret=>{
        this.agenda = ret;
        this.ordensServico = [];
        let idsOs = this.agenda.OrdensServicoId.split(',');
        for (let i = 0; i < idsOs.length; i++) {
          let Osid = idsOs[i];
          if(Osid && Osid !== "" && Osid !== "0"){
            this.ordemServicoService.getOrdemServico(parseInt(Osid)).subscribe(retOs=>{
              if(retOs.PlanejamentoId)
                this.planejamentoService.getPlanejamento(retOs.PlanejamentoId).subscribe(retP =>{
                  retOs.AreaId = retP.AreaId;
                  this.ordensServico.push(retOs);
                  this.totalArea += retOs.AreaPrevista;
                })
              else{
                this.ordensServico.push(retOs);
                this.totalArea += retOs.AreaPrevista;
              }
            },
            erroos =>{
              Swal.fire(this.ERRO_OS, erroos.error.ExceptionMessage, 'error');
              this.carregando = false;
            })
          }
        }
        this.carregarApontamentos();
        this.carregando = false;
      },
      err =>{
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarAgendas(){
    this.carregando = true;
    this.agendaAtividadeService.getAgendasAtividade().subscribe(
      ret=>{
        this.agendasAtividade = ret;
        this.auxAgendasAtividade = this.agendasAtividade;
        this.filtrarAgendas();
        this.carregando = false;
      })
  }

  incluirOs(){
    if(this.agenda.FazendaId)
      this.pesquisarOs = true;
    else
      this.toasty.show(this.ATENCAO, this.SELECIONE_UMA_FAZENDA, 'warning');
  }

  selecionarOs(osSelecionadas: OrdemServico[]){

    this.carregando = true;
    osSelecionadas.forEach(x => {
      this.agendaAtividadeService.selecionaPorOs(x.Id).subscribe(ret=>{
      this.ORDEM_DE_SERVICO__JA_INSERIDA_EM_OUTRO_PLANEJAMENTO = this.translate.instant("ORDEM_DE_SERVICO__JA_INSERIDA_EM_OUTRO_PLANEJAMENTO", { value: x.Id.toString().padStart(5, '0') });
      Swal.fire(this.ATENCAO, this.ORDEM_DE_SERVICO__JA_INSERIDA_EM_OUTRO_PLANEJAMENTO, 'warning');
        this.carregando = false;
        return;
      },
      err =>{
        this.ordensServico.push(x);
        this.totalArea += x.AreaPrevista;
        this.carregando = false;
      })
    });
  }

  getAtividade(id: number) : Atividade {
    if (!this.atividades)
      return new Atividade();

    let atividades = this.atividades.filter(x =>
      x.Id==id
    );
    return atividades.length > 0 ? atividades[0] : new Atividade();
  }

  getArea(id: number): Area{
    if(!this.areas)
      return new Area();
    return this.areas.find(x=> x.Id == id);
  }

  getFazenda(id: number): Fazenda{
    if(!this.fazendas)
      return new Fazenda();
    return this.fazendas.find(x=> x.Id == id);
  }

  getOperador(id: number): Funcionario{
    if(!this.funcionarios)
      return new Funcionario();
    return this.funcionarios.find(x => x.Id == id);
  }

  getEquipamento(id: number): Equipamento{
    if(!this.equipamentos)
      return new Equipamento();
    return this.equipamentos.find(x => x.Id == id);
  }

  excluirOs(os: OrdemServico){
    this.totalArea -= os.AreaPrevista;
    this.ordensServico.splice(this.ordensServico.indexOf(os), 1);
  }

  salvar(form: any){
    if ((form && !form.valid) ||
        this.agenda.Data == null || this.ordensServico.length == 0) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.agenda.OrdensServicoId = ",";
    this.ordensServico.forEach(o=>{
      this.agenda.OrdensServicoId += o.Id + ",";
    })

    if(this.agenda.Id > 0)
      this.agendaAtividadeService.putAgendaAtividade(this.agenda.Id,this.agenda).subscribe(
        ret =>{
          this.toasty.show(this.FEITO, this.AGENDA_ALTERADA, 'success');
          this.carregando = false;
        },
        err =>{
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else{
      this.agenda.Status = EnumStatusAgenda.Aberta;
      this.agendaAtividadeService.postAgendaAtividade(this.agenda).subscribe(
        ret =>{
          this.agenda = ret;
          this.toasty.show(this.FEITO, this.AGENDA_INCLUIDA, 'success');
          this.carregando = false;
        },
        err =>{
          Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
          this.carregando = false;
        })
      }
  }

  concluirAgenda(){

    Swal.fire({
      title: this.GERAR_NOVO_PLANEJAMENTO,
      text: this.ESTE_PLANEJAMENTO_NAO_FOI_TOTALMENTE_CONCLUIDO_DESEJA_GERAR_UM_NOVO_PLANEJAMENTO_COM_AS_AREAS_RESTANTES,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.APENAS_CONFIRMAR,
      confirmButtonText: this.NOVO_PLANEJAMENTO,
    }).then((willDelete) => {
        if (willDelete.dismiss) {
          this.carregando = true;
          this.agenda.Status = EnumStatusAgenda.Concluida;
          this.agendaAtividadeService.putAgendaAtividade(this.agenda.Id,this.agenda).subscribe(
            ret =>{
              this.toasty.show(this.FEITO, this.PLANEJAMENTO_CONCLUIDA, 'success');
              this.carregando = false;
            },
            err =>{
              Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
              this.carregando = false;
            });
        } else {
          this.carregando = true;
          this.auxOrdensServico = [];
          this.auxTotalArea = 0;
          this.auxAgenda = new AgendaAtividade();
          this.auxAgenda.EquipamentoId = this.agenda.EquipamentoId;
          this.auxAgenda.FazendaId = this.agenda.FazendaId;
          this.auxAgenda.FuncionarioId = this.agenda.FuncionarioId;
          this.auxAgenda.Data = new Date();

          this.ordensServico.forEach(x=>{
            this.equipamentoApontamentoService.AreaApontadaPorOs(x.Id).subscribe(ret=>{
              if(ret < x.AreaPrevista){
                let novaOs: OrdemServico = new OrdemServico();
                x.AreaPrevista = x.AreaPrevista - ret;
                this.auxTotalArea += x.AreaPrevista;
                this.auxOrdensServico.push(x);
              }
            })
          })
          this.carregando = false;
          this.ModalNovaAtividade.show();


        }
      });
  }

  excluir(){
    Swal.fire({
      title: this.DELETAR_PLANEJAMENTO,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.agendaAtividadeService.deleteAgendaAtividade(this.agenda.Id).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.PLANEJAMENTO_DELETADO, 'success');
              this.carregando = false;
              this.cancelar();
            },
            err => {
              Swal.fire(this.ERRO, err.message, 'error');
              this.carregando = false;
            });
        }
      });

  }

  filtrarAgendas(){
    this.agendasAtividade = this.auxAgendasAtividade.filter(x =>
      (!this.funcionarioFilter || this.funcionarioFilter == 0 || x.FuncionarioId == this.funcionarioFilter) &&
      (!this.fazendaFiltro || this.fazendaFiltro == 0 || x.FazendaId == this.fazendaFiltro) &&
      (!this.equipamentoFiltro || this.equipamentoFiltro == 0 || x.EquipamentoId == this.equipamentoFiltro) &&
      (!this.statusFiltro || this.statusFiltro == 0 || x.Status == this.statusFiltro) &&
      (!this.dataInicioFiltro || this.dataInicioFiltro == null || new Date(`${x.Data}`) >= this.dataInicioFiltro) &&
      (!this.dataFimFiltro || this.dataFimFiltro == null || new Date(`${x.Data}`) <= this.dataFimFiltro)
    )
    this.auxAgendasAtividade.map(a => console.log())
  }

  cancelar(){
    this.router.navigate(['/ordem-servico/agenda-atividade']);
  }

  imprimir(id: number){
    this.carregando = true;
    this.http.get(`${environment.apiURL}/agendaAtividade/gerarPDF/${this.empresaService.getEmpresaSelecionada().Id}/${id}`,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    });
  }

  apontarUso(isAvulso: boolean){
    this.apontamentoSelecionado = null;
    this.inserindoApontamento = true;
    this.apontar = true;
    this.apontamentoAvulso = isAvulso;
  }

  fecharUsoApontamento(){
    this.apontar = false;
    if(this.agenda && this.agenda.Id > 0)
      this.selecionarAgenda(this.agenda.Id);
    else
      this.listarAgendas();
  }

  carregarApontamentos(){
    this.equipamentosApontamento = [];
    this.improdutividadesEquipamento = [];
    this.equipamentoApontamentoService.getEquipamentosApontamento(this.agenda.Id).subscribe(ret => {
      this.equipamentosApontamento = ret.equipamentoApontamentos;
      this.improdutividadesEquipamento = ret.improdutividades;
    })
  }

  excluirApontamento(apontamentoId:number) {
    Swal.fire({
      title: 'Deletar apontamento #'+ apontamentoId + '?', //TODO Traduzir
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {
        } else {
          this.equipamentoApontamentoService.deleteEquipamentoApontamento(apontamentoId).subscribe(ret=>{
            this.utils.handleSuccess(this.APONTAMENTO_DELETADO);
            this.carregarApontamentos();
          },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

  cancelarNovaAtividade(){
    this.auxTotalArea = 0;
    this.auxAgenda = new AgendaAtividade();
    this.auxOrdensServico = [];
    this.ModalNovaAtividade.hide();
  }

  salvarNovaAtividade(form: any){
    if ((form && !form.valid) ||
        this.auxAgenda.Data == null || this.auxOrdensServico.length == 0) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.auxAgenda.OrdensServicoId = ",";
    this.auxOrdensServico.forEach(o=>{
      this.auxAgenda.OrdensServicoId += o.Id + ",";
    })

    this.auxAgenda.Status = EnumStatusAgenda.Aberta;
    this.auxAgenda.AgendaAtividadeOrigemId = this.agenda.Id;
    this.agendaAtividadeService.postAgendaAtividade(this.auxAgenda).subscribe(
      ret =>{
        this.auxAgenda = ret;
        this.toasty.show(this.FEITO, this.NOVO_PLANEJAMENTO_GERADO, 'success');

        this.agenda.Status = EnumStatusAgenda.Concluida;
        this.agendaAtividadeService.putAgendaAtividade(this.agenda.Id,this.agenda).subscribe(
          ret2 =>{
            this.toasty.show(this.FEITO, this.PLANEJAMENTO_CONCLUIDA, 'success');
            this.carregando = false;
            this.cancelarNovaAtividade();
          },
          err2 =>{
            Swal.fire(this.ERRO, err2.error.ExceptionMessage, 'error');
            this.carregando = false;
          });
        this.carregando = false;
      },
      err =>{
        Swal.fire(this.ERRO, err.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  selecionaFazenda(id:number){
    this.fazendaFiltro = id;
    if(id == 0)
      this.fazendaDescricao = this.TODAS
    else
      this.fazendaDescricao = this.fazendas.find(x => x.Id == this.fazendaFiltro).Nome;
    this.filtrarAgendas();
  }

  selecionaEquipamento(id:number){
    this.equipamentoFiltro = id;
    if(id == 0)
      this.equipamentoDescricao = this.TODOS
    else
      this.equipamentoDescricao = this.equipamentos.find(x => x.Id == this.equipamentoFiltro).Descricao;
    this.filtrarAgendas();
  }

  selecionaOperador(id:number){
    this.funcionarioFilter = id;
    if(id == 0)
      this.operadorDescricao = this.TODOS
    else
      this.operadorDescricao = this.funcionarios.find(x => x.Id == this.funcionarioFilter).Nome;
    this.filtrarAgendas();
  }

  selecionaStatus(id:number){
    this.statusFiltro = id;
    if(id == 0)
      this.statusDescricao = this.TODAS;
    else if(id ==1)
      this.statusDescricao = this.ABERTO;
    else if(id ==2)
      this.statusDescricao = this.CONCLUIDO;
    this.filtrarAgendas();
  }

  selecionaDataIncio(event){
    this.dataInicioFiltro = event;
    this.preencherDescricaoPeriodo();
    this.filtrarAgendas();
  }

  selecionaDataFim(event){
    this.dataFimFiltro = event;
    this.preencherDescricaoPeriodo();
    this.filtrarAgendas();
  }
  private preencherDescricaoPeriodo(){
    if(this.dataInicioFiltro == null && this.dataFimFiltro == null){
      this.periodoDescricao = this.TODOS;
    }else{
      this.periodoDescricao = `De: ${this.dataInicioFiltro == null ? '' : this.utils.formatarData(this.dataInicioFiltro.toDateString())} Fim: ${this.dataFimFiltro == null ? '' : this.utils.formatarData(this.dataFimFiltro.toDateString()) } `;
    }
  }

  getTipoImprodutividade(id:number):string {
    let tipo = this.tiposImprodutividade.find(x => x.Id == id);
    if(tipo)
      return tipo.Descricao;
    else
      return "";
  }

  abrirApontamento(apontamento) {
    this.apontamentoSelecionado = apontamento;
    this.apontamentoSelecionado.ImprodutividadesEquipamento = this.improdutividadesEquipamento;
    this.apontar = true;
    this.inserindoApontamento = false;
    if (this.apontamentoSelecionado.AtividadeId && this.apontamentoSelecionado.AreaId)
      this.apontamentoAvulso = true;
    else
      this.apontamentoAvulso = false;
  }

}
