import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CentroDeResultados } from 'src/app/classes/CentroDeResultados';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { ListaEnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { EnumStatusAprovacao, EnumStatusMovimento, ListaEnumTipoFrete, Movimento } from 'src/app/classes/Movimento';
import { Natureza } from 'src/app/classes/Natureza';
import { Pessoa } from 'src/app/classes/Pessoa';
import { Projeto } from 'src/app/classes/Projeto';
import { Safra } from 'src/app/classes/Safra';
import { Transacao } from 'src/app/classes/Transacao';
import { Usuario } from 'src/app/classes/Usuario';
import { Cotacao } from 'src/app/classes/cotacao/cotacao';
import { cotacaoService } from 'src/app/services/cotacao/cotacaoService';
import { MovimentoService } from 'src/app/services/movimento.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { SafraService } from 'src/app/services/safra.service';
import { StorageService } from 'src/app/services/storage.service';
import { TransacaoService } from 'src/app/services/transacao.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { MovimentoSharedService } from '../movimento-shared.service';

@Component({
  selector: 'app-movimento-forms',
  templateUrl: './movimento-forms.component.html',
  styleUrls: ['./movimento-forms.component.scss']
})
export class MovimentoFormsComponent implements OnInit {


  @ViewChild('Modal', { static: true }) modal;
  @Input() movimento: Movimento;
  @Input() transacoes: Transacao[];
  @Input() empresasIntenas: EmpresaInterna[];
  @Input() usuarios: Usuario[];
  @Input() transacao: Transacao = new Transacao();
  @Input() projetos: Projeto[] = [];
  @Input() centrosResultados: CentroDeResultados[] = [];
  @Input() naturezas: Natureza[] = [];
  @Input() locaisEstoque: LocalEstoque[] = [];
  @Output() changeTitulo = new EventEmitter();
  @Output() atualizarTransacao = new EventEmitter();
  @Output() atualizarIsSubmit = new EventEmitter();
  @Output() isServico = new EventEmitter();
  @Output() atualizarMovimento = new EventEmitter();
  @Output() showSafraStep = new EventEmitter();
  @Output() showItensStep = new EventEmitter();
  @Output() showEstoqueStep = new EventEmitter();
  @Output() showFinanceiroStep = new EventEmitter();
  @Output() attFinalizar = new EventEmitter();
  @Output() estaCarregando = new EventEmitter();


  formulario: FormGroup;
  id: number = 0;
  cotacoes: Cotacao[] = [];
  pessoas: Pessoa[] = [];
  safras: Safra[] = [];
  listTipoFrete = ListaEnumTipoFrete;
  listaEnumTipoMovimento = ListaEnumTipoMovimento
  inputProjeto = false;
  financeiro = false;
  estoque = false;
  inputCentroResultados = false;
  inputSafra = false;
  disabledRateado = false;
  disabledPercentualDesconto = false;
  disabledValorDesconto = false;
  safraId: number = null;
  showForm = false;
  isSubmit: boolean;
  isSubmitTransacao: boolean;
  pessoaId: number = null;
  tipo: number = null;
  carregando = false;
  usuario: Usuario;
  clientes: Pessoa[] = [];
  vendedores: Pessoa[] = [];
  tipoMovimento: number = 0;

  constructor(
    private cotacaoService: cotacaoService,
    private pessoaService: PessoaService,
    private safraService: SafraService,
    private movimentoService: MovimentoService,
    private transacoaoService: TransacaoService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private utils: UtilsService,
    private sharedService: MovimentoSharedService) {

  }

  ngOnInit() {
    this.carregando = true;
    this.usuario = this.storageService.getUsuario();
    this.tipoMovimento = this.sharedService.tipoMovimento;
    if (this.tipoMovimento)
      this.transacoaoService.getTransacoesPermitidasPorTipo(this.tipoMovimento).subscribe(ret => {
        this.transacoes = ret;
      }, err => this.utils.getErro(err));
    else
      this.transacoaoService.getTransacoes().subscribe(ret => {
        this.transacoes = ret;
      }, err => this.utils.getErro(err));
    this.route.queryParamMap.subscribe(
      (res) => {
        var tipo = res.get('tipo');
        if (!isNaN(parseInt(tipo)))
          this.transacoaoService.getTransacoesPorTipo(parseInt(tipo))
            .subscribe(res2 => {
              this.transacoes = res2;
            })
      }
    );

    this.listarPessoas();
    this.route.params.subscribe(res => {
      this.id = isNaN(res.id) ? 0 : res.id;

      if (this.id > 0) {
        this.onTransacaoChange();
        this.pessoaId = this.movimento.PessoaId;
        this.carregando = false;
        this.estaCarregando.emit(false);
      }
      else {

        setTimeout(() => {
          this.usuario.permissaoUsuarioTransacaos.forEach(p => {
            if (!p.PermiteCriar)
              this.transacoes = this.transacoes.filter(c => c.Id != p.TransacaoId)
          })
          this.carregando = false;
          this.estaCarregando.emit(false);
          this.modal.show();
        }, 1000);

      }
    });
  }

  continuar(form: any) {
    if (!form.valid) {
      this.isSubmitTransacao = true;
      return;
    }
    this.movimentoService.gerar(this.movimento.TransacaoId)
      .subscribe(res => {
        this.movimento = res;
        this.atualizarMovimento.emit(this.movimento);

        this.onTransacaoChange();
      }, err => this.utils.getErro(err));
    this.modal.hide();

  }
  cancelar() {
    this.router.navigate([this.sharedService.getRoute()]);
  }
  listarCotacoes() {
    this.cotacaoService.get()
      .subscribe(res => {
        this.cotacoes = res;
      }, err => this.utils.getErro(err));
  }
  listarPessoas() {
    this.pessoaService.getList()
      .subscribe(res => {
        this.pessoas = res;
        this.pessoas.forEach(p => p._bindLabel = `${p.RazaoSocial} - ${p.CpfCnpj}`)
        this.clientes = this.pessoas.filter(c => c.Papel.includes('Cliente'));
        this.vendedores = this.pessoas.filter(c => c.Papel.includes('Vendedor'));
      }, err => this.utils.getErro(err));
  }
  // listarSafras() {
  //   this.safraService.getSafras()
  //     .subscribe(res => {
  //       this.safras = res;
  //     }, err => this.utils.getErro(err));
  // }
  // onSafraChange() {
  //   this.movimentoService.puxaSafra(this.id, this.safraId)
  //     .subscribe(items => {
  //       this.movimento.Itens = items;
  //     }, err => { this.utils.getErro(err); });
  // }

  onValorBrutoChange() {
    // liquido - desconto + jutos + acrescimetos + funral
    this.movimento.ValorBruto = this.movimento.ValorLiquido - this.movimento.Descontos + this.movimento.Juros + this.movimento.Acrescimos + this.movimento.Funrural;
  }
  onDescontoChange() {
    if (this.movimento.ValorLiquido > 0) {
      this.movimento.PercentualDesconto = (this.movimento.Descontos / this.movimento.ValorLiquido) * 100;
      this.onValorBrutoChange();
    }
  }
  onPercentualDescontoChange() {
    if (this.movimento.ValorLiquido > 0) {
      this.movimento.Descontos = (this.movimento.PercentualDesconto / 100) * this.movimento.ValorLiquido;
      this.onValorBrutoChange();
    }
  }
  onTransacaoChange() {
    this.transacao = this.transacoes.find(p => p.Id == this.movimento.TransacaoId);
    if (this.id > 0) {
      this.changeTitulo.emit(`Editando ${this.movimento.GetTipoMovimento} #${(this.movimento.Serie ? this.movimento.Serie.toString() : "0").padStart(5, '0')}`)
      this.movimento.ValorLiquido += this.movimento.Itens.reduce((acc, curr) => acc + curr.ValorTotalLiquido, 0);
      this.movimento.ItemsSafra.forEach(p => {
        this.movimento.ValorLiquido += p.Items.reduce((acc, curr) => acc + curr.ValorTotalLiquido, 0);
      })
      this.movimento.ValorBruto = this.movimento.ValorLiquido - this.movimento.Descontos + this.movimento.Juros + this.movimento.Acrescimos + this.movimento.Funrural;
      this.atualizarMovimento.emit(this.movimento);
    }
    else
      this.changeTitulo.emit(`Novo ${this.movimento.GetTipoMovimento}`);

    if (this.transacao) {


      this.atualizarTransacao.emit(this.transacao);
      this.inputProjeto = this.transacao.ExigeProjeto;
      this.inputCentroResultados = this.transacao.ExigeCentroResultado;
      // this.inputSafra = this.transacao.BuscaProdutosSafra;
      this.showSafraStep.emit(this.transacao.BuscaProdutosSafra);
      // if (this.inputSafra)
      //   this.listarSafras();
      this.estoque = this.transacao.EfeitoSobreEstoque > 0;
      this.financeiro = this.transacao.EfeitoFinanceiro > 0;
      if (this.id > 0)
        this.disabledRateado = this.transacao.RateiaAutomaticamentoItem;
      else
        this.disabledRateado = this.movimento.Rateado = this.transacao.RateiaAutomaticamentoItem;
    }

    this.showForm = true;

  }
  salvar(etapa: number, salvarApenasCabecalho: boolean = false) {
    this.carregando = true;
    this.estaCarregando.emit(true);
    if (this.movimento.StatusMovimento != EnumStatusMovimento.Cadastrado && this.movimento.StatusMovimento != EnumStatusMovimento.EmRevisao) {
      this.carregando = false;
      this.estaCarregando.emit(false);
      return;
    }
    // this.formulario = new FormGroup({
    //   // transacao: new FormControl(this.movimento.TransacaoId, [Validators.required]),
    //   tipoFrete: new FormControl(this.movimento.TipoFrete, this.movimento.TemFrete ? Validators.required : null),
    //   safra: new FormControl(this.safraId, this.transacao.BuscaProdutosSafra ? Validators.required : null),
    //   // cotacao: new FormControl(this.movimento.CotacaoId, this.transacao.ExigeCotacao ? Validators.required : null),
    //   centroDeResultados: new FormControl(this.movimento.CentroDeResultadosId, this.transacao.ExigeCentroResultado ? Validators.required : null),
    //   projeto: new FormControl(this.movimento.ProjetoId, this.transacao.ExigeProjeto ? Validators.required : null),
    // })
    // console.debug(this.formulario);
    // if (!this.formulario.valid) {
    //   this.isSubmit = true;
    //   this.atualizarIsSubmit.emit(this.isSubmit);
    //   return;
    // }
    if ((etapa == 1 || etapa == 2) && this.movimento.StatusMovimento == EnumStatusMovimento.Cadastrado) {
      this.movimento.Itens = [];
      this.showItensStep.emit(false)
    }

    if (this.movimento.Id > 0) {
      this.movimento.SalvarApenasCabecalho = salvarApenasCabecalho;
      this.movimentoService.put(this.movimento.Id, !salvarApenasCabecalho, this.movimento)
        .subscribe(res => {
          this.utils.handleSuccess("Movimento atualizado!");
          this.carregando = false;
          this.estaCarregando.emit(false);
          console.log("Atualizando");

          this.movimento = res;
          this.movimento.Itens = this.movimento.Itens.filter(x => x.QuantidadePedida > 0)

          // rateio
          this.movimento.ValorTotalRateio = 'R$ ' + this.utils.formatarDecimais(this.movimento.Rateios.reduce((acc, curr) => acc + curr.Valor, 0));
          const totalRateado = this.movimento.Rateios.reduce((acc, curr) => acc + curr.Percentual, 0);
          this.movimento.PercentualTotalRateio = this.utils.formatarDecimais(totalRateado) + '%';
          this.movimento.PercentualNaoRateado = this.utils.formatarDecimais(100 - totalRateado) + `%`;

          // lancemento financeiro
          this.movimento.TotalParcelas = `${this.movimento.LancamentosFinanceiros.length}`;
          this.movimento.TotalMovimento = 'R$ ' + this.utils.formatarDecimais(this.movimento.ValorLiquido);
          this.movimento.SaldoParcelar = 'R$ ' + this.utils.formatarDecimais(this.movimento.ValorLiquido - (this.movimento.LancamentosFinanceiros.reduce((acc, curr) => acc + curr.ValorOriginal, 0)))
          //

          if (this.transacao.EfeitoFinanceiro > 0)
            this.showFinanceiroStep.emit(true);
          else
            this.showFinanceiroStep.emit(false);

          if (this.transacao.EfeitoSobreEstoque > 0)
            this.showEstoqueStep.emit(true);
          else
            this.showEstoqueStep.emit(false);


          this.atualizarMovimento.emit(res);
          if (etapa == 1 || etapa == 2)
            this.showItensStep.emit(true);
          if (salvarApenasCabecalho) {
            if (etapa == 6)
              this.attFinalizar.emit(false);
            this.carregando = false;
            this.estaCarregando.emit(false);
            return;
          }

          this.router.navigate([this.sharedService.getRoute()]);
        }, err => this.utils.getErro(err));
    } else {
      this.movimento.StatusMovimento = EnumStatusMovimento.Pendente;
      this.movimento.StatusAprovacao = EnumStatusAprovacao.Cadastrado;
      this.movimentoService.post(this.movimento)
        .subscribe(res => {
          console.log("salvando");
          this.carregando = false;
          this.estaCarregando.emit(false);
          this.utils.handleSuccess("Movimento criado!");
          this.router.navigate([this.sharedService.getRoute()]);

        }, err => { this.utils.getErro(err); });
    }
  }

  selecionarCentroResultados(id: number) {
    this.movimento.CentroDeResultadosId = id;
  }

  selecionarProjeto(id: number) {
    this.movimento.ProjetoId = id;
  }
}
