import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Safra } from 'src/app/classes/Safra';
import { SafraService } from 'src/app/services/safra.service';
@Component({
  selector: 'app-select-safra',
  templateUrl: './select-safra.component.html',
  styleUrls: ['./select-safra.component.scss']
})
export class SelectsafraComponent implements OnInit {
  @Output() safraChange: EventEmitter<number> = new EventEmitter();
  @Output() safraChangeEmit: EventEmitter<Safra> = new EventEmitter();
  @Input() safraId: number = 0;
  @Input() isSubmit: boolean;
  @Input() isDisabled: boolean = false;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() auxClass: string;
  @Input() ocultarMsgObrigatorio: boolean = false;
  //ng-select produto
  pagina: number = 2;
  termoBusca: String = '';
  carregandoSafra: boolean = false;
  ultimaPagina: boolean = false;
  public inputSafra$ = new Subject<string | null>();
  safras: Safra[] = [];
  safra: Safra;

  constructor(private safraService: SafraService,) {
    this.inputSafra$.subscribe((newTerm) => {
      this.pagina = 1;
    });
  }

  ngOnInit() {
    this.listarSafras();
  }

  listarSafras() {
    this.safraService.getSafras().subscribe(ret => {
      this.safras = [];
      let safraTemp = new Safra();
      safraTemp.Id = 0;
      safraTemp.Descricao = 'Selecione uma Safra';
      this.safras.push(safraTemp);
      ret = ret.sort((a, b) => a.Descricao.localeCompare(b.Descricao));
      this.safras = this.safras.concat(ret);
      });
  }

  changeSafra(idSafra: number) {
    this.safraChange.emit(idSafra);
    this.safra = this.safras.find(c => c.Id == idSafra);
    this.safraChangeEmit.emit(this.safra);
  }
}
