import { ItemAgrupamentoOrdemServico } from './../classes/ItemAgrupamentoOrdemServico';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AgrupamentoOrdemServico } from '../classes/AgrupamentoOrdemServico';

@Injectable({
  providedIn: 'root'
})
export class AgrupamentoOrdemServicoService {

  constructor(private http: HttpClient) { }

  getAgrupamentoOrdemServicos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/agrupamentoOrdemServico`)
  }

  getAgrupamentoOrdemServico(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/agrupamentoOrdemServico/${id}`)
  }

  postAgrupamentoOrdemServico(agrupamentoOrdemServico: AgrupamentoOrdemServico, itensAgrupamentoOrdemServico: ItemAgrupamentoOrdemServico[] ): Observable<any>{    
    let agrupamentoOS= {
      agrupamento: agrupamentoOrdemServico,
      itensAgrupamento: itensAgrupamentoOrdemServico
    }
    return this.http.post(`${environment.apiURL}/agrupamentoOrdemServico/inserir`, agrupamentoOS);
  }

  putAgrupamentoOrdemServico(id: number, agrupamentoOrdemServico: AgrupamentoOrdemServico): Observable<any>{
    return this.http.put(`${environment.apiURL}/agrupamentoOrdemServico/${id}`, agrupamentoOrdemServico);
  }

  deleteAgrupamentoOrdemServico(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/agrupamentoOrdemServico/${id}`);
  }
}
