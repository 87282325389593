import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Atividade } from '../classes/Atividade';

@Injectable({
  providedIn: 'root'
})
export class AtividadeService {

  constructor(private http: HttpClient) { }

  getAtividades(): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividade`)
  }

  getAtividadesExcluidas(): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividade/ListarComExcluidas`)
  }

  getAtividadesParaUso(): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividade/ListarParaUso`)
  }

  getAtividade(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividade/${id}`)
  }
  getAtividadePadrao(): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividade/padrao`)
  }
  postAtividade(atividade: Atividade): Observable<any>{
    return this.http.post(`${environment.apiURL}/atividade`, atividade);
  }

  putAtividade(id: number, atividade: Atividade): Observable<any>{
    return this.http.put(`${environment.apiURL}/atividade/${id}`, atividade);
  }

  deleteAtividade(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/atividade/${id}`);
  }

  getAtividadesPorGrupo(grupoOperacaoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividade/listarPorGrupoOperacao/${grupoOperacaoId}`)
  }
  getAtividadesPorProcesso(processoPlanejamentoId:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/atividade/AtividadesPorProcesso/${processoPlanejamentoId}`)
  }
}
