import { Component, OnInit } from '@angular/core';
import { BuscarAreas } from 'src/app/classes/tipos-diagnose/BuscarAreas';
import { DiagnosesService } from '../../../services/diagnoses/diagnoses.service';
import { AreaUltimaModificacao } from '../../../classes/tipos-diagnose/AreaUltimaModificacao';
import { Diagnoses } from 'src/app/classes/tipos-diagnose/Diagnoses';

@Component({
  selector: 'app-relatorios-diagnoses',
  templateUrl: './relatorios-diagnoses.component.html',
  styleUrls: ['./relatorios-diagnoses.component.scss']
})
export class RelatoriosDiagnosesComponent implements OnInit {

  passo: string = 'Empresa';
  areaUltimaModificacao: AreaUltimaModificacao[] = [];
  buscarAreasDados: BuscarAreas = null;
  areaSelecionadaId: number = null;
  diagnosesDados: Diagnoses;
  diagnoses: any[] = [];
  textoInformativo: string = 'Selecione uma Empresa';

  constructor(
    private diagnosesService: DiagnosesService
  ) { }

  ngOnInit() {
  }

  opcaoSelecionada(event) {
    if (event == 'Empresa') {
      this.passo = 'Fazenda';
      this.textoInformativo = 'Selecione uma Fazenda';
    }
    if (event == 'Fazenda') {
      this.passo = 'Safra';
      this.textoInformativo = 'Selecione uma Safra';
    }
    if (event == 'Safra') {
      this.passo = 'Safra';
    }
  }

  opcaoSelecionadaInvalida(event) {
    console.log(event);
    if (event == 'Empresa') {
      this.passo = 'Empresa';
      this.textoInformativo = 'A Empresa selecionada não possui fazendas cadastradas';
    }
    if (event == 'Fazenda') {
      this.passo = 'Fazenda';
      this.textoInformativo = 'A Fazenda selecionada não possui safras cadastradas';
    }
  }

  buscarAreas(event: BuscarAreas) {
    this.buscarAreasDados = event;
    this.diagnosesService.ObterAreaUltimaModificacao(event).subscribe(
      res => {
        this.areaUltimaModificacao = res;
        this.passo = 'Menu';
      },
      error => console.log(error)
    )
  }

  selecionarArea(event) {
    this.areaSelecionadaId = event;
    this.diagnosesDados = null;
    this.passo = 'Menu';
    this.diagnosesService.ObterDiagnosesPorArea({
      AreaId: this.areaSelecionadaId,
      EmpresaId: this.buscarAreasDados.EmpresaId,
      SafraId: this.buscarAreasDados.SafraId
    }).subscribe(
      res =>  this.popularLinhaDoTempo(res),
      error => console.error(error)
    )
  }

  popularLinhaDoTempo(diagnosesDados: Diagnoses) {
    this.diagnoses = [];
    this.transformarEmLista(diagnosesDados);
    this.passo = this.diagnoses.length > 0 ? 'LinhaDoTempo' : 'SemDiagnoses';
  }

  private transformarEmLista(diagnosesInput: Diagnoses) {
    diagnosesInput.ListaDiagnoseAudios
      .map(d => this.adicionarTipoDiagnose(d, 'Audio'));
    diagnosesInput.ListaDiagnoseComentarios
      .map(d => this.adicionarTipoDiagnose(d, 'Comentario'));
    diagnosesInput.ListaDiagnoseFotos
      .map(d => this.adicionarTipoDiagnose(d, 'Foto'));
    diagnosesInput.ListaDiagnosePragas
      .map(d => this.adicionarTipoDiagnose(d, 'Praga'));
    diagnosesInput.ListaIndicadores
      .map(d => this.adicionarTipoDiagnose(d, 'Indicador'));

    this.diagnoses.push(...diagnosesInput.ListaDiagnoseAudios);
    this.diagnoses.push(...diagnosesInput.ListaDiagnoseComentarios);
    this.diagnoses.push(...diagnosesInput.ListaDiagnoseFotos);
    this.diagnoses.push(...diagnosesInput.ListaDiagnosePragas);
    this.diagnoses.push(...diagnosesInput.ListaIndicadores);
  }

  private adicionarTipoDiagnose(diagnose: any, tipo: string) {
    diagnose.Tipo = tipo;
  }
}
