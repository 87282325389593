import { TabelaCotacao } from './TabelaCotacao';
import { culturaCotacao } from './culturaCotacao';
import { itensCotacao } from './itensCotacao';
import { safraCotacao } from './safraCotacao';
import { Fornecedores } from './Fornecedores';
import { Empresa } from './../Empresa';
import { Usuario } from './../Usuario';
import { fazendaCotacao } from './fazendaCotacao';
import { AuxilicarCotacao } from './AuxilicarCotacao';
import { auxResumo } from './auxResumo';
import { MelhorCompraCotacao } from './MelhorCompraCotacao';
import { DadosTabela } from './DadosTabela';
export class CotacaoInclude{
    Id: number = 0;
    Titulo:string = "";
    TipoCotacao: number = 0;
    DataCriacao: Date = new Date();
    DataLimite: Date = new Date();
    CompradorResponsavelId: number;
    CompradorResponsavel:Usuario;
    Status: number = 0;
    UsarSimilar:boolean = false;
    SafrasCotacao:safraCotacao[];
    FazendaCotacao:fazendaCotacao[];
    CulturaCotacao:culturaCotacao[];
    ItensCotacao:itensCotacao[];
    PercentualDescontoDesejado:number = 0;
    PercentualEliminacao:number = 0;
    Auxiliar:AuxilicarCotacao[];
    DadosTabela:DadosTabela[];
    Fornecedores:Fornecedores[];
    auxResumo:auxResumo;
    getStatus:string = "";
    TempoRestante: number = 0;
    QuantidadeItens:number = 0;
    EmpresaId: number;
    Empresa:Empresa;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId:number;
    UsuarioAlteracaoId:number;
    FlagExcluido:boolean = false;
    ValorTotal: number = 0;
    TotalSemPrecos: number = 0;
    TotalComPrecos: number = 0;
    ValorTotalCotacao: number = 0;
    ValorTotalCotacaoString:string = "";
    TransacaoDestinoId: number
    AprovadoString:string = "";
    Aprovado: boolean;
    MotivmentoOrigemId: number;
    StatusAprovacao: number = 0;
    MotivoAprovacao:string = "";
    //auxiliar
    statusOc: number = 0;
    FornecedorParticipante: boolean;
}
