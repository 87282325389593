import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Cultura } from 'src/app/classes/Cultura';
import { TipoDescontoProducao } from 'src/app/classes/gestao-lite/TipoDescontoProducao';
import { CulturaService } from 'src/app/services/cultura.service';
import { TipoDescontoProducaoService } from 'src/app/services/tipo-desconto-producao.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-tipo-desconto-producao',
  templateUrl: './tipo-desconto-producao.component.html',
  styleUrls: ['./tipo-desconto-producao.component.scss']
})
export class TipoDescontoProducaoComponent implements OnInit {
  @ViewChild('ModalTipo', { static: false })
  ModalTipo: UiModalComponent;

  @Output() fechou: EventEmitter<any> = new EventEmitter();
  @Output() changeTipoDescontoProducao: EventEmitter<TipoDescontoProducao> = new EventEmitter();

  tipoDescontoProducao: TipoDescontoProducao = new TipoDescontoProducao();
  culturas: Cultura[] = [];

  constructor(private tipoDescontoProducaoService: TipoDescontoProducaoService,
    private culturaService: CulturaService,
    private translate: TranslateService,
    private utils: UtilsService) { }

  ngOnInit() {
    this.culturaService.getCulturas().subscribe(ret =>{
      this.culturas = ret;
    });
  }

  ngAfterViewInit() {
    this.ModalTipo.show();
  }

  salvar(){
    this.tipoDescontoProducaoService.postTipoDescontoProducao(this.tipoDescontoProducao).subscribe(ret =>{
      this.tipoDescontoProducao = ret;
      this.utils.handleSuccess(this.translate.instant('TIPO_DESCONTO_PRODUCAO_SALVO_COM_SUCESSO'));
      this.ModalTipo.hide();
      this.changeTipoDescontoProducao.emit(this.tipoDescontoProducao);
    },
    err =>{
      this.utils.getErro(err);
    });
  }

  fechar(){
    this.ModalTipo.hide();
    this.fechou.emit();
  }

}
