import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "app-grafico-barra",
  templateUrl: "./grafico-barra.component.html",
  styleUrls: ["./grafico-barra.component.scss"],
})
export class GraficoBarraComponent implements OnInit, OnChanges  {
  @Input() titulo: string;
  @Input() subTitulo: string;
  @Input() seriesName: string = "Valor Total";
  @Input() chartID: string;
  @Input() categories: string[] = new Array<string>();
  @Input() data: any[];
  @Input() minHeight: number = 0;
  @Input() carregando = false;
  @Input() border = false;
  @Input() horizontal = false;

  @Output() onPointSelection = new EventEmitter<any>();

  chartOptions: any;

  constructor() {}

  ngOnInit() {
    this. initChart();
  }
  ngOnChanges(changes){
    this. initChart();
  } 

  initChart(){
    this.chartOptions = {
      chart: {
        type: "bar",
        events: {
          dataPointSelection(event, chartContext, config) {
            const status = config.w.config.labels[config.dataPointIndex];
            const obj = { tipo: tipo, status: status };
            emitirEvento(obj);
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: this.horizontal,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      series: [
        {
          data: this.data,
          name: this.seriesName,
        },
      ],
      xaxis: {
        categories: this.categories,
      }  
    };

    const tipo = this.chartID;
    const emitirEvento = (obj: any) => this.onPointSelection.emit(obj);
  }
}
