import { itensCotacao } from './../../classes/cotacao/itensCotacao';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class itensCotacaoService {  

  constructor(private http: HttpClient) { }

  get(): Observable<any>{
    return this.http.get(`${environment.apiURL}/ItensCotacao`)
  }

  getPorId(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ItensCotacao/${id}`)
  }

  post(itensCotacao: itensCotacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/ItensCotacao`, itensCotacao);
  }

  put(id: number, itensCotacao: itensCotacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/ItensCotacao/${id}`, itensCotacao);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/ItensCotacao/${id}`);
  }
  getPaginado(cotacaoId:number, pagina: number, tamanhoPagina:number, tipo:number, idGrupo: number, busca: String): Observable<any>{
    return this.http.get(`${environment.apiURL}/ItensCotacao/paginado/${cotacaoId}/${pagina}/${tamanhoPagina}/${tipo}/${idGrupo}/${busca}`)
  }
  getGrupos(cotacaoId:number, tipo:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ItensCotacao/agrupamento/${cotacaoId}/${tipo}`)
  }
}
