import { AtividadePlanejamento } from "./AtividadePlanejamento";
import { Cultivar } from "./Cultivar";
import { Etapa } from "./Etapa";
import { ItemEnum } from "./ItemEnum";

export class Planejamento {
    Id: number;
    Descricao:string = "";
    SafraId: number;
    CultivarId: number;
    AreaId: number;
    Padrao: boolean;
    AreaPlantada: number = 0;
    DataPrevistaPlantio: Date = new Date();
    DataRealPlantio: Date = new Date();
    PlanoMestreProducaoId: number;

    QtdAtividades: number = 0;
    QtdEtapas: number = 0;
    CustoPorHA: number = 0;
    ProdutividadeSimulada: number = 0;
    PrecoVendaSimulado: number = 0;
    LucroSimulado: number = 0;
    DataPrevistaColheita: any;
    DescricaoArea:string = "";
    DescricaoSafra:string = "";
    CulturaId: number;
    FlagPlantou: boolean;
    FlagSemente: boolean;
    DescricaoPlanoMestreProducao:string = "";

    Collapse = false;
    AtividadesPlanejamento: AtividadePlanejamento[] = [];
    Etapas: Etapa[] = [];

    Cultivar: Cultivar;
}

export class PlanejamentoDetalhe{
PlanejamentoDesc:string = "";
PlanejamentoId: number;
ProcessoDesc:string = "";
ProcessoId: number;
EtapaDesc:string = "";
EtapaId: number;
AtividadeDesc:string = "";
AtividadeId: number;
ProdutoDesc:string = "";
ProdutoId: number;
Dose: number = 0;
}
