import { FuncionarioTranslate } from './funcionario.translate';
import { Component, OnInit } from '@angular/core';
import { Funcionario } from 'src/app/classes/Funcionario';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { EscalaTrabalho } from 'src/app/classes/EscalaTrabalho';
import { EscalaTrabalhoService } from 'src/app/services/escala-trabalho.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-funcionario',
  templateUrl: './funcionario.component.html',
  styleUrls: ['./funcionario.component.scss']
})
export class FuncionarioComponent extends FuncionarioTranslate implements OnInit {

  funcionarios: Funcionario[];
  auxFuncionarios: Funcionario[];
  funcionario: Funcionario;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  escalasTrabalho: EscalaTrabalho[];

  constructor(private funcionariosService: FuncionarioService, private escalaTrabalhoService: EscalaTrabalhoService,
              private route: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private toasty: CustomToastyService,
              private translate: TranslateService,
              private utils: UtilsService) {
                super(translate)
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.funcionario = new Funcionario();

    this.escalaTrabalhoService.getEscalaTrabalhos().subscribe(ret =>{
      this.escalasTrabalho = ret;
    });

    if(this.id > 0)
      this.selecionarFuncionario();
    else
      this.listarFuncionarios();
  }

  selecionarFuncionario() {
    this.configurarTraducao();
    this.carregando = true;
    this.funcionariosService.getFuncionario(this.id).subscribe(res =>{
      this.funcionario = new Funcionario();
      this.funcionario = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  listarFuncionarios(){
    this.configurarTraducao();
    this.carregando = true;
    this.funcionariosService.getFuncionarios().subscribe(
      res => {
        this.funcionarios = res;
        this.auxFuncionarios = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarFuncionarios();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.funcionario.Id > 0)
      this.funcionariosService.putFuncionario(this.funcionario.Id, this.funcionario).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.FUNCIONARIO_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.funcionariosService.postFuncionario(this.funcionario).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.FUNCIONARIO_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(grupooperacaoId: number, nome: string) {
    this.DELETAR_PARAM = this.translate.instant('DELETAR_PARAM', {value: nome});
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.funcionariosService.deleteFuncionario(grupooperacaoId).subscribe(
            res => {
              this.toasty.show(this.FEITO, this.FUNCIONARIO_DELETADO, 'success');
              this.listarFuncionarios();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarFuncionarios(){
    this.funcionarios = this.auxFuncionarios.filter(x =>
      x.Nome.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Funcao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/funcionario']);
  }

  getEscalaTrabalho(id: number) : EscalaTrabalho {
    if (!this.escalasTrabalho)
      return null;

    let escalas = this.escalasTrabalho.filter(x =>
      x.Id==id
    );

    return escalas.length > 0 ? escalas[0] : new EscalaTrabalho();
  }

  configurarTraducao() {
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.FEITO = this.translate.instant('FEITO');
    this.FUNCIONARIO_DELETADO = this.translate.instant('FUNCIONARIO_DELETADO');
    this.ERRO = this.translate.instant('ERRO');
    this.FUNCIONARIO_ALTERADO = this.translate.instant('FUNCIONARIO_ALTERADO');
    this.FUNCIONARIO_CADASTRADO = this.translate.instant('FUNCIONARIO_CADASTRADO');
  }
}
