import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrdemServico } from 'src/app/classes/OrdemServico';

@Component({
  selector: 'app-observacao-ordem-servico',
  templateUrl: './observacao-ordem-servico.component.html',
  styleUrls: ['./observacao-ordem-servico.component.scss']
})
export class ObservacaoOrdemServicoComponent implements OnInit {

  @Input() ordemServico: OrdemServico;
  @Output() OrdemServicoChangeEvento = new EventEmitter<OrdemServico>();
  
  constructor() { }

  ngOnInit() {
  }

  osTimeout: any;
  ordemServicoChange() {
    console.log('change')
    if (this.osTimeout)
      clearTimeout(this.osTimeout);
    this.osTimeout = setTimeout(() => {
      this.OrdemServicoChangeEvento.emit(this.ordemServico)
    }, 500);
  }

}
