import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cod-select',
  templateUrl: './cod-select.component.html',
  styleUrls: ['./cod-select.component.scss']
})
export class CodSelectComponent implements OnInit {

  @Input() placeholderLabel: string;
  @Input() label: string;
  @Input() desabilitado = false;
  @Input() required = false;
  @Input() isSubmit = false;
  @Input() inputId: number = 0;
  @Input() lista: any;
  @Input() bindingLabel: string;
  @Input() bindingValue: string;
  @Input() modelo: any;
  @Input() typeahead: any;
  @Output() changeInput = new EventEmitter<any>();
  @Output() modelChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if(!this.placeholderLabel){
      this.placeholderLabel = 'Selecione...';
    }
  }

  onModeloChange(valor) {
    if (!valor)
    this.changeInput.emit(null);
    else {
      if (this.lista.find(p => p[this.bindingValue] == valor)) {
        this.changeInput.emit(valor);
      } else
        this.changeInput.emit(null);
    }
  }

}
