import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { Indicador } from 'src/app/classes/indicadores/Indicador';
import { IndicadoresService } from 'src/app/services/Indicadores/indicadores.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.scss']
})
export class IndicadoresComponent implements OnInit {

  @ViewChild('Grid', { static: false }) grid

  colDefs: ColDef[] = [];
  listaIndicadores: Indicador[] = [];
  objSelecionado: Indicador = new Indicador();
  objSelecionadoId: string = null;
  objsSelecionados: Indicador[] = [];

  constructor(
    private router: Router,
    private indicadoresService: IndicadoresService,
    private utilsService: UtilsService,
    private toasty: CustomToastyService
  ) { }

  ngOnInit() {
    this.configura();
    this.pegarIndicadores();
  }

  novo() {
    this.router.navigate(['/cadastros/indicadores/inserir']);
  }

  excluir() {

    if (this.objsSelecionados.length <= 0 || this.objSelecionadoId == null) {
      this.utilsService.handleWarning("Selecione um Indicador para prosseguir!");
      return;
    }
    if (this.objsSelecionados.length > 1) {
      this.utilsService.handleWarning("Selecione apenas um Indicador para prosseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar ' + this.objsSelecionados[0].Descricao + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.indicadoresService.Excluir(this.objSelecionadoId).subscribe(
          res => {
            this.toasty.show('Feito!', 'Indicadores excluído!', 'success');
            this.pegarIndicadores()
          },
          err => {
            this.utilsService.getErro(err);
          });
      }
    });
  }

  selectionChanged(event) {
    this.objsSelecionados = event;
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  cellDoubleClicked(event) {
    this.router.navigate([`/cadastros/indicadores/${event.data.Id}`]);
  }

  private configura() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true
      },
      { field: 'Descricao', headerName: 'Descrição', flex: 1 },
      { field: 'Tipo', headerName: 'Tipo', flex: 1 },
      { field: 'LocalUso', headerName: 'Local Uso', flex: 1 },
      { field: 'OpcoesLista', headerName: 'Opções Lista', flex: 1 }
    ]
  }

  private pegarIndicadores() {
    this.indicadoresService.Listar().subscribe(
      res => this.listaIndicadores = res,
      error => {
        if (error.status == 404) return;
        this.utilsService.getErro(error)
      }
    );
  }
}
