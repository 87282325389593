import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, from, of } from 'rxjs';
import { RedefinirSenha, Usuario } from '../classes/Usuario';
import { PermissaoUsuarioTransacao } from '../classes/PermissaoUsuarioTransacao';
import { Empresa } from '../classes/Empresa';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { SenhaForm } from '../components/login/login.component';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  getUsuarios(): Observable<any>{
    return this.http.get(`${environment.apiURL}/usuarios`)
  }

  getUsuario(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/usuarios/${id}`)
  }

  findUsuario(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/usuarios/find/${id}`)
  }

  postUsuario(usuario: Usuario): Observable<any>{
    return this.http.post(`${environment.apiURL}/usuarios`, usuario);
  }

  putUsuario(id: number, usuario: Usuario): Observable<any>{
    return this.http.put(`${environment.apiURL}/usuarios/${id}`, usuario);
  }

  deleteUsuario(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/usuarios/${id}`);
  }
  redefinirSenha(redefinirSenha: RedefinirSenha): Observable<any>{
    return this.http.put(`${environment.apiURL}/usuarios/redefinirSenha`, redefinirSenha);
  }

  gerarTokenRecuperacao(login:string): Observable<any>{
    return this.http.get(`${environment.apiURL}/usuarios/GerarToken/${login}`);
  }

  validarToken(token:string): Observable<any>{
    return this.http.get(`${environment.apiURL}/usuarios/ValidarToken/${token}`);
  }

  trocarSenha(senhaForm: SenhaForm): Observable<any>{
    return this.http.post(`${environment.apiURL}/usuarios/TrocarSenha`, senhaForm);
  }
  getCompradores(): Observable<any>{
    return this.http.get(`${environment.apiURL}/usuarios/compradores`);
  }

  postPermissaoUsuarioTransacaos(usuarioId:number, permissaoUsuarioTransacaos: PermissaoUsuarioTransacao): Observable<any>{
    return this.http.post(`${environment.apiURL}/usuarios/PermissaoTransacao/${usuarioId}`, permissaoUsuarioTransacaos);
  }

  putPermissaoUsuarioTransacaos(id:number, usuarioId:number, permissaoUsuarioTransacaos: PermissaoUsuarioTransacao): Observable<any>{
    return this.http.put(`${environment.apiURL}/usuarios/PermissaoTransacao/${id}/${usuarioId}`, permissaoUsuarioTransacaos);
  }

  deletePermissaoUsuarioTransacaos(id: number, usuarioId:number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/usuarios/PermissaoTransacao/${id}/${usuarioId}`);
  }

  listarIdsEmpresasAdicionadas(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/usuarios/${id}/Empresas`);
  }

  adicionarEmpresa(id: number, empresaId: number): Observable<any> {
    return this.http.post(`${environment.apiURL}/usuarios/${id}/Empresa/${empresaId}`, {});
  }

  removerEmpresa(id: number, empresaId: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/usuarios/${id}/Empresa/${empresaId}`);
  }

  relacionarAEmpresas(usarrioId: number, empresas: Empresa[], empresasRelacionadas: Empresa[]) {
    const remover = empresasRelacionadas.filter(
      item => !empresas.includes(item));
    const adicionar = empresas.filter(
      item => !empresasRelacionadas.includes(item));

    this.RelacionarOuDesrelacionarSeNecessario(usarrioId, remover, false);
    this.RelacionarOuDesrelacionarSeNecessario(usarrioId, adicionar, true);
    return of(true);
  }

  private RelacionarEmpresaAUsuario(usuarioId: number, empresas: Empresa[]): Observable<boolean> {
    const url = `${environment.apiURL}/Empresas/RelacionarEmpresaUsuarios`;
    return from(empresas).pipe(
      mergeMap(emp =>
        this.http.post(url, this.GerarEmpresaUsuario(usuarioId, emp.Id))),
      map(() => true),
      catchError(error => {
        return of(false);
      })
    );
  }

  private DesrelacionarEmpresaAUsuario(usuarioId: number, empresas: Empresa[]): Observable<boolean> {
    const url = `${environment.apiURL}/Empresas/DesrelacionarEmpresaUsuarios`;
    return from(empresas).pipe(
      mergeMap(emp => this.http.delete(`${url}/${emp.Id}/${usuarioId}`)),
      map(() => true),
      catchError(error => {
        return of(false);
      })
    )
  }

  private RelacionarOuDesrelacionarSeNecessario(usuarioId: number, empresas: Empresa[], adicionar: boolean) {
    if (empresas.length > 0) {
      const request = adicionar ? this.RelacionarEmpresaAUsuario(usuarioId, empresas) : this.DesrelacionarEmpresaAUsuario(usuarioId, empresas);
      request.subscribe(
        () => { },
        () => { return of(null) }
      )
    }
  }

  private GerarEmpresaUsuario(usuarioId: number, empresaId: number) {
    return {
      EmpresaId: empresaId,
      UsuarioId: usuarioId
    }
  }
  definirIdioma(idioma: string): Observable<any>{
    return this.http.put(`${environment.apiURL}/usuarios/DefinirIdioma/${idioma}`, null);
  }

}
