import { EnumTipoManutencao } from './../../../classes/gestao-lite/AuxManutencaoFrota';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ColDef } from 'src/app/classes/grid/ColDef';
import * as moment from 'moment';
import { ItemManutencaoFrotaService } from 'src/app/services/item-mautencao-frota.service';
import { ManutencaoFrotaService } from 'src/app/services/manutencao-frota.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ManutencaoFrota } from 'src/app/classes/gestao-lite/ManutencaoFrota';
import { Produto } from 'src/app/classes/Produto';
import { ProdutoService } from 'src/app/services/produto.service';
import { OficinaService } from 'src/app/services/oficina.service';
import { Oficina } from 'src/app/classes/Oficina';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { Funcionario } from 'src/app/classes/Funcionario';
import { farmwaySchematics } from 'farmway-schematics/src/farmway-schematics';
import { ItemManutencaoFrota } from 'src/app/classes/gestao-lite/ItemManutencaoFrota';
import { platform } from 'os';
import { PendenciaFrotaService } from 'src/app/services/pendencia-frota.service';
import { ItemChecklistManutencaoFrotaService } from 'src/app/services/item-checklist-manutencao-frota.service';

@Component({
  selector: 'app-manutencao-os',
  templateUrl: './manutencao-os.component.html',
  styleUrls: ['./manutencao-os.component.scss']
})
export class ManutencaoOsComponent implements AfterViewInit  {

  @ViewChild('modalConfirmacaoPendencia', { static: false }) modalConfirmacaoPendencia;
  @ViewChild('modalVisualizarEstoque', { static: false }) modalVisualizarEstoque;
  @ViewChild('modalCriarPendenciaFronta', { static: false }) modalCriarPendenciaFronta;
  @ViewChild('modalVisualizarAnexosPendenciaFronta', { static: false }) modalVisualizarAnexosPendenciaFronta;
  @ViewChild('modalVisualizarPlanoManutencao', { static: false }) modalVisualizarPlanoManutencao;

  coldefs: ColDef[] = [];
  coldefsItens: ColDef[] = [];
  dataGrid: ManutencaoFrota[] = [];
  dataGridItens: ItemManutencaoFrota[] = [];
  model: ManutencaoFrota = new ManutencaoFrota();
  selecionados: ManutencaoFrota[] = [];
  showForms = false;
  produtos: Produto[] = [];
  oficinas: Oficina[] = [];
  locaisEstoque: LocalEstoque[] = [];
  funcionarios: Funcionario[] = [];
  titulo = 'Lista';

  constructor(private service: ManutencaoFrotaService, private utils: UtilsService, private elRef: ElementRef, private renderer: Renderer2,
    private produtoService: ProdutoService,
    private localEstoqueService: LocalEstoqueService,
    private funcionarioService: FuncionarioService,
    private oficinaService: OficinaService,
    private pendenciaFrotaService: PendenciaFrotaService,
    private itemManutencaoFrotaService: ItemManutencaoFrotaService,
    private itemChecklistManutencaoFrota: ItemChecklistManutencaoFrotaService) { }
  ngAfterViewInit(): void {
   this.resizeGrid();
  }

  ngOnInit() {
    this.configurar();
    this.listar();
    this.getProdutos();
    this.getOficinas();
    this.getLocaisEstoque();
    this.getFuncionarios();
  }
  resizeGrid(){
    const elements = this.elRef.nativeElement.querySelectorAll('.p-datatable-scrollable-body');
    elements.forEach(element => {
      this.renderer.setStyle(element, 'height', '250px');
    });
  }
  // ag-grid
  configurar(){
    this.coldefsItens = [
      {field: 'Id', headerName: 'Id'},
      {field: '', headerName: 'Cod'},
      {field: 'IsServico', headerName: 'Tipo', cellRenderer: (params) => this.badgTipoItem(params.data.IsServico)},
      {field: 'ProdutoDescricao', headerName: 'Descrição'},
      {field: 'Quantidade', headerName: 'Quantidade'},
      {field: 'StatusString', headerName: 'Situação'},

    ];
    this.coldefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'DescricaoFazenda', headerName: "Fazenda" },
      { field: 'DescricaoGrupoEquipamento', headerName: "Grupo" },
      { field: 'Tag', headerName: "Tag", valueFormatter: (params) => params.data.Equipamento.Tag },
      { field: 'DescricaoEquipamento', headerName: "Descrição Equipamento"},
      { field: 'TipoManutencao', headerName: "Tipo", cellRenderer: (params) => this.badgeTipo(params.data.TipoManutencao) },
      { field: 'StatusString', headerName: "Status", cellRenderer: (params) =>  `<span class="badge badge-primary custom-badge-grid">${params.data.StatusString}</span>` },
      { field: 'ResponsavelString', headerName: "Responsável" },
      { field: 'DataCadastro', headerName: "Data Abertura", valueFormatter: (params) => { return new Date(params.data.DataCadastro).toLocaleDateString() } },
      { field: '', headerName: "Tempo", valueFormatter: (params) => this.calcTempo(params.data.Tempo) },
    ];

  }
  attModel(obj){
    this.model = obj;
  }
  cellDoubleClicked(event) {

    this.tituloEditar();
    this.model = event.data;

    this.listarPendencias();
    // listar checklists
    this.model.ItensChecklist = [];
    this.itemChecklistManutencaoFrota.listarPorManutencao(this.model.Id)
    .subscribe(res => {
      console.debug('checklist', res);
      this.model.ItensChecklist = res;
      console.debug('checklist',this.model.ItensChecklist);
    }, err => this.utils.getErro(err));

    this.showForms = true;
  }
  onCellClicked(row) {
    this.model = row.data;

    // listar produtos
    this.model.ItensManutencaoFrota = [];
    this.itemManutencaoFrotaService.getItensManutencaoFrota(this.model.Id)
    .subscribe((res: ItemManutencaoFrota[]) => {
      this.dataGridItens = res;
      this.model.ItensManutencaoFrota = res;
    }, err => this.utils.getErro(err));
  }
  selectionChanged(event) {
    this.selecionados = event;
  }
  calcTempo(horas: number): string{
   var dias = horas / 24;    //var segundos= depois.diff(antes, 'seconds');
   return `${dias.toFixed(0)}d ${horas}h`
  }
  badgTipoItem(isServico: boolean){
    return isServico ? '<span class="badge badge-secondary custom-badge-grid">Serviço</span>'
    : '<span class="badge badge-secondary custom-badge-grid">Produto</span>';
  }
  badgeTipo(tipo: EnumTipoManutencao){
    switch (tipo) {
      case EnumTipoManutencao.Preventiva:
        return '<span class="badge badge-secondary custom-badge-grid">Preventiva</span>';
      case EnumTipoManutencao.Corretiva:
        return '<span class="badge badge-secondary custom-badge-grid">Corretiva</span>';
      case EnumTipoManutencao.Preditiva:
        return '<span class="badge badge-secondary custom-badge-grid">Preditiva</span>';
      case EnumTipoManutencao.Externa:
        return '<span class="badge badge-secondary custom-badge-grid">Externa</span>';
    }
  }

  // barra de tarefas
  toggleGrid(){
    this.showForms = !this.showForms;
    if (this.showForms) {
      if (this.model.Id > 0) {
        this.tituloEditar();
      } else
        this.tituloNovo()
    } else
      this.tituloListar();
  }

  novo(){
    this.service.gerarCadastro()
    .subscribe(res => {
      this.model = res;
      this.model.ItensManutencaoFrota = [];
      console.debug(this.model)
      this.showForms = true;
      this.tituloNovo();
    })
  }

  cancelar(){
    this.showForms = false;
    this.tituloListar();
  }

  salvar(){
    if(this.model.Id){
      this.service.put(this.model.Id, this.model)
      .subscribe(res => {
        this.utils.handleSuccess("Manuteção atualizada");
        this.listar();
        this.cancelar();
      },err => this.utils.getErro(err));
    }else
      this.service.post(this.model)
      .subscribe(res => {
        this.utils.handleSuccess("Manuteção criada");
        this.listar();
        this.cancelar();
      },err => this.utils.getErro(err))
  }
  excluir(){
    if(this.selecionados.length <= 0){
      this.utils.handleWarning("Nenhum item selecionado");
       return;
    }
    this.selecionados.forEach(item => {
      this.service.excluir(item.Id)
      .subscribe(res => {
        this.listar();
        this.utils.handleSuccess("Manuteção excluída");
      },err => this.utils.getErro(err));
    })
  }

  buscar(event){
  }

  clonar(){
  }
  listar(){
    this.service.getList()
    .subscribe(res => {
      var elemento = this.elRef.nativeElement.querySelector(".className");
      console.debug(elemento)
      this.dataGrid = res;
    }, err => this.utils.getErro(err));
  }
  getOficinas(){
    this.oficinaService.Listar()
    .subscribe(res => {
      this.oficinas = res;
    }, err => this.utils.getErro(err));
  }
  getProdutos(){
    this.produtoService.getProdutos()
    .subscribe(res => {
      this.produtos = res;
    }, err => this.utils.getErro(err));
  }
  getLocaisEstoque(){
    this.localEstoqueService.getLocaisEstoque()
    .subscribe(res => {
      this.locaisEstoque = res;
    }, err => this.utils.getErro(err));
  }
  getFuncionarios(){
    this.funcionarioService.getFuncionarios().subscribe((data) => {
      this.funcionarios = data;
    }, err => this.utils.getErro(err));
  }
  attLista(){
    this.listar();
    this.cancelar();
  }
  confirmacaoPendencias(data){
    this.modalConfirmacaoPendencia.abrirModal(data.manutencaoId, data.itemId, data.tipo);
  }
  visualizarEstoque(data){
    this.modalVisualizarEstoque.abrirModal(data)
  }
  visualizarAnexosPendenciaFrota(data){
    this.modalVisualizarAnexosPendenciaFronta.abrirModal(data);
  }
  criarPendenciaFrota(data){
    this.modalCriarPendenciaFronta.abrirModal(data);
  }
  visualizarPlanoManutencao(data){
    this.modalVisualizarPlanoManutencao.abrirModal(data);
  }
  listarPendencias(){
    this.model.Pendencias = [];

    // listar pendencias
    this.pendenciaFrotaService.GetPorEquipamento(this.model.EquipamentoId)
    .subscribe(res => {
      this.model.Pendencias = res;
      console.debug('pendencias: ', this.model.Pendencias)
    }, err => this.utils.getErro(err));
  }
  //
  private tituloListar() {
    this.resizeGrid();
    this.titulo = 'Lista';

  }
  private tituloEditar() {
    this.resizeGrid();
    this.titulo = 'Editar';

  }
  private tituloNovo() {
    this.resizeGrid();
    this.titulo = 'Cadastrar';
  }
}
