import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Area } from 'src/app/classes/Area';
import { Atividade } from 'src/app/classes/Atividade';
import { AuxEstoqueRM } from 'src/app/classes/AuxEstoqueRM';
import { Cultura } from 'src/app/classes/Cultura';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { EnumStatusOS, EnumTipoOS, OrdemServico } from 'src/app/classes/OrdemServico';
import { Planejamento } from 'src/app/classes/Planejamento';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { Safra } from 'src/app/classes/Safra';
import { DetalhesOrdemServicoComponent } from 'src/app/components/ordem-servico/ordem-servico/componentes/detalhes-ordem-servico/detalhes-ordem-servico.component';
import { ItemsOrdemServicoComponent } from 'src/app/components/ordem-servico/ordem-servico/componentes/items-ordem-servico/items-ordem-servico.component';
import { AtividadeService } from 'src/app/services/atividade.service';
import { GrupoOperacaoService } from 'src/app/services/grupo-operacao.service';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { StorageService } from 'src/app/services/storage.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { GrupoOperacao } from '../../../../../classes/GrupoOperacao';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ordem-servico-avulsa-modal',
  templateUrl: './ordem-servico-avulsa-modal.component.html',
})
export class OrdemServicoAvulsaModalComponent implements OnInit, OnChanges, AfterViewInit  {

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  @ViewChild('DetalhesOrdemServicoComponent', { static: false }) detalhesOrdemServicoComponent: DetalhesOrdemServicoComponent;
  
  @Input() area: Area;
  @Input() safra: Safra;
  @Input() planejamentoInput: Planejamento;
  @Input() culturas: Cultura[];
  @Input() id: number;
  @Input() areas: Area[];
  @Input() safras: Safra[];

  setorId: number
  
  planejamento: Planejamento = new Planejamento();

  itemTipo1HasErrors: boolean = false;
  itemTipo2HasErrors: boolean = false;
  itemTipo3HasErrors: boolean = false;
  itemTipo4HasErrors: boolean = false;
  itemTipo5HasErrors: boolean = false;

  @ViewChild('Insumos', { static: false }) itemComponente1: ItemsOrdemServicoComponent;
  @ViewChild('Servicos', { static: false }) itemComponente2: ItemsOrdemServicoComponent;
  @ViewChild('AdubacaoLinha', { static: false }) itemComponente3: ItemsOrdemServicoComponent;
  @ViewChild('PulverizacaoSulco', { static: false }) itemComponente4: ItemsOrdemServicoComponent;
  @ViewChild('TratamentoSementes', { static: false }) itemComponente5: ItemsOrdemServicoComponent;

  ordemServico: OrdemServico = this.getOs();
  getOs(): OrdemServico {
    const os = new OrdemServico();
    os.Status = EnumStatusOS.Aberta;
    os.Tipo = EnumTipoOS.Avulsa;
    if(this.area){
      os.AreaId = this.area.Id;
      os.FazendaId = this.area.FazendaId;
      this.setorId = this.area.SetorId;
      os.AreaPrevista = this.area.AreaTotal; 
      if(this.safra) {
        os.SafraId = this.safra.Id;
      }
      if(this.planejamentoInput){
        os.CulturaId = this.planejamentoInput.CulturaId;
      }
    }
    return os;
  }


  planejamentoId: number;
  inserindo: boolean = true;
  carregando: boolean = false;

  form: any;
  isSubmit: boolean;

  atividades: Atividade[];
  auxAtividades: Atividade[];

  possuiInsumos: boolean = false;
  possuiServicos: boolean = false;
  organizaPorSetor: boolean = false;

  processosPlanejamento: ProcessoPlanejamento[];
  locaisEstoque: LocalEstoque[] = [];
  quantidadeEstoques: string[] = [];
  locaisEstoqueString: string[] = [];
  undString: string[] = [];
  filiaisString: string[] = [];
  produtoString: string = "";
  AuxEstoqueRM: AuxEstoqueRM[] = [];
  filialId: number[] = [];
  gruposOperacao: GrupoOperacao[];

  constructor(
    private ordensServicoService: OrdemServicoService,
    private atividadeService: AtividadeService,
    private grupoOperacaoService: GrupoOperacaoService,
    private storageService: StorageService,
    private processoPlanejamentoService: ProcessoPlanejamentoService,
    private localEstoqueService: LocalEstoqueService,
    private cdr: ChangeDetectorRef,
    private router: Router
    ) {

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.area) {
      this.ordemServico.AreaId = this.area.Id;
      this.ordemServico.FazendaId = this.area.FazendaId;
      this.setorId = this.area.SetorId;
      this.ordemServico.AreaPrevista = this.area.AreaTotal; 
      if(this.safra) {
        this.ordemServico.SafraId = this.safra.Id;
      }
      if(this.planejamentoInput){
        this.ordemServico.CulturaId = this.planejamentoInput.CulturaId;
      }
    }
    if(changes.planejamentoInput){
      if(this.planejamentoInput)
      this.ordemServico.CulturaId = this.planejamentoInput.CulturaId;
    }
  }
  showModalOS(){
    this.Modal.show();
  }

  ngOnInit() {

    // Evento capturado ao salvar os
    this.ordensServicoService.osMultiplaCriadaEvento().subscribe(data => {
      const url = `/#/ordem-servico/ordem-servico?OSsAvulsaId=${data.Ids}`;
      window.open(url, '_blank');
      this.fecharModal();
    });

    this.ordensServicoService.osSalvaEvento().subscribe(data => {
      const currentUrl = this.router.url;
      if(!currentUrl.includes('mapa')) return;
      const url = '/#/ordem-servico/ordem-servico/' + data.Id;
      window.open(url, '_blank');
      this.fecharModal();
    });
    
    this.organizaPorSetor = this.storageService.getEmpresa().UsaSetores;
    this.localEstoqueService.getLocaisEstoque().subscribe(ret => {
      this.locaisEstoque = ret;
    });

    this.processoPlanejamentoService.getProcessosPlanejamento().subscribe(ret => {
      this.processosPlanejamento = ret;
    });

    this.atividadeService.getAtividades().subscribe(ret => {
      let atividades: Atividade[] = ret;

      this.grupoOperacaoService.getGruposOperacao().subscribe(ret => {
        this.gruposOperacao = ret;
        atividades.forEach(a => {
          let grupo = this.gruposOperacao.filter(g => g.Id == a.GrupoOperacaoId);
          a.GrupoOperacaoDescricao = grupo.length > 0 ? grupo[0].Descricao : '';
        });
        this.auxAtividades = this.atividades = atividades;
      });
    });

    if (this.inserindo) {
      this.ordemServico.Tipo = EnumTipoOS.Avulsa;
      this.ordemServico.Status = EnumStatusOS.Aberta;
      this.ordemServico.FazendaId = this.storageService.getFazenda() ? this.storageService.getFazenda().Id : 0;
    }

  }

  changeAtividade() {
    this.limparItens();
    if(!this.ordemServico.AtividadeId) {
      this.possuiInsumos = false;
      this.possuiServicos = false;
    }
    else
    {
      this.possuiInsumos = this.getAtividade(this.ordemServico.AtividadeId).UtilizaInsumo;
      this.possuiServicos = this.getAtividade(this.ordemServico.AtividadeId).UsaServico;
    }
  }

  getAtividade(id: number): Atividade {
    let nova = new Atividade();
    
    if (!this.atividades)
      return nova;
    nova.UtilizaInsumo = true;

    let atividades = this.atividades.filter(x =>
      x.Id == id
    );

    return atividades.length > 0 ? atividades[0] : nova;
  }

  itensHasErrors(): boolean {
    const itemErrors = [
      this.itemTipo1HasErrors, 
      this.itemTipo2HasErrors, 
      this.itemTipo3HasErrors, 
      this.itemTipo4HasErrors, 
      this.itemTipo5HasErrors
    ];
    return itemErrors.some(error => error);
  }

  fecharModal() {
    this.ordemServico = this.getOs();
    this.isSubmit = false;
    this.Modal.hide();
  }

  save() {
    if (this.ordemServico.AreasMultiplas.length > 0) {
      const itens = (
        (this.itemComponente1 ? this.itemComponente1.itensOrdemServico : [])
          .concat(this.itemComponente2 ? this.itemComponente2.itensOrdemServico : [])
          .concat(this.itemComponente3 ? this.itemComponente3.itensOrdemServico : [])
          .concat(this.itemComponente4 ? this.itemComponente4.itensOrdemServico : [])
          .concat(this.itemComponente5 ? this.itemComponente5.itensOrdemServico : [])
      )
      this.ordensServicoService.save(this.ordemServico, itens);
    } else
      this.ordensServicoService.save(this.ordemServico);
  }

  atualizaAreaItens() {
    this.detalhesOrdemServicoComponent.validarTamanhoArea();
  }

  limparItens() {
    if(this.itemComponente1) this.itemComponente1.itensOrdemServico = [];
    if(this.itemComponente2) this.itemComponente2.itensOrdemServico = [];
    if(this.itemComponente3) this.itemComponente3.itensOrdemServico = [];
    if(this.itemComponente4) this.itemComponente4.itensOrdemServico = [];
    if(this.itemComponente5) this.itemComponente5.itensOrdemServico = []; 
  }

}