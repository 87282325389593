import { EnumStatusSafra } from "../Safra"

export class ItemPlanejamentoSafra {
  NomeArea:string = "";
  Hectares: number = 0;
  Variedade:string = "";
  DataPlantio: Date = new Date();
  Status: EnumStatusSafra;
  SafraId: number;
  AreaId: number;
  CulturaId: number;
  FazendaId: number;
  ProducaoKg: number = 0;
}
