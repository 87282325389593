import { ParametrosCompras } from 'src/app/classes/ParametrosCompras'
import { ParametrosComprasService } from './../../../services/parametros-compras.service'
import { Component, OnInit } from '@angular/core'
import { UtilsService } from 'src/app/utils/utils.service'
import Swal from 'sweetalert2'
import { Transacao } from 'src/app/classes/Transacao'
import { TransacaoService } from 'src/app/services/transacao.service'

@Component({
  selector: 'app-parametros-compras',
  templateUrl: './parametros-compras.component.html',
  styleUrls: ['./parametros-compras.component.scss'],
})
export class ParametrosComprasComponent implements OnInit {
  constructor(
    private parametroComprasService: ParametrosComprasService,
    private transacaoService: TransacaoService,
    private utils: UtilsService,
  ) {}
  parametroCompras: ParametrosCompras
  showBtnNovo: boolean = true
  inserindo: boolean = false;
  isSubmit: boolean
  transacoes: Transacao[] = []
  ngOnInit() {
    this.parametroCompras = new ParametrosCompras()
    this.transacaoService.getTransacoes().subscribe(res => {
      this.transacoes = res;
    },
    (err) => {
      this.utils.getErro(err)
    })
    this.selecionar()
  }
  selecionar() {
    this.parametroComprasService.getList().subscribe(
      (res) => {
        if (res.length > 0) {
          this.showBtnNovo = false
          this.parametroCompras = res[0]
        }
      },
      (err) => {
        this.utils.getErro(err)
      },
    )
  }
  inserir() {
    this.parametroCompras = new ParametrosCompras()
    this.showBtnNovo = false;
    this.inserindo = true;
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true
      return
    }
    if (this.parametroCompras.Id > 0)
      this.parametroComprasService
        .put(this.parametroCompras.Id, this.parametroCompras)
        .subscribe(
          (res) => {
            this.utils.handleSuccess('Parâmetros de Compra alterados')
          },
          (err) => {
            this.utils.getErro(err)
          },
        )
    else
      this.parametroComprasService.post(this.parametroCompras).subscribe(
        (res) => {
          this.utils.handleSuccess('Parâmetros de Compra cadastrados')
          this.inserindo = false;
        },
        (err) => {
          this.utils.getErro(err)
        },
      )
  }
  excluir() {
    Swal.fire({
      title: 'Deletar este Parametro Compra?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.parametroComprasService.delete(this.parametroCompras.Id).subscribe(
          (res) => {
            this.utils.handleSuccess('Parametro Compra deletado!')
            this.showBtnNovo = true
          },
          (err) => {
            this.utils.getErro(err)
          },
        )
      }
    })
  }
}
