import { EnumStatusSafra, Safra } from 'src/app/classes/Safra';
import { ListaTipoManutencao, ListaOrigemManutencao } from './../../../../classes/gestao-lite/ManutencaoFrota';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Usuario } from 'src/app/classes/Usuario';
import { ManutencaoFrota } from 'src/app/classes/gestao-lite/ManutencaoFrota';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { SafraService } from 'src/app/services/safra.service';
import { FrotaService } from 'src/app/services/frota.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ItemManutencaoFrota } from 'src/app/classes/gestao-lite/ItemManutencaoFrota';

@Component({
  selector: 'app-custo-frota',
  templateUrl: './custo-frota.component.html',
  styleUrls: ['./custo-frota.component.scss']
})
export class CustoFrotaComponent implements OnInit {
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  @Output() fechou: EventEmitter<any> = new EventEmitter();
  id: number = 0;
  isSubmit: boolean = false;
  

  constructor(private usuarioService: UsuarioService,
    private safraService: SafraService, private frotaService: FrotaService,
    private utils: UtilsService) {
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.Modal.show();
  }

  fechar(){
    this.Modal.hide();
    this.fechou.emit();
  }


  selecionarEquipamento(equipamentoId: number){
    this.id = equipamentoId;
  }


  salvar(){
    
  }
}
