import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CustomFormsModule } from 'ng2-validation';
import { NgxMaskModule } from 'ngx-mask';
import { CardModule } from 'src/app/theme/shared/components';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { SharedFarmModule } from '../../shared-farm/shared-farm.module';
import { CentroDeResultadosComponent } from './centro-de-resultados.component';
import { FormsCentroDeResultadosComponent } from './forms-centro-de-resultados/forms-centro-de-resultados.component';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    SharedFarmModule,
    NgSelectModule
  ],
  declarations: [
    CentroDeResultadosComponent, 
    FormsCentroDeResultadosComponent
  ],
  exports: [
    CentroDeResultadosComponent, 
    FormsCentroDeResultadosComponent
  ],
})
export class CentroDeResultadosModule { }
