import { Funcionario } from '../../../../classes/Funcionario';
import { EnumStatusSafra, Safra } from 'src/app/classes/Safra';
import { ListaTipoManutencao, ListaOrigemManutencao, EnumOrigemManutencao, EnumTipoManutencao } from '../../../../classes/gestao-lite/ManutencaoFrota';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Usuario } from 'src/app/classes/Usuario';
import { ManutencaoFrota } from 'src/app/classes/gestao-lite/ManutencaoFrota';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { SafraService } from 'src/app/services/safra.service';
import { FrotaService } from 'src/app/services/frota.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ItemManutencaoFrota } from 'src/app/classes/gestao-lite/ItemManutencaoFrota';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { Subject } from 'rxjs';
import { Equipamento } from 'src/app/classes/Equipamento';
import { ProdutoService } from 'src/app/services/produto.service';
import { FornecedoresService } from 'src/app/services/cotacao/Fornecedores.service';
import { Fornecedores } from 'src/app/classes/cotacao/Fornecedores';
import { ApontamentoFrotaService } from 'src/app/services/apontamento-frota.service';
import { LocalEstoque } from 'src/app/classes/LocalEstoque';
import { LocalEstoqueService } from 'src/app/services/local-estoque.service';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { ChecklistService } from 'src/app/services/checklist.service';
import { Checklist } from 'src/app/classes/Checklist';
import { ChecklistManutencaoFrota } from 'src/app/classes/gestao-lite/ChecklistManutencaoFrota';
import { ItemManutencaoFrotaService } from 'src/app/services/item-mautencao-frota.service';
import { ChecklistManutencaoFrotaItemManutencaoFrota } from 'src/app/classes/gestao-lite/ChecklistManutencaoFrotaItemManutencaoFrota';
import { ItemChecklistService } from 'src/app/services/item-checklist.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ItemChecklist } from 'src/app/classes/ItemChecklist';
import { ItemChecklistManutencaoFrota } from 'src/app/classes/gestao-lite/ItemChecklistManutencaoFrota';

@Component({
  selector: 'app-manutencao',
  templateUrl: './manutencao.component.html',
  styleUrls: ['./manutencao.component.scss']
})
export class ManutencaoComponent implements OnInit {
  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;
  @ViewChild('ModalDiagnostico', { static: false })
  ModalDiagnostico: UiModalComponent;

  @Input() id: number = 0;
  @Output() fechou: EventEmitter<any> = new EventEmitter();

  manutencao: ManutencaoFrota;
  listaTipoManutencao = ListaTipoManutencao;
  listaOrigemManutencao = ListaOrigemManutencao;
  usuarios: Usuario[] = [];
  funcionarios: Funcionario[] = [];
  safras: Safra[] = [];
  itensRateio: any[] = [];
  itensManutencaoFrota: ItemManutencaoFrota[] = [];
  checklists: Checklist[] = [];
  checklistsModal: Checklist[] = [];
  idCheckistSelecionado: number = 0;
  locaisEstoque: LocalEstoque[];
  checklistManutencaoFrotas: ChecklistManutencaoFrota[] = [];
  itensChecklistManutencaoFrota: ItemChecklistManutencaoFrota[] = [];
  //Errado
  checklistProdutos: ChecklistManutencaoFrotaItemManutencaoFrota[] = [];


  carregando: boolean = false;
  isSubmit: boolean = false;
  ultimoMedidor: number = 0;
  variacaoMedidor: number = 0;
  possuiProdutos: boolean = false;
  possuiRateio: boolean = false;
  possuiServicos: boolean = false;
  possuiChecklist: boolean = false;
  possuiChecklistProdutos: boolean = false;
  totalRateio: number = 0;
  percentualTotal: number = 100;
  equipamento: Equipamento;
  fornecedores: Fornecedores;
  checklistManutencaoFrota: ChecklistManutencaoFrota;
  itemManutencaoIdModal: number = 0;
  isPreventiva: boolean = false;

  //Select
  public inputResponsavel$ = new Subject<string | null>();

  constructor(private fornecedoresService: FornecedoresService,
    private usuarioService: UsuarioService,
    private safraService: SafraService,
    private frotaService: FrotaService,
    private utils: UtilsService,
    private equipamentoService: EquipamentoService, private produtoService: ProdutoService,
    private apontamentoFrotaService: ApontamentoFrotaService,
    private localEstoqueService: LocalEstoqueService,
    private funcionarioService: FuncionarioService,
    private checklistService: ChecklistService,
    private itemManutencaoFrotaService: ItemManutencaoFrotaService,
    private itemChecklistService: ItemChecklistService) {
    this.manutencao = new ManutencaoFrota();
  }

  ngOnInit() {
    if (this.id)
      this.manutencao.Id = this.id;

    if (!this.manutencao.Id) {
      this.manutencao.DataManutencao = new Date();
      this.manutencao.OrigemManutencao = EnumOrigemManutencao.Interna;
      this.manutencao.TipoManutencao = EnumTipoManutencao.Corretiva;
    }
    this.usuarioService.getUsuarios().subscribe((data) => {
      this.usuarios = data;
    });

    this.funcionarioService.getFuncionarios().subscribe((data) => {
      this.funcionarios = data;
    });

    this.safraService.getSafras().subscribe((data) => {
      this.safras = data;
      this.safras = this.safras.filter(x => x.Status == EnumStatusSafra.EmAndamento);
    });

    this.localEstoqueService.getLocaisEstoque().subscribe((data) => {
      this.locaisEstoque = data;
    });

    this.fornecedoresService.get().subscribe(res => {
      this.fornecedores = res;
    });

    this.checklistService.Listar().subscribe((data) => {
      this.checklists = data;
    });

    if (this.manutencao.Id > 0) {
      this.carregando = true;
      this.frotaService.getManutencaoId(this.id).subscribe((data) => {
        this.manutencao = data;
        if(this.manutencao.ItensManutencaoFrota.filter(x=>x.IsServico == false).length>0)
           this.possuiProdutos = true;
        if(this.manutencao.ItensManutencaoFrota.filter(x=>x.IsServico == true).length>0)
          this.possuiServicos = true; 
        this.carregando = false;
      });
    }
  }

  calcRateio() {
    this.percentualTotal = 100;
    this.totalRateio = 0;
    this.itensRateio.forEach((rateio) => {
      this.totalRateio += rateio.Percentual;
      this.percentualTotal -= rateio.Percentual;
    })
  }

  ngAfterViewInit() {
    this.Modal.show();
  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

  removeItem(i) {
    this.itensRateio.splice(i, 1);
    if (this.itensRateio.length == 0)
      this.possuiRateio = false;

    this.percentualTotal = 100;
    this.itensRateio.forEach((rateio) => {
      this.percentualTotal += rateio.Percentual;
    });
  }

  removeItensProdutos(i) {
    this.itensManutencaoFrota.splice(i, 1);
  }

  selecionarEquipamento(equipamentoId: number) {
    this.manutencao.EquipamentoId = equipamentoId;
    this.equipamentoService.getEquipamento(equipamentoId).subscribe(ret => {
      this.equipamento = ret;
      this.apontamentoFrotaService.GetUltimoMedidor(equipamentoId).subscribe(ret => {
        this.ultimoMedidor = ret;
      });
    });
  }

  calcularVariacaoMedidor() {
    this.variacaoMedidor = this.manutencao.MedidorAtual - this.ultimoMedidor;
  }

  novoItemRateio() {
    this.percentualTotal = 100;
    this.itensRateio.forEach((rateio) => {
      this.percentualTotal -= rateio.Percentual;
    });
    this.itensRateio.push({
      Id: this.itensRateio.length + 1,
      SafraId: 0,
      Percentual: this.percentualTotal,
    });
    this.possuiRateio = true;
  }

  novoProdutoManutencaoFrota() {
    let novo = new ItemManutencaoFrota();
    novo.IsServico = false;
    novo.Id = this.itensManutencaoFrota.length + 1;
    this.itensManutencaoFrota.push(novo);
    this.possuiProdutos = true;
  }

  novoServicoManutencaoFrota() {
    let novo = new ItemManutencaoFrota();
    novo.IsServico = true;
    novo.Id = this.itensManutencaoFrota.length + 1;
    this.itensManutencaoFrota.push(novo);
    this.possuiServicos = true;
  }

  selecionarProduto(item: ItemManutencaoFrota, produtoId: number) {
    item.ProdutoId = produtoId;
    this.produtoService.getProduto(produtoId).subscribe((produto) => {
      item.Quantidade = 1;
      item.ValorUnitario = produto.PrecoUnitario;
      this.changeTotal(item);
    });
  }

  changeTotal(item: ItemManutencaoFrota) {
    item.ValorTotal = item.Quantidade * item.ValorUnitario;
    this.calcIntens();
  }

  calcIntens() {
    this.manutencao.CustoTotalProdutos = 0;
    this.manutencao.CustoTotalServicos = 0;
    this.itensManutencaoFrota.forEach((item) => {
      if (item.IsServico) {
        if (item.ValorTotal > 0)
          this.manutencao.CustoTotalServicos += item.ValorTotal;
      } else {
        if (item.ValorTotal > 0)
          this.manutencao.CustoTotalProdutos += item.ValorTotal;
      }
    });
    this.manutencao.CustoTotal = this.manutencao.CustoTotalProdutos + this.manutencao.CustoTotalServicos;
  }

  salvar() {
    if (this.manutencao.OrigemManutencao == EnumOrigemManutencao.Externa && this.manutencao.FornecedorId == null) {
      this.utils.handleWarning("O Campo de fornecedor é obrigatório");
      return;
    }

    //Valida rateio
    let totalRateio = 0;
    this.itensRateio.forEach((rateio) => {
      totalRateio += rateio.Percentual;
    })

    if (this.possuiRateio && (totalRateio != 100)) {
      this.utils.handleWarning("O rateio total das safras deve ser igual a 100%");
      return;
    }

    this.itensRateio.forEach((rateio) => {
      if (rateio.Percentual == 0 || rateio.Percentual > 100)
        this.utils.handleWarning("Exite ratio com valor inválido");
    });


    this.isSubmit = true;
    this.carregando = true;
    this.manutencao.ItensManutencaoFrota = this.itensManutencaoFrota;
    this.manutencao.ItensSafra = this.itensRateio;
    this.manutencao.ChecklistManutencaoFrota = this.checklistManutencaoFrotas;
    if (this.manutencao.FornecedorId == 0)
      this.manutencao.FornecedorId = null;
    if (!this.manutencao.Id || this.manutencao.Id == 0) {
      this.frotaService.postManutencao(this.manutencao).subscribe((data) => {
        this.manutencao = data;
        this.carregando = false;
        this.utils.handleSuccess("Manutencao inserido com sucesso!");
        this.fechar();
        // this.frotaService.postSafra({ SafraId: this.manutencao.SafraId, ManutencaoFrotaId: data.Id }).subscribe((data) => {
        //   console.log(data);
        //   this.carregando = false;
        //   this.utils.handleSuccess("Manutencao inserido com sucesso!");
        // },
        //   (error) => {
        //     this.carregando = false;
        //     this.utils.getErro(error);
        //   });
      },
        (error) => {
          this.carregando = false;
          this.utils.getErro(error);
        });
    }
    else {
      this.frotaService.putManutencao(this.manutencao.Id, this.manutencao).subscribe((data) => {
        this.carregando = false;
        this.utils.handleSuccess("Manutencao atualizada com sucesso!");
        this.fechar();
      },
        (error) => {
          this.carregando = false;
          this.utils.getErro(error);
        });
    }
  }

  changeResponsavel(id: number) {
  }

  getItensManutencaoFrota(manutencaoFrotaId: number) {
    this.itemManutencaoFrotaService.getItensManutencaoFrota(manutencaoFrotaId).subscribe(data => {
      this.itensManutencaoFrota = data;
    });
  }

  novoDiagnosticoManutencaoFrota() {
    let novo = new ChecklistManutencaoFrota();
    novo.Id = this.checklistManutencaoFrotas.length + 1;
    this.checklistManutencaoFrotas.push(novo);
    this.possuiChecklist = true;
  }

  removeDiagnosticoManutencaoFrota(i) {
    this.checklistManutencaoFrotas.splice(i, 1);
  }

  selecionarChecklist(checklists: number[]) {
    console.log("cheklists: ", checklists);

    //Verificar se o checklist foi removido
    this.checklistManutencaoFrotas.forEach(x => {
      if (!checklists.includes(x.ChecklistId))
        this.removerChecklist(x.ChecklistId);
    })

    //Verificar se o checklist foi adicionado
    checklists.forEach(x => {
      if (!this.checklistManutencaoFrotas.find(y => y.ChecklistId == x))
        this.inserirChecklist(x);
    })
  }

  inserirChecklist(idChecklist: number) {
    let checklist = new ChecklistManutencaoFrota();
    checklist.ChecklistId = idChecklist;
    checklist.ChecklistId = idChecklist;
    checklist.ManutencaoFrotaId = this.manutencao.Id;
    this.checklistManutencaoFrotas.push(checklist);
    this.inserirItensChecklists(idChecklist);
    this.idCheckistSelecionado = 0;
  }

  removerChecklist(idChecklist: number) {
    let checklist = this.checklistManutencaoFrotas.find(x => x.ChecklistId == idChecklist);
    if (checklist) {
      this.checklistManutencaoFrotas.splice(this.checklistManutencaoFrotas.indexOf(checklist), 1);
      this.itensChecklistManutencaoFrota.map(x => {
        if (x.ChecklistId == idChecklist)
          this.excluirItensChecklists(x.ItemChecklistId);
      });
    }
  }

  inserirItensChecklists(idChecklist: number) {
    this.itemChecklistService.Listar(idChecklist).subscribe(dt => {
      if (dt) {
        dt.forEach(x => {
          let item = new ItemChecklistManutencaoFrota();
          item.ItemChecklistId = x.Id;
          item.Descricao = x.Descricao;
          item.ChecklistId = idChecklist;
          item.ManutencaoFrotaId = this.manutencao.Id;
          item.FlagConcluido = false;
          this.itensChecklistManutencaoFrota.push(item);
        });
      }
    });
  }

  excluirItensChecklists(id: number) {//Continue daqui, e termine!
    let item = this.itensChecklistManutencaoFrota.find(x => x.ItemChecklistId == id);
    if (item.Id > 0) {
    }
    else {
      this.itensChecklistManutencaoFrota = this.itensChecklistManutencaoFrota.filter(x => x.ItemChecklistId != id);
    }
  }

  getDescricaoChecklist(id: number): string {
    let checklist = this.checklists.find(x => x.Id == id);
    if (checklist)
      return checklist.Descricao;
    return "";
  }

  preencheChecklistProdutos(item: ItemManutencaoFrota) {
    console.log(item)
    console.log(this.checklistManutencaoFrotas)
    this.itemManutencaoIdModal = item.Id;
    this.checklistProdutos = [];
    if (item.Checklist)
      this.checklistProdutos = item.Checklist;

    if (this.checklistProdutos.length > 0)
      this.possuiChecklistProdutos = true;
    else
      this.possuiChecklistProdutos = false;

    this.checklistsModal = [];
    this.checklistManutencaoFrotas.forEach(x => {
      this.checklistsModal.push(x.Checklist);
    });
    this.getTotalItensChecklist();
    this.ModalDiagnostico.show();
  }

  fecharDiagnostico() {
    this.ModalDiagnostico.hide();
  }

  getChecklistId(item: ChecklistManutencaoFrotaItemManutencaoFrota) {
    let check = this.checklistManutencaoFrotas.find(x => x.Id == item.ChecklistManutencaoFrotaId);
    if (check) {
      return check.ChecklistId;
    }
    return 0;
  }

  selecionarChecklistProduto(item: ChecklistManutencaoFrotaItemManutencaoFrota, checklistId: number) {
    let check = this.checklistManutencaoFrotas.find(x => x.ChecklistId == checklistId);
    if (check) {
      item.ItemManutencaoFrotaId = this.itemManutencaoIdModal;
      item.ChecklistManutencaoFrotaId = check.Id;
    }
    this.getTotalItensChecklist();
    console.log(item);
  }

  novoDiagnosticoProduto() {
    let novo = new ChecklistManutencaoFrotaItemManutencaoFrota();
    novo.ItemManutencaoFrotaId = this.itemManutencaoIdModal;
    this.checklistProdutos.push(novo);
    this.possuiChecklistProdutos = true;
    console.log(this.checklistProdutos);
  }

  removeDiagnosticoProduto(i) {
    this.checklistProdutos.splice(i, 1);
    if (this.checklistProdutos.length > 0)
      this.possuiChecklistProdutos = true;
    else
      this.possuiChecklistProdutos = false;
  }

  getTotalItensChecklist() {
    this.checklistProdutos.forEach(v => {
      let checklistId = this.getChecklistId(v);
      this.itemChecklistService.Listar(checklistId).subscribe(dt => {
        if (dt)
          v.QtdItens = dt.length;
        else
          v.QtdItens = 0;
      });
    });

  }

  salvarDiagnostico() {
    let item = this.itensManutencaoFrota.find(x => x.Id == this.itemManutencaoIdModal);
    if (item) {
      item.Checklist = []
      this.checklistProdutos.forEach(x => {
        item.Checklist.push(x);
      });
    }
    this.fecharDiagnostico();
  }

  changeTipoManutencao() {
    if (this.manutencao.TipoManutencao == EnumTipoManutencao.Preventiva ||
      this.manutencao.TipoManutencao == EnumTipoManutencao.Preditiva)
      this.isPreventiva = true;
    else
      this.isPreventiva = false;
  }
}