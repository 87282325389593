import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { StorageService } from 'src/app/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TipoBicoService } from './../../../services/tipo-bico.service';
import { TipoBico } from './../../../classes/TipoBico';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-tipo-bico',
  templateUrl: './tipo-bico.component.html',
  styleUrls: ['./tipo-bico.component.scss']
})
export class TipoBicoComponent implements OnInit {

  tiposBicos: TipoBico[];
  auxTiposBicos: TipoBico[];
  tipoBico: TipoBico;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  // tradução
  TIPO_DE_BICO_ALTERADO: string = 'Tipo de Bico alterado!';
  TIPO_DE_BICO_CADASTRADO: string = 'Tipo de Bico cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  TIPO_DE_BICO_DELETADO: string = 'Tipo de Bico deletado!';

  configurarTraducao() {
    this.TIPO_DE_BICO_ALTERADO = this.translate.instant('TIPO_DE_BICO_ALTERADO');
    this.TIPO_DE_BICO_CADASTRADO = this.translate.instant('TIPO_DE_BICO_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.TIPO_DE_BICO_DELETADO = this.translate.instant('TIPO_DE_BICO_DELETADO');
  }

  constructor(private tipoBicoServico: TipoBicoService,
              private route: ActivatedRoute, private router: Router,
              private storageService: StorageService,
              private translate: TranslateService,
              private utils: UtilsService,
              private toasty: CustomToastyService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.tipoBico = new TipoBico();

    if(this.id > 0)
      this.selecionarTipoBico();
    else
      this.listarTipoBico();
  }

  selecionarTipoBico() {
    this.carregando = true;
    this.tipoBicoServico.getTipoBico(this.id).subscribe(res =>{
      this.tipoBico = res;
      this.carregando = false;
    },
    ex =>{
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  listarTipoBico(){
    this.carregando = true;
    this.tipoBicoServico.getTiposBicos().subscribe(
      res => {
        this.tiposBicos = res;
        this.auxTiposBicos = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarTipoBico();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.tipoBico.Id > 0)
      this.tipoBicoServico.putTipoBico(this.tipoBico.Id, this.tipoBico).subscribe(
        res => {
          this.utils.handleSuccess(this.TIPO_DE_BICO_ALTERADO);
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.tipoBicoServico.postTipoBico(this.tipoBico).subscribe(
        res => {
          this.utils.handleSuccess(this.TIPO_DE_BICO_CADASTRADO);
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(TipoBicoId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR + ' '+ nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.tipoBicoServico.deleteTipoBico(TipoBicoId).subscribe(
            res => {
              this.utils.handleSuccess(this.TIPO_DE_BICO_DELETADO);
              this.listarTipoBico();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarTipoBico(){
    this.tiposBicos = this.auxTiposBicos.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/gerenciador/tipo-bico']);
  }
}
