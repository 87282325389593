import { Component, OnInit, Input, EventEmitter, Output, OnChanges, ElementRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isDate } from 'util';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() data: Date;
  @Input() required: boolean;
  @Input() ocultarMsgObrigatorio: boolean = false;
  @Input() isSubmit: boolean;
  @Input() placeholder: string = 'dd/mm/yyyy';
  @Input() disabled: boolean;
  @Input() auxClass: string;
  @Output() dataChange: EventEmitter<Date> = new EventEmitter();

  temValor: boolean;

  dsData: NgbDateStruct;

  dataInput: Date;

  constructor(private elementRef: ElementRef) { }

  setData(valor: string){
    this.elementRef.nativeElement.querySelector('#Data').value = valor;
  }

  ngOnInit() {
    //this.dsData = this.retornaDataForm(this.data);
    this.dataInput = this.data;

    this.temValor = this.data != null;
  }

  timeout: any;
  ngOnChanges() {
    //this.dsData = this.retornaDataForm(this.data);

    if (this.timeout)
      clearTimeout(this.timeout);

    this.timeout = setTimeout(() => this.dataInput = this.data, 300);
  }

  retornaDataForm(data: Date): NgbDateStruct{
    if (data == null)
     return null;

    if (Number(data.toString().substr(5,2)) > 0){
      return {year: Number(data.toString().substr(0,4)),
          month: Number(data.toString().substr(5,2)),
          day: Number(data.toString().substr(8,2))};
    } else {
      return {year: data.getFullYear(),
          month: data.getMonth()+1,
          day: data.getDate()};
    }
  }

  retornaData(data: NgbDateStruct):Date{
    if (data == null)
     return null;

    return new Date(data.year, data.month-1, data.day);
  }

  onChange(data: any){
    if (data == ''){
      data = null;
      this.temValor = false;
    }

    if (data && Number(data.toString().substr(5,2)) > 0){
      data = new Date(
        Number(data.toString().substr(0,4)),
        Number(data.toString().substr(5,2))-1,
        Number(data.toString().substr(8,2))
      )

      this.temValor = true;
    }

    this.dataChange.emit(data);
  }

  isValid(dataString) {
    // Expressão regular para verificar o formato YYYY-MM-DD
    var regex = /^\d{4}-\d{2}-\d{2}$/;
  
    // Testar se a string corresponde ao padrão
    if (regex.test(dataString)) {
      // Separar os componentes da data
      var partesData = dataString.split('-');
      var ano = parseInt(partesData[0], 10);
      var mes = parseInt(partesData[1], 10);
      var dia = parseInt(partesData[2], 10);
  
      // Criar uma nova data e verificar se é válida
      var data = new Date(ano, mes - 1, dia);
      if (data.getFullYear() === ano && data.getMonth() === mes - 1 && data.getDate() === dia) {
        return true; // A data é válida
      }
    }
  
    return false; // A data é inválida ou não está no formato correto
  }

}
