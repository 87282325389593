import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ListaEnumTipoNatureza, Natureza } from 'src/app/classes/Natureza';
import { NaturezaService } from 'src/app/services/natureza.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forms-natureza',
  templateUrl: './forms-natureza.component.html',
  styleUrls: ['./forms-natureza.component.scss']
})
export class FormsNaturezaComponent implements OnInit {

  constructor(private naturezaService: NaturezaService, private utils: UtilsService, private router: Router, private location: Location) {
    this.naturezaService.getList()
    .subscribe(res => {
      this.naturezas = res;
      if (this.router.url.search('inserir') > 0)
          document.getElementById("resetarForm").click();
    }, err => {this.utils.getErro(err)})
  }

  @Input() customMask: string;
  @Input() natureza: Natureza;
  @Output() atualizarArvore = new EventEmitter<any>();

  naturezas: Natureza[];
  isSubmit: boolean = false;
  carregando: boolean
  listaTipoNatureza = ListaEnumTipoNatureza;

  ngOnInit() {
  }
  save(form: any, novo: boolean = false) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.natureza.Id > 0)
    {
      if(this.natureza.Codigo == this.natureza.CodigoPai.toString()){
        this.utils.handleWarning('Natureza pai não pode ser ele mesmo!')
          this.carregando = false;
          return;
      }

      this.naturezaService.put(this.natureza.Id, this.natureza).subscribe(
        res => {
          this.utils.handleSuccess('Natureza alterada!')
          this.atualizarArvore.emit();
          this.carregando = false;
          this.novoItem(form, res, novo);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else
      this.naturezaService.post(this.natureza).subscribe(
        res => {
          this.utils.handleSuccess('Natureza cadastrada!')
          this.atualizarArvore.emit();
          this.carregando = false;
          this.novoItem(form, res, novo);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });


  }
  private novoItem(form: any, response: any, novo: boolean){
    if(novo){
      this.isSubmit = false;
      form.reset();
      this.natureza = new Natureza();
      this.naturezaService.getList()
      .subscribe(res2 => {
         this.naturezas = res2;
         this.natureza.CodigoPai = parseInt(response.Codigo);
      }, err => {this.utils.getErro(err)})
    }else
      this.router.navigate(['/cadastros/natureza/'])
  }
  excluir(id: number, nome: string) {
    Swal.fire({
      title: 'Deletar '+ nome + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.naturezaService.delete(id).subscribe(
            res => {
              this.utils.handleSuccess('Natureza deletada!')
              this.router.navigate(['/cadastros/natureza'])
              this.carregando = false;
            },
            err => {
              Swal.fire('Erro!', err.message, 'error');
              this.carregando = false;
            });
        }
      });
  }
  resetarForm(form: any){
    form.reset();
    this.natureza = new Natureza();
  }
}
