import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Empresa } from 'src/app/classes/Empresa';
import { EmpresaService } from 'src/app/services/empresa.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-seleciona-empresa',
  templateUrl: './seleciona-empresa.component.html',
  styleUrls: ['./seleciona-empresa.component.scss']
})
export class SelecionaEmpresaComponent implements OnInit {

  @Input() semLabel: boolean;

  empresas: Empresa[];
  empresaSelecionada: number = 0;

  sub: Subscription;

  constructor(private empresaService: EmpresaService,
              private router: Router) { }

  ngOnInit() {
    this.atualizarEmpresas(false);

    this.sub = this.empresaService.novaEmpresaEmitter.subscribe((obj) => {
      this.atualizarEmpresas(obj.semReload);
    })
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  atualizarEmpresas(semReload: boolean){
    this.empresaService.getEmpresas().subscribe(ret =>{
      this.empresas = ret;
      this.changeEmpresa(1, semReload);
    });
  }

  changeEmpresa(id: number, semReload?: boolean){
    this.empresaSelecionada = id;
    this.empresaService.setEmpresaSelecionada(this.empresas.filter(e => e.Id==id)[0]);

    if (!semReload)
      this.router.navigate(['/painel']);
  }

}
