import { ItemEnum } from "./ItemEnum";

export class Endereco {
    Id: number;
    Descricao:string = "";
    CEP: string = '';
    UF:string = "";
    Cidade:string = "";
    Bairro:string = "";
    Logradouro:string = "";
    Numero:string = "";
    Complemento?:string = "";
    Referencia?:string = "";
    Tipo: number = 0;

    PessoaId?: number = 0;
    EmpresaInternaId?: number;

    EmpresaId: number;
}

export enum EnumTipoEndereco
{
    Principal = 1,
    Correspondencia = 2,
    Entrega = 3,
    Outro = 4
}

export const TipoEndereco: ItemEnum[] = [
    { name: 'Principal', value: EnumTipoEndereco.Principal },
    { name: 'Correspondência', value: EnumTipoEndereco.Correspondencia },
    { name: 'Entrega', value: EnumTipoEndereco.Entrega },
    { name: 'Outro', value: EnumTipoEndereco.Outro }
]

