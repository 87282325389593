import { PlanejamentoModule } from './../planejamento/planejamento.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CustomFormsModule } from 'ng2-validation';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SelecionaEmpresaModule } from '../seleciona-empresa/seleciona-empresa.module';
import { SharedFarmModule } from '../shared-farm/shared-farm.module';
import { CotacaoComponent } from './cotacao/cotacao.component';
import { EnvioPrecosComponent } from './envio-precos/envio-precos.component';
import { AlterarSenhaFornecedorComponent } from './alterar-senha-fornecedor/alterar-senha-fornecedor.component';
import { LoginFornecedorComponent } from './login-fornecedor/login-fornecedor.component';
import { PainelFornecedorComponent } from './painel-fornecedor/painel-fornecedor.component';
import { RecuperarSenhaFornecedorComponent } from './recuperar-senha-fornecedor/recuperar-senha-fornecedor.component';
import { ResumoCotacaoComponent } from './resumo-cotacao/resumo-cotacao.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { OrdemCompraComponent } from './ordem-compra/ordem-compra.component';
import { CardModule } from "../../theme/shared/components/card/card.module";
import { ModalModule } from "../../theme/shared/components/modal/modal.module";
import { CadastrosModule } from '../cadastros/cadastros.module';
import { AnexoCotacaoComponent } from './anexo-cotacao/anexo-cotacao.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient} from '@angular/common/http';


@NgModule({
    declarations: [
        CotacaoComponent,
        EnvioPrecosComponent,
        LoginFornecedorComponent,
        PainelFornecedorComponent,
        AlterarSenhaFornecedorComponent,
        RecuperarSenhaFornecedorComponent,
        ResumoCotacaoComponent,
        OrdemCompraComponent,
        AnexoCotacaoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        CustomFormsModule,
        RouterModule,
        CurrencyMaskModule,
        TextMaskModule,
        NgNumberFormatterModule,
        NgbDatepickerModule,
        SelecionaEmpresaModule,
        SharedFarmModule,
        NgbPaginationModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgSelectModule,
        PlanejamentoModule,
        NgbTabsetModule,
        CardModule,
        ModalModule,
        CadastrosModule,
        FileUploadModule,
        TranslateModule.forChild({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            },
            defaultLanguage: 'pt'
        }),
    ],
    exports :[
        AlterarSenhaFornecedorComponent,
        FileUploadModule,
    ]
})
export class CotacaoModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
