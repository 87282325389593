import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustoEquipamento, TipoCusto } from 'src/app/classes/CustoEquipamento';
import { CustoEquipamentoService } from 'src/app/services/custo-equipamento.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-custo-equipamento',
  templateUrl: './custo-equipamento.component.html',
  styleUrls: ['./custo-equipamento.component.scss']
})
export class CustoEquipamentoComponent implements OnInit {

  @Input() equipamentoId: number = 0;

  custoEquipamento: CustoEquipamento;
  listaCustoEquipamento: CustoEquipamento[] = [];
  auxCustoEquipamentos: CustoEquipamento[] = [];

  listaTipoCusto = TipoCusto;


  id: number = 0;

  inserindo: boolean = false;
  editando: boolean = false;
  textoBusca: string = '';

  carregando: boolean = false;
  isSubmit: boolean;

  // tradução
  CUSTO_ALTERADO: string = 'Custo alterado!';
  CUSTO_CADASTRADO: string = 'Custo cadastrado!';
  DELETAR: string = 'Deletar?';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CUSTO_EXCLUIDO: string = 'Custo excluído!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';

  constructor(
    private custoEquipamentoService: CustoEquipamentoService,
    private utils: UtilsService,
    private router: Router,
    private toasty: CustomToastyService,
    private translate: TranslateService) {
  }

  ngOnInit() {

    this.custoEquipamento = new CustoEquipamento();

    if (this.equipamentoId > 0)
      this.listar();
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.configurarTraducao();

    this.custoEquipamento.EquipamentoId = this.equipamentoId;
    this.carregando = true;

    if (this.custoEquipamento.Id > 0) {
      this.custoEquipamentoService.Atualizar(this.custoEquipamento.Id, this.custoEquipamento).subscribe(
        res => {
          this.utils.handleSuccess(this.CUSTO_ALTERADO);
          this.custoEquipamento = new CustoEquipamento();
          this.custoEquipamento.DataCusto = null;
          this.listar();
          this.carregando = false;
          this.isSubmit = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
    else
      this.custoEquipamentoService.Inserir(this.custoEquipamento).subscribe(
        res => {
          this.utils.handleSuccess(this.CUSTO_CADASTRADO);
          this.listaCustoEquipamento.push(res);
          this.custoEquipamento = new CustoEquipamento();
          this.custoEquipamento.DataCusto = null;
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    if (this.equipamentoId > 0)
      this.listar();
  }

  selecionar() {
    this.carregando = true;
    this.custoEquipamentoService.ObterPorId(this.id).subscribe(res => {
      this.custoEquipamento = res;
      this.carregando = false;
    },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });

  }

  listar() {
    this.carregando = true;
    this.custoEquipamentoService.Listar(this.equipamentoId).subscribe(
      res => {
        console.log("custoEquipamento", res);
        this.listaCustoEquipamento = res;
        this.auxCustoEquipamentos = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrar();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }



  excluir(id: number) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR,
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.custoEquipamentoService.Excluir(id).subscribe(
          res => {
            this.utils.handleSuccess(this.CUSTO_EXCLUIDO);
            this.listar();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  editar(id: number) {
    this.id = id;
    this.selecionar();
  }

  filtrar() {
    this.listaCustoEquipamento = this.auxCustoEquipamentos.filter(x =>
      x.Id.toString().includes(this.textoBusca)
    );
  }

  cancelar() {
    this.custoEquipamento = new CustoEquipamento();
    this.id = 0;
    this.router.navigate([`/ordem-servico/equipamento/${this.equipamentoId}`]);
  }
  configurarTraducao() {
    this.CUSTO_ALTERADO = this.translate.instant('CUSTO_ALTERADO');
    this.CUSTO_CADASTRADO = this.translate.instant('CUSTO_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CUSTO_EXCLUIDO = this.translate.instant('CUSTO_EXCLUIDO');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
  }
}
