import { ItemEnum } from "./ItemEnum";

export class ProcessoPlanejamento {
    Id: number = 0;
    Descricao:string = "";
    Ordem: number = 0;
    ReferenciaDAP: enumReferenciaDAP;
    Plantio: boolean;
    Colheita: boolean;
    TratamentoSemente: boolean;
}

export enum enumReferenciaDAP
{
    AntesPlantio = 0,
    DepoisPlantio = 1,
    DepoisColheita = 2,
    Nenhum = 3
}

export const ReferenciasDAP: ItemEnum[] = [
    {name: 'Dias Antes do Plantio', value: enumReferenciaDAP.AntesPlantio},
    {name: 'Dias Depois do Plantio', value: enumReferenciaDAP.DepoisPlantio},
    {name: 'Dias Depois da Colheita', value: enumReferenciaDAP.DepoisColheita},
    {name: 'Não Informado', value: enumReferenciaDAP.Nenhum}
  ];
