import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { GraficoQuantitativo } from "src/app/classes/GraficoQuantitativo";

@Component({
  selector: "app-grafico-quantitativo",
  templateUrl: "./grafico-quantitativo.component.html",
  styleUrls: ["./grafico-quantitativo.component.scss"],
})
export class GraficoQuantitativoComponent {
  @Input() grafico: GraficoQuantitativo;

  constructor(private router: Router) {}

  clickIcon(link: string) {
    let options = {
      rota: link.split("-")[0],
      tipo: link.split("-")[1],
      status: link.split("-")[2],
    };
    this.router.navigate([`${options.rota}`], {
      queryParams: { tipo: options.tipo, status: options.status },
    });
  }
}
