import { SubstituirProduto } from './../classes/SubstituirProduto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Produto } from '../classes/Produto';
import { EstoqueProduto } from '../classes/gestao-lite/EstoqueProduto';
import { RelatorioMovimentoEstoque } from '../classes/movimento-estoque/RelatorioMovimentoEstoque';
import { FiltroProdutoMovimentoEstoque } from '../classes/movimento-estoque/FiltroProdutoMovimentoEstoque';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  constructor(private http: HttpClient) { }

  getProdutos(): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto`)
  }

  getSoTodosProdutosPaginando(pagina: number, tamanhoPagina: number, grupoProdutoId: number, busca: String): Observable<any> {
    if (!(busca == null || busca == undefined || busca == ''))
      return this.http.get(`${environment.apiURL}/produto/listarTodosPaginandoCategoria/${pagina}/${tamanhoPagina}/${grupoProdutoId}/${busca}/1`)
    else
      return this.http.get(`${environment.apiURL}/produto/listarTodosPaginandoCategoria/${pagina}/${tamanhoPagina}/${grupoProdutoId}/1`)
  }

  getSoTodosServicosPaginando(pagina: number, tamanhoPagina: number, grupoProdutoId: number, busca: String): Observable<any> {
    if (!(busca == null || busca == undefined || busca == ''))
      return this.http.get(`${environment.apiURL}/produto/listarTodosPaginandoCategoria/${pagina}/${tamanhoPagina}/${grupoProdutoId}/${busca}/2`)
    else
      return this.http.get(`${environment.apiURL}/produto/listarTodosPaginandoCategoria/${pagina}/${tamanhoPagina}/${grupoProdutoId}/2`)
  }

  getTodosProdutosPaginando(pagina: number, tamanhoPagina: number, grupoProdutoId: number, busca: String): Observable<any> {
    if (!(busca == null || busca == undefined || busca == ''))
      return this.http.get(`${environment.apiURL}/produto/listarTodosPaginando/${pagina}/${tamanhoPagina}/${grupoProdutoId}/${busca}`)
    else
      return this.http.get(`${environment.apiURL}/produto/listarTodosPaginando/${pagina}/${tamanhoPagina}/${grupoProdutoId}`)
  }

  getProdutosPaginando(planejamentoId: number, pagina: number, busca: String, isServico: boolean, isPlantio: boolean): Observable<any> {
    if (!(busca == null || busca == undefined || busca == ''))
      return this.http.get(`${environment.apiURL}/produto/listarPaginando/${planejamentoId}/${pagina}/${isServico}/${isPlantio}/${busca}`)
    else
      return this.http.get(`${environment.apiURL}/produto/listarPaginando/${planejamentoId}/${pagina}/${isServico}/${isPlantio}`)
  }

  getProdutosPaginandoSemCultivar(planejamentoId: number, pagina: number, busca: String, isServico: boolean, isPlantio: boolean): Observable<any> {
    if (!(busca == null || busca == undefined || busca == ''))
      return this.http.get(`${environment.apiURL}/produto/listarPaginandoCultivar/${planejamentoId}/${pagina}/${isServico}/${isPlantio}/false/${busca}`)
    else
      return this.http.get(`${environment.apiURL}/produto/listarPaginandoCultivar/${planejamentoId}/${pagina}/${isServico}/${isPlantio}/false/`)
  }

  getProdutosPaginandoOS(ordemServicoId: number, pagina: number, busca: String, isServico: boolean): Observable<any> {
    if (!(busca == null || busca == undefined || busca == ''))
      return this.http.get(`${environment.apiURL}/produto/listarPaginandoOS/${ordemServicoId}/${pagina}/${isServico}/${busca}`)
    else
      return this.http.get(`${environment.apiURL}/produto/listarPaginandoOS/${ordemServicoId}/${pagina}/${isServico}`)
  }

  geEstoqueProduto(produtoId: number, localId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto/estoque/${produtoId}/${localId}`)
  }

  getProduto(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto/${id}`)
  }

  getProdutoLocalEstoque(id: number, localEstoque: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto/${id}/${localEstoque}`)
  }

  getProdutoLocalEstoqueData(id: number, localEstoque: number, dataReferencia: Date): Observable<any> {
    if (dataReferencia == null || dataReferencia == undefined)
      dataReferencia = new Date();
    let dataString = '';
    try{
      dataString = dataReferencia.toISOString();
    }
    catch
    {
      dataString = dataReferencia.toString();
    }
    return this.http.get(`${environment.apiURL}/produto/${id}/${localEstoque}/${dataString}`);
  }

  listarPorDescricao(descricao: string): Observable<Produto[] | null> {
    return this.http.get<Produto[] | null>(`${environment.apiURL}/produto/listarPorDescricao/${descricao}`)
  }

  postProduto(produto: Produto): Observable<any> {
    return this.http.post(`${environment.apiURL}/produto`, produto);
  }

  putProduto(id: number, produto: Produto): Observable<any> {
    return this.http.put(`${environment.apiURL}/produto/${id}`, produto);
  }

  deleteProduto(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/produto/${id}`);
  }

  sincronizarErp(threadId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto/sincronizarerp/${threadId}`);
  }

  sincronizarErpPeriodico(): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto/sincronizarerpperiodico`);
  }

  getProdutosPorIds(ids: number[]): Observable<any> {
    return this.http.post(`${environment.apiURL}/produto/listarporids`, ids)
  }

  substituirProduto(obj: SubstituirProduto): Observable<any> {
    return this.http.post(`${environment.apiURL}/produto/SubstituirProduto`, obj)
  }

  atualizarProdutoErp(produtoId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto/atualizarProdutoErp/${produtoId}`);
  }
  atualizarPrecos(): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto/sincroniaPreco/`);
  }

  //Gestao Lite
  getEstoqueProdutos(pagina: number, tamanhoPagina: number, grupoProdutoId: number, busca: String): Observable<any> {
    let estoqueProtudoMok: EstoqueProduto[] = [];
    for (let index = 0; index < 10; index++) {
      let estoqueProduto = new EstoqueProduto();
      estoqueProduto.ProdutoId = index;
      estoqueProduto.ProdutoDescricao = `Produto ${index}`;
      estoqueProduto.LocalEstoqueId = index;
      estoqueProduto.LocalEstoqueDescricao = `Local ${index}`;
      estoqueProduto.UnidadeMedida = 'KG';
      estoqueProduto.Saldo = index;
      estoqueProduto.Reserva = index;
      estoqueProduto.Disponivel = index;
      estoqueProduto.Entradas = index;
      estoqueProduto.Saidas = index;
      estoqueProduto.DataUltimaEntrada = new Date();
      estoqueProduto.DataUltimaSaida = new Date();
      estoqueProduto.PrecoMedio = index;
      estoqueProduto.ValorEstoque = index;
      estoqueProtudoMok.push(estoqueProduto);
    }
    return new Observable<any>(observer => {
      observer.next(estoqueProtudoMok);
      observer.complete();
    });
  }

  atualizarProdutos(): Observable<any> {
    return this.http.post(`${environment.apiURL}/produto/preencherBaseAgriq`, null);
  }

  atualizarBaseAgriq(): Observable<any> {
    return this.http.post(`${environment.apiURL}/produto/forcarAgriq`, null);
  }

  quantidadeEstoque(produtoId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/produto/QuantidadeEstoqueGeral/${produtoId}`);
  }

  relatorioMovimentacao(filtro: FiltroProdutoMovimentoEstoque): Observable<RelatorioMovimentoEstoque[] | null> {

    return this.http.post<RelatorioMovimentoEstoque[] | null>(`${environment.apiURL}/MovimentoEstoque/RelatoriosProdutos`, filtro);
  }


}
