import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Checklist, TipoChecklist, TipoIntervalo } from 'src/app/classes/Checklist';
import { ChecklistService } from 'src/app/services/checklist.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import Swal from 'sweetalert2';
import { ItemChecklistComponent } from '../item-checklist/item-checklist.component';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {

  @ViewChild(ItemChecklistComponent, { static: false }) itemChecklistComponent: ItemChecklistComponent;

  checklist: Checklist;
  listaChecklist: Checklist[] = [];
  auxChecklists: Checklist[] = [];

  tipoChecklist = TipoChecklist;
  tipoIntervalo = TipoIntervalo;

  id: number = 0;

  inserindo: boolean = false;
  editando: boolean = false;
  textoBusca: string = '';

  carregando: boolean = false;
  isSubmit: boolean;
  touched = false;

  //traduções
  FEITO: string = "Feito!";
  CHECKLIST_ALTERADO: string = "Checklist  alterado!";
  CHECKLIST_CADASTRADO: string = "Checklist cadastrado!";
  CONFIRMAR_DELETE: string = "Se deletar, esta ação não poderá ser desfeita!";
  CANCELAR: string = "Cancelar";
  SIM: string = "Sim";
  CHECKLIST_DELETADA: string = "Checklist deletada!";
  DELETAR_PARAM: string;

  constructor(
    private checklistService: ChecklistService,
    private router: Router,
    private route: ActivatedRoute,
    private toasty: CustomToastyService,
    private utils: UtilsService,
    private translate: TranslateService
  ) {
    this.checklist = new Checklist();
  }

  ngOnInit() {
    try {
      this.inserindo = this.router.url.search("inserir") > 0;
      {
        this.id = 0;
        this.checklist = new Checklist();
      }
    }
    catch
    { }
    this.route.params.subscribe(res => {
      if (res.id != "inserir")
        this.id = res.id;
      else
        this.id = 0;
    });
    if (this.id > 0) {
      this.selecionarChecklist();
    }
    else {
      if (!this.inserindo)
        this.listarChecklists();
    }
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.FEITO = this.translate.instant("FEITO");
    this.CHECKLIST_ALTERADO = this.translate.instant("CHECKLIST_ALTERADO");
    this.CHECKLIST_CADASTRADO = this.translate.instant("CHECKLIST_CADASTRADO");

    if (this.checklist.Id > 0)
      this.checklistService.Atualizar(this.checklist.Id, this.checklist).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.CHECKLIST_ALTERADO, 'success');
          this.carregando = false;
          this.router.navigate([`/cadastros/checklist/${this.id}`]);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.checklistService.Inserir(this.checklist).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.CHECKLIST_CADASTRADO, 'success');
          this.carregando = false;
          this.checklist = res;
          this.router.navigate([`/cadastros/checklist/${res.Id}`]);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  selecionarChecklist() {
    this.carregando = true;
    this.checklistService.ObterPorId(this.id).subscribe(res => {
      this.checklist = new Checklist();
      this.checklist = res;
      console.log(this.checklist);
      this.carregando = false;
      this.listarItens();
    },
      ex => {
        this.utils.getErro(ex);
        this.carregando = false;
      })
  }

  listarChecklists() {
    this.carregando = true;

    this.checklistService.Listar().subscribe(
      res => {
        this.listaChecklist = res;
        this.auxChecklists = res;
        this.carregando = false;
        if (this.textoBusca != '')
          this.filtrarChecklists();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }



  excluir(checklistId: number, nome: string) {
    this.CONFIRMAR_DELETE = this.translate.instant("CONFIRMAR_DELETE");
    this.CANCELAR = this.translate.instant("CANCELAR");
    this.SIM = this.translate.instant("SIM");
    this.CHECKLIST_DELETADA = this.translate.instant("CHECKLIST_DELETADA");
    this.FEITO = this.translate.instant("FEITO");
    this.DELETAR_PARAM = this.translate.instant("DELETAR_PARAM", { value: nome });

    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.CONFIRMAR_DELETE,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.checklistService.Excluir(checklistId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.CHECKLIST_DELETADA, 'success');
            this.listarChecklists();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  filtrarChecklists() {
    this.listaChecklist = this.auxChecklists.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca)
    );
  }

  cancelar() {
    this.router.navigate(['/cadastros/checklist']);
  }

  novoItem() {
    this.itemChecklistComponent.novoItem(this.id);
  }

  listarItens() {
    this.itemChecklistComponent.checklistId = this.id;
    this.itemChecklistComponent.listarItemChecklists();
  }

}
