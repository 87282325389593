import { TipoBico } from './../classes/TipoBico';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TipoBicoService {

  constructor(private http: HttpClient) { }

  getTiposBicos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/tipoBico`)
  }

  getTipoBico(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/tipoBico/${id}`)
  }

  postTipoBico(TipoBico: TipoBico): Observable<any>{
    return this.http.post(`${environment.apiURL}/tipoBico`, TipoBico);
  }

  putTipoBico(id: number, TipoBico: TipoBico): Observable<any>{
    return this.http.put(`${environment.apiURL}/tipoBico/${id}`, TipoBico);
  }

  deleteTipoBico(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/tipoBico/${id}`);
  }
}
