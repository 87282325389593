import { ItemEnum } from "./ItemEnum"

export class Projeto {
  Id: number
  Codigo: string
  Descricao: string
  CodigoPai: number
  IsAnalitico: boolean
  Abreviacao: string
  Inicial: Date
  Final: Date
  IsAberto: boolean
  Tipo: EnumTipoProjeto

  _codigo: number = 0;
}

export enum EnumTipoProjeto {
  Producao = 1,
  Investimento = 2,
  Outros = 3,
}

export const ListaEnumTipoProjeto: ItemEnum[] = [
  {name: 'Produção', value: EnumTipoProjeto.Producao},
  {name: 'Investimento', value: EnumTipoProjeto.Investimento},
  {name: 'Outros', value: EnumTipoProjeto.Outros},
];
