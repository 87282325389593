import { Injectable } from '@angular/core';
import { utils, writeFileXLSX } from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class JsonToXlsxConvertService {
  constructor() { }

  gerarXLSX(json: any[], headers: string[] ,nomeArquivo: string) {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);

    utils.sheet_add_aoa(ws, [headers])
    utils.sheet_add_json(ws, json, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Relatório');
    writeFileXLSX(wb, `${nomeArquivo}.xlsx`);
  }
}
