import { EnumEfeitoFinanceiro } from "./enum/EnumEfeitoFinanceiro";
import { EnumTipoItem } from "./enum/EnumTipoItem";

export class Transacao{

  public Numero: number = 0;

  public Id: number;
  public DataCadastro:  Date;

  // cabecalho
  public GrupoTransacaoId: number;
  public GrupoTransacaoDescricao:string = "";
  public Descricao:string = "";

  // configuracoes gerais
  public TipoItem: EnumTipoItem; // EnumTipoItem;
  public ExigeCentroResultado: boolean;
  public ExigeProjeto: boolean;
  public CentroResultadoItem: boolean;
  public ProjetoItem: boolean;
  public InformarEquipamentoItem: boolean;
  public InformaFazendaItem: boolean;
  public RateiaAutomaticamentoItem: boolean;
  public PermiteRecebimentoParcial: boolean;
  public PermiteAlterarConfirmar: boolean;
  public NumeracaoAuto: boolean;
  public BaseNumeracao: number = 0; // EnumBaseNumeracao;
  public TipoNumeracao: number = 0; // EnumTipoNumeracao;
  public ValidarNumeracao: boolean;
  public PrestacaoServico: boolean;

  // compras
  public ExigeCotacao: boolean;
  public BuscaProdutosSafra: boolean;
  public SugereFornecedoresHistoricoCompras: boolean;
  public QuantidadeFornecedoresSugerir: boolean;
  public ExigePrazoEntrega: boolean;
  public CompradorPadraoId: number;
  public PrazoPadraoAtendimento: boolean;
  public RequerAprovacao: boolean;
  public UsaAlcadaAprovacao: boolean;
  public AprovadorPadraoId: number;

  // estoque
  public ReposicaoEstoque: boolean;
  public EfeitoSobreEstoque: number = 0; // EnumEfeitoSobreEstoque;
  public ReservaEstoqueConfirmacao: boolean;
  public AceitaProdutoRepetido: boolean;
  public ValidaEstoqueMaximo: boolean;
  public LocalEstoque: number = 0; // EnumLocalEstoque

  // financeiro
  public EfeitoFinanceiro: number = EnumEfeitoFinanceiro.Nenhum;
  public TipoFinanceiro: number = 0; // EnumTipoFinanceiro
  public ExigeAutorizacaoPagamento: boolean;
  public ExigeAutorizaBaixa: boolean;
  public UsaDataPrevisaoBaixa: boolean;
  public ExigeHistoricoFinanceiro: boolean;
  public HistoricoPadraoFinanceiro:string = "";
  public GerarFinanceiroAtravesDoItemDeMovimento: boolean;

  // contabilidade
  public Contabiliza: boolean;
  public ExigeComplementoContabilidade: boolean;
  public ComplementoContabilidade:string = "";

  // NFe
  public EnviaNfe: boolean;
  public ModeloDocumento:string = "";
  public TransacaoDenegadaId: number;
  public TransacaoPendenteRetornoId: number;
  public TipoEmissao: number = 0; // EnumTipoEmissao
  public CodigoModeloImpressaoDANFE: number = 0;

  // fiscal
  public NaoAfetaLCDPR: boolean;
  public TemIPI: boolean;
  public TemICMS: boolean;
  public TemICMSST: boolean;

  // notificacoes
  public EnviaNotificacaoInclusao: boolean;
  public NotificarUsuarioCriacaoRecebimento: boolean;
  public NotificarUsuarioCriacaoNaAprovacao: boolean;
  public NotificarUsuarioCriacaoNaReprovacao: boolean;
  public NotificarUsuarioCriacaoNaRevisao: boolean;

  //destinos
  public DestinosIds: Array<number>;


  //descrições
  public GrupoTransacaoDesc:string = "";
  public TipoItemDesc:string = "";
  public BaseNumeracaoDesc:string = "";
  public TipoNumeracaoDesc:string = "";
  public CompradorPadraoDesc:string = "";
  public AprovadorPadraoDesc:string = "";
  public EfeitoSobreEstoqueDesc:string = "";
  public LocalEstoqueDesc:string = "";
  public EfeitoFinanceiroDesc:string = "";
  public TipoFinanceiroDesc:string = "";
  public TipoEmissaoDesc:string = "";

  public MovimentoSerieId: number;
}
