import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LancamentoFinanceiro } from '../classes/LancamentoFinanceiro';
import { MovimentoSerie } from '../classes/MovimentoSerie';

@Injectable({
  providedIn: 'root'
})
export class MovimentoSerieService {

  constructor(private http: HttpClient) { }

  getList(): Observable<any>{
    return this.http.get(`${environment.apiURL}/MovimentoSerie`)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/MovimentoSerie/${id}`)
  }

  post(obj: MovimentoSerie): Observable<any>{
    return this.http.post(`${environment.apiURL}/MovimentoSerie`, obj);
  }

  put(id: number, obj: MovimentoSerie): Observable<any>{
    return this.http.put(`${environment.apiURL}/MovimentoSerie/${id}`, obj);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/MovimentoSerie/${id}`);
  }
}
