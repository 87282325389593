import { produtoSimilar } from './../../classes/cotacao/produtoSimilar';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { precoCotacaoItens } from 'src/app/classes/cotacao/precoCotacaoItens';


@Injectable({
  providedIn: 'root'
})
export class precoCotacaoItensService {

  constructor(private http: HttpClient) { }

  get(): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacaoItens`);
  }

  getPorId(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacaoItens/${id}`);
  }

  post(precoCotacaoItens: precoCotacaoItens): Observable<any> {
    return this.http.post(`${environment.apiURL}/PrecoCotacaoItens`, precoCotacaoItens);
  }

  put(id: number, precoCotacaoItens: precoCotacaoItens): Observable<any> {
    return this.http.put(`${environment.apiURL}/PrecoCotacaoItens/${id}`, precoCotacaoItens);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/PrecoCotacaoItens/${id}`);
  }

  selecionaPorCotacaoFornecedor(cotacaoId: number, fornecedorId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacaoItens/cotacao/${cotacaoId}/${fornecedorId}`);
  }

  selecionaPorCotacao(cotacaoId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacaoItens/cotacao/${cotacaoId}`);
  }
  salvarEtapa(FornecedorId: number, precoCotacaoItens: precoCotacaoItens[]): Observable<any>
  {
    return this.http.post(`${environment.apiURL}/PrecoCotacaoItens/Etapa/${FornecedorId}`, precoCotacaoItens);
  }
  salvarEtapaSemValidar(FornecedorId: number, precoCotacaoItens: precoCotacaoItens[]): Observable<any>
  {
    return this.http.post(`${environment.apiURL}/PrecoCotacaoItens/EtapaSemValidar/${FornecedorId}`, precoCotacaoItens);
  }
  salvarProdutoSimiliar(precoCotacaoIntesId: number, produtoSimilar: produtoSimilar): Observable<any>
  {
    return this.http.post(`${environment.apiURL}/PrecoCotacaoItens/ProdutoSimiliar/${precoCotacaoIntesId}`, produtoSimilar);
  }
  getProdutoSimilar(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacaoItens/GetProdutoSimiliar/${id}`);
  }
  trocaIndisponivel(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/PrecoCotacaoItens/TrocaIndisponivel/${id}`);
  }
}
