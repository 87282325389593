export class Sistema {
    Id: number;
    Descricao:string = "";
    Ativo: boolean = true;
    Itens: ItemSistema[] = [];

    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
}

export class ItemSistema {
    Id: number;
    Descricao:string = "";
    Ativo: boolean = true;
    SistemaId: number;

    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
}
