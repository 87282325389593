export class TipoLancamento {
    Id: number = 0;
    Descricao:string = "";
    Tipo: number = 0;
    AfetaCusto: boolean;
    AfetaConsumo: boolean;
    Desconto: boolean;
}

export enum enumTipoLancamento
{
    Bandeira = 1,
    Consumo = 2,
    Demanda = 3,
    Desconto = 4,
    Reativa = 5
}
