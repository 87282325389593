import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { GrupoCompra } from 'src/app/classes/GrupoCompra';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { Pessoa } from 'src/app/classes/Pessoa';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { GrupoCompraService } from 'src/app/services/grupo-compra.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { PessoaService } from 'src/app/services/pessoa.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grupo-compra',
  templateUrl: './grupo-compra.component.html',
  styleUrls: ['./grupo-compra.component.scss']
})
export class GrupoCompraComponent implements OnInit {

  gruposCompra: GrupoCompra[] = [];
  auxGruposCompra: GrupoCompra[] = [];
  grupoCompra: GrupoCompra = new GrupoCompra();
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean = false;
  textoBusca: string = "";
  perfilLogado: number = 0;

  listaPessoa: Pessoa[] = [];
  listaGrupoProduto: GrupoProduto[] = [];
  listaEmpresaInterna: EmpresaInterna[] = [];

  dicPessoa: {[key: number]: Pessoa} = {};
  dicGrupoProduto: {[key: number]: GrupoProduto} = {};
  dicEmpresaInterna: {[key: number]: EmpresaInterna} = {};


  constructor(
    private grupoCompraService: GrupoCompraService,
    private route: ActivatedRoute,
    private router: Router,
    private pessoaService: PessoaService,
    private empresaInternaService: EmpresaInternaService,
    private grupoProdutoService: GrupoProdutoService,
    private storageService: StorageService,
    private toasty: CustomToastyService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.grupoCompra = new GrupoCompra();

    this.listarUsuarios();
    this.listarGrupoProduto();
    this.listarEmpresaInterna();

    if(this.id > 0)
      this.selecionarGrupoCompra();
    else
      this.listar();
  }

  selecionarGrupoCompra() {
    this.carregando = true;
    this.grupoCompraService.get(this.id).subscribe(res =>{
      this.grupoCompra = new GrupoCompra();
      this.grupoCompra = res;
      this.carregando = false;
    },
    ex =>{
      Swal.fire('Erro!', ex.error.ExceptionMessage, 'error');
      this.carregando = false;
    })
  }

  listar(){
    this.carregando = true;
    this.grupoCompraService.toList().subscribe(
      res => {
        this.gruposCompra = res;
        this.auxGruposCompra = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrar();
      },
      err => {
        Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.grupoCompra.Id > 0)
      this.grupoCompraService.put(this.grupoCompra.Id, this.grupoCompra).subscribe(
        res => {
          this.toasty.show('Feito!', 'Grupo de Compra alterado!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/grupo-compra']);
        },
        err => {
          Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });
    else
      this.grupoCompraService.post(this.grupoCompra).subscribe(
        res => {
          this.toasty.show('Feito!', 'Grupo de Compra cadastrado!', 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/grupo-compra']);
        },
        err => {
          Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
          this.carregando = false;
        });

  }

  excluir(grupoCompraId: number, nome: string) {
    Swal.fire({
      title: 'Deletar '+ nome + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.grupoCompraService.delete(grupoCompraId).subscribe(
            res => {
              this.toasty.show('Feito!', 'Grupo de Compra deletado!', 'success');
              this.listar();
              this.carregando = false;
            },
            err => {
              Swal.fire('Erro!', err.message, 'error');
              this.carregando = false;
            });
        }
      });
  }

  filtrar(){
    this.gruposCompra = this.auxGruposCompra.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca)
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/grupo-compra']);
  }

  listarUsuarios(){
    this.pessoaService.getList().subscribe({
      next: (res) => {
          this.listaPessoa = res;
          this.dicPessoa = {};
          res.forEach(objeto => {
            this.dicPessoa[objeto.Id] = objeto;
          });
      },
      error: (err) => {
        console.error('Ocorreu um erro:', err);
      },
      complete: () => {
        console.log('Busca pessoa concluída.');
      }
    });
  }

  listarGrupoProduto() {
    this.grupoProdutoService.getGruposProduto().subscribe({
      next: (res) => {
          this.listaGrupoProduto = res;
          this.dicGrupoProduto  = {};
          res.forEach(objeto => {
            this.dicGrupoProduto[objeto.Id] = objeto;
          });
      },
      error: (err) => {
        console.error('Ocorreu um erro:', err);
      },
      complete: () => {
        console.log('Busca GrupoProduto concluída.');
      }
    });
  }

  listarEmpresaInterna() {
    this.empresaInternaService.getEmpresasInternas().subscribe({
      next: (res) => {
          this.listaEmpresaInterna = res;
          this.dicEmpresaInterna  = {};
          res.forEach(objeto => {
            this.dicEmpresaInterna[objeto.Id] = objeto;
          });
      },
      error: (err) => {
        console.error('Ocorreu um erro:', err);
      },
      complete: () => {
        console.log('Busca EmpresaInterna concluída.');
      }
    });
  }

}
