import { ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { EnumTipoMovimento } from 'src/app/classes/GrupoTransacao';
import { EnumEventoAprovacao, EnumTipoEventoAprovacao, ListaEnumEventoAprovacao, ListaEnumTipoEventoAprovacao, RegistrosAprovacoes } from 'src/app/classes/RegistrosAprovacoes';
import { Usuario } from 'src/app/classes/Usuario';
import { ResponseApi } from 'src/app/classes/auxiliar/ResponseApi';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { RegistroAprovacoesService } from 'src/app/services/registros-aprovacoes.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-central-aprovacoes',
  templateUrl: './central-aprovacoes.component.html',
  styleUrls: ['./central-aprovacoes.component.scss']
})
export class CentralAprovacoesComponent implements OnInit {

  @ViewChild('modalConfirmacao', { static: false }) modalAprovacao;
  @ViewChild('gridItensComponente', { static: false }) gridItensComponente;
  coldefs: ColDef[] = [];
  coldefsItens: ColDef[] = [];
  coldefsItensMovimento: ColDef[] = [];
  coldefsItensCotacao: ColDef[] = [];
  dataGrid: RegistrosAprovacoes[] = null;
  dataGridFilter: RegistrosAprovacoes[] = null;
  dataGridItens: any[] = [];
  model: RegistrosAprovacoes = new RegistrosAprovacoes();
  selecionados: RegistrosAprovacoes[] = [];
  showForms = false;
  showItens = true;
  titulo = 'Lista';
  listaEnumTipoEventoAprovacao = ListaEnumTipoEventoAprovacao;
  listaEnumEventoAprovacao =  ListaEnumEventoAprovacao;
  aprovador: Usuario = new Usuario();
  msgFiltro = 'Em aprovação';

  constructor(private service: RegistroAprovacoesService, private utils: UtilsService, private storageService: StorageService, private cdRef: ChangeDetectorRef ) { }

  ngOnInit() {
    this.configurar();
  }

  // ag-grid
  configurar(){
    this.aprovador = this.storageService.getUsuario();
    this.coldefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: 'Descrição', field: 'Descricao', flex: 1},
      { headerName: 'Status', field: 'Evento', cellRenderer: (params) => this.formatarEvento(params.data.Evento), flex: 1},
      { headerName: 'Aprovador', valueFormatter: (params) => this.aprovador.Nome, flex: 1},
      { field: 'DataEvento', valueFormatter: (params) => this.utils.formatarData(params.data.DataEvento), flex: 1},
      { field: 'GetTipoEvento', headerName: 'Tipo', cellRenderer: (params) => this.formatarTipoEvento(params.data.TipoEvento), flex: 1},
      { field: 'Valor', valueFormatter: (params) => `R$ ${this.utils.formatarDecimais(params.data.Valor)}`, flex: 1},
      { field: 'DataCadastro', valueFormatter: (params) => this.utils.formatarData(params.data.DataCadastro), flex: 1},
      { field: 'UsuarioCadastroId', headerName: 'Criado por', valueFormatter: (params) => params.data.UsuarioCadastro.Nome, flex: 1},
    ];
    this.coldefsItensMovimento = [
      { headerName: 'Codigo Produto', field: 'ProdutoId'},
      { headerName: 'Descrição', field: 'ProdutoId', valueFormatter: (params) => params.data.Produto.Descricao},
      { headerName: 'Unidade Medida', field: 'Un' },
      { headerName: 'Quantidade', cellStyle: { 'text-align': 'right' }, field: 'QuantidadePedida', valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.QuantidadePedida)}` },
      { headerName: 'Valor Unitário', field: 'ValorUnitario', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => `R$ ${this.utils.formatarDecimais(params.data.ValorUnitario)}`},
      { headerName: 'Valor Total', field: 'ValorTotalLiquido', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => `R$ ${this.utils.formatarDecimais(params.data.ValorTotalLiquido)}` },
      { headerName: 'Natureza', field: 'NaturezaId', valueFormatter: (params) => params.data.Natureza.Descricao },
      { headerName: 'Centro Resultado', field: 'CentroDeResultadosId', valueFormatter: (params) => params.data.CentroDeResultados.Descricao },
      { headerName: 'Projeto', field: 'ProjetoId', valueFormatter: (params) => params.data.Projeto.Descricao },
    ];
    this.coldefsItensCotacao = [
      { flex: 1,headerName: 'Codigo Produto', field: 'ProdutoId'},
      { flex: 1,headerName: 'Descrição', field: 'ProdutoId', valueFormatter: (params) => params.data.Produto.Descricao},
      { flex: 1,headerName: 'Unidade Medida', field: 'UnidadeMedida' },
      { flex: 1,headerName: 'Quantidade', cellStyle: { 'text-align': 'right' }, field: 'QuantidadeOriginal', valueFormatter: (params) => `${this.utils.formatarDecimais(params.data.QuantidadeOriginal)}` },
      { flex: 1,headerName: 'Valor Unitário', field: 'ValorVencedor', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => `R$ ${this.utils.formatarDecimais(params.data.ValorVencedor)}`},
      { flex: 1,headerName: 'Valor Total', field: '', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => this.valorTotal(params.data.ValorVencedor, params.data.QuantidadeOriginal)},
    ]

    // this.coldefsItensFinanceiro= [
    //   { headerName: 'Codigo Produto', field: '??'},
    //   { headerName: 'Descrição', field: '??'},
    //   { headerName: 'Unidade Medida', field: '??' },
    //   { headerName: 'Quantidade Sistema', field: '??', cellStyle: { 'text-align': 'right' } },
    //   { headerName: 'Quantidade Contada', field: '??', cellStyle: { 'text-align': 'right' } },
    //   { headerName: 'Diferença', field: '' , valueFormatter: (params) => '?????'},
    //   { headerName: 'Preço Unitário', field: '', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => '?????' },
    //   { headerName: 'Valor Acerto', field: '', cellStyle: { 'text-align': 'right' }, valueFormatter: (params) => '?????'},

    // ]
    this.listar();
    this.cdRef.detectChanges();
  }
  listar(){
    this.service.get()
    .subscribe((res: ResponseApi<RegistrosAprovacoes[]>) => {
      if(res.Success)
        this.dataGrid = res.Data;
        this.dataGridFilter = this.dataGrid.filter(p => p.Evento == EnumEventoAprovacao.EmAprovacao);
    }, err => this.utils.swalError(err.error.Error));
  }
  formatarEvento(evento: number){
    switch(evento){
      case EnumEventoAprovacao.Aprovado:
        return `<span class="badge badge-light-success custom-badge-grid">Aprovado</span>`
      case EnumEventoAprovacao.Cadastrado:
        return `<span class="badge badge-light-secondary custom-badge-grid">Cadastrado</span>`
      case EnumEventoAprovacao.Reprovado:
        return `<span class="badge badge-light-danger custom-badge-grid">Reprovado</span>`
      case EnumEventoAprovacao.Revisar:
        return `<span class="badge badge-light-warning custom-badge-grid">Revisar</span>`
      case EnumEventoAprovacao.EmAprovacao:
        return `<span class="badge badge-light-primary custom-badge-grid">Em Aprovação</span>`
    }
  }
  formatarTipoEvento(tipoEvento: number){
    switch(tipoEvento){
      case EnumTipoEventoAprovacao.Pagamento:
        return `<span class="badge badge-light-secondary custom-badge-grid">Pagamento</span>`
      case EnumTipoEventoAprovacao.Cotacao:
        return `<span class="badge badge-light-secondary custom-badge-grid">Cotação</span>`
      case EnumTipoEventoAprovacao.PedidoCompras:
        return `<span class="badge badge-light-secondary custom-badge-grid">Pedido de Compra</span>`
      case EnumTipoEventoAprovacao.SolicitacaoCompras:
        return `<span class="badge badge-light-secondary custom-badge-grid">Solicitação de Compra</span>`
    }
  }
  valorTotal(valor: number, quantidade: number){
    return `R$ ${this.utils.formatarDecimais(valor * quantidade)}`
  }
  cellDoubleClicked(event) {
    this.tituloEditar();
    this.model = event.data;

  }

  onCellClicked(row) {
    this.model = row.data;
    this.showItens = false;
    switch(row.data.TipoEvento){
      case EnumTipoMovimento.PedidoCompras:
      case EnumTipoMovimento.SolicitacaoCompras:
        this.coldefsItens = this.coldefsItensMovimento;
        this.dataGridItens = this.model.ItensMovimentos;
        break;
      case EnumTipoMovimento.Cotacao:
        this.coldefsItens = this.coldefsItensCotacao;
        this.dataGridItens = this.model.ItensCotacao;
        break;
    }
    setTimeout(() => {
      this.showItens = true;
    }, 600);
  }
  selectionChanged(event) {
    this.selecionados = event;
  }

  filtrarStatus(status: EnumEventoAprovacao ){
    if(status == null){
      this.dataGridFilter = this.dataGrid.filter(p => p.Evento != EnumEventoAprovacao.Cadastrado);
      this.msgFiltro = 'Todos';
      return;
    }
    this.dataGridFilter = this.dataGrid.filter(p => p.Evento == status);
    switch(status){
      case EnumEventoAprovacao.Aprovado:
        this.msgFiltro = 'Aprovados';
        break;
      case EnumEventoAprovacao.Reprovado:
        this.msgFiltro = 'Reprovados';
        break;
      case EnumEventoAprovacao.EmAprovacao:
        this.msgFiltro = 'Em aprovação';
        break;
      case EnumEventoAprovacao.Revisar:
        this.msgFiltro = 'Em revisão';
        break;
      default:
        this.msgFiltro = 'Filtrar';
        break;
    }
  }
  abrirModalConfirmacao(evento: EnumEventoAprovacao){
    this.modalAprovacao.abrirModal(evento);
  }
  alterarStatus(obj){
    if(this.selecionados.length <= 0){
      this.utils.handleWarning('Selecione um regristro para continuar.');
      return;
    }
    this.model.Observacao = obj.obs;
    this.selecionados.forEach( p => {
      p.Observacao = obj.obs;
      this.service.alterarStatus(p, obj.evento)
      .subscribe((res:ResponseApi<any>) => {
        if(res.Success){
          let msg = 'Registro alterado com sucesso';
          switch(obj.evento){
            case EnumEventoAprovacao.Aprovado:
              msg = 'Registro aprovado!';
              break;
            case EnumEventoAprovacao.Reprovado:
              msg = 'Registro reprovado!';
              break;
            case EnumEventoAprovacao.Revisar:
              msg = 'Registro enviado para revisão!';
              break;
          }
          this.modalAprovacao.cancelar();
          this.utils.handleSuccess(msg);
          this.listar();
        }
      }, err => {
        this.utils.swalError(err.error.Error)
      })
    })

  }

  //
  private tituloListar() {
    this.titulo = 'Lista';
  }
  private tituloEditar() {
    this.titulo = 'Editar';
  }
  private tituloNovo() {
    this.titulo = 'Cadastrar';
  }
}
