import { NgModule } from "@angular/core";
import { MovimentoEstoqueComponent } from "./movimento-estoque.component";
import { MovimentoEstoqueFiltroComponent } from "./movimento-estoque-filtro/movimento-estoque-filtro.component";
import { CommonModule } from "@angular/common";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { CustomFormsModule } from "ng2-validation";
import { NgxMaskModule } from "ngx-mask";
import { SharedFarmModule } from "../../shared-farm/shared-farm.module";
import { HttpLoaderFactory } from "../cadastros.module";
import { I18n } from "src/app/services/CustomDatepickerI18n";
import { SharedModule } from "src/app/theme/shared/shared.module";
import { MovimentoEstoqueRelatorioComponent } from "./relatorios/movimento-estoque-relatorio.component";
import { MovimentoEstoqueRelatorioListaComponent } from "./relatorios/lista/movimento-estoque-relatorio-lista.component";
import { MovimentoEstoqueAvulsoComponent } from "./avulso/movimento-estoque-avulso.component";
import { MovimentoEstoqueAvulsoFiltroComponent } from "./avulso/filtro/movimento-estoque-avulso-filtro.component";
import { GestaoLiteModule } from "../../gestao-lite/gestao-lite.module";

@NgModule({
  declarations: [
    MovimentoEstoqueComponent,
    MovimentoEstoqueFiltroComponent,
    MovimentoEstoqueRelatorioComponent,
    MovimentoEstoqueRelatorioListaComponent,
    MovimentoEstoqueAvulsoComponent,
    MovimentoEstoqueAvulsoFiltroComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    NgSelectModule,
    SharedFarmModule,
    GestaoLiteModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
    })
  ],
  exports: [],
  providers: [
    [I18n]
  ],
})
export class MovimentoEstoqueModule { }
