import { ItemEnum } from "./ItemEnum";

export class CustoEquipamento {
  public Id: number;
  public EquipamentoId: number;
  public TipoCusto: number = 0;
  public Descricao:string = "";
  public Responsavel:string = "";
  public DataCusto: Date = new Date();
  public Valor: number = 0;
}

export enum EnumTipoCusto {
  Seguro = 1,
  Servicos = 2,
  Depreciacao = 3,
  Outros = 4,
}

export const TipoCusto: ItemEnum[] = [
  {name: 'Seguro', value: EnumTipoCusto.Seguro},
  {name: 'Serviços', value: EnumTipoCusto.Servicos},
  {name: 'Depreciação', value: EnumTipoCusto.Depreciacao},
  {name: 'Outros', value: EnumTipoCusto.Outros}
]
