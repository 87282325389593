import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import {NextConfig} from '../../../../app-config';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public windowWidth: number = 0;
  public nextConfig: any;
  @Output() onNavMobCollapse = new EventEmitter();
  @Output() changeTitle: EventEmitter<string> = new EventEmitter();

  constructor(private router: Router) {
    this.nextConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
  }

  navMobCollapse() {
    if (this.windowWidth < 992) {
      this.onNavMobCollapse.emit();
    }
  }

  changeTitleFun(title: string) {
    this.changeTitle.emit(title);
  }
}
