import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GrupoEquipamento } from '../classes/GrupoEquipamento';
import { FiltroCusto } from '../classes/gestao-lite/FiltroCusto';
import { RelatorioService } from './relatorio.service';

@Injectable({
  providedIn: 'root'
})
export class GrupoEquipamentoService {

  constructor(private http: HttpClient) { }

  getGrupoEquipamentos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoEquipamento`)
  }

  getGrupoEquipamento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/grupoEquipamento/${id}`)
  }

  postGrupoEquipamento(grupoEquipamento: GrupoEquipamento): Observable<any>{
    return this.http.post(`${environment.apiURL}/grupoEquipamento`, grupoEquipamento);
  }

  putGrupoEquipamento(id: number, grupoEquipamento: GrupoEquipamento): Observable<any>{
    return this.http.put(`${environment.apiURL}/grupoEquipamento/${id}`, grupoEquipamento);
  }

  deleteGrupoEquipamento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/grupoEquipamento/${id}`);
  }

  getCustoGrupoEquipamento(): Observable<any>{
    return this.http.get(`${environment.apiURL}/Equipamento/ListarCustoPorGrupoEquipamento`);
  }

  getListaCustos(filtro: FiltroCusto): Observable<any>{
    return this.http.get(`${environment.apiURL}/Equipamento/ListarCusto?${this.toQueryString(filtro)}`);
  }

  formatarData(data: Date): string {
    var year = data.getFullYear().toString();
    var month = (data.getMonth() + 1).toString().padStart(2, '0');
    var day = data.getDate().toString().padStart(2, '0');
    return year + "-" + month + "-" + day;
  }

  public toQueryString(obj: any): string {
    const parts = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        if (obj[key] instanceof Date) {
          const dateStr = this.formatarData(obj[key]);
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(dateStr)}`);
        } else {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
      }
    }
    return parts.join('&');
  }
}
