import { ItemEnum } from "./ItemEnum";

export class Equipamento {
    Id: number;
    Descricao:string = "";
    Potencia: number = 0;
    DoseTanque: number = 0;
    UnidadeMedida:string = "";
    VidaUtilHoras: number = 0;
    GrupoEquipamentoId: number;
    TipoMedidor: EnumTipoMedidorEquipamento;
    LimiteDiarioKm: number = 0;
    AtividadeId: number;
    MedidorInicial: number = 0;
    FlagImplemento: boolean;
    ProcessoPlanejamento:string = "";
    _processoPlanejamento: number[] = [];
    UsaImplemento: boolean;

    IdIrrigaPrime:string = "";
    CorRotaIrrigaPrime:string = "";
    IconeIrrigaPrime:string = "";
    IconeIrrigaPrimeBase64:string = "";
    Tag:string = "";
    ClasseId: number;
}

export enum EnumTipoMedidorEquipamento
{
    NaoInformado = 0,
    Horimetro = 1,
    Hodometro =2
}

export const TiposMedidorEquipamento: ItemEnum[] = [
    {name: 'Nenhum', value: EnumTipoMedidorEquipamento.NaoInformado},
    {name: 'Horímetro', value: EnumTipoMedidorEquipamento.Horimetro},
    {name: 'Km', value: EnumTipoMedidorEquipamento.Hodometro},
  ];
