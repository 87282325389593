import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Equipamento } from 'src/app/classes/Equipamento';
import { PendenciaFrota } from 'src/app/classes/PendenciaFrota';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { PendenciaFrotaService } from 'src/app/services/pendencia-frota.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pendencia-frota',
  templateUrl: './pendencia-frota.component.html',
  styleUrls: ['./pendencia-frota.component.scss']
})
export class PendenciaFrotaComponent implements OnInit {

  @ViewChild('Grid', { static: false }) grid: any;
  @ViewChild('AnexosModal', { static: false })
  AnexosModal: UiModalComponent;

  coldefs: ColDef[] = [];
  dataGrid: any[] = [];
  pendenciaFrota: PendenciaFrota = new PendenciaFrota();
  objetosSelecionados: PendenciaFrota[] = [];
  titulo = 'Pendência Frota';

  listaPendenciaFrota: PendenciaFrota[] = [];
  objSelecionado: PendenciaFrota = new PendenciaFrota();
  objSelecionadoId: string = null;

  inserindo = false;
  id: string;
  equipamentos: Equipamento[] = [];


  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private pendenciaFrotaService: PendenciaFrotaService,
    private router: Router,
    private equipamentosService: EquipamentoService,
    private toasty: CustomToastyService,
    ) {
      this.route.params.subscribe(res => {
        this.id = res.id;
      });
    }

  ngOnInit() {
    const currentUrl = this.route.snapshot.url.join('/');
    if (currentUrl.includes('inserir')){
      this.inserindo = true;
      this.pendenciaFrota = new PendenciaFrota();
    }
    else if (this.id){
      this.selecionarPendenciaFrota();
    }
    else
      this.listar();

    this.listarEquipamentos();
    this.configurar();
  }

  listarEquipamentos(){
    this.equipamentosService.getEquipamentos().subscribe(
      res => {
        this.equipamentos = res;
      },
      err => {
        this.utils.getErro(err);
      });
  }

  selecionarPendenciaFrota() {
    this.pendenciaFrotaService.ObterPorId(this.id).subscribe(res =>{
      this.pendenciaFrota = res;
    },
    ex =>{
      this.utils.getErro(ex);
    })
  }

  listar(){
    this.pendenciaFrotaService.Listar()
      .subscribe(res => {
        this.listaPendenciaFrota = res;
      }, err => this.utils.getErro(err));
  }

  // ag-grid
  configurar(){
    this.coldefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'Descricao', headerName: 'Descrição'},
      {field: 'PrioridadeDesc', headerName: 'Prioridade'},
      {field: 'StatusDesc', headerName: 'Status'},
      {field: 'EquipamentoDesc', headerName: 'Equipamento'},
      {field: 'DataAbertura', headerName: 'Data Abertura', valueFormatter: (params) => this.utils.formatarData(params.data.DataAbertura)},
      {field: 'DataConclusao', headerName: 'Data Conclusão', valueFormatter: (params) => this.utils.formatarData(params.data.DataConclusao)},

    ];
  }

  cellDoubleClicked(event) {
    this.tituloEditar();
    this.pendenciaFrota = event.data;
    this.toggleGrid();
  }
  onCellClicked(row) {
    this.pendenciaFrota = row.data;
  }
  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  toggleGrid(){
    this.inserindo = !this.inserindo;
    if (this.inserindo) {
      if (this.pendenciaFrota.Id) {
        this.tituloEditar();
      } else
        this.tituloNovo()
    } else
      this.tituloListar();
  }

  novo(){
    this.pendenciaFrota = new PendenciaFrota();
    this.toggleGrid();
  }

  cancelar(){
    this.pendenciaFrota = new PendenciaFrota();
    this.listar();
    this.toggleGrid();
  }

  excluir(){

    if (this.objetosSelecionados.length === 0) {
      this.utils.handleWarning("Selecione um objeto para prosseguir!");
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning("Deve selecionar apenas um objeto para proseguir!");
      return;
    }

    Swal.fire({
      title: 'Deletar '+ this.objetosSelecionados[0].Descricao + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.pendenciaFrotaService.Excluir(this.objetosSelecionados[0].Id).subscribe(
            res => {
              this.toasty.show('Feito!', 'PendênciaFrota Frota excluída!', 'success');
              this.listar();
              this.inserindo = true;
              this.inserindo = false;
              this.pendenciaFrota = null;
              this.objetosSelecionados = [];
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }


  private tituloListar() {
    this.titulo = 'Lista';
  }
  private tituloEditar() {
    this.titulo = 'Editar';
  }
  private tituloNovo() {
    this.titulo = 'Cadastrar';
  }
}
