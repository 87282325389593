import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { Cultura } from 'src/app/classes/Cultura';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { Planejamento } from 'src/app/classes/Planejamento';
import { CultivarService } from 'src/app/services/cultivar.service';
import { Cultivar } from 'src/app/classes/Cultivar';
import Swal from 'sweetalert2';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit, OnChanges {
  @ViewChild('ModalDuplicar', { static: false })
  ModalDuplicar: UiModalComponent;
  @ViewChild('ModalSimular', { static: false })
  ModalSimular: UiModalComponent;

  @Input() cultura: Cultura = new Cultura();

  public carregando: boolean = false;
  tableView: boolean = false;

  planejamentos: Planejamento[];
  auxPlanejamentos: Planejamento[];
  cultivares: Cultivar[];
  cultivaresPorCultura: Cultivar[];

  filtroStatus: number = 0;
  filtroCultivar: number = 0;

  filtroStatusAtivo: string = "Por status";
  filtroCultivarAtivo: string = "Por cultivar";

  planejamentoDuplicado: Planejamento = new Planejamento();
  planejamentoSimulado: Planejamento = new Planejamento();
  public isSubmit: boolean;

  constructor(private planejamentoService: PlanejamentoService,
    private cultivaresService: CultivarService,
    private utils: UtilsService,
    private translateService: TranslateService
  ) {
      this.cultura = new Cultura();
  }

  ngOnInit() {
    this.carregando = true;

    this.cultivaresService.getCultivaresPadrao().subscribe(ret =>{
      this.cultivares = ret;
    });

    this.listarPlanejamentos();
  }

  ngOnChanges(){
    this.listarPlanejamentos();
  }

  listarPlanejamentos(){
    if (this.cultura == null){
      this.planejamentos = [];
      return;
    }

    this.carregando = true;
    this.planejamentoService.getPlanosMestrePorCultura(this.cultura.Id).subscribe(ret =>{
      this.planejamentos = ret;
      this.auxPlanejamentos = ret;
      this.carregando = false;
    });
  }

  getCultivar(id: number) : Cultivar {
    if (!this.cultivares)
      return new Cultivar();

    let cultivar = this.cultivares.filter(x =>
      x.Id==id
    );

    return cultivar.length > 0 ? cultivar[0] : new Cultivar();
  }

  getCultivaresPorCultura() : Cultivar[] {
    if (!this.cultivares || !this.cultura)
      return [];

    let cultivar = this.cultivares.filter(x =>
      x.CulturaId==this.cultura.Id
    );

    return cultivar;
  }

  filtrar(){
    this.filtroStatusAtivo = 'Por status';

    if (this.filtroStatus == 1)
      this.filtroStatusAtivo = 'Configurados';
    else if (this.filtroStatus == 2)
      this.filtroStatusAtivo = 'Não configurados';

    this.filtroCultivarAtivo = 'Por cultivar';

    if (this.filtroCultivar > 0)
      this.filtroCultivarAtivo = this.getCultivar(this.filtroCultivar).Descricao;

    this.planejamentos = this.auxPlanejamentos.filter(x =>
      (this.filtroStatus == 1 && x.Id > 0 ||
      this.filtroStatus == 2 && x.Id <= 0 ||
      this.filtroStatus == 0) &&
      (x.CultivarId == this.filtroCultivar ||
        this.filtroCultivar == 0)
    );
  }

  filtrarStatus(status){
    this.filtroStatus = status;
    this.filtrar();
  }

  filtrarCultivar(cultivarId){
    this.filtroCultivar = cultivarId;
    this.filtrar();
  }

  limparFiltros(){
    this.filtroStatus = 0;
    this.filtroCultivar = 0;
    this.filtrar();
  }

  deletar(id: number, index: number, nome: string) {
    const msgDeletar: string = this.translateService.instant("CONFIRMAR_DELETE");
    const deletar: string = this.translateService.instant("EXCLUIR");
    const sim: string = this.translateService.instant("SIM");
    Swal.fire({
      title: `${deletar} ${nome} ?`,
      text: msgDeletar,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: sim,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.planejamentoService.deletePlanejamento(id).subscribe(
          res => {
            this.planejamentos.splice(index, 1);
            this.cultura.QtdPlanejamentos--;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
        }
      });
  }

  duplicar(planejamento: Planejamento){
    this.isSubmit = false;
    this.planejamentoDuplicado = new Planejamento();
    this.planejamentoDuplicado.Id = planejamento.Id;
    this.planejamentoDuplicado.Descricao = planejamento.Descricao;
    this.planejamentoDuplicado.CultivarId = planejamento.CultivarId;
    this.ModalDuplicar.show();
  }

  confirmarDuplicacao(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    this.carregando = true;
    this.planejamentoService.duplicarPlanejamento(this.planejamentoDuplicado).subscribe(
      res => {
        this.planejamentos.push(res);
        this.ModalDuplicar.hide();
        this.carregando = false;
        this.cultura.QtdPlanejamentos++;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  novoPlanejamento(){
    this.isSubmit = false;
    this.planejamentoDuplicado = new Planejamento();
    this.planejamentoDuplicado.Padrao = true;
    this.cultivaresPorCultura = this.getCultivaresPorCultura();
    this.ModalDuplicar.show();
  }

  confirmarInclusao(form: any) {
    const mensagem: string = this.translateService.instant("PLANEJAMENTO_INCLUIDO_SUCESSO");
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    this.carregando = true;
    this.planejamentoService.postPlanejamento(this.planejamentoDuplicado).subscribe(
      res => {
        this.planejamentos.push(res);
        this.utils.handleSuccess(mensagem);
        this.ModalDuplicar.hide();
        this.carregando = false;
        this.cultura.QtdPlanejamentos++;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  simular(planejamento: Planejamento){
    this.isSubmit = false;
    this.planejamentoSimulado = new Planejamento();
    this.planejamentoSimulado.Id = planejamento.Id;
    this.planejamentoSimulado.CustoPorHA = planejamento.CustoPorHA;
    this.planejamentoSimulado.ProdutividadeSimulada = planejamento.ProdutividadeSimulada;
    this.planejamentoSimulado.PrecoVendaSimulado = planejamento.PrecoVendaSimulado;
    this.planejamentoSimulado.LucroSimulado = planejamento.LucroSimulado;
    this.ModalSimular.show();
  }

  confirmarSimulacao(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    let planejamento = this.planejamentos.filter(p => p.Id == this.planejamentoSimulado.Id)[0];

    this.calculaLucro();
    planejamento.ProdutividadeSimulada = this.planejamentoSimulado.ProdutividadeSimulada;
    planejamento.PrecoVendaSimulado = this.planejamentoSimulado.PrecoVendaSimulado;
    planejamento.LucroSimulado = this.planejamentoSimulado.LucroSimulado;

    this.carregando = true;
    this.planejamentoService.putPlanejamento(this.planejamentoSimulado.Id, planejamento).subscribe(
      res => {
        this.ModalSimular.hide();
        this.carregando = false;
      },
      err => {
        Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  calculaLucro(){
    this.planejamentoSimulado.LucroSimulado = (this.planejamentoSimulado.ProdutividadeSimulada * this.planejamentoSimulado.PrecoVendaSimulado) - this.planejamentoSimulado.CustoPorHA;
  }

}
