import { Component, Input, OnInit } from '@angular/core';
import { EmpresaInterna } from 'src/app/classes/EmpresaInterna';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { EmpresaInternaService } from 'src/app/services/empresa-interna.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-usuario-empresa',
  templateUrl: './usuario-empresa.component.html',
  styleUrls: ['./usuario-empresa.component.scss']
})
export class UsuarioEmpresaComponent implements OnInit {

  @Input() id: number = 0;

  empresas: EmpresaInterna[] = [];
  listaEmpresasAdicionadas: EmpresaInterna[] = [];
  listaEmpresasNaoAdicionadas: EmpresaInterna[] = [];

  colDefs: ColDef[] = [
    {
      width: 45,
      lockPosition: true,
      lockVisible: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {field: 'NomeFantasia', headerName: 'Nome Fantasia', flex: 1},
    {field: 'RazaoSocial', headerName: 'Razão Social', flex: 1}
  ];

  objSelecionadoT1: EmpresaInterna = new EmpresaInterna();
  objSelecionadoIdT1: number = null;
  objetosSelecionadosT1: EmpresaInterna[] = [];

  objSelecionadoT2: EmpresaInterna = new EmpresaInterna();
  objSelecionadoIdT2: number = null;
  objetosSelecionadosT2: EmpresaInterna[] = [];
  acoes1 = ['Adicionar'];
  acoes2 = ['Remover'];

  constructor(private empresaInternaService: EmpresaInternaService,
    private utils: UtilsService,
    private usuarioService: UsuarioService) { }

  ngOnInit() {
    this.listarEmpresas();
  }

  listarEmpresas() {
    this.empresaInternaService.getEmpresasInternas().subscribe(
      res => {
        this.empresas = res;
        this.listarEmpresasAdicionadas();
      },
      err => {
        this.utils.getErro(err);
      }
    );
  }

  listarEmpresasAdicionadas() {
    this.usuarioService.listarIdsEmpresasAdicionadas(this.id).subscribe(
      res => {
        const ids = res;
        this.listaEmpresasAdicionadas = this.empresas.filter(e => ids.includes(e.Id));
        this.listaEmpresasNaoAdicionadas = this.empresas.filter(e => !ids.includes(e.Id));
      },
      err => {
        this.utils.getErro(err);
      }
    );
  }

  onCellClickedT1(row) {
    this.objSelecionadoIdT1 = row.data.Id;
    this.objSelecionadoT1 = row.data;
  }

  selectionChangedT1(event) {
    this.objetosSelecionadosT1 = event;
  }

  onCellClickedT2(row) {
    this.objSelecionadoIdT2 = row.data.Id;
    this.objSelecionadoT2 = row.data;
  }

  selectionChangedT2(event) {
    this.objetosSelecionadosT2 = event;
  }

  adicionarItem(event) {
    this.usuarioService.adicionarEmpresa(this.id, event.data.Id).subscribe(
      ret =>{
      this.listarEmpresasAdicionadas();
      },
      err => {
        this.utils.getErro(err);
      }
    );
  }

  removerItem(event) {
    this.usuarioService.removerEmpresa(this.id, event.data.Id).subscribe(
      ret =>{
      this.listarEmpresasAdicionadas();
      },
      err => {
        this.utils.getErro(err);
      }
    );
  }

  acao(acao: string) {
    switch (acao) {
      case "Adicionar":
        this.acaoAdicionar();
        break;
      case 'Remover':
        this.acaoRemover();
        break;
      default:
        break
    }
  }

  acaoRemover() {
    if (this.objetosSelecionadosT2.length <= 0) {
      this.utils.handleWarning("Selecione uma Empresa para prosseguir!");
      return;
    }
    this.objetosSelecionadosT2.forEach(e => {
      this.usuarioService.removerEmpresa(this.id, e.Id).subscribe(
        ret =>{
        },
        err => {
          this.utils.getErro(err);
        }
        );
    });
    this.listarEmpresasAdicionadas();
  }

  acaoAdicionar() {
    if (this.objetosSelecionadosT1.length <= 0) {
      this.utils.handleWarning("Selecione uma Empresa para prosseguir!");
      return;
    }
    this.objetosSelecionadosT1.forEach(e => {
      this.usuarioService.adicionarEmpresa(this.id, e.Id).subscribe(
        ret =>{
        },
        err => {
          this.utils.getErro(err);
        }
      );
    });
    this.listarEmpresasAdicionadas();
  }


}
