import { StorageService } from 'src/app/services/storage.service';
import { EmpresaService } from './../../../services/empresa.service';
import { AtividadeService } from './../../../services/atividade.service';
import { ProdutoService } from './../../../services/produto.service';
import { ItemOrdemServicoService } from './../../../services/item-ordem-servico.service';
import { Produto } from './../../../classes/Produto';
import { ItemOrdemServico } from './../../../classes/ItemOrdemServico';
import { OrdemServico } from './../../../classes/OrdemServico';
import { OrdemServicoService } from './../../../services/ordem-servico.service';
import { SafraService } from './../../../services/safra.service';
import { FazendaService } from './../../../services/fazenda.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Fazenda } from 'src/app/classes/Fazenda';
import { Safra } from 'src/app/classes/Safra';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { EnumStatusOS } from 'src/app/classes/OrdemServico';
import Swal from 'sweetalert2';
import { Atividade } from 'src/app/classes/Atividade';
import { environment } from 'src/environments/environment';
import { BuscaOrdemServicoTranslate } from './busca-orderm-servico.translate';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/utils/utils.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-busca-ordem-servico',
  templateUrl: './busca-ordem-servico.component.html',
  styleUrls: ['./busca-ordem-servico.component.scss']
})
export class BuscaOrdemServicoComponent extends BuscaOrdemServicoTranslate implements OnInit {
  @Output() fechou: EventEmitter<any> = new EventEmitter();
  @Output() selecionar: EventEmitter<any> = new EventEmitter();
  @Input() fazendaId: number = 0;

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  carregando: boolean;
  safras: Safra[];
  fazendas: Fazenda[];
  ordensServico: OrdemServico[];
  itensOrdemServico: ItemOrdemServico[];
  produtos: Produto[];
  atividades: Atividade[];

  safrasFiltro: number[] = [];
  fazendasFiltro: number[] = [];
  statusFiltro: number[] = [];

  @Input() dataInicio: Date;
  @Input() dataFim: Date;
  @Input() status: number = 0;
  @Input() fixaFazenda: boolean;
  osDetalhe: number = 0;


  constructor(private fazendaService: FazendaService,
              private safraService: SafraService,
              private http: HttpClient,
              private utils: UtilsService,
              private ordemServicoService: OrdemServicoService,
              private itemOrdemServicoService: ItemOrdemServicoService,
              private produtoService: ProdutoService,
              private atividadeService: AtividadeService,
              private empresaService: EmpresaService,
              private storageService: StorageService,
              private translate: TranslateService) {
                super(translate)
               }

  ngOnInit() {
    this.fazendaService.getFazendas().subscribe(ret=>{
        this.fazendas = ret;
    })

    this.atividadeService.getAtividades().subscribe(ret =>{
      this.atividades = ret;
    });


    this.selecionarFazenda();
    this.selecionarStatus();

    setTimeout(()=>{
      this.Modal.show();
    }, 500)
  }

  selecionarFazenda(){
    this.safrasFiltro = [];
    this.fazendasFiltro = [];
    if(this.fazendaId){
      this.fazendasFiltro.push(this.fazendaId);
      this.carregando = true;
      this.safraService.getSafrasPorAnoFazendas(0, this.fazendasFiltro).subscribe(ret =>{
        this.safras = ret;
        this.carregando = false;
      });
    }
  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

  inserir(){
    let selecionadas: OrdemServico[] = [];
    this.ordensServico.filter(x=> x.Checked).forEach(o => {selecionadas.push(o)});
    this.selecionar.emit(selecionadas);
    this.fechar();
  }

  selectionarSafra(){

  }

  selecionarStatus(){
    this.statusFiltro = [];
    if (this.status ==1) {
        this.statusFiltro.push(EnumStatusOS.Aberta);
        this.statusFiltro.push(EnumStatusOS.AguardandoLiberacao);
    }
    else if(this.status == 2){

        this.statusFiltro.push(EnumStatusOS.Pendente);
        this.statusFiltro.push(EnumStatusOS.ParcialmenteConcluida);
    }
    else if(this.status == 3)
    {
        this.statusFiltro.push(EnumStatusOS.Concluida);
    }
    else if(this.status ==4) {
        this.statusFiltro.push(EnumStatusOS.Cancelada);
    }
  }

  filtrarOS(){
    let filtros: any = {
      Status: this.statusFiltro ? this.statusFiltro : [],
      Periodo: 0,
      AnoSafraId: 0,
      Fazenda: this.fazendasFiltro,
      Safra: this.safrasFiltro,
      Planejamento: [],
      Operacao: [],
      Atividade: [],
      AgruparPorFazenda: false,
      AgruparPorSafra: false,
      AgruparPorArea: false,
      AgruparPorEtapa: true,
      tipoVisualizacao: 1,
      dataInicio: this.dataInicio,
      dataFim: this.dataFim
    }
    this.carregando = true;
    this.ordemServicoService.detalhamento(filtros).subscribe(
      res => {
        this.ordensServico = res;
        this.carregando = false;
        this.agrupamentos();
      },
      err => {
        Swal.fire('Erro!', err.error.ExceptionMessage, 'error');
        this.carregando = false;
      });
  }

  exibeItens(id:number){
    this.osDetalhe = id;
    this.itensOrdemServico = [];
    this.produtos = [];
    if(id > 0){
      this.itemOrdemServicoService.getItensOrdemServico(id).subscribe(ret =>{
        this.itensOrdemServico = ret;
        this.itensOrdemServico.forEach(x => {
          this.produtoService.getProduto(x.ProdutoId).subscribe(retProd => {
            this.produtos.push(retProd);
          })
        })
      })
    }
  }

  getProduto(id: number) : Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';

    if (!this.produtos)
      return novo;

    let produto = this.produtos.filter(x =>
      x.Id==id
    );

    return produto.length > 0 ? produto[0] : novo;
  }

  getAtividade(id: number) : Atividade {
    if (!this.atividades)
      return new Atividade();

    let atividades = this.atividades.filter(x =>
      x.Id==id
    );

    return atividades.length > 0 ? atividades[0] : new Atividade();
  }

  agrupamentos(): void {
    this.carregando = true;
    this.ordensServico.forEach(o => {
      o.DescricaoSafraGroupBy = o.DescricaoFazenda+'-'+o.DescricaoSafra;
      o.DescricaoAreaGroupBy = o.DescricaoFazenda+'-'+o.DescricaoArea;
    });

    let fazendaAtual: String = '';
    let safraAtual: String = '';
    let areaAtual: String = '';
    let etapaAtual: String = '';

    this.ordensServico.forEach(o => {
      if (fazendaAtual != o.DescricaoFazenda){
        o.AgruparFazenda = true;
        fazendaAtual = o.DescricaoFazenda;
      }

      if (etapaAtual != o.DescricaoEtapa){
        o.AgruparEtapa = true;
        etapaAtual = o.DescricaoEtapa;
      }

      if (safraAtual != o.DescricaoSafraGroupBy){
        o.AgruparSafra = true;
        safraAtual = o.DescricaoSafraGroupBy;
      }

      if (areaAtual != o.DescricaoAreaGroupBy){
        o.AgruparArea = true;
        areaAtual = o.DescricaoAreaGroupBy;
      }
    })

    this.carregando = false;
  }

  imprimirAgrupamento(agrupamentoId: number){
    this.carregando = true;
    this.http.get(`${environment.apiURL}/ordemServico/agrupamento/gerarPDF/${this.empresaService.getEmpresaSelecionada().Id}/${agrupamentoId}`,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    }); 
  }

  imprimir(id: number){
    this.carregando = true;
    this.http.get(`${environment.apiURL}/ordemServico/gerarPDF/${this.empresaService.getEmpresaSelecionada().Id}/${id}/${this.storageService.getUsuario().Id}`,  { responseType: 'blob' }).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      this.carregando = false;
    }, err => {
      this.utils.getErro(err);
      this.carregando = false;
    }); 
  }

  abrir(id: number){
    window.open(`/ordem-servico/ordem-servico/${id}`, "_blank");
    //this.router.navigate(['/ordem-servico/ordem-servico/'+ id]);
  }

  toogleEtapa(descricao: String){
    this.ordensServico.filter(o => o.DescricaoEtapa == descricao).forEach(o => o.CollapsedEtapa = !o.CollapsedEtapa);
  }

}
