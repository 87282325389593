import { AnoSafraService } from 'src/app/services/ano-safra.service';
import { Component, OnInit } from '@angular/core';
import { AnoAgricola } from 'src/app/classes/AnoAgricola';
import { AnoSafra } from 'src/app/classes/AnoSafra';
import { Safra } from 'src/app/classes/Safra';
import { AnoAgricolaService } from 'src/app/services/ano-agricola.service';
import { SafraService } from 'src/app/services/safra.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { EntradaProducaoService } from 'src/app/services/entrada-producao.service';
import { ProducaoConsolidadoSafra } from 'src/app/classes/gestao-lite/ProducaoConsolidadoSafra';
import { ProducaoConsolidadoArea } from 'src/app/classes/gestao-lite/ProducaoConsolidadoArea';
import { PlanejamentoService } from 'src/app/services/planejamento.service';

@Component({
  selector: 'app-producao',
  templateUrl: './producao.component.html',
  styleUrls: ['./producao.component.scss']
})
export class ProducaoComponent implements OnInit {
  novaEntradaProducao: boolean = false;
  anosAgricolas: AnoAgricola[] = [];
  anosSafra: AnoSafra[] = [];
  safras: Safra[] = [];
  listaConsolidado: ProducaoConsolidadoSafra[] = [];
  auxListaConsolidado: ProducaoConsolidadoSafra[] = [];
  listaConsolidadoArea: ProducaoConsolidadoArea[] = [];
  auxListaConsolidadoArea: ProducaoConsolidadoArea[] = [];
  
  fazendaSelecionadaId: number = 0;
  anoAgricolaSelecionadoId: number = 0;
  anoSafraSelecionadoId: number = 0;
  safraSelecionadaId: number = 0;
  carregando: boolean = false;
  textoBuscaConsolidado: string = "";
  textoBuscaConsolidadoArea: string = "";
  totalSaldoKg: number = 0;
  totalSaldoSacas: number = 0;
  totalSaldoAreaKg: number = 0;
  totalSaldoAreaSacas: number = 0;
  totalAreaColhida: number = 0;
  totalAreaPlantada: number = 0;
  totalSaidasKg: number = 0;

  constructor(private entradaProducaoService: EntradaProducaoService,
    private anoSafraService: AnoSafraService,
    private anoAgricolaService: AnoAgricolaService,
    private safraService: SafraService,
    private planejamentoService: PlanejamentoService,
    private utils: UtilsService) { }

  ngOnInit() {    
  }

  inserirEntradaProducao(){
    this.novaEntradaProducao = true;
  }

  fecharEntradaProducao(){
    this.novaEntradaProducao = false;
  }

  selecionarFazenda(fazendaId: number){
    this.fazendaSelecionadaId = fazendaId;
    this.carregando = true;
    this.anoAgricolaService.listarPorFazenda(this.fazendaSelecionadaId).subscribe(ret => {
      this.anosAgricolas = ret;
      this.anoAgricolaSelecionadoId = 0;
      this.anoSafraSelecionadoId = 0;
      this.safraSelecionadaId = 0;
      this.carregando = false;
    })    
  }
  
  selecionarAnoAgricola(){
    this.carregando = true;
    this.anoSafraService.getAnosSafrasPorAnoAgricola(this.anoAgricolaSelecionadoId).subscribe(ret =>{
      this.anosSafra = ret;
      this.anoSafraSelecionadoId = 0;
      this.safraSelecionadaId = 0;
      this.carregando = false;
    })
  }

  selecionarAnoSafra(){
    this.carregando = true;
    this.safraService.getSafrasPorAnoFazenda(this.anoSafraSelecionadoId, this.fazendaSelecionadaId).subscribe(ret => {
      this.safras = ret;
      this.safraSelecionadaId = 0;
      this.carregando = false;
    })
  }

  selecionarSafra(){
    this.listarConsolidadoSafra();
    this.listarConsolidadoArea();
    this.getTotalAreaPlantada();
  }

  listarConsolidadoSafra(){
    this.carregando = true;
    this.entradaProducaoService.listarConsolidadoSafra(this.safraSelecionadaId).subscribe(ret => {
      this.listaConsolidado = ret;
      this.auxListaConsolidado = ret;    
      this.calcularTotais();  
      this.carregando = false;
    },
    err => {
      this.carregando = false;
      this.utils.getErro(err);
    })
  }

  listarConsolidadoArea(){
    this.carregando = true;
    this.entradaProducaoService.listarConsolidadoArea(this.safraSelecionadaId).subscribe(ret => {
      this.listaConsolidadoArea = ret;
      this.auxListaConsolidadoArea = ret;
      this.calcularTotaisArea();
      this.carregando = false;
    },
    err => {
      this.carregando = false;
      this.utils.getErro(err);
    })
  }


  filtraConsolidado(){
    if(this.textoBuscaConsolidado == ""){
      this.listaConsolidado = this.auxListaConsolidado;
    }else{
      this.listaConsolidado = this.auxListaConsolidado.filter(x => x.ProdutoDescricao.toLowerCase().includes(this.textoBuscaConsolidado.toLowerCase()));
    }
    this.calcularTotais();
  }

  filtrarConsolidadoArea(){
    if(this.textoBuscaConsolidadoArea == ""){
      this.listaConsolidadoArea = this.auxListaConsolidadoArea;
    }else{
      this.listaConsolidadoArea = this.auxListaConsolidadoArea.filter(x => x.AreaDescricao.toLowerCase().includes(this.textoBuscaConsolidadoArea.toLowerCase()) 
      || x.ProdutoDescricao.toLowerCase().includes(this.textoBuscaConsolidadoArea.toLowerCase()));
    }
    this.calcularTotaisArea();
  }
  
  calcularTotais(){
    this.totalSaldoKg = 0;
    this.totalSaldoSacas = 0;
    this.totalSaidasKg = 0;
    this.listaConsolidado.forEach(x => {
      this.totalSaldoKg += x.Saldo;      
      this.totalSaidasKg += x.Saidas;      
    })
    this.totalSaldoSacas = this.totalSaldoKg / 60;
  }

  calcularTotaisArea(){
    this.totalSaldoAreaKg = 0;
    this.totalSaldoAreaSacas = 0;
    this.totalAreaColhida = 0;
    this.listaConsolidadoArea.forEach(x => {
      this.totalSaldoAreaKg += x.ProducaoKg;
      this.totalAreaColhida += x.AreaColhida;
    })
    this.totalSaldoAreaSacas = this.totalSaldoAreaKg / 60;
  }

  getTotalAreaPlantada(){
    if(!this.safraSelecionadaId)
      return;

    this.carregando = true;
    this.planejamentoService.areaPlantadaPorSafra(this.safraSelecionadaId).subscribe(ret => {
      this.totalAreaPlantada = ret;
      this.carregando = false;
    },err => {
      this.carregando = false;
      this.utils.getErro(err);
    })
  }


}
