export class TipoDescontoProducao{
    Id: number;
    Descricao:string = "";
    CulturaId: number;
    Ativo: boolean;

    EmpresaId: number;
    DataCadastro: Date = new Date();
    DataAlteracao: Date = new Date();
    UsuarioCadastroId: number;
    UsuarioAlteracaoId: number;
}
