import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Rateio } from '../classes/Rateio';

@Injectable({
  providedIn: 'root'
})
export class RateioService {

  constructor(private http: HttpClient) { }

  post(rateio: Rateio): Observable<any>{
    return this.http.post(`${environment.apiURL}/Rateio`, rateio);
  }
  put(id: number, rateio: Rateio): Observable<any>{
    return this.http.put(`${environment.apiURL}/Rateio/${id}`, rateio);
  }
}
