import { TranslateService } from "@ngx-translate/core";

export class AgendaAtividadeTranslate{
  /**
   *
   */
  constructor(translate: TranslateService) {
    this.TODAS = translate.instant('TODAS');
    this.TODOS = translate.instant('TODOS');
    this.ABERTO = translate.instant('ABERTO');
    this.ERRO_OS = translate.instant('ERRO_OS');
    this.ERRO = translate.instant('ERRO');
    this.ATENCAO = translate.instant('ATENCAO');
    this.SELECIONE_UMA_FAZENDA = translate.instant('SELECIONE_UMA_FAZENDA');
    this.ORDEM_DE_SERVICO__JA_INSERIDA_EM_OUTRO_PLANEJAMENTO = translate.instant('ORDEM_DE_SERVICO__JA_INSERIDA_EM_OUTRO_PLANEJAMENTO');
    this.AGENDA_ALTERADA = translate.instant('AGENDA_ALTERADA');
    this.AGENDA_INCLUIDA = translate.instant('AGENDA_INCLUIDA');
    this.GERAR_NOVO_PLANEJAMENTO = translate.instant('GERAR_NOVO_PLANEJAMENTO');
    this.ESTE_PLANEJAMENTO_NAO_FOI_TOTALMENTE_CONCLUIDO_DESEJA_GERAR_UM_NOVO_PLANEJAMENTO_COM_AS_AREAS_RESTANTES = translate.instant('ESTE_PLANEJAMENTO_NAO_FOI_TOTALMENTE_CONCLUIDO_DESEJA_GERAR_UM_NOVO_PLANEJAMENTO_COM_AS_AREAS_RESTANTES');
    this.APENAS_CONFIRMAR = translate.instant('APENAS_CONFIRMAR');
    this.NOVO_PLANEJAMENTO = translate.instant('NOVO_PLANEJAMENTO');
    this.PLANEJAMENTO_CONCLUIDA = translate.instant('PLANEJAMENTO_CONCLUIDA');
    this.DELETAR_PLANEJAMENTO = translate.instant('DELETAR_PLANEJAMENTO');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.PLANEJAMENTO_DELETADO = translate.instant('PLANEJAMENTO_DELETADO');
    this.APONTAMENTO_DELETADO = translate.instant('APONTAMENTO_DELETADO');
    this.NOVO_PLANEJAMENTO_GERADO = translate.instant('NOVO_PLANEJAMENTO_GERADO');
    this.PLANEJAMENTO_CONCLUIDA = translate.instant('PLANEJAMENTO_CONCLUIDA');
    this.ABERTO = translate.instant('ABERTO');
    this.CONCLUIDO = translate.instant('CONCLUIDO');
    this.FEITO = translate.instant('FEITO');
    this.CANCELAR = translate.instant('CANCELAR');
    this.SIM = translate.instant('SIM');

  }
  TODAS: string = 'Todas';
  TODOS: string = 'Todos';
  ABERTO: string = 'Aberto';
  ERRO_OS: string = 'Erro OS!';
  ERRO: string = 'Erro!';
  ATENCAO: string = 'Atenção!';
  SELECIONE_UMA_FAZENDA: string = 'Selecione uma Fazenda!';
  ORDEM_DE_SERVICO__JA_INSERIDA_EM_OUTRO_PLANEJAMENTO: string = 'Ordem de Serviço #{{}} já inserida em outro Planejamento';
  AGENDA_ALTERADA: string = 'Agenda alterada!';
  AGENDA_INCLUIDA: string = 'Agenda incluída!';
  GERAR_NOVO_PLANEJAMENTO: string = 'Gerar novo Planejamento?';
  ESTE_PLANEJAMENTO_NAO_FOI_TOTALMENTE_CONCLUIDO_DESEJA_GERAR_UM_NOVO_PLANEJAMENTO_COM_AS_AREAS_RESTANTES: string = 'Este planejamento não foi totalmente concluído, deseja gerar um novo planejamento com as áreas restantes?';
  APENAS_CONFIRMAR: string = 'Apenas Confirmar';
  NOVO_PLANEJAMENTO: string = 'Novo Planejamento';
  PLANEJAMENTO_CONCLUIDA: string = 'Planejamento concluída!';
  DELETAR_PLANEJAMENTO: string = 'Deletar planejamento?';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  PLANEJAMENTO_DELETADO: string = 'Planejamento deletado!';
  APONTAMENTO_DELETADO: string = 'Apontamento deletado!';
  NOVO_PLANEJAMENTO_GERADO: string = 'Novo planejamento gerado!';
  CONCLUIDO: string = 'Concluído';
  FEITO: string = 'Feito!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
}
