import { ItemMovimento } from '../classes/ItemMovimento';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemMovimentoService {

  constructor(private http: HttpClient) { }

  getList(movimentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ItemMovimento/Listar/` + movimentoId)
  }

  get(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ItemMovimento/${id}`)
  }

  post(itemMovimento: ItemMovimento): Observable<any>{
    return this.http.post(`${environment.apiURL}/ItemMovimento`, itemMovimento);
  }

  put(id: number, itemMovimento: ItemMovimento): Observable<any>{
    return this.http.put(`${environment.apiURL}/ItemMovimento/${id}`, itemMovimento);
  }

  delete(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/ItemMovimento/${id}`);
  }
  getItemProduto(produtoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/ItemMovimento/Produto/${produtoId}`);
  }
}
