import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Cultura } from 'src/app/classes/Cultura';
import { TipoDescontoProducao } from 'src/app/classes/gestao-lite/TipoDescontoProducao';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { CulturaService } from 'src/app/services/cultura.service';
import { TipoDescontoProducaoService } from 'src/app/services/tipo-desconto-producao.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tipo-desconto-producao',
  templateUrl: './tipo-desconto-producao.component.html',
  styleUrls: ['./tipo-desconto-producao.component.scss']
})
export class TipoDescontoProducaoComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
    private router: Router,
    private culturaService: CulturaService,
    private toasty: CustomToastyService,
    private tipoDescontoProducaoService : TipoDescontoProducaoService
    ) { }

  @ViewChild('Grid', { static: false }) grid: any;

  colDefs: ColDef[] = [
    {
      width: 45,
      lockPosition: true,
      lockVisible: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {field: 'Descricao', headerName: 'Descrição', flex: 1},
    {field: 'CulturaId', headerName: 'Cultura', flex: 1, valueFormatter: (params) => this.getDescricaoCultura(params.data.CulturaId)},
    {field: 'Ativo', headerName: 'Status', flex: 1, cellRenderer: (params: any) => this.statusAprovacaoBadge(params.data.Ativo) }
  ];
  listaTipoDescontoProducao: TipoDescontoProducao[] = [];
  objSelecionado: TipoDescontoProducao = new TipoDescontoProducao();
  objSelecionadoId: number = 0;
  objetosSelecionados: TipoDescontoProducao[] = [];
  culturas: Cultura[];

  ngOnInit() {

    this.culturaService.getCulturas().subscribe((res: Cultura[]) =>{
      this.culturas = res;
    })
    this.listarTipoDescontoProducao();
  }
  statusAprovacaoBadge(status: boolean) {
    if (status)
      return '<span class="text-success text-uppercase">Ativo</span>'
    else
      return '<span class="text-danger text-uppercase">Inativo</span>'
  }

  getDescricaoCultura(id:number){
    return this.culturas.find(c => c.Id == id).Descricao;
  }

  listarTipoDescontoProducao() {
    this.tipoDescontoProducaoService.getTiposDescontoProducao()
      .subscribe(res => {
        this.listaTipoDescontoProducao = res;
        this.grid.setRowData(this.listaTipoDescontoProducao);
      }, err => this.utils.getErro(err));
  }

  novo() {
    this.router.navigate(['/gestao-lite/tipo-desconto-producao/inserir']);
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/gestao-lite/tipo-desconto-producao/' + event.data.Id]);
  }

  onCellClicked(row) {
    this.objSelecionadoId = row.data.Id;
    this.objSelecionado = row.data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  changeStatus() {
    if (!this.isSelecaoValida()) return;
    this.objetosSelecionados[0].Ativo = !this.objetosSelecionados[0].Ativo;
    this.tipoDescontoProducaoService.putTipoDescontoProducao(this.objetosSelecionados[0].Id, this.objetosSelecionados[0]).subscribe(
      res => {
        this.utils.handleSuccess(this.translate.instant('TIPO_DESCONTO_PRODUCAO_ALTERADO'));
        this.listarTipoDescontoProducao()
      },
      err => {
        this.utils.getErro(err);
      });

  }

  excluir() {
    if (!this.isSelecaoValida()) return;
    Swal.fire({
      title: this.translate.instant('DELETAR') + ' ' + this.objetosSelecionados[0].Descricao + '?',
      text: this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA'),
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('CANCELAR'),
      confirmButtonText: this.translate.instant('SIM'),
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.tipoDescontoProducaoService.deleteTipoDescontoProducao(this.objetosSelecionados[0].Id).subscribe(
            res => {
              this.utils.handleSuccess(this.translate.instant('TIPO_DESCONTO_PRODUCAO_EXCLUIDO'));
              this.listarTipoDescontoProducao();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

  isSelecaoValida(): boolean {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning(this.translate.instant('SELECIONE_UM_TIPO_DESCONTO_PRODUCAO_PARA_PROSSEGUIR'));
      return false;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning(this.translate.instant('SELECIONE_APENAS_UM_TIPO_DESCONTO_PRODUCAO_PARA_PROSSEGUIR'));
      return false;
    }

    return true;
  }

}
