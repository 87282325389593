import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { Cultura } from 'src/app/classes/Cultura';
import { Fazenda } from 'src/app/classes/Fazenda';
import { ItemEnum } from 'src/app/classes/ItemEnum';
import { Safra, StatusSafra } from 'src/app/classes/Safra';
import { FiltrosPlanejamentoSafra } from 'src/app/classes/planejamento-safra/FiltrosPlanejamentoSafra';
import { CulturaService } from 'src/app/services/cultura.service';
import { FazendaService } from 'src/app/services/fazenda.service';
import { SafraService } from 'src/app/services/safra.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-planejamento-producao-aside-filter',
  templateUrl: './planejamento-producao-aside-filter.component.html',
  styleUrls: ['./planejamento-producao-aside-filter.component.scss']
})
export class PlanejamentoProducaoAsideFilterComponent implements OnInit {

  @Output() filtrarItens = new EventEmitter<FiltrosPlanejamentoSafra>();

  listaFazendas: Fazenda[] = [];
  listaSafras: Safra[] = [];
  listaCulturas: Cultura[] = [];
  listaStatusSafra: ItemEnum[] = StatusSafra;


  fazendaSelecionada: Fazenda = null;
  safraSelecionada: Safra = null;
  culturaSelecionada: Cultura = null;
  statusSafraSelecionada: ItemEnum = null;

  filtroPlanejamentoSafra: FiltrosPlanejamentoSafra = new FiltrosPlanejamentoSafra();

  constructor(
    private fazendaService: FazendaService,
    private safraService: SafraService,
    private culturaService: CulturaService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.listarCulturas();
    this.listarFazendas();
  }

  private listarFazendas() {
    this.fazendaService.getFazendas()
      .subscribe(
        res => {
          this.listaFazendas = res as Fazenda[];
          this.listarSafras(res);
        },
        err => this.utils.getErro(err)
      )
  }

  private listarSafras(listaFazenda: Fazenda[]){
    this.safraService.getSafrasPorFazendas(
      listaFazenda.map(f => f.Id)
    ).subscribe(
      res => this.listaSafras = res,
      err => this.utils.getErro(err)
    )
  }

  private listarCulturas() {
    this.culturaService.getCulturas()
      .subscribe(
        res => this.listaCulturas = res,
        err => this.utils.getErro(err)
      )
  }

  selecionarFazenda(event){
    this.fazendaSelecionada = event;
    this.filtroPlanejamentoSafra.FazendaId = event ? this.fazendaSelecionada.Id : null;
    this.filtrarItens.emit(this.filtroPlanejamentoSafra);
  }

  selecionarSafra(event) {
    this.safraSelecionada = event;
    this.filtroPlanejamentoSafra.SafraId = event ? this.safraSelecionada.Id : null;
    this.filtrarItens.emit(this.filtroPlanejamentoSafra);
  }

  selecionarCultura(event) {
    this.culturaSelecionada = event;
    this.filtroPlanejamentoSafra.CulturaId = event ? this.culturaSelecionada.Id : null;
    this.filtrarItens.emit(this.filtroPlanejamentoSafra);
  }

  selecionarStatusSafra(event) {
    this.statusSafraSelecionada = event;
    this.filtroPlanejamentoSafra.StatusSafra = event ? this.statusSafraSelecionada.value : null;
    this.filtrarItens.emit(this.filtroPlanejamentoSafra);
  }
}
