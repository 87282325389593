import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Cultivar } from 'src/app/classes/Cultivar';
import { Cultura } from 'src/app/classes/Cultura';
import { CulturaProduto } from 'src/app/classes/CulturaProduto';
import { Empresa, enumErpIntegrado } from 'src/app/classes/Empresa';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { Natureza } from 'src/app/classes/Natureza';
import { Produto, enumTipo } from 'src/app/classes/Produto';
import { ClasseProdutoService } from 'src/app/services/classe-produto.service';
import { CultivarService } from 'src/app/services/cultivar.service';
import { CulturaProdutoService } from 'src/app/services/cultura-produto.service';
import { CulturaService } from 'src/app/services/cultura.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { NaturezaService } from 'src/app/services/natureza.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { StorageService } from 'src/app/services/storage.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-produto-forms',
  templateUrl: './produto-forms.component.html',
  styleUrls: ['./produto-forms.component.scss']
})
export class ProdutoFormsComponent implements OnInit {

  @ViewChild('Modal', { static: false })  Modal: UiModalComponent;

  @Input() IsNovo: boolean = false; 
  @Input() IsServico: boolean = false; 
  @Output() cancelarEvent = new EventEmitter<any>();

  produto: Produto;
  id: number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  servicos: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";

  perfilLogado: number = 0;

  listaNatureza: Natureza[] = [];
  gruposProduto: GrupoProduto[];
  culturas: Cultura[];
  culturaProduto: CulturaProduto = new CulturaProduto();
  culturasProduto: CulturaProduto[];
  cultivares: Cultivar[];
  auxCultivares: Cultivar[];
  culturaId: number = 0;

  possuiIntegracao: boolean;
  integracaoRM: boolean;

  pagina: number = 1;
  totalItens: number = 0;
  tamanhoPagina: number = 20;
  grupoProdutoFiltro: number = 0;
  grupoDescricaoFiltro: string = "Todos";
  ultimaSincronizacao: Date;
  permissaoSincronizaEstoqueERP: boolean;

  // tradução
  ERRO: string = 'Erro!';
  TODOS: string = 'Todos';
  FEITO: string = 'Feito!';
  SERVICO_ALTERADO: string = 'Serviço alterado!';
  PRODUTO_ALTERADO: string = 'Produto alterado!';
  SERVICO_CADASTRADO: string = 'Serviço cadastrado!';
  PRODUTO_CADASTRADO: string = 'Produto cadastrado!';
  DELETAR: string = 'Deletar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  CANCELAR: string = 'Cancelar';
  SIM: string = 'Sim';
  SERVICO_DELETADO: string = 'Serviço deletado!';
  PRODUTO_DELETADO: string = 'Produto deletado!';
  SEM_PERMISSAO: string = 'Sem Permissão!';
  VOCE_NAO_TEM_PERMISSAO_PARA_SINCRONIZAR_O_CADASTRO_DE_PRODUTOS: string = 'Você não tem permissão para sincronizar o cadastro de produtos.';
  TUDO_CERTO: string = 'Tudo certo!';
  FORAM_SINCRONIZADOS: string = 'Foram sincronizados';
  PRODUTOS: string = 'produtos';
  NENHUM_PRODUTO_IMPORTADO: string = 'Nenhum produto importado';
  CULTURA_DEVE_SER_INFORMADA: string = 'Cultura deve ser informada';
  CONFIGURACAO_DE_CULTURA_ADICIONADA: string = 'Configuração de Cultura adicionada!';
  DELETAR_CULTURA: string = 'Deletar Cultura';
  CONFIGURACAO_DE_CULTURA_DELETADA: string = 'Configuração de Cultura deletada!';
  PRODUTO_ATUALIZADO_COM_SUCESSO: string = 'Produto atualizado com sucesso!';

  configurarTraducao() {
    this.ERRO = this.translate.instant('ERRO');
    this.TODOS = this.translate.instant('TODOS');
    this.FEITO = this.translate.instant('FEITO');
    this.SERVICO_ALTERADO = this.translate.instant('SERVICO_ALTERADO');
    this.PRODUTO_ALTERADO = this.translate.instant('PRODUTO_ALTERADO');
    this.SERVICO_CADASTRADO = this.translate.instant('SERVICO_CADASTRADO');
    this.PRODUTO_CADASTRADO = this.translate.instant('PRODUTO_CADASTRADO');
    this.DELETAR = this.translate.instant('DELETAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SIM = this.translate.instant('SIM');
    this.SERVICO_DELETADO = this.translate.instant('SERVICO_DELETADO');
    this.PRODUTO_DELETADO = this.translate.instant('PRODUTO_DELETADO');
    this.SEM_PERMISSAO = this.translate.instant('SEM_PERMISSAO');
    this.VOCE_NAO_TEM_PERMISSAO_PARA_SINCRONIZAR_O_CADASTRO_DE_PRODUTOS = this.translate.instant('VOCE_NAO_TEM_PERMISSAO_PARA_SINCRONIZAR_O_CADASTRO_DE_PRODUTOS');
    this.TUDO_CERTO = this.translate.instant('TUDO_CERTO');
    this.FORAM_SINCRONIZADOS = this.translate.instant('FORAM_SINCRONIZADOS');
    this.PRODUTOS = this.translate.instant('PRODUTOS');
    this.NENHUM_PRODUTO_IMPORTADO = this.translate.instant('NENHUM_PRODUTO_IMPORTADO');
    this.CULTURA_DEVE_SER_INFORMADA = this.translate.instant('CULTURA_DEVE_SER_INFORMADA');
    this.CONFIGURACAO_DE_CULTURA_ADICIONADA = this.translate.instant('CONFIGURACAO_DE_CULTURA_ADICIONADA');
    this.DELETAR_CULTURA = this.translate.instant('DELETAR_CULTURA');
    this.CONFIGURACAO_DE_CULTURA_DELETADA = this.translate.instant('CONFIGURACAO_DE_CULTURA_DELETADA');
    this.PRODUTO_ATUALIZADO_COM_SUCESSO = this.translate.instant('PRODUTO_ATUALIZADO_COM_SUCESSO');
}


  constructor(private produtosService: ProdutoService,
    private grupoProdutoService: GrupoProdutoService,
    private classeProdutoService: ClasseProdutoService,
    private naturezaService: NaturezaService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private culturaService: CulturaService,
    private culturaProdutoService: CulturaProdutoService,
    private cultivarService: CultivarService,
    private empresaService: EmpresaService,
    private utils: UtilsService) {
    this.route.params.subscribe(res => {
      this.id = res.id;

    });
    this.servicos = this.router.url.includes('servicos');
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.permissaoSincronizaEstoqueERP = this.storageService.getUsuario().SincronizaEstoqueERP;

    this.empresaService.getEmpresa(this.storageService.getEmpresa().Id).subscribe(ret => {
      let empresa: Empresa = ret;
      this.possuiIntegracao = empresa.ErpIntegrado > enumErpIntegrado.Nenhum;
      this.integracaoRM = empresa.ErpIntegrado == enumErpIntegrado.Totvs_RM;
      this.ultimaSincronizacao = empresa.ErpIntegrado == enumErpIntegrado.Totvs_RM ? empresa.DataSincTotvsRm : empresa.DataSincSankhya;
    })

    this.inserindo = this.router.url.search("inserir") > 0;
    this.produto = new Produto();
    if(this.IsNovo)
    {
      this.inserindo = true;
      if(this.IsServico == true)
      {
        this.servicos = true;
      }
    }

    if (this.servicos) {
      this.grupoProdutoService.getServicos().subscribe(ret => {
        this.gruposProduto = ret;
      });
    }
    else {
      this.grupoProdutoService.getProdutos().subscribe(ret => {
        this.gruposProduto = ret;
      });
    }

    this.culturaService.getCulturas().subscribe(ret => {
      this.culturas = ret;
    })

    this.cultivarService.getCultivares().subscribe(ret => {
      this.auxCultivares = ret;
    })

    this.naturezaService.getList().subscribe(
      res => {
        this.listaNatureza = res;
      },
      err =>
        this.utils.getErro(err)
    );

    if(this.id > 0)
      this.selecionarProduto();
  }

  selecionarProduto() {
    this.carregando = true;
    this.produtosService.getProduto(this.id).subscribe(res => {
      this.produto = new Produto();
      this.produto = res;
      setTimeout(() => {
        if (this.produto.Cultivar) {
          this.culturaId = this.auxCultivares.find(x => x.Id == this.produto.CultivarId).CulturaId;
          this.filtrarCultivar();
        }
      }, 300);

      this.listarCulturasProduto();
      this.carregando = false;
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  save(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.produto.GrupoProduto = null;
    if (this.produto.Id > 0)
      this.produtosService.putProduto(this.produto.Id, this.produto).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.servicos ? this.SERVICO_ALTERADO : this.PRODUTO_ALTERADO, 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else {
      if (this.servicos) {
        this.produto.Tipo = enumTipo.Servico;
        this.produto.NaoControlaEstoque = true;
      }
      else {
        this.produto.Tipo = enumTipo.Produto;
      }
      this.produtosService.postProduto(this.produto).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.servicos ? this.SERVICO_CADASTRADO : this.PRODUTO_CADASTRADO, 'success');
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }

  }

  cancelar() {
    this.cancelarEvent.emit(this.servicos);
  }

  adicionarConfiguracaoCultura() {
    this.culturaProduto = new CulturaProduto();

    this.Modal.show();
  }

  salvarCulturaProduto() {
    this.configurarTraducao();
    this.carregando = true;
    if (this.culturaProduto.CulturaId <= 0) {
      Swal.fire(this.ERRO, this.CULTURA_DEVE_SER_INFORMADA, 'error');
      this.carregando = false;
    }

    if (this.culturaProduto.Id > 0) {
      this.culturaProdutoService.putCulturaProduto(this.culturaProduto.Id, this.culturaProduto).subscribe(
        ret => {
          this.listarCulturasProduto();
          this.carregando = false;
          this.fechar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        }
      );
    }
    else {
      this.culturaProduto.ProdutoId = this.id;
      this.culturaProdutoService.postCulturaProduto(this.culturaProduto).subscribe(ret => {
        this.culturaProduto = ret;
        this.culturasProduto.push(this.culturaProduto);
        this.toasty.show(this.FEITO, this.CONFIGURACAO_DE_CULTURA_ADICIONADA, 'success');
        this.carregando = false;
        this.fechar();
      },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    }
  }

  listarCulturasProduto() {
    this.culturaProdutoService.getCulturasEnergia(this.id).subscribe(ret => {
      this.culturasProduto = ret;
    })
  }

  fechar() {
    this.Modal.hide();
  }

  getDescricaoCultura(culturaId: number): string {
    return this.culturas.find(x => x.Id == culturaId).Descricao;
  }

  selecionarCulturaProduto(culturaProdutoId: number) {
    this.carregando = true;
    this.culturaProdutoService.getCulturaProduto(culturaProdutoId).subscribe(
      ret => {
        this.culturaProduto = ret;
        this.carregando = false;
        this.Modal.show();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      })
  }

  excluirCulturaProduto(culturaProdutoId: number, descricaoCultura: string) {
    this.configurarTraducao();
    Swal.fire({
      title: this.DELETAR_CULTURA + descricaoCultura + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.culturaProdutoService.deleteCulturaProduto(culturaProdutoId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.CONFIGURACAO_DE_CULTURA_DELETADA, 'success');
            this.listarCulturasProduto();
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }

  getCultura(id: number): Cultura {
    if (!this.culturas)
      return null;

    let cultura = this.culturas.filter(x =>
      x.Id == id
    );

    return cultura.length > 0 ? cultura[0] : new Cultura();
  }

  filtrarCultivar() {
    this.cultivares = this.auxCultivares.filter(x => x.CulturaId == this.culturaId);
  }

  atualizarProdutoErp() {
    this.configurarTraducao();
    this.carregando = true;
    this.produtosService.atualizarProdutoErp(this.produto.Id).subscribe(
      ret => {
        this.produto = ret;
        this.toasty.show(this.FEITO, this.PRODUTO_ATUALIZADO_COM_SUCESSO, 'success');        
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }

    );

  }

  atualizarProdutos()
  {
    this.carregando = true;
    this.produtosService.atualizarProdutos().subscribe(
      res => {
        this.carregando = false;
      },err=>{
        Swal.fire('Erro!', err.message, 'error');
              this.carregando = false;
      });
  }
}
