import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/classes/Usuario';
import { StorageService } from 'src/app/services/storage.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import * as moment from "moment";
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  public editProfile: boolean;
  public editProfileIcon: string;
  public imagem: string;
  public editContact: boolean;
  public carregar: boolean = false;    
  usuario: Usuario;
  public modalRedefinirSenha: boolean = false;

  constructor(
    private translate: TranslateService,
    private utils: UtilsService,
    private toasty: CustomToastyService,
    private storageService: StorageService,
    private usuarioService: UsuarioService
  ) {
  }

  ngOnInit() {
    this.usuario = this.storageService.getUsuario();
    this.usuarioService.getUsuario(this.usuario.Id).subscribe(
      res => {
        this.usuario = res
      },
      err => {
        console.error(err)
      }
    );
  }

  clikEdit(){
    this.editProfile = !this.editProfile;
    this.editProfileIcon = this.editProfile ? 'icon-x' : 'icon-edit';
    if(!this.editProfile)
      this.ngOnInit();
  }

  atualizarPerfil(){
    this.carregar = true;
    this.usuarioService.putUsuario(this.usuario.Id, this.usuario)
    .subscribe(
      res => {
        this.utils.handleSuccess(this.translate.instant('PERFIL_ATUALIZADO_ENTRE_NOVAMENTE_PARA_CARREGAR_AS_ALTERACOES'))
        this.carregar = false;
      },
      err => {
        this.toasty.show(this.translate.instant('ERRO'), this.translate.instant('FALHA_AO_ATUALIZAR_PERFIL'), 'error');
        this.carregar = false;
      })
  }

 formatDate(date: string){
    if(date == null || date == ''){
      return this.translate.instant('DATA_NAO_INFORMADA');
    }
    return moment(date).format("DD-MM-YYYY")
  }

  getBase64(event) {
    let that = this;
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      that.usuario.Imagem = reader.result.toString();
    };
    reader.onerror = function (error) {
    };
  }

  removerFoto(){
    this.usuario.Imagem = "";
  }
}