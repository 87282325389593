import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Local } from 'src/app/classes/Local';
import { ColDef } from 'src/app/classes/grid/ColDef';
import { LocalService } from 'src/app/services/local.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-local',
  templateUrl: './local.component.html',
  styleUrls: ['./local.component.scss']
})
export class LocalComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
    private router: Router,
    private toasty: CustomToastyService,
    private localService : LocalService
    ) { }

  @ViewChild('Grid', { static: false }) grid: any;

  colDefs: ColDef[] = [];
  listaLocal: Local[] = [];
  objSelecionado: Local = new Local();
  objSelecionadoId: string = null;
  objetosSelecionados: Local[] = [];

  ngOnInit() {
    this.configurar();
    this.listarLocal();
  }

  configurar() {
    this.colDefs = [
      {
        width: 45,
        lockPosition: true,
        lockVisible: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {field: 'Descricao', headerName: 'Descrição', flex: 1}
    ];

  }

  listarLocal() {
    this.localService.Listar()
      .subscribe(res => {
        this.listaLocal = res;
        this.grid.setRowData(this.listaLocal);
      }, err => this.utils.getErro(err));
  }

  novo() {
    this.router.navigate(['/cadastros/local/inserir']);
  }

  cellDoubleClicked(event) {
    this.router.navigate(['/cadastros/local/' + event.data.Id]);
  }

  onCellClicked({ data }) {
    this.objSelecionadoId = data.Id;
    this.objSelecionado = data;
  }

  selectionChanged(event) {
    this.objetosSelecionados = event;
  }

  excluir() {
    if (this.objetosSelecionados.length <= 0) {
      this.utils.handleWarning(this.translate.instant('SELECIONE_UMA_LOCAL_PARA_PROSSEGUIR'));
      return;
    }
    if (this.objetosSelecionados.length > 1) {
      this.utils.handleWarning(this.translate.instant('SELECIONE_APENAS_UMA_LOCAL_PARA_PROSSEGUIR'));
      return;
    }
    Swal.fire({
      title: this.translate.instant('DELETAR') + ' ' + this.objSelecionado.Descricao + '?',
      text: this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA'),
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('CANCELAR'),
      confirmButtonText: this.translate.instant('SIM'),
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.localService.Excluir(this.objSelecionadoId).subscribe(
            res => {
              this.utils.handleSuccess(this.translate.instant('LOCAL_EXCLUIDO'));
              this.listarLocal();
            },
            err => {
              this.utils.getErro(err);
            });
        }
      });
  }

}
