import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Abastecimento } from '../classes/gestao-lite/Abastecimento';

@Injectable({
  providedIn: 'root'
})
export class AbastecimentoService {

  constructor(private http: HttpClient) { }

  getAbastecimentos(): Observable<any>{
    return this.http.get(`${environment.apiURL}/abastecimento`)
  }

  getAbastecimento(id:number): Observable<any>{
    return this.http.get(`${environment.apiURL}/abastecimento/${id}`)
  }

  postAbastecimento(abastecimento: Abastecimento): Observable<any>{
    return this.http.post(`${environment.apiURL}/abastecimento`, abastecimento);
  }

  putAbastecimento(id: number, abastecimento: Abastecimento): Observable<any>{
    return this.http.put(`${environment.apiURL}/abastecimento/${id}`, abastecimento);
  }

  deleteAbastecimento(id: number): Observable<any>{
    return this.http.delete(`${environment.apiURL}/abastecimento/${id}`);
  }

  realizarAbastecimento(abastecimento: Abastecimento): Observable<any>{
    return this.http.post(`${environment.apiURL}/abastecimento/realizarAbastecimento`, abastecimento);
  }

  listarPorEquipamento(equipamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/abastecimento/listar/${equipamentoId}`)
  }

  getUltimoAberto(equipamentoId: number): Observable<any>{
    return this.http.get(`${environment.apiURL}/abastecimento/aberto/${equipamentoId}`)
  }

}
